import { Box, Portal } from '@mui/material';
import { FC, RefObject, useEffect, useRef, ForwardedRef } from 'react';
import { Text } from '../../Text';
import { OverlayContent, OverlayContentProps } from './OverlayContnet';
import { StrokeOverlayWidth } from './Content';
import { getMutableRefFromForwardedRef } from '../utils';

type HighlighterProps = OverlayContentProps & {
  cursorRef: RefObject<HTMLSpanElement>;
  inputRef: ForwardedRef<HTMLTextAreaElement>;
};

export const Highlighter: FC<HighlighterProps> = ({ inputRef, size, variant, ...props }) => {
  const highlighterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = getMutableRefFromForwardedRef(inputRef)?.current;
    const areRefsValid = ref && highlighterRef.current;
    if (!areRefsValid) return;

    const onScroll = () => {
      if (!highlighterRef.current) return;

      highlighterRef.current.scrollLeft = ref.scrollLeft;
      highlighterRef.current.scrollTop = ref.scrollTop;
    };

    ref.addEventListener('scroll', onScroll);
    return () => ref.removeEventListener('scroll', onScroll);
  }, [inputRef, highlighterRef]);

  return (
    <Portal container={getMutableRefFromForwardedRef(inputRef)?.current?.parentElement || null}>
      <Text
        variant={variant}
        size={size}
        ref={highlighterRef}
        sx={{
          ...getOverlayPosition(getMutableRefFromForwardedRef(inputRef)?.current),
          position: 'absolute',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          overscrollBehavior: 'none',
          pointerEvents: 'none',
        }}
      >
        <OverlayContent {...props} size={size} variant={variant} />
        <Box visibility="hidden">.</Box>
      </Text>
    </Portal>
  );
};

const getOverlayPosition = (input?: HTMLTextAreaElement | null) => {
  if (!input) {
    return {};
  }

  const computedStyle = getComputedStyle(input);

  return {
    left: `${
      input.offsetLeft +
      parseFloat(computedStyle.paddingLeft) +
      parseFloat(computedStyle.borderLeft) -
      parseFloat(computedStyle.marginLeft) -
      StrokeOverlayWidth / 2
    }px`,
    width: `${input.clientWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight)}px`,

    top: `${
      input.offsetTop +
      parseFloat(computedStyle.borderTop) -
      parseFloat(computedStyle.marginTop) -
      StrokeOverlayWidth / 2
    }px`,
    paddingTop: `${0 + parseFloat(computedStyle.paddingTop)}px`,
    paddingBottom: `${0 + parseFloat(computedStyle.paddingBottom)}px`,
    marginTop: `${0 + parseFloat(computedStyle.marginTop)}px`,
    marginBottom: `${0 + parseFloat(computedStyle.marginBottom)}px`,

    height: `${input.clientHeight}px`,
  };
};
