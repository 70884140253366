import React from 'react';
import { Outlet } from 'react-router-dom';
import CloudClientProvider from './CloudClientProvider';

const CloudLayout = () => (
  <CloudClientProvider>
    <Outlet />
  </CloudClientProvider>
);

export default CloudLayout;
