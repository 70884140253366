import type { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import { TextField } from '@mui/material';

export const smartTextContainsFilterOperator: GridFilterOperator<any, string> = {
  label: 'Smart Text Contains',
  value: 'smartTextContains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return () => true;
    }

    // eslint-disable-next-line arrow-body-style
    return (params): boolean => {
      return filterItem.value
        .toLowerCase()
        .split(' ')
        .every((fragment: string) => params.value?.toLowerCase().includes(fragment));
    };
  },
  InputComponent: TextField,
  InputComponentProps: { type: 'string' },
};
