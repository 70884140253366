import { CustomFieldFilterConfig } from '../FilterPanel';
import CustomDropdownFieldFilter from './CustomDropdownFieldFilter';
import CustomCheckboxFieldFilter from './CustomCheckboxFieldFilter';
import CustomTextFieldFilter from './CustomTextFieldFilter';

export type CustomFieldFilterProps = {
  config: CustomFieldFilterConfig;
};

const CustomFieldFilter = ({ config }: CustomFieldFilterProps) => {
  switch (config.type) {
    case 'TEXT':
      return <CustomTextFieldFilter config={config} />;
    case 'DROPDOWN':
      return <CustomDropdownFieldFilter config={config} />;
    case 'CHECKBOX':
      return <CustomCheckboxFieldFilter config={config} />;
    default:
      return null;
  }
};

export default CustomFieldFilter;
