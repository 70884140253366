import React from 'react';
import { SingleValue } from 'react-select';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectColor } from '@verticeone/design-system/src';
import { SimpleOption } from '@verticeone/design-system/src';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SingleSelectComponent } from './types';
import { TaskFormEntry } from './TaskFormEntry';
import { currencies } from '@verticeone/utils/currency';

type CurrencyFormEntryProps<FormDataType extends FieldValues> = {
  name: FieldPath<FormDataType>;
};

export const CurrencyFormEntry = <FormDataType extends FieldValues>({ name }: CurrencyFormEntryProps<FormDataType>) => {
  const { t } = useTranslation();

  const options = currencies.map<SimpleOption>((value) => ({
    value,
    label: value,
  }));

  return (
    <TaskFormEntry<any, SingleSelectComponent>
      name={name}
      label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CURRENCY')}
      component={FormSelectField2}
      componentProps={{
        isClearable: false,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR as SelectColor,
        options,
        boxValue: (value) => ({ value, label: value }),
        unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
      }}
      width={6}
    />
  );
};
