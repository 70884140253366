import React from 'react';
import { Box, styled } from '@mui/material';
import { useWizard } from './Wizard';
import { createPortal } from 'react-dom';

const StyledWizardOverlay = styled(Box)(({ theme }) => ({
  margin: 'auto',
  width: 1136,
  height: 'calc(100vh - 80px)',
  backgroundColor: 'white',
  boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'key', distance: '30', depth: '2z' }),
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'row',
}));

type WizardOverlayProps = {
  children: React.ReactNode;
};

export const WizardOverlay: React.FC<WizardOverlayProps> = ({ children }) => {
  const { wizardOverlayWrapperRef } = useWizard();
  if (!wizardOverlayWrapperRef.current) return null;
  return createPortal(<StyledWizardOverlay>{children}</StyledWizardOverlay>, wizardOverlayWrapperRef.current);
};
