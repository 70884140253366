import React from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@verticeone/design-system/src';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { MissingSPBanner, MissingRIBanner, DataLoaderComponent } from '../../../../components';
import { Overview, Body, ActionBar, ButtonGroup, Title, GraphWrapper } from '../../components';
import DataSourceProvider from '../../../../../DataSourceProvider';
import { TotalGraph, EC2Graph, LambdaGraph, FargateGraph } from './Graph';
import Summary from './Summary';

const Compute = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW.WIDGETS.COMPUTE' });

  const toInventory = () => navigate(routes.CLOUD.RIO.SP.INVENTORY.DETAIL, { product: 'compute' });
  const toOptimize = () => navigate(routes.CLOUD.RIO.SP.OPTIMIZE.DETAIL, { product: 'compute' });

  return (
    <DataSourceProvider datasource="Compute">
      <Overview>
        <Stack p={6} pb={0} gap={6}>
          <Title />
          <MissingSPBanner />
          <MissingRIBanner />
        </Stack>
        <DataLoaderComponent>
          <Body>
            <GraphWrapper sx={{ px: 0 }}>
              <Stack px={4}>
                <TotalGraph />
              </Stack>
              <Stack p={4} borderTop="1px solid" borderBottom="1px solid" sx={{ borderColor: 'core.color3' }}>
                <Text variant="button-bold" size="S" color="text1">
                  {t('BREAKDOWN')}
                </Text>
              </Stack>
              <Grid container px={4} pt={4} bgcolor="core.color1" spacing={4}>
                <Grid item xs={4}>
                  <EC2Graph />
                </Grid>
                <Grid item xs={4}>
                  <LambdaGraph />
                </Grid>
                <Grid item xs={4}>
                  <FargateGraph />
                </Grid>
              </Grid>
            </GraphWrapper>
            <Summary />
          </Body>
          <ActionBar>
            <ButtonGroup>
              <Button variant="ghost" size="S" color="neutral" onClick={toInventory}>
                {t('ACTION_BAR.INVENTORY')}
              </Button>
              <Button variant="ghost" size="S" color="tertiary" onClick={toOptimize}>
                {t('ACTION_BAR.OPTIMIZE')}
              </Button>
            </ButtonGroup>
          </ActionBar>
        </DataLoaderComponent>
      </Overview>
    </DataSourceProvider>
  );
};

export default Compute;
