import { accessManagementAPI as api } from '../../api/accessManagementAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    grantRoleToUser: build.mutation<GrantRoleToUserApiResponse, GrantRoleToUserApiArg>({
      query: (queryArg) => ({
        url: `/roles/${queryArg.domain}/${queryArg.userId}/${queryArg.roleId}/assign`,
        method: 'POST',
      }),
    }),
    revokeRoleFromUser: build.mutation<RevokeRoleFromUserApiResponse, RevokeRoleFromUserApiArg>({
      query: (queryArg) => ({
        url: `/roles/${queryArg.domain}/${queryArg.userId}/${queryArg.roleId}/revoke`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listPolicies: build.query<ListPoliciesApiResponse, ListPoliciesApiArg>({
      query: () => ({ url: `/policies` }),
    }),
    getPolicy: build.query<GetPolicyApiResponse, GetPolicyApiArg>({
      query: (queryArg) => ({ url: `/policies/${queryArg.policyId}` }),
    }),
    issueAccountPolicy: build.mutation<IssueAccountPolicyApiResponse, IssueAccountPolicyApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/policies/${queryArg.policyName}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accessManagementAPICodegen };
export type GrantRoleToUserApiResponse = /** status 200 OK */ object;
export type GrantRoleToUserApiArg = {
  /** Must be domain id or __GLOBAL for system role
   */
  domain: string;
  userId: string;
  roleId: string;
};
export type RevokeRoleFromUserApiResponse = unknown;
export type RevokeRoleFromUserApiArg = {
  /** Must be domain id or __GLOBAL for system role
   */
  domain: string;
  userId: string;
  roleId: string;
  body: object;
};
export type ListPoliciesApiResponse = /** status 200 OK */ object;
export type ListPoliciesApiArg = void;
export type GetPolicyApiResponse = /** status 200 OK */ object;
export type GetPolicyApiArg = {
  policyId: string;
};
export type IssueAccountPolicyApiResponse = /** status 200 OK */ object;
export type IssueAccountPolicyApiArg = {
  accountId: string;
  policyName: string;
  body: {
    policyDef?: PolicyDef;
  };
};
export type SemVer = string;
export type PolicyStatement = string[];
export type PolicyDocument = {
  version: SemVer;
  statements?: PolicyStatement[];
};
export type PolicyDef = {
  name: string;
  document: PolicyDocument;
};
export const {
  useGrantRoleToUserMutation,
  useRevokeRoleFromUserMutation,
  useListPoliciesQuery,
  useLazyListPoliciesQuery,
  useGetPolicyQuery,
  useLazyGetPolicyQuery,
  useIssueAccountPolicyMutation,
} = injectedRtkApi;
