import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../types/auth';

const initialState: AuthState = {
  jwtToken: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialize: (
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        redirectPath: string;
      }>
    ) => {
      Object.assign(state, { ...action.payload, isInitialized: true });
    },
    signIn: (state) => {
      Object.assign(state, { isAuthenticated: true });
    },
    signOut: (state) => {
      Object.assign(state, {
        isAuthenticated: false,
        redirectPath: '',
      });
    },
    resetRedirectPath: (state) => {
      Object.assign(state, { redirectPath: '' });
    },
  },
});

export const { initialize, signIn, signOut } = authSlice.actions;

export const getAuth = ({ auth }: { auth: AuthState }) => auth;

export default authSlice.reducer;
