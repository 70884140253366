import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@vertice/core/src/components/Dialog/Components';
import { Stack } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content } from '@vertice/core/src/components/Dialog/Components';
import { VendorChipButton } from './VendorChipButton';
import FormVendorSelectField from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField';
import { NewPurchaseFormData, NewPurchaseFormEntry } from '../../types';
import { FormVendorSelectFieldComponentType } from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField/FormVendorSelectField';
import { Vendor } from '@vertice/core/src/modules/vendor/types';

const useSelectVendorWizard = () => {
  const { goToStep } = useWizard();
  const goToNext = useCallback(() => goToStep('basic_requirements'), [goToStep]);
  return { goToNext };
};

const SelectVendor: React.FC<WizardStepProps> = () => {
  const { goToNext } = useSelectVendorWizard();
  const { t } = useTranslation();

  const { setValue } = useFormContext<NewPurchaseFormData>();

  const vendor = useWatch<NewPurchaseFormData>({ name: 'vendor' }) as Vendor | null;
  const prevVendorRef = useRef(vendor);

  // Auto-proceed when vendor is selected/changed
  useEffect(() => {
    if (vendor && vendor !== prevVendorRef.current) {
      prevVendorRef.current = vendor;
      setValue('products', []);
      goToNext();
    }
  }, [goToNext, setValue, vendor]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.NEW_PURCHASE_SELECT_VENDOR.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.NEW_PURCHASE_SELECT_VENDOR.HEADING_SUBTITLE')}
      />
      <InnerScrollable>
        <Stack direction="column" spacing={8}>
          <NewPurchaseFormEntry<FormVendorSelectFieldComponentType<NewPurchaseFormData>>
            name="vendor"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR')}
            required
            width={12}
            component={FormVendorSelectField}
            componentProps={{
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR'),
            }}
          />
          <Divider>{t('FORM.OR_DIVIDER')}</Divider>
          <NewPurchaseFormEntry
            name="vendor"
            label={t('INTAKE_FORM.NEW_PURCHASE_SELECT_VENDOR.LABELS.TRENDING_VENDORS')}
            width={12}
            component={VendorChipButton}
          />
        </Stack>
      </InnerScrollable>
    </Content>
  );
};

export default SelectVendor;
