import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';
import DateContractFormEntry from './DateContractFormEntry';

/**
 * Shows renewal with changing label based on markedForExpiration
 * - true => 'Planned Expiration Date'
 * - false => 'Renewal Date'
 */
const RenewalDateFormEntry: FC = () => {
  const { t } = useTranslation();

  const markedForExpiration = useWatch<ContractFormData>({ name: 'parts.contractual.renewal.markedForExpiration' });

  return (
    <DateContractFormEntry
      name="parts.contractual.lifecycle.renewalDate"
      label={t(
        markedForExpiration
          ? 'ENTITIES.CONTRACT.LABELS.PLANNED_EXPIRATION_DATE'
          : 'ENTITIES.CONTRACT.LABELS.RENEWAL_DATE'
      )}
      width={12}
    />
  );
};

export default RenewalDateFormEntry;
