/* eslint-disable camelcase */
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import { ApplicationWithMeasureReports } from '../../graphql/bffeUsage/derivedTypes';
import type { Severity } from '../../types/Severity';

export const MORE_THAN_MONTH = 46;

const lastDayUsedToTranslationString = (lastDayUsed: number) => {
  // categories inspired by https://day.js.org/docs/en/display/from-now

  if (lastDayUsed === 0) {
    return 'PREFERENCES.INTEGRATIONS.OKTA.USAGE.TODAY';
  }

  if (lastDayUsed === 1) {
    return 'PREFERENCES.INTEGRATIONS.OKTA.USAGE.YESTERDAY';
  }

  if (lastDayUsed < 26) {
    return 'PREFERENCES.INTEGRATIONS.OKTA.USAGE.X_DAYS_AGO';
  }

  if (lastDayUsed < MORE_THAN_MONTH) {
    return 'PREFERENCES.INTEGRATIONS.OKTA.USAGE.MONTH_AGO';
  }

  return 'PREFERENCES.INTEGRATIONS.OKTA.USAGE.MORE_THAN_MONTH';
};

export const getFromToDates = (reportGeneratedAt?: string) => {
  const today = dayjs();
  const tenDaysAgo = today.subtract(10, 'days');

  // the following are used in metrics and as we want to include also "today's stats"
  // we need to set the intervals from the tomorrow's date
  const tomorrow = today.add(1, 'day');
  const monthAgo = tomorrow.subtract(30, 'days');
  const twoMonthsAgo = tomorrow.subtract(60, 'days');

  // if the reportGeneratedAt is present, we need to shift it one day after to return that day's stats,
  // otherwise we return tomorrow's date to include "today's" stats
  const dayAfterReportGenerated = reportGeneratedAt ? dayjs(reportGeneratedAt).add(1, 'day') : tomorrow;

  return {
    today: today.format('YYYY-MM-DD'),
    tenDaysAgo: tenDaysAgo.format('YYYY-MM-DD'),
    tomorrow: tomorrow.format('YYYY-MM-DD'),
    monthAgo: monthAgo.format('YYYY-MM-DD'),
    twoMonthsAgo: twoMonthsAgo.format('YYYY-MM-DD'),
    dayAfterReportGenerated: dayAfterReportGenerated.format('YYYY-MM-DD'),
  };
};

export const getTimeFromNow = (date: string) => {
  dayjs.extend(relativeTime);

  return dayjs(date).fromNow();
};

export const getDaysFromNow = (date: string | null | undefined): number | null =>
  date ? dayjs().diff(dayjs(date).set('hour', 0).set('minute', 0), 'day') : null;

export const useGetLastUsed = () => {
  const { t } = useTranslation();

  const getDaysFromNowInString = (daysFromNow: number | null): string | null => {
    if (daysFromNow === null) return null;
    return t(lastDayUsedToTranslationString(daysFromNow), { daysFromNow });
  };

  return {
    getDaysFromNowInString,
    getLastUsedFromNowInString: (date: string | null | undefined) => getDaysFromNowInString(getDaysFromNow(date)),
  };
};

export const parseDelta = (lastValue?: number, previousValue?: number): number => {
  if (!!lastValue && !!previousValue) {
    return Math.round(((lastValue - previousValue) / previousValue) * 100);
  }

  if (!!lastValue && !previousValue) {
    return 100;
  }

  if (!lastValue && !!previousValue) {
    return -100;
  }

  return 0;
};

type PrepareDataReturn = {
  lastUsed: string | null;
  activeUsers: null | {
    users: number;
    licenses: number | null;
    delta: {
      severity: Severity;
      value: string;
    };
  };
  utilization: number | null;
};

export const prepareDataForApplicationAndMeasures = ({
  application,
}: {
  application?: ApplicationWithMeasureReports;
}): PrepareDataReturn => {
  const licenses = application?.license?.type === 'SEAT_BASED' ? application.license.quantity! : null;

  const lastMonth = parseInt(application?.measureReports?.uniqueUserAccessCountLast28d?.at(0)?.value || '0', 10);
  const users = lastMonth;
  const previousMonth = parseInt(application?.measureReports?.uniqueUserAccessCountPrev28d?.at(0)?.value || '0', 10);

  const activeUsersDelta = parseDelta(lastMonth, previousMonth);
  const lastUsed = application?.measureReports?.lastActivity56d ?? null;
  const deltaSeverity = ((): Severity => {
    if (activeUsersDelta > 0) return 'success';
    if (activeUsersDelta < 0) return 'error';
    return 'info';
  })();

  return {
    lastUsed,
    activeUsers:
      users !== null || licenses || activeUsersDelta
        ? {
            users,
            licenses,
            delta: {
              severity: deltaSeverity,
              value: `${activeUsersDelta >= 0 ? '+' : ''}${activeUsersDelta}`,
            },
          }
        : null,
    utilization: users !== null && licenses ? Math.round((users / licenses) * 100) : null,
  };
};

export const prepareDataForApplication = (application?: ApplicationWithMeasureReports): PrepareDataReturn =>
  prepareDataForApplicationAndMeasures({
    application,
  });

export const hasApplicationData = (application?: ApplicationWithMeasureReports) => {
  const data = prepareDataForApplication(application);

  return Object.values(data).some((item) => !!item);
};
