import React from 'react';
import { styled } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';

import { IconWrapper } from '../../../IconWrapper';
import { useBaseDropdown } from './BaseDropdownProvider';

const StyledExpandIcon = styled(IconWrapper)<{ $expanded: boolean }>(({ theme, $expanded }) => ({
  color: theme.palette.core.color5,
  transition: 'transform .2s ease-in-out',
  transform: `rotate(${$expanded ? '0deg' : '180deg'})`,
}));

const BaseExpandIcon = () => {
  const { expanded } = useBaseDropdown();

  return <StyledExpandIcon $expanded={expanded} size="L" icon={ExpandMoreOutlined} />;
};

export default BaseExpandIcon;
