import dayjs from 'dayjs';
import { Period } from './ActiveUser';

export const getDateRange = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, period: Period): dayjs.Dayjs[] => {
  if (period === '2MONTHS') {
    const numberOfSlots = Math.floor(endDate.diff(startDate, 'day') / 7);
    return Array.from({ length: numberOfSlots }, (_, i) => endDate.subtract((numberOfSlots - i) * 7, 'day'));
  } else {
    const numberOfSlots = Math.floor(endDate.diff(startDate, 'month'));
    const startMonth = startDate.add(1, 'month').startOf('month');
    return Array.from({ length: numberOfSlots }, (_, i) => startMonth.add(i, 'month'));
  }
};
