import { Stack } from '@mui/material';
import { Placeholder } from '../Placeholder';
import { Avatar } from '../Avatar';

export const CommentSkeleton = () => (
  <Stack padding={4} width={'100%'} gap={2} bgcolor="white">
    <Stack direction="row" alignItems="center" gap={2}>
      <Avatar personName="" personId="" size="XS" />
      <Placeholder width={100} />
    </Stack>
    <Stack paddingLeft={8} alignItems="top" gap={2}>
      <Placeholder width={120} />
    </Stack>
  </Stack>
);
