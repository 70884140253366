import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import { IconWrapper } from '@verticeone/design-system/src';
import { IconWrapperProps } from '@verticeone/design-system/src';

export const InfoIcon = styled(
  forwardRef<SVGSVGElement, Pick<IconWrapperProps, 'size'>>(({ size = 'S', ...restProps }, ref) => (
    <IconWrapper {...restProps} ref={ref} icon={InfoOutlined} size={size} color="info" />
  ))
)(({ theme }) => ({
  backgroundColor: theme.palette.info.color2,
  borderRadius: '4px',
  color: theme.palette.info.contrastText,
  padding: 2,
}));
