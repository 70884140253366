import { useState, useEffect } from 'react';
import useRIPurchaseData from './useRIPurchaseData';

const useLoadMoreRiPurchaseData = (isPending?: boolean) => {
  const [lastNextToken, setLastNextToken] = useState<string | undefined>(undefined);
  const [requestNextToken, setRequestNextToken] = useState<string | undefined>(undefined);

  const { data, refetch, ...loadingStatus } = useRIPurchaseData({ isPending, nextToken: requestNextToken });

  useEffect(() => {
    if (data) {
      setLastNextToken(data.nextToken);
    }
  }, [data]);

  const onLoadMore = () => setRequestNextToken(lastNextToken);

  return { data, loadingStatus, onLoadMore, refetch };
};

export default useLoadMoreRiPurchaseData;
