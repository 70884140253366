import {
  Contract,
  ContractCategory,
  ContractClassification,
  ContractCustomFields,
  ContractMaterialized,
  ContractOrigin,
  ContractPartContractual,
  ContractPartContractualVendor,
  ContractPartModelCost,
  ContractPartOverview,
  ContractPartWorkflow,
  RecordStatus,
  RenewalType,
  RollFrequency,
  Task,
  VendorColabDetails,
  VendorColabDetailsOutcome,
  VendorColabDetailsRagStatus,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { FieldPath, UseFormReturn } from 'react-hook-form';
import { Vendor } from '@vertice/core/src/modules/vendor/types';
import { ZodSchema } from 'zod';
import { UseContractFieldsRulesReturn } from './useContract/useContractFieldsRules';
import { UseContractEditModeReturn } from './useContract/useContractEditMode';
import { UseContractSavingReturn } from './useContract/useContractSaving';
import { VendorType } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { AnnualCostApproximationCostModelFormType } from './costModels/AnnualCostApproximation';
import { AdvancedLinearApproximationCostModelFormType } from './costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';

export type AccessViaAccount = {
  type: 'account';
  accountId: string;
};

export type AccessViaWorkflow = {
  type: 'workflow';
  accountId: string;
  workflowId: string;
};

export type AccessVia = AccessViaAccount | AccessViaWorkflow;

/**
 * Encapsulates information about what specific contract is being accessed through what proxy.
 * It should have everything that is necessary to fetch the contract from the API.
 */
export type ContractLocator = {
  contractId: string;
  accessVia: AccessVia;
};

export type LoadableContract<PayloadType> = { isFetching: boolean; refetch: () => void } & (
  | { isLoading: true }
  | { isLoading: false; data: PayloadType }
);

/**
 * Representation of all raw data from API that are necessary for rendering a basic contract form.
 *
 * No computed nor transformed fields here.
 */
export type FetchedContract = {
  contract: Contract;
  accessedVia: AccessVia;
  // Avoid putting computed fields here.
};

export type ContractContextDataBase = {
  accessVia: AccessVia;

  /**
   * Encapsulated state of react-hook-form.
   * You can also access this separately with `useContractHookFormContext`.
   */
  hookForm: UseFormReturn<ContractFormData>;

  /** Functions and atoms for consumption of fieldsConfig by the form. */
  fieldsRules: UseContractFieldsRulesReturn;

  /** Controls of the edit mode */
  editMode: UseContractEditModeReturn;

  /** Saving of the contract to the API */
  saving: UseContractSavingReturn;

  // Computed stuff can go here, but still, if possible, prefer small independent hooks.
};

export type ContractContextDataRequireFetched = {
  formSupportedMode: 'REQUIRE_FETCHED';

  /** Last contract that is known to be saved on the server. */
  fetchedContract: FetchedContract;
} & ContractContextDataBase;

export type ContractContextDataOptionalFetched = {
  formSupportedMode: 'DONT_REQUIRE_FETCHED';

  /** Last contract that is known to be saved on the server. */
  fetchedContract?: FetchedContract;
} & ContractContextDataBase;

/** Main contract context. React-hook-form state is wrapped inside of it. */
export type ContractContextData = ContractContextDataRequireFetched | ContractContextDataOptionalFetched;
export const isContractContextDataRequireFetched = (
  contractContextData: ContractContextData
): contractContextData is ContractContextDataRequireFetched =>
  contractContextData.formSupportedMode === 'REQUIRE_FETCHED';

export const allRecordStatuses: RecordStatus[] = ['ACTIVE', 'INACTIVE', 'ARCHIVED', 'DELETED'];

export const allContractOrigins: ContractOrigin[] = [
  'EXISTING',
  'PURCHASE_WITH_VERTICE',
  'PURCHASE_WITHOUT_VERTICE',
  'RENEWAL_WITH_VERTICE',
  'RENEWAL_WITHOUT_VERTICE',
];

export const allRollFrequencies: readonly RollFrequency[] = ['MONTHLY', 'QUARTERLY', 'ANNUALLY', 'BIANNUALLY', 'NO'];

export const allRenewalTypes: RenewalType[] = ['UPGRADE', 'FLAT', 'DOWNGRADE'];

export const allRagStatuses: VendorColabDetailsRagStatus[] = ['GREEN', 'AMBER', 'RED'];

export const allVendorColabOutcomes: VendorColabDetailsOutcome[] = [
  'SIGNED_NDA',
  'SUPPLIER_BACKED_DOWN',
  'PENDING',
  'BACKGROUND_SUPPORT',
];

export type ContractFormProducts = Array<{
  id: string;
  name: string;
  productId: string;
  annualCost: number | null;
  numberOfLicences: number | null;
  licenseType: {
    id: string;
    label: string;
  } | null;
}>;

export type CostModelMaterialized =
  | AnnualCostApproximationCostModelFormType
  | AdvancedLinearApproximationCostModelFormType;

export type ContractFormData = {
  record: {
    contractOrigin?: ContractOrigin;
    /** @deprecated use classification.category instead */
    contractCategory?: ContractCategory;
    status?: RecordStatus;
  };
  parts: {
    model: {
      cost: ContractPartModelCost;
    };
    contractual: Omit<ContractPartContractual, 'vendor' | 'products'> & {
      vendor: Omit<ContractPartContractualVendor, 'vendorId' | 'vendorType' | 'vendorName'> & {
        vendorOption: Vendor | null;
      };
      products?: ContractFormProducts;
    };
    overview: ContractPartOverview;
  };
  additionalDetails: {
    internal?: {
      notes?: string | null;
      vendorColabDetails?: VendorColabDetails;
    };
    external?: {
      notes?: string | null;
    };
  };
  classification: ContractClassification;
  workflow: ContractPartWorkflow;
  customFields: ContractCustomFields;
  files?: File[];
  costModel: {
    baseline?: CostModelMaterialized;
    negotiated?: CostModelMaterialized;
  };
};

export type ContractFormComputedFields = 'contractLength' | 'nextRollDate';

export type FormPlusComputedFieldPaths = FieldPath<ContractFormData> | ContractFormComputedFields;

export type FieldRuleExtraOptions = { showIn?: 'BOTH' | 'EDIT_MODE_ONLY' | 'READ_MODE_ONLY' };

export type FormSupportedMode = 'REQUIRE_FETCHED' | 'DONT_REQUIRE_FETCHED';
export type FetchedDataTypeFromMode<Mode extends FormSupportedMode> = Mode extends 'REQUIRE_FETCHED'
  ? FetchedContract
  : FetchedContract | undefined;
export type ModeFromFetchedDataType<T extends FetchedContract | undefined> = T extends FetchedContract
  ? 'REQUIRE_FETCHED'
  : 'DONT_REQUIRE_FETCHED';
export type ContractContextDataTypeFromMode<Mode extends FormSupportedMode> = 'REQUIRE_FETCHED' extends Mode
  ? ContractContextDataRequireFetched
  : ContractContextDataOptionalFetched;

export type OverrideFieldRuleGetterContext = {
  baseRule: FieldRule;
};
export type OverrideFieldRuleGetter = (context: OverrideFieldRuleGetterContext) => FieldRule;

/**
 * Controls how a field is rendered and what validation rules it has.
 *
 * ['HIDDEN'] - Field is not rendered at all.
 * ['READABLE'] - Field is always rendered in read-only mode, even if you enter edit mode.
 * ['WRITABLE', ZodSchema]
 *   Field renders read component in read mode, edit component in edit mode.
 *   In edit mode, validates using specified ZodSchema.
 *
 * Optionally, for 'READ' and 'WRITE' permissions, you can specify additional options and limit rendering
 * to only edit or only to read mode.
 */
export type FieldRule =
  | ['HIDDEN']
  | ['READABLE', FieldRuleExtraOptions?]
  | ['WRITABLE', ZodSchema, FieldRuleExtraOptions?];
export type FieldRuleGetterContext<Mode extends FormSupportedMode> = (Mode extends 'REQUIRE_FETCHED'
  ? FetchedContract
  : Partial<FetchedContract>) & { formData: ContractFormData };
export type FieldRuleGetter<Mode extends FormSupportedMode> = (context: FieldRuleGetterContext<Mode>) => FieldRule;
export type FieldRulesPerField = Partial<Record<FormPlusComputedFieldPaths, FieldRule | OverrideFieldRuleGetter>>;
// when field overrides have been resolved
export type ResolvedFieldRulesPerField = Partial<Record<FormPlusComputedFieldPaths, FieldRule>>;
export type FieldsConfig<Mode extends FormSupportedMode> = {
  isWritableAsDefault: boolean;
  isVisibleAsDefault: boolean;
  getRules: (fieldRulesContext: FieldRuleGetterContext<Mode>) => ResolvedFieldRulesPerField;
};

export type ResolvedFieldsConfig = {
  isWritableAsDefault: boolean;
  isVisibleAsDefault: boolean;
  fieldRules: ResolvedFieldRulesPerField;
};

export type FieldsRules = Partial<Record<FormPlusComputedFieldPaths, FieldRule | undefined>>;

export type WorkflowStage =
  | 'REQUIREMENTS_GATHERING'
  | 'NEGOTIATING'
  | 'APPROVAL'
  | 'CONTRACTING'
  | 'CANCELLED'
  | 'COMPLETED'
  | undefined;

/** Set of fields that typically represent one vendor in API */
export type ApiVendor = {
  vendorType?: VendorType;
  vendorId?: string;
  vendorName?: string;
};

export type PurchaseRequestInputPresetConfiguration = {
  kind: 'InputPreset';
  configuration: {
    contractId: string;
    stage: WorkflowStage;
  };
};

export type RenewalRequestInputPresetConfiguration = {
  kind: 'InputPreset';
  configuration: {
    contractId: string;
    parentContractId: string;
    vendorType: VendorType | null;
    vendorId: string | null;
    vendorName: string | null;
    contractCategory: ContractCategory | null;
    stage: WorkflowStage;
  };
};

export type RequestConfiguration = PurchaseRequestInputPresetConfiguration | RenewalRequestInputPresetConfiguration;

export const allTaskStatuses = new Array<Task['status']>('PENDING', 'ACTIVE', 'COMPLETED', 'TERMINATED');

export type FulfillFirstTaskOptions = { onSuccess?: () => void; validateFormNextStageRequirements?: boolean };

export type ContractTasksContextData = {
  firstTask?: Task;
  fulfillFirstTask: (input: object, options?: FulfillFirstTaskOptions) => void;
  isFulfillingFirstTask: boolean;
};

export type RenewalDeadline = NonNullable<ContractMaterialized['renewalDeadline']>;

export type ContractPermissions = {
  userCanReadContract: boolean;
  userCanWriteContract: boolean;
  userCanExecuteContract: boolean;
};
