import { RefObject, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Switch, { SwitchProps, switchClasses } from '@mui/material/Switch';
import { styled, Theme } from '@mui/material/styles';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { gradientBorder } from '../../utils/css/gradientBorder';
import { testProps } from '../../utils/testProperties';
import CheckSvg from './check.svg';
import RemoveSvg from './remove.svg';
import ClearSvg from './clear.svg';

const THUMB_BORDER_WIDTH = 1;
const indeterminateClass = 'vrtc-indeterminate';

export type ToggleSwitchProps = TestProps &
  CustomizedProps &
  Omit<SwitchProps, 'color' | 'size'> & {
    color?: DesignSystemColor;
    size?: DesignSystemSize;
    withCheckIcon?: boolean;
  };

type StyledSwitchProps = TestProps & {
  $color?: DesignSystemColor;
  $size?: DesignSystemSize;
  $internalChecked?: boolean | null;
  $internalFocusVisible: boolean;
  $withCheckIcon?: boolean;
  theme: Theme;
  ref?: RefObject<any>;
};

type SizeDef = {
  width: number;
  height: number;
  padding: number;
};

const sizeDefinitions: Record<DesignSystemSize, SizeDef> = {
  XL: { width: 48, height: 26, padding: 3 },
  L: { width: 48, height: 24, padding: 3 },
  M: { width: 36, height: 20, padding: 2 },
  S: { width: 36, height: 18, padding: 2 },
  XS: { width: 24, height: 16, padding: 2 },
  XXS: { width: 24, height: 14, padding: 2 },
};

const StyledSwitch = styled(Switch)<StyledSwitchProps>(
  ({ theme, $color = 'primary', $size = 'M', $withCheckIcon, $internalChecked, $internalFocusVisible }) => {
    const palette = theme.palette;

    const sizeDef = sizeDefinitions[$size];
    const thumbDiameter = sizeDef.height - 2 * sizeDef.padding;
    const stateBoxShadow = `0 0 0 4px ${$internalChecked ? palette[$color].color4 : palette.core.color2}`;

    return {
      width: sizeDef.width,
      height: sizeDef.height,
      padding: 0,
      display: 'flex',
      overflow: 'visible',
      borderRadius: sizeDef.height / 2,
      [`& .${switchClasses.switchBase}`]: {
        padding: sizeDef.padding,
        [`&.${indeterminateClass}`]: {
          transform: `translateX(${(sizeDef.width - thumbDiameter - 2 * sizeDef.padding) / 2}px)`,
          [`& .${switchClasses.thumb}:after`]: {
            ...($withCheckIcon
              ? {
                  mask: `url(${RemoveSvg}) no-repeat center center`,
                  maskSize: '74%',
                }
              : {}),
          },
        },
        '&.Mui-checked': {
          transform: `translateX(${sizeDef.width - thumbDiameter - 2 * sizeDef.padding}px)`,
        },
        [`&.Mui-checked, &.${indeterminateClass}`]: {
          color: '#fff',
          [`& + .${switchClasses.track}`]: {
            opacity: 1,
            backgroundColor: palette[$color].color2,
          },
          // for check icon
          [`& .${switchClasses.thumb}`]: {
            '&:after': {
              backgroundColor: palette[$color].color2,
            },
          },
        },
        '&.Mui-disabled.Mui-disabled': {
          '+ .MuiSwitch-track': {
            backgroundColor: palette.inactive.color4,
            opacity: 1,
          },

          [`.${switchClasses.thumb}`]: {
            boxShadow: 'none',
            backgroundColor: palette.inactive.color3,
            // for check icon
            '&:after': {
              backgroundColor: palette.inactive.color4,
            },
            ...gradientBorder({
              width: `${THUMB_BORDER_WIDTH}px`,
              color: palette.inactive.color3,
              radius: `${thumbDiameter / 2}px`,
            }),
          },

          [`&.Mui-checked, &.${indeterminateClass}`]: {
            '+ .MuiSwitch-track': {
              backgroundColor: palette[$color].color4,
              opacity: 1,
            },
            [`.${switchClasses.thumb}`]: {
              boxShadow: 'none',
              backgroundColor: palette[$color].color3,
              // for check icon
              '&:after': {
                backgroundColor: palette[$color].color4,
              },
              ...gradientBorder({
                width: `${THUMB_BORDER_WIDTH}px`,
                color: palette[$color].color3,
                radius: `${thumbDiameter / 2}px`,
              }),
            },
          },
        },
      },
      [`& .${switchClasses.thumb}`]: {
        ...gradientBorder({
          width: `${THUMB_BORDER_WIDTH}px`,
          color: palette.gradient.transparentToBlack10,
          radius: `${thumbDiameter / 2}px`,
        }),
        boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'key', depth: '1z', distance: '10' }),

        // We want to set boxSizing explicitly because thumb is a span and without a CSS normalizr, spans would
        // content-box boxSizing.
        boxSizing: 'border-box',
        width: thumbDiameter,
        height: thumbDiameter,
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
        '&:after': {
          ...($withCheckIcon
            ? {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: '0',
                top: '0',
                backgroundColor: palette.inactive.color3,
                mask: `url(${$internalChecked ? CheckSvg : ClearSvg}) no-repeat center center`,
                maskSize: '80%',
              }
            : {}),
        },
      },
      [`& .${switchClasses.track}`]: {
        borderRadius: sizeDef.height / 2,
        opacity: 1,
        backgroundColor: palette.core.color3,
        boxSizing: 'border-box',
        boxShadow: $internalFocusVisible ? stateBoxShadow : 'none',
      },
      '&:hover': {
        [`& .${switchClasses.switchBase}.Mui-checked:not(.Mui-disabled), & .${switchClasses.switchBase}.${indeterminateClass}:not(.Mui-disabled)`]:
          {
            [`& + .${switchClasses.track}`]: {
              background: palette[$color].hover.color2,
            },
            // for check icon
            [`& .${switchClasses.thumb}`]: {
              '&:after': {
                backgroundColor: palette[$color].hover.color2,
              },
            },
          },
        [`& .${switchClasses.switchBase} + .MuiSwitch-track`]: {
          background: palette.inactive.color2,
        },
        [`& .${switchClasses.switchBase}`]: {
          background: 'none',
        },
        [`& .${switchClasses.thumb}`]: {
          // for check icon
          '&:after': {
            backgroundColor: palette.inactive.color2,
          },
        },
      },
      '&:active': {
        [`& .${switchClasses.switchBase}:not(.Mui-disabled) + .${switchClasses.track}`]: {
          boxShadow: stateBoxShadow,
        },
      },
    };
  }
);

export const ToggleSwitch = ({
  color,
  size,
  defaultChecked,
  disabled,
  checked,
  withCheckIcon,
  onChange,
  onFocusVisible,
  onBlur,
  testId,
  ...otherProps
}: ToggleSwitchProps) => {
  const isControlled = typeof checked !== 'undefined';

  // We need to track focus-visible and checked state internally because we need to apply styles to the parent component which doesn't have "Mui-focusVisible" class.
  const [isFocusVisible, setIsFocusVisible] = useState(false);
  const [mirroredChecked, setMirroredChecked] = useState(isControlled ? null : defaultChecked);
  const switchRoot = useRef<HTMLButtonElement>(null);

  const handleChange = useCallback<NonNullable<ToggleSwitchProps['onChange']>>(
    (e, ...props) => {
      if (!isControlled) {
        setMirroredChecked(e.target.checked);
      }
      if (onChange) {
        onChange(e, ...props);
      }
    },
    [onChange, isControlled]
  );

  const handleFocusVisible = useCallback<NonNullable<ToggleSwitchProps['onFocusVisible']>>(
    (e, ...props) => {
      setIsFocusVisible(true);
      if (onFocusVisible) {
        onFocusVisible(e, ...props);
      }
    },
    [onFocusVisible]
  );

  const handleBlur = useCallback<NonNullable<ToggleSwitchProps['onBlur']>>(
    (e, ...props) => {
      setIsFocusVisible(false);
      if (onBlur) {
        onBlur(e, ...props);
      }
    },
    [onBlur]
  );

  const internalChecked = useMemo(
    () => (isControlled ? checked : mirroredChecked),
    [isControlled, checked, mirroredChecked]
  );

  const isIndeterminate = typeof internalChecked !== 'boolean';

  useLayoutEffect(() => {
    if (switchRoot.current) {
      isIndeterminate
        ? switchRoot.current.classList.add(indeterminateClass)
        : switchRoot.current.classList.remove(indeterminateClass);
    }
  }, [isIndeterminate, switchRoot]);

  return (
    <StyledSwitch
      size="medium"
      disabled={disabled}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocusVisible={handleFocusVisible}
      {...testProps(testId, 'toggle-switch')}
      {...otherProps}
      $color={color}
      $size={size}
      $withCheckIcon={withCheckIcon}
      $internalChecked={internalChecked}
      $internalFocusVisible={isFocusVisible}
      ref={switchRoot}
    />
  );
};
