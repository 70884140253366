import React from 'react';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import { ContractFormData } from '../../types';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import FormDecimalNumberField, { FormDecimalNumberFieldType } from '../../../../forms/fields/FormDecimalNumberField';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormDecimalNumberFieldType<ContractFormData>;

type PriceContractFormEntryProps = ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>;

const PriceContractFormEntry = (options: PriceContractFormEntryProps) => {
  const formatContractCurrency = useFormatContractCurrency();

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        ...options.componentProps,
        formatter: formatContractCurrency,
      }}
      writeComponent={FormDecimalNumberField}
      writeComponentProps={{
        emptyValue: null,
        ...options.writeComponentProps,
      }}
    />
  );
};

export default PriceContractFormEntry;
