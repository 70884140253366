import { Stack } from '@mui/material';
import styled from 'styled-components';

export const ShowOnRowHover = styled(Stack)({
  opacity: 0,
  maxHeight: 0,
  transition: 'all 200ms ease-in-out',
  overflow: 'hidden',

  '.Mui-hovered &': {
    opacity: 1,
    maxHeight: '30px',
  },
});
