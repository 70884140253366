export type ContractCounts = {
  existing: number;
  purchaseInProgress: number;
};

export type VendorData = {
  contractCounts: ContractCounts;
  productNames: string[];
};

export enum VendorListFilter {
  ALL_VENDORS = 'ALL_VENDORS',
  MY_VENDROS = 'MY_VENDROS',
}
