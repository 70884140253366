import { FC, ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { IconWrapper } from '@verticeone/design-system/src';
import { KeyboardArrowRightTwoTone } from '@mui/icons-material';

type PageCardProps = {
  children: ReactNode;
  path: string;
};

const StyledPageCard = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.core.color3}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  position: 'relative',
}));

export const PageCard: FC<PageCardProps> = ({ children, path }) => {
  return (
    <Link style={{ textDecoration: 'none', color: 'unset' }} to={path}>
      <StyledPageCard>
        <Stack top={16} right={16} position="absolute">
          <IconWrapper icon={KeyboardArrowRightTwoTone} />
        </Stack>
        <Stack gap={2} alignItems="flex-start">
          {children}
        </Stack>
      </StyledPageCard>
    </Link>
  );
};
