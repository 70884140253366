import { formatCurrency } from '@verticeone/utils/formatting';

export const getCost = (contractLength: string | number, annualCost: string | number) =>
  (+contractLength * +annualCost) / 12;

export const makePriceValue = (
  contractLength: string,
  price: string,
  currency: string,
  locale: string,
  maximumFractionDigits = 2
) => contractLength && formatCurrency(getCost(contractLength, price), { currency, locale, maximumFractionDigits });
