import { styled } from '@mui/material';
import { Tooltip } from '@verticeone/design-system/src';
import { TFunction } from 'i18next/typescript/t';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { DeadlineOrigin } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type DeadlineOriginTooltipProps = {
  origin?: DeadlineOrigin;
};

export const TooltipContent = styled(Info)(({ theme: { palette } }) => ({
  opacity: 0,
  color: palette.text.color1,
  transition: 'opacity 0.15s ease-in-out',
  position: 'relative',
  top: '-2px',
}));

const getSourceLabel = (origin: DeadlineOrigin | undefined, t: TFunction<'translation'>) => {
  switch (origin) {
    case 'USERDEADLINE':
      return t('CONTRACTS.GRID.DEADLINE_HINTS.PURCHASE_TARGET_SIGN_DATE');
    case 'FOLLOWINGVERSIONUSERDEADLINE':
      return t('CONTRACTS.GRID.DEADLINE_HINTS.RENEWAL_TARGET_SIGN_DATE');
    case 'RENEWALDATE':
      return t('CONTRACTS.GRID.DEADLINE_HINTS.RENEWAL_DATE');
    case 'AUTORENEWALDEADLINE':
      return t('CONTRACTS.GRID.DEADLINE_HINTS.AUTO_RENEWAL_DATE');
  }
  return null;
};

export const DeadlineOriginTooltip = ({ origin }: DeadlineOriginTooltipProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip placement="top-end" size="S" noArrow minified content={getSourceLabel(origin, t)}>
      <TooltipContent />
    </Tooltip>
  );
};
