import { useMemo } from 'react';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useLoggedUser } from '@verticeone/auth/src';

import { useGetRequestService } from '../../../hooks/useGetRequestService';
import { widgetDef as journalWidgetDef } from './Journal/widgetDef';
import { widgetDef as detailsWidgetDef } from './Details/widgetDef';
import { widgetDef as documentsWidgetDef } from './Documents/widgetDef';
import { widgetDef as activeTasksWidgetDef } from './Tasks/widgetDef';
import { widgetDef as linkedContractsWidgetDef } from './LinkedContracts/widgetDef';
import { baselineOfferWidgetDef, finalOfferWidgetDef, negotiatedOffersWidgetDef } from './Offer/widgetDef';
import { getDataForWidget, hasDataForWidget, matchWidgetRef } from './shared/utils';
import { PredefinedWidgetDef, WidgetProps } from './shared/types';

const coreWidgets: PredefinedWidgetDef[] = [
  journalWidgetDef,
  detailsWidgetDef,
  documentsWidgetDef,
  linkedContractsWidgetDef,
  baselineOfferWidgetDef,
  negotiatedOffersWidgetDef,
  finalOfferWidgetDef,
  activeTasksWidgetDef,
];

// Default widgets to be displayed in the request detail page when widgets are not defined in the service definition
const defaultWidgets: PredefinedWidget[] = [
  { widgetDef: activeTasksWidgetDef },
  { widgetDef: documentsWidgetDef },
  { widgetDef: journalWidgetDef },
  { widgetDef: detailsWidgetDef },
];

type PredefinedWidget = {
  widgetDef: PredefinedWidgetDef;
  widgetData?: WidgetProps['widgetData'];
};

export const useRequestWidgets = (
  request?: Request,
  additionalPredefinedWidgets?: PredefinedWidgetDef[]
): PredefinedWidget[] | undefined => {
  const { service, isLoading } = useGetRequestService(request);
  const { roles } = useLoggedUser();

  const allWidgets = useMemo(
    () => [...coreWidgets, ...(additionalPredefinedWidgets || [])],
    [additionalPredefinedWidgets]
  );
  const resolvedWidgets = useMemo(() => {
    return isLoading || !request
      ? undefined
      : (service?.definition?.Widgets || [])
          ?.map((widgetRef: string) => {
            return allWidgets.find((widgetDef) => matchWidgetRef(widgetDef.urnSuffix, widgetRef));
          })
          ?.filter((widgetDef: PredefinedWidgetDef) => widgetDef !== undefined)
          ?.filter(
            ({ restrictedRoles }: PredefinedWidgetDef) => !restrictedRoles?.some((role) => roles?.includes(role))
          )
          ?.filter(
            (widgetDef: PredefinedWidgetDef) =>
              widgetDef.renderWhenMissingData || hasDataForWidget(request, widgetDef.urnSuffix)
          )
          ?.map((widgetDef: PredefinedWidgetDef) => ({
            widgetDef,
            widgetData: getDataForWidget(request, widgetDef.urnSuffix),
          }))
          ?.filter(
            ({ widgetDef, widgetData }: { widgetDef: PredefinedWidgetDef; widgetData: Record<string, any> }) =>
              widgetDef.renderPredicate?.(widgetData, request) ?? true
          ) || [];
  }, [allWidgets, isLoading, request, service?.definition?.Widgets, roles]);

  const effectiveWidgets = useMemo(() => {
    return resolvedWidgets?.length ? resolvedWidgets : defaultWidgets;
  }, [resolvedWidgets]);

  return effectiveWidgets;
};
