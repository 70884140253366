import { DesignSystemSize } from '../../types';
import { SizeParams } from './types';

export const SIZE_DEFINITION: Record<DesignSystemSize, SizeParams> = {
  XL: {
    shortcutsPadding: [10, 12, 10, 10],
    shortcutsGap: '20px',
    defaultPadding: 10,
    todaySize: 10,
    todayBottomOffset: 6,
    weekContainerMargin: 6,
    calendarDaySize: {
      width: '60px',
      height: '56px',
    },
  },
  L: {
    shortcutsPadding: [9, 11, 9, 9],
    shortcutsGap: '18px',
    defaultPadding: 9,
    todaySize: 9,
    todayBottomOffset: 5,
    weekContainerMargin: 6,
    calendarDaySize: {
      width: '54px',
      height: '50px',
    },
  },
  M: {
    shortcutsPadding: [8, 10, 8, 8],
    shortcutsGap: '16px',
    defaultPadding: 8,
    todaySize: 8,
    todayBottomOffset: 4,
    weekContainerMargin: 4,
    calendarDaySize: {
      width: '48px',
      height: '44px',
    },
  },
  S: {
    shortcutsPadding: [7, 9, 7, 7],
    shortcutsGap: '14px',
    defaultPadding: 7,
    todaySize: 7,
    todayBottomOffset: 3,
    weekContainerMargin: 4,
    calendarDaySize: {
      width: '42px',
      height: '38px',
    },
  },
  XS: {
    shortcutsPadding: [6, 8, 6, 6],
    shortcutsGap: '12px',
    defaultPadding: 6,
    todaySize: 6,
    todayBottomOffset: 2,
    weekContainerMargin: 2,
    calendarDaySize: {
      width: '36px',
      height: '32px',
    },
  },
  XXS: {
    shortcutsPadding: [5, 7, 5, 5],
    shortcutsGap: '10px',
    defaultPadding: 5,
    todaySize: 5,
    todayBottomOffset: 1,
    weekContainerMargin: 2,
    calendarDaySize: {
      width: '30px',
      height: '26px',
    },
  },
};
