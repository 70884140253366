import { FC, useEffect, useId, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import FormSelectField2 from '../../../../../forms/fields/FormSelectField2';
import { TextField } from '@verticeone/design-system/src';
import { TextFieldArea } from '@verticeone/design-system/src';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import { EditUserTaskFormData } from './schema';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { GroupOption, Option, useAssignmentOptions } from './assignmentOptions';

type EditUserTaskFormProps = {
  onDirty: () => void;
  onAddUser: () => void;
  allowContractOwnerAssignment?: boolean;
};

export const EditUserTaskForm: FC<EditUserTaskFormProps> = ({ onDirty, onAddUser, allowContractOwnerAssignment }) => {
  const { t } = useTranslation();
  const id = useId();

  const { register, formState } = useFormContext<EditUserTaskFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const [sortAssignees, setSortAssignees] = useState(true);
  const currentAssignees = useWatch<EditUserTaskFormData, 'assignees'>({ name: 'assignees' });
  const assignmentOptions = useAssignmentOptions(currentAssignees, sortAssignees, allowContractOwnerAssignment);

  return (
    <Stack gap={4} direction={'column'} width={'100%'}>
      <Stack gap={1}>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.NAME')}
          size="XS"
          htmlFor={`${id}-name`}
        />
        <TextField
          {...register('name')}
          id={`${id}-name`}
          variant="outlined"
          size="S"
          fullWidth
          autoComplete="off"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        />
      </Stack>
      <Stack gap={1}>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DESCRIPTION')}
          size="XS"
          htmlFor={`${id}-description`}
        />
        <TextFieldArea
          {...register('description')}
          id={`${id}-description`}
          variant="outline"
          size="S"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          autoComplete="off"
          maxRows={6}
          style={{ resize: 'none' }}
        />
      </Stack>
      <Stack gap={1}>
        <TextFieldCaption
          id={`${id}-assignees`}
          label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.ASSIGNEES')}
          size="XS"
          htmlFor="assignees"
        />
        <FormSelectField2<EditUserTaskFormData, Option, true, GroupOption>
          isMulti
          id={`${id}-assignees`}
          name="assignees"
          variant="outlined"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          maxMultiChips={1}
          options={assignmentOptions}
          onMenuOpen={() => setSortAssignees(false)}
          onMenuClose={() => setSortAssignees(true)}
          footer={
            <Stack paddingBottom={0.5} alignItems="flex-start">
              <Button variant="plain" size="S" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={onAddUser}>
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.ADD_USER')}
              </Button>
            </Stack>
          }
          unboxValue={(value) => value.map((val) => val.value)}
          boxValue={(value) =>
            assignmentOptions
              .map((v) => v.options)
              .flat()
              .filter((val) => (value as string[]).includes(val.value))
          }
        />
      </Stack>
    </Stack>
  );
};
