import { useCallback } from 'react';
import { UpdateOptionsFn } from '../types';
import { mergeOptions } from '../utils/optionsUtils';

const LEFT_OFFSET = 96;
const RIGHT_OFFSET = 24;

export const useXAxisOffset = (leftOffset?: number, rightOffset?: number) => {
  const usedLeftOffset = leftOffset ?? LEFT_OFFSET;
  const usedRightOffset = rightOffset ?? RIGHT_OFFSET;

  const onRender = useCallback(
    (event: any) => {
      const renderedChart = event.target;
      const desiredWidth = renderedChart.plotWidth - usedLeftOffset - usedRightOffset;
      const currentWidth = renderedChart.xAxis[0].width;
      const options = renderedChart.userOptions;

      // Fix the x-axis width to show all values even though left offset is used
      if (desiredWidth !== currentWidth) {
        renderedChart.update({ xAxis: { ...options.xAxis, width: desiredWidth + 'px' } }, true, true, false);
      }
    },
    [usedLeftOffset, usedRightOffset]
  );

  return useCallback<UpdateOptionsFn>(
    (options) =>
      mergeOptions(
        {
          chart: {
            events: {
              render: onRender,
            },
          },
          xAxis: {
            left: usedLeftOffset,
          },
        },
        options
      ),
    [onRender, usedLeftOffset]
  );
};
