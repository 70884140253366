import { AuthType, useAuthentication } from '@verticeone/auth/src';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

export const useCanUseCustomViewsAndFilters = () => {
  const { isEnabled } = useFeatures();
  const { type: authType } = useAuthentication();

  return isEnabled(FEATURES.CONTRACTS_CUSTOMIZATION) && authType !== AuthType.ASSUME_ROLE;
};
