import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

type SignDateFormEntryProps = {
  width?: number;
};

export const SignDateFormEntry: FC<SignDateFormEntryProps> = ({ width = 12 }) => {
  const { t } = useTranslation();
  return (
    <TaskFormEntry<any, typeof FormDateField>
      name="signingDate"
      label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
      component={FormDateField}
      componentProps={{
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
      width={width}
    />
  );
};
