import { Stack } from '@mui/material';
import React, { useId } from 'react';
import { Text, TextFieldCaption } from '@verticeone/design-system/src';
import FormControl from '@mui/material/FormControl';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import LargeValue from '../LargeValue';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import { getAnnualTotalSavings, getAnnualTotalSavingsPercentage, getTotalSavings } from '../../../savings/computed';
import useFormatSavingsPercentage from '../../../savings/useFormatSavingsPercentage';

const AnnualSavingsTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_ANNUAL_SAVINGS.LINE_1')}
      </Text>
      <Text size="S" variant="body-regular" color="text4" sx={{ whiteSpace: 'pre-line' }}>
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_ANNUAL_SAVINGS.LINE_2')}
      </Text>
    </Stack>
  );
};

const AdvancedAnnualSavingsContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const formatSavingsPercentage = useFormatSavingsPercentage();
  const id = useId();

  const totalSavings = useContractFormDataSelector(getTotalSavings);
  const annualTotalSavings = useContractFormDataSelector(getAnnualTotalSavings);
  const annualTotalSavingsPct = useContractFormDataSelector(getAnnualTotalSavingsPercentage);

  const isAnnualTotalSavingsVisible = useIsContractFieldVisible('parts.overview.totalSavings');

  if (!isAnnualTotalSavingsVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.ANNUAL_SAVINGS')}
          size="XS"
          tooltip={{ content: <AnnualSavingsTooltipContent />, maxWidth: '500px' }}
        />
        <LargeValue
          id={id}
          primaryValue={formatContractCurrency(annualTotalSavings)}
          primaryTestId="annualTotalSavingsValue"
          primaryColor={annualTotalSavings > 0 ? 'success' : 'text1'}
          chipValue={annualTotalSavingsPct > 0 ? formatSavingsPercentage(annualTotalSavingsPct) : undefined}
          chipTestId="annualTotalSavingsPercentage"
          secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalSavings) })}
          secondaryTestId="totalSavingsValue"
        />
      </Stack>
    </FormControl>
  );
};

export default AdvancedAnnualSavingsContractFormEntry;
