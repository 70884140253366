import { FC, useState } from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@verticeone/design-system/src';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import { ProcessDefinition, GatewayDefinition, FlowEdgeDefinition } from '../../definitionsTypes';
import { EditorDrawer } from './EditorDrawer';
import { EditGatewayForm } from './forms/EditGatewayForm/EditGatewayForm';
import { EditGatewayFormData, editGatewayFormSchema } from './forms/EditGatewayForm/schema';
import { GatewayVariablesDialog } from './GatewayVariablesDialog';
import { GatewayIconMap } from '../../components/icons/Icons';

const StyledGatewayIcon = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.color4,
  width: 32,
  height: 32,
  borderRadius: 4,
  padding: 4,
  color: theme.palette.neutral.color2,
  '& > svg:first-of-type': {
    width: '100%',
    height: '100%',
  },
}));

type EditGatewayDrawerProps = {
  isOpen: boolean;
  gateway?: GatewayDefinition;
  gatewayLeavingEdges: FlowEdgeDefinition[];
  onClose: VoidFunction;
  onSave: (args: { gateway: GatewayDefinition; edges: FlowEdgeDefinition[] }) => void;
  onDirty: VoidFunction;
  processDefinition?: ProcessDefinition;
};

const EditGatewayDrawerContent: FC<Omit<Required<EditGatewayDrawerProps>, 'isOpen'>> = ({
  gateway,
  onClose,
  onSave,
  onDirty,
  processDefinition,
  gatewayLeavingEdges,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });
  const { palette } = useTheme();

  const [isGatewayVariablesDialogOpen, setIsGatewayVariablesDialogOpen] = useState(false);

  const handlers = useForm<EditGatewayFormData>({
    resolver: zodResolver(editGatewayFormSchema),
    defaultValues: {
      gatewayType: gateway.gateway.gatewayType,
      edges: gatewayLeavingEdges.map(({ edge }) => {
        return {
          id: edge.id,
          name: edge.name || '',
        };
      }),
    },
  });

  const onSubmit = (data: EditGatewayFormData) => {
    const updatedEdgeDefinitions: FlowEdgeDefinition[] = [];

    for (const edge of data.edges) {
      const outgoingLeavingEdge = gatewayLeavingEdges.find((leavingEdge) => leavingEdge.edge.id === edge.id);

      if (!outgoingLeavingEdge) {
        continue;
      }

      updatedEdgeDefinitions.push({
        ...outgoingLeavingEdge,
        edge: {
          ...outgoingLeavingEdge.edge,
          name: edge.name,
        },
      });
    }

    onSave({
      gateway: {
        ...gateway,
        gateway: {
          ...gateway.gateway,
          gatewayType: data.gatewayType,
        },
      },
      edges: updatedEdgeDefinitions,
    });
    onClose();
  };

  const Icon = GatewayIconMap[handlers.watch('gatewayType')];

  return (
    <>
      <Stack direction="row" gap={2} alignItems="center" p={6}>
        <StyledGatewayIcon>
          <Icon />
        </StyledGatewayIcon>
        <Text variant="heading" size="S" color="text1">
          {`Gateway ${gateway.gateway.name || ''}`.trim()}
        </Text>
      </Stack>
      <FormProvider {...handlers}>
        <form style={{ width: '100%', height: '100%', maxHeight: '100%' }} onSubmit={handlers.handleSubmit(onSubmit)}>
          <Stack direction="column" justifyContent="space-between" height="100%" width="100%">
            <Stack
              direction="column"
              px={6}
              pb={6}
              justifyContent="space-between"
              overflow="auto"
              height="100%"
              width="100%"
            >
              <EditGatewayForm onDirty={onDirty} />
              <Button
                onClick={() => setIsGatewayVariablesDialogOpen(true)}
                variant="outline"
                color="secondary"
                size="S"
                fullWidth
              >
                {t('EDIT_GATEWAY.DIALOG.AVAILABLE_VARIABLES')}
              </Button>
            </Stack>
            <Stack gap={1} p={6} direction="row" borderTop={`1px solid ${palette.core.color3}`}>
              <Button onClick={onClose} variant="outline" color="secondary" size="S" fullWidth>
                {t('ACTIONS.DISCARD')}
              </Button>
              <Button variant="solid" color="secondary" size="S" fullWidth type="submit">
                {t('ACTIONS.APPLY')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      {isGatewayVariablesDialogOpen && (
        <GatewayVariablesDialog
          processDefinition={processDefinition}
          isOpened={isGatewayVariablesDialogOpen}
          onClose={() => setIsGatewayVariablesDialogOpen(false)}
          gateway={gateway}
        />
      )}
    </>
  );
};

export const EditGatewayDrawer: FC<EditGatewayDrawerProps> = ({
  isOpen,
  gateway,
  onClose,
  onSave,
  onDirty,
  processDefinition,
  gatewayLeavingEdges,
}) => {
  return (
    <EditorDrawer open={isOpen}>
      {gateway && processDefinition && (
        <EditGatewayDrawerContent
          gatewayLeavingEdges={gatewayLeavingEdges}
          onDirty={onDirty}
          onSave={onSave}
          onClose={onClose}
          gateway={gateway}
          key={gateway.gateway.id}
          processDefinition={processDefinition}
        />
      )}
    </EditorDrawer>
  );
};
