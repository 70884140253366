import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { ChangeEvent } from 'react';
import { styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)`
  & .MuiInput-input {
    padding-left: 12px;
  }
  & .MuiInput-root {
    display: flex;
    align-items: stretch;
  }
`;

const EditableLicensesCell = ({ id, value, field }: GridCellParams) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const digitsOnly = /^[0-9]*$/.test(newValue);
    if (digitsOnly) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      apiRef.current.setEditCellValue({ id, field, value: newValue ? parseInt(newValue, 10) : null });
    }
  };

  return (
    <StyledTextField
      value={value ?? ''}
      onChange={handleValueChange}
      fullWidth
      variant="standard"
      placeholder={t('INTAKE_FORM.PRODUCTS_TABLE.LICENSES')}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

export default EditableLicensesCell;
