import { z } from 'zod';
import { formDataProductItemSchema, vendorSchema } from '../shared/schemas';

export const zodSchema = z.object({
  vendor: vendorSchema,
  products: z.array(formDataProductItemSchema),
  targetSignDate: z.string().optional().nullish(),
  additionalNotes: z.string().optional().nullish(),
});

export type FormData = z.infer<typeof zodSchema>;
