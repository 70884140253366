import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import { useMonitoringLatestQuery } from '../../../../../dataSources/useMonitoringLatestQuery';
import { Graph, GraphBreakdownHeader, GraphSeries } from '../../../components/Graph';
import { createSeriesConfig } from './utils';

const useOptimizationSeries = () => {
  const { palette } = useTheme();
  const { data, isFetching } = useMonitoringLatestQuery('Compute');
  const { t } = useTranslation(undefined, {
    keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.GRAPH`,
  });

  return useMemo(
    () => ({
      isFetching,
      resourceCost: data?.ec2_total_cost ?? 0,
      series: [
        createSeriesConfig({
          id: 'RI_UTILIZATION_COVERAGE',
          value: data?.ec2_ri_cost_percentage,
          color: palette.visualization.monochromatic.tertiary['80'],
          name: t('RI_UTILIZATION_COVERAGE'),
          hoverColor: palette.visualization.monochromatic.tertiary['50'],
          labelColor: palette.visualization.monochromatic.tertiary['30'],
        }),
        createSeriesConfig({
          id: 'SAVINGS_PLANS_COVERAGE',
          value: data?.ec2_sp_usage_cost_percentage,
          color: palette.visualization.monochromatic.primary['80'],
          name: t('SAVINGS_PLANS_COVERAGE'),
          labelColor: palette.visualization.monochromatic.primary['30'],
          hoverColor: palette.visualization.monochromatic.primary['50'],
        }),
        createSeriesConfig({
          id: 'ON_DEMAND_USAGE',
          value: data?.ec2_on_demand_cost_percentage,
          color: getColorPattern(palette.text.color3),
          name: t('ON_DEMAND_USAGE'),
          labelColor: palette.text.color1,
          hoverColor: getColorPattern(palette.text.color4),
        }),
      ] as GraphSeries,
    }),
    [data, isFetching, palette, t]
  );
};

const GraphContainer = () => {
  const { series, isFetching, resourceCost } = useOptimizationSeries();

  if (isFetching) {
    return null;
  }

  return (
    <>
      <GraphBreakdownHeader serviceName="EC2" resourceCost={resourceCost} />
      <Graph series={series} />
    </>
  );
};

export default GraphContainer;
