import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';

import { Text } from '@verticeone/design-system/src';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { AccountingSetupCard } from './components';

const useCards = () => {
  const { isEnabled } = useFeatures();

  return [
    { component: <AccountingSetupCard id="netsuite" />, enabled: isEnabled(FEATURES.INTEGRATION_NETSUITE) },
    { component: <AccountingSetupCard id="quickbooks" />, enabled: isEnabled(FEATURES.INTEGRATION_QUICKBOOKS) },
    { component: <AccountingSetupCard id="msDynamics" />, enabled: isEnabled(FEATURES.INTEGRATION_MS_DYNAMICS) },
    { component: <AccountingSetupCard id="xero" />, enabled: isEnabled(FEATURES.INTEGRATION_XERO) },
  ]
    .filter(({ enabled }) => enabled)
    .map(({ component }) => component);
};

export const Accounting = () => {
  const { getFeature } = useFeatures();
  const mergeIntegrationFeature = getFeature(FEATURES.INTEGRATION_MERGE);
  const integrationsFeature = getFeature(FEATURES.INTEGRATIONS);
  const { t } = useTranslation();
  const cards = useCards();

  if (
    !mergeIntegrationFeature?.enabled ||
    !mergeIntegrationFeature?.properties?.accounting ||
    !integrationsFeature?.enabled ||
    !cards.length
  ) {
    return null;
  }

  return (
    <Stack direction="column" gap="24px">
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.ACCOUNTING.HEADER')}
      </Text>
      <Grid container spacing={3} direction="row">
        {cards.map((card, index) => (
          <Grid container item xs={4} sx={{ minWidth: '400px' }} key={index}>
            {card}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
