import { PropsWithChildren, WheelEventHandler, Ref, useState, forwardRef } from 'react';
import styled from '@mui/material/styles/styled';
import { Stack, StackProps } from '@mui/material';

const CommentsContainerBase = styled(Stack)(({ theme }) => ({
  height: '100%',
  minHeight: theme.spacing(40),
  maxHeight: '100%',
  overflowY: 'auto',
  flex: '1 1 0%',
  position: 'relative',
}));

const ShadowBase = styled('div')<{ $visible: boolean }>(({ theme, $visible }) => ({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)',
  position: 'sticky',
  zIndex: 1,
  minHeight: theme.spacing(14),
  opacity: $visible ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
  width: '100%',
  pointerEvents: 'none',
}));

const ShadowTop = styled(ShadowBase)(({ theme }) => ({
  background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)',
  top: 0,
  marginBottom: theme.spacing(-14),
}));

const ShadowBottom = styled(ShadowBase)(({ theme }) => ({
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)',
  bottom: 0,
  marginTop: theme.spacing(-14),
}));

export type CommentsContainerProps = StackProps & {
  newMessagesCount?: number;
  onNewMessagesChipClick?: (containerRef: Ref<HTMLDivElement>) => void;
  onScroll?: WheelEventHandler<HTMLDivElement>;
  onScrollToEnd?: VoidFunction;
} & PropsWithChildren;

const CommentsContainer = forwardRef<HTMLDivElement, CommentsContainerProps>(
  ({ children, newMessagesCount, onNewMessagesChipClick, onScroll, onScrollToEnd, ...rest }, ref) => {
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);

    const onScrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
      setScrollTop(event.currentTarget.scrollTop);
      setScrollHeight(event.currentTarget.scrollHeight);
      setClientHeight(event.currentTarget.clientHeight);

      if (clientHeight === scrollHeight - scrollTop) {
        onScrollToEnd?.();
      }

      onScroll?.(event);
    };

    const getVisibleSides = (): { top: boolean; bottom: boolean } => {
      const isBottom = clientHeight === scrollHeight - scrollTop;
      const isTop = scrollTop === 0;
      const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

      return {
        top: (isBottom || isBetween) && !(isTop && isBottom),
        bottom: (isTop || isBetween) && !(isTop && isBottom),
      };
    };

    return (
      <CommentsContainerBase ref={ref} onScroll={onScrollHandler} {...rest}>
        <ShadowTop $visible={getVisibleSides().top} />
        {children}
        <ShadowBottom $visible={getVisibleSides().bottom} />
      </CommentsContainerBase>
    );
  }
);

export default CommentsContainer;
