import React, { Children, useMemo, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useBaseDropdown } from './BaseDropdownProvider';
import { Button } from '../../../Button';
import BaseItem from '../BaseItem';
import { useFilterGroupData } from '../FilterProvider';
import i18n from '../../../../translate';

type BaseDropdownListProps = {
  children: React.ReactNode;
  defaultShowItemCount?: number;
  showAll?: boolean;
};

type StyledDropdownListProps = {
  $expanded: boolean;
  $maxHeight: string;
  $level: number;
};

const StyledDropdownList = styled(Stack)<StyledDropdownListProps>(({ theme, $expanded, $maxHeight, $level }) => ({
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.core[$level >= 3 ? 'color4' : 'color3'],
  transition: 'max-height .5s ease-in-out',
  maxHeight: $expanded ? $maxHeight : 0,
  overflow: 'auto',
}));

const BaseDropdownList = ({ children, defaultShowItemCount = 5, showAll = false }: BaseDropdownListProps) => {
  const { t } = useTranslation(undefined, { i18n });
  const { level } = useFilterGroupData();
  const { listHeight, expanded, dropdownListRef } = useBaseDropdown();
  const [limit, setLimit] = useState(defaultShowItemCount);
  const childrenArray = useMemo(() => Children.toArray(children), [children]);

  const handleExpand = () => {
    setLimit((prev) => (prev === defaultShowItemCount ? childrenArray.length : defaultShowItemCount));
  };

  const showExpand = childrenArray.length > defaultShowItemCount && childrenArray.length !== limit;

  return (
    <StyledDropdownList $maxHeight={listHeight} $expanded={expanded} $level={level}>
      <Stack gap="1px" ref={dropdownListRef}>
        {!showAll ? (
          <>
            {Children.toArray(children).slice(0, limit)}
            {showExpand && (
              <BaseItem>
                <Button size="S" variant="plain" sx={{ justifyContent: 'left' }} onClick={handleExpand}>
                  {t('FILTER.BUTTONS.SHOW_ALL')}
                </Button>
              </BaseItem>
            )}
          </>
        ) : (
          children
        )}
      </Stack>
    </StyledDropdownList>
  );
};

export default BaseDropdownList;
