import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import FilesDropArea, { FileWithPath } from './FilesDropArea';

type FormFilesDropAreaProps = {
  name: string;
  required?: boolean;
};

const FormFilesDropArea = ({ name, required }: FormFilesDropAreaProps) => {
  const {
    field: { onChange, value },
  } = useController({ name, rules: { required } });

  const handleFile = useCallback(
    (file: FileWithPath) => {
      const fileAlreadyPresent = value.some((f: FileWithPath) => f.name === file.name);
      if (!fileAlreadyPresent) {
        onChange([...value, file]);
      }
    },
    [value, onChange]
  );

  return <FilesDropArea handleFile={handleFile} />;
};

export default FormFilesDropArea;
