import { FormControl, styled, TextField } from '@mui/material';
import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)`
  & .MuiInput-input {
    padding-left: 12px;
  }
  & .MuiInput-root {
    display: flex;
    align-items: stretch;
  }
`;

const DescriptionCellEditable = ({ id, value, field }: GridCellParams<any, string>) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    void apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <FormControl fullWidth>
      <StyledTextField
        value={value ?? ''}
        onChange={handleValueChange}
        fullWidth
        variant="standard"
        placeholder={t('CONCESSIONS_LIST.PLACEHOLDER.DESCRIPTION')}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </FormControl>
  );
};

export default DescriptionCellEditable;
