import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system/src';

import { useShoppingCart } from '../../../../providers/ShoppingCartProvider';

type AddToCartButtonProps = {
  id: string;
  tKey: string;
};

const RemoveFromCartButton = ({ id, tKey }: AddToCartButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const { removeFromCart } = useShoppingCart();

  return <Button onClick={() => removeFromCart(id)}>{t('ACTIONS.REMOVE')}</Button>;
};

export default RemoveFromCartButton;
