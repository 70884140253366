import React, { ReactElement, ReactNode } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import clsx from 'clsx';
import styles from './WizardStepTemplate.module.scss';

interface WizardStepTemplateProps {
  headerImage?: ReactNode;
  header?: ReactNode;
  content: ReactNode;
  buttons: ReactElement[];
  scrollable?: boolean;
}

const WizardStepTemplate = ({ headerImage, header, content, buttons, scrollable = true }: WizardStepTemplateProps) => (
  <>
    {headerImage && <Box className={styles.image}>{headerImage}</Box>}
    {header && <Box className={styles.header}>{header}</Box>}
    {header && <Divider />}
    <Box className={clsx(styles.content, { [styles['content--scrollable']]: scrollable })}>{content}</Box>
    {!headerImage && <Divider />}
    <Stack className={styles.buttonbar} direction="row" gap="8px">
      {buttons.map((button: ReactElement, index) =>
        button ? React.cloneElement(button, { key: index, fullWidth: true }) : null
      )}
    </Stack>
  </>
);

export default WizardStepTemplate;
