const isValidDateFormat = (dateString?: string | null): boolean => {
  if (!dateString) return true;
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
};

export const checkDateFormatAndWarn = (date: string | null | undefined, dateType: string) => {
  if (!isValidDateFormat(date)) {
    console.warn(`Invalid date format for '${dateType}'. Expected 'YYYY-MM-DD' or null, got '${date}'.`);
  }
};
