import LargeValue from '../LargeValue';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';

export type AnnualCostBaselineViewOnlyFieldProps = {
  id: string;
};

export const AnnualCostBaselineViewOnlyField = ({ id }: AnnualCostBaselineViewOnlyFieldProps) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();

  const annualCostWithoutVertice = useWatch<ContractFormData>({ name: 'parts.overview.annualCostBaseline' });
  const totalCostValueWithoutVertice = useWatch<ContractFormData>({
    name: 'parts.overview.totalCostValueBaseline',
  });

  return (
    <LargeValue
      id={id}
      primaryValue={formatContractCurrency(annualCostWithoutVertice)}
      primaryTestId={'annualCostWithoutVerticeValue'}
      secondaryValue={
        totalCostValueWithoutVertice
          ? t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalCostValueWithoutVertice) })
          : undefined
      }
      secondaryTestId={'totalCostValueWithoutVerticeValue'}
    />
  );
};
