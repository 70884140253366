import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RadioGroup, Stack } from '@mui/material';
import { RadioSwitch } from '@verticeone/design-system/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { Text } from '@verticeone/design-system/src';
import { useController } from 'react-hook-form';
import { FormData } from './schema';
import { useTaskContext } from '../../../TaskContext';
import { TaskFormControlLabel } from '../shared/formFields/TaskFormControlLabel';

const SaaSApprovalSimpleForm = () => {
  const { t } = useTranslation();
  const {
    field: { ref, value, ...field },
  } = useController<FormData>({ name: 'result' });
  const { taskOverview } = useTaskContext();

  const handleChange = (newValue: string) => field.onChange(newValue);

  return (
    <Stack gap={6}>
      <Stack sx={{ wordWrap: 'break-word', width: '100%', alignItems: 'center' }}>
        <Text variant="body-regular" size="M">
          <Trans
            i18nKey={'INTELLIGENT_WORKFLOWS.TASK_MODAL.WHAT_IS_THE_FINAL_DECISION'}
            components={{ bold: <b /> }}
            values={{ taskName: taskOverview?.task.name }}
          />
        </Text>
      </Stack>
      <RadioGroup ref={ref} value={value} onChange={(_event, newValue) => handleChange(newValue)}>
        <Stack direction="row" gap={2} alignItems="center">
          <TaskFormControlLabel
            control={<RadioSwitch value="OK" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
            label={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.APPROVED')}
          />
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

export default SaaSApprovalSimpleForm;
