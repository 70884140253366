import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';

export const GOOGLE_INTEGRATION_ID = 'Provider:Google:Workspace';

export const STEPS = {
  START: 'start',
  DATA_FIDELITY: 'data_fidelity',
  SELECT_DATA_SOURCE: 'select_data_source',
  ALLOW_ACCESS: 'allow_access',
  CHECK_CONNECTION: 'check_connection',
  CONNECTION_ERROR: 'connection_error',
  CONNECTION_SUCCESS: 'connection_success',
};

export type ConnectionFinishedStepProps = StepProps & {
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
  samlProviderTargetState: boolean;
};

export type ConnectionCheckStepsProps = StepProps & {
  testingInProgress: boolean;
  connectionWindow: Window | undefined;
  setTestingInProgress: (testingInProgress: boolean) => void;
};

export type StepProps = WizardStepProps & {
  accountId: string;
};
