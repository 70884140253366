import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import React, { FC, ReactNode } from 'react';
import { ContractFormData } from '../../types';
import useWatchContractFormField from '../../hooks/useWatchContractFormField';
import { GridRowParams } from '@mui/x-data-grid-pro';
import {
  FormCostModelProductsField,
  FormCostModelProductsFieldComponentType,
} from '../../../../forms/fields/FormCostModelProductsField';
import { ProductItem } from '../ProductList/types';

type Component = FormCostModelProductsFieldComponentType<ContractFormData>;

type CostModelProductsContractFormEntryProps = Omit<
  ContractFormEntryProps<Component, Component>,
  'component' | 'writeComponent' | 'componentProps'
> & {
  detailRowRenderer?: (params: GridRowParams) => ReactNode;
  productCellDecorator?: (row: ProductItem) => ReactNode;
};

export const CostModelProductsContractFormEntry: FC<CostModelProductsContractFormEntryProps> = ({
  detailRowRenderer,
  productCellDecorator,
  ...props
}) => {
  const vendor = useWatchContractFormField('parts.contractual.vendor.vendorOption');
  const currency = useWatchContractFormField('parts.contractual.financial.baseCurrency');
  const rollingFrequency = useWatchContractFormField('parts.contractual.lifecycle.rollFrequency');

  const commonProps = {
    vendorId: vendor?.type === 'INLINE' ? undefined : vendor?.id,
    currency,
    rollingFrequency: rollingFrequency ?? 'NO',
  };
  return (
    <ContractFormEntry<Component, Component>
      {...props}
      component={FormCostModelProductsField}
      componentProps={{ ...commonProps, detailRowRenderer, productCellDecorator }} // Detail row and product cell decorator is visible only in view mode
      writeComponentProps={{ ...commonProps, editable: true }}
      writeComponent={FormCostModelProductsField}
    />
  );
};
