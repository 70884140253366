import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const contractApi = createApi({
  reducerPath: 'contractApi',
  baseQuery: retry(prepareBaseQuery(API_URLS.BASE_URLS.CONTRACTS), { maxRetries: 0 }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['Contracts'],
  endpoints: (builder) => ({}),
});
