import { PeriodCostChangeItem, PeriodCostComparisonData } from './types';
import { DATE_FORMAT } from '../constants';
import { eachDayOfInterval, format, parseISO } from 'date-fns';

import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { DataTableResult } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

export const getSortedTableData = (rawData: DataTableResult): PeriodCostChangeItem[] => {
  if (rawData?.__typename !== 'DataTableResult' || !rawData?.table) {
    return [];
  }

  const tableData = getTableData(rawData.table, {
    date: 'date',
    cost_period_one: 'costPeriodOne',
    cost_period_two: 'costPeriodTwo',
  }) as PeriodCostChangeItem[];

  return tableData.sort((a, b) => a.date - b.date);
};

export const prepareCostDataForPeriods = (
  data: PeriodCostChangeItem[] | undefined,
  periodOneStart: string,
  periodOneEnd: string,
  periodTwoStart: string,
  periodTwoEnd: string
): PeriodCostComparisonData => {
  if (!data) {
    return {
      dates: [],
      costs: [],
      previousCosts: [],
      previousDates: [],
    };
  }

  const createComputedDict = (graphData: PeriodCostChangeItem[], key: 'costPeriodOne' | 'costPeriodTwo') =>
    graphData.reduce((dict, { date, [key]: value }) => {
      dict[format(new Date(date!), DATE_FORMAT)] = value || 0;
      return dict;
    }, {} as Record<string, number>);

  const mapPeriodDatesToValues = (periodDates: Date[], computedDict: Record<string, number>) =>
    periodDates.map((date) => computedDict[format(date, DATE_FORMAT)] || 0);

  const generateZerosToAdd = (lengthDifference: number) =>
    lengthDifference > 0 ? new Array(lengthDifference).fill(0) : [];

  const periodOneDates = eachDayOfInterval({
    start: parseISO(periodOneStart),
    end: parseISO(periodOneEnd),
  });
  const periodTwoDates = eachDayOfInterval({ start: parseISO(periodTwoStart), end: parseISO(periodTwoEnd) });

  const computedPeriodOneDict = createComputedDict(data, 'costPeriodOne');
  const computedPeriodTwoDict = createComputedDict(data, 'costPeriodTwo');

  const costsForPeriodOne = mapPeriodDatesToValues(periodOneDates, computedPeriodOneDict);
  const costsForPeriodTwo = mapPeriodDatesToValues(periodTwoDates, computedPeriodTwoDict);

  const zerosToAdd = generateZerosToAdd(periodOneDates.length - periodTwoDates.length);

  return {
    dates: periodOneDates.map((date) => format(date, DATE_FORMAT)),
    costs: costsForPeriodOne,
    previousCosts: costsForPeriodTwo.slice(0, periodOneDates.length).concat(zerosToAdd),
    previousDates: periodTwoDates.map((date) => format(date, DATE_FORMAT)),
  };
};
