import React from 'react';
import { USER_ROLES } from '@vertice/core/src/constants/userRoles';
import styles from './NewContact.module.scss';
import { MenuItem, Select, Typography } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { NewContactFormType } from './NewContact';
import { useTranslation } from 'react-i18next';

type SelectConfig = {
  defaultValue: string;
  items: { value: string; label: string }[];
};

type RoleSelectProps = {
  id: string;
  register: UseFormRegister<NewContactFormType>;
};

export const RoleSelect = ({ id, register }: RoleSelectProps) => {
  const { t } = useTranslation();

  const config: SelectConfig = {
    defaultValue: USER_ROLES.user,
    items: [
      { value: USER_ROLES.admin, label: t('PREFERENCES.NEW_CONTACT.ROLES.ADMIN') },
      { value: USER_ROLES.powerUser, label: t('PREFERENCES.NEW_CONTACT.ROLES.POWER_USER') },
      { value: USER_ROLES.user, label: t('PREFERENCES.NEW_CONTACT.ROLES.USER') },
    ],
  };

  return (
    <Select
      defaultValue={config.defaultValue}
      className={styles['new-contact-input']}
      fullWidth
      {...register('userRole', { required: true })}
      id={id}
    >
      {config.items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Typography variant="body-regular-m">{item.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
