import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import DataGridPriceCell from '../../../../../../components/DataGridPriceCell/DataGridPriceCell';

const EditableAnnualCostCell = (params: GridCellParams) => {
  const { t } = useTranslation();

  return <DataGridPriceCell placeholder={t('INTAKE_FORM.PRODUCTS_TABLE.ANNUAL_COST')} {...params} />;
};

export default EditableAnnualCostCell;
