import { CardHeaderDescription, CardHeaderDescriptionProps } from '../Card/CardHeaderDescription';
import { useDrawer } from './DrawerContext';
import { Placeholder } from '../Placeholder';

export const DrawerHeaderDescription = ({ children, ...otherProps }: CardHeaderDescriptionProps) => {
  const { isLoading } = useDrawer();

  if (isLoading) {
    return <Placeholder width="calc(100% - 50px)" />;
  }

  return <CardHeaderDescription {...otherProps}>{children}</CardHeaderDescription>;
};
