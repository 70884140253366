import React, { useState, useCallback } from 'react';
import { Node } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { useSimpleDialogContext } from '@verticeone/design-system/src';
import { Definitions, TaskDefinition, GatewayDefinition, FlowEdgeDefinition } from '../../definitionsTypes';
import { updateEdgesInProcessDefinition, updateNodeInProcessDefinition } from '../../definitions/processDefinition';
import { EditUserTaskDrawer } from './EditUserTaskDrawer';
import { EditGatewayDrawer } from './EditGatewayDrawer';
import { isProcessDefinition } from '../../pocWorkflowSchema';
import { useWorkflowViewerState } from '../WorkflowViewer/useViewerState';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { RendererGatewayNode, RendererTaskNode } from '../WorkflowRenderer/types';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

type WorkflowEditorProps = {
  workflowDefinitions?: Definitions;
  serviceRef?: string;
  setDefinitions: (definitions: Definitions) => void;
};

const isNodeTaskNode = (node?: Node): node is RendererTaskNode => node?.data.kind === 'task';
const isNodeGatewayNode = (node?: Node): node is RendererGatewayNode => node?.data.kind === 'gateway';

export const WorkflowEditor = ({ workflowDefinitions, setDefinitions, serviceRef }: WorkflowEditorProps) => {
  const { t } = useTranslation();

  const [activeTaskNode, setActiveTaskNode] = useState<TaskDefinition | undefined>(undefined);
  const [activeGatewayNode, setActiveGatewayNode] = useState<GatewayDefinition | undefined>(undefined);

  const { getFeature } = useFeatures();
  const isGatewayEditingEnabled = getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.gatewayVariables;

  const { getConfirmation } = useSimpleDialogContext();
  const [hasChange, setHasChange] = useState(false);

  const processDefinition = workflowDefinitions?.definitions.find(isProcessDefinition);

  const editorConfig = {
    allowContractOwnerAssignment: !!serviceRef?.includes('service/saas/renewal'),
  };

  const activeGatewayLeavingEdges =
    activeGatewayNode && processDefinition
      ? (processDefinition.process.flow?.flow.edges ?? []).filter(
          ({ edge }) => edge.from === activeGatewayNode.gateway.id
        )
      : [];

  const handleNodeSelected = useCallback(
    (node?: Node) => {
      if (!processDefinition) {
        return;
      }

      const { tasks = [], gateways = [] } = processDefinition.process;

      if (isNodeTaskNode(node)) {
        setActiveGatewayNode(undefined);

        const taskId = node.data.id;
        const definitionTaskNode = tasks.find(({ task }) => task.id === taskId);

        if (definitionTaskNode?.task.taskType !== 'User') {
          setActiveTaskNode(undefined);
          return;
        }
        setActiveTaskNode(definitionTaskNode);
        setHasChange(false);
      }

      if (isNodeGatewayNode(node)) {
        setActiveTaskNode(undefined);

        if (!isGatewayEditingEnabled) {
          return;
        }

        const gatewayId = node.data.id;
        const definitionGatewayNode = gateways.find(({ gateway }) => gateway.id === gatewayId);

        if (!definitionGatewayNode) {
          setActiveGatewayNode(undefined);
          return;
        }

        setActiveGatewayNode(definitionGatewayNode);
        setHasChange(false);
      }
    },
    [processDefinition, isGatewayEditingEnabled]
  );

  const checkChangeSelectionAllowed = async () => {
    if (!activeTaskNode || !hasChange || !activeGatewayNode) {
      return true;
    }

    await getConfirmation({
      title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_TASK_CHANGES_DIALOG.HEADER'),
      okButton: {
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      },
      cancelButton: {
        hidden: true,
      },
    });

    return false;
  };

  const { unselectNodes } = useWorkflowViewerState({
    onNodeSelected: handleNodeSelected,
    checkChangeSelectionAllowed,
  });

  const unselectTaskNode = useCallback(() => {
    setActiveTaskNode(undefined);
    unselectNodes();
  }, [unselectNodes]);

  const unselectGatewayNode = useCallback(() => {
    setActiveGatewayNode(undefined);
    unselectNodes();
  }, [unselectNodes]);

  const handleSaveTaskDrawer = useCallback(
    (task: TaskDefinition | GatewayDefinition) => {
      if (!workflowDefinitions) {
        return;
      }

      const definition = updateNodeInProcessDefinition(workflowDefinitions, task);

      unselectTaskNode();
      setDefinitions(definition);
    },
    [workflowDefinitions, setDefinitions, unselectTaskNode]
  );

  const handleSaveGatewayDrawer = useCallback(
    ({ gateway, edges }: { gateway: GatewayDefinition; edges: FlowEdgeDefinition[] }) => {
      if (!workflowDefinitions) {
        return;
      }

      const definitionWithNewGateway = updateNodeInProcessDefinition(workflowDefinitions, gateway);
      const finalDefinition = updateEdgesInProcessDefinition(definitionWithNewGateway, edges);

      unselectGatewayNode();
      setDefinitions(finalDefinition);
    },
    [workflowDefinitions, setDefinitions, unselectGatewayNode]
  );

  return (
    <>
      <EditUserTaskDrawer
        isOpen={!!activeTaskNode}
        task={activeTaskNode}
        saveTask={handleSaveTaskDrawer}
        close={unselectTaskNode}
        onDirty={() => setHasChange(true)}
        editorConfig={editorConfig}
      />
      <EditGatewayDrawer
        onClose={unselectGatewayNode}
        gatewayLeavingEdges={activeGatewayLeavingEdges}
        isOpen={!!activeGatewayNode}
        gateway={activeGatewayNode}
        onSave={handleSaveGatewayDrawer}
        onDirty={() => setHasChange(true)}
        processDefinition={processDefinition}
      />
    </>
  );
};
