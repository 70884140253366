import { useTranslation } from 'react-i18next';
import type { GridColDef } from '@mui/x-data-grid-pro';

import { useTermFormatter, useFormatCost } from '../../utils';
import type { OfferingsData } from '../../../providers/OfferingsProvider';

export const useColumns = (tKey: string): Array<GridColDef<OfferingsData>> => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const formatTerm = useTermFormatter();
  const formatCost = useFormatCost();

  return [
    {
      field: 'instanceType',
      renderCell: ({ value }) => <span data-testid="ri">{value}</span>,
      headerName: t('COLUMNS.RI'),
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: t('COLUMNS.QUANTITY'),
      flex: 1,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'normalizedUnits',
      headerName: t('COLUMNS.NORMALIZED_UNITS'),
      flex: 1,
      minWidth: 196,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'term',
      headerName: t('COLUMNS.TERM'),
      flex: 1,
      renderCell: ({ value }) => formatTerm(value),
    },
    {
      field: 'offeringType',
      headerName: t('COLUMNS.OFFERING_TYPE'),
      flex: 1,
      minWidth: 148,
    },
    {
      field: 'offeringClass',
      headerName: t('COLUMNS.OFFERING_CLASS'),
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'onDemandCost',
      headerName: t('COLUMNS.ON_DEMAND_COST'),
      flex: 1,
      minWidth: 148,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value),
    },
    {
      field: 'riCost',
      headerName: t('COLUMNS.RI_COST'),
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value),
    },
    {
      field: 'expectedSavings',
      headerName: t('COLUMNS.EXPECTED_SAVINGS'),
      flex: 1,
      minWidth: 184,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value),
    },
  ];
};
