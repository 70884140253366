import { EllipsisTextCell } from './EllipsisTextCell';
import React, { useMemo } from 'react';
import { ContractEntityCategory } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useContractCategoryOptions } from '../../../hooks/useContractCategoryOptions';
import { keyBy } from 'lodash';

export type ContractCategoryCellProps = { value: ContractEntityCategory };

export const ContractCategoryCell = ({ value }: ContractCategoryCellProps) => {
  const contractCategoryOptions = useContractCategoryOptions();
  const contractCategoryMap = useMemo(() => keyBy(contractCategoryOptions, 'value'), [contractCategoryOptions]);

  return <EllipsisTextCell value={contractCategoryMap[value]?.label} />;
};
