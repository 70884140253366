import React from 'react';
import { styled } from '@mui/material';
import { Title } from '../StatsBody/Title';
import { TooltipInfoTrigger } from '@verticeone/design-system/src';
import { rgba } from 'polished';
import { Value } from '../StatsBody/Value';
import { LoadableTextComponent } from '../../LoadableTextComponent';
import { StatsTile, StatsTileProps } from './StatsTile';
import { DesignSystemColor } from '@verticeone/design-system/src';

const StyledSolidStatsTile = styled(StatsTile)<{ $color: DesignSystemColor }>(({ $color, theme }) => ({
  backgroundColor: theme.palette[$color].color2,
  [Title]: {
    color: theme.palette.global.transparency.white['80'],
  },
  [TooltipInfoTrigger]: {
    color: rgba(theme.palette.global.transparency.white['80'], 0.4),
  },
  [Value]: {
    color: theme.palette.global.color.white.main,
  },
  [LoadableTextComponent]: {
    backgroundColor: rgba(theme.palette.global.color.white.main, 0.3),
  },
}));

export type SolidStatsTileProps = StatsTileProps & {
  color?: DesignSystemColor;
};

const SolidStatsTile = ({ color = 'primary', ...props }: SolidStatsTileProps) => (
  <StyledSolidStatsTile $color={color} {...props} />
);
export default SolidStatsTile;
