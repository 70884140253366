import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { type TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

type DataColumns =
  | 'edp_month_start'
  | 'total_edp_discount'
  | 'edp_discount_cumm_spend'
  | 'zero_based_edp_discount_cumm_spend'
  | 'is_forecast';

const EDPSavingsQuery = graphql(`
  query EDPSavingsQuery($accountId: String!) {
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }
    ) {
      ... on DataTableResult {
        __typename
        table(
          columns: [
            "edp_month_start"
            "total_edp_discount"
            "edp_discount_cumm_spend"
            "zero_based_edp_discount_cumm_spend"
            "is_forecast"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export type SavingsData = {
  isSavingsDataLoading: boolean;
  months: string[];
  values: { id: string; data: Array<number | null>; type: string; lineWidth?: number; dashStyle?: string }[];
  usedCategories: string[];
};

export const useSavingsData = () => {
  const { accountId } = useAccountContext();
  const startDate = useMemo(() => dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'), []);
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['EDPSavings'],
    queryFn: () =>
      fetchCloudOptimization(EDPSavingsQuery, {
        accountId,
        startDate,
        checkCode: 'TOTAL_COST_DISCOUNT',
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      const isSavingsDataLoading = data.athenaViewQuery?.__typename === 'DeferredQueryResult';

      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        const result = (getTableData(data.athenaViewQuery.table as TableType) as Record<DataColumns, number>[]) ?? null;

        const totalSavings = result
          .filter((item) => !item.is_forecast)
          .map((item) => item.total_edp_discount)
          .slice(-1)[0];

        return {
          isSavingsDataLoading,
          months: result.map((item) => dayjs(item.edp_month_start).format()),
          totalSavings,
          values: [
            {
              id: 'actualSavings',
              data: result.map((item) => (item.is_forecast ? 0 : item.total_edp_discount)),
              type: 'column',
            },
            {
              id: 'cumulativeSavings',
              data: result.map((item) => (item.is_forecast ? 0 : item.zero_based_edp_discount_cumm_spend)),
              type: 'column',
            },
          ],
          usedCategories: ['actualSavings', 'cumulativeSavings'],
        };
      }

      return {
        isSavingsDataLoading,
        months: [],
        totalSavings: 0,
        values: [],
        usedCategories: [],
      };
    },
  });
};
