import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { keyBy, orderBy } from 'lodash';
import { ContractFormData } from '../../types';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, CustomContractFieldDropdownOption, false>;

type CustomDropdownFormEntryProps = ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent> & {
  items?: CustomContractFieldDropdownOption[];
};

const CustomDropdownFormEntry = ({ items, ...options }: CustomDropdownFormEntryProps) => {
  const { t } = useTranslation();
  const selectedValue = useWatch<ContractFormData>({ name: options.name }); // Selected value can be disabled -> we want to show it in the list as disabled option
  const enabledDropdownOptions = useMemo(
    () => items?.filter((item) => item.enabled || item.id === selectedValue),
    [items, selectedValue]
  );
  const dropdownOptionsMap = useMemo(() => keyBy(enabledDropdownOptions, 'id'), [enabledDropdownOptions]);
  const itemMap = keyBy(items, 'id');
  const isSelectedValueDisabled = itemMap[selectedValue] && !itemMap[selectedValue].enabled;

  const getDisabledLabel = (item: CustomContractFieldDropdownOption) =>
    `${item.label}${item.enabled ? '' : ` (${t('ENTITIES.CONTRACT_CUSTOMIZATION.DISABLED')})`}`;

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, CustomContractFieldDropdownOption, false>
    >
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (val?: string | null) => {
          const item = val ? itemMap[val] : undefined;
          return item ? getDisabledLabel(item) : '-';
        },
        textProps: {
          ...options.componentProps?.textProps,
          color: isSelectedValueDisabled ? 'text3' : 'text1',
          variant: 'body-regular',
          size: 'M',
        },
        ...options.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: orderBy(enabledDropdownOptions, 'order'),
        isClearable: true,
        isLoading: !enabledDropdownOptions,
        isOptionDisabled: (option) => !option.enabled,
        getOptionLabel: (item) => getDisabledLabel(item),
        getOptionValue: (item) => item.id,
        boxValue: (value) => (value ? dropdownOptionsMap[value as string] : undefined),
        unboxValue: (selectValue) => selectValue?.id,
      }}
    />
  );
};

export default CustomDropdownFormEntry;
