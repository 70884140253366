import React from 'react';
import { Grid } from '@mui/material';
import ComputeUsageCard from '../../../../modules/cloud/cards/ComputeUsageCard/ComputeUsageCard';
import EC2ProcessorUsageCard from '../../../../modules/cloud/cards/EC2ProcessorUsageCard/EC2ProcessorUsageCard';
import EC2OSUsageCard from '../../../../modules/cloud/cards/EC2OSUsageCard/EC2OSUsageCard';

const CloudInsightsUsageTab = () => (
  <Grid container rowSpacing={4} columnSpacing={4}>
    <Grid item sm={12}>
      <ComputeUsageCard />
    </Grid>
    <Grid item xs={12} lg={6} display="flex">
      <EC2ProcessorUsageCard sx={{ flex: 1 }} />
    </Grid>
    <Grid item xs={12} lg={6} display="flex">
      <EC2OSUsageCard sx={{ flex: 1 }} />
    </Grid>
  </Grid>
);

export default CloudInsightsUsageTab;
