import { createApi, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ListVendorsApiResponse, ListVendorsApiArg } from '../openapi/codegen/vendorAPI';
import { API_URLS, VENDORS_LIST_FILTERS_LIMIT } from '../constants';
import prepareBaseQuery from '../baseQuery';

const chunkBySize = (inputArray: string[], chunkSize: number): string[][] => {
  const result = inputArray.reduce((acc: string[][], item: string, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    acc[chunkIndex].push(item);

    return acc;
  }, []);

  return result;
};

export const vendorApi = createApi({
  reducerPath: 'vendorApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.PRODUCTS),
  endpoints: (builder) => ({
    listVendorsOptimised: builder.query<ListVendorsApiResponse, ListVendorsApiArg>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const allFilters = _arg.filters;

        const parsedFilters = allFilters?.reduce<{ vendors: string[]; others: string[] }>(
          (acc, item) => {
            if (item.includes('vendorId:')) {
              acc.vendors.push(item);
            } else {
              acc.others.push(item);
            }
            return acc;
          },
          {
            vendors: [],
            others: [],
          }
        );

        const splittedFilters = chunkBySize(parsedFilters?.vendors || [], VENDORS_LIST_FILTERS_LIMIT);

        const vendorsResult = (await Promise.all(
          splittedFilters.map(async (filters) =>
            fetchWithBQ({
              url: `/${API_URLS.VENDORS}`,
              params: {
                limit: _arg.limit,
                offset: _arg.offset,
                query: _arg.query,
                filters: filters.concat(parsedFilters?.others || []),
              },
            })
          )
        )) as QueryReturnValue<ListVendorsApiResponse, FetchBaseQueryError, FetchBaseQueryMeta>[];

        let firstError: FetchBaseQueryError | undefined;

        const vendors = vendorsResult.reduce<ListVendorsApiResponse>((acc, item) => {
          if (item.data) {
            return acc.concat(item.data);
          }
          if (item.error) {
            firstError = item.error;
          }
          return acc;
        }, []);

        if (firstError) {
          return { error: firstError };
        }

        const orderedVendors = vendors.map((v) => ({ ...v, rank: v.rank || 10000 })).sort((a, b) => a.rank - b.rank);

        return vendors?.length ? { data: orderedVendors as ListVendorsApiResponse } : { data: [] };
      },
    }),
  }),
});

export const { useListVendorsOptimisedQuery } = vendorApi;
