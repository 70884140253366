import { useState } from 'react';
import { useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { GOOGLE_INTEGRATION_ID, STEPS } from './Steps/common';
import { CollectStep } from './Steps/CollectStep';
import { ConnectionCheckStep } from './Steps/ConnectionCheckStep';
import { StartStep } from './Steps/StartStep';
import { ConnectionSuccessStep } from './Steps/ConnectionSuccessStep';
import { ConnectionErrorStep } from './Steps/ConnectionErrorStep';
import { AllowAccess } from './Steps/AllowAccess';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import Wizard from 'pages/Preferences/Integrations/components/Wizard';

const GoogleSetupWizard = () => {
  const { accountId } = useAccountContext();
  const [step, setStep] = useState(STEPS.START);
  const [connectionWindow, setConnectionWindow] = useState();
  const [testingInProgress, setTestingInProgress] = useState(false);
  const [samlProviderTargetState, setSamlProviderTargetState] = useState(false);

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const handleCancellation = async () => {
    if (!accountId || step === STEPS.START) return;
    await revokeAccountIntegration({ accountId, integrationId: GOOGLE_INTEGRATION_ID });
  };

  return (
    <Wizard activeStep={step} onCancel={handleCancellation}>
      <StartStep stepValue={STEPS.START} setStep={setStep} accountId={accountId!} />
      <CollectStep
        stepValue={STEPS.SELECT_DATA_SOURCE}
        setStep={setStep}
        accountId={accountId!}
        samlProviderTargetState={samlProviderTargetState}
        setSamlProviderTargetState={setSamlProviderTargetState}
      />
      <AllowAccess
        stepValue={STEPS.ALLOW_ACCESS}
        setStep={setStep}
        setConnectionWindow={setConnectionWindow}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
      />
      <ConnectionCheckStep
        stepValue={STEPS.CHECK_CONNECTION}
        setStep={setStep}
        accountId={accountId!}
        setTestingInProgress={setTestingInProgress}
        testingInProgress={testingInProgress}
        connectionWindow={connectionWindow}
      />
      <ConnectionErrorStep
        stepValue={STEPS.CONNECTION_ERROR}
        setStep={setStep}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
        samlProviderTargetState={samlProviderTargetState}
      />
      <ConnectionSuccessStep
        stepValue={STEPS.CONNECTION_SUCCESS}
        setStep={setStep}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
        samlProviderTargetState={samlProviderTargetState}
      />
    </Wizard>
  );
};

export default GoogleSetupWizard;
