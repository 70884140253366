import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  TextField,
  Autocomplete,
  CircularProgress,
  FilterOptionsState,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import { Add, Close as CloseIcon } from '@mui/icons-material';
import { AccountUser } from '@vertice/slices';
import clsx from 'clsx';
import styles from './ApproverAutocomplete.module.scss';
import { ContractOwner } from '../constants';

interface ApproverAutocompleteProps {
  options: AccountUser[];
  selectedId?: string | null;
  onSelected?: (selected: string | null) => void;
  isLoading?: boolean;
  allowAdd?: boolean;
  onAddRequest?: () => void;
  labeled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
}

const CATEGORY = {
  option: 'option',
  header: 'header',
  button: 'button',
};

type CategoryType = keyof typeof CATEGORY;

interface ApproverAutocompleteOption {
  category?: CategoryType;
  label?: string;
  value: string;
  addButton?: boolean;
}

/** @deprecated Use our unified @vertice/core/src/modules/user/UserSelect instead */
const ApproverAutocomplete: React.FC<ApproverAutocompleteProps> = ({
  options,
  selectedId = null,
  onSelected,
  isLoading = false,
  allowAdd = true,
  onAddRequest,
  labeled = false,
  error = false,
  fullWidth = false,
}) => {
  const { t } = useTranslation();
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<ApproverAutocompleteOption | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<ApproverAutocompleteOption[]>([]);
  const [searchValue] = useDebounce(search, 500);

  useEffect(() => {
    const lowerCaseSearch = searchValue.toLowerCase().trim();
    const filterFunction = (user: AccountUser) =>
      !filterEnabled ||
      !lowerCaseSearch ||
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(lowerCaseSearch);
    const mapFunction = (user: AccountUser) => ({
      category: CATEGORY.option as CategoryType,
      label: `${user.firstName} ${user.lastName}`,
      value: user.userId,
    });
    setFilteredOptions([
      {
        category: CATEGORY.option as CategoryType,
        label: t('PREFERENCES.WORKFLOW.APPROVERS.AUTOCOMPLETE.CONTRACT_OWNER'),
        value: ContractOwner,
      },
      {
        category: CATEGORY.header as CategoryType,
        label: t('PREFERENCES.WORKFLOW.APPROVERS.AUTOCOMPLETE.SPECIFIC_TEAM_MEMBER'),
        value: 'header',
      },
      ...options.filter(filterFunction).map(mapFunction),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, searchValue, filterEnabled]);

  useEffect(() => {
    if (selectedId) {
      const selectedOption = filteredOptions.find((option) => option.value === selectedId);
      setSelected(selectedOption || { value: selectedId });
    } else {
      setSelected(null);
    }
  }, [filteredOptions, selectedId]);

  const handleChange = async (event: any, newValue: ApproverAutocompleteOption | null | string) => {
    if (typeof newValue === 'string') {
      return;
    }

    if (newValue?.category === CATEGORY.button && allowAdd && onAddRequest) {
      clearSelection();
      setSearch('');
      onAddRequest();
    } else {
      if (onSelected) {
        onSelected(newValue?.value || null);
      }
      setSelected(newValue);
    }

    setFilterEnabled(false);
  };

  const clearSelection = () => {
    if (onSelected) {
      onSelected(null);
    }
    setSelected(null);
  };

  const handleChangeSearch = (event: React.SyntheticEvent, text: string) => {
    if (event && event.type === 'change') {
      // Clear selection if the event was triggered by user typing into the input
      if (selected) {
        clearSelection();
      }
      setFilterEnabled(true);
    }
    setSearch(text);
  };

  const handleClear = () => {
    clearSelection();
    setSearch('');
  };

  const addEndAdornment = (endAdornment: ReactElement) => {
    const children = React.Children.toArray(endAdornment?.props?.children);
    if (search) {
      children.unshift(
        <IconButton key="clear-button" title="Clear" onClick={handleClear}>
          <CloseIcon className={styles['close-icon']} />
        </IconButton>
      );
    }

    if (isLoading) {
      children.unshift(<CircularProgress key="progress" size="20px" />);
    }
    return React.cloneElement(endAdornment, {}, children);
  };

  return (
    <Autocomplete
      id="approver-autocomplete"
      className={styles['approver-autocomplete']}
      freeSolo
      forcePopupIcon
      value={selected}
      isOptionEqualToValue={(option, { value: val }) => option.value === val}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label || '')}
      options={filteredOptions}
      loading={isLoading}
      onChange={handleChange}
      onInputChange={handleChangeSearch}
      inputValue={search}
      getOptionDisabled={(option) => option.category === CATEGORY.header}
      fullWidth={fullWidth}
      classes={{
        inputRoot: styles['input-root'],
        clearIndicator: styles['clear-indicator'],
        endAdornment: styles['end-adornment-container'],
        paper: styles.paper,
        listbox: styles.listbox,
        option: styles.option,
      }}
      renderInput={(params) => (
        <TextField
          className={styles.input}
          label={labeled ? t('PREFERENCES.WORKFLOW.APPROVERS.AUTOCOMPLETE.APPROVER') : null}
          error={error}
          {...params}
          placeholder={t('PREFERENCES.WORKFLOW.APPROVERS.AUTOCOMPLETE.SEARCH_USER')}
          InputLabelProps={{ shrink: true, classes: { root: styles.label } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: addEndAdornment(params.InputProps.endAdornment as ReactElement),
            classes: {
              notchedOutline: styles.outline,
              focused: styles.focused,
              error: styles.error,
            },
          }}
        />
      )}
      renderOption={(props: any, option: ApproverAutocompleteOption) => {
        const addButton = allowAdd && option.category === CATEGORY.button;

        if (addButton) {
          return (
            <li {...props} key="add-button" className={clsx(props.className, styles['add-option'])}>
              <Stack direction="row" gap="8px">
                <Add className={styles['plus-icon']} />
                <Typography variant="button-m" color="var(--tokens-color-light-primary-1)">
                  {t('PREFERENCES.WORKFLOW.APPROVERS.AUTOCOMPLETE.ADD_NEW')}
                </Typography>
              </Stack>
            </li>
          );
        }

        return (
          <li
            {...props}
            key={option.value}
            className={clsx(props.className, { [styles.header]: option.category === CATEGORY.header })}
          >
            <Typography variant={option.category === CATEGORY.header ? 'label-s' : 'body-regular-m'}>
              {option.label}
            </Typography>
          </li>
        );
      }}
      filterOptions={(opts, params: FilterOptionsState<ApproverAutocompleteOption>) => {
        if (allowAdd) {
          opts.push({ category: CATEGORY.button as CategoryType, label: 'Add user', value: 'button' });
        }
        return opts;
      }}
    />
  );
};

export default ApproverAutocomplete;
