import { useQuery } from '@tanstack/react-query';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { type OptimizationsListQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCloudClient } from '../../CloudClientProvider';

type UseOptimizationsProps<TResult extends any> = {
  select: (data: OptimizationsListQuery) => TResult;
};

const OptimizationsQuery = graphql(`
  query OptimizationsList($accountId: String!) {
    optimizationsQuery(params: { accountId: $accountId }) {
      ... on OptimizationsResult {
        __typename
        summary {
          recsBreaching
          recsCancelled
          recsNonBreaching
          recsTotal
          estimatedSavings {
            category
            count
            saving
          }
        }
        tests {
          breaching
          category
          code
          saving
          testSummary {
            type
            value
            tooltip {
              data
              types
            }
          }
          recommendations {
            breaching
            code
            effort
            proposedCost
            resourceCost
            saving
            status
          }
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const QUERY_KEY = 'Optimizations' as const;

const useOptimizations = <TResult = any,>({ select }: UseOptimizationsProps<TResult>) => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchCloudOptimization(OptimizationsQuery, { accountId }),
    enabled: !!accountId,
    select,
  });
};

export default useOptimizations;
