import { useSpendVsCommitmentChartData } from './useSpendVsCommitmentChartData';
import SpendVsCommitmentChart from './SpendVsCommitmentChart';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';

const SpendVsCommitmentTab = () => {
  const { data, error, isFetching } = useSpendVsCommitmentChartData();

  return (
    <LoadableContentWrapper isEmpty={!data} error={error} isLoading={isFetching || (!data && !error)}>
      <SpendVsCommitmentChart data={data!} />
    </LoadableContentWrapper>
  );
};

export default SpendVsCommitmentTab;
