import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import Stack from '@mui/material/Stack';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { Link as RouterLink } from 'react-router-dom';
import { Text } from '@verticeone/design-system/src';
import { useGetProductByIdQuery, useGetVendorByIdQuery } from '@vertice/slices';
import { RequestBenchmarkingDataButton } from '../../modules/saas/vendor/buttons/RequestBenchmarkingDataButton';
import React from 'react';

export type ProductDetailPageHeaderProps = {
  vendorId?: string;
  productId?: string;
};

const ProductDetailPageHeader = ({ vendorId, productId }: ProductDetailPageHeaderProps) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });

  const { data: product } = useGetProductByIdQuery(
    {
      vendorId: vendorId!,
      productId: productId!,
    },
    { skip: !vendorId || !productId }
  );

  return (
    <>
      <Stack gap={2} p={8}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
          <BreadcrumbLink component={RouterLink} to={generatePath(routes.VENDORS.PATH)}>
            {t('SAAS.NAVBAR.VENDORS')}
          </BreadcrumbLink>
          <BreadcrumbLink
            disabled={!vendorId}
            component={RouterLink}
            to={generatePath(routes.VENDORS.VENDOR.ABSOLUTE_PATH, { id: vendorId! })}
          >
            {vendor?.name ?? <Skeleton width="6em" />}
          </BreadcrumbLink>
          <BreadcrumbLink disabled>{product?.name ?? <Skeleton width="6em" />}</BreadcrumbLink>
        </Breadcrumbs>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" gap={2}>
            <Text variant="heading" size="M" tag="h1">
              {product?.name ?? <Skeleton height="1em" width="6em" />}
            </Text>
          </Stack>
          <Stack direction="row" gap={2}>
            <RequestBenchmarkingDataButton vendorId={vendorId} productId={productId} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ProductDetailPageHeader;
