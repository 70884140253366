import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { removeNotification, selectNotifications } from './reducer';
import type { NotificationActionData } from './types';

const useNotifier = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const notifications = useSelector(selectNotifications);
  const [displayedIdx, setDisplayedIdx] = useState(notifications.map(({ id }) => id));
  const storeDisplayed = (id: SnackbarKey) => setDisplayedIdx((idx) => [...idx, id]);
  const removeDisplayed = (id: SnackbarKey) => setDisplayedIdx((idx) => idx.filter((key) => key !== id));

  const getActionComponent = (actionData: NotificationActionData | undefined) => {
    // add another types if it will be required.
    if (actionData?.type === 'link') {
      return (
        <Link {...actionData.props}>
          <Typography variant="button-s">{t(actionData.textTranslationPath)}</Typography>
        </Link>
      );
    }

    return null;
  };

  React.useEffect(() => {
    notifications.forEach(({ id, textKey, type, action }) => {
      // prevent to display notifications which are already displayed
      if (!displayedIdx.includes(id)) {
        enqueueSnackbar(t(textKey), {
          key: id,
          variant: type,
          onExited: (event, snackbarKey) => {
            dispatch(removeNotification(snackbarKey.toString()));
            removeDisplayed(id);
          },
          action: getActionComponent(action),
        });
        storeDisplayed(id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);
};

export default useNotifier;
