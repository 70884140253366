import React, { FC } from 'react';
import { ButtonBase, Stack, styled } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VendorLogo } from '@vertice/components';

import { stageColor } from './useScheduleView';
import { Stage, VendorDetails, Timeline } from './types';
import { Tooltip } from '@verticeone/design-system/src';
import { VendorInfo, TimelineInfo } from './TooltipInfo';

const TooltipVendorStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.core.bg : undefined,
  alignSelf: 'center',
}));
const TooltipVendorLogo = styled(VendorLogo)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
}));

const StyledVendorLogo = styled(VendorLogo)({
  width: '22px',
  height: '22px',
});
const ScheduleItemButton = styled(ButtonBase)<{ $scheduleStage: Stage }>(({ $scheduleStage, theme }) => ({
  flex: 1,
  width: theme.spacing(8),
  height: theme.spacing(8),
  minHeight: theme.spacing(8),
  maxHeight: theme.spacing(8),
  borderRadius: '10px',
  border: `2px solid ${stageColor($scheduleStage)}`,
}));

type ScheduleItemProps = {
  scheduleDate: string;
  scheduleStage: Stage;
  handleClick: () => void;
  vendor: VendorDetails;
  timeline: Timeline;
};
const ScheduleItem: FC<ScheduleItemProps> = ({ scheduleStage, scheduleDate, handleClick, vendor, timeline }) => {
  const { vendorId } = vendor;

  const tooltip = (
    <Stack flexDirection="row" gap={2}>
      <TooltipVendorStack>
        <TooltipVendorLogo vendorId={vendorId} />
      </TooltipVendorStack>

      <Stack gap={1}>
        <VendorInfo scheduleDate={scheduleDate} {...vendor} />
        {scheduleStage === 'IN_PROGRESS' && <TimelineInfo {...timeline} />}
      </Stack>
    </Stack>
  );

  return (
    <Tooltip size="XXS" tooltip={tooltip} maxWidth="fit-content">
      <ScheduleItemButton onClick={handleClick} $scheduleStage={scheduleStage}>
        <StyledVendorLogo vendorId={vendorId} />
      </ScheduleItemButton>
    </Tooltip>
  );
};

export default ScheduleItem;
