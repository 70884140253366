import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import styles from './IntegrationAccordion.module.scss';

interface IntegrationAccordionProp {
  summary: string;
  details: React.ReactNode;
  defaultExpanded?: boolean;
}

const IntegrationAccordion: FC<IntegrationAccordionProp> = ({ summary, details, defaultExpanded }) => (
  <Box className={styles.container}>
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon />}>
        <Typography variant="button-m">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles['accordion-details']}>{details}</AccordionDetails>
    </Accordion>
  </Box>
);
export default IntegrationAccordion;
