export const readTextFile = (file: File): Promise<string> => {
  const reader = new FileReader();
  reader.readAsText(file || '');

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (file) {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject();
        }
      } else {
        reject();
      }
    };
  });
};
