import React from 'react';
import { styled } from '@mui/material';

type NoRowsGridWrapperProps = {
  hasData: boolean;
  children: React.ReactNode;
};

const StyledWrapper = styled('div')`
  height: 20rem;
  width: 100%;
`;

const NoDataGridWrapper = ({ hasData, children }: NoRowsGridWrapperProps) => {
  if (hasData) {
    return <>{children}</>;
  }

  return <StyledWrapper>{children}</StyledWrapper>;
};

export default NoDataGridWrapper;
