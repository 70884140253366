import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import Deadline from '../../Deadline';
import { getFeDeadline } from '../../Deadline/utils';
import { rollingFrequencyCellSortableValueGetter } from './RollingFrequencyCell';

type DeadlineCellProps = {
  viewOnContract: ViewOnContract;
};

export const deadlineCellValueGetter = (viewOnContract: ViewOnContract) => {
  const deadlineDate = getFeDeadline(viewOnContract.contract)?.deadlineDate;

  if (deadlineDate) {
    return deadlineDate;
  }

  return rollingFrequencyCellSortableValueGetter(viewOnContract);
};

export const DeadlineCell = ({ viewOnContract }: DeadlineCellProps) => {
  return <Deadline contract={viewOnContract.contract} />;
};
