import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { Box, Stack } from '@mui/material';

import { Text } from '@verticeone/design-system/src';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { FormData } from '../../SecurityAssessmentForm/schema';
import { FormCardWrapper } from './FormCardWrapper';

export const SecurityQuestionnaireOverview = () => {
  const { t } = useTranslation();
  const taskData = useWatch<FormData>();

  return (
    <Stack gap={1}>
      <FormCardWrapper>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_WEBSITE')} />
          <Text variant="body-regular" size="S">
            {taskData.vendorWebsite}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_CONTACT_EMAIL')} />
          <Text variant="body-regular" size="S">
            {taskData.vendorContact?.email}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_CONTACT_EMAIL')} />
          <Text variant="body-regular" size="S">
            {taskData.vendorContact?.email}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q1')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharedWithVendor}`)}
          </Text>
        </Box>
      </FormCardWrapper>

      <FormCardWrapper>
        <Text variant={'caption'}>{t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.TITLE_1')}</Text>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q2')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerPiiData}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q3')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerAccountAuthData}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q4')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.employeeContacts}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q5')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.employeeSocialSecurityNumbers}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q6')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.employeeBankAccounts}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q7')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.acquisitionPlans}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q8')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.sourceCode}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q9')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.publishedResearch}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q10')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.pressReleases}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q11')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.accountingData}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q12')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerCreditCardData}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q13')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerMedicalRecords}`)}
          </Text>
        </Box>
      </FormCardWrapper>

      <FormCardWrapper>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q14')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.additionalIntegration}`)}
          </Text>
        </Box>
        <Box>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.OTHER_NOTES')} />
          <Text variant="body-regular" size="S">
            {taskData.additionalNotes}
          </Text>
        </Box>
      </FormCardWrapper>
    </Stack>
  );
};
