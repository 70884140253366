import { createRequiredContext } from '../contexts';
import { useMemo, useState } from 'react';

// WARNING:
// This file is copied from @vertice/core/src/contexts/LocaleContext.ts.
// Don't change this file without updating source file as well.
// This file is going to be deleted as soon as we move the formatters into the shared @verticeone/utils package.

// For now, we use it as a way to propagate chosen locale to our temporary formatters here in Design System.
// After migration to @verticeone/utils, we will use the locale from the app's context.

export type LocaleContextData = {
  locale: string;
  setLocale: (locale: string) => void;
};

export const { LocaleContextProvider, useLocaleContext } = createRequiredContext<LocaleContextData, 'Locale'>('Locale');

const DEFAULT_LOCALE = 'en-US';

/** Prepares LocaleContextData */
export const useLocale = (defaultLocale = DEFAULT_LOCALE): LocaleContextData => {
  const [locale, setLocale] = useState(defaultLocale);
  return useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale, setLocale]
  );
};
