import { useMemo } from 'react';
import { RequestRow } from '../../types';
import { useUsersContext } from '@vertice/core/src/contexts/UsersContext';
import { STATUS_FILTER_MAP, StatusFilterType } from './types';
import { useLoggedUser } from '@verticeone/auth/src';
import { parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';

type GetFullNameFunction = ReturnType<typeof useUsersContext>['getFullName'];

export const searchQueryFilter = (getFullName: GetFullNameFunction) => (requests: RequestRow[], searchQuery?: string) =>
  requests.filter((request) => {
    if (!searchQuery || searchQuery === '') return true;

    const filterFields = [
      request.requestName,
      request.ownerId && getFullName(request.ownerId),
      request.requestType,
      request.vendor?.name,
      ...(request.taskAssignees?.map((assignee) => getFullName(parseUserRef(assignee).userId)) ?? []),
      ...(request.tasks?.filter((task) => task.status === 'ACTIVE').map((task) => task.name) ?? []),
    ]
      .map((field) => field?.toLowerCase())
      .filter((field) => field !== null && field !== undefined);

    return searchQuery
      .toLowerCase()
      .split(' ')
      .every((word) => filterFields.some((field) => field && field.includes(word)));
  });

export const statusFilterFilter = (requests: RequestRow[], statusFilter: StatusFilterType) =>
  requests.filter((request) => STATUS_FILTER_MAP[statusFilter].includes(request.status));

export const myRequestsFilter = (userId: string) => (requests: RequestRow[], myRequestsOnly: boolean) =>
  requests.filter((request) => (myRequestsOnly ? request.ownerId === userId : true));

export const useFilterUtils = () => {
  const { getFullName } = useUsersContext();
  const { userId } = useLoggedUser();

  const filterBySearchQuery = useMemo(() => searchQueryFilter(getFullName), [getFullName]);

  const filterByStatusFilter = useMemo(() => statusFilterFilter, []);

  const filterByMyRequests = useMemo(() => myRequestsFilter(userId), [userId]);

  return { filterBySearchQuery, filterByStatusFilter, filterByMyRequests };
};
