import { Offer } from '../../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { OfferType } from '../types';
import { useOfferTotalCost } from '../../../../../hooks/useOfferTotalCost';

type UseOfferFooter = {
  offer: Offer;
  baselineOffer?: Offer;
  type: OfferType;
};

type UseOfferFooterValuesReturn = {
  baselineAnnualCost: number;
  annualCost: number;
  baselineTotalCost: number;
  totalCost: number;
  savings?: {
    baseSavings: number;
    concessionSavings: number;
  };
};

export const useOfferFooterValues = ({ offer, baselineOffer, type }: UseOfferFooter) => {
  const baselineOfferTotalCost = useOfferTotalCost(baselineOffer);
  const offerTotalCost = useOfferTotalCost(offer);

  const getOfferFooterValues = () => {
    let footer: UseOfferFooterValuesReturn = {
      baselineAnnualCost: 0,
      annualCost: 0,
      baselineTotalCost: 0,
      totalCost: 0,
    };

    if (offer.overrideEffectiveCost && offer.effectiveCosts) {
      footer.annualCost = offer.effectiveCosts;
    } else {
      footer.annualCost = offer.computedCosts ?? 0;
    }

    if (baselineOffer) {
      if (baselineOffer.overrideEffectiveCost && baselineOffer.effectiveCosts) {
        footer.baselineAnnualCost = baselineOffer.effectiveCosts;
      } else {
        footer.baselineAnnualCost = baselineOffer.computedCosts ?? 0;
      }
    }

    if (offerTotalCost) {
      footer.totalCost = offerTotalCost;
    }

    if (baselineOfferTotalCost) {
      footer.baselineTotalCost = baselineOfferTotalCost;
    }

    if (baselineOffer) {
      switch (type) {
        case 'enumerated':
        case 'final':
          const baseOfferCost =
            (baselineOffer.overrideEffectiveCost ? baselineOffer.effectiveCosts : baselineOffer.computedCosts) ?? 0;
          const offerCost = (offer.overrideEffectiveCost ? offer.effectiveCosts : offer.computedCosts) ?? 0;

          const baseSavings = baseOfferCost - offerCost;
          const concessionSavings = offer.concessions.reduce(
            (acc, concession) => (acc += concession.status === 'REALISED' ? concession.amount : 0),
            0
          );

          footer.savings = {
            baseSavings,
            concessionSavings,
          };
          break;
        default:
          break;
      }
    }

    return footer;
  };

  return { getOfferFooterValues };
};
