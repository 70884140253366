import { keyBy, range } from 'lodash';
import { SeriesOptionsWithData } from '../types';

type TopBottom = {
  top?: string;
  bottom?: string;
};

type Masks = {
  topBottom: boolean[];
  top: boolean[];
  bottom: boolean[];
  rest: boolean[];
};

/**
 * Returns the top most and bottom most category for every x (month).
 * @param seriesItems Chart data for the series
 * @param seriesLength The length of data series (e.g. 12 for every month)
 */
export const getTopBottomCategories = (seriesItems: SeriesOptionsWithData[], seriesLength: number) => {
  const categoryMap = keyBy(seriesItems, 'id');
  const chartOrderedSeries = seriesItems.map((seriesItem) => seriesItem.id);

  return range(seriesLength).map((index) => {
    const positiveSeries = chartOrderedSeries.filter((id) => {
      const serie = categoryMap[id];
      return serie && serie.data[index] && (serie.data[index] as number) > 0;
    });

    const negativeSeries = chartOrderedSeries.filter((id) => {
      const serie = categoryMap[id];
      return serie && serie.data[index] && (serie.data[index] as number) < 0;
    });

    // Highchart stacks the negative values in opposite order
    const topBottomOrder = positiveSeries.concat(negativeSeries.slice().reverse());

    return {
      top: topBottomOrder[0],
      bottom: topBottomOrder[topBottomOrder.length - 1],
    };
  });
};

/**
 * __Main principle:__
 *   If some category value becomes topmost or lowest in the column (because it has nothing above it),
 *   it needs to be rendered in a separate series that has corresponding corners rounded.
 *
 * This function prepares support data structures that will make it easier to construct all the helper series.
 *
 * It returns the true/false mask array for each category.
 * The true value in the mask array means that the series value on the given x index belongs to the specified category.
 *
 * @param categories An array of categories taken into an account
 * @param topBottomCategories An array containing the top-most and bottom-most category for every x index
 *
 * @example
 *
 * Categories: A-E
 * A
 * |             /---\
 * |             | C |
 * | /---\       +---+
 * | | A |       | D |
 * | +---+ /---\ +---+
 * | | B | | B | | E |
 * | \---/ \---/ \---/
 * +------------------------>
 *
 * RESULT
 *             ---- TIME ---->
 * A:
 *   topBottom: [false, false, false],
 *   top:       [TRUE , false, false],
 *   bottom:    [false, false, false],
 *   rest:      [false, TRUE,  TRUE ],
 *
 * B:
 *   topBottom: [false, TRUE,  false],
 *   top:       [false, false, false],
 *   bottom:    [TRUE,  false, false],
 *   rest:      [false, false, TRUE],
 *
 * C: (similar)
 *
 * D:
 *   topBottom: [false, false, false],
 *   top:       [false, false, false],
 *   bottom:    [false, false, false],
 *   rest:      [TRUE,  TRUE,  TRUE ],
 */
export const getCategoryMasks = (categories: string[], topBottomCategories: TopBottom[]) =>
  categories.reduce(
    (acc, category): { [arg: string]: Masks } => ({
      ...acc,
      [category]: {
        topBottom: topBottomCategories.map(({ top, bottom }) => top === category && top === bottom),
        top: topBottomCategories.map(({ top, bottom }) => top === category && top !== bottom),
        bottom: topBottomCategories.map(({ top, bottom }) => bottom === category && top !== bottom),
        rest: topBottomCategories.map(({ top, bottom }) => bottom !== category && top !== category),
      },
    }),
    {}
  );

/**
 * Returns the masked array. Positive value in the mask means that the original value is kept, otherwise it is replaced with null.
 * @param originalData An array of original values
 * @param mask An array of true/false values
 */
export const getMaskedValues = (originalData: (number | null)[], mask: boolean[]) =>
  originalData.map((value, index) => (mask[index] ? value : null));

const SERIES_TYPES_WITH_BORDER_RADIUS = ['column'];

/**
 * Prepares the chart series data so that the topmost series has the top border radius and the bottommost series has
 * the bottom border radius. If there is only one series for the given x index, it has both, top and bottom radius.
 * @param seriesItems Chart data for the series
 * @param borderRadius The border radius value to be used
 */
export const getSeriesWithBorderRadius = (seriesItems: SeriesOptionsWithData[], borderRadius: number | string) => {
  const orderedChartSeries = seriesItems.map((seriesItem) => seriesItem.id);

  const topBottomCategories = getTopBottomCategories(seriesItems, seriesItems[0]?.data.length || 0);
  const categoryMasks: { [arg: string]: Masks } = getCategoryMasks(orderedChartSeries, topBottomCategories);

  return seriesItems.reduce((acc: SeriesOptionsWithData[], originalSerie) => {
    const categoryMask = categoryMasks[originalSerie.id];

    const hasTopBottom = categoryMask?.topBottom?.some((item: boolean) => item);
    const hasTop = categoryMask?.top?.some((item: boolean) => item);
    const hasBottom = categoryMask?.bottom?.some((item: boolean) => item);
    const shouldApplyBorderRadius = SERIES_TYPES_WITH_BORDER_RADIUS.includes(originalSerie.type);

    if (!shouldApplyBorderRadius) {
      return [...acc, originalSerie];
    }

    if (hasTopBottom || hasTop || hasBottom) {
      return [
        ...acc,
        {
          ...originalSerie,
          data: getMaskedValues(originalSerie.data, categoryMask.rest),
        },
        ...(hasTopBottom
          ? [
              {
                ...originalSerie,
                id: originalSerie.id + '-top-bottom',
                linkedTo: originalSerie.id,
                data: getMaskedValues(originalSerie.data, categoryMask.topBottom),
                borderRadiusTopLeft: borderRadius,
                borderRadiusTopRight: borderRadius,
                borderRadiusBottomLeft: borderRadius,
                borderRadiusBottomRight: borderRadius,
              },
            ]
          : []),
        ...(hasTop
          ? [
              {
                ...originalSerie,
                id: originalSerie.id + '-top',
                linkedTo: originalSerie.id,
                data: getMaskedValues(originalSerie.data, categoryMask.top),
                borderRadiusTopLeft: borderRadius,
                borderRadiusTopRight: borderRadius,
              },
            ]
          : []),
        ...(hasBottom
          ? [
              {
                ...originalSerie,
                id: originalSerie.id + '-bottom',
                linkedTo: originalSerie.id,
                data: getMaskedValues(originalSerie.data, categoryMask.bottom),
                borderRadiusBottomLeft: borderRadius,
                borderRadiusBottomRight: borderRadius,
              },
            ]
          : []),
      ];
    }

    return [...acc, originalSerie];
  }, [] as SeriesOptionsWithData[]);
};
