import React from 'react';
import type { SxProps } from '@mui/system';
import { useTheme } from '@mui/material';
import { Card, testProps } from '@verticeone/design-system/src';

type ChartWrapperProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const GraphWrapper = ({ children, sx }: ChartWrapperProps) => {
  const { palette } = useTheme();

  return (
    <Card p={4} pb={0} bgcolor={palette.core.bg} {...testProps('chart')} sx={sx}>
      {children}
    </Card>
  );
};

export default GraphWrapper;
