import React from 'react';
import clsx from 'clsx';

import styles from './StepsIndicator.module.scss';

interface StepsIndicatorProps {
  total: number;
  current: number;
}

const StepsIndicator: React.FC<StepsIndicatorProps> = ({ total, current }) => {
  const totalArray = Array.from(Array(total).keys());

  return (
    <div className={styles.container}>
      {totalArray.map((i) => (
        <div className={clsx(styles.step, { [styles.filled]: i < current })} key={`${i}-step`} />
      ))}
    </div>
  );
};

export default StepsIndicator;
