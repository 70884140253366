import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import type { SeriesLineOptions } from 'highcharts';

export type getTargetCoverage = {
  data: Array<[number, number | undefined]>;
};

export const TARGET_COVERAGE_ID = 'target_coverage';

const useTargetCoverage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return useCallback(
    ({ data }: getTargetCoverage): SeriesLineOptions => {
      return {
        id: TARGET_COVERAGE_ID,
        name: t('CLOUD.RESERVED_INSTANCES.TARGET_COVERAGE'),
        type: 'line',
        color: palette.core.color6,
        visible: false,
        enableMouseTracking: false,
      };
    },
    [palette, t]
  );
};

export default useTargetCoverage;
