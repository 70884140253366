import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchInput from '@vertice/core/src/components/SearchInput';
import { ToggleSwitch } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { RequestRow } from '../../types';
import { STATUS_FILTER_MAP, StatusFilter, StatusFilterType } from './types';
import { useDebouncedCallback } from 'use-debounce';

export type FilterPanelProps = {
  statusFilters: StatusFilter[];
  activeStatusFilter: StatusFilterType;
  onStatusFilterClick: (statusFilter: StatusFilter) => void;
  searchQuery?: string;
  onSearchQueryChange: (searchQuery: string) => void;
  myRequestsOnly?: boolean;
  onMyRequestsOnlyChange: (myRequestsOnly: boolean) => void;
  requests?: RequestRow[];
  isLoading?: boolean;
};

export const FilterPanel = ({
  statusFilters,
  activeStatusFilter,
  onStatusFilterClick,
  searchQuery,
  onSearchQueryChange,
  myRequestsOnly,
  onMyRequestsOnlyChange,
  requests,
  isLoading = false,
}: FilterPanelProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });
  const onSearchQueryChangeDebounced = useDebouncedCallback(onSearchQueryChange, 300);

  const requestCount = (requests ?? []).filter((request) =>
    STATUS_FILTER_MAP[activeStatusFilter].includes(request.status)
  ).length;

  return (
    <Stack direction="column" gap={4}>
      <Stack direction="row" gap={4}>
        <SearchInput
          disabled={!requests?.length && !searchQuery}
          value={searchQuery ?? ''}
          onSearch={onSearchQueryChangeDebounced}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          placeholder={isLoading ? t('SEARCH_REQUESTS_LOADING') : t('SEARCH_REQUESTS', { count: requestCount })}
        />
        <Stack gap={2} direction="row" alignItems="center">
          <ToggleSwitch
            disabled={isLoading}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            checked={myRequestsOnly}
            onChange={(e) => onMyRequestsOnlyChange(e.target.checked)}
          />
          <Text variant="caption" size="S" color="text1">
            {t('MY_REQUESTS_ONLY')}
          </Text>
        </Stack>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {statusFilters.map((statusFilter, index) => {
          const filterRequestsCount = (requests ?? []).filter((request) =>
            STATUS_FILTER_MAP[statusFilter.value].includes(request.status)
          ).length;

          if (
            filterRequestsCount === 0 &&
            !!statusFilter.showOnlyNonEmpty &&
            statusFilter.value !== activeStatusFilter
          ) {
            return undefined;
          }

          return (
            <Button
              key={[statusFilter.value, index].join()}
              variant={statusFilter.active ? 'solid' : 'ghost'}
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              onClick={() => onStatusFilterClick(statusFilter)}
              size="S"
              disabled={isLoading}
            >
              {statusFilter.label} {filterRequestsCount}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};
