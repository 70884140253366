import React, { FC } from 'react';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { Stack } from '@mui/material';
import { Button } from '@verticeone/design-system/src';
import { TaskState, WorkflowTaskNodeType } from '../../../model/types';
import { useRouteNavigate } from '@verticeone/router/src';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';

type TaskActionProps = {
  state?: TaskState;
  route?: string;
  taskNodeType?: WorkflowTaskNodeType;
};

export const TaskNavigateAction: FC<TaskActionProps> = ({ state, route, taskNodeType }) => {
  const { t } = useTranslation();
  const { accountId, allowVerticeServiceNavigation } = useWorkflowRendererContext();
  const { generatePath } = useRouteNavigate();

  if (taskNodeType === 'VERTICE_SERVICE' && !allowVerticeServiceNavigation) {
    return null;
  }

  if (taskNodeType === 'USER') {
    return null;
  }

  if (!state?.requestRef || !route) {
    return null;
  }

  const requestId = parseRequestRef(state.requestRef).requestId;
  const requestAccountId = parseRequestRef(state.requestRef).accountId;

  const isCrossAccount = requestAccountId !== accountId;

  return (
    <Stack marginTop={1}>
      <Button
        component={Link}
        to={generatePath(route, { requestId, account_id: requestAccountId })}
        target={isCrossAccount ? '_blank' : '_self'}
      >
        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.GO_TO_PAGE')}
      </Button>
    </Stack>
  );
};
