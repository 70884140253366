import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Skeleton, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { BreadcrumbsV2 } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import { DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { isNotNil } from '@verticeone/utils/validation';
import { useLastFetchedData } from '../hooks/useLastFetchedData';
import { DiscoverySourcesCell } from '../components/DiscoverySourcesCell';
import { LastFetchedInfo } from '../components/LastFetchedInfo';
import { useApplication } from './hooks/useApplication';
import { ApplicationLayout, UsersLayout } from './layouts';
import { filterDailyAlarms } from '../Applications/helpers';
import { UsageContext } from '../Applications/UsageContext';
import { TabDefinition, UsageTabs } from './utils';

export const Usage = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const { applicationId } = useParams();

  const { activeTab, setActiveTab } = useContext(UsageContext);
  const [showHideConfirmation, setShowHideConfirmation] = useState(false);
  const { application, isSuccess, isFetching, isHidden, toggleStatus, refetch } = useApplication(applicationId);

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: UsageTabs.APPLICATIONS,
        label: t('APPLICATIONS.USAGE.APPLICATION'),
        element: <ApplicationLayout />,
        isVisible: true,
      },
      {
        id: UsageTabs.USERS,
        label: t('APPLICATIONS.USAGE.USERS'),
        element: <UsersLayout />,
        isVisible: true,
      },
    ],
    [t]
  );

  const switchApplicationStatus = (skipConfirmation: boolean) => {
    setShowHideConfirmation(!skipConfirmation);

    if (skipConfirmation) {
      void toggleStatus().then(() => refetch());
    }
  };

  useEffect(() => {
    if (!application && isSuccess) {
      navigate(routes.PAGE_NOT_FOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, isSuccess]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.Usage);
  const accessLogs = data?.getAlarms?.lastDataUpdate;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const alarmsPerDay = filterDailyAlarms(data?.getAlarms?.alarmsPerDay);
  const providers = application?.sources?.map((source) => source.id).filter(isNotNil);

  if (!applicationId) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ width: 1 }} py={10} gap={6}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', px: 10 }}>
        <Stack direction="column" gap={2}>
          <Stack direction="row">
            <BreadcrumbsV2
              items={[
                { label: t('SAAS.NAVBAR.SAAS') },
                {
                  label: t('SAAS.NAVBAR.APPLICATIONS'),
                  component: Link,
                  to: routes.APPLICATIONS.ABSOLUTE_PATH,
                },
                {
                  label: application?.name !== undefined ? application.name : <Skeleton width={80} />,
                  startAdornment: (
                    <DiscoverySourcesCell
                      sources={application?.sources}
                      useSourceIcons={false}
                      size={16}
                      spacing={-1}
                    />
                  ),
                },
              ]}
            />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={4}>
          {!isFetching && !isLoadingLastFetchedData && hasAccessLogs && (
            <LastFetchedInfo accessLogs={accessLogs} alarmsPerDay={alarmsPerDay} providers={providers} />
          )}
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          {application && !isFetching && (
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  switchApplicationStatus(isHidden);
                }}
              >
                {isHidden ? t('APPLICATIONS.USAGE.UNHIDE_FROM_LIST') : t('APPLICATIONS.USAGE.HIDE_FROM_LIST')}
              </MenuItem>
            </Menu>
          )}
        </Stack>
      </Stack>
      <Stack gap={8}>
        <TabsBottomLineWrapper paddingX={10}>
          <Tabs variant="outlined" value={activeTab} onChange={(_, value) => setActiveTab(value)} scrollButtons="auto">
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} label={tab.label} />
            ))}
          </Tabs>
        </TabsBottomLineWrapper>
        <Box sx={{ px: 10 }}>{tabs.find((tab) => tab.id === activeTab)?.element}</Box>
      </Stack>
      <ConfirmationDialog
        isOpen={showHideConfirmation}
        secondaryButtonAction={() => setShowHideConfirmation(false)}
        secondaryButtonText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CANCEL_BUTTON')}
        primaryButtonAction={() => switchApplicationStatus(true)}
        headerText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.HEADER')}
        bodyPrimaryText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CONTENT', {
          application: application?.name,
        })}
        primaryButtonText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CONFIRM_BUTTON')}
      />
    </Stack>
  );
};
