import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { useGetVendorLegalInfoQuery } from '@vertice/slices';
import BlurredContent from './BlurredContent';
import VendorLegal from './VendorLegal';
import VendorSecurity from './VendorSecurity';
import VendorCompliance from './VendorCompliance';
import { isComplianceDataAvailable, isDataAvailable, isLegalDataAvailable } from './utils';
import { Tabs } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';
import useDiligenceSelectedTab, { DiligenceTab, TabDefinition } from './useDiligenceSelectedTab';
import { Box } from '@mui/material';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

interface VendorLegalProps {
  accountId: string;
  vendorId: string;
  topPadding?: boolean;
  leftPadding?: boolean;
}

const VendorDiligenceInsights: FC<VendorLegalProps> = ({ accountId, vendorId, topPadding, leftPadding = false }) => {
  const { t } = useTranslation();
  const { getFeature } = useFeatures();
  const isSubscribed = getFeature(FEATURES.VENDOR_LEGAL)?.properties?.isSubscribed;
  const { tabId, setTabId } = useDiligenceSelectedTab();

  const { data: vendorLegalData, isLoading: isVendorLegalDataLoading } = useGetVendorLegalInfoQuery(
    {
      accountId: accountId!,
      vendorId: vendorId!,
    },
    { skip: !isSubscribed }
  );
  const emptyData = !isSubscribed || (!isVendorLegalDataLoading && !isDataAvailable(vendorLegalData!));
  const emptyLegalData = emptyData || !isLegalDataAvailable(vendorLegalData!);
  const emptyComplianceData = emptyData || !isComplianceDataAvailable(vendorLegalData!);

  const blurredContentMode = isSubscribed ? 'NO_DATA' : 'NO_SUBSCRIPTION';

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: DiligenceTab.LEGAL,
        label: t('LEGAL_INFO.TABS.LEGAL'),
        element: emptyLegalData ? (
          <Box py={6}>
            <BlurredContent
              accountId={accountId}
              vendorId={vendorId}
              mode={isSubscribed ? 'NO_DATA' : 'NO_SUBSCRIPTION'}
            />
          </Box>
        ) : (
          <VendorLegal {...{ accountId, vendorId, vendorLegalData, isVendorLegalDataLoading }} />
        ),
        isVisible: true,
      },
      {
        id: DiligenceTab.SECURITY,
        label: t('LEGAL_INFO.TABS.SECURITY'),
        element: <VendorSecurity vendorId={vendorId} />,
        isVisible: !isSubscribed,
      },
      {
        id: DiligenceTab.COMPLIANCE,
        label: t('LEGAL_INFO.TABS.COMPLIANCE'),
        element: emptyComplianceData ? (
          <Box py={6}>
            <BlurredContent
              accountId={accountId}
              vendorId={vendorId}
              mode={isSubscribed ? 'NO_DATA' : 'NO_SUBSCRIPTION'}
            />
          </Box>
        ) : (
          <VendorCompliance {...{ accountId, vendorId, vendorLegalData, isVendorLegalDataLoading }} />
        ),
        isVisible: !isSubscribed,
      },
    ],
    [
      t,
      emptyLegalData,
      accountId,
      vendorId,
      isSubscribed,
      vendorLegalData,
      isVendorLegalDataLoading,
      emptyComplianceData,
    ]
  );

  if (isVendorLegalDataLoading) {
    return null;
  }

  return (
    <Card>
      <CardHeader noDivider={true} size="S">
        <CardHeaderTitle text={t('LEGAL_INFO.TITLE')} />
        <CardHeaderDescription>
          {emptyData ? t(`LEGAL_INFO.${blurredContentMode}.DETAILS`) : t(`LEGAL_INFO.DETAILS`)}
        </CardHeaderDescription>
      </CardHeader>
      <TabsBottomLineWrapper paddingX={6}>
        <Tabs variant="outlined" value={tabId} onChange={(_, value) => setTabId(value)} scrollButtons="auto">
          {tabs.map((tab) => (
            <Tab key={tab.id} value={tab.id} label={tab.label} />
          ))}
        </Tabs>
      </TabsBottomLineWrapper>
      {tabs.find((tab) => tab.id === tabId)?.element}
    </Card>
  );
};

export default VendorDiligenceInsights;
