import React, { useMemo } from 'react';
import { Card, CardHeaderTitle } from '@verticeone/design-system/src';
import { Stack } from '@mui/material';
import { Tab } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';
import { ContractContextProvider, useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useTranslation } from 'react-i18next';
import RenewalOfferSection from './RenewalOfferSection';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import {
  isContractExisting,
  isContractNegotiatingApprovalContracting,
  isContractWithVertice,
} from '@vertice/core/src/modules/saas/contract/computed';
import SavingsBreakdownTab from '@vertice/core/src/modules/saas/contract/components/SavingsBreakdownTab';
import RenewalObjectivesTab from './RenewalObjectivesTab';
import DocumentsTab from '@vertice/core/src/modules/saas/contract/components/DocumentsTab';
import RenewalRequestDetailsTab from './RenewalRequestDetailsTab';
import ProductsTab from '../../tabs/ProductsTab';
import PurchaseRenewalHeaderActions from '@vertice/dashboard/src/modules/saas/contract/components/PurchaseRenewalHeaderActions';
import WorkflowTab from '@vertice/core/src/modules/saas/contract/components/WorkflowTab';
import RenewalClassificationSection from './RenewalClassificationSection';
import useFormUpdateActions from '@vertice/core/src/modules/saas/contract/hooks/useFormUpdateActions';
import LinkedContractsTab from '@vertice/core/src/modules/saas/contract/components/LinkedContractsTab';
import useContractTabNavigation, {
  TabDefinition,
} from '@vertice/core/src/modules/saas/contract/hooks/useContractTabNavigation';
import { ContractTab } from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';
import CustomTab from '@vertice/core/src/modules/saas/contract/components/CustomTab';
import { useCanSeeCustomFields } from '@vertice/core/src/modules/saas/contract/components/CustomFields';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useProductTabBenchmarkingProps } from '../../tabs/useProductTabBenchmarkingProps';

const RenewalContractCard = () => {
  const contractContextData = useContractContext('REQUIRE_FETCHED');
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAccountContext();
  const { handleSubmit } = useFormUpdateActions(contractContextData);

  const contract = contractContextData.fetchedContract.contract;
  const isNegotiatingApprovalContracting = useMemo(
    () => !!contract && isContractNegotiatingApprovalContracting(contract),
    [contract]
  );

  const showConcessionTab = useMemo(() => {
    if (!contract) {
      return false;
    }
    if (isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS)) {
      return !isContractExisting(contract);
    }
    return isContractWithVertice(contract) && isNegotiatingApprovalContracting;
  }, [contract, isNegotiatingApprovalContracting, isFeatureEnabled]);

  const showCustomTab = useCanSeeCustomFields();

  const productTabBenchmarkingProps = useProductTabBenchmarkingProps();

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: ContractTab.OBJECTIVES,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.OBJECTIVES'),
        element: <RenewalObjectivesTab />,
        isVisible: true,
      },
      {
        id: ContractTab.REQUEST_DETAILS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.REQUEST_DETAILS'),
        element: <RenewalRequestDetailsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.SAVINGS_BREAKDOWN,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.SAVINGS_BREAKDOWN'),
        element: <SavingsBreakdownTab />,
        isVisible: showConcessionTab,
      },
      {
        id: ContractTab.PRODUCTS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.PRODUCTS'),
        element: <ProductsTab showAnnualCost={isNegotiatingApprovalContracting} />,
        isVisible: true,
        otherProps: productTabBenchmarkingProps,
      },
      {
        id: ContractTab.DOCUMENTS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.RENEWAL_DOCUMENTS'),
        element: <DocumentsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.WORKFLOW,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.WORKFLOW'),
        element: <WorkflowTab />,
        isVisible: true,
      },
      {
        id: ContractTab.LINKED_CONTRACTS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.LINKED_CONTRACTS'),
        element: <LinkedContractsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.CUSTOM,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.CUSTOM'),
        element: <CustomTab />,
        isVisible: showCustomTab,
      },
    ],
    [isNegotiatingApprovalContracting, showConcessionTab, showCustomTab, productTabBenchmarkingProps, t]
  );

  const { visibleTabs, activeTab, setActiveTab, scrollTargetRef } = useContractTabNavigation({ tabs });

  return (
    <Card>
      <ContractContextProvider value={contractContextData}>
        <Stack component="form" onSubmit={handleSubmit}>
          {contract && (
            <HighlightedCardHeader size="S">
              <CardHeaderTitle text={t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.HEADER.RENEWAL_IN_PROGRESS')} />
              <PurchaseRenewalHeaderActions />
            </HighlightedCardHeader>
          )}
          <Stack py={6} spacing={8}>
            <RenewalOfferSection />
            <RenewalClassificationSection />
          </Stack>
          <TabsBottomLineWrapper paddingX={6}>
            <Tabs
              ref={scrollTargetRef}
              variant="outlined"
              value={activeTab.id}
              onChange={(_, value) => setActiveTab(value)}
              scrollButtons="auto"
            >
              {visibleTabs.map((tab) => (
                <Tab key={tab.id} value={tab.id} label={tab.label} />
              ))}
            </Tabs>
          </TabsBottomLineWrapper>
          {visibleTabs.find((tab) => tab.id === activeTab.id)?.element}
        </Stack>
      </ContractContextProvider>
    </Card>
  );
};

export default RenewalContractCard;
