import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { VerticeAwsIntegration } from '@vertice/assets';
import { Button } from '@verticeone/design-system/src';
import { AWS_STEPS, AWSStepProps } from '../AWSSetupWizard';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import useAWSTestIntegration from './useAWSTestIntegration';

const AWSSynthetic = ({ setStep }: AWSStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCancel = () => {
    navigate(-1);
  };
  const [checkingParams, setCheckingParams] = useState(false);
  const onAccountIntegrationSuccess = () => setStep(AWS_STEPS.SYNTHETIC_VERIFIED);
  const onAccountIntegrationError = () => setStep(AWS_STEPS.SYNTHETIC_ERROR);
  const setCheckError = (v: boolean) => {
    if (v) {
      setStep(AWS_STEPS.SYNTHETIC_ERROR);
    }
  };
  const { onCheckAWSKeys } = useAWSTestIntegration({
    params: {},
    setCheckingParams,
    setCheckError,
    onAccountIntegrationError,
    onAccountIntegrationSuccess,
  });

  const header = (
    <Stack gap="8px">
      <Text variant="heading" size="M">
        {t('PREFERENCES.INTEGRATIONS.AWS.SYNTHETIC_STEP.TITLE')}
      </Text>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.AWS.SYNTHETIC_STEP.SUBTITLE')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.AWS.SYNTHETIC_STEP.INFO')}
      </Text>
    </Stack>
  );

  return (
    <WizardStepTemplate
      headerImage={<VerticeAwsIntegration />}
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onCancel}>
          {t('COMMON.CANCEL')}{' '}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption onClick={onCheckAWSKeys} isLoading={checkingParams}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
    />
  );
};
export default AWSSynthetic;
