import { Palette } from '@mui/material';

export type ShortcutColor =
  | 'text1'
  | 'text2'
  | 'text3'
  | 'text4'
  | 'text5'
  | 'primary'
  | 'primary1'
  | 'primary2'
  | 'primary3'
  | 'primary4'
  | 'secondary'
  | 'secondary1'
  | 'secondary2'
  | 'secondary3'
  | 'secondary4'
  | 'tertiary'
  | 'tertiary1'
  | 'tertiary2'
  | 'tertiary3'
  | 'tertiary4'
  | 'success'
  | 'success1'
  | 'success2'
  | 'success3'
  | 'success4'
  | 'error'
  | 'error1'
  | 'error2'
  | 'error3'
  | 'error4'
  | 'warning'
  | 'warning1'
  | 'warning2'
  | 'warning3'
  | 'warning4'
  | 'info'
  | 'info1'
  | 'info2'
  | 'info3'
  | 'info4'
  | 'transparent'
  | 'transparent1'
  | 'transparent2'
  | 'transparent3'
  | 'transparent4'
  | 'inactive'
  | 'inactive1'
  | 'inactive2'
  | 'inactive3'
  | 'inactive4'
  | 'neutral'
  | 'neutral1'
  | 'neutral2'
  | 'neutral3'
  | 'neutral4';

const getPaletteColor = (colorSpace: any, index?: string) => {
  if (index) {
    return colorSpace[`color${index}`];
  }
  return colorSpace.main;
};

export const getShortcutColor = (palette: Palette, color?: string | ShortcutColor): string | undefined => {
  if (!color) {
    return undefined;
  }
  let finalColor: string = color;
  const textColorMatch = color.match(/^text([1-5])$/);
  if (textColorMatch) {
    finalColor = getPaletteColor(palette.text, textColorMatch[1]);
  } else {
    const colorSpaces = [
      'primary',
      'secondary',
      'tertiary',
      'success',
      'error',
      'warning',
      'info',
      'transparent',
      'inactive',
      'neutral',
    ];
    const otherColorMatch = color.match(new RegExp(`^(${colorSpaces.join('|')})([1-4])?$`));
    if (otherColorMatch) {
      finalColor = getPaletteColor((palette as any)[otherColorMatch[1]], otherColorMatch[2]);
    }
  }
  return finalColor;
};
