import React, { useMemo } from 'react';
import { ContractContextDataRequireFetched, FetchedContract } from '../../saas/contract/types';
import { useAccountContext } from '../../../contexts/AccountContext';
import VendorDiligenceInsights from './VendorDiligenceInsights';
import { useFeatures } from '../../features/useFeatures';
import { FEATURES } from '../../features/constants';
import { Feature } from '@vertice/slices/src/openapi/codegen/featuresAPI';

export const shouldCardBeVisible = (legalFeature: Feature | null, fetchedContract: FetchedContract) => {
  const vendorId = fetchedContract?.contract?.parts?.contractual?.vendor?.vendorId;
  return !!vendorId && legalFeature?.enabled && legalFeature?.properties?.isSubscribed;
};
export const useDiligenceInsightsCard = ({ fetchedContract }: ContractContextDataRequireFetched) => {
  const { getFeature } = useFeatures();
  const legalFeature = getFeature(FEATURES.VENDOR_LEGAL);

  const { accountId } = useAccountContext();
  return useMemo(
    () =>
      shouldCardBeVisible(legalFeature, fetchedContract)
        ? () => (
            <VendorDiligenceInsights
              {...{
                accountId: accountId!,
                vendorId: fetchedContract?.contract?.parts?.contractual?.vendor?.vendorId!,
              }}
            />
          )
        : null,
    [fetchedContract, accountId, legalFeature]
  );
};
