import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material';

import { IconWrapper } from '@verticeone/design-system/src';
import { IconWrapperProps } from '@verticeone/design-system/src';

export const WarningIcon = styled(
  forwardRef<SVGSVGElement, Pick<IconWrapperProps, 'size'>>(({ size = 'S', ...restProps }, ref) => (
    <IconWrapper {...restProps} ref={ref} icon={ReportProblemOutlined} size={size} color="warning" />
  ))
)(({ theme }) => ({
  backgroundColor: theme.palette.warning.color2,
  borderRadius: '4px',
  color: theme.palette.warning.contrastText,
  padding: 2,
}));
