import React, { CSSProperties, FC, ReactNode } from 'react';
import { TRANSFORMERS, $convertFromMarkdownString } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import clsx from 'clsx';
import ToolbarPlugin from './ToolbarPlugin';
import styles from './editor.module.scss';
import ListMaxIndentLevelPlugin from './ListMaxIndentLevelPlugin';
import { ControlledValuePlugin } from './ControlledValuePlugin';

type LexicalComposerProps = Parameters<typeof LexicalComposer>[0];

const defaultTheme: LexicalComposerProps['initialConfig']['theme'] = {
  paragraph: styles['editor-paragraph'],
  list: {
    nested: {
      listitem: styles['editor-list-nested'],
    },
    olDepth: [
      styles['editor-list-ol-depth-1'],
      styles['editor-list-ol-depth-2'],
      styles['editor-list-ol-depth-3'],
      styles['editor-list-ol-depth-4'],
      styles['editor-list-ol-depth-5'],
    ],
  },
};

type InitialConfig = LexicalComposerProps['initialConfig'];

interface EditorProps extends Partial<InitialConfig> {
  defaultValue?: string;
  asyncValue?: string;
  onChange?: (value: string) => void;
  placeholder?: ReactNode | undefined;
  disableToolbar?: boolean;
  contentStyle?: CSSProperties;
  resetValue?: boolean;
}

const Editor: FC<EditorProps> = (props) => {
  const {
    defaultValue = '',
    asyncValue = '',
    onChange,
    placeholder,
    disableToolbar = false,
    namespace = 'defaultEditor',
    theme = defaultTheme,
    nodes = [ListItemNode, ListNode, HeadingNode, QuoteNode, CodeNode, LinkNode],
    onError = () => {},
    editorState = () => {
      $convertFromMarkdownString(defaultValue, TRANSFORMERS);
    },
    contentStyle,
    resetValue,
  } = props;

  return (
    <LexicalComposer
      initialConfig={{
        namespace,
        theme,
        nodes,
        onError,
        editorState,
      }}
    >
      <div className={clsx(styles['editor-root'], { [styles['editor-root-toolbar']]: !disableToolbar })}>
        {!disableToolbar && <ToolbarPlugin />}
        <div className={styles['editor-textarea']}>
          <RichTextPlugin
            contentEditable={<ContentEditable style={contentStyle} className={styles['editor-input']} />}
            placeholder={<div className={styles['editor-placeholder']}>{placeholder}</div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={5} />
          <ControlledValuePlugin
            asyncValue={asyncValue}
            onChange={onChange}
            resetValue={resetValue && asyncValue === defaultValue}
          />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  );
};

export default Editor;
