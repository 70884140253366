import { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type AgeProps = {
  createdAt?: string;
};

export const Age: FC<AgeProps> = ({ createdAt }) => {
  const { t } = useTranslation();
  if (!createdAt) {
    return null;
  }

  const diffDays = dayjs().diff(createdAt, 'day');
  return t('UNITS.DAY', { count: diffDays });
};
