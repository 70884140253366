export enum ConcessionType {
  FreeMonths = 'FREE_MONTHS',
  NoUpliftOnRenewal = 'NO_UPLIFT_ON_RENEWAL',
  AdditionalFeatures = 'ADDITIONAL_FEATURES',
  WaivedImplementation = 'WAIVED_IMPLEMENTATION',
  ContractualConcessions = 'CONTRACTUAL_CONCESSIONS',
  BreakClauses = 'BREAK_CLAUSES',
  OneTimeDiscounts = 'ONE_TIME_DISCOUNTS',
  DiscountedOverages = 'DISCOUNTED_OVERAGES',
  Other = 'OTHER',
}

export enum ConcessionStatus {
  InProgress = 'IN_PROGRESS',
  Realised = 'REALISED',
}
