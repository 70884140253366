import React, { useState } from 'react';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { ButtonProps, Stack } from '@mui/material';
import { Text, EllipsisText } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@verticeone/design-system/src';
import { VendorsPageProduct } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const CollapseButton = ({ isOpen, ...props }: Partial<IconButtonProps> & { isOpen: boolean }) => {
  return (
    <IconButton
      {...props}
      size="M"
      variant="plain"
      aria-label={isOpen ? 'Close' : 'Open'}
      sx={{ margin: '0 -6px' }}
      icon={isOpen ? ExpandMore : ChevronRight}
    />
  );
};

const Category = (props: GridRenderCellParams) => {
  const { id, field, rowNode, row } = props;
  const apiRef = useGridApiContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'VENDOR.CONTRACTS' });
  const [isOpen, setOpen] = useState(true);

  const handleCollapseButtonClick: ButtonProps['onClick'] = (event) => {
    setOpen(!isOpen);
    'childrenExpanded' in rowNode && apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Stack direction="row" alignItems="center">
      <CollapseButton onClick={handleCollapseButtonClick} isOpen={isOpen} />
      <Text variant="body-bold" size="M" color="primary1">
        {t('LABELS.CONTRACT_ORD', { ord: row.ord })}
      </Text>
    </Stack>
  );
};

const Products = (props: GridRenderCellParams) => {
  const productNames = props.row.products?.map((product: VendorsPageProduct) => product.productName);

  if (!productNames || productNames.length === 0) {
    return (
      <Text variant="body-regular" size="M" color="inactive2">
        —
      </Text>
    );
  }

  return (
    <EllipsisText variant="body-regular" size="M">
      {productNames.join(', ')}
    </EllipsisText>
  );
};

const ProductsCell = (props: GridRenderCellParams) => {
  const { rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  return filteredDescendantCount > 0 ? <Category {...props} /> : <Products {...props} />;
};

export default ProductsCell;
