import { WorkflowStage } from '../../types';

const stageOrder: WorkflowStage[] = ['REQUIREMENTS_GATHERING', 'NEGOTIATING', 'APPROVAL', 'CONTRACTING', 'COMPLETED'];

const getOrdinalPhase = (stageName: string | undefined) => {
  let matchedIndex = -1;
  if (stageName !== undefined && stageName) {
    matchedIndex = (stageOrder as string[]).indexOf(stageName);
  }

  return {
    phase: matchedIndex < 0 ? null : matchedIndex + 1,
    outOf: stageOrder.length,
  };
};

export { getOrdinalPhase };
