import { ContractColumnsList } from './ContractColumnsList';
import { useController } from 'react-hook-form';

export const FormContractColumnsList = () => {
  const {
    field: { value, onChange },
  } = useController({ name: 'columns' });

  return <ContractColumnsList value={value} onChange={onChange} />;
};
