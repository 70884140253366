import React, { ReactNode } from 'react';
import { useController, FieldValues } from 'react-hook-form';
import { CommonFormFieldProps } from '../../types';
import { ProductList } from '@vertice/core/src/modules/saas/contract/components/ProductList';
import { ProductItem, ProductListColumn } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useProductExtraColumnBuilder } from '../../../saas/contract/components/ProductList/hooks/useProductExtraColumnBuilder';
import { isNotNil } from '@verticeone/utils/validation';

type FormProductsFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  vendorId?: string;
  currency?: string;
  editable?: boolean;
  showAnnualCost?: boolean;
  showLicenseType?: boolean;
  detailRowRenderer?: (params: GridRowParams) => ReactNode;
  productCellDecorator?: (row: ProductItem) => ReactNode;
};

export type FormProductsFieldComponentType<FormDataType extends FieldValues> = (
  props: FormProductsFieldProps<FormDataType>
) => JSX.Element;

const FormProductsField = <FormDataType extends FieldValues = never>({
  name,
  required,
  editable,
  vendorId,
  currency,
  showAnnualCost,
  showLicenseType,
  detailRowRenderer,
  productCellDecorator,
}: FormProductsFieldProps<FormDataType>) => {
  const { buildColumn } = useProductExtraColumnBuilder();
  const {
    field: { ref, value, ...field },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
    },
  });

  return (
    <ProductList
      withActions={editable}
      extraColumns={[
        showLicenseType ? buildColumn(ProductListColumn.LICENSE_TYPE) : null,
        showAnnualCost ? buildColumn(ProductListColumn.ANNUAL_COST) : null,
        buildColumn(ProductListColumn.NUMBER_OF_LICENSES),
      ].filter(isNotNil)}
      detailRowRenderer={detailRowRenderer}
      productCellDecorator={productCellDecorator}
      selectedProducts={(value as ProductItem[]) || []}
      setSelectedProducts={(newValue) => {
        field.onChange(newValue);
      }}
      currency={currency}
      vendorId={vendorId}
    />
  );
};

export default FormProductsField;
