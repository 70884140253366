import React from 'react';
import { FieldPath, FieldValues, useFormContext, useFormState, Validate } from 'react-hook-form';
import TextFieldBase from '@mui/material/TextField/TextField';
import type { BaseTextFieldProps } from '@mui/material/TextField/TextField';

export type TextFieldProps<FormDataType extends FieldValues> = BaseTextFieldProps & {
  id: FieldPath<FormDataType>;
  required?: boolean;
  validate?: Validate<string, FormDataType> | Record<any, Validate<string, FormDataType>>;
};

/** @deprecated use FormTextField instead */
const TextField = <FormDataType extends FieldValues = never>({
  id,
  validate,
  required,
  ...otherProps
}: TextFieldProps<FormDataType>) => {
  const { register } = useFormContext<FormDataType>();
  const { isSubmitted, errors } = useFormState<FormDataType>();

  return (
    <TextFieldBase
      {...register(id, {
        required: required ?? false,
        validate,
      })}
      id={id}
      fullWidth
      error={isSubmitted && !!errors[id]?.message}
      helperText={isSubmitted && (errors[id]?.message as string)}
      InputProps={{ sx: { height: '2.5rem' } }}
      {...otherProps}
    />
  );
};

export default TextField;
