import styled from '@mui/material/styles/styled';
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow';
import { testProps } from '../../utils/testProperties';
import { TestProps } from '../../types';

export type TableRowProps = MuiTableRowProps & TestProps;

const StyledTableRow = styled(MuiTableRow)(({ theme: { palette } }) => {
  const headerBorder = `1px solid ${palette.core.color3}`;
  const border = `1px solid ${palette.core.color2}`;
  return {
    '&:hover': {
      backgroundColor: palette.input.color1,
    },
    '&:not(:last-child) .MuiTableCell-root': {
      borderBottom: border,
    },
    '.MuiTableCell-root:not(:last-child) ': {
      borderRight: border,
    },

    '.MuiTableCell-head': {
      borderBottom: headerBorder,
    },
    '.MuiTableCell-head:not(:last-child) ': {
      borderRight: headerBorder,
    },

    '.MuiTableCell-footer': {
      borderTop: border,
    },
    '.MuiTableCell-footer:not(:last-child)': {
      borderRight: border,
    },
  };
});

export const TableRow = ({ children, testId, ...otherProps }: TableRowProps) => (
  <StyledTableRow {...otherProps} {...testProps(testId, 'table-row')}>
    {children}
  </StyledTableRow>
);
