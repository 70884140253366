import type { Property } from 'csstype';
import type { CSSObject } from '@mui/material';

export type GradientBorderProps = {
  radius: Property.BorderRadius;
  width?: Property.BorderWidth;
  color: string;
  transition?: string;
};

/**
 * Custom implementation of border that supports gradients. Built for styled components.
 * @example
 * '.MuiFilledInput-root': {
 *   color: 'green',
 *   ...gradientBorder({
 *     width: '2px',
 *     color: 'linear-gradient(45deg, red, blue)',
 *     radius: '8px',
 *     transition: '200ms cubic-bezier(0.0,0,0.2,1)',
 *   }),
 * },
 */
export const gradientBorder = ({ radius, width = '1px', color, transition }: GradientBorderProps) => ({
  position: 'relative' as Property.Position,
  borderRadius: radius,
  // Transparent border just to hold space
  border: `${width} solid transparent`,

  '&::before': {
    content: '""',
    position: 'absolute' as Property.Position,
    inset: '0',
    borderRadius: radius,
    padding: width,
    background: color,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none',

    transition: transition ? `background ${transition}` : 'none',

    top: `-${width}`,
    right: `-${width}`,
    bottom: `-${width}`,
    left: `-${width}`,
  },
});

/**
 * Helper for overriding gradient border's color. Mental equivalent of border-color CSS property.
 */
export const gradientBorderColor = (color: string): CSSObject => ({
  '&::before': { background: color },
});
