import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WithRequired } from '../../types/WithRequired';
import { Notification } from './types';

export type NotificationsState = {
  notifications: Array<WithRequired<Notification, 'id'>>;
};

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      const isNotificationExist = state.notifications.some(({ id }) => id === action.payload.id);
      if (isNotificationExist) return state;
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload,
            id: action.payload.id || (new Date().getTime() + Math.random()).toString(),
          },
        ],
      };
    },
    removeNotification: (state, action: PayloadAction<string>) => ({
      ...state,
      notifications: state.notifications.filter((item) => item.id !== action.payload),
    }),
  },
});

export const { addNotification, removeNotification } = notificationsSlice.actions;

export const selectNotifications = ({ notifications }: { notifications: NotificationsState }) =>
  notifications.notifications;

export default notificationsSlice.reducer;
