import React from 'react';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Text,
} from '@verticeone/design-system/src';
import { Grid } from '@mui/material';
import { WorkflowIcon } from '@vertice/assets';
import i18n from '@vertice/i18n/src/i18n';
import { Trans, useTranslation } from 'react-i18next';

export const WorkflowTriggersCard = () => {
  const { t } = useTranslation();

  // TODO: Get the workflow we want to support.
  // Probably based on part of urn: urn:verticeone:vertice:8abda36c-0b2b-4cfd-8e92-d16d95ab07f8:services:service/saas/purchase/v4
  // => service/saas/purchase
  const workflows = [
    {
      name: 'Saas - Mew Purchase',
      id: '1',
    },
  ];

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle
          icon={WorkflowIcon}
          text={
            <Text variant="heading" size="S" color="text1">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKFLOW_TRIGGERS.CARD_HEADER')}
            </Text>
          }
        />
        <CardHeaderDescription>
          <Trans
            i18n={i18n}
            i18nKey={t(
              'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKFLOW_TRIGGERS.CARD_SUB_HEADER_DESCRIPTION'
            )}
            components={{ bold: <Text variant="body-bold" size="S" /> }}
          />
        </CardHeaderDescription>
      </CardHeader>
      <Grid container spacing={6} sx={{ p: 6 }}>
        <Grid item xs={12} sm={6}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKFLOW_TRIGGERS.COLUMN_WORKFLOW')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workflows.map((workflow) => (
                  <TableRow key={workflow.id}>
                    <TableCell>{workflow.name}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Checkbox color="primary" sx={{ display: 'inline-flex' }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
};
