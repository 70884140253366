import { DrawerHeader } from '@verticeone/design-system/src';
import { Drawer, DrawerBody, DrawerHeaderTitle } from '@verticeone/design-system/src';
import { CardHeaderActions } from '@verticeone/design-system/src';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system/src';
import { useSimpleDialogContext } from '@verticeone/design-system/src';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { FormSchemaProvider } from '@vertice/core/src/modules/forms/schema/FormSchemaContext';
import { Grid } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import Stack from '@mui/material/Stack';
import { ViewSettingsFormData, viewSettingsFormSchema } from './schema';
import ViewSettingsFormEntry from './ViewSettingsFormEntry';
import FormContractRollingFrequencyMultiselect from './formFields/FormContractRollingFrequencyMultiselect';
import FormContractStageMultiselect from './formFields/FormContractStageMultiselect';
import { useCustomViewUpdate } from '../hooks/useCustomViewUpdate';
import { useCustomViewCreate } from '../hooks/useCustomViewCreate';

import { CustomFilterView } from '../types';
import { FormContractColumnsList } from './formFields/FormContractColumnsList';
import { ContractListColumn } from '../../../types';
import { FormEntrySkeleton } from '@vertice/core/src/modules/forms/fields/FormEntry/FormEntrySkeleton';

export type ViewSettingsDrawerOpeningState = { mode: 'create' } | { mode: 'update'; viewId: string } | null;

export type ViewSettingsDrawerProps = {
  state: ViewSettingsDrawerOpeningState;
  onClose: () => void;
  onFilterViewCreated?: (filterView: CustomFilterView) => Promise<void> | void;
  onFilterViewUpdated?: (filterView: CustomFilterView) => Promise<void> | void;
  onFilterViewDeleted?: () => void;
};

const DEFAULT_FORM_DATA: ViewSettingsFormData = {
  label: '',
  filters: {
    contractStage: [],
    contractRollingFrequency: [],
  },
  columns: [ContractListColumn.VENDOR],
};

const SubmitButton = ({ children }: PropsWithChildren) => {
  const formState = useFormState<ViewSettingsFormData>();
  const submitButtonProps = getPropsForSubmitButton(formState);

  return (
    <Button
      type="submit"
      variant="solid"
      color="primary"
      isLoading={submitButtonProps.isLoading}
      disabled={submitButtonProps.disabled}
    >
      {children}
    </Button>
  );
};

const FieldsGroupHeader = ({ children }: PropsWithChildren) => (
  <Text component="h3" variant="label" size="XS" color="text3">
    {children}
  </Text>
);

export const ViewSettingsDrawer = ({
  state,
  onClose,
  onFilterViewCreated,
  onFilterViewUpdated,
  onFilterViewDeleted,
}: ViewSettingsDrawerProps) => {
  const { t } = useTranslation();

  const { customView, updateCustomView, deleteCustomView, isFetching, isDeleting } = useCustomViewUpdate({
    filterViewId: state?.mode === 'update' ? state.viewId : undefined,
  });
  const { createCustomView } = useCustomViewCreate();

  const formMethods = useForm<ViewSettingsFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    mode: 'all', // Validate on change, blur, submit
    resolver: zodResolver(viewSettingsFormSchema),
    disabled: state?.mode === 'update' && isFetching,
  });
  const goodFormMethods = useGoodFormUtils(formMethods);

  const { getConfirmation } = useSimpleDialogContext();

  useEffect(() => {
    if (state?.mode === 'update' && customView) {
      formMethods.reset(customView);
    }
  }, [customView, formMethods, state?.mode]);

  const handleClose = () => {
    formMethods.reset(DEFAULT_FORM_DATA);
    onClose();
  };

  const handleDelete = async () => {
    const result = await getConfirmation({
      title: t('ENTITIES.CONTRACT_CUSTOM_VIEW.DIALOGS.DELETE.TITLE'),
      description: t('ENTITIES.CONTRACT_CUSTOM_VIEW.DIALOGS.DELETE.DESCRIPTION'),
      okButton: {
        label: t('DIALOG.BUTTONS.DELETE'),
        color: 'error',
      },
    });

    if (result.status === 'ok') {
      // We don't want to have the drawer open after the view is deleted, otherwise we would get 404 error.
      handleClose();
      await deleteCustomView();
      onFilterViewDeleted?.();
    }
  };

  const handleSubmit = async (data: ViewSettingsFormData) => {
    if (state?.mode === 'create') {
      const createdCustomView = await createCustomView(data);
      if (createdCustomView) {
        await onFilterViewCreated?.(createdCustomView);
      }
    } else {
      const updatedCustomView = await updateCustomView(data);
      if (updatedCustomView) {
        await onFilterViewUpdated?.(updatedCustomView);
      }
    }
    handleClose();
  };

  return (
    <FormSchemaProvider value={viewSettingsFormSchema}>
      <FormProvider {...formMethods}>
        <Drawer width={`max(calc(100% / 12 * ${5}), 650px)`} open={state !== null} onClose={handleClose}>
          <form onSubmit={goodFormMethods.handleSubmit(handleSubmit)}>
            <DrawerHeader>
              <DrawerHeaderTitle
                text={
                  state?.mode === 'create'
                    ? t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CREATE_NEW_CONTRACT_VIEW')
                    : t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.EDIT_CONTRACT_VIEW')
                }
              />
              <CardHeaderActions gap={2}>
                {state?.mode === 'update' && !isFetching && (
                  <Button
                    variant="plain"
                    onClick={handleDelete}
                    color="error"
                    disabled={isDeleting}
                    isLoading={isDeleting}
                  >
                    {t('ENTITIES.CONTRACT_CUSTOM_VIEW.ACTIONS.DELETE_VIEW')}
                  </Button>
                )}
                <Button variant="outline" onClick={handleClose}>
                  {t('DIALOG.BUTTONS.CANCEL')}
                </Button>
                <SubmitButton>
                  {state?.mode === 'create'
                    ? t('ENTITIES.CONTRACT_CUSTOM_VIEW.ACTIONS.CREATE_VIEW')
                    : t('ENTITIES.CONTRACT_CUSTOM_VIEW.ACTIONS.SAVE_CHANGES')}
                </SubmitButton>
              </CardHeaderActions>
            </DrawerHeader>
            <DrawerBody>
              <Stack gap={8}>
                <Grid container spacing={4}>
                  {isFetching ? (
                    <Grid item xs={12}>
                      <FormEntrySkeleton />
                    </Grid>
                  ) : (
                    <ViewSettingsFormEntry
                      name="label"
                      label={t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.VIEW_NAME')}
                      component={FormTextField}
                      componentProps={{
                        placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.VIEW_NAME'),
                        size: 'S',
                      }}
                      width={12}
                    />
                  )}
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FieldsGroupHeader>{t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.VIEW_PROPERTIES')}</FieldsGroupHeader>
                  </Grid>
                  {isFetching ? (
                    <>
                      <Grid item xs={12}>
                        <FormEntrySkeleton />
                      </Grid>
                      <Grid item xs={12}>
                        <FormEntrySkeleton />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <FormContractStageMultiselect />
                      <FormContractRollingFrequencyMultiselect />
                    </>
                  )}
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FieldsGroupHeader>
                      {t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.SELECT_CONTRACT_COLUMNS')}
                    </FieldsGroupHeader>
                  </Grid>
                  <Grid item xs={12}>
                    {isFetching ? <FormEntrySkeleton /> : <FormContractColumnsList />}
                  </Grid>
                </Grid>
              </Stack>
            </DrawerBody>
          </form>
        </Drawer>
      </FormProvider>
    </FormSchemaProvider>
  );
};
