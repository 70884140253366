import { FileMetaData } from '@vertice/slices';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { QuestionerStatus } from './QuestionnaireFile';

interface FileType extends FileMetaData {
  status: QuestionerStatus;
}

export const getFileNames = (files: Array<FileMetaData>) => files.map(({ fileName }) => fileName);
export const getFiles = (questionnaires: Array<FileMetaData>) => questionnaires.filter(({ type }) => type === 'FILE');

export const prepareQuestionnaires = (questionnaires: Array<FileMetaData>): Array<FileType> => {
  const allQuestionnairesFiles = getFiles(questionnaires);

  const allResponses = allQuestionnairesFiles
    .filter(({ dirPath }) => dirPath.split('/legal_docs/')[1].includes('responses'))
    .map((questionnaire) => ({ ...questionnaire, status: 'completed' as QuestionerStatus }));

  const allResponsesFileNames = getFileNames(allResponses);

  const uniqQuestionnaires = allQuestionnairesFiles.reduce((acc: Array<FileType>, questionnaire) => {
    if (!allResponsesFileNames.includes(questionnaire.fileName)) {
      acc.push({ ...questionnaire, status: 'requested' });
    }
    return acc;
  }, []);

  return [...allResponses, ...uniqQuestionnaires].sort((a, b) => {
    if (a.fileName > b.fileName) {
      return 1;
    }
    if (a.fileName < b.fileName) {
      return -1;
    }
    return 0;
  });
};

const complienceDataKeys = [
  'statusUrl',
  'privacyUrl',
  'securityUrl',
  'gdprUrl',
  'complianceUrl',
  'iso27001Url',
  'soc2Url',
  'soc3Url',
];

const legalContractInsightDataKeys = [
  'feesRefundable',
  'feesRefundableClause',
  'paymentTerms',
  'paymentTermsClause',
  'liability',
  'liabilityClause',
  'liabilityLimits',
  'liabilityLimitsClause',
  'warranties',
  'warrantiesClause',
  'autoRenewal',
  'autoRenewalClause',
  'noticePeriod',
  'noticePeriodClause',
  'usageLimits',
  'usageLimitsClause',
  'serviceAgreement',
  'serviceAgreementClause',
  'marketingPermit',
  'marketingPermitClause',
  'governingLaw',
  'governingLawClause',
  'otherConsiderations',
];

const legalDocumentsDataKeys = ['msaUrl', 'msaAgreementUrl', 'dpaUrl', 'subprocessorsUrl'];

const subsetDataAvailable = (keys: Array<string>) => (data: Record<string, unknown>) =>
  !isEmpty(data) && Object.values(pick(data, keys)).some((val) => !isEmpty(val));

export const isComplianceDataAvailable = subsetDataAvailable(complienceDataKeys);
export const isLegalDataAvailable = subsetDataAvailable(legalContractInsightDataKeys.concat(legalDocumentsDataKeys));
export const isLegalContractInsightDataAvailable = subsetDataAvailable(legalContractInsightDataKeys);
export const isDataAvailable = subsetDataAvailable(
  complienceDataKeys.concat(legalContractInsightDataKeys).concat(legalDocumentsDataKeys)
);
