import React, { useCallback, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart, SeriesOptionsType } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import {
  monthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import { AWSSupportFeesData } from './useAWSSupportFeesData';
import { AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';

type AWSSupportFeesGraphProps = {
  data: AWSSupportFeesData;
};

const AWSSupportFeesGraph = ({ data }: AWSSupportFeesGraphProps) => {
  const { locale } = useLocaleContext();
  const { palette } = useTheme();

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const applyStyledHighcharts = useStyledHighcharts();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const getColoredSeries = useGetColoredItems();

  const { values, months, usedCategories } = data;
  const coloredValues = getColoredSeries(values) as unknown as SeriesOptionsWithData[];

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyColumnHover,
        applyXAxisOffset,
        (opts) =>
          mergeOptions(
            {
              chart: { type: 'column' },
              yAxis: {
                labels: {
                  formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY),
                },
                maxPadding: 0,
              },
              xAxis: {
                categories: months,
                labels: { formatter: monthFormatter },
              },
              tooltip: { shared: true },
              series: getSeriesWithBorderRadius(coloredValues, 8) as SeriesOptionsType[],
            },
            opts
          ),
      ]),
    [applyStyledHighcharts, applyColumnHover, applyXAxisOffset, palette, locale, months, coloredValues]
  );

  const legendItems = useMemo<LegendItemData[]>(
    () => extractLegendItemsFromSeries(options, usedCategories),
    [options, usedCategories]
  );

  const tooltipSort = sortTooltipPointsFunction(usedCategories, true);
  const tooltipExtractor = extractTooltipContent(
    tooltipSort,
    currencyFormatter(locale, AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES)
  );

  return (
    <Stack gap={1.5}>
      {legendItems.length > 0 && (
        <Box alignSelf="flex-end" px={6}>
          <Legend items={legendItems} />
        </Box>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          const tooltipContent = tooltipExtractor(context);
          return (
            <TooltipWrapper>
              {tooltipContent.items?.map((item: TooltipItem, index: number) => (
                <TooltipSeriesValuePair
                  seriesColor={item.color}
                  seriesName={item.name}
                  value={item.value}
                  key={item.name || index}
                />
              ))}
              <TooltipTotal value={tooltipContent.total} />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default AWSSupportFeesGraph;
