import { useExchangeCurrency } from '@vertice/core/src/modules/currency/useExchangeCurrency';
import { useWatch } from 'react-hook-form';
import { Money } from '../../shared/schemas';
import { useTaskContext } from '../../../../TaskContext';
import { FormData } from '../schema';

type ReturnType =
  | undefined
  | {
      eligibleForVerticeNegotiation?: boolean;
      threshold?: Money;
    };

export const usePurchaseVerticeNegotiationEligibility = (): ReturnType => {
  const { exchangeCurrency, isLoading: isLoadingExchangeRates } = useExchangeCurrency();
  const { taskOverview } = useTaskContext();

  const verticeNegotiationThreshold = taskOverview?.task?.input?.verticeNegotiationThreshold;
  const approvedBudget = useWatch<FormData, 'approvedBudget'>({ name: 'approvedBudget' });
  const budgetCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });

  if (!verticeNegotiationThreshold || typeof approvedBudget !== 'number' || !budgetCurrency || isLoadingExchangeRates) {
    return undefined;
  }

  const convertedBudget = exchangeCurrency({
    sourceCurrency: budgetCurrency,
    targetCurrency: verticeNegotiationThreshold.currency,
    value: approvedBudget,
  });

  if (typeof convertedBudget === 'number') {
    return {
      eligibleForVerticeNegotiation: convertedBudget > verticeNegotiationThreshold.value,
      threshold: verticeNegotiationThreshold,
    };
  }

  return undefined;
};
