import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryProps } from '../../../ContractFormEntry';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { keyBy, orderBy } from 'lodash';
import { ContractFormData } from '../../../types';
import { ContractClassification } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useListAccountUsersQuery } from '@vertice/slices';
import { useContractContext } from '../../../ContractContext';
import UserReadOnlyListField, { UserReadOnlyListFieldType } from '../../UserReadOnlyListField';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { getSelectOptions } from './utils';
import { SelectOption } from './types';

type ReadComponent = UserReadOnlyListFieldType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, SelectOption, true>;

type ContractWatchersFormEntryProps = Omit<
  ContractFormEntryProps<ReadComponent, WriteComponent>,
  'component' | 'writeComponent' | 'componentProps'
>;

const ContractWatchersFormEntry = (options: ContractWatchersFormEntryProps) => {
  const { t } = useTranslation();
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const accountId = contractContext.accessVia.accountId;
  const { data: usersData } = useListAccountUsersQuery({ accountId: accountId! }, { skip: !accountId });

  const contractOwner = useWatch<ContractFormData>({ name: 'classification.contractOwner' });
  // Create a list of possible watchers excluding the users which are selected as owners
  const contractWatchersOptions = useMemo(() => {
    const usersToExclude = contractOwner ? [contractOwner] : [];
    const filteredOptions = usersData?.users ? getSelectOptions(usersData.users, usersToExclude) : undefined;
    return filteredOptions ? orderBy(filteredOptions, (opt) => opt.label.toLowerCase()) : undefined;
  }, [usersData, contractOwner]);

  const userMap = useMemo(() => keyBy(contractWatchersOptions, 'value'), [contractWatchersOptions]);

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...options}
      component={UserReadOnlyListField}
      componentProps={{
        emptyValue: t('ENTITIES.CONTRACT.PLACEHOLDERS.WATCHERS_NO_ITEMS'),
        getUserData: (value) => {
          const userId = value.userId;
          const userItem = userId ? userMap[userId as string] : undefined;
          return userItem?.user;
        },
      }}
      // TODO: Use our unified @vertice/core/src/modules/user/UserSelect instead
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: contractWatchersOptions,
        isMulti: true,
        boxValue: (values) =>
          values
            ? (values as ContractClassification['contractWatchers'])?.map(({ userId }) => userMap[userId as string])
            : undefined,
        unboxValue: (selectValues) => selectValues?.map((item) => ({ userId: item.value })),
      }}
    />
  );
};

export default ContractWatchersFormEntry;
