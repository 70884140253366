import { useFulfillUserTaskMutation } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useCallback } from 'react';

export const PROCEED_FULFILL_BODY = { result: 'OK' };
export const PROCEED_REVERT_BODY = { result: 'GO_BACK' };
export const PROCEED_CANCEL_BODY = { result: 'CANCEL' };

export const useFulfillUserTask = () => {
  const [fulfillUserTaskMutation, { isLoading: isFulfillingUserTask }] = useFulfillUserTaskMutation();
  const fulfillUserTask = useCallback(
    (accountId: string, taskId: string, input: object = {}, onSuccess?: () => void) => {
      void (async () => {
        await fulfillUserTaskMutation({
          accountId,
          taskId,
          userTaskResult: { ...input },
        });
        onSuccess?.();
      })();
    },
    [fulfillUserTaskMutation]
  );

  return {
    fulfillUserTask,
    isFulfillingUserTask,
  };
};
