import React, { FC } from 'react';
import UserListBase, { UserListProps as UserListPropsBase } from './UserList';
import WithActions, { ForbiddenDeletionProps } from './WithActions';
export type { UpdatedItem, columnName } from './UserList';

interface UserListProps extends UserListPropsBase {
  withActions?: boolean;
  checkAvailableDeletion?: (value: string) => Promise<ForbiddenDeletionProps>;
  addLabel?: string;
  adminMode?: boolean;
}

export const UserList: FC<UserListProps> = ({ withActions, checkAvailableDeletion, addLabel, adminMode, ...rest }) => {
  if (withActions) {
    return (
      <WithActions adminMode={adminMode} {...{ checkAvailableDeletion }}>
        <UserListBase {...rest} />
      </WithActions>
    );
  }

  return <UserListBase {...rest} />;
};
