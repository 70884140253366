import {
  WarningAmberOutlined,
  CheckCircleOutlineOutlined,
  InfoOutlined,
  ErrorOutline,
  Star,
} from '@mui/icons-material';

import { DesignSystemColor, DesignSystemDensity, DesignSystemSeverity, DesignSystemSize } from '../types';

export const exampleIcons = {
  WarningAmberOutlined: WarningAmberOutlined,
  CheckCircleOutlineOutlined: CheckCircleOutlineOutlined,
  InfoOutlined: InfoOutlined,
  ErrorOutline: ErrorOutline,
  Star: Star,
  None: undefined,
};

export const allColors: DesignSystemColor[] = [
  'neutral',
  'primary',
  'secondary',
  'tertiary',
  'transparent',
  'success',
  'warning',
  'error',
  'info',
];

export const allSizes: DesignSystemSize[] = ['XXS', 'XS', 'S', 'M', 'L', 'XL'];
export const allDensities: DesignSystemDensity[] = ['high', 'medium', 'low'];
export const allSeverities: DesignSystemSeverity[] = ['success', 'warning', 'error', 'info'];
