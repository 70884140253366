import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import styles from './IntegrationStatus.module.scss';

interface IntegrationStatusProps {
  icon: ReactNode;
  title: string;
  text?: string;
}

const IntegrationStatus = ({ icon, title, text }: IntegrationStatusProps) => (
  <Stack className={styles.root}>
    <Stack className={styles['icon-container']}>{icon}</Stack>
    <Stack className={styles['text-container']} gap="8px">
      <Text variant="heading" size="M">
        {title}
      </Text>
      {text && (
        <Text variant="body-regular" size="M" className={styles['text-detail']}>
          {text}
        </Text>
      )}
    </Stack>
  </Stack>
);

export default IntegrationStatus;
