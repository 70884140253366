import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import { useDialog } from './DialogContext';
import type { DesignSystemSize } from '../../types';
import { reducedSizes } from '../../guidelines/Sizing/sizings';

type DialogTextWrapperProps = {
  children: ReactNode;
  size?: DesignSystemSize;
};

export const DialogTextWrapper = ({ children, size }: DialogTextWrapperProps) => {
  const dialog = useDialog();
  const dialogSize = size ?? dialog.size;
  const gap = reducedSizes[dialogSize] ?? reducedSizes['M'];

  return <Stack gap={gap}>{children}</Stack>;
};
