import React from 'react';
import { Stack, useTheme } from '@mui/material';

type FunctionProps = {
  children: React.ReactNode;
};

const ActionBar = ({ children }: FunctionProps) => {
  const { palette } = useTheme();

  return (
    <Stack px={6} justifyContent="space-between" direction="row" borderTop={`1px solid ${palette.core.color3}`}>
      {children}
    </Stack>
  );
};

export default ActionBar;
