import { useMemo } from 'react';
import { useDepartmentListContext } from '../DepartmentListContextProvider';
import { User } from '../types';

type UseUserSelectOptionsProps = {
  userIdsToExclude?: string[];
};

const useUserSelectOptions = ({ userIdsToExclude }: UseUserSelectOptionsProps) => {
  const { userMap } = useDepartmentListContext();
  const filteredUsers: User[] = useMemo(() => {
    const allUsers = Object.values(userMap);
    return userIdsToExclude ? allUsers.filter((user) => !userIdsToExclude.includes(user.userId)) : allUsers;
  }, [userMap, userIdsToExclude]);

  return filteredUsers;
};

export default useUserSelectOptions;
