import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { styled, useTheme } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LightbulbTwoToneIcon from '@mui/icons-material/LightbulbTwoTone';

import { Text } from '@verticeone/design-system/src';
import { IconButton } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import { BannerContent } from '@vertice/core/src/components/Banner/BannerContent';
import { Banner } from '@vertice/core/src/components/Banner/Banner';
import { useRecommendationData } from './RecommendationProvider';

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  padding: '2px',
  backgroundColor: theme.palette.core.color1,
  color: theme.palette.core.color6,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SavingsBanner = () => {
  const { palette } = useTheme();
  const [visible, setVisible] = useState(true);
  const {
    item: { empty },
  } = useRecommendationData();

  if (!visible || empty) {
    return null;
  }

  return (
    <Banner color={palette.neutral.color4}>
      <BannerContent
        icon={<StyledIconWrapper size="L" icon={LightbulbTwoToneIcon} />}
        title={
          <Text variant="body-regular" size="M" color="text1">
            <Trans
              i18nKey="CLOUD.CLOUD_RECOMMENDATIONS.SAVINGS_NOTE"
              components={{ bold: <Text variant="body-bold" size="M" color="text1" /> }}
            />
          </Text>
        }
        badge={
          <IconButton
            variant="ghost"
            size="XS"
            color="neutral"
            icon={CloseOutlinedIcon}
            onClick={() => setVisible(false)}
          />
        }
      />
    </Banner>
  );
};

export default SavingsBanner;
