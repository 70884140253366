import React from 'react';
import { useFormState } from 'react-hook-form';
import { FormLabel as FormLabelBase } from '@mui/material';

type FormLabelProps = {
  for: string;
  error?: boolean;
  children: React.ReactNode;
};

const FormLabel = ({ for: id, error, children, ...otherProps }: FormLabelProps) => {
  const { isSubmitted, errors } = useFormState();
  return (
    <FormLabelBase htmlFor={id} error={error || (isSubmitted && Boolean(errors[id]?.message))} {...otherProps}>
      {children}
    </FormLabelBase>
  );
};

export default FormLabel;
