import React from 'react';
import { Divider, Stack } from '@mui/material';

import {
  BuyInstanceDrawer,
  BuyInstanceDrawerGraph,
  BuyInstanceDrawerHeader,
  RecommendedTable,
  CustomizeTable,
  ShoppingTable,
  BuyInstanceDrawerTab,
  BuyInstanceDrawerTabs,
} from '../../components/BuyInstanceDrawer';
import OfferingsProvider from '../../components/providers/OfferingsProvider';

const BuyInstanceDrawerLayout = () => (
  <BuyInstanceDrawer>
    <BuyInstanceDrawerHeader />
    <Stack pb={8} px={8}>
      <OfferingsProvider>
        <BuyInstanceDrawerGraph />
      </OfferingsProvider>
    </Stack>
    <Divider />
    <BuyInstanceDrawerTabs>
      <BuyInstanceDrawerTab tabId="RECOMMENDED">
        <OfferingsProvider
          defaultFilter={{
            offeringType: 'No Upfront',
            duration: 31536000,
          }}
        >
          <RecommendedTable />
        </OfferingsProvider>
      </BuyInstanceDrawerTab>
      <BuyInstanceDrawerTab tabId="CUSTOMIZE" filterByShoppingCart>
        <OfferingsProvider>
          <CustomizeTable />
        </OfferingsProvider>
        <OfferingsProvider>
          <ShoppingTable />
        </OfferingsProvider>
      </BuyInstanceDrawerTab>
    </BuyInstanceDrawerTabs>
  </BuyInstanceDrawer>
);

export default BuyInstanceDrawerLayout;
