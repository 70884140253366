import React, { useEffect } from 'react';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import FormSelectField from '@vertice/core/src/modules/forms/fields/FormSelectField';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { BILLING_FREQUENCIES } from './constants';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import { getStartedMonthsBetweenDates } from '@vertice/core/src/modules/saas/contract/components/ContractLengthComputedField';

export const FixedTermContractFields = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AddExistingContractFormData>();

  const contractLength = watch('contractLength');
  const startDate = watch('startDate');
  const renewalDate = watch('renewalDate');

  useEffect(() => {
    // Contract length is computed for V2 contracts
    const computedContractLength = getStartedMonthsBetweenDates(startDate, renewalDate);
    setValue('contractLength', computedContractLength);
  }, [startDate, renewalDate, setValue]);

  return (
    <>
      <AddExistingContractFormEntry
        name="startDate"
        label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
        component={FormDateField}
        required
      />
      <AddExistingContractFormEntry
        name="signDate"
        label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
        component={FormDateField}
      />
      <AddExistingContractFormEntry
        name="contractLength"
        label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH')}
        component={FormNumberField}
        disabled={true}
        componentProps={{
          minValue: 0,
          unit: t('UNITS.MONTH', { count: contractLength || 0 }),
        }}
      />
      <AddExistingContractFormEntry
        name="billingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
        component={FormSelectField}
      >
        {BILLING_FREQUENCIES.map((freq) => (
          <MenuItem key={freq} value={freq}>
            {t(`ENTITIES.CONTRACT.LABELS.FREQUENCY_${freq}`)}
          </MenuItem>
        ))}
      </AddExistingContractFormEntry>
      <AddExistingContractFormEntry
        name="renewalDate"
        label={t('ENTITIES.CONTRACT.LABELS.RENEWAL_DATE')}
        component={FormDateField}
        required
      />
      <AddExistingContractFormEntry
        name="autoRenewalDate"
        label={t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE')}
        component={FormDateField}
      />
    </>
  );
};
