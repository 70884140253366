import { FC, ReactNode } from 'react';
import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';

import { Direction } from '../../types';

export const ButtonLabel = styled('span')({
  opacity: 0.4,
});

const StyledButtonContent = styled(Stack)<{ $direction?: Direction }>(({ $direction, theme }) => {
  const iconSize = theme.spacing($direction === 'horizontal' ? 4 : 12);
  return {
    flexDirection: $direction === 'horizontal' ? 'row' : 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    svg: {
      width: iconSize,
      height: iconSize,
    },
    ...($direction === 'vertical'
      ? {
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5),
          svg: {
            width: iconSize,
            height: iconSize,
            '&:first-child': {
              marginBottom: theme.spacing(2),
            },
            '&:last-child': {
              marginTop: theme.spacing(2),
            },
          },
        }
      : {}),
  };
});

type BaseButtonContentProps = {
  children: ReactNode;
  direction?: Direction;
};

export const BaseButtonContent: FC<BaseButtonContentProps> = ({ children, direction }) => {
  return <StyledButtonContent $direction={direction}>{children}</StyledButtonContent>;
};
