import { FC } from 'react';
import { DesignSystemColor, DesignSystemSize } from '@verticeone/design-system/src';
import { ChipButton } from '@verticeone/design-system/src';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useTranslation } from 'react-i18next';
import { assertExhausted } from '@verticeone/utils/logic';

const getRequestStatusColor = (status: Request['status']): DesignSystemColor => {
  switch (status) {
    case 'PENDING':
      return 'warning';
    case 'ACTIVE':
      return 'secondary';
    case 'COMPLETED':
      return 'success';
    case 'TERMINATED':
    case 'CANCELLED':
      return 'neutral';
    case 'FAILED':
      return 'error';
    default:
      assertExhausted(status);
      return 'neutral';
  }
};

type RequestStatusChipProps = {
  status: Request['status'];
  customValue?: string;
  variant?: 'ghost' | 'outline' | 'solid';
  size?: DesignSystemSize;
};

export const RequestStatusChip: FC<RequestStatusChipProps> = ({
  status,
  customValue,
  variant = 'ghost',
  size = 'S',
}) => {
  const { t } = useTranslation();
  const customValueTranslated =
    // those two custom workflow result values should be presented as Completed
    customValue && ['COMPLETE', 'REJECTED'].includes(customValue)
      ? t('ENTITIES.WORKFLOW_REQUEST.STATUSES.COMPLETED')
      : customValue;
  const statusValueTranslated = t(`ENTITIES.WORKFLOW_REQUEST.STATUSES.${status}`);
  return (
    <ChipButton variant={variant} size={size} color={getRequestStatusColor(status)} isActive={false}>
      {customValueTranslated || statusValueTranslated}
    </ChipButton>
  );
};
