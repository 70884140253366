import { Card } from '@verticeone/design-system/src';
import { Placeholder } from '@verticeone/design-system/src';
import { Stack } from '@mui/material';
import React from 'react';

export const RequestDetailSkeleton = () => {
  return (
    <Stack gap={4}>
      <Card>
        <Placeholder height={400} />
      </Card>
      <Card>
        <Stack spacing={6} padding={6}>
          {Array.from('abcd').map((i) => (
            <Placeholder width={300} height={52} key={i} />
          ))}
        </Stack>
      </Card>
    </Stack>
  );
};
