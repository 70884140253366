import { GRID_REORDER_COL_DEF, GridPreProcessEditCellProps, GridRowsProp } from '@mui/x-data-grid-pro';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const normalizeValueLabel = (value: string) => value.trim().replace(/\s\s+/g, ' ');

export const preProcessNonEmptyCell =
  (values: CustomContractFieldDropdownOption[]) => (params: GridPreProcessEditCellProps) => {
    const valueId = params.row.id;
    const valueLabel = normalizeValueLabel(params.props.value);
    const isEmpty = !valueLabel;
    const nameExists =
      valueLabel &&
      values?.some((value: CustomContractFieldDropdownOption) => value.label === valueLabel && value.id !== valueId);
    return { ...params.props, error: isEmpty || nameExists };
  };

export const enhanceRowsWithReorderProperty = (rows: GridRowsProp, propName: string) =>
  rows.map((item) => ({ ...item, [GRID_REORDER_COL_DEF.field]: item[propName] }));
