import { useMemo } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useListContractsPaginatedQuery } from '@vertice/slices';
import { orderBy } from 'lodash';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export const useLinkedContracts = ({ contract, skip = false }: { contract: Contract; skip?: boolean }) => {
  const { accountId } = useAccountContext();
  const lineageId = contract.record.lineageId;

  const { data: contractsData, isLoading } = useListContractsPaginatedQuery({ accountId, lineageId }, { skip });

  const linkedContracts = useMemo(() => {
    const sortedLineage = contractsData
      ? orderBy(contractsData.contracts, (view) => view.computed?.chronologicalLineageOrder, 'desc')
      : [];
    // exclude current contract
    return sortedLineage.filter(({ contract: c }) => c.record.contractId !== contract.record.contractId);
  }, [contract.record.contractId, contractsData]);

  return {
    linkedContracts,
    isLoadingLinkedContracts: isLoading,
  };
};
