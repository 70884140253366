import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { UnifiedContractStage } from '../types';

export enum Filter {
  VENDOR_PRODUCT = 'vendorProduct',
  RENEWAL_STATUS = 'renewalStatus',
  THRESHOLD = 'threshold',
  DEPARTMENT = 'department',
  OWNER = 'owner',
  SIGNING_ENTITY = 'signingEntity',
  REGION = 'region',
  ROLLING_FREQUENCY = 'rollingFrequency',
  BILLING_FREQUENCY = 'billingFrequency',
  PAYMENT_TERMS = 'paymentTerms',
  STAGE = 'stage',
  CONTRACT_ORIGIN = 'contractOrigin',
  CONTRACT_CATEGORY = 'category',
  CUSTOM_CHECKBOX_01 = 'custom_checkbox01',
  CUSTOM_CHECKBOX_02 = 'custom_checkbox02',
  CUSTOM_CHECKBOX_03 = 'custom_checkbox03',
  CUSTOM_CHECKBOX_04 = 'custom_checkbox04',
  CUSTOM_CHECKBOX_05 = 'custom_checkbox05',
  CUSTOM_TEXT_01 = 'custom_text01',
  CUSTOM_TEXT_02 = 'custom_text02',
  CUSTOM_TEXT_03 = 'custom_text03',
  CUSTOM_TEXT_04 = 'custom_text04',
  CUSTOM_TEXT_05 = 'custom_text05',
  CUSTOM_DROPDOWN_01 = 'custom_dropdown01',
  CUSTOM_DROPDOWN_02 = 'custom_dropdown02',
  CUSTOM_DROPDOWN_03 = 'custom_dropdown03',
  CUSTOM_DROPDOWN_04 = 'custom_dropdown04',
  CUSTOM_DROPDOWN_05 = 'custom_dropdown05',
}

export type FilterConfiguration = {
  name: Filter;
  enabled: boolean;
  visible: boolean;
};

export type FilterValues = {
  [Filter.VENDOR_PRODUCT]?: string;
  [Filter.RENEWAL_STATUS]?: RenewalStatus[];
  [Filter.THRESHOLD]?: ContractValue[];
  [Filter.DEPARTMENT]?: string[];
  [Filter.OWNER]?: string[];
  [Filter.SIGNING_ENTITY]?: string;
  [Filter.REGION]?: string;
  [Filter.ROLLING_FREQUENCY]?: string[];
  [Filter.BILLING_FREQUENCY]?: string[];
  [Filter.PAYMENT_TERMS]?: string[];
  [Filter.STAGE]?: UnifiedContractStage[];
  [Filter.CONTRACT_ORIGIN]?: string[];
  [Filter.CONTRACT_CATEGORY]?: string[];
  [Filter.CUSTOM_CHECKBOX_01]?: CheckboxFilterValue[];
  [Filter.CUSTOM_CHECKBOX_02]?: CheckboxFilterValue[];
  [Filter.CUSTOM_CHECKBOX_03]?: CheckboxFilterValue[];
  [Filter.CUSTOM_CHECKBOX_04]?: CheckboxFilterValue[];
  [Filter.CUSTOM_CHECKBOX_05]?: CheckboxFilterValue[];
  [Filter.CUSTOM_TEXT_01]?: string;
  [Filter.CUSTOM_TEXT_02]?: string;
  [Filter.CUSTOM_TEXT_03]?: string;
  [Filter.CUSTOM_TEXT_04]?: string;
  [Filter.CUSTOM_TEXT_05]?: string;
  [Filter.CUSTOM_DROPDOWN_01]?: string[];
  [Filter.CUSTOM_DROPDOWN_02]?: string[];
  [Filter.CUSTOM_DROPDOWN_03]?: string[];
  [Filter.CUSTOM_DROPDOWN_04]?: string[];
  [Filter.CUSTOM_DROPDOWN_05]?: string[];
};

export enum RenewalStatus {
  NOT_YET_RENEWED = 'notYetRenewed',
  NOT_YET_RENEWED_DEADLINE_90_DAYS = 'notYetRenewedDeadline90Days',
  RENEWAL_IN_PROGRESS = 'renewalInProgress',
  RENEWAL_WITH_VERTICE_COMPLETED = 'renewalWithVerticeCompleted',
  RENEWAL_OUTSIDE_VERTICE_COMPLETED = 'renewalOutsidedVerticeCompleted',
  PLANNED_FOR_EXPIRATION = 'plannedForExpiration',
}

export enum ContractValue {
  ABOVE_THRESHOLD = 'aboveThreshold',
  BELOW_THRESHOLD = 'belowThreshold',
}

export enum CheckboxFilterValue {
  TRUE = 'true',
  FALSE = 'false',
}

export type ExchangeContractCostFnType = (contract: Contract) => number | undefined;
