import { ComponentProps, PropsWithChildren, ReactNode, useRef, useState } from 'react';
import MuiLink, { LinkProps } from '@mui/material/Link';
import { MoreVertOutlined } from '@mui/icons-material';

import { Text, TextProps } from '../Text';
import { Tooltip } from '../Tooltip';
import { Button, ButtonProps } from '../Button';
import { IconWrapper } from '../IconWrapper';
import { Menu } from '../Menu';

import { usePageHeaderContext } from './PageHeaderContext';
import type { DesignSystemSize } from '../../types';

export type ActionProps = Omit<ButtonProps, 'variant' | 'size' | 'color'>;

export const PrimaryAction = ({ children, ...props }: ActionProps) => {
  const { color, size } = usePageHeaderContext();

  return (
    <Button variant="solid" size={size} color={color} {...props}>
      {children}
    </Button>
  );
};

export const SecondaryAction = ({ children, ...props }: ActionProps) => {
  const { color, size } = usePageHeaderContext();

  return (
    <Button variant="outline" size={size} color={color} {...props}>
      {children}
    </Button>
  );
};

export type MenuActionProps = Omit<ComponentProps<typeof Menu>, 'open' | 'onClick' | 'onClose' | 'anchorEl'>;

export const MenuAction = ({ children, ...props }: MenuActionProps) => {
  const { color, size } = usePageHeaderContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button variant="plain" onClick={() => setMenuOpen(true)} ref={menuRef} size={size} sx={{ width: 32 }}>
        <IconWrapper icon={MoreVertOutlined} />
      </Button>
      <Menu
        color={color}
        open={menuOpen}
        anchorEl={menuRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setMenuOpen(false)}
        {...props}
      >
        {children}
      </Menu>
    </>
  );
};

type LinkActionTooltip = {
  title?: string;
  content?: ReactNode;
};

/**
 * @description LinkAction is a component that renders a link with a tooltip.
 * It is used to render a link in the PageHeader component.
 * You can pass a custom component or wrap it in a Link
 */

export const linkActionSizeMap: Record<DesignSystemSize, DesignSystemSize> = {
  XL: 'L',
  L: 'M',
  M: 'S',
  S: 'XS',
  XS: 'XXS',
  XXS: 'XXS',
};

// prettier-ignore
type LinkActionProps<C extends React.ElementType = 'a'> = PropsWithChildren
  & { tooltip?: LinkActionTooltip; component?: C; }
  & Omit<LinkProps<C>, 'variant' | "color">
  & Pick<TextProps, 'size' | 'color'>
  & { variant?: Exclude<TextProps['variant'], 'code' | 'display'>; };

export const LinkAction = <C extends React.ElementType = 'a'>({
  children,
  tooltip,
  variant = 'body-regular',
  color = 'neutral1',
  ...props
}: LinkActionProps<C>) => {
  const { size } = usePageHeaderContext();
  const linkSize = linkActionSizeMap[size];
  const shouldHideLinkTooltip = !tooltip || (!tooltip.content && !tooltip.title);

  return (
    <MuiLink
      {...props}
      sx={{
        textDecoration: 'none',
        cursor: 'default',
        '&:hover': { textDecoration: 'underline', cursor: 'pointer', color: 'inherit' },
      }}
    >
      <Tooltip {...(tooltip ?? {})} placement="top" size={linkSize} disableHoverListener={shouldHideLinkTooltip}>
        <Text variant={variant} size={linkSize} color={color}>
          {children}
        </Text>
      </Tooltip>
    </MuiLink>
  );
};
