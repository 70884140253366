import React, { FC, useEffect, useState } from 'react';
import { createRequiredContext } from '@verticeone/design-system/src';
import { ContractCategory } from './types';
import { useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type DashboardContextProviderProps = {
  children: string | JSX.Element | JSX.Element[];
};

type CustomFieldsListContextType = {
  allowCategoryChange?: boolean;
  contractCategory: ContractCategory;
  setContractCategory: (category: ContractCategory) => void;
};

const { DashboardContextProvider: RawDashboardContextProvider, useDashboardContext } = createRequiredContext<
  CustomFieldsListContextType,
  'Dashboard'
>('Dashboard');

export { useDashboardContext };

const DashboardContextProvider: FC<DashboardContextProviderProps> = ({ children }) => {
  const { accountId } = useAccountContext();
  const { data } = useGetContractsStatisticsQuery({ accountId });

  const [allowCategoryChange, setAllowCategoryChange] = useState(false);
  const [contractCategory, setContractCategory] = useState<ContractCategory>(ContractCategory.SAAS);

  useEffect(() => {
    if (data) {
      setAllowCategoryChange(!!data.stats?.categories?.other);
    }
  }, [data]);

  const context: CustomFieldsListContextType = {
    allowCategoryChange,
    contractCategory,
    setContractCategory,
  };

  return <RawDashboardContextProvider value={context}>{children}</RawDashboardContextProvider>;
};

export default DashboardContextProvider;
