import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useLatestOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { TopFeaturesBySpendData } from './types';
import { prepareTopFeaturesBySpendData } from './utils';

const CODE = 'CLOUDWATCH_BREAKDOWN';

const useCloudWatchFeaturesBySpendData = (): LoadableAdvanced<TopFeaturesBySpendData> => {
  const { accountId } = useSelector(getAccount);

  const {
    data: rawData,
    error: dataError,
    isLoading,
  } = useLatestOptimizationCheckQuery(
    {
      accountId: accountId!,
      checkCode: CODE,
    },
    { skip: !accountId }
  );

  const computed = useMemo(() => prepareTopFeaturesBySpendData(rawData), [rawData]);

  return {
    error: dataError,
    isEmpty: Boolean(
      !rawData ||
        rawData?.monitoringLatestQuery?.__typename !== 'MonitoringResult' ||
        !rawData.monitoringLatestQuery.items
    ),
    isLoading,
    data: computed,
  };
};

export default useCloudWatchFeaturesBySpendData;
