import type { DesignSystemSize } from '../../../types';

export const toolbarSizeDict: Record<DesignSystemSize, `${number}px`> = {
  XL: '10px',
  L: '9px',
  M: '8px',
  S: '7px',
  XS: '6px',
  XXS: '5px',
};
