import { DateRangePickerDayProps } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { Dayjs } from 'dayjs';
import { DesignSystemColor, DesignSystemSize } from '../../../types';
import styled from '@mui/material/styles/styled';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay/DateRangePickerDay';
import { dateRangePickerDayClasses, pickersDayClasses } from '@mui/x-date-pickers-pro';
import { getTextVariantStyle } from '../../Text';
import { SIZE_DEFINITION } from '../constants';
import { useStaticDateRangePickerContext } from '../context/StaticDateRangePickerContext';

export type CustomDateRangePickerDayProps = DateRangePickerDayProps<Dayjs> & {
  $size: DesignSystemSize;
  $color: DesignSystemColor;
};

const StyledDateRangePickerDay = styled(MuiDateRangePickerDay)<CustomDateRangePickerDayProps>(
  ({
    theme: { palette },
    isHighlighting,
    isStartOfHighlighting,
    isPreviewing,
    isStartOfPreviewing,
    isEndOfPreviewing,
    isEndOfHighlighting,
    outsideCurrentMonth,
    selected,
    $size,
    $color,
  }) => ({
    [`&.${dateRangePickerDayClasses.root}`]: {
      width: SIZE_DEFINITION[$size].calendarDaySize.width,
      height: SIZE_DEFINITION[$size].calendarDaySize.height,
      overflow: 'hidden',
      backgroundColor: 'transparent',
      borderTopRightRadius: isEndOfHighlighting || isEndOfPreviewing ? 8 : 0,
      borderBottomRightRadius: isEndOfHighlighting || isEndOfPreviewing ? 8 : 0,
      borderTopLeftRadius: isStartOfHighlighting || isStartOfPreviewing ? 8 : 0,
      borderBottomLeftRadius: isStartOfHighlighting || isStartOfPreviewing ? 8 : 0,
      '&:focus, &:hover': {
        borderTopRightRadius:
          isEndOfHighlighting || isEndOfPreviewing || (isEndOfHighlighting && isStartOfHighlighting) ? 8 : 0,
        borderBottomRightRadius:
          isEndOfHighlighting || isEndOfPreviewing || (isEndOfHighlighting && isStartOfHighlighting) ? 8 : 0,
        borderTopLeftRadius:
          isStartOfHighlighting || isStartOfPreviewing || (isEndOfHighlighting && isStartOfHighlighting) ? 8 : 0,
        borderBottomLeftRadius:
          isStartOfHighlighting || isStartOfPreviewing || (isEndOfHighlighting && isStartOfHighlighting) ? 8 : 0,
      },
    },
    [`:last-of-type .${dateRangePickerDayClasses.rangeIntervalDayPreview}, :first-of-type .${dateRangePickerDayClasses.rangeIntervalDayPreview}`]:
      {
        borderRadius: 0,
      },
    [`.${dateRangePickerDayClasses.rangeIntervalDayPreviewEnd}`]: {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    [`.${dateRangePickerDayClasses.rangeIntervalDayPreviewStart}`]: {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    [`.${dateRangePickerDayClasses.day}`]: {
      ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
      color: isHighlighting ? palette[$color].color1 : palette.neutral.color1,
      width: SIZE_DEFINITION[$size].calendarDaySize.width,
      height: SIZE_DEFINITION[$size].calendarDaySize.height,
      margin: '-2px',
      borderRadius: 0,
      transform: 'none',
      '&:focus, &:hover': {
        color: palette.neutral.hover.color1,
        backgroundColor: palette.neutral.hover.color4,
      },
    },
    [`.${pickersDayClasses.root}.${dateRangePickerDayClasses.day}`]: {
      border: 'none',
      '&:focus, &:hover': {
        border: 'none',
      },
    },
    [`.Mui-selected.${dateRangePickerDayClasses.day}`]: {
      ...getTextVariantStyle({ variant: 'body-bold', size: $size }),
      color: palette[$color].color4,
      backgroundColor: palette[$color].color2,
      '&:hover, &:focus': {
        color: palette.neutral.hover.color1,
        backgroundColor: palette.neutral.hover.color4,
      },
    },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlight}`]: {
      borderRadius: 0,
      backgroundColor: outsideCurrentMonth ? 'transparent' : palette[$color].color4,
    },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlightStart}, &.${dateRangePickerDayClasses.rangeIntervalDayPreviewStart}`]:
      {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        '&:focus, &:hover': {
          borderTopRightRadius:
            isEndOfHighlighting && isStartOfHighlighting && !isStartOfPreviewing && !isEndOfPreviewing ? 8 : 0,

          borderBottomRightRadius:
            isEndOfHighlighting && isStartOfHighlighting && !isStartOfPreviewing && !isEndOfPreviewing ? 8 : 0,
        },
      },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd}, &.${dateRangePickerDayClasses.rangeIntervalDayPreviewEnd}`]:
      {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        '&:focus, &:hover': {
          borderTopLeftRadius:
            isEndOfHighlighting && isStartOfHighlighting && !isStartOfPreviewing && !isEndOfPreviewing ? 8 : 0,
          borderBottomLeftRadius:
            isEndOfHighlighting && isStartOfHighlighting && !isStartOfPreviewing && !isEndOfPreviewing ? 8 : 0,
        },
      },
    [`.${dateRangePickerDayClasses.notSelectedDate}, .${dateRangePickerDayClasses.dayOutsideRangeInterval}`]: {
      borderRadius: isPreviewing || isHighlighting ? 0 : 8,
      '&:focus, &:hover': {
        borderRadius: isPreviewing || isHighlighting ? 0 : 8,
      },
    },
    [`.${pickersDayClasses.today}`]: {
      position: 'relative',
      '::after': {
        content: '""',
        position: 'absolute',
        bottom: SIZE_DEFINITION[$size].todayBottomOffset,
        left: '50%',
        transform: 'translateX(-50%)',
        width: SIZE_DEFINITION[$size].todaySize,
        height: SIZE_DEFINITION[$size].todaySize,
        borderRadius: '50%',
        backgroundColor: selected ? palette[$color].color1 : palette[$color].color2,
      },
    },
    [`.${pickersDayClasses.today}:hover`]: {
      '::after': {
        backgroundColor: palette[$color].color2,
      },
    },
  })
);

const CustomDateRangePickerDay = (props: DateRangePickerDayProps<Dayjs>) => {
  const { size, color } = useStaticDateRangePickerContext();

  return <StyledDateRangePickerDay {...props} $size={size} $color={color} />;
};

export default CustomDateRangePickerDay;
