import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.EVENTS),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
