import { FormControl, Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import React, { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useRenewalVerticeNegotiationEligibility } from '../formHooks/useRenewalVerticeNegotiationEligibility';
import { useFormContext } from 'react-hook-form';
import { FormData } from '../schema';
import { useTaskFormContext } from '../../shared/TaskFormContext';

export const VerticeRenewalNegotiationThresholdSection = () => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();
  const formatCurrency = useFormatCurrency();
  const { setValue } = useFormContext<FormData>();
  const negotiationEligibility = useRenewalVerticeNegotiationEligibility();

  useEffect(() => {
    if (negotiationEligibility?.eligibleForVerticeNegotiation === undefined) {
      return;
    }

    if (negotiationEligibility.eligibleForVerticeNegotiation) {
      setValue('verticeNegotiationRequested', false);
    } else {
      setValue('internalNegotiationRequested', false);
    }
  }, [negotiationEligibility?.eligibleForVerticeNegotiation, setValue]);

  if (negotiationEligibility === undefined) {
    return null;
  }

  const { threshold: verticeNegotiationThreshold, eligibleForVerticeNegotiation } = negotiationEligibility;

  return (
    <Stack gap={3}>
      <Text variant="body-regular" size="S">
        <Trans
          i18nKey={`INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.THRESHOLD_${
            eligibleForVerticeNegotiation ? 'ABOVE' : 'BELOW'
          }`}
          components={{ bold: <b /> }}
          values={{
            accountThreshold: verticeNegotiationThreshold
              ? formatCurrency(verticeNegotiationThreshold.value, {
                  currency: verticeNegotiationThreshold.currency,
                  maximumFractionDigits: 0,
                })
              : '',
          }}
        />
      </Text>
      {eligibleForVerticeNegotiation && (
        <>
          <FormControl variant="outlined">
            <FormChecklistItemField
              name={'internalNegotiationRequested'}
              label={t(`INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.THRESHOLD_ABOVE_OVERRIDE_LABEL`)}
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              size={'S'}
              disabled={readOnly}
            />
          </FormControl>
          <Alert
            icon={InfoOutlinedIcon}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            title={
              <Trans
                i18nKey="INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.NEGOTIATION_SAFE_HARBOR"
                components={{ break: <br /> }}
              />
            }
            variant="ghost"
            size="S"
          />
        </>
      )}
    </Stack>
  );
};
