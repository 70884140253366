import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import { useOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeferredQuery } from '@verticeone/utils/api';
import { DATE_FORMAT } from '../../utils/graphDataUtils';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { prepareHistoricalSpendData } from './utils';
import { CHECK_CODE } from './constants';
import { HistoricalSpendData } from './types';

const startDate = addMonths(startOfMonth(new Date()), -12);

const useCloudWatchHistoricalSpendData = (): LoadableAdvanced<HistoricalSpendData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_WATCH_HISTORICAL_SPEND' });
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error: dataError } = useDeferredQuery(
    useOptimizationCheckQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      checkCode: CHECK_CODE,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ checkQuery }) => checkQuery
  );

  const computed = useMemo(() => prepareHistoricalSpendData(rawData, startDate, t('OTHERS')), [rawData, t]);

  return {
    error: dataError,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useCloudWatchHistoricalSpendData;
