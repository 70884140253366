import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyAllOutlined, DeleteOutlined, MoreVert, RemoveRedEyeOutlined } from '@mui/icons-material';

import { Menu, MenuItem } from '@verticeone/design-system/src';
import { IconButton } from '@verticeone/design-system/src';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { WorkflowVersionRow } from '../types';
import { WorkflowPreviewDialog } from './WorkflowPreviewDialog';
import { useDeleteWorkflowVersion } from './hooks/useDeleteWorkflowVersion';
import { useDuplicateWorkflowVersion } from './hooks/useDuplicateWorkflowVersion';

type DraftActionsButtonProps = {
  row: WorkflowVersionRow;
};

export const DraftActionsButton: FC<DraftActionsButtonProps> = ({ row }) => {
  const { t } = useTranslation();

  const buttonRef = React.useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const { deleteWorkflowVersion, isDeletingWorkflowVersion } = useDeleteWorkflowVersion(row, {
    onComplete: () => {
      setIsMenuOpen(false);
    },
  });

  const { duplicateWorkflow, isDuplicatingWorkflow } = useDuplicateWorkflowVersion(row, {
    onComplete: () => {
      setIsMenuOpen(false);
    },
  });

  const handlePreview = () => {
    setPreviewOpen(true);
    setIsMenuOpen(false);
  };

  return (
    <>
      <IconButton
        icon={MoreVert}
        variant="plain"
        ref={buttonRef}
        onClick={() => setIsMenuOpen(true)}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      />
      <Menu open={isMenuOpen} anchorEl={buttonRef.current} onClose={() => setIsMenuOpen(false)}>
        <MenuItem startIcon={RemoveRedEyeOutlined} onClick={handlePreview}>
          {t('ENTITIES.WORKFLOW_VERSION.ACTIONS.PREVIEW')}
        </MenuItem>
        <MenuItem startIcon={CopyAllOutlined} onClick={duplicateWorkflow} disabled={isDuplicatingWorkflow}>
          {t(
            isDuplicatingWorkflow
              ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DUPLICATING'
              : 'ENTITIES.WORKFLOW_VERSION.ACTIONS.DUPLICATE'
          )}
        </MenuItem>
        <MenuItem startIcon={DeleteOutlined} onClick={deleteWorkflowVersion} disabled={isDeletingWorkflowVersion}>
          {t(
            isDeletingWorkflowVersion
              ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DELETING'
              : 'ENTITIES.WORKFLOW_VERSION.ACTIONS.DELETE'
          )}
        </MenuItem>
      </Menu>
      {previewOpen && <WorkflowPreviewDialog previewRow={row} onClose={() => setPreviewOpen(false)} />}
    </>
  );
};
