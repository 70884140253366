import { useCloudIntegrationQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useSelector } from 'react-redux';
import { Loadable } from '@verticeone/utils/async';

/**
 * Returns info whether cloud integration is installed & configured.
 */
const useCloudIntegrationStatus = ({ skip }: { skip?: boolean }): Loadable<{ isActive: boolean }> => {
  const { accountId } = useSelector(getAccount);
  const { data, isLoading, error } = useCloudIntegrationQuery({ accountId: accountId! }, { skip: !accountId || skip });

  return isLoading && !error
    ? { isLoading: true }
    : {
        isLoading: false,
        data: {
          isActive:
            data?.integrationQuery?.__typename === 'IntegrationResult' && data?.integrationQuery?.status === 'ACTIVE',
        },
      };
};

export default useCloudIntegrationStatus;
