import { isNil } from 'lodash';

const reachedMaxNumberOfCustomViews = ({
  numberOfCustomFilterViews,
  maxNumberOfCustomFilterViews,
}: {
  numberOfCustomFilterViews: number;
  maxNumberOfCustomFilterViews?: number;
}) => !isNil(maxNumberOfCustomFilterViews) && numberOfCustomFilterViews >= maxNumberOfCustomFilterViews;

export default reachedMaxNumberOfCustomViews;
