import React from 'react';
import { Stack, Typography } from '@mui/material';
import ColorSquare from '../ColorSquare';
import { PatternColorSquareProps } from '../PatternColorSquare';

type TooltipSeriesValuePairProps = {
  seriesColor?: string | PatternColorSquareProps;
  seriesName?: string;
  value: string;
  borderColor?: string;
};

const TooltipSeriesValuePair = ({ seriesColor, seriesName, value, borderColor }: TooltipSeriesValuePairProps) => {
  return (
    <Stack direction="row" gap="7px" alignItems="center">
      {seriesColor && <ColorSquare color={seriesColor} borderColor={borderColor} />}
      {seriesName && (
        <Typography variant="body-regular-s" flex={1}>
          {seriesName}
        </Typography>
      )}
      <Typography variant="body-bold-s">{value}</Typography>
    </Stack>
  );
};

export default TooltipSeriesValuePair;
