import { ElementType, ReactNode } from 'react';
import { Button, ButtonProps, DefaultComponentType } from '../Button';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';

type Props<C extends ElementType = DefaultComponentType> = Omit<
  ButtonProps<C>,
  'color' | 'size' | 'variant' | 'children'
> & {
  text: ReactNode;
};

export const NoDataButton = ({ text, onClick, disabled = false }: Props) => (
  <Button color="neutral" size="M" onClick={onClick} variant="solid" disabled={disabled}>
    {text}
    <ChevronRight opacity={0.6} />
  </Button>
);
