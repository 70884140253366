import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { CancelOutlined, RemoveShoppingCartOutlined } from '@mui/icons-material';

import { Loader } from '@verticeone/design-system/src';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import EmptyStateError from '@vertice/dashboard/src/modules/cloud/components/EmptyStateError';
import { NoData } from '@verticeone/design-system/src';

interface LoadablePurchaseOptionsWrapperProps {
  error: unknown;
  isEmpty?: boolean;
  isLoading: boolean;
  isFilterApplied?: boolean;
  isRecommended?: boolean;
  isRiCoverageAppropriate?: boolean;
  isShoppingCartEmpty?: boolean;
  children: React.ReactNode;
}

export const LoadableComponent = ({
  error,
  isEmpty,
  isLoading,
  isFilterApplied,
  isRiCoverageAppropriate,
  isShoppingCartEmpty,
  children,
  ...otherProps
}: LoadablePurchaseOptionsWrapperProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_OPTIMIZATION' });
  const {
    palette: {
      tertiary: { color2: color },
    },
  } = useTheme();

  if (!!error || isLoading || isEmpty || isRiCoverageAppropriate || isShoppingCartEmpty) {
    return (
      <Box display="flex" justifyContent="center" p={6} flexGrow={1}>
        {!!error ? (
          <EmptyStateError />
        ) : isLoading ? (
          <Loader color={AWS_BRAND_COLOR} />
        ) : isFilterApplied && isEmpty ? (
          <NoData icon={<CancelOutlined htmlColor={color} />} header={t('NO_PURCHASE_OPTIONS_WITH_APPLIED_FILTERS')} />
        ) : isRiCoverageAppropriate ? (
          <NoData icon={<CancelOutlined htmlColor={color} />} header={t('NO_RIS_RECOMMENDED')} />
        ) : isShoppingCartEmpty ? (
          <NoData icon={<RemoveShoppingCartOutlined htmlColor={color} />} header={t('EMPTY_SHOPPING_CART')} />
        ) : (
          <NoData icon={<CancelOutlined htmlColor={color} />} header={t('DATA_UNAVAILABLE')} />
        )}
      </Box>
    );
  }

  return (
    <Stack flexDirection="column" {...otherProps}>
      {children}
    </Stack>
  );
};
