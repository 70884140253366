import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const FEATURE_RIO = FEATURES.AWS_RIO;
const DEFAULT_RIO_PURCHASE_DELAY = 72;

const useAwsRioSubFeatures = () => {
  const { getFeature, isLoading } = useFeatures();

  if (isLoading) {
    return {
      isFetching: true,
      data: null,
    };
  }

  return {
    isFetching: false,
    data: {
      // check to avoid NaN
      rioPurchaseDelay: !isNaN(Number(getFeature(FEATURE_RIO)?.properties?.rioPurchaseDelay))
        ? Number(getFeature(FEATURE_RIO)?.properties?.rioPurchaseDelay)
        : DEFAULT_RIO_PURCHASE_DELAY,
    },
  };
};

export default useAwsRioSubFeatures;
