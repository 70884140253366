import React from 'react';

import { Card } from '@verticeone/design-system/src';
import { Placeholder } from '@verticeone/design-system/src';

import { LinkedContractContent } from './LinkedContractContent';
import { useGetContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { useRequestContext } from '../../RequestContext';
import { WidgetProps } from '../shared/types';

export const LinkedContractsSection = ({ widgetData }: WidgetProps) => {
  const { request } = useRequestContext();
  const accountId = request ? parseRequestRef(request.ref).accountId : undefined;
  const parentAccountId = widgetData?.parentAccountId;
  const effectiveAccountId = parentAccountId ?? accountId;

  const contractId = widgetData?.contractId as string | undefined;

  const { data: contractData } = useGetContractQuery(
    { contractId: contractId!, accountId: effectiveAccountId },
    { skip: contractId === undefined }
  );

  const contract = contractData?.viewOnContract.contract;

  if (contractId === undefined) {
    return undefined;
  }

  return contract === undefined ? (
    <Placeholder height={437} />
  ) : (
    <Card>
      <LinkedContractContent contract={contract} />
    </Card>
  );
};
