import { ActivityFrequency } from './components/ActivityFrequency/utils';
import { ReactElement } from 'react';

export enum HealthFilter {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
  ALL = 'ALL',
}

export enum UsageTabs {
  APPLICATIONS = 'Applications',
  USERS = 'Users',
}

export type TabDefinition = {
  id: UsageTabs;
  label: string;
  element: ReactElement;
};

export type ActivityFilter = Record<ActivityFrequency | 'active' | 'inactive' | 'all', boolean>;

export const EMPTY_FILTER: ActivityFilter = {
  active: false,
  inactive: false,
  daily: false,
  twiceAWeek: false,
  weekly: false,
  biweekly: false,
  monthly: false,
  occasionally: false,
  all: false,
};
