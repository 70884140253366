import { createRequiredContext } from '@verticeone/design-system/src';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CardGridSortModel, ValidRowModel } from './types';
import { sortRows } from './sorting';

export type CardGridContextType<R extends ValidRowModel> = {
  sortedRows: R[];
  sortModel: CardGridSortModel;
  setSortModel: (sortModel: CardGridSortModel) => void;
};

const { CardGridContextProvider, useCardGridContext } = createRequiredContext<CardGridContextType<any>, 'CardGrid'>(
  'CardGrid'
);

type CardGridStateContextProps<R extends ValidRowModel> = {
  children: ReactNode;
  sortModel: CardGridSortModel;
  onSortModelChange?: (sortModel: CardGridSortModel) => void;
  rows: Array<R>;
};

const CardGridStateContext = <R extends ValidRowModel>({
  sortModel,
  onSortModelChange,
  rows,
  children,
}: CardGridStateContextProps<R>) => {
  const [currentSortModel, setCurrentSortModel] = useState<CardGridSortModel>(sortModel);
  const handleSortModelChange = useCallback(
    (newSortModel: CardGridSortModel) => {
      setCurrentSortModel(newSortModel);
      onSortModelChange?.(newSortModel);
    },
    [onSortModelChange]
  );
  useEffect(() => {
    setCurrentSortModel(sortModel);
  }, [sortModel]);

  const sortedRows = useMemo(() => sortRows(rows, currentSortModel), [rows, currentSortModel]);

  const stateValue = useMemo(
    () => ({ sortedRows, sortModel: currentSortModel, setSortModel: handleSortModelChange }),
    [sortedRows, currentSortModel, handleSortModelChange]
  );
  return <CardGridContextProvider value={stateValue}>{children}</CardGridContextProvider>;
};

const useCardGridState = <R extends ValidRowModel>(): CardGridContextType<R> => useCardGridContext();

export { useCardGridState, CardGridStateContext };
