import React, { ReactElement } from 'react';
import { Stack, styled } from '@mui/material';
import { Text } from '@verticeone/design-system/src';

const StyledIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.palette.primary.color4,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
}));

type AWSTextItemWithIconProps = {
  icon: ReactElement;
  title: string;
  text: string;
};

const AWSTextItemWithIcon = ({ icon, title, text }: AWSTextItemWithIconProps) => (
  <Stack direction="row" gap={4} alignItems="flex-start">
    <StyledIconWrapper>{icon}</StyledIconWrapper>
    <Stack gap={1}>
      <Text variant="heading" size="XS">
        {title}
      </Text>
      <Text variant="body-regular">{text}</Text>
    </Stack>
  </Stack>
);

export default AWSTextItemWithIcon;
