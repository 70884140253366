import { InvoiceItemViewModel, InvoicesViewModel, InvoiceViewModel, Transactions } from './types';
import { sumBy } from 'lodash';
import dayjs from 'dayjs';

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

const getAttribute = (transaction: ArrayElement<Transactions>, name: string): string | undefined => {
  return transaction.document?.attributes?.find((attribute) => attribute.name === name)?.value;
};

const parseFloatOrUndefined = (value: string | undefined): number | undefined => {
  const parsedFloat = value ? parseFloat(value) : undefined;
  return !Number.isNaN(parsedFloat) ? parsedFloat : undefined;
};

const getNetAmount = (transaction: ArrayElement<Transactions>) => {
  const subTotal = parseFloatOrUndefined(getAttribute(transaction, 'subTotal'));
  const totalTaxAmount = parseFloatOrUndefined(getAttribute(transaction, 'totalTaxAmount'));
  const totalValue = parseFloatOrUndefined(getAttribute(transaction, 'totalValue'));

  if (subTotal !== undefined) {
    return subTotal;
  } else if (totalTaxAmount !== undefined && totalValue !== undefined) {
    return totalValue - totalTaxAmount;
  } else if (totalValue !== undefined) {
    return totalValue;
  }
  return undefined;
};

export const getViewModel = (transactions: Transactions): InvoicesViewModel => {
  if (transactions.length === 0) {
    return {
      invoices: [],
      sumInvoiced: 0,
    };
  }

  const discoveredInvoiceTransactions = transactions.filter(
    (transaction) =>
      transaction.discoveryStatus === 'DISCOVERED' && getAttribute(transaction, 'transactionType') === 'INVOICE'
  );

  const invoices = discoveredInvoiceTransactions.map((transaction): InvoiceViewModel => {
    const itemListStr = getAttribute(transaction, 'itemList');
    let itemList: any = undefined;
    try {
      const itemListParsed = itemListStr ? JSON.parse(itemListStr) : [];
      if (Array.isArray(itemListParsed)) {
        itemList = itemListParsed;
      }
    } catch (_e) {}

    const items: InvoiceItemViewModel[] =
      itemList?.map((item: any) => {
        return {
          name: item.name,
          description: item.description,
          vendor: transaction.matches?.find((match) => match.entityType === 'CUSTOMER/VENDOR')?.entityName,
          quantity: parseFloatOrUndefined(item.quantity),
          itemCost: parseFloatOrUndefined(item.unit_price),
          totalCost: parseFloatOrUndefined(item.amount),
        };
      }) ?? [];

    const issueDateStr = getAttribute(transaction, 'transactionDate');
    const issueDateParsed = issueDateStr ? dayjs(issueDateStr) : undefined;

    return {
      id: transaction.id,
      issueDateTimestamp: issueDateParsed && issueDateParsed.isValid() ? issueDateParsed.valueOf() : undefined,
      invoiceNumber: getAttribute(transaction, 'transactionNumber') || undefined,
      netAmount: getNetAmount(transaction),
      currency: getAttribute(transaction, 'currency'),
      items,
    };
  });

  const sumInvoiced = sumBy(invoices, (invoice) => invoice.netAmount ?? 0);

  // provider id from first transaction and its first source
  const providerId = discoveredInvoiceTransactions.at(0)?.sources?.at(0)?.provider?.id;
  return {
    invoices,
    provider: providerId ? providerId.slice(providerId.lastIndexOf(':') + 1) : undefined,
    sumInvoiced,
  };
};
