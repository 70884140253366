import dayjs from 'dayjs';
import { TableDataRow } from './types';
import { chain, sumBy } from 'lodash';

export const generateDateRange = (start: string, end: string, granularity: 'day' | 'week' | 'month') => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const dates = [];

  let currentDate = startDate.startOf(granularity);
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, granularity)) {
    dates.push(currentDate.valueOf());
    currentDate = currentDate.add(1, granularity);
  }

  return dates;
};

export const extendTableData = (
  tableData: TableDataRow[],
  periodStart: string,
  periodEnd: string,
  granularity: 'day' | 'week' | 'month'
) => {
  const dateRange = generateDateRange(periodStart, periodEnd, granularity);

  return dateRange.map((startDate) => {
    const existingData = tableData.find((item) => Number(item.startDate) === startDate);
    if (existingData) {
      return existingData;
    } else {
      return {
        startDate,
        totalCost: 0,
        ...Object.fromEntries(
          Object.keys(tableData[0])
            .filter((key) => key !== 'startDate' && key !== 'totalCost')
            .map((key) => [key, 0])
        ),
      } as TableDataRow;
    }
  });
};

export const processTableData = <
  Columns extends {
    start_date: number;
    cost: number;
  }
>(
  computedData: Columns[],
  periodStart: string,
  periodEnd: string,
  granularity: 'day' | 'week' | 'month',
  comparisonColumn: keyof Columns
): TableDataRow[] => {
  const columnKeys = chain(computedData).groupBy(comparisonColumn).keys().value();

  const tableData = chain(computedData)
    .groupBy('start_date')
    .map((allInGroup, startDate) => {
      const dynamicColumns = columnKeys.map((accId) => {
        const item = allInGroup.find((row) => row[comparisonColumn] === accId);
        return { [accId]: item?.cost || 0 };
      });

      return {
        startDate: Number(startDate),
        totalCost: sumBy(allInGroup, ({ cost }) => cost),
        ...dynamicColumns.reduce((acc, item) => ({ ...acc, ...item }), {}),
      } as TableDataRow;
    })
    .value();

  return extendTableData(tableData, periodStart, periodEnd, granularity);
};
