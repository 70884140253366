import { useTranslation } from 'react-i18next';
import { VendorLegalInfo } from '@vertice/slices';

export interface InsightMetrics {
  prefix: string;
  tooltip: string;
  text?: string;
  clause?: string;
}

interface MetricsDataModel {
  [key: string]: InsightMetrics[];
}

const useMetricsDataModel = (vendorLegalData: VendorLegalInfo = {}): MetricsDataModel => {
  const { t } = useTranslation();

  return {
    PAYMENT: [
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.FEES.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.FEES.TOOLTIP'),
        text: vendorLegalData.feesRefundable,
        clause: vendorLegalData.feesRefundableClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.PAYMENT_TERMS.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.PAYMENT_TERMS.TOOLTIP'),
        text: vendorLegalData.paymentTerms,
        clause: vendorLegalData.paymentTermsClause,
      },
    ],
    LIABILITY: [
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.AGGREGATE_LIABILITY.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.AGGREGATE_LIABILITY.TOOLTIP'),
        text: vendorLegalData.liability,
        clause: vendorLegalData.liabilityClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.LIABILITY_LIMITS.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.LIABILITY_LIMITS.TOOLTIP'),
        text: vendorLegalData.liabilityLimits,
        clause: vendorLegalData.liabilityLimitsClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.WARRANTIES.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.WARRANTIES.TOOLTIP'),
        text: vendorLegalData.warranties,
        clause: vendorLegalData.warrantiesClause,
      },
    ],
    RENEWAL_USAGE: [
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.AUTO_RENEWAL.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.AUTO_RENEWAL.TOOLTIP'),
        text: vendorLegalData.autoRenewal,
        clause: vendorLegalData.autoRenewalClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.NOTICE_PERIOD.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.NOTICE_PERIOD.TOOLTIP'),
        text: vendorLegalData.noticePeriod,
        clause: vendorLegalData.noticePeriodClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.USAGE_LIMITS.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.USAGE_LIMITS.TOOLTIP'),
        text: vendorLegalData.usageLimits,
        clause: vendorLegalData.usageLimitsClause,
      },
    ],
    MISC: [
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.SERVICE_LEVEL_AGREEMENT.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.SERVICE_LEVEL_AGREEMENT.TOOLTIP'),
        text: vendorLegalData.serviceAgreement,
        clause: vendorLegalData.serviceAgreementClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.CUSTOMER.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.CUSTOMER.TOOLTIP'),
        text: vendorLegalData.marketingPermit,
        clause: vendorLegalData.marketingPermitClause,
      },
      {
        prefix: t('LEGAL_INFO.METRICS_DATA_MODEL.GOVERNING_LAW.PREFIX'),
        tooltip: t('LEGAL_INFO.METRICS_DATA_MODEL.GOVERNING_LAW.TOOLTIP'),
        text: vendorLegalData.governingLaw,
        clause: vendorLegalData.governingLawClause,
      },
    ],
  };
};

export default useMetricsDataModel;
