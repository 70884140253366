import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useCallback } from 'react';

type DateFormatOptions = {
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';
};

export const useDateFormat = () => {
  const { locale } = useLocaleContext();

  return useCallback(
    (value: number, options: DateFormatOptions) => {
      return new Intl.DateTimeFormat(locale, options).format(new Date(value));
    },
    [locale]
  );
};

const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 48;
const BOTTOM_OFFSET = 12;
const VISIBLE_ROW_COUNT = 10;
const MIN_VISIBLE_ROW_COUNT = 1;

export const getTableHeight = <T extends Array<object>>(data?: T) => {
  const bottomOffset = data && data.length > VISIBLE_ROW_COUNT ? BOTTOM_OFFSET : 0;
  const visibleRows = Math.min(data?.length || MIN_VISIBLE_ROW_COUNT, VISIBLE_ROW_COUNT);
  return `${visibleRows * ROW_HEIGHT + HEADER_HEIGHT + bottomOffset}px`;
};
