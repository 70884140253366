import {
  ResourceItem,
  RiPriceType,
  RiResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { chain, sumBy, isNil } from 'lodash';
import { normalizationFactorMap, RISize } from '../../normalizationFactor';
import { FamilyInstanceItem } from './types';
import { ALLOWED_RI_STATES } from './constants';
import { getInstanceFamily, getInstanceSize } from './useCloudInventory';

export type ReservedInstance = {
  id: string;
  region: string;
  instanceFamily: string;
  size: string;
  multiAZ?: boolean;
  product: string;
  quantity: number;
  normalizedUnits: number;
  hourlyRate?: number;
  hourlyOnDemandRate?: number;
};

const SECONDS_PER_HOUR = 3600;

export const getHourlyRate = (price: RiPriceType, duration?: number) => {
  const hourlyDuration = (duration ?? 0) / SECONDS_PER_HOUR;
  const fixedPricePerHour = (price?.fixed ?? 0) / hourlyDuration;
  const recurringPricePerHour = price?.recurring ? sumBy(price.recurring, 'amount') : 0;

  return (price?.usage ?? 0) + recurringPricePerHour + fixedPricePerHour;
};

type InstanceTypePosition = {
  instanceFamily: number;
  size: number;
};

export const processQueryData = (
  reservedInstancesItems?: ResourceItem[],
  showExpired = false,
  instanceTypePosition: InstanceTypePosition = { instanceFamily: 0, size: 1 }
) => {
  const today = new Date();

  return reservedInstancesItems
    ?.filter((resourceItem) => {
      const item = resourceItem.resource as RiResult;
      const retentionDate = item.retentionDate ? new Date(item.retentionDate * 1000) : undefined;
      return (showExpired || (retentionDate && retentionDate >= today)) && ALLOWED_RI_STATES.includes(item.state || '');
    })
    .map((resourceItem) => {
      const item = resourceItem.resource as RiResult;
      const { instance, price } = item;
      const instanceFamily = getInstanceFamily(instance?.type, instanceTypePosition);
      const size = getInstanceSize(instance?.type, instanceTypePosition);
      const quantity = instance?.count ?? 0;

      return {
        id: item.id,
        region: resourceItem.region,
        instanceFamily: instanceFamily,
        multiAZ: item?.multiAZ,
        size: size,
        product: instance?.product,
        quantity: quantity,
        normalizedUnits: size ? normalizationFactorMap[size as RISize] * quantity : 0,
        hourlyRate: price ? getHourlyRate(price, item.duration) : null,
      };
    }) as ReservedInstance[];
};

export const groupByRegionAndInstanceFamily = (flatData: ReservedInstance[]) => {
  return chain(flatData)
    .groupBy('region')
    .map((allInRegion: ReservedInstance[], region) => ({
      id: region,
      region: region,
      items: chain(allInRegion)
        .groupBy((item) => item.instanceFamily)
        .map((groupedItems: ReservedInstance[], instanceFamily) => {
          const totalNormalizedUnits = sumBy(groupedItems, ({ normalizedUnits }) => normalizedUnits);
          const hasAllHourlyRates = groupedItems.every(({ hourlyRate }) => !isNil(hourlyRate));
          const hasAllOnDemandHourlyRates = groupedItems.every(({ hourlyOnDemandRate }) => !isNil(hourlyOnDemandRate));

          return {
            id: `${region}_${instanceFamily}`,
            region: region,
            instanceFamily: groupedItems[0].instanceFamily,
            quantity: sumBy(groupedItems, ({ quantity }) => quantity),
            normalizedUnits: totalNormalizedUnits,
            product: groupedItems[0].product,
            averageHourlyRate: hasAllHourlyRates
              ? sumBy(groupedItems, ({ hourlyRate, normalizedUnits }) => hourlyRate! * normalizedUnits) /
                totalNormalizedUnits
              : undefined,
            averageHourlyOnDemandRate: hasAllOnDemandHourlyRates
              ? sumBy(
                  groupedItems,
                  ({ hourlyOnDemandRate, normalizedUnits }) => hourlyOnDemandRate! * normalizedUnits
                ) / totalNormalizedUnits
              : undefined,
          } as FamilyInstanceItem;
        })
        .orderBy('normalizedUnits', 'desc')
        .value(),
    }))
    .orderBy('region', 'asc')
    .value();
};

export const getPriceListFilter = (data?: ReservedInstance[]) => {
  if (!data) {
    return undefined;
  }

  return chain(data)
    .groupBy('region')
    .map((allInRegion: ReservedInstance[], region) => ({
      region: region,
      instanceFamilies: chain(allInRegion)
        .groupBy('instanceFamily')
        .map((allInInstanceFamily, instanceFamily) => ({
          name: instanceFamily,
          sizes: chain(allInInstanceFamily)
            .groupBy('size')
            .map((_, size) => size)
            .value(),
        }))
        .value(),
    }))
    .value();
};
