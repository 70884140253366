import React, { useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translate';
import { useGridApiContext, useGridRootProps, GridColumnMenuItemProps } from '@mui/x-data-grid-pro';
import { ListItemIcon, Typography } from '@mui/material';
import { FilterAltOutlined } from '@mui/icons-material';
import { MenuItem } from '../../Menu';

export const FilterGridMenuItem = ({ colDef, onClick }: GridColumnMenuItemProps) => {
  const { t } = useTranslation(undefined, { i18n });
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const showFilter = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick(event);
      apiRef.current.showFilterPanel(colDef?.field);
    },
    [apiRef, colDef?.field, onClick]
  );

  if (rootProps.disableColumnFilter || !colDef?.filterable) {
    return null;
  }

  return (
    <MenuItem onClick={showFilter}>
      <ListItemIcon>
        <FilterAltOutlined fontSize="small" />
      </ListItemIcon>
      <Typography variant="button">{t('GRID_COLUMN_MENU.FILTER_COLUMN')}</Typography>
    </MenuItem>
  );
};
