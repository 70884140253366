import { useEffect } from 'react';
import { AwsRumConfig } from 'aws-rum-web';
import { useLocation } from 'react-router-dom';

interface RumConfigs {
  rumApplicationId: string;
  rumApplicationVersion: string;
  rumApplicationRegion: string;
}

interface AwsRumComponentProps {
  config: AwsRumConfig;
  rumCredentials: RumConfigs;
  enableRum: boolean;
}

declare function cwr(operation: string, payload: any): void;

declare global {
  interface Window {
    cwr: any;
    AwsRumClient: any;
  }
}

function startRum(
  functionName: string,
  applicationId: string,
  applicationVersion: string,
  applicationRegion: string,
  cdn: string,
  configs: AwsRumConfig,
  x?: any,
  z?: any
) {
  // eslint-disable-next-line no-multi-assign
  x = window.AwsRumClient = {
    q: [],
    n: functionName,
    i: applicationId,
    v: applicationVersion,
    r: applicationRegion,
    c: configs,
  };
  (window as any)[functionName] = function (c: any, p: any) {
    x.q.push({ c, p });
  };
  z = document.createElement('script');
  z.async = true;
  z.src = cdn;
  document.head.insertBefore(z, document.head.getElementsByTagName('script')[0]);
}

export const logHttpError = (status: string, arg: { endpointName: string; originalArgs: any; message?: string }) => {
  const { endpointName, originalArgs } = arg;
  const err = new Error(`${status} ${endpointName}`);
  err.name = JSON.stringify({ endpointName, originalArgs, message: arg.message || '' });

  if (typeof cwr === 'function') {
    cwr('recordError', err);
  }
};

const useAwsRum = ({ config, rumCredentials, enableRum }: AwsRumComponentProps) => {
  const location = useLocation();
  useEffect(() => {
    if (enableRum) {
      try {
        const { rumApplicationId, rumApplicationVersion, rumApplicationRegion } = rumCredentials;
        if (rumApplicationId && rumApplicationVersion && rumApplicationRegion) {
          startRum(
            'cwr',
            rumApplicationId,
            rumApplicationVersion,
            rumApplicationRegion,
            'https://client.rum.us-east-1.amazonaws.com/1.12.0/cwr.js',
            config
          );
        }
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
      }
    }
  }, [config, rumCredentials, enableRum]);

  useEffect(() => {
    if (typeof cwr === 'function') {
      cwr('recordPageView', location.pathname);
    }
  }, [location]);
};

export default useAwsRum;
