import { DesignSystemSize } from '../../../types';

export type SizeDef = {
  paddingX: number;
  paddingY: number;
  labelLineHeight: number;
  gapX: number;
  gapY: number;
  /** Gap between text field and helper text */
  helperTextGap: number;
  helperTextSize: DesignSystemSize;
};

const sizeDefinitions: Record<DesignSystemSize, SizeDef> = {
  XL: { paddingX: 20, paddingY: 10, labelLineHeight: 17, gapX: 10, gapY: 4, helperTextGap: 6, helperTextSize: 'S' },
  L: { paddingX: 18, paddingY: 9, labelLineHeight: 17, gapX: 9, gapY: 4, helperTextGap: 6, helperTextSize: 'S' },
  M: { paddingX: 16, paddingY: 8, labelLineHeight: 16, gapX: 8, gapY: 4, helperTextGap: 4, helperTextSize: 'XS' },
  S: { paddingX: 14, paddingY: 7, labelLineHeight: 16, gapX: 7, gapY: 2, helperTextGap: 4, helperTextSize: 'XS' },
  XS: { paddingX: 12, paddingY: 6, labelLineHeight: 14, gapX: 6, gapY: 2, helperTextGap: 2, helperTextSize: 'XXS' },
  XXS: { paddingX: 10, paddingY: 5, labelLineHeight: 14, gapX: 5, gapY: 2, helperTextGap: 2, helperTextSize: 'XXS' },
};

export default sizeDefinitions;
