import React from 'react';
import { Stack } from '@mui/material';

type ContentProps = {
  children: React.ReactNode;
};

export const Content: React.FC<ContentProps> = ({ children }) => (
  <Stack direction="column" height="100%" flexGrow={1} justifyContent="center">
    {children}
  </Stack>
);
