import { ConcessionStatus } from '../../components/ConcessionsList/constants';
import { CostModelProductItem } from '../../components/CostModelProductList';
import { AdvancedLinearCostModelLine } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ProductRollingFrequency } from '../../components/CostModelProductList/types';

export const ANNUAL_COST_IDENTIFIER = 'annualCost';
export const ANNUAL_SCOPE_REDUCTION_IDENTIFIER = 'annualScopeReductionSavings';
export const SCOPE_REDUCTION_IDENTIFIER = 'scopeReductionSavings';

const VENDOR_DB = 'vendordb';
const PRODUCTS_DB = 'productdb';

export const buildProductIdentifierFromIds = (vendorId?: string, productId?: string) => {
  return `${VENDOR_DB}/${vendorId}/${PRODUCTS_DB}/${productId}`;
};

export const buildProductIdentifier = (product: CostModelProductItem) => {
  return buildProductIdentifierFromIds(product.vendorId, product.productId);
};

export const getProductIdFromProductItem = (identifier?: string) => {
  if (!identifier) {
    return undefined;
  }

  const parts = identifier.split('/');
  if (parts.length >= 4 && parts[0] === VENDOR_DB && parts[2] === PRODUCTS_DB) {
    return parts[3];
  }
};

export const getVendorIdFromProductItem = (identifier?: string) => {
  if (!identifier) {
    return undefined;
  }

  const parts = identifier.split('/');
  if (parts.length >= 2 && parts[0] === VENDOR_DB) {
    return parts[1];
  }
};

export const isValidProductItem = (item: AdvancedLinearCostModelLine) =>
  item.lineItemCategory === 'PRODUCT' && item.identifier?.includes(VENDOR_DB) && item.identifier?.includes(PRODUCTS_DB);

export const isValidConcessionItem = (item: AdvancedLinearCostModelLine) => item.lineItemCategory === 'CONCESSION';

export const isAnnualCostOverride = (item: AdvancedLinearCostModelLine) =>
  item.lineItemCategory === 'FORM_OVERRIDE' && item.identifier === ANNUAL_COST_IDENTIFIER;

export const isScopeReductionOverride = (item: AdvancedLinearCostModelLine) =>
  item.lineItemCategory === 'FORM_OVERRIDE' &&
  (item.identifier === ANNUAL_SCOPE_REDUCTION_IDENTIFIER || item.identifier === SCOPE_REDUCTION_IDENTIFIER); //TODO remove SCOPE_REDUCTION_IDENTIFIER when fixed in BLUE-1116

export const convertRollingFrequencyToProductFrequency = (
  rollingFrequency: AdvancedLinearCostModelLine['rollingFrequency']
): ProductRollingFrequency | undefined => {
  switch (rollingFrequency) {
    case 'MONTHLY':
      return 'monthly';
    case 'QUARTERLY':
      return 'quarterly';
    case 'ANNUALLY':
      return 'annually';
    case 'BIANNUALLY':
      return 'bi-annually';
    default:
      return undefined;
  }
};

export const convertProductFrequencyToItemFrequency = (
  rollingFrequency?: ProductRollingFrequency
): AdvancedLinearCostModelLine['rollingFrequency'] => {
  switch (rollingFrequency) {
    case 'monthly':
      return 'MONTHLY';
    case 'quarterly':
      return 'QUARTERLY';
    case 'annually':
      return 'ANNUALLY';
    case 'bi-annually':
      return 'BIANNUALLY';
    default:
      return undefined;
  }
};

export const convertConcessionStatusToItemStatus = (
  status?: ConcessionStatus | null
): AdvancedLinearCostModelLine['status'] => {
  switch (status) {
    case ConcessionStatus.Realised:
      return 'REALIZED';
    case ConcessionStatus.InProgress:
      return 'UNREALIZED';
    default:
      return undefined;
  }
};

export const convertItemStatusToConcessionStatus = (
  status: AdvancedLinearCostModelLine['status']
): ConcessionStatus | null => {
  switch (status) {
    case 'REALIZED':
      return ConcessionStatus.Realised;
    case 'UNREALIZED':
      return ConcessionStatus.InProgress;
    default:
      return null;
  }
};
