export const normalizationFactorMap = {
  nano: 0.25,
  micro: 0.5,
  small: 1,
  medium: 2,
  large: 4,
  xlarge: 8,
  '2xlarge': 16,
  '4xlarge': 32,
  '8xlarge': 64,
  '10xlarge': 80,
  '16xlarge': 128,
  '32xlarge': 256,
};

export type RISize = keyof typeof normalizationFactorMap;
