import React from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Card } from '@verticeone/design-system/src';

import Header from './components/Header';
import Summary from './components/Summary';
import Instructions from './components/Instructions';
import Description from './components/Description';
import SavingsBanner from './components/SavingsBanner';
import OtherRecommendationsBanner from './components/OtherRecommendationsBanner';
import { useRecommendationData } from './components/RecommendationProvider';
import { testLayoutExtensions } from './testLayoutExtensions';
import type { Code } from './types';
import SavingsOverTimeGraph from './components/SavingsOverTimeGraph';

const RecommendationContent = () => {
  const { code } = useParams<{ code: Code }>();
  const {
    item: { empty },
  } = useRecommendationData();
  const extension = !empty ? testLayoutExtensions[code!] : null;

  return (
    <Stack gap={8}>
      <Header />
      <Stack gap={4}>
        <Card>
          <Summary />
          <SavingsOverTimeGraph code={code!} />
        </Card>
        <SavingsBanner />
        <Description />
        <Instructions />
        {extension}
        <OtherRecommendationsBanner />
      </Stack>
    </Stack>
  );
};

export default RecommendationContent;
