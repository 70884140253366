import { CardGridColDef, OnRowClickEvent, ValidRowModel } from './types';
import { Stack, styled } from '@mui/material';

import { Placeholder } from '@verticeone/design-system/src';

import { CardGridRow } from './CardGridRow';
import { useCardGridState } from './CardGridStateContext';

type CardGridRowsProps<R extends ValidRowModel> = {
  columns: CardGridColDef<R>[];
  activeRowId?: string;
  loading?: boolean;
  loadingRowCount?: number;
  onRowClick?: OnRowClickEvent<R>;
  rowHeight?: number;
};

const DEFAULT_LOADING_ROW_COUNT = 5;

const StyledPlaceholder = styled(Placeholder)(({ theme }) => ({
  border: `1px solid ${theme.palette.core.color3}`,
  borderRadius: theme.spacing(4),
}));

export const CardGridRows = <R extends ValidRowModel>({
  columns,
  activeRowId,
  loadingRowCount = DEFAULT_LOADING_ROW_COUNT,
  loading,
  onRowClick,
  rowHeight,
}: CardGridRowsProps<R>) => {
  const { sortedRows } = useCardGridState<R>();

  const handleRowClick = (row: R, event: React.MouseEvent) => {
    onRowClick?.({ row, sortedRows }, event);
  };

  const effectiveRowHeight = rowHeight || 60;

  if (loading) {
    return (
      <Stack gap={2}>
        {Array.from({ length: loadingRowCount }, (_v, i) => i).map((l) => (
          <StyledPlaceholder height={effectiveRowHeight} key={l} />
        ))}
      </Stack>
    );
  }
  return (
    <Stack>
      {sortedRows.map((row) => (
        <CardGridRow
          key={row.id}
          highlight={activeRowId === row.id}
          columns={columns}
          row={row}
          onClick={handleRowClick}
          height={effectiveRowHeight}
        />
      ))}
    </Stack>
  );
};
