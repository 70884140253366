import { drawerClasses, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer/Drawer';
import styled from '@mui/material/styles/styled';
import { DesignSystemDensity, DesignSystemSize, TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';
import { DrawerProvider } from './DrawerContext';

const getBorderStyle = (anchor: MuiDrawerProps['anchor'], theme: Theme) => {
  const borderStyle = `1px solid ${theme.palette.core.color3}`;
  const borderStyles = {
    right: { borderLeft: borderStyle },
    left: { borderRight: borderStyle },
    top: { borderBottom: borderStyle },
    bottom: { borderTop: borderStyle },
  };
  return borderStyles[anchor!] || {};
};

type StyleProps = {
  $width: string;
  $anchor: MuiDrawerProps['anchor'];
  theme: Theme;
};

const StyledDrawer = styled(MuiDrawer)<StyleProps>(({ $width, $anchor, theme }) => ({
  [`.${drawerClasses.paper}`]: {
    width: $width,
    ...getBorderStyle($anchor, theme),
  },
}));

type DrawerProps = TestProps &
  MuiDrawerProps & {
    onClose: () => void;
    /* Width of Drawer component */
    width?: string;
    /* Size of DrawerHeader */
    size?: DesignSystemSize;
    /* Drawer density of DrawerHeader & DrawerBody */
    density?: DesignSystemDensity;
    /* Header title */
    title?: string;
    /* Header description */
    description?: string;
    /* Loading placeholder for header and Body */
    isLoading?: boolean;
    /*
    Determines the visibility of the scrollbar.
    When true => The Drawer's scrollbar is visible when the Drawer's content exceeds 100% of the viewport height.
    When false => The Body's scrollbar is visible when the Body's content exceeds 100% of the viewport height.
    */
    fullScrollbar?: boolean;
  };

export const Drawer = ({
  width = `calc(100% - 64px)`,
  anchor = 'right',
  open,
  onClose,
  children,
  size = 'M',
  density = 'low',
  testId,
  isLoading,
  fullScrollbar,
  ...restProps
}: DrawerProps) => {
  return (
    <StyledDrawer
      $width={width}
      $anchor={anchor}
      anchor={anchor}
      open={open}
      onClose={onClose}
      {...restProps}
      {...testProps(testId, 'drawer')}
    >
      <DrawerProvider value={{ onClose, size, density, isLoading, fullScrollbar }}>{children}</DrawerProvider>
    </StyledDrawer>
  );
};
