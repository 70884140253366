import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@vertice/components/src/DataGrid';
import { RegionData } from './types';
import CoverageTable from './CoverageTable';
import CurrencyCell from './Cells/CurrencyCell';
import PercentageCell from './Cells/PercentageCell';
import SavingsCell from './Cells/SavingsCell';

type ReservedInstancesTableProps = {
  data: RegionData[];
};

const RdsCoverageTable = ({ data }: ReservedInstancesTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RESERVED_INSTANCES' });

  const columns: GridColDef[] = [
    {
      field: 'cost',
      headerName: t('COLUMN.RESOURCE_COST'),
      renderCell: ({ value }) => <CurrencyCell value={value} />,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'coverage',
      headerName: t('COLUMN.COVERAGE'),
      renderCell: ({ value }) => <PercentageCell value={value} />,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'saving',
      headerName: t('COLUMN.SAVING'),
      renderCell: ({ value, row }) => <SavingsCell value={value} row={row} />,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];

  return <CoverageTable data={data} columns={columns} />;
};

export default RdsCoverageTable;
