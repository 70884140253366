import { useCustomerTransactionsByContractV2Query } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';

type UseTransactionsV1Props = {
  accountId: string;
  contractId: string;
};

type UseTransactionsV2Options = {
  skip?: boolean;
};

export const useTransactionsV2 = (props: UseTransactionsV1Props, { skip }: UseTransactionsV2Options) => {
  const { data: transactionDocuments, isLoading: isLoadingTransactions } = useCustomerTransactionsByContractV2Query(
    props,
    { skip }
  );

  return {
    transactions: {
      customerTransactionsByContract: transactionDocuments?.customerTransactionsByContractV2,
    },
    isLoadingTransactions,
  };
};
