import React, { FC } from 'react';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Typography } from '@mui/material';
import styles from './VendorDiligenceInsights.module.scss';

interface ItemProps {
  title: string;
  link: string;
}

const FileItem: FC<ItemProps> = ({ title, link }) => (
  <a className={styles.file} href={link} target="_blank" rel="noreferrer">
    <FilePresentIcon />
    <Typography variant="button-s">{title}</Typography>
  </a>
);

export default FileItem;
