import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { GridFooterContainer } from '@mui/x-data-grid-pro';
import { Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import styles from './GridList.module.scss';

interface CustomFooterProps {
  addLabel?: string;
  onAddItem?: () => void;
}

const CustomFooter: FC<CustomFooterProps> = ({ addLabel, onAddItem }) => {
  const { t } = useTranslation();

  const handleAddClick = () => {
    if (onAddItem) {
      onAddItem();
    }
  };

  return (
    <GridFooterContainer>
      <Button
        onClick={handleAddClick}
        className={clsx(styles['add-button'], styles['user-list-add-button'])}
        startIcon={<Add />}
        variant="text"
        fullWidth
      >
        <Typography variant="body-regular-m">
          {addLabel || t('PREFERENCES.WORKFLOW.USER_TABLE.ADD_NEW_CONTACT')}
        </Typography>
      </Button>
    </GridFooterContainer>
  );
};

export default CustomFooter;
