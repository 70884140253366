import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { BuyersBoardRequest, Request as BffeRequest } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

export const matchWidgetRef = (widgetRefPattern: string | RegExp, widgetRef: string) => {
  const lastPart = widgetRef.split(':').pop() || widgetRef;
  if (!lastPart) return false;

  return widgetRefPattern instanceof RegExp ? widgetRefPattern.test(lastPart) : widgetRefPattern === lastPart;
};

export const hasDataForWidget = (request: Request | BffeRequest, widgetUrn: string | RegExp): boolean => {
  return Object.keys(request?.widgetData || {}).some((widgetRef) => matchWidgetRef(widgetUrn, widgetRef)) || false;
};

export const getDataForWidget = (request: Request | BffeRequest | BuyersBoardRequest, widgetUrn: string | RegExp) => {
  const widgetKey = Object.keys(request?.widgetData || []).find((widgetRef) => matchWidgetRef(widgetUrn, widgetRef));
  return widgetKey ? request?.widgetData?.[widgetKey] : undefined;
};
