import { useTranslation } from 'react-i18next';

import { Text } from '@verticeone/design-system/src';
import SkeletonForCell from '../../../components/SkeletonForCell';

type ActivityFrequencyCellProps = {
  activityFrequency?: string | null;
};

const ActivityFrequencyCell = ({ activityFrequency }: ActivityFrequencyCellProps) => {
  const { t } = useTranslation();

  if (activityFrequency === undefined) {
    return <SkeletonForCell />;
  }

  if (activityFrequency === null) {
    return null;
  }

  return (
    <Text variant="body-regular" size="M">
      {t(`APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.BINS.${activityFrequency}`)}
    </Text>
  );
};

export default ActivityFrequencyCell;
