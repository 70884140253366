import { useCallback } from 'react';
import Highcharts from 'highcharts';
import { useTheme } from '@mui/material';

import { yLabelPercentageFormatter } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

export type getPercentageProps = {
  minTargetValue?: number;
  maxTargetValue: number;
};

const usePercentage = () => {
  const { palette } = useTheme();
  const { locale } = useLocaleContext();

  return useCallback(
    ({ minTargetValue = 0, maxTargetValue }: getPercentageProps): Highcharts.XAxisOptions => ({
      min: 0,
      max: 1.1,
      tickInterval: 0.1,
      showLastLabel: false,
      plotLines: [
        { value: minTargetValue, width: 1, color: palette.core.color6, zIndex: 3 },
        { value: maxTargetValue, width: 2, dashStyle: 'ShortDash', color: palette.core.color6, zIndex: 3 },
      ],
      labels: {
        formatter: yLabelPercentageFormatter(palette, locale),
      },
    }),
    [locale, palette]
  );
};

export default usePercentage;
