import RITransactionHistory from '../../../../modules/cloud/cards/RIPurchaseCard/RITransactionHistory';
import RIPendingTransactions from '../../../../modules/cloud/cards/RIPurchaseCard/RIPendingTransactions';
import { Stack } from '@mui/material';

const CloudTransactionsTab = () => {
  return (
    <Stack gap={4} flexDirection="column" flex={1}>
      <RIPendingTransactions />
      <RITransactionHistory />
    </Stack>
  );
};

export default CloudTransactionsTab;
