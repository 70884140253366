import React from 'react';
import { Stack } from '@mui/material';
import LoadableAWSPageWrapper from '@vertice/dashboard/src/pages/Cloud/LoadableAWSPageWrapper';
import RecommendationProvider from './components/RecommendationProvider';
import RecommendationContent from './RecommendationContent';

const Recommendation = () => {
  return (
    <Stack padding={10}>
      <LoadableAWSPageWrapper>
        <RecommendationProvider>
          <RecommendationContent />
        </RecommendationProvider>
      </LoadableAWSPageWrapper>
    </Stack>
  );
};

export default Recommendation;
