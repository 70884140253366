import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { Card, CardHeaderTitle, CardHeader, Divider } from '@verticeone/design-system/src';
import { ContractContextDataRequireFetched } from '@vertice/core/src/modules/saas/contract/types';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useContractRenewalRequests } from '../useContractRenewalRequests';
import { useLinkedContracts } from './useLinkedContracts';
import { ContractPageCard } from './components/ContractPageCard';
import { RequestPageCard } from './components/RequestPageCard';

const NotEmptyStack = styled(Stack)({
  '&:empty': {
    display: 'none',
  },
});

type LinkedPagesCardProps = {
  contracts: ViewOnContract[];
  requests: Request[];
  isContractListDisplayed?: boolean;
};

const LinkedPagesCard: FC<LinkedPagesCardProps> = ({ contracts, requests, isContractListDisplayed = true }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('SAAS.LINKED_PAGES_CARD.TITLE')} />
      </CardHeader>
      <Stack>
        <NotEmptyStack gap={2} padding={6}>
          {requests?.map((request) => (
            <RequestPageCard request={request} key={request.ref} />
          ))}
        </NotEmptyStack>
        {isContractListDisplayed && (
          <>
            {!!requests.length && !!contracts.length && <Divider />}
            <NotEmptyStack gap={2} padding={6}>
              {contracts?.map(({ contract: lineageContract }) => (
                <ContractPageCard contract={lineageContract} key={lineageContract.record.contractId} />
              ))}
            </NotEmptyStack>
          </>
        )}
      </Stack>
    </Card>
  );
};

export const useLinkedPagesCard = ({
  contractContext,
  skipContracts = false,
}: {
  contractContext: ContractContextDataRequireFetched;
  skipContracts?: boolean;
}) => {
  const {
    fetchedContract,
    editMode: { isEditing },
  } = contractContext;

  const { isFeatureEnabled } = useAccountContext();
  const { linkedRequests, isLoadingLinkedRequests } = useContractRenewalRequests(fetchedContract.contract);
  const { linkedContracts, isLoadingLinkedContracts } = useLinkedContracts({
    contract: fetchedContract.contract,
    skip: skipContracts,
  });
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  const isLoading = isLoadingLinkedRequests || isLoadingLinkedContracts;
  const isEmpty = !linkedRequests.length && !linkedContracts.length;

  return useMemo(
    () =>
      !isLoading && !isEmpty && !isEditing && isIWEnabled
        ? () => (
            <LinkedPagesCard
              isContractListDisplayed={!skipContracts}
              contracts={linkedContracts}
              requests={linkedRequests}
            />
          )
        : null,
    [isLoading, isEmpty, isEditing, isIWEnabled, linkedContracts, linkedRequests, skipContracts]
  );
};
