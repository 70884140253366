import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';
import { UploadContractFileV2ApiArg, UploadContractFileV2ApiResponse } from '../openapi/codegen/bffeSaasAPI';

export const bffeSaasAPI = createApi({
  reducerPath: 'bffeSaasAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.BFFE_SAAS),
  refetchOnMountOrArgChange: true,
  tagTypes: ['ContractsV2'],
  endpoints: (builder) => ({
    uploadContractFileV2: builder.mutation<UploadContractFileV2ApiResponse, UploadContractFileV2ApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/files/${queryArg.fileName}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/octet-stream',
        },
        body: queryArg.body,
      }),
    }),
  }),
});
