import { ReactElement, SetStateAction, useState } from 'react';

export enum DiligenceTab {
  LEGAL = 'LEAGAL',
  SECURITY = 'SECURITY',
  COMPLIANCE = 'COMPLIANCE',
}

export type TabDefinition = {
  id: DiligenceTab;
  label: string;
  element: ReactElement;
  isVisible: boolean;
};

type TabId = DiligenceTab | null | undefined;

export type UseDiligenceSelectedTabReturn = {
  tabId: TabId;
  setTabId: (selectedTab: SetStateAction<TabId>) => void;
};

/**
 * Keeps track of the last selected tab
 */
const useDiligenceSelectedTab = (): UseDiligenceSelectedTabReturn => {
  const [tabId, setTabId] = useState<TabId>(DiligenceTab.LEGAL);

  return {
    tabId,
    setTabId,
  };
};

export default useDiligenceSelectedTab;
