import React from 'react';
import { Grid } from '@mui/material';

import CardHeader from '../../components/CardHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import CardBody from '../../components/CardBody';
import Instance from '../../components/Instance';
import InstanceStats from '../../components/InstanceStats';
import InstanceTable from '../../components/InstanceTable';
import InstanceGraph from '../../components/InstanceGraph';
import ExpandableRow from '../../components/ExpandableRow';
import MissingRiLookupBanner from '../../components/MissingRiLookupBanner';
import CardActionsRow from '../../components/CardActionsRow';
import PurchaseRIButton from '../../components/PurchaseRIButton';
import TargetsAndControlButton from '../../components/TargetsAndControlButton';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import { useTranslation } from 'react-i18next';
import { useFormatPercentage } from '@verticeone/utils/formatting';
import { useInstanceData } from '../../components/providers/InstanceProvider';
import type { EC2DataEnhanced } from '../dataSource/useEC2Data';
import OfferingsProvider from '../../components/providers/OfferingsProvider';

const SPCoverageStatsBody = () => {
  const { item } = useInstanceData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.RI_STATS' });
  const formatPercentage = useFormatPercentage();
  const ec2Item = item as EC2DataEnhanced;

  return (
    <StatsBody
      isLoading={false}
      data-testid="sp_coverage"
      title={
        <Title
          testId="label"
          tooltip={{
            title: t('SP_COVERAGE.TOOLTIP.TITLE'),
            content: t('SP_COVERAGE.TOOLTIP.CONTENT'),
          }}
        >
          {t('SP_COVERAGE.TITLE')}
        </Title>
      }
      value={<Value>{formatPercentage(ec2Item.spCoverage || 0)}</Value>}
    />
  );
};

const InstanceLayout = () => (
  <Instance>
    <CardHeader>
      <CardHeaderTitle />
    </CardHeader>
    <MissingRiLookupBanner />
    <CardBody>
      <InstanceStats customStatsBody={<SPCoverageStatsBody />} />
      <ExpandableRow>
        <Grid container spacing={4} direction="row" alignItems="stretch">
          <Grid item xs={12} md={12} lg={4}>
            <InstanceTable />
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <OfferingsProvider
              defaultFilter={{
                offeringType: 'No Upfront',
                duration: 31536000,
                offeringClass: 'standard',
              }}
            >
              <InstanceGraph />
            </OfferingsProvider>
          </Grid>
        </Grid>
      </ExpandableRow>
    </CardBody>
    <CardActionsRow>
      <TargetsAndControlButton />
      <PurchaseRIButton />
    </CardActionsRow>
  </Instance>
);

export default InstanceLayout;
