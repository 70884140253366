import React from 'react';
import type { DesignSystemDensity, DesignSystemSize } from '../../types';

export type DrawerContextProps = {
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  size: DesignSystemSize;
  density: DesignSystemDensity;
  isLoading?: boolean;
  fullScrollbar?: boolean;
};

const DrawerContext = React.createContext<DrawerContextProps>({
  size: 'M',
  density: 'low',
} as DrawerContextProps);

export const DrawerProvider = DrawerContext.Provider;

export const useDrawer = () => {
  const context = React.useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};
