import React from 'react';
import clsx from 'clsx';
import { Box, Modal, IconButton, Stack } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { Close as CloseIcon } from '@mui/icons-material';
import { Loader } from '../Loader';
import styles from './ModalWindow.module.scss';

interface ModalWindowProps {
  title: JSX.Element;
  width?: number;
  content: JSX.Element;
  open: boolean;
  closeButton?: boolean;
  onClose?: () => void;
  actions?: JSX.Element[];
  topActionsPosition?: boolean;
  titleVariant?: Variant;
  isLoading?: boolean;
  persistent?: boolean;
}

/** @deprecated Use Design System's Dialog */
const ModalWindow: React.FC<ModalWindowProps> = ({
  title,
  content,
  open,
  closeButton = true,
  onClose,
  actions,
  width,
  topActionsPosition,
  isLoading,
  persistent = false,
}) => {
  const handleClose = (event: React.MouseEvent<Element>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && persistent) && onClose) {
      onClose();
    }
  };

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      {isLoading ? (
        <Box className={clsx(styles.paper, styles['loader-wrap'])} style={{ width }}>
          <Loader />
        </Box>
      ) : (
        <Box className={styles.paper} style={{ width }}>
          <div className={styles.header}>
            {title}
            {topActionsPosition && (
              <Stack className={styles.actions} direction="row">
                {actions}
                {closeButton && (
                  <IconButton className={styles['close-button']} color="primary" aria-label="close" onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </Stack>
            )}
            {!topActionsPosition && closeButton && (
              <IconButton className={styles['close-button']} color="primary" aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
          {content}
          {!topActionsPosition && (
            <Stack className={clsx(styles.actions, styles.centered)} direction="row">
              {actions}
            </Stack>
          )}
        </Box>
      )}
    </Modal>
  );
};

export default ModalWindow;
