import { AccountUser } from '@vertice/slices/src/openapi/codegen/accountAPI';
import { USER_ROLES } from '@vertice/core/src/constants/userRoles';

export const getDefaultUserRole = (userData?: AccountUser) => {
  if (userData) {
    const roles = userData?.userRoles || [];

    if (roles.includes(USER_ROLES.admin)) {
      return USER_ROLES.admin;
    }

    if (roles.includes(USER_ROLES.powerUser)) {
      return USER_ROLES.powerUser;
    }

    if (roles.includes(USER_ROLES.user)) {
      return USER_ROLES.user;
    }
  }

  return USER_ROLES.user;
};
