import React from 'react';

import { useEC2Data, useEC2ReservationData, useEc2RiOfferingData } from '../dataSource';
import InstanceList from '../../components/InstanceList';
import InstanceProvider from '../../components/providers/InstanceProvider';
import InstanceListProvider from '../../components/providers/InstanceListProvider';

import InstanceLayout from './InstanceLayout';
import BuyInstanceDrawerLayout from './BuyInstanceDrawer';
import { parseEc2RiOfferings, chartSeries } from '../dataSource/utils';
import permissions from '../permissions';

const EC2InstanceList = () => {
  const { data, isFetching } = useEC2Data();
  const getReservations = useEC2ReservationData();
  const getOfferings = useEc2RiOfferingData();

  return (
    <InstanceListProvider service="ec2" permissions={permissions} itemsCount={data?.length}>
      <InstanceList isLoading={isFetching}>
        <InstanceProvider
          items={data}
          getReservations={getReservations}
          getOfferings={getOfferings}
          offeringsParser={parseEc2RiOfferings}
          chartSeries={chartSeries}
        >
          <InstanceLayout />
          <BuyInstanceDrawerLayout />
        </InstanceProvider>
      </InstanceList>
    </InstanceListProvider>
  );
};

export default EC2InstanceList;
