import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { ChipButton } from '@verticeone/design-system/src';
import { Tooltip } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';

import { useInstanceData } from './providers/InstanceProvider';

const ChipsGroup = () => {
  //prettier-ignore
  const { item: { chips: { items, showCount } }, managedByVertice } = useInstanceData();
  const { t } = useTranslation();

  const visibleChips = showCount ? items.slice(0, showCount) : items;
  const showMoreChips = showCount ? items.slice(showCount, items.length) : null;

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {visibleChips.map((item) => (
        <ChipButton
          key={item.label}
          isActive={managedByVertice.isActive}
          color={managedByVertice.isActive ? 'tertiary' : 'neutral'}
          variant={managedByVertice.isActive ? 'solid' : 'ghost'}
          size="S"
        >
          {item.label}: {item.value}
        </ChipButton>
      ))}
      {showMoreChips && (
        <Tooltip
          maxWidth="500px"
          content={
            <Stack direction="column" paddingLeft={3} gap={1}>
              {showMoreChips.map((item) => (
                <Text size="M" color="text5" variant="body-regular" key={item.label}>
                  <Text size="M" color="text5" variant="body-bold">
                    {item.label}
                  </Text>
                  : {item.value}
                </Text>
              ))}
            </Stack>
          }
          size="M"
        >
          <ChipButton
            key="showMore"
            isActive={managedByVertice.isActive}
            color={managedByVertice.isActive ? 'tertiary' : 'neutral'}
            variant={managedByVertice.isActive ? 'solid' : 'ghost'}
            size="S"
          >
            {t('CLOUD.RIO.OPTIMIZE.CHIPS.MORE_CHIPS', { count: showMoreChips.length })}
          </ChipButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ChipsGroup;
