import React from 'react';
import { Text } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { ShortcutColor } from '@verticeone/design-system/src';
import { useFormatPercentage } from '@verticeone/utils/formatting';

type PercentageCellProps = {
  value: number | null;
  customNullPlaceholder?: string;
  color?: string | ShortcutColor;
  formatOptions?: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  };
};

const PercentageCell: React.FC<PercentageCellProps> = ({ value, customNullPlaceholder, color, formatOptions }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RESERVED_INSTANCES' });
  const formatPercentage = useFormatPercentage();

  return (
    <Text variant="body-regular" size="M" color={color}>
      {value === null ? customNullPlaceholder || t('NA') : formatPercentage(Number(value), formatOptions)}
    </Text>
  );
};

export default PercentageCell;
