import { createScopedI18nextHooks } from '../i18n/createScopedI18nextHooks';
import i18n from 'i18next';
import enTranslation from './translations/en.json';

export const { I18nContextProvider, useTranslation } = createScopedI18nextHooks(() => {
  const instance = i18n.createInstance();
  void instance.init({
    defaultNS: 'utils',
    resources: {
      en: {
        utils: enTranslation,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
  });
  return instance;
});
