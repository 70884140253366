export enum ProviderIds {
  ENTRA = 'Provider:Azure',
  GOOGLE_SAML = 'Provider:Google:Workspace:SAML',
  GOOGLE_SOCIAL_LOGIN = 'Provider:Google:Workspace:SocialLogin',
  GOOGLE_SOCIAL_LOGIN_NO_PREFIX = 'Google:Workspace:SocialLogin',
  JUMPCLOUD = 'Provider:JumpCloud',
  OKTA = 'Provider:Okta',
  ONE_LOGIN = 'Provider:OneLogin',
  SIMULATOR = 'Provider:Simulator',
  SLACK = 'Provider:Slack',
}
