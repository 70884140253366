import { Stack } from '@mui/material';

import { testProps } from '../../utils/testProperties';
import { BreadcrumbsV2Props } from './types';
import { CurrentPageItem } from './CurrentPageItem';
import { BreadcrumbLink } from './BreadcrumbLink';
import { SlashDivider } from './SlashDivider';

export const BreadcrumbsV2 = <C extends React.ElementType = 'a'>({
  items,
  size = 'M',
  testId,
}: BreadcrumbsV2Props<C>) => {
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      component="nav"
      divider={<SlashDivider size={size} />}
      {...testProps(testId, 'breadcrumbsV2')}
    >
      {items.map((item, index) => {
        const { startAdornment, endAdornment, copyToClipboardUrl, label, ...restProps } = item;
        const isCurrentPageItem = index === items.length - 1;

        return (
          <Stack direction="row" gap={1} alignItems="center" key={index}>
            {startAdornment}
            {isCurrentPageItem ? (
              <CurrentPageItem
                label={label}
                copyToClipboardUrl={copyToClipboardUrl ?? window.location.href}
                size={size}
              />
            ) : (
              <BreadcrumbLink label={label} size={size} {...restProps} />
            )}
            {endAdornment}
          </Stack>
        );
      })}
    </Stack>
  );
};
