import { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';
import { CommentMetadata } from './types';
import { IconButton } from '../IconButton';
import { Menu, MenuItem } from '../Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
import { Text } from '../Text';
import useTheme from '@mui/material/styles/useTheme';
import { RemoveCommentCallback } from '../Comments';

export type CommentHeaderMenuProps = Pick<CommentMetadata, 'messageId' | 'isDeleted' | 'isCommentAuthor'> & {
  removeComment: RemoveCommentCallback;
  editComment: (id: string) => void;
  canEditComents: boolean;
};

export const CommentHeaderMenu: FC<CommentHeaderMenuProps> = ({
  messageId,
  isDeleted,
  removeComment,
  editComment,
  isCommentAuthor,
  canEditComents,
}: CommentHeaderMenuProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { i18n });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const deleteComment = useCallback(async () => {
    closeMenu();
    setDisabled(true);
    try {
      await removeComment(messageId);
    } finally {
      setDisabled(false);
    }
  }, [messageId, removeComment, closeMenu]);

  const editCommentHandler = () => {
    editComment(messageId);
    closeMenu();
  };

  if (isDeleted || !(isCommentAuthor || canEditComents)) {
    return null;
  }

  return (
    <>
      <IconButton icon={MoreVertIcon} onClick={openMenu} variant="outline" size="S" disabled={disabled} />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem startIcon={EditTwoTone} iconColor={palette.text.color2} onClick={editCommentHandler}>
          <Text variant="body-bold" size="S" color={palette.text.color2}>
            {t('COMMENTS.ACTIONS.EDIT')}
          </Text>
        </MenuItem>
        <MenuItem startIcon={DeleteTwoTone} iconColor={palette.error.color1} onClick={deleteComment}>
          <Text variant="body-bold" size="S" color={palette.error.color1}>
            {t('COMMENTS.ACTIONS.DELETE')}
          </Text>
        </MenuItem>
      </Menu>
    </>
  );
};
