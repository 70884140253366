import React from 'react';
import { Logo } from '@vertice/assets';
import styles from './AuthContainer.module.scss';

const AuthContainer = ({ children }: { children?: React.ReactNode }) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  </div>
);

export default AuthContainer;
