import { useMemo } from 'react';
import { groupBy } from 'lodash';
import {
  CustomContractFieldSpec,
  useGetCustomContractFieldsQuery,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '../../../../../contexts/AccountContext';

export type UseVisibleCustomFieldsReturn = {
  visibleFields?: CustomContractFieldSpec[];
  visibleFieldsByType?: Record<string, CustomContractFieldSpec[]>;
  isLoading: boolean;
};

export type UseVisibleCustomFieldsProps = {
  skip: boolean;
};

export const useVisibleCustomFields = (props?: UseVisibleCustomFieldsProps): UseVisibleCustomFieldsReturn => {
  const { accountId } = useAccountContext();
  const { data } = useGetCustomContractFieldsQuery({ accountId }, { skip: props?.skip });
  const visibleFields = useMemo(() => data?.customFields.filter((field) => field.isVisible), [data]);
  const visibleFieldsByType = useMemo(
    () => (visibleFields ? groupBy(visibleFields, 'type') : undefined),
    [visibleFields]
  );

  return {
    visibleFields,
    visibleFieldsByType,
    isLoading: !visibleFieldsByType || !visibleFields,
  };
};
