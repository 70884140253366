import { MakeStylesParams, mergeStylesConfigs } from './styles/common';
import { makeSolidStylesConfig } from './styles/solidVariant';
import { makeOutlinedStylesConfig } from './styles/outlinedVariant';
import { makeMenuStyles } from './styles/menu';
import { useTheme } from '@mui/material';
import { GroupBase } from './types';

export const useSelectStylesConfig = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  params: Omit<MakeStylesParams, 'theme'>
) => {
  const theme = useTheme();
  const makeStylesParams: MakeStylesParams = { theme, ...params };
  const makeVariantStylesConfig =
    makeStylesParams.variant === 'solid' ? makeSolidStylesConfig : makeOutlinedStylesConfig;
  return mergeStylesConfigs<Option, IsMulti, Group>(
    makeMenuStyles(makeStylesParams),
    makeVariantStylesConfig(makeStylesParams)
  );
};
