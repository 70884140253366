import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { Card } from '@verticeone/design-system/src';

import { WorkflowViewer } from '../../../../workflowSchema';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { Definitions } from '../../../../definitionsTypes';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { GetWorkflowVersionApiResponse } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useRoutes } from '@verticeone/router/src';
import { useRequestContext } from '../../RequestContext';

type TasksSectionProps = {
  request: Request;
  workflowVersion?: GetWorkflowVersionApiResponse;
};

export const VisualizerSection: FC<TasksSectionProps> = ({ request, workflowVersion }) => {
  const routes = useRoutes();
  const requestContext = useRequestContext();

  const workflowDefinitions = workflowVersion?.workflowDefinition as Definitions | undefined;
  return (
    <Card>
      <Stack height={'min(calc(100vh - 300px), 700px)'}>
        <WorkflowViewer
          definitions={workflowDefinitions}
          requestId={parseRequestRef(request.ref).requestId}
          requestRoute={routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.ABSOLUTE_PATH}
          allowVerticeServiceNavigation={requestContext.allowVerticeServiceNavigation}
        />
      </Stack>
    </Card>
  );
};
