import { useGetVendorByIdQuery } from '@vertice/slices';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonIntakeFormFormData } from '../types';

export const useSetFormVendor = (vendorId?: string) => {
  const { setValue } = useFormContext<CommonIntakeFormFormData>();
  const { data: vendorData } = useGetVendorByIdQuery({ vendorId: vendorId || '' }, { skip: !vendorId });
  useEffect(() => {
    if (vendorId) {
      setValue('vendor', {
        type: 'PREDEFINED',
        id: vendorId,
        name: vendorData ? vendorData.name! : '', // set vendor name to empty until data is fetched
      });
    }
  }, [vendorId, vendorData, setValue]);
};
