import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridCellParams,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridRowIdGetter,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { chain } from 'lodash';
import { useSnackbar } from 'notistack';

import { AccountUser, useAdminConnectUserIdentityMutation } from '@vertice/slices';
import {
  useListSlackUserIdentitiesPaginatedQuery,
  useListSlackUsersPaginatedQuery,
} from '@vertice/slices/src/api/enhanceIntegrationsAPI';
import { useEditableDataGrid } from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { isTruthy } from '@verticeone/utils/validation';
import { DataGrid, GridColDef } from '@verticeone/design-system/src';
import {
  getConnectedUser,
  isValidConnectionResponse,
  SLACK_USER_FIELD,
  SlackConnectionFilter,
  SlackConnection,
  SlackConnectionStatus,
  VERTICE_USER_FIELD,
} from '../../../common';
import { VerticeUserCell } from './VerticeUserCell';
import { SlackUserCell } from './SlackUserCell';
import { SlackUserSelectCell } from './SlackUserSelectCell';
import { StatusCell } from './StatusCell';
import { ActionCell } from './ActionCell';

// getRowId function should be same during DataGrid rerenders
const getRowId: GridRowIdGetter<SlackConnection> = (row) => row.userId;

type SlackUsersGridProps = {
  verticeUsersData?: AccountUser[];
  isLoadingVerticeUsersData: boolean;
  search: string;
  slackConnectionFilter: SlackConnectionFilter;
};

const isUserNotLinkedFilterOperator: GridFilterOperator = {
  value: 'isUserNotLinked',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return () => true;
    }

    return (params: GridCellParams<SlackConnection>): boolean => params.value !== filterItem.value;
  },
};

export const SlackUsersGrid = ({
  verticeUsersData,
  isLoadingVerticeUsersData,
  search,
  slackConnectionFilter,
}: SlackUsersGridProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [pollingInterval, setPollingInterval] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [loadingUserId, setLoadingUserId] = useState<string>('');

  const { data: slackUsersData, isLoading: isLoadingSlackUsersData } = useListSlackUsersPaginatedQuery({ accountId });
  const {
    data: slackConnectedUserIdentitiesData,
    isLoading: isLoadingSlackConnectedUserIdentitiesData,
    refetch: refetchSlackConnectedUserIdentitiesData,
  } = useListSlackUserIdentitiesPaginatedQuery(
    { accountId },
    { pollingInterval: pollingInterval > 0 ? pollingInterval : undefined }
  );
  const [connectUser, { isLoading: isConnectUserLoading }] = useAdminConnectUserIdentityMutation();

  const handleConnectUser = useCallback(
    (row: SlackConnection) => {
      const selectedSlackUserEmail = apiRef.current.getCellValue(row.userId, SLACK_USER_FIELD);

      if (!selectedSlackUserEmail) return;

      setLoadingUserId(row.userId);

      void (async () => {
        const response = await connectUser({
          accountId,
          userId: row.userId,
          body: {
            userEmail: selectedSlackUserEmail,
          },
        });

        if (isValidConnectionResponse(response)) {
          enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.LINKING_SUCCESSFUL'), {
            variant: 'success',
          });
          void refetchSlackConnectedUserIdentitiesData();
        } else {
          enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.LINKING_FAILED_TITLE'), {
            description: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.LINKING_FAILED_DESCRIPTION'),
            variant: 'error',
          });
        }

        setLoadingUserId('');
      })();
    },
    [accountId, apiRef, connectUser, enqueueSnackbar, refetchSlackConnectedUserIdentitiesData, t]
  );

  const hasPendingConnectedUserIdentities = slackConnectedUserIdentitiesData?.items?.some(
    (user) => user.status === SlackConnectionStatus.PENDING || user.status === SlackConnectionStatus.REVOKING
  );

  useEffect(() => {
    if (hasPendingConnectedUserIdentities !== undefined) {
      setPollingInterval(hasPendingConnectedUserIdentities ? 1000 : 0);
    }
  }, [hasPendingConnectedUserIdentities]);

  useEffect(() => {
    setFilterModel({
      items: [
        {
          id: 1,
          field: VERTICE_USER_FIELD,
          value: search,
          operator: 'contains',
        },
        slackConnectionFilter.notLinked && {
          id: 2,
          field: 'status',
          value: SlackConnectionStatus.ACTIVE,
          operator: 'isUserNotLinked',
        },
      ].filter(isTruthy),
    });
  }, [search, slackConnectionFilter.notLinked]);

  const editableDataGrid = useEditableDataGrid<SlackConnection>({
    apiRef,
    defaultMode: 'confirm',
    isRowEditable: (row) =>
      getConnectedUser(row.userId, slackConnectedUserIdentitiesData?.items)?.status !== SlackConnectionStatus.ACTIVE,
    isRowDeletable: () => false,
    onUpdateRow: (row) => handleConnectUser(row),
  });

  const columns: GridColDef<SlackConnection>[] = useMemo(
    () => [
      {
        field: VERTICE_USER_FIELD,
        headerName: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_GRID_COL_VERTICE'),
        flex: 1,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams<SlackConnection>) =>
          `${params.row.userName} ${params.row.firstName} ${params.row.lastName}`,
        renderCell: (params) => (
          <VerticeUserCell connectedUsers={slackConnectedUserIdentitiesData?.items} {...params} />
        ),
      },
      {
        field: SLACK_USER_FIELD,
        headerName: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_GRID_COL_SLACK'),
        flex: 1,
        minWidth: 200,
        editable: true,
        sortable: false,
        renderCell: (params) => <SlackUserCell connectedUsers={slackConnectedUserIdentitiesData?.items} {...params} />,
        renderEditCell: (params) => (
          <SlackUserSelectCell connectedUsers={slackConnectedUserIdentitiesData?.items} {...params} />
        ),
      },
      {
        field: 'status',
        headerName: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_GRID_COL_STATUS'),
        flex: 1,
        maxWidth: 142,
        filterOperators: [isUserNotLinkedFilterOperator],
        valueGetter: (params: GridValueGetterParams<SlackConnection, string | undefined>) =>
          getConnectedUser(params.row.userId, slackConnectedUserIdentitiesData?.items)?.status,
        renderCell: (params) => <StatusCell {...params} />,
      },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        align: 'center',
        maxWidth: 120,
        sortable: false,
        renderCell: (params) => (
          <ActionCell
            rowModesModel={editableDataGrid.dataGridProps.rowModesModel}
            connectedUsers={slackConnectedUserIdentitiesData?.items}
            isConnectUserLoading={isConnectUserLoading}
            loadingUserId={loadingUserId}
            refetchConnectedUsers={refetchSlackConnectedUserIdentitiesData}
            apiRef={apiRef}
            {...params}
          />
        ),
      },
    ],
    [
      editableDataGrid.dataGridProps.rowModesModel,
      isConnectUserLoading,
      loadingUserId,
      refetchSlackConnectedUserIdentitiesData,
      slackConnectedUserIdentitiesData?.items,
      t,
      apiRef,
    ]
  );

  const rows = useMemo(
    () =>
      chain(verticeUsersData)
        .filter((user) => user.userStatus === 'ACTIVE' || user.userStatus === 'PENDING')
        .orderBy(({ email }) => email?.toLocaleLowerCase(), 'asc')
        .map((user) => {
          const slackUsers = chain(slackUsersData?.items)
            .orderBy(({ email }) => email?.toLocaleLowerCase(), 'asc')
            .value();

          return {
            ...user,
            slackUsers,
          };
        })
        .value(),
    [slackUsersData?.items, verticeUsersData]
  );

  return (
    <EditableDataGridContextProvider value={editableDataGrid.context}>
      <DataGrid
        {...editableDataGrid.dataGridProps}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        autoHeight
        disableColumnMenu
        disableColumnReorder
        filterModel={filterModel}
        getRowHeight={() => 56}
        getRowId={getRowId}
        initialState={{
          sorting: {
            sortModel: [{ field: 'status', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { pageSize: 25 },
          },
        }}
        loadingStyle="skeleton"
        loading={isLoadingVerticeUsersData || isLoadingSlackUsersData || isLoadingSlackConnectedUserIdentitiesData}
        noBorder
        noBorderRadius
        pagination
        slots={editableDataGrid.dataGridProps.slots}
        sortingOrder={['asc', 'desc', null]}
      />
    </EditableDataGridContextProvider>
  );
};
