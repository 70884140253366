import React, { createContext, useContext } from 'react';
import type { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import type { SerializedError } from '@reduxjs/toolkit';
import type { InstanceData } from './InstanceProvider';
import type { ShoppingCartItemType } from './ShoppingCartProvider';
import type { OfferingsData } from './OfferingsProvider';
import { type RiListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

export type GetOfferingsHandler<Item extends InstanceData> = (
  item: Item,
  filter: Record<string, any>
) => Promise<{
  isFetching: boolean;
  data?: RiListOfferingsQuery;
  error?: ErrorResponse | SerializedError;
}>;

export type OfferingsParserHandler<Item extends InstanceData> = (
  data: RiListOfferingsQuery | undefined,
  item: Item,
  cartItems?: ShoppingCartItemType[] | null
) => Array<OfferingsData>;

type GetOfferingsHandlerContextData = {
  getOfferings: GetOfferingsHandler<InstanceData>;
  offeringsParser: OfferingsParserHandler<InstanceData>;
};

type GetOfferingsHandlerProviderProps = {
  children: React.ReactNode;
  getOfferings: GetOfferingsHandler<InstanceData>;
  offeringsParser: OfferingsParserHandler<InstanceData>;
};

const GetOfferingsHandlerContext = createContext<GetOfferingsHandlerContextData>({} as GetOfferingsHandlerContextData);

const GetOfferingsHandlerProvider = ({ children, ...value }: GetOfferingsHandlerProviderProps) => (
  <GetOfferingsHandlerContext.Provider value={value}>{children}</GetOfferingsHandlerContext.Provider>
);

export const useGetOfferingsHandler = () => useContext(GetOfferingsHandlerContext);

export default GetOfferingsHandlerProvider;
