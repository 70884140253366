import React from 'react';
import { styled, Typography } from '@mui/material';
import type { OverridableStringUnion } from '@mui/types';
import type { Variant } from '@mui/material/styles/createTypography';
import type { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import Calendar from '@mui/icons-material/CalendarTodayOutlined';

type CardTitleProps = {
  header: string;
  children?: React.ReactNode;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
};

const StyledSubHeaderWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  color: var(--tokens-color-light-text-2);
`;

export const StyledCalendar = styled(Calendar)`
  font-size: 1rem;
  margin-right: 0.25rem;
  color: var(--tokens-color-light-text-2);
`;

const CardTitle = ({ header, variant, children }: CardTitleProps) => (
  <>
    <Typography variant="heading-s">{header}</Typography>
    {children && <StyledSubHeaderWrapper variant={variant}>{children}</StyledSubHeaderWrapper>}
  </>
);

CardTitle.defaultProps = {
  variant: 'body-regular-s',
};

export default CardTitle;
