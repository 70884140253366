import React from 'react';
import { Box } from '@mui/material';

type InnerScrollableProps = {
  children?: React.ReactNode;
  padding?: number;
};

export const InnerScrollable: React.FC<InnerScrollableProps> = ({ children, padding = 8, ...otherProps }) => (
  <Box flexGrow={1} padding={padding} overflow="auto" {...otherProps}>
    {children}
  </Box>
);
