import { useTranslation } from 'react-i18next';

const useTermFormatter = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.UNITS' });

  return (value: number) => {
    const isYear = !(value % 6);
    if (isYear) {
      return t('YEAR', { count: value / 12 });
    }
    return t('MONTH', { count: value });
  };
};

export default useTermFormatter;
