import { useEffect, useRef } from 'react';
import { ContractFormData, FetchedDataTypeFromMode, FormSupportedMode } from '../types';
import { UseFormReturn } from 'react-hook-form';
import { fetchedContractToForm } from '../transformations';

export type UseContractAutoResetOnFetchedContractChangeParams<Mode extends FormSupportedMode> = {
  hookForm: UseFormReturn<ContractFormData>;
  fetchedContract: FetchedDataTypeFromMode<Mode>;
};

const useContractAutoResetOnFetchedContractChange = <Mode extends FormSupportedMode>({
  hookForm,
  fetchedContract,
}: UseContractAutoResetOnFetchedContractChangeParams<Mode>): void => {
  const isInitialRenderRef = useRef(true);
  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false;
      return;
    }
    hookForm.reset(fetchedContract ? fetchedContractToForm(fetchedContract) : undefined);
  }, [hookForm, fetchedContract]);
};

export default useContractAutoResetOnFetchedContractChange;
