import { ContractContextDataRequireFetched, FetchedContract } from '../types';

/**
 * Normally, FetchedContract's properties are inlined in the ContractContext for easy access.
 * This function is helpful in some corner cases, when you need to get fetchedContract from the ContractContext.
 */
const fetchedContractFromContext = ({
  accessVia,
  fetchedContract,
}: ContractContextDataRequireFetched): FetchedContract => ({
  ...fetchedContract,
  accessedVia: accessVia,
});

export default fetchedContractFromContext;
