import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { useLegalSubscriptionMutation } from '@vertice/slices/src/openapi/codegen/legalAPI';
import { useRequestVendorLegalInfoMutation } from '@vertice/slices/src/openapi/codegen/vendorAPI';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import { Button } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';
import { IconWrapper } from '@verticeone/design-system/src';

export interface BlurredContentProps {
  accountId: string;
  vendorId: string;
  mode: 'NO_SUBSCRIPTION' | 'NO_DATA';
}

const AbsoluteOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const BlurredContent: FC<BlurredContentProps> = ({ accountId, vendorId, mode }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [requestSubscription, { isLoading: isSubscriptionRequesting }] = useLegalSubscriptionMutation();
  const [requestVendorInsight, { isLoading: isLegalInfoRequesting }] = useRequestVendorLegalInfoMutation();

  const { t } = useTranslation();
  const contractsInsights = ['PAYMENT', 'LIABILITY', 'RENEWAL_USAGE', 'MISC'].map((item) =>
    t('LEGAL_INFO.INSIGHTS.' + item)
  );

  const handleResponse = (response: any) => {
    if (response.data) {
      enqueueSnackbar(t('SNACKBAR.LEGAL_REQUEST'), { variant: 'success' });
    } else {
      throw new Error(response.error);
    }
  };

  const onContactManager = async () => {
    const response = await requestSubscription({
      accountId,
    });
    handleResponse(response);
  };

  const onRequestInsights = async () => {
    const response = await requestVendorInsight({
      accountId,
      vendorId,
    });
    handleResponse(response);
  };

  const onActionButton = mode === 'NO_SUBSCRIPTION' ? onContactManager : onRequestInsights;

  const isRequesting = isSubscriptionRequesting || isLegalInfoRequesting;

  return (
    <Box position="relative">
      <Stack gap={4} px={6} sx={{ filter: 'blur(4px)' }}>
        <Text variant="caption" size="S" color="primary2">
          {t('LEGAL_INFO.CONTRACT_INSIGHTS')}
        </Text>
        <Stack component="ul" pl={4} m={0} gap={1.5}>
          {contractsInsights.map((item, i) => (
            <li key={`blurred-insights-${i}`}>{item}</li>
          ))}
        </Stack>
      </Stack>
      <AbsoluteOverlay>
        <Button
          size="XL"
          variant="solid"
          color="neutral"
          onClick={onActionButton}
          disabled={isRequesting}
          isLoading={isRequesting}
        >
          {t(`LEGAL_INFO.${mode}.BUTTON`)}
          <IconWrapper icon={ChevronRight} size="XL" opacity={0.6} />
        </Button>
      </AbsoluteOverlay>
    </Box>
  );
};

export default BlurredContent;
