import { MouseEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

import { BreadcrumbsV2 } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { ApplicationStatus, DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { useLastFetchedData } from '../hooks/useLastFetchedData';
import { LastFetchedInfo } from '../components/LastFetchedInfo';
import { ErrorLogButton, ErrorLogDrawer } from '../components/ErrorLogDrawer';
import { useGetApplicationsWithVendors } from './hooks';
import { Applications } from './Applications';
import { filterDailyAlarms } from './helpers';

const LOG_URL_PARAM = 'logOpen';

export type ApplicationsPageParams = {
  /** Router path to the Integrations page. Used as call-to-action if no applications are integrated. */
  integrationsHref?: string;
};

export const ApplicationsPage = ({ integrationsHref }: ApplicationsPageParams) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const [showIgnored, setShowIgnored] = useState(false);
  const [showGoogleSocial, setShowGoogleSocial] = useState(true);
  const [showErrorLog, setShowErrorLog] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const {
    applications,
    healthyApplicationsCount,
    unhealthyApplicationsCount,
    isLoading: isLoadingApplications,
  } = useGetApplicationsWithVendors(
    {
      accountId,
      status: showIgnored ? ApplicationStatus.Ignored : ApplicationStatus.Discovered,
      showGoogleSocial,
    },
    { skip: !accountId }
  );

  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.Usage);
  const accessLogs = data?.getAlarms?.lastDataUpdate;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const alarmsPerDay = filterDailyAlarms(data?.getAlarms?.alarmsPerDay);

  useEffect(() => {
    const logOpen = searchParams.get(LOG_URL_PARAM);
    if (logOpen === 'true') setShowErrorLog(true);
  }, [searchParams]);

  const handleLogClose = () => {
    searchParams.delete(LOG_URL_PARAM);
    setSearchParams(searchParams);
    setShowErrorLog(false);
  };

  const menuOpen = Boolean(menuAnchor);
  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <Stack direction="column" sx={{ width: 1 }} p={10} gap={6}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Stack direction="column" gap={2}>
          <Stack direction="row">
            <BreadcrumbsV2 items={[{ label: t('SAAS.NAVBAR.SAAS') }, { label: t('SAAS.NAVBAR.APPLICATIONS') }]} />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={4}>
          {!isLoadingLastFetchedData && (
            <>
              {!isLoadingApplications && hasAccessLogs && (
                <LastFetchedInfo accessLogs={accessLogs} alarmsPerDay={alarmsPerDay} />
              )}
              <ErrorLogButton onClick={() => setShowErrorLog(true)} />
              <ErrorLogDrawer open={showErrorLog} onClose={handleLogClose} alarmsPerDay={alarmsPerDay} />
            </>
          )}
          <IconButton onClick={handleMenuClick}>
            <MoreVert />
          </IconButton>
          <Menu id="menu" anchorEl={menuAnchor} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                setShowIgnored(!showIgnored);
                setMenuAnchor(null);
              }}
            >
              {showIgnored
                ? t('APPLICATIONS.USAGE.SHOW_ACTIVE_APPLICATIONS')
                : t('APPLICATIONS.USAGE.SHOW_HIDDEN_APPLICATIONS')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowGoogleSocial(!showGoogleSocial);
                setMenuAnchor(null);
              }}
            >
              {showGoogleSocial
                ? t('APPLICATIONS.USAGE.HIDE_GOOGLE_SOCIAL_APPLICATIONS')
                : t('APPLICATIONS.USAGE.SHOW_GOOGLE_SOCIAL_APPLICATIONS')}
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>

      <Applications
        applications={applications}
        loading={isLoadingApplications}
        healthyApplicationsCount={healthyApplicationsCount}
        unhealthyApplicationsCount={unhealthyApplicationsCount}
        integrationsHref={integrationsHref}
      />
    </Stack>
  );
};
