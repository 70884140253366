import { FC, useCallback } from 'react';
import { CommonIntakeFormFormData } from '../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductList } from '@vertice/core/src/modules/saas/contract/components/ProductList';
import { useProductExtraColumnBuilder } from '@vertice/core/src/modules/saas/contract/components/ProductList/hooks/useProductExtraColumnBuilder';
import { ProductItem, ProductListColumn } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { isNotNil } from '@verticeone/utils/validation';

type IntakeProductListProps = {
  showAnnualCost?: boolean;
};
export const IntakeProductList: FC<IntakeProductListProps> = ({ showAnnualCost }) => {
  const { setValue } = useFormContext<CommonIntakeFormFormData>();

  const products = useWatch<CommonIntakeFormFormData, 'products'>({ name: 'products' });
  const contractCurrency = useWatch<CommonIntakeFormFormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const vendor = useWatch<CommonIntakeFormFormData, 'vendor'>({ name: 'vendor' });

  const setProducts = useCallback(
    (productsToSet: ProductItem[]) => {
      setValue('products', productsToSet);
    },
    [setValue]
  );

  const { buildColumn } = useProductExtraColumnBuilder();

  return (
    <ProductList
      withActions
      extraColumns={[
        showAnnualCost ? buildColumn(ProductListColumn.ANNUAL_COST) : null,
        buildColumn(ProductListColumn.NUMBER_OF_LICENSES),
      ].filter(isNotNil)}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
      currency={contractCurrency}
      vendorId={vendor?.id ?? undefined}
    />
  );
};
