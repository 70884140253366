import React, { useId, useMemo } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatsBar, StatsBody, StatsContainer, Title, Value } from '@vertice/core/src/components/StatsBar';
import optimizationScoreBackground from './optimizationScoreBackground.svg';
import EstimatedSavingsStatsContainer from '../../components/EstimatedSavingsStatsContainer';
import { AWS_BRAND_COLOR } from '../../constants';
import GhostStatsTile from '@vertice/core/src/components/StatsBar/StatsTile/GhostStatsTile';
import AnnualInvoicedSpendStatsContainer from '../../components/AnnualInvoicedSpendStatsContainer';
import useAnnualSpendData from '../../hooks/useAnnualSpendData';
import AnnualGrossSpendStatsContainer from '../../components/AnnualGrossSpendStatsContainer';
import { useTestStats } from '../../hooks/useTestStats';

const MainTile = styled(GhostStatsTile)({
  backgroundImage: `url(${optimizationScoreBackground})`,
  backgroundSize: 'contain',
  backgroundPosition: 'bottom right 12px',
  backgroundRepeat: 'no-repeat',
});

const StyledStatsBar = styled(StatsBar)({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
});

const OptimizationScoreStatsContainer = (props: { flex: number }) => {
  const id = useId();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.DASHBOARD_OVERVIEW' });
  const optimizationDataQuery = useTestStats();

  const optimizationScore = useMemo(() => {
    if (optimizationDataQuery.isLoading || !optimizationDataQuery.data) {
      return t('CALCULATING');
    }

    const { passed, failed } = optimizationDataQuery.data!;

    if (passed === 0 && failed === 0) {
      return t('CALCULATING');
    }

    return passed + '/' + (passed + failed);
  }, [optimizationDataQuery, t]);

  return (
    <StatsContainer flex={props.flex}>
      <MainTile color={AWS_BRAND_COLOR}>
        <StatsBody
          isLoading={optimizationDataQuery.isLoading}
          title={
            <Title tooltip={{ content: t('OPTIMIZATION_SCORE_INFO') }} id={id}>
              {t('OPTIMIZATION_SCORE')}
            </Title>
          }
          value={<Value aria-labelledby={id}>{optimizationScore}</Value>}
        />
      </MainTile>
    </StatsContainer>
  );
};

const OverviewStatsCard = () => {
  const annualSpendDataQuery = useAnnualSpendData();
  return (
    <StyledStatsBar>
      <EstimatedSavingsStatsContainer flex={1} />
      <AnnualInvoicedSpendStatsContainer annualSpendDataQuery={annualSpendDataQuery} flex={0.75} />
      <AnnualGrossSpendStatsContainer annualSpendDataQuery={annualSpendDataQuery} flex={0.75} />
      <OptimizationScoreStatsContainer flex={0.75} />
    </StyledStatsBar>
  );
};

export default OverviewStatsCard;
