/**
 * Takes SVG path's d attribute string and returns a Highcharts-compatible array of commands and values
 */
export const svgPathToArray = (d: string): (string | number)[] => {
  const commands = d.match(/[a-zA-Z][^a-zA-Z]*/g) || [];
  return commands.flatMap((command) => {
    const type = command[0];
    const numbersPartStr = command.slice(1).trim();
    const values = numbersPartStr === '' ? [] : numbersPartStr.split(/[\s,]+/).map(Number);
    return [type, ...values];
  });
};
