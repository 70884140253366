import { Alert } from '@verticeone/design-system/src';
import { useDataSource } from '../../../DataSourceProvider';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRecommendationData } from './useRecommendationData';

const RecommendationAlert = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.VERTICE_RECOMMENDATION_ALERT' });
  const { data } = useRecommendationData();
  const { datasource } = useDataSource();
  const formatCurrency = useFormatCurrency();

  return (
    <Alert
      color="tertiary"
      size="M"
      subtitle={t('DESCRIPTION', {
        product: t(`PRODUCT.${datasource.toUpperCase()}`),
        cost: formatCurrency(data?.hourlyComputedUsage ?? 0, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 2,
        }),
        savings12Months: formatCurrency(data?.savings12Months ?? 0, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 0,
        }),
      })}
      testId="recommendation-alert"
      title={t('TITLE')}
      variant="ghost"
    />
  );
};

export default RecommendationAlert;
