import getAssumeRoleJwtToken from './assumeRole/getAssumeRoleJwtToken';
import getAmplifyJwtToken from '@verticeone/auth/src/authentication/amplify/getAmplifyJwtToken';

const getCfaJwtToken = async (): Promise<string> => {
  const assumeRoleToken = await getAssumeRoleJwtToken();
  if (assumeRoleToken) {
    return assumeRoleToken;
  }

  const amplifyToken = await getAmplifyJwtToken();
  if (amplifyToken) {
    return amplifyToken;
  }

  return '';
};

export default getCfaJwtToken;
