import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const CONVERSATIONS_TAG_PREFIX = 'Conversations';

export const conversationsAPI = createApi({
  reducerPath: 'conversationsAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.CONVERSATIONS),
  refetchOnMountOrArgChange: true,
  tagTypes: [CONVERSATIONS_TAG_PREFIX],
  endpoints: () => ({}),
});
