import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import React from 'react';
import { NavigationNode, Node, useCloudAnalytics } from '../../../CloudAnalyticsContext';
import { styled } from '@mui/material';
import { useParams } from 'react-router-dom';

type BreadcrumbItem = {
  label: string;
  onClick?: () => void;
  disabled: boolean;
};

const StyledBreadcrumbLink = styled(BreadcrumbLink)<{ $disabled: boolean }>(({ $disabled }) => ({
  cursor: !$disabled ? 'pointer' : 'default',
}));

const TableBreadcrumbs = () => {
  const { node, removeNode } = useCloudAnalytics();
  const { activeTab } = useParams();
  const activeTabId = activeTab as keyof NavigationNode;

  const findPathToNode = (item: Node, newPath: BreadcrumbItem[]): BreadcrumbItem[] => {
    if (!item.children) {
      return [
        ...newPath,
        {
          label: item.label,
          disabled: true,
        },
      ];
    }

    return findPathToNode(item.children, [
      ...newPath,
      {
        label: item.label,
        onClick: () => (item.children ? removeNode(item.children) : undefined),
        disabled: false,
      },
    ]);
  };

  const breadcrumbItems = findPathToNode(node[activeTabId], []);

  if (breadcrumbItems.length <= 1) {
    return null;
  }

  return (
    <Breadcrumbs variant="slash" size="S">
      {breadcrumbItems.map((breadcrumb, index) => (
        <StyledBreadcrumbLink
          key={index}
          onClick={breadcrumb.onClick}
          disabled={breadcrumb.disabled}
          $disabled={breadcrumb.disabled}
        >
          {breadcrumb.label}
        </StyledBreadcrumbLink>
      ))}
    </Breadcrumbs>
  );
};

export default TableBreadcrumbs;
