import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';

import { Text } from '@verticeone/design-system/src';
import { CardWrapper } from '../components/CardWrapper';
import { JiraSetupCard } from './Jira';
import { JIRA_PROVIDER_ID } from './Jira/constants';
import { VisoTrustSetupCard } from './VisoTrust';
import { VISOTRUST_PROVIDER_ID } from './VisoTrust/constants';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';

export const Workflows = () => {
  const { t } = useTranslation();

  const { isEnabled } = useFeatures();
  const jiraFeatureEnabled = isEnabled(FEATURES.INTEGRATION_JIRA);
  const visoTrustFeatureEnabled = isEnabled(FEATURES.INTEGRATION_VISOTRUST);

  return (
    <Stack gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.WORKFLOWS')}
      </Text>
      <Grid container direction="row" spacing={3}>
        {jiraFeatureEnabled && (
          <CardWrapper>
            <JiraSetupCard providerId={JIRA_PROVIDER_ID} />
          </CardWrapper>
        )}
        {visoTrustFeatureEnabled && (
          <CardWrapper>
            <VisoTrustSetupCard providerId={VISOTRUST_PROVIDER_ID} />
          </CardWrapper>
        )}
      </Grid>
    </Stack>
  );
};
