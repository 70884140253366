import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';

export type FullPageMessageParams = {
  verticalFill?: 'container' | 'window';
  title?: ReactNode;
  subtitle?: ReactNode;
  message?: ReactNode;
  callToAction?: ReactNode;
};

export const CallToActionLinkButton = ({ to, label }: { to: string; label: string }) => {
  return (
    <Button variant="solid" component={Link} to="/">
      {label}
      <ChevronRight opacity={0.6} />
    </Button>
  );
};

const FullPageMessage = ({
  verticalFill = 'container',
  title,
  subtitle,
  message,
  callToAction,
}: FullPageMessageParams) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    maxWidth="540px"
    minHeight={verticalFill === 'container' ? '100%' : '100vh'}
    margin="0 auto"
  >
    <Stack
      p={6}
      textAlign="center"
      spacing={5}
      alignItems="center"
      sx={{
        '@media (min-width: 960px)': {
          padding: '40px',
        },
      }}
    >
      {(title || subtitle) && (
        <Stack spacing={1}>
          {title && (
            <Text tag="div" variant="heading" size="L" color="text1">
              {title}
            </Text>
          )}
          {subtitle && (
            <Text tag="h1" variant="heading" size="S" color="text3">
              {subtitle}
            </Text>
          )}
        </Stack>
      )}
      {message && (
        <Text tag="p" variant="body-regular" size="M" color="text2">
          {message}
        </Text>
      )}
      {callToAction}
    </Stack>
  </Stack>
);

export default FullPageMessage;
