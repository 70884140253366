import React from 'react';
import { Stack, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';
import { useWizard, type WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Divider, Header, Content, Buttons, InnerScrollable } from '@vertice/core/src/components/Dialog/Components';
import { ContentWrapper } from './Components';

type SetupProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  translationId: string;
} & WizardStepProps;

type CriteriaItemProps = {
  text: string;
};

const Bullet = styled(Stack)(({ theme }) => ({
  width: '6px',
  height: '6px',
  minWidth: '6px',
  minHeight: '6px',
  borderRadius: '2px',
  marginTop: '0.5rem',
  backgroundColor: theme.palette.text.color1,
}));

const MailTo = styled('a')(({ theme }) => ({
  color: theme.palette.text.color1,
}));

const ConditionItem = ({ text }: CriteriaItemProps) => (
  <Stack gap="0.5rem" direction="row">
    <Bullet />
    <Text variant="body-regular" size="M" color="text1">
      <Trans
        i18nKey={text}
        components={{
          bold: <Text variant="body-bold" size="M" color="text1" />,
          mail: <MailTo href="mailto:support@vertice.one" />,
        }}
      />
    </Text>
  </Stack>
);

const SetUp = ({ icon: Icon, translationId }: SetupProps) => {
  const { t } = useTranslation();
  const wizard = useWizard();

  return (
    <Content>
      <Icon />
      <Divider />
      <Header
        title={`
              ${t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.TITLE')} 
              ${t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.TITLE`)}
            `.trim()}
        subtitle={t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.DESCRIPTION`)}
      />
      <InnerScrollable padding={0}>
        <ContentWrapper>
          <Text variant="caption" size="XS" color="text2" marginBottom={1}>
            {t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.CRITERIA.TITLE')}
          </Text>
          <ConditionItem text={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.CRITERIA.1')} />
          <ConditionItem text={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.CRITERIA.2')} />
          <ConditionItem text={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.CRITERIA.3')} />
          <ConditionItem text={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.CRITERIA.4')} />
          <ConditionItem text={t('PREFERENCES.INTEGRATIONS.ACCOUNTING.WIZARD.SET_UP.CRITERIA.5')} />
        </ContentWrapper>
      </InnerScrollable>
      <Buttons
        primary={{
          onClick: () => wizard.goToStep('integration_details'),
          title: t('DIALOG.BUTTONS.NEXT'),
        }}
      />
    </Content>
  );
};

export default SetUp;
