import React, { useEffect } from 'react';
import { HierarchyData, HierarchyItem } from './useHierarchyGraphData';
import TreeCanvas from '../../components/HierarchyTree/TreeCanvas';
import Tree from '../../components/HierarchyTree/Tree';
import Node from './Node';

type HierarchyGraphProps = {
  data: HierarchyData;
};

const HierarchyGraph = ({ data }: HierarchyGraphProps) => {
  const nodeRenderer = (item: HierarchyItem, selected: boolean) => (
    <Node data={item} selected={selected} currency={data.currency} />
  );
  const centerToRootNode = () => {
    if (window.document) {
      // Get root node and center it in the view
      const scrollableElement = window.document.getElementById(data.hierarchy.id);
      scrollableElement?.scrollIntoView({ inline: 'center' });

      // Scroll back to the top of the page
      const mainElements = window.document.getElementsByTagName('main');
      if (mainElements.length) {
        mainElements[0].scrollTo(0, 0);
      }
    }
  };

  useEffect(() => {
    centerToRootNode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TreeCanvas px={2} py={2}>
      <Tree data={data.hierarchy} nodeWidth={300} rowHeight={200} nodeRenderer={nodeRenderer} activeRows={[1]} />
    </TreeCanvas>
  );
};

export default HierarchyGraph;
