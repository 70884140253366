import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormEditorField, {
  type FormEditorFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormEditorField';
import React from 'react';
import { ContractFormData } from '../../types';
import ReactMarkdown from 'react-markdown';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormEditorFieldComponentType<ContractFormData>;

type EditorContractFormEntryProps = Omit<
  ContractFormEntryProps<ReadComponent, WriteComponent>,
  'component' | 'writeComponent'
>;

const EditorContractFormEntry = (options: EditorContractFormEntryProps) => {
  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value) => {
          return value ? <ReactMarkdown>{value}</ReactMarkdown> : undefined;
        },
      }}
      writeComponent={FormEditorField}
    />
  );
};

export default EditorContractFormEntry;
