import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Content } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData } from '../../types';
import useAutoProgressDelay from '../../../sharedHooks/useAutoProgressDelay';
import { VendorLogoConfirmationPanel } from '../../../SharedComponents/VendorLogoConfirmationPanel';

const useContractAddedWizard = () => {
  const wizard = useWizard();

  return {
    goToNext: () => wizard.goToStep('choose_initiate_renewal'),
  };
};

const ContractAdded: React.FC<WizardStepProps> = () => {
  const { goToNext } = useContractAddedWizard();

  useAutoProgressDelay(goToNext);

  const { watch } = useFormContext<AddExistingContractFormData>();
  const vendor = watch('vendor');
  return (
    <Content>
      <VendorLogoConfirmationPanel
        headingTitleKey="INTAKE_FORM.EXISTING_CONTRACT_CONTRACT_ADDED.HEADING_TITLE"
        vendorName={vendor?.name ?? undefined}
      />
    </Content>
  );
};

export default ContractAdded;
