import React from 'react';
import { FormControl, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskContext } from '../../../TaskContext';
import { DocumentsSection } from '../shared/formSections/DocumentsSection';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useTaskFormContext } from '../shared/TaskFormContext';
import { SignDateFormEntry } from '../shared/formFields/SignDateFormEntry';
import { useWatch } from 'react-hook-form';
import { FormData } from './schema';

const SIZE = 'S';

export const ContractingForm = () => {
  const { t } = useTranslation();
  const { taskOverview } = useTaskContext();
  const { readOnly } = useTaskFormContext();

  const requestId = taskOverview?.request?.ref ? parseRequestRef(taskOverview?.request?.ref).requestId : null;
  const contractSigned = useWatch<FormData, 'contractSigned'>({ name: 'contractSigned' });
  const signingDate = useWatch<FormData, 'signingDate'>({ name: 'signingDate' });

  return (
    <Stack gap={6}>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="vendorInformed"
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACTING.VENDOR_INFORMED.LABEL')}
          description={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACTING.VENDOR_INFORMED.DESCRIPTION')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size={SIZE}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="contractSentToSignatory"
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACTING.CONTRACT_SENT_TO_SIGNATORY.LABEL')}
          description={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACTING.CONTRACT_SENT_TO_SIGNATORY.DESCRIPTION')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size={SIZE}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Stack gap={4}>
          <FormChecklistItemField
            name="contractSigned"
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACTING.CONTRACT_SIGNED.LABEL')}
            description={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACTING.CONTRACT_SIGNED.DESCRIPTION')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={readOnly}
            size={SIZE}
          />
          {(readOnly ? signingDate : contractSigned) && <SignDateFormEntry />}
        </Stack>
      </FormControl>
      {requestId ? <DocumentsSection /> : null}
      <AdditionalNotesFormEntry />
    </Stack>
  );
};
