import React from 'react';
import { FormatCurrencyOptions, formatCurrency } from '@verticeone/utils/formatting';

type CurrencyProps = FormatCurrencyOptions & {
  className?: string;
  value: number;
};

/** @deprecated Use formatCurrency function from @vertice/core directly. It takes the same parameters. */
const Currency: React.FC<CurrencyProps> = ({ className, value, ...currencyProps }) => (
  <span className={className} data-testid="currency-data">
    {formatCurrency(value, currencyProps)}
  </span>
);

export default Currency;
