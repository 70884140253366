import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ViewSettingsFormEntry from '../ViewSettingsFormEntry';

const FormContractRollingFrequencyMultiselect = () => {
  const { t } = useTranslation();

  const rollingFrequencyOptions = useMemo(
    () => [
      { value: 'NO', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_NO') },
      { value: 'MONTHLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_MONTHLY') },
      { value: 'QUARTERLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_QUARTERLY') },
      { value: 'ANNUALLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ANNUALLY') },
      { value: 'BIANNUALLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_BIANNUALLY') },
    ],
    [t]
  );

  return (
    <ViewSettingsFormEntry
      name="filters.contractRollingFrequency"
      label={t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_ROLLING_FREQUENCY')}
      component={FormSelectField2}
      componentProps={{
        isMulti: true,
        size: 'S',
        options: rollingFrequencyOptions,
        placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
        boxValue: (values?: string[]) =>
          (values ?? [])
            .map((value) => rollingFrequencyOptions.find((option) => option.value === value))
            .filter((x) => !isNil(x)),
        unboxValue: (options?: typeof rollingFrequencyOptions) => options?.map((option) => option.value) ?? [],
      }}
      width={12}
    />
  );
};

export default FormContractRollingFrequencyMultiselect;
