import React, { useId } from 'react';
import { AnnualSpendData } from '../hooks/useAnnualSpendData';
import {
  StatsBody,
  StatsContainer,
  StatsContainerProps,
  StatsTile,
  Title,
  Value,
} from '@vertice/core/src/components/StatsBar';
import { AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../constants';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';

type AnnualGrossSpendStatsContainerProps = StatsContainerProps & {
  annualSpendDataQuery: LoadableAdvanced<AnnualSpendData>;
};

const AnnualGrossSpendStatsContainer = ({ annualSpendDataQuery, ...props }: AnnualGrossSpendStatsContainerProps) => {
  const id = useId();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANNUAL_GROSS_SPEND_STATS_CONTAINER' });
  return (
    <StatsContainer {...props}>
      <StatsTile>
        <StatsBody
          isLoading={annualSpendDataQuery.isLoading}
          title={
            <Title tooltip={{ content: t('TOOLTIP') }} id={id}>
              {t('TITLE')}
            </Title>
          }
          value={
            <Value aria-labelledby={id}>
              {!annualSpendDataQuery.isLoading &&
                formatCurrency(annualSpendDataQuery.data!.annualGrossSpend, {
                  currency: annualSpendDataQuery.data!.currency,
                  maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
                })}
            </Value>
          }
        />
      </StatsTile>
    </StatsContainer>
  );
};

export default AnnualGrossSpendStatsContainer;
