export const TEST_CODE = {
  EC2: {
    test: 'VT-18',
    recommendation: 'VR-18',
  },
  RDS: {
    test: 'VT-55',
    recommendation: 'VR-55',
  },
  REDSHIFT: {
    test: 'VT-58',
    recommendation: 'VR-58',
  },
  ELC: {
    test: 'VT-47',
    recommendation: 'VR-47',
  },
  OSS: {
    test: 'VT-50',
    recommendation: 'VR-50',
  },
};
