import { styled } from '@mui/material';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';

import { DesignSystemSize } from '../../types';
import { Text } from '../Text';
import { BreadcrumbItem } from './types';
import { isLabelString } from './utils';

export const StyledLink = styled((props: MuiLinkProps) => <MuiLink {...props} />)(({ theme }) => ({
  cursor: 'default',
  lineHeight: 1,
  textDecoration: 'none',
  '&[href]:hover': {
    cursor: 'pointer',
    color: theme.palette.inactive.hover.color1,
    textDecoration: 'underline',
    textDecorationColor: 'currentColor',
  },
}));

type BreadcrumbLinkProps<C extends React.ElementType = 'a'> = BreadcrumbItem<C> & {
  size: DesignSystemSize;
};

export const BreadcrumbLink = <C extends React.ElementType = 'a'>({
  label,
  size,
  ...restProps
}: BreadcrumbLinkProps<C>) => {
  if (isLabelString(label))
    return (
      <StyledLink {...restProps}>
        <Text variant="button-regular" size={size} color="inactive2">
          {label}
        </Text>
      </StyledLink>
    );

  return label;
};
