import styled from '@mui/material/styles/styled';
import MuiRadio, { RadioProps, radioClasses } from '@mui/material/Radio';

import { Theme } from '@mui/material/styles';
import { CustomizedProps, DesignSystemColor, DesignSystemSize } from '../../types';
import { svgIconClasses } from '@mui/material/SvgIcon';

export type RadioSwitchProps = CustomizedProps &
  Omit<RadioProps, 'color' | 'size'> & {
    color?: DesignSystemColor;
    size?: DesignSystemSize;
    disabled?: boolean;
  };

type SizeDef = {
  size: number;
  circleSize: number;
  padding: number;
  alignment: number;
};

const sizeDefinitions: Record<DesignSystemSize, SizeDef> = {
  XL: { size: 50, circleSize: 26, padding: 15, alignment: -1 },
  L: { size: 48, circleSize: 24, padding: 13.5, alignment: 0 },
  M: { size: 40, circleSize: 22, padding: 12, alignment: 2 },
  S: { size: 38, circleSize: 20, padding: 11.5, alignment: 3 },
  XS: { size: 30, circleSize: 18, padding: 10, alignment: 5 },
  XXS: { size: 28, circleSize: 16, padding: 8.5, alignment: 6 },
};

/** WARNING: Don't use directly in apps. Rather use via RadioSwitch with a specified variant. */
export const StyledRadioSwitch = styled(MuiRadio)(
  ({
    color = 'primary',
    $size = 'M',
    disabled = false,
    theme,
  }: RadioSwitchProps & { theme: Theme; $size: DesignSystemSize }) => {
    const sizeDef = sizeDefinitions[$size];

    const renderingColor = disabled ? theme.palette.inactive.color2 : theme.palette[color].color2;

    return {
      width: sizeDef.size,
      height: sizeDef.size,
      padding: sizeDef.padding,
      marginLeft: sizeDef.alignment,
      borderRadius: '25%',
      [`.${radioClasses.root}`]: {
        color: renderingColor,
        width: sizeDef.size,
        height: sizeDef.size,
      },
      [`.${svgIconClasses.root}`]: {
        width: sizeDef.circleSize,
        height: sizeDef.circleSize,
      },
      [`&.${radioClasses.checked} span svg:nth-child(2)`]: {
        transform: 'scale(1.20)',
      },
    };
  }
);
