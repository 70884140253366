import React from 'react';
import ExtensionGrid from '../../ExtensionGrid';
import MostCostlyEbsVolumesCard from './components/MostCostlyEbsVolumesCard';
import HistoricalSpendByEBSGenerationCard from './components/HistoricalSpendByEBSGenerationCard/HistoricalSpendByEBSGenerationCard';
import EBSProvisionedStorageDistributionCard from './components/EBSProvisionedStorageDistributionCard/EBSProvisionedStorageDistributionCard';

const VR31 = () => {
  return (
    <>
      <ExtensionGrid breakpoints={[5, 7]}>
        <EBSProvisionedStorageDistributionCard />
        <HistoricalSpendByEBSGenerationCard />
      </ExtensionGrid>
      <MostCostlyEbsVolumesCard />
    </>
  );
};

export default VR31;
