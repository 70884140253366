import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { ProductKey } from '../../../types';

type InstanceListProviderProps = {
  service: ProductKey;
  permissions: string[];
  itemsCount: number | undefined;
  children: React.ReactNode;
};

type InstanceListContextData = {
  product: ProductKey;
  permissions: string[];
  pagination: {
    loadNextPage: () => void;
    visibleItems: number;
    isVisible: boolean;
  };
};

const InstanceListContext = createContext<InstanceListContextData>({} as InstanceListContextData);

const InstanceListProvider = ({ service, permissions, itemsCount, children }: InstanceListProviderProps) => {
  const [visibleItems, setVisibleItems] = useState(10);
  const isVisible = useMemo(() => visibleItems < (itemsCount ?? 0), [itemsCount, visibleItems]);
  const loadNextPage = useCallback(() => setVisibleItems((prev) => prev + 10), [setVisibleItems]);

  return (
    <InstanceListContext.Provider
      value={{
        permissions,
        product: service,
        pagination: { isVisible, visibleItems, loadNextPage },
      }}
    >
      {children}
    </InstanceListContext.Provider>
  );
};

export const useInstanceList = () => useContext(InstanceListContext);

export default InstanceListProvider;
