import { useCallback, useEffect, useState } from 'react';
import { FileAccepted, FileRejected } from '@verticeone/design-system/src';
import {
  useDeleteContractFileV2Mutation,
  useListContractAttachmentsQuery,
  useResolveUserPermissionsOnContractQuery,
  useUploadContractFileV2Mutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useContractContext } from '../../ContractContext';
import { autoDownloadBlob } from '@verticeone/utils/file';
import { orderBy } from 'lodash';
import { API_URLS, useApiFileFetching } from '@vertice/slices';
import { generatePath } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';

const CONTRACT_FILE_PATH = '/accounts/:accountId/contracts/:contractId/files/:fileName';

const useContractDocuments = () => {
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const accountId = contractContext.accessVia.accountId;
  const contractId = contractContext.fetchedContract?.contract?.record.contractId;
  const formFiles = useWatch<ContractFormData>({ name: 'files' });
  const [loadedFiles, setLoadedFiles] = useState<File[] | undefined>();
  const { fetchFile } = useApiFileFetching();

  const { data: userContractPermissions } = useResolveUserPermissionsOnContractQuery(
    { accountId, contractId: contractId! },
    { skip: !contractId }
  );
  // allow adding files to nonexistent contract
  const canEditFiles = !contractId || (userContractPermissions?.permissions.w ?? false);

  const {
    data: contractAttachments,
    refetch,
    isFetching,
  } = useListContractAttachmentsQuery(
    {
      accountId: accountId!,
      contractId: contractId!,
    },
    { skip: !accountId || !contractId }
  );

  const [uploadContractFile] = useUploadContractFileV2Mutation();
  const [deleteContractFile] = useDeleteContractFileV2Mutation();

  const setFormFiles = useCallback(
    (filesToSet: File[]) => {
      contractContext.hookForm.setValue('files', filesToSet);
    },
    [contractContext.hookForm]
  );

  useEffect(() => {
    if (!formFiles && loadedFiles) {
      // We need to set the form value on initial load and whenever the edit mode is switched
      setFormFiles(loadedFiles);
    }
  }, [formFiles, loadedFiles, setFormFiles]);

  useEffect(() => {
    if (!contractAttachments) {
      return;
    }
    const mappedAttachments = contractAttachments.attachments.map(
      (attachment) =>
        ({
          name: attachment.fileName,
          size: attachment.size,
          lastModified: attachment.lastModified ? new Date(attachment.lastModified).getTime() : 0,
        } as unknown as File)
    );

    const sortedAttachments = orderBy(mappedAttachments, (f) => f.lastModified, ['asc']);
    setLoadedFiles(sortedAttachments);
  }, [contractAttachments, setFormFiles]);

  const deleteFiles = async (removedFiles: FileAccepted[]) => {
    const originalFiles = contractContext.hookForm.getValues('files');
    const removedFileNames = removedFiles.map((f) => f.file.name);
    const allAcceptedFiles = originalFiles?.filter((f) => !removedFileNames.includes(f.name)) ?? [];
    setFormFiles(allAcceptedFiles);

    //Delete files directly if the contract already exists
    if (contractId) {
      const removePromises: Promise<void>[] = [];
      removedFiles.forEach((removedFile) => {
        removePromises.push(
          deleteContractFile({
            accountId: accountId,
            contractId: contractId,
            fileName: encodeURIComponent(removedFile.file.name),
          }).unwrap()
        );
      });
      await Promise.all(removePromises);
      void refetch();
    }
  };

  const uploadFiles = async (newFiles: FileAccepted[]) => {
    const originalFiles = contractContext.hookForm.getValues('files');
    const allAcceptedFiles = [...(originalFiles ?? []), ...newFiles.map((file) => file.file)];
    setFormFiles(allAcceptedFiles);

    //Upload files directly if the contract already exists
    if (contractId) {
      const uploadPromises: Promise<string>[] = [];
      newFiles.forEach((newFile) => {
        uploadPromises.push(
          uploadContractFile({
            accountId: accountId,
            contractId: contractId,
            fileName: encodeURIComponent(newFile.file.name),
            body: newFile.file,
          }).unwrap()
        );
      });

      await Promise.all(uploadPromises);
      void refetch();
    }
  };

  const downloadFile = async (file: FileAccepted | FileRejected) => {
    const fileName = file.file.name;
    if (contractId) {
      // Download remote file
      const contractFilePath = generatePath(CONTRACT_FILE_PATH, {
        accountId,
        contractId,
        fileName: encodeURIComponent(fileName),
      });

      void fetchFile(`${API_URLS.BASE_URLS.BFFE_SAAS}${contractFilePath}`).then((blob: Blob) => {
        autoDownloadBlob(blob, fileName);
      });
    } else {
      // Download local file
      autoDownloadBlob(file.file, fileName);
    }
  };

  return {
    files: formFiles?.map((file: File) => ({ file })),
    isFetching: isFetching && !formFiles,
    uploadFiles,
    deleteFiles,
    downloadFile,
    canEditFiles,
  };
};

export default useContractDocuments;
