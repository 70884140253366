import { PaymentTerms } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import usePaymentTermsOptions from '../../../hooks/usePaymentTermsOptions';
import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { EllipsisTextCell } from './EllipsisTextCell';

export type PaymentTermsCellProps = { value: PaymentTerms };

export const PaymentTermsCell = ({ value }: PaymentTermsCellProps) => {
  const paymentTermsOptions = usePaymentTermsOptions();
  const paymentTermsMap = useMemo(() => keyBy(paymentTermsOptions, 'value'), [paymentTermsOptions]);

  return <EllipsisTextCell value={paymentTermsMap[value]?.label} />;
};
