import { Theme } from '@mui/material/styles';
import { StylesConfig, CSSObjectWithLabel } from 'react-select';
import { mergeWith } from 'lodash';
import { ControlProps } from 'react-select';
import { DesignSystemColor, DesignSystemSize } from '../../../types';
import { GroupBase, SelectVariant } from '../types';

export type MakeStylesParams = {
  theme: Theme;
  color: DesignSystemColor;
  variant: SelectVariant;
  size: DesignSystemSize;
  error: boolean;
};

/**
 * Merge two StylesConfigs for React-Select. Apply `overrideStyles` on top of `baseStyles`.
 * See tests for usage examples.
 */
export const mergeStylesConfigs = <Option extends unknown, IsMulti extends boolean, Group extends GroupBase<Option>>(
  baseStyles: StylesConfig<Option, IsMulti, Group>,
  overrideStyles: StylesConfig<Option, IsMulti, Group>
): StylesConfig<Option, IsMulti, Group> =>
  mergeWith(
    baseStyles,
    overrideStyles,
    (componentBaseStylesFn, componentOverrideStylesFn) =>
      (reactSelectComponentBaseStyles: CSSObjectWithLabel, state: ControlProps<Option, IsMulti, Group>) => {
        const componentBaseStyles = componentBaseStylesFn
          ? componentBaseStylesFn(reactSelectComponentBaseStyles, state)
          : reactSelectComponentBaseStyles;
        return componentOverrideStylesFn ? componentOverrideStylesFn(componentBaseStyles, state) : componentBaseStyles;
      }
  );
