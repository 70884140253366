import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardActions, CardContent, CardHeader, Stack, styled } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

import {
  useDeactivateAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import { ConfirmationDialog } from '@vertice/components';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { IconWrapper, Loader } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import { StatusBadge, StatusBadgeVariant } from '../../components/StatusBadge';
import { isValidIntegrationResponse } from '../../Notifications/components/Slack/common';
import { IntegrationsCardContent } from '../../components/IntegrationsCardContent';

type WorkflowsSetupCardProps = {
  providerId: string;
  providerName: string;
  providerIcon: ReactNode;
  description: string;
  setupRoute: string;
};

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(100),
  borderRadius: '8px',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.core.color3}`,
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const getBadgeStatus = (integrationStatus?: string) => {
  switch (integrationStatus) {
    case 'MISSING':
      return StatusBadgeVariant.NEW;
    case 'INACTIVE':
      return StatusBadgeVariant.INACTIVE;
    case 'ACTIVATING':
    case 'TESTING':
      return StatusBadgeVariant.PROGRESS;
    case 'ACTIVE':
      return StatusBadgeVariant.ACTIVE;
    case 'FAILED':
      return StatusBadgeVariant.ERROR;
    default:
      return undefined;
  }
};

export const WorkflowsSetupCard = ({
  providerId,
  providerName,
  providerIcon,
  description,
  setupRoute,
}: WorkflowsSetupCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const [revokeConfirmationDisplayed, setRevokeConfirmationDisplayed] = useState(false);

  const {
    data: integration,
    isLoading: isGetIntegrationLoading,
    isError: isGetIntegrationError,
  } = useGetAccountIntegrationQuery({ accountId: accountId, integrationId: providerId }, { skip: !accountId });

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const handleRevoke = async (action: 'DELETE' | 'CANCEL' | 'CONFIRM') => {
    setRevokeConfirmationDisplayed(action === 'CONFIRM');

    if (action === 'DELETE') {
      const response = await deactivateIntegration({
        accountId: accountId,
        integrationId: providerId,
      });
      if (isValidIntegrationResponse(response)) {
        void revokeIntegration({ accountId: accountId, integrationId: providerId });
      }
    }
  };

  const navigateToSetup = () => {
    navigate(setupRoute);
  };

  const integrationStatus = integration?.status;
  const isIntegrationActive = integrationStatus === 'ACTIVE';
  const badgeStatus = getBadgeStatus(integrationStatus);
  const revokeInProgress = isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress;

  if (isGetIntegrationLoading)
    return (
      <StyledCard variant="outlined">
        <Box p={24}>
          <Loader />
        </Box>
      </StyledCard>
    );

  return (
    <>
      <StyledCard variant="outlined">
        <CardHeader avatar={providerIcon} />
        <StyledCardContent>
          <IntegrationsCardContent
            title={providerName}
            description={description}
            statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
          />
        </StyledCardContent>
        <StyledCardActions>
          {isGetIntegrationError ? (
            t('PREFERENCES.INTEGRATIONS.STATUS.ERROR')
          ) : (
            <Stack direction="row" gap={2}>
              <Button
                variant="outline"
                size="S"
                color="neutral"
                onClick={navigateToSetup}
                disabled={revokeInProgress}
                endIcon={<IconWrapper icon={ArrowForwardIos} size="M" sx={{ opacity: 0.6 }} />}
              >
                {t('PREFERENCES.INTEGRATIONS.SETUP')}
              </Button>
              {isIntegrationActive && (
                <Button
                  onClick={() => handleRevoke('CONFIRM')}
                  variant="ghost"
                  color="neutral"
                  size="S"
                  disabled={revokeInProgress}
                >
                  {t('PREFERENCES.INTEGRATIONS.REVOKE_ACCESS')}
                </Button>
              )}
            </Stack>
          )}
        </StyledCardActions>
      </StyledCard>
      <ConfirmationDialog
        isOpen={revokeConfirmationDisplayed}
        headerText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.WARNING')}
        bodyPrimaryText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
        bodySecondaryText={t('PREFERENCES.INTEGRATIONS.ALERTING.REVOKE_DIALOG.DESCRIPTION', { providerName })}
        primaryButtonAction={() => handleRevoke('DELETE')}
        secondaryButtonAction={() => handleRevoke('CANCEL')}
        primaryButtonText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.DELETE')}
        secondaryButtonText={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.CANCEL')}
      />
    </>
  );
};
