import clsx from 'clsx';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import style from './SubmitButton.module.scss';

type SubmitButtonProps = {
  isLoading?: boolean;
  children: React.ReactNode;
};

/** @deprecated see packages/admin-panel/src/modules/saas/contract/ContractDetailForm/SubmitButton.tsx for better example */
const SubmitButton = ({ isLoading, children, ...otherProps }: SubmitButtonProps) => {
  const { isSubmitting, isDirty, isValid } = useFormState();

  return (
    <LoadingButton
      type="submit"
      fullWidth
      variant="contained"
      disabled={!isDirty && !isValid}
      loading={isSubmitting || (isLoading ?? false)}
      className={clsx('uppercase', style.button)}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
};

export default SubmitButton;
