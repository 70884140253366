import React, {
  MouseEventHandler,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import type {
  DesignSystemColor,
  DesignSystemSize,
  CustomizedProps,
  Direction,
  DesignSystemVariant,
  TestProps,
} from '../../types';
import type { StackProps } from '@mui/material/Stack';
import Popper from '@mui/material/Popper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { MenuItem } from '../Menu';
import { BaseButtonGroup } from './BaseButtonGroup';
import { ButtonGroupContext } from './ButtonGroupContext';

import type { ButtonProps } from '../Button';
import { IconButton } from '../IconButton';
import { testProps } from '../../utils/testProperties';
import { MenuList } from '@mui/material';

type OmittedButtonProps = Omit<StackProps, 'color' | 'direction' | 'children'>;
type SharedProps = CustomizedProps & OmittedButtonProps;

type ModifiedChildren = {
  active: Array<ReactNode>;
  dropdown: Array<ReactNode>;
};

type ButtonDropdownProps = {
  open: boolean;
  onClickAway: () => void;
  onClick: (node: ReactNode) => void;
  children: Array<ReactNode>;
};

type BaseButtonGroupProps<V> = TestProps & {
  size?: DesignSystemSize;
  color?: DesignSystemColor;
  variant?: DesignSystemVariant;
  direction?: Direction;
  activeButtonKeys?: V;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  container?: boolean;
  collapsed?: boolean;
  multiple?: boolean;
  children: ReactNode;
} & SharedProps;

export type ButtonGroupProps =
  | ({ multiple: true } & BaseButtonGroupProps<Array<number>>)
  | ({ multiple?: false } & BaseButtonGroupProps<number>);

// Dropdown menu for collapsed button group
const ButtonDropdown = React.forwardRef(({ children, onClick, open, onClickAway }: ButtonDropdownProps, ref) => (
  <Popper
    open={open}
    anchorEl={(ref as MutableRefObject<null>)?.current}
    role={undefined}
    transition
    disablePortal
    placement="bottom-end"
  >
    {({ TransitionProps }) => (
      <Grow {...TransitionProps}>
        <Paper>
          <ClickAwayListener onClickAway={onClickAway}>
            <MenuList autoFocusItem>
              {children.map((child: any) => (
                <MenuItem key={child.key} onClick={() => onClick(child)}>
                  {child.props.children}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
));

export const ButtonGroup = ({
  size = 'M',
  color = 'neutral',
  variant = 'solid',
  direction = 'horizontal',
  disabled = false,
  collapsed = false,
  container = false,
  multiple = false,
  activeButtonKeys: initValue,
  testId,
  onClick,
  children,
  ...otherProps
}: ButtonGroupProps) => {
  const anchorRef = React.useRef(null);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [activeButtons, setActiveButtons] = useState<Array<number>>(() => {
    if (initValue) {
      return Array.isArray(initValue) ? initValue : [initValue];
    }

    return multiple ? [] : [0];
  });

  const filteredValidChildrenArray: Array<ReactNode> = useMemo(
    () => React.Children.toArray(children).filter((child) => React.isValidElement(child)),
    [children]
  );

  // prettier-ignore
  const changeActiveButton = useCallback((node: ReactNode) => {
    setActiveButtons((prevState) => {
      const key = filteredValidChildrenArray.findIndex(child => (child as any).key === (node as any).key);

      if (multiple) {
        return prevState.includes(key) ? prevState.filter((buttonKey) => buttonKey !== key) : prevState.concat(key);
      }

      return [key];
    });
  }, [filteredValidChildrenArray, setActiveButtons, multiple]);

  // separate children to two active and dropdown arrays.
  // if group is collapsed there is only one active button and others are in dropdown
  // if group is not collapsed all buttons are active and dropdown is empty array
  const modifiedChildren = useMemo<ModifiedChildren>(() => {
    if (collapsed) {
      return {
        active: filteredValidChildrenArray.filter((child, index) => activeButtons.includes(index)),
        dropdown: filteredValidChildrenArray.filter((child, index) => !activeButtons.includes(index)),
      };
    }

    return {
      active: filteredValidChildrenArray.map((child, index) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        return React.cloneElement<ButtonProps>(child as ReactElement<ButtonProps>, {
          isActive: activeButtons.includes(index),
          onClick: (e, ...args) => {
            e.currentTarget.blur();
            changeActiveButton(child);

            if (onClick) {
              onClick(e, ...args);
            }
            if (child.props.onClick) {
              child.props.onClick(e, ...args);
            }
          },
        });
      }),
      dropdown: [],
    };
  }, [onClick, filteredValidChildrenArray, collapsed, activeButtons, changeActiveButton]);

  // prettier-ignore
  const providedValues = useMemo(() => ({
    size,
    color,
    variant,
    disabled,
  }), [size, color, variant, disabled]);

  return (
    <ButtonGroupContext.Provider value={providedValues}>
      <>
        <BaseButtonGroup
          role="group"
          ref={anchorRef}
          $direction={direction}
          $container={container}
          $variant={variant}
          $color={color}
          $size={size}
          {...testProps(testId, 'button-group')}
          {...otherProps}
        >
          {modifiedChildren.active}
          {collapsed && (
            <IconButton
              fullWidth={direction === 'vertical' && !container}
              size={size}
              icon={ExpandMoreIcon}
              variant={variant}
              onClick={() => setPopperOpen(true)}
            />
          )}
        </BaseButtonGroup>

        <ButtonDropdown
          ref={anchorRef}
          open={popperOpen}
          onClick={(node) => {
            changeActiveButton(node);
            setPopperOpen(false);
          }}
          onClickAway={() => setPopperOpen(false)}
        >
          {modifiedChildren.dropdown}
        </ButtonDropdown>
      </>
    </ButtonGroupContext.Provider>
  );
};
