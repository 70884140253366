import { addDays, subDays, format, startOfDay } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import {
  type MonitoringValueCheckResult,
  type TableType,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { DATE_FORMAT, getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { generatePreviousDaysData } from '../../../components/utils';

export type SummaryDataColumns = 'resource_cost_per_hour';
export type DailyDataColumns = 'timestamp' | 'sp_covered_cost' | 'on_demand_cost';

type GraphDataItem = {
  date: string;
  totalUsage: number | null;
  spCoveredUsage: number | null;
  onDemandUsage: number | null;
  averageUsage: number | null;
  spPurchaseRecommendation: number | null;
};

export type GraphData = Array<GraphDataItem>;

const FUTURE_PERIOD = 5;
const COMPUTED_PERIOD = 45;

const SPRIOOptimizeGraphQuery = graphql(`
  query SageMakerSPRIODaily($accountId: String!) {
    summaryQuery: monitoringLatestQuery(
      params: { accountId: $accountId, monitoringType: CHECK, code: "SAGE_MAKER_COVERAGE_LAST_DAYS" }
    ) {
      __typename
      ... on MonitoringResult {
        items {
          code
          results {
            __typename
            ... on MonitoringValueCheckResult {
              columns
              data
              dataTypes
            }
          }
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
    dailyUsageQuery: athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_sprio_sage_maker_daily_usage_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(columns: ["timestamp", "sp_covered_cost", "on_demand_cost"]) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
      ... on DeferredQueryResult {
        __typename
      }
    }
  }
`);

export const useGraphData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['SageMakerSPRIODaily'],
    queryFn: () => fetchCloudOptimization(SPRIOOptimizeGraphQuery, { accountId }),
    enabled: !!accountId,
    refetchInterval: ({ state: { data } }) => {
      return data?.dailyUsageQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: ({ summaryQuery, dailyUsageQuery }) => {
      if (summaryQuery?.__typename === 'MonitoringResult' && dailyUsageQuery?.__typename === 'DataTableResult') {
        const { resource_cost_per_hour } = getTableData(
          summaryQuery?.items?.[0]?.results as MonitoringValueCheckResult
        )?.[0] as Record<SummaryDataColumns, number>;
        const resourceCostPerDay = resource_cost_per_hour * 24;

        const daily = getTableData(dailyUsageQuery.table as TableType) as Array<Record<DailyDataColumns, number>>;

        const computedDays: GraphData = daily?.map(({ timestamp, sp_covered_cost, on_demand_cost }, index) => ({
          date: format(new Date(timestamp), DATE_FORMAT),
          totalUsage: on_demand_cost + sp_covered_cost,
          spCoveredUsage: sp_covered_cost,
          onDemandUsage: on_demand_cost,
          averageUsage: resourceCostPerDay,
          spPurchaseRecommendation: null,
        }));

        const startDate = subDays(startOfDay(new Date()), 2);
        const previousDays: GraphData = generatePreviousDaysData<GraphDataItem>(
          computedDays,
          startDate,
          COMPUTED_PERIOD,
          {
            totalUsage: 0,
            spCoveredUsage: 0,
            onDemandUsage: 0,
            averageUsage: 0,
            spPurchaseRecommendation: null,
          }
        ).map((day, index, array) => ({
          ...day,
          spPurchaseRecommendation: index === array.length - 1 ? resourceCostPerDay : null,
        }));

        const futureDays: GraphData = Array.from({ length: FUTURE_PERIOD }, (_, i) => ({
          date: format(addDays(startOfDay(new Date(previousDays[previousDays.length - 1].date)), i + 1), DATE_FORMAT),
          totalUsage: null,
          spCoveredUsage: null,
          onDemandUsage: null,
          averageUsage: null,
          spPurchaseRecommendation: resourceCostPerDay,
        }));

        return sortBy([previousDays, futureDays].flat(), ({ date }) => new Date(date));
      }

      return null;
    },
  });
};
