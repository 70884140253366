import { Divider as MuiDivider } from '@mui/material';
import { Text } from '@verticeone/design-system/src';

type DividerProps = {
  text: string;
};

export const Divider = ({ text }: DividerProps) => {
  return (
    <MuiDivider>
      <Text variant="label" size="XS" textTransform="uppercase" color="text2">
        {text}
      </Text>
    </MuiDivider>
  );
};
