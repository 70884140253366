import { useTranslation } from 'react-i18next';
import { Box, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { Loader } from '@vertice/components';
import { useGetSsoDetailsQuery } from '@vertice/slices';
import { IntegrationSSOIcon } from '@vertice/assets';
import { ArrowForwardIos } from '@mui/icons-material';
import { IntegrationsCardContent } from '../../components/IntegrationsCardContent';
import { StatusBadge, StatusBadgeVariant } from '../../components/StatusBadge';
import { Button } from '@verticeone/design-system/src';
import styles from '../../common.module.scss';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export const SSOSetupCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const { data: ssoDetails, isLoading: isSsoDetailsLoading } = useGetSsoDetailsQuery(
    { accountId: accountId! },
    { skip: !accountId }
  );

  const getBadgeStatus = (ssoStatus?: string) => {
    switch (ssoStatus) {
      case 'INACTIVE':
        return StatusBadgeVariant.INACTIVE;
      case 'PROVISIONING':
      case 'WAITING_FOR_MANIFEST':
      case 'WAITING_FOR_DOMAINS':
        return StatusBadgeVariant.PROGRESS;
      case 'ACTIVE':
        return StatusBadgeVariant.ACTIVE;
      case 'ERROR':
        return StatusBadgeVariant.ERROR;
      default:
        return undefined;
    }
  };

  const navigateToSamlSetup = (ssoStatus?: string) => {
    switch (ssoStatus) {
      case 'INACTIVE':
      case 'PROVISIONING':
        navigate(ROUTES.SAML);
        break;
      case 'WAITING_FOR_MANIFEST':
      case 'WAITING_FOR_DOMAINS':
      case 'ERROR':
        navigate(`${ROUTES.SAML}/${ROUTES.SAML_UPLOAD_CONFIG}`);
        break;
      case 'ACTIVE':
        navigate(`${ROUTES.SAML}/${ROUTES.SAML_RESTART}`);
        break;
      default:
    }
  };

  const getMainButtonLabel = (ssoStatus?: string) => {
    switch (ssoStatus) {
      case 'INACTIVE':
        return t('PREFERENCES.INTEGRATIONS.RUN_SETUP');
      case 'PROVISIONING':
      case 'WAITING_FOR_MANIFEST':
      case 'WAITING_FOR_DOMAINS':
        return t('PREFERENCES.INTEGRATIONS.CONTINUE_SETUP');
      case 'ACTIVE':
      case 'ERROR':
        return t('PREFERENCES.INTEGRATIONS.EDIT_CONFIG');
      default:
        return undefined;
    }
  };

  const ssoStatus = ssoDetails?.status;
  const badgeStatus = getBadgeStatus(ssoStatus);
  const mainButtonLabel = getMainButtonLabel(ssoStatus);

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        {isSsoDetailsLoading ? (
          <Box sx={{ height: '244px' }}>
            <Loader />
          </Box>
        ) : (
          <>
            <CardHeader avatar={<IntegrationSSOIcon />} />
            <CardContent classes={{ root: styles['card-content'] }}>
              <IntegrationsCardContent
                title={t('PREFERENCES.INTEGRATIONS.SSO.TITLE')}
                statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
                description={t('PREFERENCES.INTEGRATIONS.SSO.DESCRIPTION')}
              />
            </CardContent>
            <CardActions className={styles['card-actions']}>
              <Button
                onClick={() => navigateToSamlSetup(ssoDetails?.status)}
                variant="outline"
                color="neutral"
                size="S"
              >
                {mainButtonLabel}
                <ArrowForwardIos sx={{ opacity: 0.6 }} />
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </div>
  );
};
