import { styled } from '@mui/material';
import { StatsTile, StyledFieldset } from '@vertice/core/src/components/StatsBar';

export const FullHeightStatsTile = styled(StatsTile)(({ theme }) => ({
  height: '100%',
  padding: 0,
  [StyledFieldset]: {
    height: '100%',
    padding: 0,
  },
}));
