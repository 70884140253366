import { GridComparatorFn, GridSortModel } from '@mui/x-data-grid-pro';
import { gridStringOrNumberComparator } from '@mui/x-data-grid/hooks/features/sorting/gridSortingUtils';
import { Savings } from './SavingsCell';
import { AutoRenewalValue } from './AutoRenewalDateCell';

/**
 * This comparator keeps empty values always at the end no matter the sort direction
 */
export const emptyLastComparator: GridComparatorFn = (v1, v2, params1, params2) => {
  const fieldName = params1.field;
  const sortModel: GridSortModel = params1.api.state.sorting.sortModel;
  const isFilterAsc = sortModel.find((sortModelItem) => sortModelItem.field === fieldName)?.sort === 'asc';

  if (!v1) {
    return isFilterAsc ? 1 : -1;
  }

  if (!v2) {
    return isFilterAsc ? -1 : 1;
  }

  return gridStringOrNumberComparator(v1, v2, params1, params2);
};

export const savingsComparator: GridComparatorFn = (a, b) => {
  const savings = (value: Savings | null): number =>
    value ? value.annualTotalSavingsExchanged || value.annualTotalSavings || 0 : 0;
  return savings(a) - savings(b);
};

export const autoRenewalDateComparator: GridComparatorFn = (a, b, param1, param2) => {
  const getDate = (value: AutoRenewalValue | null): string | undefined => value?.date;
  return emptyLastComparator(getDate(a), getDate(b), param1, param2);
};
