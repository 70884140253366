import { ConcessionItem } from './types';

type UseConcessionsProps = {
  selectedConcessions?: ConcessionItem[];
  setSelectedConcessions: (newConcessions: ConcessionItem[]) => void;
};

const useConcessions = ({ selectedConcessions, setSelectedConcessions }: UseConcessionsProps) => {
  const updateConcession = (updatedConcession: ConcessionItem) => {
    if (!selectedConcessions) {
      return;
    }

    setSelectedConcessions(
      selectedConcessions?.reduce((acc: ConcessionItem[], item: ConcessionItem) => {
        if (item.id === updatedConcession.id) {
          acc.push({
            ...item,
            ...updatedConcession,
          });
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };

  const addConcession = (newConcession: ConcessionItem) => {
    if (!selectedConcessions) {
      return;
    }

    setSelectedConcessions([...selectedConcessions, newConcession]);
  };

  const removeConcession = (id: string) => {
    if (!selectedConcessions) {
      return;
    }

    setSelectedConcessions(
      selectedConcessions?.reduce((acc: ConcessionItem[], item) => {
        if (item.id !== id) {
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };

  return {
    addConcession,
    updateConcession,
    removeConcession,
  };
};

export default useConcessions;
