import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system/src';
import {
  RiAction,
  useRiPurchasePermissionQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getAccount } from '@vertice/slices/src/slices/account';
import { Tooltip } from '@verticeone/design-system/src';

import { useInstanceData } from './providers/InstanceProvider';
import { useInstanceList } from './providers/InstanceListProvider';

type PurchaseTooltipProps = {
  show: boolean;
  children: React.ReactNode;
};

const PurchaseTooltip = ({ show, children }: PurchaseTooltipProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });

  if (show) {
    return (
      <Tooltip size="S" content={t('ACTIONS_ROW.PURCHASE_RIS.TOOLTIP')}>
        <span>{children}</span>
      </Tooltip>
    );
  }

  return children;
};

const PurchaseRIButton = () => {
  const { product } = useInstanceList();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });
  const { item, buyDrawer } = useInstanceData();
  const { accountId } = useSelector(getAccount);

  const { data: offeringsPermissionData, isLoading: isLoadingOfferingsPermission } = useRiPurchasePermissionQuery(
    {
      service: product!,
      accountId: accountId!,
      region: item.region,
      action: RiAction.Offerings,
    },
    {
      skip: !accountId || !item.region,
    }
  );

  const permissionQueryData = offeringsPermissionData?.riPurchasePermissionQuery;
  const offeringsButtonEnabled =
    permissionQueryData?.__typename === 'RIPurchasePermissionResult' && permissionQueryData?.allowed;

  return (
    <PurchaseTooltip show={!item.isUnderCommitted}>
      <Button
        testId="purchase-ris"
        variant="ghost"
        size="M"
        color="tertiary"
        onClick={buyDrawer.open}
        isLoading={isLoadingOfferingsPermission}
        disabled={!offeringsButtonEnabled || !item.isUnderCommitted}
      >
        {t('ACTIONS_ROW.PURCHASE_RIS.LABEL')}
      </Button>
    </PurchaseTooltip>
  );
};

export default PurchaseRIButton;
