import { ContractFormData } from '../types';
import { DependencyList, useCallback, useMemo } from 'react';
import atomWithFormValues from '@vertice/core/src/modules/forms/utils/atomWithFormValues';
import { useContractContext } from '../ContractContext';
import { selectAtom } from 'jotai/utils';
import { useAtomValue } from 'jotai';

/**
 * Selects a specific value from the form data. Causes re-render only when the selected value changes.
 * @param selector As default, selector MUST be a pure function (it cannot read values from upper scope).
 * If you need to read values from upper scope, you must specify dependencies.
 *
 * @param deps dependencies that cause re-evaluation of the selector. @default []
 * @example
 * // OK - Preferred
 * const value = useContractFormDataSelector((formData) => formData.parts.contractual.financial.baseCurrency))
 * // Not OK -
 * const [ activeId, setActiveId ] = useState('1234')
 * const value = useContractFormDataSelector((formData) => formData.products[activeId])
 * // OK
 * const [ activeId, setActiveId ] = useState('1234')
 * const value = useContractFormDataSelector((formData) => formData.products[activeId]), [activeId])
 */
const useContractFormDataSelector = <T>(selector: (formData: ContractFormData) => T, deps: DependencyList = []): T => {
  // As default, we don't want to re-evaluate on selector changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSelector = useCallback(selector, deps);
  const { hookForm } = useContractContext('DONT_REQUIRE_FETCHED');
  const formValuesAtom = useMemo(() => atomWithFormValues(hookForm), [hookForm]);
  const selectedValueAtom = useMemo(
    () => selectAtom(formValuesAtom, memoizedSelector),
    [formValuesAtom, memoizedSelector]
  );
  return useAtomValue(selectedValueAtom);
};

export default useContractFormDataSelector;
