import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './components/Layout';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import { productKeyToTitleMap } from './utils';
import { toConstantCase } from '@verticeone/utils/strings';

type TABS = 'optimize' | 'inventory';

type RIOSavingsPlansProps = {
  activeTab: TABS;
};

const RIOSavingsPlans = ({ activeTab }: RIOSavingsPlansProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { product } = useParams<{ product: keyof typeof productKeyToTitleMap }>();

  useEffect(() => {
    if (!activeTab) {
      navigate(routes.CLOUD.RIO.SP.INVENTORY, {}, { replace: true });
    }
  }, [activeTab, routes, navigate]);

  const showProductBreadcrumb = ['optimize', 'inventory'].includes(activeTab) && product;

  return (
    <Layout
      getCanBeViewed={({ rio, sprio, sprioEC2SP, sprioComputeSP, sprioSagemakerSP }) =>
        rio && sprio && (sprioEC2SP || sprioComputeSP || sprioSagemakerSP)
      }
      breadcrumbs={[
        {
          label: t('NAVBAR.RIO_NEW.MAIN'),
          to: ROUTES.CLOUD_RIO,
        },
        {
          label: t('NAVBAR.RIO_NEW.SAVINGS_PLANS'),
          to: generatePath(ROUTES.CLOUD_RIO_SAVINGS_PLANS, { activeTab: 'optimize' }),
        },
        {
          to: showProductBreadcrumb
            ? generatePath(ROUTES.CLOUD_RIO_SAVINGS_PLANS, { activeTab: activeTab })
            : undefined,
          label: t(`RIO.${toConstantCase(activeTab)}_TAB`),
        },
        ...(showProductBreadcrumb ? [{ label: productKeyToTitleMap[product] ?? product }] : []),
      ]}
      activeTab={activeTab}
      tabs={[
        {
          value: 'optimize',
          label: t('RIO.OPTIMIZE_TAB'),
          onClick: () => navigate(routes.CLOUD.RIO.SP.OPTIMIZE),
        },
        {
          value: 'inventory',
          label: t('RIO.INVENTORY_TAB'),
          onClick: () => navigate(routes.CLOUD.RIO.SP.INVENTORY),
        },
      ]}
      title={t('RIO_NEW.SAVINGS_PLANS.TITLE')}
    >
      <Outlet />
    </Layout>
  );
};
export default RIOSavingsPlans;
