import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, accountAPICodegen } from '../openapi/codegen/accountAPI';
import { accountAdminAPICodegen } from '../openapi/codegen/accountAdminAPI';

const initialState: Account = {
  accountId: '',
  name: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Account>) => {
      Object.assign(state, action.payload);
    },
    unsetAccount: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(accountAPICodegen.endpoints.getAccounts.matchFulfilled, (state, { payload }) => {
      if (state.accountId) {
        const selected = payload.find(({ accountId }) => state.accountId === accountId);
        Object.assign(state, selected);
      }
    });
    builder.addMatcher(accountAdminAPICodegen.endpoints.adminListAccounts.matchFulfilled, (state, { payload }) => {
      if (state.accountId) {
        const selected = payload.find(({ accountId }) => state.accountId === accountId);
        Object.assign(state, selected);
      }
    });
  },
});

/** @deprecated use useAccountContext from @vertice/core/src/contexts/AccountContext */
export const getAccount = ({ account }: { account: Account }) => account;
