import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { keyBy } from 'lodash';
import { ContractFormData } from '../../types';
import useRollingFrequencyOptions from '../../hooks/useRollingFrequencyOptions';

type RollFrequencyFormEntryProps = Omit<ContractFormEntryProps<any, any>, 'component' | 'writeComponent'>;

type RollFrequencySelectOption = { value: string; label: string };

const RollFrequencyFormEntry = (options: RollFrequencyFormEntryProps) => {
  const rollFrequencyOptions = useRollingFrequencyOptions();
  const rollFrequencyMap = useMemo(() => keyBy(rollFrequencyOptions, 'value'), [rollFrequencyOptions]);

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, RollFrequencySelectOption, false>
    >
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? rollFrequencyMap[value]?.label : undefined),
        ...options.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: rollFrequencyOptions,
        boxValue: (value) => (value ? rollFrequencyMap[value as string] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
    />
  );
};

export default RollFrequencyFormEntry;
