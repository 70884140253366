import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import DepartmentFormEntry from '@vertice/core/src/modules/saas/contract/components/form/DepartmentFormEntry';
import { ContractCategoryFormEntry } from '@vertice/core/src/modules/saas/contract/components/form/ContractCategoryFormEntry';

const PurchaseClassificationSection = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={6} px={6}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <DepartmentFormEntry
            name="classification.departmentId"
            label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractCategoryFormEntry
            name="classification.category"
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CATEGORY')}
            width={12}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default PurchaseClassificationSection;
