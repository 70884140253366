import React from 'react';
import {
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { Box, Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import SubmitButton from '../../components/PurchaseRenewalHeaderActions/SubmitButton';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useTranslation } from 'react-i18next';
import { useUserContractPermissions } from '@vertice/core/src/modules/saas/contract/hooks/useUserContractPermissions';
import useFormUpdateActions from '@vertice/core/src/modules/saas/contract/hooks/useFormUpdateActions';
import ContractSummaryCardHeaderMenu from './ContractSummaryCardHeaderMenu';

const ContractSummaryCardHeader = () => {
  const { t } = useTranslation();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const {
    fetchedContract: { contract },
  } = contractContext;

  const { userCanEditContract } = useUserContractPermissions(contract.record.contractId);
  const { handleCancel } = useFormUpdateActions(contractContext);

  const workflowStage = contract.materialized?.simplifiedStage;

  return (
    <>
      <CardHeader size="S">
        <CardHeaderTitle
          text={
            <Stack direction="row" alignItems="baseline" gap={4}>
              <Text variant="heading" size="S" color="text1">
                {t('SAAS.CONTRACT_SUMMARY_CARD.TITLE')}
              </Text>
              {workflowStage && (
                <Text variant="body-regular" size="M" color="text3">
                  {t(`ENTITIES.CONTRACT.STAGES_ON_DETAIL.${workflowStage}`)}
                </Text>
              )}
            </Stack>
          }
        />
        <CardHeaderDescription>
          <Box pr="13em" /* <- Extra padding to not interfere with the action buttons */>
            {t('SAAS.CONTRACT_SUMMARY_CARD.DESCRIPTION')}
          </Box>
        </CardHeaderDescription>

        {userCanEditContract && (
          <CardHeaderActions spacing={2}>
            {contractContext.editMode.isEditing ? (
              <>
                <Button variant="outline" color="neutral" size="M" onClick={handleCancel}>
                  {t('ENTITIES.CONTRACT.ACTIONS.CANCEL')}
                </Button>
                <SubmitButton />
              </>
            ) : (
              <>
                <ContractSummaryCardHeaderMenu />
                <Button variant="outline" color="neutral" size="M" onClick={contractContext.editMode.enable}>
                  {t('ENTITIES.CONTRACT.ACTIONS.EDIT')}
                </Button>
              </>
            )}
          </CardHeaderActions>
        )}
      </CardHeader>
    </>
  );
};

export default ContractSummaryCardHeader;
