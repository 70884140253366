import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SpendByAccountGraph from './SpendByAccountGraph';
import useSpendByAccountData from './useSpendByAccountData';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import { CalendarTodayOutlined } from '@mui/icons-material';

const SpendByAccountCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useSpendByAccountData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('SPEND_BY_ACCOUNT.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
        <CardHeaderDescription>{t('SPEND_BY_ACCOUNT.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus} pt="22px" pb={6}>
          <SpendByAccountGraph data={data!} />
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default SpendByAccountCard;
