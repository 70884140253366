import React, { forwardRef } from 'react';
import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';
import type { EditorState } from 'lexical';
import { TRANSFORMERS, $convertToMarkdownString, $convertFromMarkdownString } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import TextFieldAreaWrapper from '../TextFieldArea/components/TextFieldAreaWrapper';
import { getTextFieldAreaStyles } from '../TextFieldArea/utils';
import type { TextFieldAreaProps } from '../TextFieldArea/components/constants';

import editorTheme, { getStyles } from './theme';
import Toolbar, { ToolbarContextProvider } from './Toolbar';
import Placeholder from './Placeholder';

export type EditorProps = {
  onChange: (value: string) => void;
  placeholder?: string;
  customToolbar?: React.ReactNode;
} & Omit<TextFieldAreaProps, 'onChange'>;

type EditorConfig = {
  editable: boolean;
  defaultValue?: string;
};

const getEditorConfig = ({ editable, defaultValue }: EditorConfig) => ({
  editable,
  namespace: 'VerticeEditor',
  theme: editorTheme,
  onError(error: any) {
    console.error(error);
  },
  editorState: () => {
    $convertFromMarkdownString(defaultValue ?? '', TRANSFORMERS);
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
});

const StyledContentEditable = styled(ContentEditable)(({ theme }) => ({
  position: 'relative',
  overflow: 'auto',
  tabSize: 1,
  resize: 'none',
  ...getTextFieldAreaStyles({ theme, $size: 'M' }),
  '& p': {
    margin: 0,
  },
}));

const StyledEditorWrapper = styled(Stack)<Pick<EditorProps, 'size'>>((props) => getStyles(props));

const DefaultToolbar = () => (
  <Toolbar.Wrapper>
    <Toolbar.Row>
      <Toolbar.Formatters.Text types={['bold', 'italic', 'underline']} />
    </Toolbar.Row>
    <Toolbar.Divider />
    <Toolbar.Row>
      <Toolbar.Formatters.Paragraph types={['ul', 'ol']} />
    </Toolbar.Row>
  </Toolbar.Wrapper>
);

export const Editor = forwardRef<HTMLDivElement, EditorProps>(({ size = 'M', customToolbar, ...otherProps }, ref) => {
  const isTextOverflow = otherProps.value && otherProps.textLimit ? otherProps.value.length > otherProps.textLimit : false; //prettier-ignore
  const initialConfig = getEditorConfig({ editable: !otherProps.disabled, defaultValue: otherProps.value });
  const onChange = (editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      otherProps.onChange?.(markdown);
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Stack direction="column" gap={2.5}>
        <ToolbarContextProvider size={size} variant={otherProps.variant} disabled={!!otherProps.disabled}>
          {customToolbar ?? <DefaultToolbar />}
        </ToolbarContextProvider>
        <StyledEditorWrapper ref={ref} position="relative" size={size}>
          <RichTextPlugin
            contentEditable={
              <TextFieldAreaWrapper
                size={size}
                value={otherProps.value ?? ''}
                label={otherProps.label}
                variant={otherProps.variant}
                color={otherProps.color}
                hasError={otherProps.hasError || isTextOverflow}
                textLimit={otherProps.textLimit}
              >
                <StyledContentEditable id={otherProps.id} className="textarea" />
              </TextFieldAreaWrapper>
            }
            placeholder={<Placeholder size={size} variant={otherProps.variant} children={otherProps.placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </StyledEditorWrapper>
      </Stack>
      <HistoryPlugin />
      <ListPlugin />
      <OnChangePlugin ignoreSelectionChange onChange={onChange} />
    </LexicalComposer>
  );
});
