import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoggedUser } from '@verticeone/auth/src';

interface MeFlagProps {
  userId: string;
  customLabel?: string;
}

/**
 * Returns me flag if provided userId is equal to userId of currently logged user
 */
const MeFlag: React.FC<MeFlagProps> = ({ userId, customLabel }) => {
  const { t } = useTranslation();
  const { userId: loggedUserId } = useLoggedUser();

  if (loggedUserId !== userId) {
    return null;
  }

  return (
    <Typography variant="body-regular-m" color="var(--tokens-color-light-text-3)">
      &nbsp;({customLabel || t('COMMON.ME')})
    </Typography>
  );
};

export default MeFlag;
