import React, { createContext, useCallback, useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Tabs } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';

export type NavigationTab = 'RECOMMENDED' | 'CUSTOMIZE';

type BuyInstanceDrawerTabsProps = {
  children: React.ReactNode;
};

type TabsContextProps = {
  getIsVisible: (tabs: NavigationTab) => boolean;
};

const TabsContext = createContext<TabsContextProps>({} as TabsContextProps);

const BuyInstanceDrawerTabs = ({ children }: BuyInstanceDrawerTabsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.PURCHASE_TAB' });
  const [navigationTab, setNavigationTab] = useState<NavigationTab>('RECOMMENDED');
  const getIsVisible = useCallback((tab: NavigationTab) => tab === navigationTab, [navigationTab]);

  return (
    <Stack gap={6} py={8} px={8}>
      <TabsContext.Provider value={{ getIsVisible }}>
        <Tabs
          variant="outlined"
          color="tertiary"
          value={navigationTab}
          onChange={(_, value: NavigationTab) => setNavigationTab(value)}
        >
          <Tab value="RECOMMENDED" label={t('RECOMMENDED')} />
          <Tab value="CUSTOMIZE" label={t('CUSTOMIZE_PURCHASE')} />
        </Tabs>
        {children}
      </TabsContext.Provider>
    </Stack>
  );
};

export const useBuyInstanceDrawerTabs = () => useContext(TabsContext);

export default BuyInstanceDrawerTabs;
