import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

type StatBarProps = {
  children: ReactNode;
  borderRadius?: number;
  borderRadiusTop?: number;
  borderRadiusLeft?: number;
  borderRadiusRight?: number;
  borderRadiusBottom?: number;
};

const BASIC_BORDER_RADIUS = 4;

export const StatBar = ({
  children,
  borderRadius,
  borderRadiusRight,
  borderRadiusLeft,
  borderRadiusTop,
  borderRadiusBottom,
}: StatBarProps) => {
  const theme = useTheme();

  const br = [
    theme.spacing(borderRadiusTop ?? borderRadiusLeft ?? borderRadius ?? BASIC_BORDER_RADIUS),
    theme.spacing(borderRadiusTop ?? borderRadiusRight ?? borderRadius ?? BASIC_BORDER_RADIUS),
    theme.spacing(borderRadiusBottom ?? borderRadiusRight ?? borderRadius ?? BASIC_BORDER_RADIUS),
    theme.spacing(borderRadiusBottom ?? borderRadiusLeft ?? borderRadius ?? BASIC_BORDER_RADIUS),
  ].join(' ');

  return (
    <Stack direction="row" borderRadius={br} overflow="hidden" border={`1px solid ${theme.palette.core.color3}`}>
      {children}
    </Stack>
  );
};
