import React from 'react';
import useOptimizationTestsData from './OptimizationTestsTable/useOptimizationTestsData';

type LoadableOptimizationProps = {
  children: React.ReactNode;
};

const LoadableOptimization = ({ children }: LoadableOptimizationProps) => {
  const { isFetching } = useOptimizationTestsData();

  if (!isFetching) {
    return <>{children}</>;
  }
};

export default LoadableOptimization;
