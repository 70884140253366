/** @deprecated pls prefer to add new routes into our new better-typed routes.ts files */
export const ROUTES = {
  ROOT: '/',
  CLOUD_OVERVIEW: '/cloud/overview',
  CLOUD_ANALYTICS: '/cloud/analytics',
  CLOUD_ANALYTICS_TAB: '/cloud/analytics/:activeTab',
  CLOUD_INSIGHTS_BASE: '/cloud/insights',
  CLOUD_INSIGHTS_TAB: '/cloud/insights/:activeTab',
  CLOUD_EDP: '/cloud/edp',
  CLOUD_OPTIMIZATION: '/cloud/optimization',
  CLOUD_OPTIMIZATION_RECOMMENDATION: '/cloud/optimization/recommendation',
  CLOUD_OPTIMIZATION_RECOMMENDATION_CODE: '/cloud/optimization/recommendation/:code',
  CLOUD_RIO: '/cloud/rio',
  CLOUD_RIO_TAB: '/cloud/rio/:activeTab',
  CLOUD_RIO_TAB_DETAIL: '/cloud/rio/:activeTab/:product',
  CLOUD_RIO_SAVINGS_PLANS: '/cloud/rio/sp/:activeTab',
  CLOUD_RIO_SAVINGS_PLANS_DETAIL: '/cloud/rio/sp/:activeTab/:product',
  CLOUD_RIO_RESERVED_INSTANCES: '/cloud/rio/ri/:activeTab',
  CLOUD_RIO_RESERVED_INSTANCES_DETAIL: '/cloud/rio/ri/:activeTab/:product',
  CLOUD_RIO_TRANSACTIONS: '/cloud/rio/transactions',
  APPLICATIONS: '/applications',
  USAGE_DETAIL: '/applications/:applicationId/usage-detail',
  VENDORS: '/vendors',
  VENDOR: '/vendors/:id',
  SIGN_IN: 'sign-in',
  '404': '404',
  USER_PROFILE: '/user-profile',
  INTAKE_EXISTING_CONTRACT: '/create-existing-contract',
  PREFERENCES: '/preferences',
  SAML: '/saml',
  SAML_CREATE_APP: 'create-app',
  SAML_UPLOAD_CONFIG: 'upload-config',
  SAML_SSO_DOMAIN: 'register-sso-domain',
  SAML_TEAM_MEMBERS: 'team-members',
  SAML_RESTART: 'restart',
  SCIM_ENTRA: '/scim-entra',
  SCIM_OKTA: '/scim-okta',
  INTEGRATIONS: 'integrations',
  AWS: 'aws',
  KNOWLEDGE_BASE: '/knowledge-base',
  LOGIN: '/login',
  SSO_LOGIN: '/single-sign-on',
  SIGN_UP: '/sign-up',
  ERROR: '/error',
  RESET_PASSWORD: '/reset-password',
  SUBMIT_RESET_PASSWORD: '/submit-reset-password',
  FORCE_NEW_PASSWORD: '/force-new-password',
  ZENDESK_SSO_LOGIN: '/zendesk-sso-login',
  ZENDESK_SSO_LOGOUT: '/zendesk-sso-logout',
} as const;

export const INTEGRATION_ROUTES = {
  BASE_PATH: 'integrations',
  ACCOUNTING: 'accounting/:providerId',
  OKTA_SETUP: 'usage/okta',
  ENTRA_SETUP: 'usage/entra',
  ONELOGIN_SETUP: 'usage/onelogin',
  JUMPCLOUD_SETUP: 'usage/jumpcloud',
  GOOGLE_SETUP: 'usage/google',
  SLACK_SETUP: 'slack',
};

export const PREFERENCES_PAGES = {
  COMPANY: 'company',
  DIRECTORY: 'directory',
  CUSTOMIZATION: 'customization',
  INTEGRATIONS: INTEGRATION_ROUTES.BASE_PATH,
  WORKFLOWS: 'workflows',
  USER: 'user',
  USER_SLACK_WIZARD: 'user/slack',
};
