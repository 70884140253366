import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import Highcharts, { OptionsStackingValue } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { SeriesOptionsWithStates } from '@vertice/core/src/components/charts/highcharts-specific/types';
import {
  AWS_DEFAULT_CURRENCY,
  AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
} from '@vertice/dashboard/src/modules/cloud/constants';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import { Text } from '@verticeone/design-system/src';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import { useCloudOptimization } from '../../CloudOptimizationContext';
import { testProps } from '@verticeone/design-system/src';
import useOptimizations from '../../dataSource/useOptimizations';

const useOptimizationSeries = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_OPTIMIZATION' });
  const { isAnnualPeriod } = useCloudOptimization();
  const multiplier = isAnnualPeriod ? 12 : 1;

  const { data, isFetching } = useOptimizations({
    select: (result) => {
      if (result?.optimizationsQuery?.__typename === 'OptimizationsResult') {
        return {
          low: result.optimizationsQuery.summary.estimatedSavings.find((item) => item?.category === 'LOW_EFFORT'),
          medium: result.optimizationsQuery.summary.estimatedSavings.find((item) => item?.category === 'MEDIUM_EFFORT'),
          high: result.optimizationsQuery.summary.estimatedSavings.find((item) => item?.category === 'HIGH_EFFORT'),
          wontDo: result.optimizationsQuery.summary.estimatedSavings.find((item) => item?.category === 'CANCELLED'),
        };
      }
    },
  });

  return useMemo(
    () => ({
      isFetching,
      series: [
        {
          id: 'LOW_EFFORT',
          type: 'bar' as const,
          data: [(data?.low?.saving ?? 0) * multiplier],
          color: palette.visualization.monochromatic.tertiary['60'],
          name: t('LOW_EFFORT'),
          count: data?.low?.count ?? 0,
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.tertiary['60'],
            },
          },
        },
        {
          id: 'MEDIUM_EFFORT',
          type: 'bar' as const,
          data: [(data?.medium?.saving ?? 0) * multiplier],
          color: palette.visualization.monochromatic.tertiary['40'],
          name: t('MEDIUM_EFFORT'),
          count: data?.medium?.count ?? 0,
          states: {
            hover: {
              color: palette.visualization.monochromatic.tertiary['40'],
            },
          },
        },
        {
          id: 'HIGH_EFFORT',
          type: 'bar' as const,
          data: [(data?.high?.saving ?? 0) * multiplier],
          color: palette.visualization.monochromatic.tertiary['20'],
          name: t('HIGH_EFFORT'),
          count: data?.high?.count ?? 0,
          states: {
            hover: {
              color: palette.visualization.monochromatic.tertiary['20'],
            },
          },
        },
        {
          id: 'WONT_DO',
          type: 'bar' as const,
          data: [(data?.wontDo?.saving ?? 0) * multiplier],
          color: getColorPattern(palette.text.color3),
          name: t('WONT_DO'),
          count: data?.wontDo?.count ?? 0,
          states: {
            hover: {
              color: getColorPattern(palette.text.color3),
            },
          },
        },
      ],
    }),
    [data, isFetching, multiplier, palette, t]
  );
};

type TooltipProps = {
  seriesColor?: string;
  seriesName: string;
  value: string;
  count: string;
};

const Tooltip = ({ seriesColor, seriesName, value, count }: TooltipProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_OPTIMIZATION' });

  return (
    <Stack direction="row" gap={2} alignItems="flex-start" width={250}>
      {seriesColor && (
        <Stack mt={1}>
          <ColorSquare color={seriesColor} borderColor={seriesColor} />
        </Stack>
      )}
      <Stack direction="column" gap={1} width="100%">
        <Text variant="body-regular" size="M" color="text5">
          {seriesName}
        </Text>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="body-regular" size="S" color="text5">
            {t('POTENTIAL_SAVINGS')}
          </Text>
          <Text variant="body-regular" size="S" color="text5" sx={{ opacity: 0.6 }}>
            {value}
          </Text>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="body-regular" size="S" color="text5">
            {t('RECOMMENDATION_COUNT')}
          </Text>
          <Text variant="body-regular" size="S" color="text5" sx={{ opacity: 0.6 }}>
            {count}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

const BreakdownChart = ({ series }: Pick<ReturnType<typeof useOptimizationSeries>, 'series'>) => {
  const { locale } = useLocaleContext();
  const [chart, setChartRef] = useChartRef();
  const { palette } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset();
  const tooltipFormatter = useMemo(
    () => currencyFormatter(locale, AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES),
    [locale]
  );

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: {
            type: 'bar',
            height: 54,
            spacing: [0, 0, 0, 0],
            marginLeft: 25,
            marginRight: 15,
            backgroundColor: palette.core.bg,
            plotBackgroundColor: palette.core.bg,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
            series: {
              stacking: 'normal' as OptionsStackingValue,
              dataLabels: {
                enabled: false,
              },
              borderWidth: 2,
              borderColor: palette.core.bg,
            },
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            visible: false,
            tickInterval: 0.1, // to avoid resizing when the period changes
          },
          series: series.toReversed(),
          tooltip: {
            outside: true,
            enabled: true,
            animation: false,
            positioner: function (w, h, point) {
              const getNewWidth = (width: number) => {
                const pointCenter = this.chart.plotLeft + point.plotX - (point as any).h / 2;
                const xCenter = pointCenter - width / 2;

                return Math.max(Math.min(xCenter, this.chart.chartWidth - width), 0);
              };

              return {
                x: getNewWidth(w),
                y: this.chart.plotTop - h,
              };
            },
          },
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, series]
  );

  const legendItems = series.map((item) => ({
    color: item.states.hover.color,
    label: item.name,
    value: item.data[0],
    id: item.name,
  }));

  return (
    <Stack pb={4} bgcolor={palette.core.bg} {...testProps('chart')}>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ point }) => (
          <TooltipWrapper>
            <Tooltip
              seriesName={point.series.name}
              seriesColor={(point.series.options as SeriesOptionsWithStates)?.states?.hover?.color}
              value={tooltipFormatter(point.y)}
              count={(point.series.userOptions as any).count}
              key={point.series.name}
            />
          </TooltipWrapper>
        )}
      </HighchartTooltip>
      {legendItems.length > 0 && (
        <Box alignSelf="flex-start" px={6}>
          <Stack direction="row" bgcolor={palette.core.color1} py={1} px={2} borderRadius={2} gap={3.5}>
            {legendItems.map((item) => (
              <Stack direction="row" alignItems="center" gap={2} key={item.id}>
                <ColorSquare color={item.color} />
                <Stack direction="row" gap={1}>
                  <Text variant="body-regular" size="S" color="text1">
                    {item.label}
                  </Text>
                  <Text variant="body-regular" size="S" color="text1" sx={{ opacity: 0.4 }}>
                    {tooltipFormatter(item.value)}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

const BreakdownChartContainer = () => {
  const { series, isFetching } = useOptimizationSeries();

  if (!isFetching) {
    return <BreakdownChart series={series} />;
  }
};

export default BreakdownChartContainer;
