import React from 'react';
import { useController, FieldValues } from 'react-hook-form';
import { CommonFormFieldProps } from '../../types';
import { ConcessionsList } from '@vertice/core/src/modules/saas/contract/components/ConcessionsList';
import { ConcessionItem } from '@vertice/core/src/modules/saas/contract/components/ConcessionsList/types';
import { DesignSystemColor } from '@verticeone/design-system/src';

type FormConcessionsFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  currency?: string;
  editable?: boolean;
  color?: DesignSystemColor;
};

export type FormConcessionsFieldComponentType<FormDataType extends FieldValues> = (
  props: FormConcessionsFieldProps<FormDataType>
) => JSX.Element;

const FormConcessionsField = <FormDataType extends FieldValues = never>({
  name,
  required,
  editable,
  currency,
  color,
}: FormConcessionsFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
    },
  });

  return (
    <ConcessionsList
      selectedConcessions={(value as ConcessionItem[]) || []}
      setSelectedConcessions={(newValue) => {
        field.onChange(newValue);
      }}
      currency={currency}
      withActions={editable}
      readOnlyMode={!editable}
      color={color}
    />
  );
};

export default FormConcessionsField;
