import React, { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import Highcharts, { OptionsStackingValue, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { range, sumBy } from 'lodash';
import { ScoreBarChartItem } from './ScoreBar';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';

type ScoreBarChartProps = {
  values: ScoreBarChartItem[];
};

const ScoreBarChart = ({ values }: ScoreBarChartProps) => {
  const { palette } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();

  const chartSeries: SeriesOptionsType[] = useMemo(
    () =>
      values.map((item) => ({
        name: item.name,
        data: [item.value],
        color: item.seriesColor,
        type: 'bar',
      })),
    [values]
  );

  const totalValue = useMemo(() => sumBy(values, (item) => item.value), [values]);
  const tickPositions = useMemo(() => {
    let step = 1;
    if (totalValue >= 50) {
      step = Math.ceil(totalValue / 100) * 10;
    } else if (totalValue > 10) {
      step = 5;
    }

    return range(0, totalValue, step).concat(totalValue);
  }, [totalValue]);

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        (opts) =>
          mergeOptions(
            {
              chart: {
                type: 'bar',
                height: 16,
                spacing: [0, 0, 0, 0],
                plotBackgroundColor: palette.core.bg,
                backgroundColor: palette.core.bg,
              },
              plotOptions: {
                series: {
                  animation: false,
                  stacking: 'normal' as OptionsStackingValue,
                  dataLabels: {
                    enabled: false,
                  },
                  enableMouseTracking: false,
                  borderWidth: 0,
                  pointWidth: 16,
                } as Highcharts.PlotSeriesOptions,
              },
              xAxis: {
                labels: {
                  enabled: false,
                },
              },
              yAxis: {
                max: 1,
                tickPositions: tickPositions,
                labels: {
                  enabled: false,
                },
              },
              series: chartSeries.reverse(),
              tooltip: { enabled: false },
            },
            opts
          ),
      ]),
    [applyStyledHighcharts, palette.core.bg, tickPositions, chartSeries]
  );

  return (
    <Stack borderRadius={1} overflow="hidden">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
};

export default ScoreBarChart;
