export const amplifyConfig = {
  aws_cognito_region: window._configEnv.REACT_APP_AWS_COGNITO_REGION,
  // employee pool
  aws_user_pools_id: window._configEnv.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: window._configEnv.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const rumConfigs = {
  guestRoleArn: window._configEnv.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
  identityPoolId: window._configEnv.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
  endpoint: window._configEnv.REACT_APP_AWS_RUM_ENDPOINT ?? 'https://dataplane.rum.eu-west-1.amazonaws.com',
  sessionSampleRate: window._configEnv.REACT_APP_AWS_RUM_SESSION_SAMPLE_RATE
    ? Number(window._configEnv.REACT_APP_AWS_RUM_SESSION_SAMPLE_RATE)
    : 1,
  allowCookies: window._configEnv.REACT_APP_AWS_RUM_ALLOW_COOKIES === 'true',
  enableXRay: window._configEnv.REACT_APP_AWS_RUM_ENABLE_XRAY === 'true',
  telemetries: JSON.parse(window._configEnv.REACT_APP_AWS_RUM_TELEMETRIES ?? '["errors","performance","http"]'),
};

export const rumCredentials = {
  rumApplicationId: window._configEnv.REACT_APP_AWS_RUM_APPLICATION_ID ?? '2319125a-60ac-4dd3-9363-61d95aa19789', // for localhost(shared fro AIT and CFA) use e7807abc-74bb-4ba9-a12e-e3e00a6f087e,
  rumApplicationVersion: window._configEnv.REACT_APP_AWS_RUM_APPLICATION_VERSION ?? '1.0.0',
  rumApplicationRegion: window._configEnv.REACT_APP_AWS_RUM_APPLICATION_REGION ?? 'eu-west-1',
};

export const isDevelopment = window._configEnv.NODE_ENV === 'development';
export const enableRum = window._configEnv.REACT_APP_ENABLE_RUM === 'true';

export const smartlookConfigs = {
  enable: window._configEnv.REACT_APP_SMARTLOOK_ENABLED === 'true',
  projectKey: window._configEnv.REACT_APP_SMARTLOOK_PROJECT_KEY,
};

export const licenses = {
  muiProKey: window._configEnv.REACT_APP_MUI_PRO_LICENSE_KEY,
};
