import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { API_URLS } from '../../constants';
import { getPrepareHeadersFn } from '../../baseQuery';

const { BFFE_USAGE_GRAPHQL_URL } = API_URLS;

export const api = createApi({
  reducerPath: 'usageGraphQL',
  baseQuery: graphqlRequestBaseQuery({
    url: BFFE_USAGE_GRAPHQL_URL!,
    prepareHeaders: getPrepareHeadersFn(),
  }),
  endpoints: () => ({}),
});
