import React from 'react';
import { Grid, Stack } from '@mui/material';
import ContractFormEntry from '@vertice/core/src/modules/saas/contract/ContractFormEntry';
import FormReadOnlyText from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { useTranslation } from 'react-i18next';
import CheckboxContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/CheckboxContractFormEntry';

const RenewalRequestDetailsTab = () => {
  const { t } = useTranslation();

  return (
    <Stack p={6} gap={6}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.signatory.signer.name"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.signatory.signer.email"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.signatory.signingEntity.name"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.vendor.vendorContact.name"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_NAME')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <ContractFormEntry
            name="parts.contractual.vendor.vendorContact.email"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_EMAIL')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="workflow.externalDocuments.requestOwner"
            label={t('ENTITIES.CONTRACT.LABELS.REQUEST_OWNER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <ContractFormEntry
            name="workflow.externalDocuments.requestOwnerEmail"
            label={t('ENTITIES.CONTRACT.LABELS.REQUEST_OWNER_EMAIL')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.legalReviewRequired"
            label={t('ENTITIES.CONTRACT.LABELS.LEGAL_REVIEW_REQUIRED')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.securityReviewRequired"
            label={t('ENTITIES.CONTRACT.LABELS.SECURITY_REVIEW_REQUIRED')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.piiExpected"
            label={t('ENTITIES.CONTRACT.LABELS.PII_EXPECTED')}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.contactVendorDirectly"
            label={t('ENTITIES.CONTRACT.LABELS.CONTACT_VENDOR_DIRECTLY')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.multiYear"
            label={t('ENTITIES.CONTRACT.LABELS.DISCUSS_MULTI_YEAR_DEALS')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.plannedGrowth"
            label={t('ENTITIES.CONTRACT.LABELS.DISCUSS_PLANNED_GROWTH')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.discussCompetitors"
            label={t('ENTITIES.CONTRACT.LABELS.DISCUSS_COMPETITORS')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="workflow.externalDocuments.caseStudies"
            label={t('ENTITIES.CONTRACT.LABELS.DISCUSS_CASE_STUDIES')}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default RenewalRequestDetailsTab;
