import { styled } from '@mui/material';

import { DesignSystemSize } from '../../types';
import { Text } from '../Text';

type SlashDividerProps = {
  size: DesignSystemSize;
};

const StyledSlash = styled(Text)({
  cursor: 'default',
});

export const SlashDivider = ({ size }: SlashDividerProps) => {
  return (
    <StyledSlash variant="body-regular" size={size} color="text4" aria-hidden>
      /
    </StyledSlash>
  );
};
