import React from 'react';
import type { DesignSystemSize, DesignSystemVariant } from '../../../types';

type ToolbarContextProviderProps = {
  children: React.ReactNode;
} & ToolbarContextValue;

export type ToolbarContextValue = {
  disabled: boolean;
  size: DesignSystemSize;
  variant: Exclude<DesignSystemVariant, 'plain'>;
};

const ToolbarContext = React.createContext<ToolbarContextValue>({} as ToolbarContextValue);

export const useToolbar = () => {
  const context = React.useContext(ToolbarContext);

  if (context === undefined) {
    throw new Error('useToolbar must be used within a ToolbarContextProvider');
  }

  return context;
};

const ToolbarContextProvider = ({ children, size, variant, disabled }: ToolbarContextProviderProps) => {
  const defaultValues: ToolbarContextValue = { size, variant, disabled };

  return <ToolbarContext.Provider value={defaultValues} children={children} />;
};

export default ToolbarContextProvider;
