import React from 'react';
import { styled } from '@mui/material';
import { Title } from '../StatsBody/Title';
import { TooltipInfoTrigger } from '@verticeone/design-system/src';
import { rgba } from 'polished';
import { Value } from '../StatsBody/Value';
import { LoadableTextComponent } from '../../LoadableTextComponent';
import { StatsTile, StatsTileProps } from './StatsTile';
import { DesignSystemColor } from '@verticeone/design-system/src';

const StyledGhostStatsTile = styled(StatsTile)<{ $color: DesignSystemColor }>(({ $color, theme }) => ({
  backgroundColor: theme.palette[$color].color4,
  [Title]: {
    color: theme.palette[$color].color1,
  },
  [TooltipInfoTrigger]: {
    color: rgba(theme.palette[$color].color1, 0.4),
  },
  [Value]: {
    color: theme.palette[$color].color1,
  },
  [LoadableTextComponent]: {
    backgroundColor: rgba(theme.palette[$color].color3, 0.3),
  },
}));

type GhostStatsTileProps = StatsTileProps & {
  color?: DesignSystemColor;
};

const GhostStatsTile = ({ color = 'primary', ...props }: GhostStatsTileProps) => (
  <StyledGhostStatsTile $color={color} {...props} />
);
export default GhostStatsTile;
