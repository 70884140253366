import dayjs, { Dayjs } from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
dayjs.extend(dayjsUtcPlugin);

/**
 * Takes a timestamp, converts it to local offset and returns the date part.
 *
 * For example:
 * When running in a timezone with +01:00 offset, for the input
 * 2023-01-01T23:00:00.000Z it returns 2023-01-02
 */
export const keepOnlyLocalDatePart = (date?: Dayjs | string | null | undefined) =>
  date ? dayjs(date).local().format('YYYY-MM-DD') : date;
