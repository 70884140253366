import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  ForecastPrecisionType,
  useForecastCostQuery,
  useUsageCostPerRecordTypeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { addMonths, startOfMonth } from 'date-fns';
import { useDeferredQuery } from '@verticeone/utils/api';
import { findFirstCurrency, isoDateToTimestamp, sumPositiveDataPointValues } from '../../utils/graphDataUtils';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { AWS_DEFAULT_CURRENCY } from '../../constants';

const startDate = addMonths(startOfMonth(new Date()), -3);

const usePastData = (accountId?: string) => {
  const { data: dataPoints, error } = useDeferredQuery(
    useUsageCostPerRecordTypeQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, 'yyyy-MM-dd'),
      precision: ForecastPrecisionType.Day,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  return useMemo(
    () => ({
      data: dataPoints
        ? {
            pastData: dataPoints.map(
              (p) => [isoDateToTimestamp(p.time!), sumPositiveDataPointValues(p)] as [number, number | undefined]
            ),
            currency: findFirstCurrency(dataPoints) ?? AWS_DEFAULT_CURRENCY,
          }
        : undefined,
      error: error,
      isEmpty: Boolean(dataPoints && dataPoints.length === 0),
    }),
    [dataPoints, error]
  );
};

const useForecastData = (accountId?: string) => {
  const { data: dataPoints, error } = useDeferredQuery(
    useForecastCostQuery,
    { accountId: accountId!, precision: ForecastPrecisionType.Day },
    { skip: !accountId, pollingInterval: 5000 },
    ({ forecastQuery }) => forecastQuery
  );

  return useMemo(
    () => ({
      data: dataPoints
        ? {
            forecastMeanValues: dataPoints.map(
              (p) => [isoDateToTimestamp(p.time!), p.values[0]?.mean] as [number, number | undefined]
            ),
            forecastBoundValues: dataPoints.map((p) => {
              const { lowerBound, upperBound } = p.values[0] ?? {};
              return [isoDateToTimestamp(p.time!), lowerBound, upperBound] as [
                number,
                number | undefined,
                number | undefined
              ];
            }),
          }
        : undefined,
      error: error,
      isEmpty: Boolean(dataPoints && dataPoints.length === 0),
    }),
    [dataPoints, error]
  );
};

export type ForecastData = {
  pastData: [number, number | undefined][];
  currency: string;
  forecastMeanValues: [number, number | undefined][];
  forecastBoundValues: [number, number | undefined, number | undefined][];
};

const useForecastGraphData = (): LoadableAdvanced<ForecastData> => {
  const { accountId } = useSelector(getAccount);
  const { data: pastData, error: pastDataError, isEmpty: pastDataEmpty } = usePastData(accountId);
  const { data: forecastData, error: forecastDataError, isEmpty: forecastDataEmpty } = useForecastData(accountId);
  const dataLoaded = pastData && forecastData;

  return {
    error: pastDataError || forecastDataError,
    isEmpty: pastDataEmpty && forecastDataEmpty,
    isLoading: !dataLoaded,
    data: dataLoaded ? { ...pastData!, ...forecastData! } : undefined,
  };
};

export default useForecastGraphData;
