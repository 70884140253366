import { flow } from 'lodash';
import type { PortableRoute } from './PortableRoute';
import type { EnhancedPortableRoute, PrefixEnhance } from './EnhancedPortableRoute';

type ToString<Value extends any> = `${Value extends string ? Value : ''}`;
type JoinStrings<T extends string[]> = T extends [infer First, ...infer Rest]
  ? `${ToString<First>}${Rest extends string[] ? JoinStrings<Rest> : ''}`
  : '';

type PrependSeparator<V extends string, S extends string = '/'> = V extends ''
  ? V
  : V extends `${S}${string}`
  ? V
  : `${S}${V}`;

export type MixedPortableRoute = PortableRoute | EnhancedPortableRoute<PrefixEnhance>;
export type MixedPortableRouteList = Record<string, MixedPortableRoute>;

export type AttachedRoute<
  Route extends MixedPortableRoute = any,
  ParentPath extends string = string,
  CurrentPath extends string | null = Route extends { path: infer InferredPath } ? InferredPath : null,
  Prefix extends string | null = Route extends { enhance: { prefix: infer InferredPrefix } } ? InferredPrefix : null,
  DefaultParams extends Record<string, any> | null = Route extends { enhance: { defaultParams: infer InferredParams } }
    ? InferredParams
    : {} // eslint-disable-line @typescript-eslint/ban-types
> = {
  defaultParams: DefaultParams;
  ABSOLUTE_PATH: ToString<CurrentPath> extends `/${string}`
    ? JoinStrings<[ToString<Prefix>, ToString<ParentPath>, PrependSeparator<ToString<CurrentPath>>]>
    : JoinStrings<[ToString<ParentPath>, PrependSeparator<ToString<CurrentPath>>]>;
  PATH: ToString<CurrentPath> extends `/${string}`
    ? JoinStrings<[ToString<Prefix>, ToString<CurrentPath>]>
    : ToString<CurrentPath>;
} & {
  [Key in keyof Route['children']]: Route['children'][Key] extends PortableRoute
    ? AttachedRoute<
        Route['children'][Key],
        JoinStrings<[ToString<ParentPath>, ToString<Prefix>, PrependSeparator<ToString<CurrentPath>>]>
      >
    : never;
};

export type AttachedRouteList<
  R extends Record<string, EnhancedPortableRoute | PortableRoute> = any,
  P extends string = ''
> = {
  [K in keyof R]: AttachedRoute<R[K], P>;
};

export const createAttachedRoutes = <Routes extends MixedPortableRouteList>(
  routes: Routes
): AttachedRouteList<Routes> => {
  const isEnhancedRoute = (route: MixedPortableRoute): route is EnhancedPortableRoute =>
    !!(route as EnhancedPortableRoute)?.enhance;

  const transformPath = <Route extends MixedPortableRoute>(route: Route, base?: string): AttachedRoute<Route> =>
    ({
      defaultParams: isEnhancedRoute(route) ? route.enhance.defaultParams : {},
      PATH: route.path,
      ABSOLUTE_PATH: [base, route.path].filter((v) => !!v).join(route.path.startsWith('/') ? '' : '/'),
    } as any);

  const applyPrefixEnhance = <Route extends AttachedRoute>(route: Route, enhance: PrefixEnhance): Route => ({
    ...route,
    PATH: route.PATH.startsWith('/') ? [enhance.prefix, route.PATH].join('') : route.PATH,
    ABSOLUTE_PATH: [enhance.prefix, route.ABSOLUTE_PATH].join(''),
  });

  const createAbsoluteRoutes = <Route extends MixedPortableRoute, BasePath extends string = string>(
    route: Route,
    basePath?: BasePath
  ): AttachedRoute<Route, BasePath> => {
    return flow([
      (r: Route) => transformPath(r, basePath),
      (r: AttachedRoute) => {
        if (route.children) {
          const childRoutes = Object.fromEntries(
            Object.entries(route.children).map(([key, childRoute]) => [
              key,
              createAbsoluteRoutes(childRoute, r.ABSOLUTE_PATH),
            ])
          );

          return { ...r, ...childRoutes };
        }

        return r;
      },
      (r: AttachedRoute) =>
        isEnhancedRoute(route) ? applyPrefixEnhance(r, (route as EnhancedPortableRoute<PrefixEnhance>).enhance) : r,
    ])(route);
  };

  return Object.fromEntries(
    Object.entries(routes as Routes).map(([key, route]) => [key, createAbsoluteRoutes(route)])
  ) as AttachedRouteList<Routes>;
};
