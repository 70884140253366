import React from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { ifExpression } from '../../../utils/ifExpression';

import { ToolbarContextValue, useToolbar } from './ToolbarContext';
import { toolbarSizeDict } from './constants';

export type ToolbarProps = {
  children: React.ReactNode;
};

export const StyledToolbarWrapper = styled(Stack)<ToolbarContextValue>(({ theme, size, variant, disabled }) => ({
  flexDirection: 'row',
  gap: `${toolbarSizeDict[size]}`,
  ...ifExpression(variant !== 'ghost', {
    borderRadius: toolbarSizeDict[size],
    border: `1px solid ${theme.palette.core.color3}`,
  }),
  ...ifExpression(variant === 'solid', {
    backgroundColor: disabled ? theme.palette.inactive.color4 : theme.palette.core.bg,
  }),
}));

export const Toolbar = ({ children }: ToolbarProps) => {
  const toolbarProps = useToolbar();

  return <StyledToolbarWrapper {...toolbarProps} children={children} />;
};

export default Toolbar;
