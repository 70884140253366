import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { SeriesColor } from '../../chart-lib-agnostic/types';
import { colorScheme } from '@verticeone/design-system/src';
import { SeriesOptionsWithData } from '../types';

/**
 * Assigns the colors for each data series.
 * @param series Chart data for the series
 * @param usedCategories All positive and negative categories used in the chart
 * @param colors An arrays of colors for positive and negative categories
 */
export const getColoredSeries = (
  series: SeriesOptionsWithData[],
  usedCategories: { positive: string[]; negative: string[] },
  colors: { positive: SeriesColor[]; negative: SeriesColor[] }
) => {
  const colorMap = {} as { [category: string]: SeriesColor };

  const positiveLength = usedCategories.positive.length;
  const negativeLength = usedCategories.negative.length;

  // Move extra colors from one category to another
  const positiveColorsToUse = colors.positive.concat(
    colors.negative.length > negativeLength ? colors.negative.slice(negativeLength) : []
  );
  const negativeColorsToUse = colors.negative.concat(
    colors.positive.length > positiveLength ? colors.positive.slice(positiveLength) : []
  );

  // Assign colors to positive categories
  usedCategories.positive.forEach((category, index) => {
    colorMap[category] = positiveColorsToUse[index % positiveColorsToUse.length];
  });

  // Assign 'happier' green and blue colors to negative categories
  usedCategories.negative.forEach((category, index) => {
    colorMap[category] = negativeColorsToUse[index % negativeColorsToUse.length];
  });

  return series.map((item) => ({
    ...item,
    color: item.id ? colorMap[item.id]?.normal : 'transparent',
    states: {
      hover: { color: item.id ? colorMap[item.id]?.dark : 'transparent' },
      inactive: { color: item.id ? colorMap[item.id]?.light : 'transparent' },
    },
  }));
};

type UseColoredDataProps = {
  values: SeriesOptionsWithData[];
  usedCategories: { positive: string[]; negative: string[] };
};

const usePositiveNegativeColoredData = ({ values, usedCategories }: UseColoredDataProps) => {
  const { palette } = useTheme();

  // Colors for negative series should be the 'happier' green and blue colors
  const colors = useMemo(
    () => ({
      positive: [
        colorScheme(palette.visualization.divergent.primary, true),
        colorScheme(palette.visualization.divergent.primary, false),
        colorScheme(palette.visualization.divergent.secondary, true),
        colorScheme(palette.visualization.divergent.tertiary, true),
      ],
      negative: [
        colorScheme(palette.visualization.divergent.tertiary, false),
        colorScheme(palette.visualization.divergent.secondary, false),
      ],
    }),
    [palette]
  );

  return useMemo(() => getColoredSeries(values, usedCategories, colors), [values, usedCategories, colors]);
};

export default usePositiveNegativeColoredData;
