import { Filter, FilterConfiguration } from '../components/ContractList/Filters/types';
import { useCallback, useMemo, useState } from 'react';

export type UseFilterConfigReturn = {
  availableFilters: Filter[];
  activeFilters: Filter[];
  setFiltersEnabled: (filters: Filter[], enabled: boolean) => void;
  setFiltersVisible: (filters: Filter[]) => void;
};

const defaultConfig = [
  { name: Filter.VENDOR_PRODUCT, enabled: true, visible: true },
  { name: Filter.RENEWAL_STATUS, enabled: true, visible: true },
  { name: Filter.THRESHOLD, enabled: true, visible: true },
  { name: Filter.DEPARTMENT, enabled: true, visible: true },
  { name: Filter.OWNER, enabled: false, visible: true },
  { name: Filter.SIGNING_ENTITY, enabled: false, visible: false },
  { name: Filter.REGION, enabled: false, visible: false },
  { name: Filter.ROLLING_FREQUENCY, enabled: false, visible: false },
  { name: Filter.BILLING_FREQUENCY, enabled: false, visible: false },
  { name: Filter.PAYMENT_TERMS, enabled: false, visible: false },
  { name: Filter.STAGE, enabled: false, visible: false },
  { name: Filter.CONTRACT_ORIGIN, enabled: false, visible: false },
  { name: Filter.CONTRACT_CATEGORY, enabled: false, visible: false },
  { name: Filter.CUSTOM_CHECKBOX_01, enabled: false, visible: false },
  { name: Filter.CUSTOM_CHECKBOX_02, enabled: false, visible: false },
  { name: Filter.CUSTOM_CHECKBOX_03, enabled: false, visible: false },
  { name: Filter.CUSTOM_CHECKBOX_04, enabled: false, visible: false },
  { name: Filter.CUSTOM_CHECKBOX_05, enabled: false, visible: false },
  { name: Filter.CUSTOM_TEXT_01, enabled: false, visible: false },
  { name: Filter.CUSTOM_TEXT_02, enabled: false, visible: false },
  { name: Filter.CUSTOM_TEXT_03, enabled: false, visible: false },
  { name: Filter.CUSTOM_TEXT_04, enabled: false, visible: false },
  { name: Filter.CUSTOM_TEXT_05, enabled: false, visible: false },
  { name: Filter.CUSTOM_DROPDOWN_01, enabled: false, visible: false },
  { name: Filter.CUSTOM_DROPDOWN_02, enabled: false, visible: false },
  { name: Filter.CUSTOM_DROPDOWN_03, enabled: false, visible: false },
  { name: Filter.CUSTOM_DROPDOWN_04, enabled: false, visible: false },
  { name: Filter.CUSTOM_DROPDOWN_05, enabled: false, visible: false },
];

const useFilterConfig = (): UseFilterConfigReturn => {
  const [filterConfig, setFilterConfig] = useState<FilterConfiguration[]>(defaultConfig);

  const availableFilters = useMemo(
    () => filterConfig.filter((filter) => filter.enabled).map((filter) => filter.name),
    [filterConfig]
  );

  const activeFilters = useMemo(
    () => filterConfig.filter((filter) => filter.enabled && filter.visible).map((filter) => filter.name),
    [filterConfig]
  );

  const setFiltersEnabled = useCallback((filters: Filter[], enabled: boolean) => {
    setFilterConfig((oldFilterConfig) =>
      oldFilterConfig.map((filter) => {
        if (filters.includes(filter.name)) {
          return {
            ...filter,
            enabled: enabled,
          };
        } else {
          return filter;
        }
      })
    );
  }, []);

  const setFiltersVisible = useCallback((filters: Filter[]) => {
    setFilterConfig((oldFilterConfig) =>
      oldFilterConfig.map((filter) => ({
        ...filter,
        visible: filters.includes(filter.name),
      }))
    );
  }, []);

  return {
    availableFilters,
    activeFilters,
    setFiltersEnabled,
    setFiltersVisible,
  };
};

export default useFilterConfig;
