import React from 'react';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { SlackIntegrationPanel } from './SlackIntegrationPanel';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

export const SlackIntegration: React.FC = () => {
  const { isEnabled } = useFeatures();

  const { accountId } = useAccountContext();
  const { data: integration, isLoading: isIntegrationLoading } = useGetAccountIntegrationQuery(
    { accountId: accountId!, integrationId: 'Provider:Slack' },
    { skip: !accountId }
  );

  return !isIntegrationLoading && integration?.status === 'ACTIVE' && isEnabled(FEATURES.INTEGRATION_SLACK) ? (
    <SlackIntegrationPanel />
  ) : null;
};
