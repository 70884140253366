import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import { getTextVariantStyle } from '@verticeone/design-system/src';
import { Graph } from '../../components';
import { GraphSeries, GraphWrapper, GraphLegend } from '../../components/Graph';
import { useMonitoringLatestQuery } from '../../../../dataSources/useMonitoringLatestQuery';

const useOptimizationSeries = () => {
  const { palette } = useTheme();
  const { data, isFetching } = useMonitoringLatestQuery('SageMaker');
  const { t } = useTranslation(undefined, {
    keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.GRAPH`,
  });

  return useMemo(
    () => ({
      isFetching,
      series: [
        {
          id: 'SAVINGS_PLANS_COVERAGE',
          type: 'bar' as const,
          isVisible: !!data?.sp_usage_percentage,
          data: [data ? Math.round(data.sp_usage_percentage * 10000) / 100 : 0],
          color: palette.visualization.monochromatic.primary['80'],
          name: t('SAVINGS_PLANS_COVERAGE'),
          dataLabels: [
            {
              style: {
                ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
                color: palette.visualization.monochromatic.primary['30'],
              },
            },
          ],
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.primary['50'],
            },
          },
        },
        {
          id: 'ON_DEMAND_USAGE',
          type: 'bar' as const,
          isVisible: !!data?.on_demand_usage_percentage,
          data: [data ? Math.round(data.on_demand_usage_percentage * 10000) / 100 : 0],
          color: getColorPattern(palette.text.color3),
          name: t('ON_DEMAND_USAGE'),
          dataLabels: [
            {
              style: {
                ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
                color: palette.text.color1,
              },
            },
          ],
          states: {
            hover: {
              color: getColorPattern(palette.text.color4),
            },
          },
        },
      ] as GraphSeries,
    }),
    [data, isFetching, palette, t]
  );
};

const GraphContainer = () => {
  const { series, isFetching } = useOptimizationSeries();

  if (isFetching) {
    return null;
  }

  return (
    <GraphWrapper>
      <GraphLegend series={series} />
      <Graph series={series} />
    </GraphWrapper>
  );
};

export default GraphContainer;
