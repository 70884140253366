import { ComputeDataColumn, useMonitoringLatestQuery } from '../../dataSources/useMonitoringLatestQuery';
import { useDataSource } from '../../../DataSourceProvider';

export const useHasMissingLookup = () => {
  const { datasource } = useDataSource();
  const { data, isFetching } = useMonitoringLatestQuery(datasource);

  return {
    isFetching: isFetching,
    isSPMissing: data?.missing_savings_plan ?? false,
    isRIMissing: (data as Record<ComputeDataColumn, number>)?.missing_reservation ?? false,
  };
};
