import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export enum FieldType {
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  DECIMAL = 'DECIMAL',
  DATE = 'DATE',
  TEXTAREA = 'TEXTAREA',
  DROPDOWN = 'DROPDOWN',
}

export type CustomFieldIdentifier = {
  id: string;
  label: string;
  type: FieldType;
};

export type FieldItem = {
  id: string;
  fieldType: CustomFieldIdentifier | null;
  label: string;
  isVisible: boolean;
  options?: CustomContractFieldDropdownOption[];
  isBeingProcessed?: boolean;
  isNew?: boolean;
};

export type OptionCellDialogContext =
  | {
      values: CustomContractFieldDropdownOption[];
      onSave: (values: CustomContractFieldDropdownOption[]) => void;
    }
  | undefined;
