import { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';

type CardWrapperProps = PropsWithChildren;

export const CardWrapper = ({ children }: CardWrapperProps) => (
  <Grid container item xs={4} sx={{ minWidth: '400px' }}>
    {children}
  </Grid>
);
