import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';

import { QUERY_KEY as CONTRACT_LIST_QUERY_KEY } from './useContractListData';
import { QUERY_KEY as EDP_CONFIGURATION_QUERY_KEY } from '../Configurations/useEDPConfigurationData';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type UseContractDeleteProps = Partial<Record<'onSuccess' | 'onError' | 'onSettled', () => void>>;

export const QUERY_KEY = 'EDPContractDelete' as const;

const EDPContractDeleteQuery = graphql(`
  mutation EDPContractDelete($accountId: ID!, $identifier: ID!) {
    deleteContract(params: { accountId: $accountId, identifier: $identifier }) {
      ... on EDPContractMemento {
        identifier
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const useContractDelete = (options?: UseContractDeleteProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { accountId } = useAccountContext();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchCloudOptimization } = useCloudClient();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (contractId: string) => {
      return fetchCloudOptimization(EDPContractDeleteQuery, {
        accountId,
        identifier: contractId,
      });
    },
    onSettled: () => {
      options?.onSettled?.();
    },
    onSuccess: async () => {
      enqueueSnackbar({
        variant: 'success',
        message: t('ALERT.CONTRACT_DELETED'),
      });

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [CONTRACT_LIST_QUERY_KEY] }),
        queryClient.invalidateQueries({ queryKey: [EDP_CONFIGURATION_QUERY_KEY] }),
      ]);

      options?.onSuccess?.();
    },
    onError: (errors: Array<Error>) => {
      errors.forEach((e) =>
        enqueueSnackbar({
          variant: 'error',
          message: e.message,
        })
      );
      options?.onError?.();
    },
  });
};
