import React from 'react';

import { NoData } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoContractIcon } from '../../../../Applications/ContractNotPresentIcon.svg';
import { styled } from '@mui/material';

const StyledNoContractIcon = styled(NoContractIcon)(({ theme }) => ({
  fill: theme.palette.warning.light,
  width: '24px',
  height: '24px',
}));

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NoData
      header={t('APPLICATIONS.USAGE.LINKED_CONTRACTS.NO_DATA.HEADER')}
      content={t('APPLICATIONS.USAGE.LINKED_CONTRACTS.NO_DATA.CONTENT')}
      icon={<StyledNoContractIcon />}
    />
  );
};

export default NoRows;
