import React from 'react';
import { Grid, Stack } from '@mui/material';

type ExtensionGridProps = {
  breakpoints?: Array<number>;
  children: Array<JSX.Element>;
};

const ExtensionGrid = ({ children, breakpoints }: ExtensionGridProps) => (
  <Grid container spacing={4} direction="row" alignItems="stretch">
    {React.Children.map(children, (child, index) => (
      <Grid item xs={12} md={12} lg={breakpoints?.[index] ?? 12} display="flex">
        <Stack flex={1}>{child}</Stack>
      </Grid>
    ))}
  </Grid>
);

export default ExtensionGrid;
