import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Link, Stack, styled } from '@mui/material';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { GoodFormProps, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { email, nonEmptyString, url } from '@verticeone/utils/validation';
import { Button, IconWrapper, Text, Tooltip } from '@verticeone/design-system/src';
import { JiraSetupStep } from '../types';
import { FORM_FIELD_WIDTH } from '../constants';
import { useEffect } from 'react';

const connectionFormDataSchema = z.object({
  workspaceUrl: url(t),
  administratorEmail: email(t),
  apiKey: nonEmptyString,
});

export type ConnectionFormData = z.infer<typeof connectionFormDataSchema>;
type ConnectionFormProps = GoodFormProps<ConnectionFormData> & {
  activeStep: JiraSetupStep;
  isLoading: boolean;
  isSubmitted: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
};

const ConnectionFormEntry = createTypedFormEntry<ConnectionFormData>();

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.color3,
  textDecorationColor: theme.palette.text.color3,
  '&:hover': {
    color: theme.palette.inactive.color2,
    textDecorationColor: theme.palette.inactive.color2,
  },
}));

export const ConnectionForm = ({
  defaultValues,
  onSubmit,
  activeStep,
  isLoading,
  isSubmitted,
  setIsEditMode,
}: ConnectionFormProps) => {
  const formMethods = useForm<ConnectionFormData>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(connectionFormDataSchema),
  });
  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);

  useEffect(() => {
    if (isSubmitted) {
      formMethods.resetField('apiKey');
    }
  }, [formMethods, isSubmitted]);

  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={goodFormMethods.handleSubmit(onSubmit)}>
        <Stack gap={8} maxWidth={FORM_FIELD_WIDTH}>
          {isSuccessStep ? (
            <Stack gap={0.5}>
              <Stack direction="row" gap={1}>
                <Text variant="caption" size="XS" color="success">
                  {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_1_LABEL')}
                </Text>
                <Tooltip
                  content={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_1_TOOLTIP_CONNECTED')}
                  size="S"
                >
                  <IconWrapper icon={InfoIconOutlined} size="XS" color="success" />
                </Tooltip>
              </Stack>
              <Text variant="heading" size="M" color="success">
                vertice.atlassian.net
              </Text>
            </Stack>
          ) : (
            <ConnectionFormEntry
              component={FormTextField}
              componentProps={{
                placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_1_PLACEHOLDER'),
              }}
              disabled={isSubmitted}
              label={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_1_LABEL')}
              name="workspaceUrl"
              required
              tooltip={{ content: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_1_TOOLTIP') }}
            />
          )}
          <ConnectionFormEntry
            component={FormTextField}
            componentProps={{
              placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_2_PLACEHOLDER'),
            }}
            disabled={isSubmitted}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_2_LABEL')}
            name="administratorEmail"
            required
            tooltip={{ content: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_2_TOOLTIP') }}
          />
          <ConnectionFormEntry
            component={FormTextField}
            componentProps={{
              placeholder: !isSubmitted
                ? t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_3_PLACEHOLDER')
                : t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_3_PLACEHOLDER_SUBMITTED'),
            }}
            disabled={isSubmitted}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_3_LABEL')}
            name="apiKey"
            required
            tooltip={{
              content: (
                <Trans
                  i18nKey="PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.FIELD_3_TOOLTIP"
                  // TODO: Replace with actual URL once the KB article is ready
                  components={{ a: <StyledLink href="https://www.dummyurl.com" /> }}
                />
              ),
            }}
          />
          {!isSubmitted || isLoading ? (
            <Stack direction="row" gap={2}>
              {isSuccessStep && (
                <Button variant="outline" size="M" color="neutral" onClick={() => setIsEditMode(false)}>
                  {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.DISMISS_BUTTON')}
                </Button>
              )}
              <Button
                variant="outline"
                size="M"
                color="primary"
                type="submit"
                isLoading={submitButtonProps.isLoading || isLoading}
                disabled={submitButtonProps.disabled || isLoading}
              >
                {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_FORM.SUBMIT_BUTTON')}
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" gap={2}>
              <IconWrapper icon={CheckCircleOutlineIcon} color="success" />
              <Text variant="button-bold" size="M" color="success">
                {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_VERIFIED')}
              </Text>
            </Stack>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
