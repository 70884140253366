import { useQuery } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { MonitoringValueCheckResult } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

type CheckCode = 'Compute' | 'SageMaker';

export type GenericDataColumn =
  | 'annual_on_demand_cost'
  | 'annual_optimized_cost_from'
  | 'annual_savings_from'
  | 'daily_recommendation'
  | 'missing_savings_plan'
  | 'on_demand_usage_percentage'
  | 'resource_cost'
  | 'savings_achieved'
  | 'savings_found_from'
  | 'savings_found_to'
  | 'sp_usage_percentage'
  | 'sp_utilization_percentage'
  | 'three_years_optimized_cost_to'
  | 'three_years_savings_to'
  | 'uncoverable_cost';

export type ComputeDataColumn =
  | 'resource_cost'
  | 'savings_achieved'
  | 'savings_found_from'
  | 'savings_found_to'
  | 'daily_recommendation'
  | 'uncoverable_cost'
  | 'ec2_total_cost'
  | 'ec2_sp_usage_cost_percentage'
  | 'ec2_on_demand_cost_percentage'
  | 'ec2_ri_cost_percentage'
  | 'ec2_ri_utilization_percentage'
  | 'lambda_total_cost'
  | 'lambda_sp_usage_cost_percentage'
  | 'lambda_on_demand_cost_percentage'
  | 'fargate_total_cost'
  | 'fargate_sp_usage_cost_percentage'
  | 'fargate_on_demand_cost_percentage'
  | 'total_ri_cost_percentage'
  | 'total_sp_usage_cost_percentage'
  | 'total_on_demand_cost_percentage'
  | 'missing_savings_plan'
  | 'missing_reservation'
  | 'annual_on_demand_cost'
  | 'annual_optimized_cost_from'
  | 'annual_savings_from'
  | 'three_years_optimized_cost_to'
  | 'three_years_savings_to'
  | 'sp_utilization_percentage';

type DataType<T extends CheckCode> = T extends 'Compute'
  ? Record<ComputeDataColumn, number> | null
  : Record<GenericDataColumn, number> | null;

const checkCodes: Record<CheckCode, string> = {
  SageMaker: 'SAGE_MAKER_COVERAGE_LAST_DAYS',
  Compute: 'COMPUTE_SP_COVERAGE_LAST_DAYS',
};

const MonitoringLatestQuery = graphql(`
  query MonitoringLatestQuery($accountId: String!, $checkCode: String!) {
    monitoringLatestQuery(params: { accountId: $accountId, monitoringType: CHECK, code: $checkCode }) {
      __typename
      ... on MonitoringResult {
        items {
          code
          results {
            __typename
            ... on MonitoringValueCheckResult {
              columns
              data
              dataTypes
            }
          }
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const useMonitoringLatestQuery = <T extends CheckCode>(checkCode: T) => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['MonitoringLatestOverviewWidget', checkCode],
    queryFn: () => fetchCloudOptimization(MonitoringLatestQuery, { accountId, checkCode: checkCodes[checkCode] }),
    enabled: !!accountId,
    select: (data): DataType<T> | null => {
      if (data.monitoringLatestQuery?.__typename === 'MonitoringResult') {
        if (data.monitoringLatestQuery.items?.[0].results?.__typename === 'MonitoringValueCheckResult') {
          const tableData = getTableData(data.monitoringLatestQuery.items[0].results as MonitoringValueCheckResult);

          return (tableData?.[0] as DataType<T>) ?? null;
        }
      }

      return null;
    },
  });
};
