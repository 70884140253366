import { TaskConfiguration } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { resolveServiceTaskType } from './resolveTaskNodeType';

export const resolveTaskType = (configurations: TaskConfiguration[] = []) => {
  const verticeServiceConfiguration = configurations.find((config) => config.kind === 'Vertice:Service');

  const resourceUrn = verticeServiceConfiguration?.resourceUrn;

  if (!resourceUrn || typeof resourceUrn !== 'string') {
    return 'USER';
  }

  return resolveServiceTaskType(resourceUrn);
};
