import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';
import { servicesAPICodegen, ListRequestsApiResponse, ListRequestsApiArg } from '../openapi/codegen/servicesAPI';

import { SERVICES_TAG_PREFIX } from './servicesAPI';
export const SERVICES_REQUEST = `${SERVICES_TAG_PREFIX}Request`;
export const SERVICES_RESOURCE = `${SERVICES_TAG_PREFIX}CatalogResource`;

export type ServicesTagType = typeof SERVICES_REQUEST | typeof SERVICES_RESOURCE;

export const enhancedServicesAPI = servicesAPICodegen
  .enhanceEndpoints<ServicesTagType>({
    addTagTypes: [SERVICES_REQUEST, SERVICES_RESOURCE],
    endpoints: {
      createRequest: {
        invalidatesTags: [SERVICES_REQUEST],
      },
      getRequest: {
        providesTags: [SERVICES_REQUEST],
      },
      listRequests: {
        providesTags: [SERVICES_REQUEST],
      },
      listServiceCatalog: {
        providesTags: [SERVICES_RESOURCE],
      },
      updateServiceCatalogServiceResource: {
        invalidatesTags: [SERVICES_RESOURCE],
      },
      updateRequestsOwner: {
        invalidatesTags: [SERVICES_REQUEST],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listServiceRequestsPaginated: build.query<{ items: ListRequestsApiResponse['items'] }, ListRequestsApiArg>({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListRequestsApiResponse,
            ListRequestsApiArg,
            ListRequestsApiResponse['items'][0]
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/requests`,
                params: { nextToken: args.nextToken, maxResults: args.maxResults },
              });
              return { data: queryResponse.data as ListRequestsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.items,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [SERVICES_REQUEST],
      }),
    }),
  });

export const { useListServiceRequestsPaginatedQuery } = enhancedServicesAPI;
