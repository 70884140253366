import React from 'react';
import { useParams } from 'react-router-dom';

import EC2Instance from './products/EC2/Layout';
import RDSInstance from './products/RDS/Layout';
import type { ProductKey } from './types';

const Products: Record<ProductKey, React.ReactNode> = {
  ec2: <EC2Instance />,
  rds: <RDSInstance />,
};

const CloudRioInstanceTypes = () => {
  const { product } = useParams<{ product: ProductKey }>();

  return product ? Products[product] : null;
};

export default CloudRioInstanceTypes;
