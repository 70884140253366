import styled from '@mui/material/styles/styled';
import MuiButton from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import { gradientBorderColor } from '../../utils/css/gradientBorder';
import { getTextVariantStyle } from '../Text/utils';
import type { ButtonProps } from './Button';
import { getButtonVariantProperties, getPadding } from './variantProperties';
import { ForwardedRef } from 'react';
import { CSSObject } from '@mui/styled-engine';

export type UseStylesProps = Required<Pick<ButtonProps, 'size' | 'color' | 'variant' | 'isLoading'>> & {
  isActive: boolean;
  isCaption: boolean;
  borderRadius?: string;
};

export const useBaseButtonStyles = ({
  size,
  variant,
  color,
  isCaption,
  isActive,
  isLoading,
  borderRadius,
}: UseStylesProps): CSSObject => {
  const { palette } = useTheme();

  const borderWidth = '2px';
  const paddingGrowth = 1;
  const minPadding = 5;

  const variantProperties = getButtonVariantProperties({
    color,
    size,
    isCaption,
    minPadding,
    paddingGrowth,
    borderRadius: borderRadius ?? '8px',
    borderWidth,
    palette,
  });

  return {
    ...getTextVariantStyle({ variant: 'button-bold', size }),
    borderRadius: borderRadius ?? '8px',
    opacity: isActive ? '100%' : '40%',
    cursor: isActive ? 'pointer' : 'default',
    ...variantProperties[variant][isLoading ? 'loading' : 'primary'],
    '.loader-spinner svg': {
      ...variantProperties[variant].loading,
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
    },
    '&:hover': variantProperties[variant].hover,
    '&:focus-visible, &:active': variantProperties[variant].focus,
    '&[disabled]': {
      color: palette.inactive.color2,
      backgroundColor: palette.inactive.color4,
      padding: getPadding(size, isCaption, borderWidth, minPadding, paddingGrowth),
      border: `${borderWidth} solid ${palette.inactive.color4}`,
      ...gradientBorderColor(palette.inactive.color4),
      '.loader-spinner svg': {
        color: palette.inactive.color2,
      },
    },
  };
};

export const BaseButton = styled(MuiButton)<{ $styles: CSSObject; ref: ForwardedRef<HTMLButtonElement> }>(
  ({ $styles }) => ({
    ...$styles,
    minWidth: '10px',
    height: 'fit-content',
    lineHeight: '100%',
    fontWeight: '600',
    position: 'relative',
  })
);
