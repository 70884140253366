import { Request, useListRequestsDetailsMutation } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { isEqual } from 'lodash';
import { RequestRow } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { mapRequestRow, useRequests } from './useRequests';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { isRequestRef, parseRequestRef } from '../../../../hooks/workflows/refUtils';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { GridRowId, GridState } from '@mui/x-data-grid-pro';

export const mapRequestRowDetails = (request: Request): RequestRow => {
  const requestRow = mapRequestRow(request);
  return {
    ...requestRow,
    activeTasks: request.tasks
      ?.filter(({ status }) => status === 'ACTIVE')
      .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    completedTasks: request.tasks?.filter(({ status }) => status === 'COMPLETED').length,
    taskAssignees: request.taskAssignment,
    tasks: request.tasks,
    comments:
      request.totalMessages && request.unreadMessages
        ? {
            total: request.totalMessages,
            unread: request.unreadMessages,
          }
        : undefined,

    isPartial: false,
  };
};

const useRequestDetails = () => {
  const { accountId } = useAccountContext();

  const [data, setData] = useState<Record<string, RequestRow | undefined>>({});

  const [fetchRequestsDetails] = useListRequestsDetailsMutation();
  const fetchDetails = useCallback(
    async (requestIds: string[]) => {
      if (requestIds.length === 0) {
        return;
      }

      const details = await fetchRequestsDetails({ accountId, body: requestIds });
      if ('error' in details) {
        return;
      }

      setData((old) => {
        const next = details.data.reduce(
          (acc, request) => ({ ...acc, [parseRequestRef(request.ref).requestId]: mapRequestRowDetails(request) }),
          { ...old }
        );

        for (const requestId of requestIds) {
          if (requestId in next) {
            continue;
          }
          next[requestId] = undefined;
        }
        return next;
      });
    },
    [accountId, fetchRequestsDetails]
  );
  const refetch = useCallback(() => fetchDetails(Object.keys(data)), [fetchDetails, data]);

  return { data, fetchDetails, refetch };
};

export const useGridRequestDetails = (filterChildLeafs = true) => {
  const { requests: baseRequests, requestIds, isLoadingRequests } = useRequests();
  const { data: requestDetails, fetchDetails, refetch } = useRequestDetails();

  useNotificationWebSocketSubscription({
    filter: isRequestRef,
    callback: () => {
      void refetch();
    },
  });

  const [partialRequestIds, setPartialRequestIds] = useState<string[]>([]);

  const onGridStateChanged = useCallback(
    ({ rows, pagination, sorting }: GridState) => {
      if (
        rows.loading ||
        rows.loading === undefined ||
        pagination.paginationModel.page === undefined ||
        pagination.paginationModel.pageSize === undefined
      ) {
        return;
      }

      const sortedRows = filterChildLeafs
        ? sorting.sortedRows.filter((rowId) => !rows.tree[rowId]?.depth)
        : sorting.sortedRows;

      const next = sortedRows
        .slice(
          pagination.paginationModel.page * pagination.paginationModel.pageSize,
          (pagination.paginationModel.page + 1) * pagination.paginationModel.pageSize
        )
        .map((id: GridRowId) => rows.dataRowIdToModelLookup[id])
        .filter((request) => request)
        .filter(({ isPartial, isLegacy }) => isPartial || !isLegacy)
        .map(({ requestId }) => requestId);

      setPartialRequestIds((old) => {
        if (isEqual(old, next)) {
          return old;
        }

        return next;
      });
    },
    [filterChildLeafs]
  );

  useEffect(() => {
    fetchDetails(partialRequestIds).catch(() => {});
  }, [partialRequestIds, fetchDetails]);

  const requests = useMemo(() => {
    if (Object.keys(requestDetails).length === 0) {
      return baseRequests;
    }

    return baseRequests.map((base) => {
      if (!(base.requestId in requestDetails)) {
        return base;
      }
      const details = requestDetails[base.requestId];
      return details ? details : base;
    });
  }, [baseRequests, requestDetails]);

  return {
    requests,
    requestIds,
    isLoadingRequests,
    onGridStateChanged,
  };
};
