import React, { FC } from 'react';
import { styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { NodeControls } from './NodeControls';
import { Task } from '../../definitionsTypes';
import { StyledRectNode } from './Shared';
import {
  ArticleOutlined,
  CableOutlined,
  MiscellaneousServicesOutlined,
  PersonOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import { IconWrapper } from '@verticeone/design-system/src';

export const StyledTaskNode = styled(StyledRectNode)(({ theme }) => ({
  backgroundColor: theme.palette.warning.color4,
}));

const TypeIconMap: Record<Task['taskType'], SvgIconComponent> = {
  Service: MiscellaneousServicesOutlined,
  User: PersonOutlined,
  Script: ArticleOutlined,
  Connector: CableOutlined,
};

export const TaskNodeComponent: FC<NodeProps<Node<Task>>> = (props) => (
  <NodeControls
    {...props}
    content={
      <StyledTaskNode $selected={props.selected}>
        <IconWrapper size="XL" icon={TypeIconMap[props.data.taskType]} />
        {props.data.name}
      </StyledTaskNode>
    }
  />
);
