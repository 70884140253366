import { useLatestOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { chain, sumBy } from 'lodash';

const CHECK_CODE = 'EC2_PLATFORM_COST';

export type EC2OSUsageData = { name: string; y: number }[];

const useEC2OSUsageData = (): LoadableAdvanced<EC2OSUsageData> => {
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error } = useLatestOptimizationCheckQuery(
    { accountId: accountId!, checkCode: CHECK_CODE },
    { skip: !accountId }
  );

  const computed = useMemo(() => {
    if (!rawData || rawData.monitoringLatestQuery?.__typename !== 'MonitoringResult') {
      return undefined;
    }

    const checkData = rawData.monitoringLatestQuery.items?.find(
      (rec) => rec.code === CHECK_CODE && rec.results?.__typename === 'MonitoringValueCheckResult'
    );

    if (checkData?.results?.__typename !== 'MonitoringValueCheckResult') {
      return [];
    }

    const result = checkData?.results;

    const operatingSystemColIndex = result.columns.indexOf('operating_system');
    const normalizedUsageColIndex = result.columns.indexOf('sum_normalized_usage_quantity');

    if (operatingSystemColIndex === -1 || normalizedUsageColIndex === -1) {
      console.error('useEC2OSUsageData: Some of the required columns is missing in the data.');
      return [];
    }

    const operatingSystems: { name: string; y: number }[] = chain(checkData?.results.data)
      .groupBy((row) => row[operatingSystemColIndex])
      .map((recordsWithTheOs, os) => ({
        name: os,
        y: sumBy(recordsWithTheOs, (row) => parseFloat(row[normalizedUsageColIndex])),
      }))
      .value();

    return operatingSystems;
  }, [rawData]);

  return { error, isEmpty: Boolean(computed && computed.length === 0), isLoading: !computed, data: computed };
};

export default useEC2OSUsageData;
