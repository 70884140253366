// EXPERIMENATAL, DON'T USE IN CFA
import React, { ReactElement, RefAttributes } from 'react';
import { styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { CSSObjectWithLabel } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import SelectClass from 'react-select/dist/declarations/src/Select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

type SelectFieldProps<T> = {
  options: T[];
  getOptionLabel: (option: T) => string;
  id: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
};

const selectStyle = {
  option: (styles: CSSObjectWithLabel) => ({
    ...styles,
    backgroundColor: 'white',
    color: 'var(--tokens-color-light-text-1)',
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    borderRadius: '10px',
    padding: '5px 10px',
  }),
};

type SelectWithoutTheme = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Omit<StateManagerProps<Option, IsMulti, Group> & RefAttributes<SelectClass<Option, IsMulti, Group>>, 'theme'>
) => ReactElement;

const StyledReactAsyncSelect = styled(Select)(({ theme }) => ({
  background: theme.palette.core.color1,
})) as SelectWithoutTheme;

const ReactSelectField = <T extends any>({
  id,
  name,
  required,
  disabled,
  options,
  getOptionLabel,
}: SelectFieldProps<T>) => {
  // eslint-disable-next-line no-restricted-syntax
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <StyledReactAsyncSelect
          onBlur={onBlur}
          onChange={onChange}
          options={options}
          styles={selectStyle}
          getOptionLabel={getOptionLabel}
          value={value || ''}
          ref={ref}
          id={id}
          isDisabled={disabled}
          menuPlacement="auto"
          menuPosition="absolute"
          isClearable
        />
      )}
    />
  );
};

export default ReactSelectField;
