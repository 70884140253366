import { useMemo, useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../../Menu';
import { Stack, styled } from '@mui/material';
import { SortByAlpha } from '@mui/icons-material';
import { SortByZaIcon } from '../../../assets';
import i18n from '../../../translate';
import {
  GridSortDirection,
  gridSortModelSelector,
  useGridApiContext,
  GridColumnMenuItemProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';

const SortByDesc = styled(SortByZaIcon)(({ theme: { palette } }) => ({
  width: '14px',
  height: '14px',
  fill: palette.text.color3,
  marginLeft: 2,
}));

export const GridColumnMenuSortItem = ({ colDef, onClick }: GridColumnMenuItemProps) => {
  const { t } = useTranslation(undefined, { i18n });
  const apiRef = useGridApiContext();
  const sortModel = useGridSelector(apiRef, gridSortModelSelector);

  const sortDirection = useMemo(() => {
    if (!colDef) {
      return null;
    }

    return sortModel.find((item) => item.field === colDef.field)?.sort;
  }, [colDef, sortModel]);

  const onSortMenuItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick(event);
      const direction = event.currentTarget.getAttribute('data-value') || null;
      apiRef.current.sortColumn(colDef, direction as GridSortDirection);
    },
    [apiRef, colDef, onClick]
  );

  if (!colDef || !colDef.sortable) {
    return null;
  }

  return (
    <>
      <MenuItem
        startIcon={SortByAlpha}
        onClick={onSortMenuItemClick}
        data-value="asc"
        disabled={sortDirection === 'asc'}
      >
        {t('GRID_COLUMN_MENU.SORT_ASCENDING')}
      </MenuItem>
      <MenuItem onClick={onSortMenuItemClick} data-value="desc" disabled={sortDirection === 'desc'}>
        <Stack gap={1} direction="row" justifyContent="center" alignItems="center">
          <SortByDesc />
          {t('GRID_COLUMN_MENU.SORT_DESCENDING')}
        </Stack>
      </MenuItem>
    </>
  );
};
