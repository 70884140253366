import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';

import { Comment, CommentMetadata } from '../Comment';
import CommentsContainer from './components/CommentsContainer';
import { Divider } from '../Divider';
import CommentsWrapper from './components/CommentsWrapper';
import CommentInput from './components/CommentInput';
import EmptyComments from './components/EmptyComments';
import {
  EditCommentCallback,
  EditCommentInternalCallback,
  RemoveCommentCallback,
  SendCommentCallback,
  UpdateCursorCallback,
} from './types';
import NewMessageDivider from './components/NewMessageDivider';
import { CommentLoading } from './CommentLoading';
import { useScrollElement } from './components/useScrollElement';
import { CommentSkeleton } from '../Comment';
import { getFirstNewCommentIndex, getNewCommentsCount, useCommentContent } from './utils';
import { MentionUser } from '../Comment/types';

export type CommentsProps = {
  comments?: CommentMetadata[] | null;
  isLoading?: boolean;
  cursor: string | null;
  isSending?: boolean;
  currentUser?: string;
  users?: { [x: string]: MentionUser };
  canEditComents: boolean;
  onSendComment: SendCommentCallback;
  onRemoveComment: RemoveCommentCallback;
  onEditComment: EditCommentCallback;
  onUpdateCursor?: UpdateCursorCallback;
};

export const Comments = ({
  users,
  currentUser,
  comments,
  isLoading,
  cursor,
  isSending,
  canEditComents,
  onSendComment,
  onRemoveComment,
  onEditComment,
  onUpdateCursor,
}: CommentsProps) => {
  const [editedComment, setEditedComment] = useState<{ id: string; content: string } | null>(null);

  const newMessagesCount = comments && cursor ? getNewCommentsCount(comments, cursor) : 0;
  const newMessageIndex = comments && cursor ? getFirstNewCommentIndex(comments, cursor) : undefined;

  const lastMessageBeforeNewMessagesRef = useRef<HTMLDivElement>(null);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!commentsContainerRef.current || !lastMessageRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // Not sure if timeout is ideal, but we want the user to see the information, not just make it disappear immediately
          setTimeout(() => onUpdateCursor?.(new Date().toISOString()), 5000);
          observer.unobserve(lastMessageRef.current!);
        }
      },
      { root: commentsContainerRef.current, threshold: 0.99 }
    );
    observer.observe(lastMessageRef.current!);
    /* eslint-disable */
  }, [commentsContainerRef.current, lastMessageRef.current, onUpdateCursor]);

  const commentContent = useCommentContent(currentUser, users);
  const { isScrolledToBottom, scrollToBottom } = useScrollElement({
    container: commentsContainerRef,
    condition:
      !isLoading && (newMessagesCount === 0 || !cursor) && !isSending && !lastMessageBeforeNewMessagesRef.current,
    tolerance: 50,
  });

  useScrollElement({
    element: lastMessageBeforeNewMessagesRef,
    container: commentsContainerRef,
    condition: !isLoading && newMessagesCount > 0,
  });

  const handleSendComment: SendCommentCallback = (comment, onSuccess) => {
    onSendComment(comment, () => {
      if (isScrolledToBottom) scrollToBottom('smooth');

      onSuccess?.();
    });
  };

  const handleEditComment: EditCommentInternalCallback = (messageId, content) => {
    if (!messageId || !content) {
      setEditedComment(null);
      return;
    }

    onEditComment(messageId, content).then(() => {
      setEditedComment(null);
    });
  };

  const toggleEditComment = useCallback(
    (id?: string | null) => {
      if (!id || !comments) return setEditedComment(null);

      const content = comments.find((comment) => comment.messageId === id)?.content ?? '';

      setEditedComment({ id, content });
    },
    [onEditComment, comments]
  );

  useEffect(() => {
    if (isScrolledToBottom && newMessagesCount > 1) {
      // Not sure if timeout is ideal, but we want the user to see the information, not just make it disappear immediately
      setTimeout(() => onUpdateCursor?.(new Date().toISOString()), 5000);
    }
  }, [isScrolledToBottom, newMessagesCount, onUpdateCursor]);

  return (
    <Stack minHeight={0} height={'100%'}>
      <CommentsWrapper minHeight={'100%'}>
        {!isLoading ? (
          <>
            {comments && comments.length > 0 ? (
              <CommentsContainer newMessagesCount={newMessagesCount} ref={commentsContainerRef}>
                {comments.map(({ isVerticeUser, content, ...comment }, index) => (
                  <Fragment key={comment.commentId}>
                    <Comment
                      ref={(() => {
                        if (newMessageIndex && index === newMessageIndex - 1) {
                          return lastMessageBeforeNewMessagesRef;
                        }
                        if (newMessageIndex !== undefined && newMessageIndex >= 0 && index === comments.length - 1) {
                          return lastMessageRef;
                        }
                      })()}
                      variant={isVerticeUser ? 'branded' : 'default'}
                      content={commentContent(content)}
                      canEditComents={canEditComents}
                      editComment={toggleEditComment}
                      isEditing={!!editedComment?.id && editedComment.id === comment.messageId}
                      removeComment={onRemoveComment}
                      {...comment}
                    />
                    {newMessageIndex !== undefined && index === newMessageIndex - 1 && <NewMessageDivider />}
                  </Fragment>
                ))}
                {isSending && <CommentSkeleton />}
              </CommentsContainer>
            ) : (
              <CommentsContainer justifyContent="center" alignItems="center">
                <EmptyComments />
              </CommentsContainer>
            )}
          </>
        ) : (
          <CommentLoading />
        )}
        <Divider />
        <CommentInput
          currentUser={currentUser}
          users={users}
          onSend={handleSendComment}
          onEdit={handleEditComment}
          editedComment={editedComment}
          disabled={isLoading || isSending}
        />
      </CommentsWrapper>
    </Stack>
  );
};
