import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { ChangeEvent } from 'react';
import { FormControl } from '@mui/material';
import { ConcessionStatus } from '../constants';
import { ConcessionStatusType } from '../types';
import { DesignSystemColor } from '@verticeone/design-system/src';
import { Checkbox } from '@verticeone/design-system/src';

export type StatusCellProps = GridCellParams & {
  onStatusChange: (value: ConcessionStatusType) => void;
  color?: DesignSystemColor;
};

const StatusCell = ({ id, value, field, isEditable, onStatusChange, color = 'primary' }: StatusCellProps) => {
  const apiRef = useGridApiContext();

  const handleValueChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked; // The new value entered by the user
    const newStatus = checked ? ConcessionStatus.Realised : ConcessionStatus.InProgress;

    // Updating the status in read mode
    onStatusChange(newStatus);

    // Updating the status in the new row
    void apiRef.current.setEditCellValue({ id, field, value: newStatus });
  };

  return (
    <FormControl>
      <Checkbox
        color={color}
        checked={value === ConcessionStatus.Realised}
        onChange={handleValueChange}
        disabled={!isEditable}
      />
    </FormControl>
  );
};

export default StatusCell;
