import { orderBy } from 'lodash';
import { Point } from '../types';

export const DEFAULT_OTHERS_LABEL = 'Other';

/**
 * Limits overall number of items to the desired count. If necessary, adds "Other" item
 * with the sum of extraneous smallest ones. The "Other" is included in the `count`.
 */
const getLargestNPoints = <PointType extends Point>(
  points: PointType[],
  count: number,
  othersLabel = DEFAULT_OTHERS_LABEL,
  createOthersPoint: (p: Point) => PointType
): PointType[] => {
  const sortedPoints = orderBy(points, ['y', 'name'], ['desc', 'asc']);

  const included: PointType[] = [];
  let othersSum = 0;

  sortedPoints.forEach((point) => {
    if (point.name === othersLabel || included.length >= count) {
      othersSum += point.y ?? 0;
    } else {
      included.push(point);
    }
  });

  if (othersSum > 0) {
    if (included.length === count) {
      // Replace the last slot with the 'Other' category
      othersSum += included.pop()?.y ?? 0;
    }
    included.push(createOthersPoint({ name: othersLabel, y: othersSum }));
  }

  return included;
};

export const getLargestNPointsBasic = (points: Point[], count: number, othersLabel = DEFAULT_OTHERS_LABEL): Point[] =>
  getLargestNPoints(points, count, othersLabel, (p) => p);

export default getLargestNPoints;
