import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { testProps } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { IconButton } from '@verticeone/design-system/src';

import { TaskIcon } from '../../sharedVisualStyle/taskStyle';
import { TaskRow } from '../types';
import { resolveTaskNodeThumbnailConfiguration } from '../../workflowSchema/model/resolveTaskNodeThumbnailConfiguration';

type TaskDrawerHeadingProps = {
  taskRow: TaskRow;
  userId: string;
  onClose: () => void;
};

export const TaskDrawerHeading = ({ taskRow, userId, onClose }: TaskDrawerHeadingProps) => {
  const thumbnail = useMemo(() => resolveTaskNodeThumbnailConfiguration(taskRow?.configurations!), [taskRow]);

  return (
    <Stack gap={2} padding={6} direction="row" justifyContent="space-between" {...testProps('taskDrawerHeading')}>
      <Stack direction="row" alignItems="center" gap={2} height="100%">
        <TaskIcon
          userId={userId}
          status={taskRow.status}
          type={thumbnail.type}
          id={thumbnail.id}
          size="M"
          assigneeIds={taskRow.assignees.map((a) => a.id)}
        />
        <Text variant="heading" size="M" color="text1" {...testProps('taskName')}>
          {taskRow.name}
        </Text>
      </Stack>
      <IconButton size="M" variant="ghost" icon={CloseOutlined} onClick={onClose} />
    </Stack>
  );
};
