import { useTranslation } from 'react-i18next';

import { SlackIcon } from '@vertice/assets';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { NotificationsSetupCard } from '../NotificationsSetupCard';

type SlackSetupCardProps = {
  providerId: string;
};

export const SlackSetupCard = ({ providerId }: SlackSetupCardProps) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  return (
    <NotificationsSetupCard
      providerId={providerId}
      providerName={t('PREFERENCES.INTEGRATIONS.SLACK.TITLE')}
      providerIcon={<SlackIcon />}
      description={t('PREFERENCES.INTEGRATIONS.SLACK.DESCRIPTION')}
      wizardRoute={generatePath(routes.PREFERENCES.TAB.SLACK.ABSOLUTE_PATH, { activeTab: 'integrations' })}
    />
  );
};
