export const termStringifier = {
  and: (terms: (string | undefined)[]) => {
    if (terms.length === 0) {
      return '';
    }

    return `(${terms.filter((term) => term).join(' and ')})`;
  },

  or: (terms: (string | undefined)[]) => {
    if (terms.length === 0) {
      return '';
    }

    return `(${terms.filter((term) => term).join(' or ')})`;
  },

  eq: (fieldName: string, value?: string) => {
    if (!value) {
      return '';
    }

    return `${fieldName} = '${value}'`;
  },
};
