import React, { useEffect } from 'react';
import { Collapse, Stack } from '@mui/material';

import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { Loader } from '@verticeone/design-system/src';

import { useInstanceData } from './providers/InstanceProvider';

type ExpandableRowProps = {
  children: React.ReactNode;
};

const ExpandableRow = ({ children }: ExpandableRowProps) => {
  const { detail, reservations } = useInstanceData();
  const { item } = useInstanceData();

  useEffect(() => {
    if (detail.isExpanded && reservations.isUninitialized) {
      reservations.fetch();
    }
  }, [detail, reservations]);

  if (!detail.isExpanded || item.missingRiLookup) {
    return null;
  }

  if (reservations.isFetching) {
    return (
      <Stack paddingBottom={6}>
        <Loader color={AWS_BRAND_COLOR} />
      </Stack>
    );
  }

  return (
    <Collapse in={detail.isExpanded} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  );
};

export default ExpandableRow;
