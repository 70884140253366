import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogText,
  DialogTextWrapper,
} from '@verticeone/design-system/src/components/Dialog';
import { Button } from '@verticeone/design-system/src';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  primaryButtonAction?: () => void;
  primaryButtonText: string;
  primaryButtonLoading?: boolean;
  secondaryButtonAction?: () => void;
  secondaryButtonText?: string;
  headerText?: string;
  bodyPrimaryText?: string;
  bodySecondaryText?: string;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  primaryButtonAction,
  primaryButtonText,
  primaryButtonLoading,
  secondaryButtonAction,
  secondaryButtonText,
  headerText,
  bodyPrimaryText,
  bodySecondaryText,
}) => (
  <Dialog open={isOpen} align="center" size="M">
    <DialogHeader>{headerText}</DialogHeader>
    <DialogContent>
      <DialogTextWrapper>
        {bodyPrimaryText && <DialogText variant="body-bold">{bodyPrimaryText}</DialogText>}
        <DialogText variant="body-regular">{bodySecondaryText}</DialogText>
      </DialogTextWrapper>
    </DialogContent>
    <DialogActions>
      {secondaryButtonText && secondaryButtonAction && (
        <Button onClick={secondaryButtonAction} variant="outline">
          {secondaryButtonText}
        </Button>
      )}
      <Button onClick={primaryButtonAction} variant="solid" isLoading={primaryButtonLoading}>
        {primaryButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
