import React from 'react';
import { InputLabel, Typography } from '@mui/material';
import styles from './Label.module.scss';

interface LabelProps {
  text: string;
  htmlFor?: string;
  required?: boolean;
  disabled?: boolean;
}

/** @deprecated use Design System's TextFieldCaption or Text with caption/label variant. */
const Label: React.FC<LabelProps> = ({ text, htmlFor = '', required = false, disabled = false }) => (
  <InputLabel className={styles.label} shrink required={required} htmlFor={htmlFor} disabled={disabled}>
    <Typography variant="label-s">{text}</Typography>
  </InputLabel>
);

export default Label;
