import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';
import {
  integrationsAPICodegen,
  ListSlackChannelsApiArg,
  ListSlackChannelsApiResponse,
  ListSlackUserIdentitiesApiArg,
  ListSlackUserIdentitiesApiResponse,
  ListSlackUsersApiArg,
  ListSlackUsersApiResponse,
  PageToken,
  SlackChannel,
  SlackUser,
  UserIdentity,
} from '../openapi/codegen/integrationsAPI';

const TAG_TYPE = 'Integrations';
const INTEGRATIONS_SLACK_CONNECTION_TAG = 'INTEGRATIONS_SLACK_CONNECTION_TAG';

type AllTagType = typeof TAG_TYPE | typeof INTEGRATIONS_SLACK_CONNECTION_TAG;

export const enhancedIntegrationsAPI = integrationsAPICodegen
  .enhanceEndpoints<AllTagType>({
    addTagTypes: [TAG_TYPE, INTEGRATIONS_SLACK_CONNECTION_TAG],
    endpoints: {
      getSupportedIntegrations: {
        providesTags: [TAG_TYPE],
      },
      getAccountIntegrations: {
        providesTags: [TAG_TYPE],
      },
      getAccountIntegration: {
        providesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      connectAccountIntegration: {
        invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      configureAccountIntegration: {
        invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      deactivateAccountIntegration: {
        invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      revokeAccountIntegration: {
        invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      testAccountIntegration: {
        invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      activateAccountIntegration: {
        invalidatesTags: (result, error, arg) => [{ type: TAG_TYPE, id: `${arg.accountId}/${arg.integrationId}` }],
      },
      adminConnectUserIdentity: {
        invalidatesTags: [INTEGRATIONS_SLACK_CONNECTION_TAG],
      },
      revokeUserAuthorization: {
        invalidatesTags: [INTEGRATIONS_SLACK_CONNECTION_TAG],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listSlackUsersPaginated: build.query<ListSlackUsersApiResponse, ListSlackUsersApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListSlackUsersApiResponse,
            ListSlackUsersApiArg & { nextToken?: PageToken },
            SlackUser
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/integrations/accounts/${args.accountId}/slack/discovery/slackUsers`,
                params: {
                  pageToken: args.nextToken,
                  pageSize: args.pageSize,
                  status: args.status,
                },
              });
              return { data: queryResponse.data as ListSlackUsersApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.items || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [],
      }),
      listSlackUserIdentitiesPaginated: build.query<ListSlackUserIdentitiesApiResponse, ListSlackUserIdentitiesApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListSlackUserIdentitiesApiResponse,
            ListSlackUserIdentitiesApiArg & { nextToken?: PageToken },
            UserIdentity
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/integrations/accounts/${args.accountId}/slack/users`,
                params: {
                  pageToken: args.nextToken,
                  pageSize: args.pageSize,
                  status: args.status,
                },
              });
              return { data: queryResponse.data as ListSlackUserIdentitiesApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.items || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [INTEGRATIONS_SLACK_CONNECTION_TAG],
      }),
      listSlackChannelsPaginated: build.query<ListSlackChannelsApiResponse, ListSlackChannelsApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListSlackChannelsApiResponse,
            ListSlackChannelsApiArg & { nextToken?: PageToken },
            SlackChannel
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/integrations/accounts/${args.accountId}/slack/channels`,
                params: {
                  pageToken: args.nextToken,
                  pageSize: args.pageSize,
                  types: args.types,
                },
              });
              return { data: queryResponse.data as ListSlackChannelsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.items || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [],
      }),
    }),
  });

export const {
  useListSlackUsersPaginatedQuery,
  useListSlackUserIdentitiesPaginatedQuery,
  useListSlackChannelsPaginatedQuery,
} = enhancedIntegrationsAPI;
