import { createContext, ReactNode, useContext, useState } from 'react';
import { Shadows } from '@mui/material/styles/shadows';
import { createTheme as createMuiTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { components, breakpoints, variants, typography } from './config';

import type { ThemeVariant } from './types';
import { THEME_STORE_KEY } from './constants';
import '../translate';

export type ThemeProviderProps = {
  children: ReactNode;
  locale: string;
  preselectedTheme?: ThemeVariant;
};

type ThemeContextType = {
  theme: ThemeVariant;
  setTheme: (theme: ThemeVariant) => void;
  locale: string;
};

export const createTheme = (name: ThemeVariant) => {
  const themeConfig = variants[name];

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      components,
      typography,
      shadows: ['none', ...Array(24).fill(`box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);`)] as Shadows,
      palette: themeConfig.palette,
    },
    {
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

function ThemeProvider({ children, locale, preselectedTheme = 'default' }: ThemeProviderProps) {
  const storedTheme = localStorage.getItem(THEME_STORE_KEY);
  const initTheme: ThemeVariant = storedTheme ? JSON.parse(storedTheme) : preselectedTheme;
  const [theme, setTheme] = useState<ThemeVariant>(initTheme);

  const changeTheme = (variant: ThemeVariant) => {
    localStorage.setItem('theme', JSON.stringify(variant));
    setTheme(variant);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: changeTheme, locale }}>
      <MuiThemeProvider theme={createTheme(theme)}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider component');
  }
  return context;
};

function InvertedThemeProvider({ children }: { children: ReactNode }) {
  const { theme, setTheme, locale } = useThemeContext();
  const invertedTheme: ThemeVariant = theme === 'dark' ? 'light' : 'dark';
  return (
    <ThemeContext.Provider value={{ theme: invertedTheme, setTheme, locale }}>
      <MuiThemeProvider theme={createTheme(invertedTheme)}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, useThemeContext, InvertedThemeProvider };
