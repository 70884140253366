import { FC, useState } from 'react';
import { Stack } from '@mui/material';

import { Drawer } from '@verticeone/design-system/src';
import { Divider } from '@verticeone/design-system/src';

import { TaskRow } from '../types';
import { TaskDrawerContent } from './TaskDrawerContent';
import { TaskDrawerActions } from './TaskDrawerActions';
import { useGetTaskOverviewQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { TaskContextProvider } from './TaskContext';
import { useLoggedUser } from '@verticeone/auth/src';
import { TaskDrawerHeading } from './TaskDrawerHeading';

type TaskDrawerProps = {
  taskRow?: TaskRow;
  onClose: () => void;
};

const DRAWER_WIDTH = '900px';

export const TaskDrawer: FC<TaskDrawerProps> = ({ taskRow, onClose }) => {
  const { userId } = useLoggedUser();
  const [open, setOpen] = useState(true);
  const { accountId } = useAccountContext();

  const { data: taskOverview } = useGetTaskOverviewQuery({ accountId, taskId: taskRow?.id! }, { skip: !taskRow });

  if (taskRow === undefined) {
    return null;
  }

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
      // Hack to get the drawer to transition properly and not get stuck in a weird state
      setOpen(true);
    }, 200);
  };

  return (
    <TaskContextProvider task={taskRow} taskOverview={taskOverview}>
      <Drawer open={open} size="S" width={DRAWER_WIDTH} onClose={handleClose}>
        <Stack minHeight={0} height={'100vh'} overflow={'hidden'}>
          <TaskDrawerHeading taskRow={taskRow} userId={userId} onClose={handleClose} />
          <Divider />
          <Stack minHeight={0} flexShrink={0} gap={6} paddingTop={6} justifyContent="space-between">
            <TaskDrawerContent />
            <Divider />
          </Stack>
          <TaskDrawerActions key={taskRow.id} onSubmit={handleClose} />
        </Stack>
      </Drawer>
    </TaskContextProvider>
  );
};
