import { useCallback } from 'react';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import type { SeriesAreaOptions } from 'highcharts';

export type getDiscountedUsage = {
  data: Array<[number, number | undefined]>;
};

export const DISCOUNTED_USAGE_ID = 'discounted_usage';

const useDiscountedUsage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return useCallback(
    ({ data }: getDiscountedUsage): SeriesAreaOptions => {
      return {
        id: DISCOUNTED_USAGE_ID,
        name: t('CLOUD.RESERVED_INSTANCES.DISCOUNTED_USAGE'),
        type: 'area',
        data,
        zIndex: 1,
        fillOpacity: 0.2,
        color: rgba(palette.success.color2, 0.2),
      };
    },
    [palette, t]
  );
};

export default useDiscountedUsage;
