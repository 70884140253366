export const starLegendIconGeometry = {
  width: 15,
  height: 14,
  d: 'M6.56215 1.03451C6.88431 0.16389 8.11569 0.163887 8.43785 1.03451L9.6362 4.27299C9.73748 4.54671 9.95329 4.76252 10.227 4.8638L13.4655 6.06215C14.3361 6.38431 14.3361 7.61569 13.4655 7.93785L10.227 9.1362C9.95329 9.23748 9.73748 9.45329 9.6362 9.72701L8.43785 12.9655C8.11569 13.8361 6.88431 13.8361 6.56215 12.9655L5.3638 9.72701C5.26252 9.45329 5.04671 9.23748 4.77299 9.1362L1.53451 7.93785C0.66389 7.61569 0.663887 6.38431 1.53451 6.06215L4.77299 4.8638C5.04671 4.76252 5.26252 4.54671 5.3638 4.27299L6.56215 1.03451Z',
};

export const StarLegendIcon = ({ fill }: { fill?: string }) => (
  <svg
    width={starLegendIconGeometry.width}
    height={starLegendIconGeometry.height}
    viewBox={`0 0 ${starLegendIconGeometry.width} ${starLegendIconGeometry.height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={starLegendIconGeometry.d} fill={fill} />
  </svg>
);
