import React from 'react';
import { rgba } from 'polished';
import { TableCell, TableRow } from '@verticeone/design-system/src';
import { Stack, styled, tableCellClasses } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Tooltip, TooltipInfoTrigger } from '@verticeone/design-system/src';

type InstanceTableRowProps = {
  label: string;
  value: string;
  tooltip?: {
    title: string;
    content: string;
  };
  testId: string;
};

const StyledTooltipInfoTrigger = styled(TooltipInfoTrigger)(({ theme }) => ({
  color: rgba(theme.palette.text.color2, 0.4),
}));

const InstanceTableRow = ({ label, tooltip, value, testId }: InstanceTableRowProps) => (
  <TableRow
    testId={testId}
    key={label}
    sx={{ [`.${tableCellClasses.root}:not(:last-child)`]: { borderRight: 'none' } }}
  >
    <TableCell sx={{ height: '65px' }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Text variant="body-regular" size="M" testId="label">
          {label}
        </Text>
        {tooltip ? (
          <Tooltip {...tooltip} size="S">
            <StyledTooltipInfoTrigger />
          </Tooltip>
        ) : null}
      </Stack>
    </TableCell>
    <TableCell sx={{ height: '65px' }} align="right">
      <Text variant="body-bold" size="M" testId="value">
        {value}
      </Text>
    </TableCell>
  </TableRow>
);

export default InstanceTableRow;
