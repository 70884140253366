export const productToOSSWMap = {
  'Linux/UNIX': {
    operatingSystem: 'Linux',
    preInstalledSW: null,
  },
  'Linux with SQL Server Standard': {
    operatingSystem: 'Linux',
    preInstalledSW: 'SQL Std',
  },
  'Linux with SQL Server Web': {
    operatingSystem: 'Linux',
    preInstalledSW: 'SQL Web',
  },
  'Linux with SQL Server Enterprise': {
    operatingSystem: 'Linux',
    preInstalledSW: 'SQL Ent',
  },
  'SUSE Linux': {
    operatingSystem: 'SUSE',
    preInstalledSW: null,
  },
  'Red Hat Enterprise Linux': {
    operatingSystem: 'RHEL',
    preInstalledSW: null,
  },
  'RHEL with SQL Server Standard': {
    operatingSystem: 'RHEL',
    preInstalledSW: 'SQL Std',
  },
  'RHEL with SQL Server Enterprise': {
    operatingSystem: 'RHEL',
    preInstalledSW: 'SQL Ent',
  },
  'RHEL with SQL Server Web': {
    operatingSystem: 'RHEL',
    preInstalledSW: 'SQL Web',
  },
  'RHEL with HA': {
    operatingSystem: 'Red Hat Enterprise Linux with HA',
    preInstalledSW: null,
  },
  'RHEL with HA and SQL Server Standard': {
    operatingSystem: 'Red Hat Enterprise Linux with HA',
    preInstalledSW: 'SQL Std',
  },
  'RHEL with HA and SQL Server Enterprise': {
    operatingSystem: 'Red Hat Enterprise Linux with HA',
    preInstalledSW: 'SQL Ent',
  },
  'Ubuntu Pro': {
    operatingSystem: 'Ubuntu Pro',
    preInstalledSW: null,
  },
  Windows: {
    operatingSystem: 'Windows',
    preInstalledSW: null,
  },
  'Windows with SQL Server Standard': {
    operatingSystem: 'Windows',
    preInstalledSW: 'SQL Std',
  },
  'Windows with SQL Server Enterprise': {
    operatingSystem: 'Windows',
    preInstalledSW: 'SQL Ent',
  },
  'Windows with SQL Server Web': {
    operatingSystem: 'Windows',
    preInstalledSW: 'SQL Web',
  },
};

export type ProductType = keyof typeof productToOSSWMap;
