import { CommentTwoTone, MoreVertOutlined } from '@mui/icons-material';
import { Stack, useTheme, styled } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Tooltip } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { RequestRow } from '../../types';
import { IconButton } from '@verticeone/design-system/src';
import { Menu, MenuItem } from '@verticeone/design-system/src';
import { useRef, useState } from 'react';
import { ShowOnRowHover } from '../ShowOnRowHover';

export const UnreadMessagesBubble = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.color2,
  aspectRatio: '1 / 1',
  borderRadius: '50%',
  padding: theme.spacing(1),
  height: 20,
  width: 20,
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  top: '-25%',
  right: '-25%',
}));

type ActionCellsProps = {
  row: RequestRow;
};

type MenuItemT = {
  label: string;
  onClick: () => void;
};

export const ActionCells = ({ row }: ActionCellsProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const hasComments = row.comments;
  const newMessagesCount = row.comments?.unread;
  const displayComments = hasComments && newMessagesCount && newMessagesCount > 0;

  const menuItems: MenuItemT[] = []; // Currently empty as we don't have any actions for requests yet

  return (
    <Stack direction="row" gap={2}>
      <Tooltip
        disableHoverListener={!displayComments}
        title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.NEW_MESSAGE', { count: newMessagesCount })}
        placement="bottom"
        size="S"
        content={
          <Text variant="body-regular" size="S" color="text4">
            {t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.TOTAL', { count: row.comments?.total })}
          </Text>
        }
      >
        <Stack position="relative" height={28} width={28} marginTop={2.5} sx={{ opacity: displayComments ? 1 : 0 }}>
          <CommentTwoTone htmlColor={palette.core.color5} sx={{ height: 28, width: 28 }} />
          <UnreadMessagesBubble>
            <Text variant="button-bold" size="XS" color={palette.text.color5}>
              {newMessagesCount}
            </Text>
          </UnreadMessagesBubble>
        </Stack>
      </Tooltip>
      {/* Show kebab menu only on row hover and if it has items */}
      <ShowOnRowHover>
        <Stack sx={{ opacity: menuItems.length === 0 ? 0 : 1 }} onClick={(e) => e.preventDefault()}>
          {/* onClick handler here is to disable the global link on the row */}
          <IconButton
            icon={MoreVertOutlined}
            color="neutral"
            variant="plain"
            ref={anchorRef}
            size="XL"
            disabled={menuItems.length === 0}
            onClick={() => {
              setIsMenuOpen((val) => !val);
            }}
          />
          <Menu
            open={isMenuOpen}
            anchorEl={anchorRef.current}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            onClose={() => setIsMenuOpen(false)}
          >
            {menuItems.map(({ label, onClick, ...rest }) => (
              <MenuItem key={label} onClick={onClick} {...rest}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </ShowOnRowHover>
    </Stack>
  );
};
