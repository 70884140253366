import {
  GridApiPro,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { MutableRefObject, SyntheticEvent, useEffect, useState } from 'react';
import { ActionsCell } from './ActionsCell';
import { EditabilityMode } from './types';
import { EditableDataGridContextType } from './EditableDataGridContext';
import { DataGridProps } from '@verticeone/design-system/src';
import { GridValidRowModel } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { AddItemFooter, AddItemFooterProps } from './AddItemFooter';
import { DeepPartial } from 'react-hook-form';
import { DesignSystemColor } from '@verticeone/design-system/src';
import { mapValues } from 'lodash';

export type UseEditableDataGridParams<R> = {
  apiRef: MutableRefObject<GridApiPro>;
  editMode?: boolean;
  defaultMode: EditabilityMode;
  validateRow?: (row: R) => { isValid: true; row?: R } | { isValid: false; message: string };
  isRowEditable?: (row: R) => boolean;
  isRowDeletable?: (row: R) => boolean;
  onErrorsChanged?: (errors: string[]) => void;
  onAddRow?: (row: R) => void;
  onUpdateRow?: (row: R) => void;
  onBeforeDeleteRow?: (row: R) => Promise<boolean>;
  onDeleteRow?: (id: GridRowId) => void;

  // Add Button props
  withAddButton?: boolean;
  addItemButtonLabel?: string;
  color?: DesignSystemColor;
  createTmpAddRow?: (id: string) => DeepPartial<R>;
};

export type UseEditableDataGridReturn<R extends GridValidRowModel> = {
  dataGridProps: Partial<DataGridProps<R>>;
  context: EditableDataGridContextType;
  actionsColumn?: GridColDef;
  tmpAddRow: null | R;
};

export const useEditableDataGrid = <R extends GridValidRowModel>({
  defaultMode,
  onErrorsChanged,
  apiRef,
  editMode = true,
  validateRow,
  isRowEditable,
  isRowDeletable,
  onAddRow,
  onUpdateRow,
  onBeforeDeleteRow = () => Promise.resolve(true),
  onDeleteRow,
  createTmpAddRow,
  withAddButton,
  addItemButtonLabel,
  color,
}: UseEditableDataGridParams<R>): UseEditableDataGridReturn<R> => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [tmpAddRow, setTmpAddRow] = useState<R | null>(null);

  useEffect(() => {
    if (!editMode) {
      setRowModesModel((prev) =>
        mapValues(prev, () => {
          return { mode: GridRowModes.View, ignoreModifications: true };
        })
      );
      setTmpAddRow(null);
    }
  }, [editMode]);

  const setRowMode = (id: GridRowId, mode: GridRowModes, flags?: 'ignoreModifications') => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode, ignoreModifications: flags === 'ignoreModifications' },
    }));
  };

  const removeRowMode = (id: GridRowId) => {
    setRowModesModel((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  };

  // OVERRIDES OF THE DEFAULT BEHAVIORS

  const handleBuiltinRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleBuiltinRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // ROW ACTIONS + VALIDATION + SAVING

  const handleRowEditClick = (id: GridRowId) => {
    setRowMode(id, GridRowModes.Edit);
  };

  const handleRowCancelClick = (id: GridRowId) => {
    const tmpRowId = tmpAddRow ? apiRef.current.getRowId(tmpAddRow) : null;
    if (tmpRowId === id) {
      removeRowMode(id);
      setTmpAddRow(null);
    } else {
      setRowMode(id, GridRowModes.View, 'ignoreModifications');
    }
  };

  const handleRowDeleteClick = async (id: GridRowId) => {
    setRowMode(id, GridRowModes.View);
    const row = apiRef.current.getRow(id);
    const deleteAllowed = await onBeforeDeleteRow(row);
    if (deleteAllowed) {
      onDeleteRow?.(id);
    }
  };

  const handleRowSaveClick = (id: GridRowId) => {
    const updatedRow = apiRef.current.getRowWithUpdatedValues(id, '' /* not necessary in row-editing mode */);

    if (validateRow) {
      const validationResult = validateRow(updatedRow as R);
      if (!validationResult.isValid) {
        onErrorsChanged?.([validationResult.message]);
        return;
      } else {
        onErrorsChanged?.([]);
        setRowMode(id, GridRowModes.View);
      }
    } else {
      setRowMode(id, GridRowModes.View);
    }

    // The saving process then continues with the processRowUpdateOrCreate function below.
  };

  const processRowUpdateOrCreate = (updatedRow: R) => {
    // Validator can be used to refine the result as well...
    let effectiveUpdatedRow = updatedRow;

    if (validateRow) {
      const validationResult = validateRow(updatedRow as R);
      if (validationResult.isValid && validationResult.row) {
        effectiveUpdatedRow = validationResult.row;
      }
    }

    const effectiveUpdatedRowId = apiRef.current.getRowId(effectiveUpdatedRow);
    const tmpRowId = tmpAddRow ? apiRef.current.getRowId(tmpAddRow) : null;

    setTimeout(() => {
      if (effectiveUpdatedRowId === tmpRowId) {
        onAddRow?.(effectiveUpdatedRow);
        setTmpAddRow(null);
      } else {
        onUpdateRow?.(effectiveUpdatedRow);
      }
    });

    return effectiveUpdatedRow;
  };

  const handleAddRowClick = () => {
    const tmpRowId = randomId();
    setTmpAddRow(createTmpAddRow?.(tmpRowId) as R);
    setRowMode(tmpRowId, GridRowModes.Edit);
  };

  const actionsColumn: GridColDef = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => <ActionsCell {...params} rowModesModel={rowModesModel} />,
    width: 84,
    align: 'center',
  };

  return {
    dataGridProps: {
      editMode: 'row',
      isCellEditable: () => true,
      disableRowSelectionOnClick: true,
      onRowEditStart: handleBuiltinRowEditStart,
      onRowEditStop: handleBuiltinRowEditStop,
      rowModesModel,
      processRowUpdate: processRowUpdateOrCreate,
      slots: {
        footer: withAddButton && editMode ? AddItemFooter : undefined,
      },
      slotProps: {
        footer:
          withAddButton && editMode
            ? ({
                label: addItemButtonLabel,
                onClick: handleAddRowClick,
                disabled: tmpAddRow !== null,
                color,
              } as AddItemFooterProps)
            : undefined,
      },
      hideFooter: withAddButton && editMode ? false : undefined,
    },
    context: {
      mode: defaultMode,
      apiRef,
      setRowMode,
      handleRowEditClick,
      handleRowCancelClick,
      handleRowSaveClick,
      handleRowDeleteClick,
      isRowEditable: isRowEditable as (row: unknown) => boolean,
      isRowDeletable: isRowDeletable as (row: unknown) => boolean,
    },
    actionsColumn: editMode ? actionsColumn : undefined,
    tmpAddRow: editMode ? tmpAddRow : null,
  };
};
