import { userAPICodegen } from '@vertice/slices/src/openapi/codegen/userAPI';

export const enhancedUserAPI = userAPICodegen.enhanceEndpoints({
  endpoints: {
    updateUserSettings: {
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const result = await queryFulfilled;
        dispatch(userAPICodegen.util.updateQueryData('getUserSettings', { userId: arg.userId }, () => result.data));
      },
    },
    adminUpdateUserSettings: {
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const result = await queryFulfilled;
        dispatch(userAPICodegen.util.updateQueryData('getUserSettings', { userId: arg.userId }, () => result.data));
      },
    },
  },
});
