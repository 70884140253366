import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogText,
} from '@verticeone/design-system/src/components/Dialog';
import { Button } from '@verticeone/design-system/src';

type SlackDialogProps = {
  header: string;
  contentText: string;
  buttonLabel: string;
  isOpened: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

export const SlackDialog = ({
  header,
  contentText,
  buttonLabel,
  isOpened,
  isLoading,
  onSubmit,
  onClose,
}: SlackDialogProps) => {
  return (
    <Dialog size="M" open={isOpened} setOpen={onClose}>
      <DialogHeader>{header}</DialogHeader>
      <DialogContent>
        <DialogText variant="body-regular">{contentText}</DialogText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} isLoading={isLoading} variant="solid">
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
