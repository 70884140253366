import React, { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { differenceInDays, parseISO } from 'date-fns';

type Granularity = 'day' | 'week' | 'month';

export type Node = {
  search?: string;
  label: string;
  id: string;
  type: 'root' | 'account' | 'service';
  loadingHeight?: string;
  children?: Node | null;
};

export type NavigationNode = {
  accounts: Node;
  tags: Node;
  [key: string]: Node;
};

export type Period = {
  periodOneStart: string;
  periodOneEnd: string;
  periodTwoStart: string;
  periodTwoEnd: string;
};

type FilterValue = string | string[] | number | null;

type CloudAnalyticsState = {
  node: NavigationNode;
  period: Period;
  chartDataLimit: number;
  updatePeriod: (newPeriod: Period) => void;
  removeNode: (node: Node) => void;
  addChildNode: (node: Node) => void;
  getLastNode: () => Node;
  setSearch: (search: string) => void;
  filter: { [key: string]: FilterValue };
  setFilter: (key: string, value: FilterValue) => void;
  granularity: Granularity;
};

const CloudAnalyticsContext = createContext<CloudAnalyticsState>({
  period: {
    periodOneStart: '',
    periodOneEnd: '',
    periodTwoStart: '',
    periodTwoEnd: '',
  },
  chartDataLimit: 0,
  updatePeriod: () => {},
  node: {
    accounts: {
      label: '',
      id: 'root',
    },
  } as NavigationNode,
  removeNode: () => {},
  addChildNode: () => {},
  setSearch: () => {},
  getLastNode: () => {
    throw new Error('getLastNode must be implemented');
  },
  filter: {},
  setFilter: () => {},
  granularity: 'day',
});

type CloudAnalyticsProviderProps = {
  children: React.ReactNode;
  period: CloudAnalyticsState['period'];
};

export const CloudAnalyticsProvider = ({ children, period }: CloudAnalyticsProviderProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const { activeTab } = useParams();
  const activeTabId = activeTab as keyof NavigationNode;

  const [node, setNode] = useState<NavigationNode>({
    accounts: {
      label: t('BREADCRUMB.ACCOUNTS'),
      id: 'root',
      type: 'root',
    },
    tags: {
      label: t('BREADCRUMB.TAGS'),
      id: 'root',
      type: 'root',
    },
  });
  const [localPeriod, setLocalPeriod] = useState(period);
  const [filter, setFilter] = useState<{ [key: string]: FilterValue }>({});

  const removeNode = (remove: Node) => {
    const removeNodeFromLastNode = (currentNode: Node, nodeToRemove: Node): Node => {
      if (!currentNode.children) {
        return currentNode;
      }
      if (currentNode.children.id === nodeToRemove.id) {
        return { ...currentNode, children: undefined };
      }
      return { ...currentNode, children: removeNodeFromLastNode(currentNode.children, nodeToRemove) };
    };

    setNode((prevNode) => {
      if (!prevNode) {
        return prevNode;
      }
      return {
        ...prevNode,
        [activeTabId]: removeNodeFromLastNode(prevNode[activeTabId], remove),
      };
    });
  };

  const addChildNode = (newChildNode: Node) => {
    const addChildToLastNode = (currentNode: Node, newNode: Node): Node => {
      if (!currentNode.children) {
        return { ...currentNode, children: newNode };
      } else {
        return { ...currentNode, children: addChildToLastNode(currentNode.children, newNode) };
      }
    };

    setNode((prevNode) => {
      return {
        ...prevNode,
        [activeTabId]: addChildToLastNode(prevNode[activeTabId], newChildNode),
      };
    });
  };

  const getLastNode = (): Node => {
    const findLastNode = (item: Node): Node => {
      if (!item.children) {
        return item;
      }
      return findLastNode(item.children);
    };

    return findLastNode(node[activeTabId]);
  };

  const setSearch = (search: string) => {
    setNode((currentNode) => {
      const updateSearchInLastNode = (nodeToUpdate: Node): Node => {
        if (!nodeToUpdate.children) {
          return { ...nodeToUpdate, search };
        }
        return { ...nodeToUpdate, children: updateSearchInLastNode(nodeToUpdate.children) };
      };

      if (currentNode) {
        return {
          ...currentNode,
          [activeTabId]: updateSearchInLastNode(currentNode[activeTabId]),
        };
      }
      return currentNode;
    });
  };

  const updatePeriod = (newPeriod: CloudAnalyticsState['period']) => {
    setLocalPeriod((prevPeriod) => ({
      ...prevPeriod,
      ...newPeriod,
    }));
    setFilter({});
  };

  const granularity = useMemo((): Granularity => {
    const { periodOneStart, periodOneEnd } = localPeriod;
    const start = parseISO(periodOneStart);
    const end = parseISO(periodOneEnd);
    const daysDifference = differenceInDays(end, start);

    if (daysDifference <= 30) {
      return 'day';
    } else if (daysDifference <= 90) {
      return 'week';
    } else {
      return 'month';
    }
  }, [localPeriod]);

  return (
    <CloudAnalyticsContext.Provider
      value={{
        period: localPeriod,
        chartDataLimit: 2 * 365, // chartDataLimit
        updatePeriod,
        node,
        removeNode,
        addChildNode,
        setSearch,
        getLastNode,
        filter,
        setFilter: (key: string, value: string | Array<string> | number | null) => {
          setFilter((prevFilters) => ({
            ...prevFilters,
            [key]: value,
          }));
        },
        granularity,
      }}
    >
      {children}
    </CloudAnalyticsContext.Provider>
  );
};

export const useCloudAnalytics = () => useContext(CloudAnalyticsContext);
