import { ReactNode } from 'react';
import { styled } from '@mui/material';

import { Text } from '../../../Text';
import {
  BaseFilterDropdown,
  BaseDropdownList,
  BaseExpandIcon,
  BaseDropdownHeader,
  DropdownSelectedCount,
} from '../BaseFilterDropdown';

type FilterDropdownProps = {
  title: string;
  id: string;
  children: ReactNode;
  showAll?: boolean;
  defaultShowItemCount?: number;
};

const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(4)} 0`,
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  cursor: 'pointer',
}));

export const Header = ({ title, children, id, showAll, defaultShowItemCount }: FilterDropdownProps) => (
  <BaseFilterDropdown id={id} label={title}>
    <BaseDropdownHeader>
      <StyledHeaderWrapper>
        <Text variant="heading" size="XS" color="text1">
          {title}
        </Text>
        <DropdownSelectedCount>
          <BaseExpandIcon />
        </DropdownSelectedCount>
      </StyledHeaderWrapper>
    </BaseDropdownHeader>
    <BaseDropdownList showAll={showAll} defaultShowItemCount={defaultShowItemCount}>
      {children}
    </BaseDropdownList>
  </BaseFilterDropdown>
);
