import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import { VendorLegalInfo } from '@vertice/slices';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import AccordionLegal from './AccordionLegal';
import CertificationItem from './CertificationItem';
import FileItem from './FileItem';
import styles from './VendorDiligenceInsights.module.scss';
import { InsightLink, useLinksDataModelCompliance } from './useLinksDataModel';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AICPA2Icon, AICPA3Icon, ISOIcon } from '@vertice/assets';
import { Loader } from 'react-feather';
import BeAwareParagraph from './BeAwareParagraph';

interface VendorComplianceProps {
  isVendorLegalDataLoading: boolean;
  vendorLegalData?: VendorLegalInfo;
}

const VendorCompliance: FC<VendorComplianceProps> = ({ isVendorLegalDataLoading, vendorLegalData = {} }) => {
  const { t } = useTranslation();
  const linksDataModel = useLinksDataModelCompliance(vendorLegalData);

  const documentsAndLinks = Object.keys(linksDataModel).filter((item) =>
    linksDataModel[item].some((data: InsightLink) => data.link)
  );

  const documentsAndLinkLabels = documentsAndLinks.map((item) => t('LEGAL_INFO.DOCUMENTS_AND_LINKS.' + item));

  const { iso27001Url, soc2Url, soc3Url } = vendorLegalData;

  const vendorLegalOptions = [
    { link: iso27001Url, icon: <ISOIcon /> },
    { link: soc2Url, icon: <AICPA2Icon /> },
    { link: soc3Url, icon: <AICPA3Icon /> },
  ].filter((v) => v.link);

  return (
    <div className={styles.content}>
      <div className={styles.body}>
        {!isVendorLegalDataLoading && vendorLegalData ? (
          <>
            {!!(documentsAndLinks.length || vendorLegalOptions.length) && (
              <Stack gap="16px">
                <Typography variant="caption-s" className={styles['sub-header']}>
                  <FilePresentOutlinedIcon fontSize="inherit" />
                  {t('LEGAL_INFO.DOCUMENTATION_AND_LINKS')}
                </Typography>

                <Stack className={styles['accordion-list']}>
                  {documentsAndLinks.map((item, i) => (
                    <AccordionLegal
                      key={`documentation-and-links-${i}`}
                      ariaControls={`documentation-and-links${i}-content`}
                      accordionSummaryId={`documentation-and-links${i}-header`}
                      title={documentsAndLinkLabels[i]}
                      accordionDetails={
                        <div className={styles['chip-wrap-panel']}>
                          {linksDataModel[item]
                            .filter((data: InsightLink) => data.link)
                            .map((data: InsightLink) => (
                              <FileItem title={data.label} link={data.link!} key={randomId()} />
                            ))}
                        </div>
                      }
                    />
                  ))}

                  {!!vendorLegalOptions.length && (
                    <AccordionLegal
                      key="certifications"
                      ariaControls="certifications-content"
                      accordionSummaryId="certifications-headerr"
                      title={t('LEGAL_INFO.DOCUMENTS_AND_LINKS.CERTIFICATIONS')}
                      accordionDetails={
                        <div className={styles['icon-wrap-panel']}>
                          {vendorLegalOptions.map(({ link, icon }) => (
                            <CertificationItem {...{ link: link!, icon, key: randomId() }} />
                          ))}
                        </div>
                      }
                    />
                  )}
                </Stack>
              </Stack>
            )}
            <BeAwareParagraph />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default VendorCompliance;
