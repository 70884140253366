import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

export const useLocaleDateFormatter = () => {
  const { locale } = useLocaleContext();

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};
