import { FormState } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

const getPropsForSubmitButton = <FormDataType extends FieldValues = never>(
  { isSubmitting, isDirty, isValidating, isValid }: FormState<FormDataType>,
  { disableWhenFormUntouched = true }: { disableWhenFormUntouched?: boolean } = {}
) => ({
  disabled: isSubmitting || (disableWhenFormUntouched && !isDirty) || isValidating || !isValid,
  isLoading: isSubmitting,
});

export default getPropsForSubmitButton;
