import React from 'react';
import { API_URLS } from '@vertice/slices/src/constants';
import { NoVendorLogo } from '@vertice/assets/icons';
import { styled } from '@mui/material';

type VendorLogoProps = {
  vendorId?: string;
  size?: number;
};

const VendorLogoWrapper = styled('div')<Pick<VendorLogoProps, 'size'>>(({ size }) => ({
  width: size ? `${size}px` : '25px',
  height: size ? `${size}px` : '25px',
}));

const StyledNoVendorLogo = styled(NoVendorLogo)({
  width: '100%',
  height: '100%',
});

const StyledSvg = styled('svg')({
  width: '100%',
  height: '100%',
  borderRadius: '0px',
  overflow: 'hidden',
});

const StyledImage = styled('image')({
  width: '100%',
});

const VendorLogoInner = ({ vendorId }: Pick<VendorLogoProps, 'vendorId'>) => {
  if (!vendorId) {
    return <StyledNoVendorLogo />;
  }

  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <StyledImage href={`${API_URLS.LOGOS_URL}/${vendorId}.svg`} />
    </StyledSvg>
  );
};

/** @deprecated use VendorLogo from core */
const VendorLogo = ({ vendorId, ...otherProps }: VendorLogoProps) => (
  <VendorLogoWrapper {...otherProps}>
    <VendorLogoInner vendorId={vendorId} />
  </VendorLogoWrapper>
);

export default VendorLogo;
