import { GRID_REORDER_COL_DEF, GridEditInputCell, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { ChangeEvent } from 'react';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const EditableLabelCell = (params: GridRenderCellParams<CustomContractFieldDropdownOption, string | undefined>) => {
  const { id, field } = params;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    void apiRef.current.setEditCellValue({ id, field, value: newValue });
    void apiRef.current.setEditCellValue({ id, field: GRID_REORDER_COL_DEF.field, value: newValue });
  };

  return <GridEditInputCell {...params} onValueChange={handleValueChange} />;
};

export default EditableLabelCell;
