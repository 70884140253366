import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import {
  ForecastPrecisionType,
  TimeSeriesDataPoint,
  useUsageCostPerRecordTypeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useDeferredQuery } from '@verticeone/utils/api';
import {
  DATE_FORMAT,
  findFirstCurrency,
  sumPositiveDataPointValues,
  sumTimeSeriesValues,
} from '../utils/graphDataUtils';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { sumBy } from 'lodash';
import { AWS_DEFAULT_CURRENCY } from '../constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

// We want to calculate the annual spend for completed months only
// On any day of October 2023 the time range would be October 2022 - September 2023 (included)
const startOfCurrentMonth = startOfMonth(new Date());
const startDate = addMonths(startOfCurrentMonth, -12);

export type AnnualSpendData = {
  currency: string;
  annualGrossSpend: number;
  annualInvoicedSpend: number;
  monthlyGrossSpend: number;
  monthlyInvoicedSpend: number;
};

export const prepareAnnualSpendData = (dataPointsRaw: TimeSeriesDataPoint[]): AnnualSpendData => {
  const annualGrossSpend = sumBy(dataPointsRaw, (p) => sumPositiveDataPointValues(p));
  const annualInvoicedSpend = sumBy(dataPointsRaw, ({ values }) => Math.max(0, sumTimeSeriesValues(values)));

  return {
    annualGrossSpend,
    annualInvoicedSpend,
    monthlyGrossSpend: annualGrossSpend / 12,
    monthlyInvoicedSpend: annualInvoicedSpend / 12,
    currency: findFirstCurrency(dataPointsRaw) || AWS_DEFAULT_CURRENCY,
  };
};

const useAnnualSpendData = (): LoadableAdvanced<AnnualSpendData> => {
  const { accountId } = useAccountContext();
  const { data: dataPointsRaw, error } = useDeferredQuery(
    useUsageCostPerRecordTypeQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(startOfCurrentMonth, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const computed = useMemo(() => {
    if (!dataPointsRaw) return undefined;
    return prepareAnnualSpendData(dataPointsRaw);
  }, [dataPointsRaw]);

  return {
    error: error,
    isEmpty: Boolean(dataPointsRaw && dataPointsRaw.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useAnnualSpendData;
