import React from 'react';
import { EditOutlined } from '@mui/icons-material';
import { Button } from '@verticeone/design-system/src';
import { useContractGridContext } from '../ContractContext';
import { useTranslation } from 'react-i18next';

const EditContract = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.BUTTONS' });
  const { actions, isEditing } = useContractGridContext();

  if (isEditing) {
    return null;
  }

  return (
    <Button size="S" variant="ghost" color="tertiary" onClick={actions.startEdit}>
      {t('EDIT')}
      <EditOutlined />
    </Button>
  );
};

export default EditContract;
