import React, { FC } from 'react';
import * as z from 'zod';
import { t } from 'i18next';
import { url } from '@verticeone/utils/validation';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { GoodFormProps, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { Grid } from '@mui/material';
import { Button } from '@verticeone/design-system/src';

const connectionFormDataSchema = z.object({
  connectUrl: url(t),
  apiToken: z.string(), // Coerce automatically converts string to number
});

type ConnectionFormData = z.infer<typeof connectionFormDataSchema>;
const ConnectionFormEntry = createTypedFormEntry<ConnectionFormData>();

export type SetupConnectionFormProps = GoodFormProps<ConnectionFormData>;
export const SetupConnectionForm: FC<SetupConnectionFormProps> = ({ defaultValues, onSubmit }) => {
  const formMethods = useForm<ConnectionFormData>({
    defaultValues,
    mode: 'all', // Validate on change, blur, submit
    resolver: zodResolver(connectionFormDataSchema),
  });

  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={goodFormMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={8}>
          <ConnectionFormEntry
            name="connectUrl"
            label={t('PREFERENCES.INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.CONNECT_URL')}
            component={FormTextField}
            width={8}
          />
          <ConnectionFormEntry
            name="apiToken"
            label={t('PREFERENCES.INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.API_TOKEN')}
            component={FormTextField}
            width={8}
          />
          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              isLoading={submitButtonProps.isLoading}
              disabled={submitButtonProps.disabled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
