import { BillingFrequency, RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const ROLLING_FREQUENCIES: NonNullable<RollFrequency>[] = ['MONTHLY', 'QUARTERLY'];

export const BILLING_FREQUENCIES: NonNullable<BillingFrequency>[] = [
  'MONTHLY',
  'QUARTERLY',
  'BIANNUALLY',
  'ANNUALLY',
  'ONE_OFF',
  'OTHER',
];
