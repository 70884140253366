import React from 'react';
import { useTheme } from '@mui/material';
import {
  CloudAWSIcon,
  CloudCategoryOutlineAnalytics,
  CloudCategoryOutlineApplicationIntegration,
  CloudCategoryOutlineArchitectureStrategy,
  CloudCategoryOutlineBlockchain,
  CloudCategoryOutlineBusinessApplications,
  CloudCategoryOutlineCloudFinancialManagement,
  CloudCategoryOutlineCompute,
  CloudCategoryOutlineContainers,
  CloudCategoryOutlineDatabase,
  CloudCategoryOutlineSDK,
  CloudCategoryOutlineEndUserComputing,
  CloudCategoryOutlineFrontEndWebMobile,
  CloudCategoryOutlineIoT,
  CloudCategoryOutlineMachineLearning,
  CloudCategoryOutlineManagementGovernance,
  CloudCategoryOutlineMediaServices,
  CloudCategoryOutlineMigration,
  CloudCategoryOutlineNetworkingContentDelivery,
  CloudCategoryOutlineRobotics,
  CloudCategoryOutlineSatellite,
  CloudCategoryOutlineSecurityIdentityCompliance,
  CloudCategoryOutlineServerless,
  CloudCategoryOutlineStorage,
} from '@vertice/assets';
import { ItemType } from './useHierarchyGraphData';
import { AWS_BRAND_COLOR } from '../../constants';

type NodeIconProps = {
  type: ItemType;
  name?: string;
  selected?: boolean;
};

const getCategoryIcon = (name: string) => {
  switch (name) {
    case 'AR & VR':
      return CloudCategoryOutlineSDK;
    case 'Analytics':
      return CloudCategoryOutlineAnalytics;
    case 'Application Integration':
      return CloudCategoryOutlineApplicationIntegration;
    case 'Architecture Strategy':
      return CloudCategoryOutlineArchitectureStrategy;
    case 'Blockchain':
      return CloudCategoryOutlineBlockchain;
    case 'Business Applications':
      return CloudCategoryOutlineBusinessApplications;
    case 'Cloud Financial Management':
      return CloudCategoryOutlineCloudFinancialManagement;
    case 'Compute':
      return CloudCategoryOutlineCompute;
    case 'Containers':
      return CloudCategoryOutlineContainers;
    case 'Databases':
      return CloudCategoryOutlineDatabase;
    case 'Developer Tools':
      return CloudCategoryOutlineSDK;
    case 'End User Computing':
      return CloudCategoryOutlineEndUserComputing;
    case 'Front-End Web & Mobile':
      return CloudCategoryOutlineFrontEndWebMobile;
    case 'Game Tech':
      return CloudCategoryOutlineSDK;
    case 'Internet of Things':
      return CloudCategoryOutlineIoT;
    case 'Machine Learning':
      return CloudCategoryOutlineMachineLearning;
    case 'Management & Governance':
      return CloudCategoryOutlineManagementGovernance;
    case 'Media Services':
      return CloudCategoryOutlineMediaServices;
    case 'Migration':
      return CloudCategoryOutlineMigration;
    case 'Networking & Content Delivery':
      return CloudCategoryOutlineNetworkingContentDelivery;
    case 'Robotics':
      return CloudCategoryOutlineRobotics;
    case 'Satellite':
      return CloudCategoryOutlineSatellite;
    case 'Security, Identity & Compliance':
      return CloudCategoryOutlineSecurityIdentityCompliance;
    case 'Serverless':
      return CloudCategoryOutlineServerless;
    case 'Storage':
      return CloudCategoryOutlineStorage;
    default:
      return null;
  }
};

const NodeIcon = ({ type, name, selected }: NodeIconProps) => {
  const { palette } = useTheme();

  if (type === 'root') {
    return <CloudAWSIcon />;
  }

  if (type === 'category' && name) {
    const Icon = getCategoryIcon(name);
    if (Icon) {
      return <Icon fill={selected ? palette[AWS_BRAND_COLOR].color2 : palette.core.color5} width={24} height={24} />;
    }
  }

  return null;
};

export default NodeIcon;
