import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system/src';
import { UnifiedContractStage } from '../components/ContractList/types';

export type UseUnifiedStageOptionsReturn = SimpleOption[];

const useUnifiedStageOptions = (): UseUnifiedStageOptionsReturn => {
  const { t } = useTranslation();

  const unifiedStageOptions = useMemo(
    (): { value: UnifiedContractStage; label: string }[] => [
      { value: 'LIVE', label: t('ENTITIES.CONTRACT.STAGES_ON_DETAIL.LIVE') },
      { value: 'NOT_YET_LIVE', label: t('ENTITIES.CONTRACT.STAGES_ON_DETAIL.NOT_YET_LIVE') },
      { value: 'EXPIRED', label: t('ENTITIES.CONTRACT.STAGES_ON_DETAIL.EXPIRED') },
      { value: 'EXPIRED_PLANNED', label: t('ENTITIES.CONTRACT.STAGES_ON_DETAIL.EXPIRED_PLANNED') },
      { value: 'TERMINATED', label: t('ENTITIES.CONTRACT.STAGES_ON_DETAIL.TERMINATED') },
      { value: 'DRAFT', label: t('ENTITIES.CONTRACT.STAGES_ON_DETAIL.DRAFT') },
      { value: 'REQUIREMENTS_GATHERING', label: t('ENTITIES.CONTRACT.WORKFLOW_STAGES.REQUIREMENTS_GATHERING') },
      { value: 'NEGOTIATING', label: t('ENTITIES.CONTRACT.WORKFLOW_STAGES.NEGOTIATING') },
      { value: 'APPROVAL', label: t('ENTITIES.CONTRACT.WORKFLOW_STAGES.APPROVAL') },
      { value: 'CONTRACTING', label: t('ENTITIES.CONTRACT.WORKFLOW_STAGES.CONTRACTING') },
    ],
    [t]
  );

  return unifiedStageOptions;
};

export default useUnifiedStageOptions;
