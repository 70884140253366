import { SkeletonProps } from '@mui/material/Skeleton/Skeleton';
import { useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useDataGridContext } from '../DataGridContext';

const heightMap = {
  XL: '25px',
  L: '22.5px',
  M: '20.8px',
  S: '16.8px',
  XS: '16.2px',
  XXS: '13.5px',
};

export const GridCellSkeleton = ({
  variant = 'text',
  animation = 'wave',
  width = '50%',
  sx,
  ...otherProps
}: SkeletonProps) => {
  const { size } = useDataGridContext();
  const { palette } = useTheme();

  return (
    <Skeleton
      variant={variant}
      animation={animation}
      width={width}
      height={heightMap[size]}
      sx={{ backgroundColor: palette.core.color2, transform: 'none', ...sx }}
      {...otherProps}
    />
  );
};
