import { useNavigate } from 'react-router-dom';

import { useDeactivateAccountIntegrationMutation, useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { isValidIntegrationResponse, SLACK_PROVIDER_ID } from '../../common';

export const useDeactivateSlackIntegration = (setIsDisconnectDialogOpened: (value: boolean) => void) => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const deactivateSlackIntegration = async () => {
    const response = await deactivateIntegration({
      accountId,
      integrationId: SLACK_PROVIDER_ID,
    });

    if (isValidIntegrationResponse(response)) {
      await revokeIntegration({ accountId, integrationId: SLACK_PROVIDER_ID });
      setIsDisconnectDialogOpened(false);
      navigate(generatePath(routes.PREFERENCES.TAB.ABSOLUTE_PATH, { activeTab: 'integrations' }));
    }
  };

  return { deactivateSlackIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress };
};
