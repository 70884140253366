import { type DependencyList, useLayoutEffect, useState } from 'react';
import { Chart as HighchartsChart } from 'highcharts';

export const useChartPortalReposition = (chart: HighchartsChart | null, otherDeps: DependencyList = []) => {
  const [, rerender] = useState({});

  useLayoutEffect(() => {
    // Wait for initial Highchart render (possibly with empty div portal targets)
    if (chart) {
      // Force React render portals into Highchart
      rerender({});
      // Wait for React render to finish
      requestAnimationFrame(() => {
        // Position the React-rendered elements
        (chart as any).layOutTitles();
        chart.series.forEach((ser) => (ser as any).redraw());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, rerender, ...otherDeps]);
};
