import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types';
import { Atom, atom } from 'jotai';

/** Adapter that subscribes to react-hook-form values and turns them into a Jotai atom. */
const atomWithFormValues = <TFieldValues extends FieldValues>(
  hookForm: UseFormReturn<TFieldValues>
): Atom<TFieldValues> => {
  const formValuesAtom = atom(hookForm.getValues());
  formValuesAtom.onMount = (set) => {
    const { unsubscribe } = hookForm.watch(() => set(hookForm.getValues()));
    return unsubscribe;
  };
  return formValuesAtom;
};

export default atomWithFormValues;
