import React from 'react';
import { Stack, TableCellProps, useTheme } from '@mui/material';
import { TableCell } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { Tooltip, TooltipInfoTrigger } from '@verticeone/design-system/src';
import styled from '@mui/material/styles/styled';

type DetailPanelCellProps = {
  label: string;
  children: React.ReactNode;
  testId: string;
  tooltip: {
    title: string;
    content: string;
  };
} & Omit<TableCellProps, 'children' | 'testId' | 'size'>;

const StyledTooltipInfoTrigger = styled(TooltipInfoTrigger)(({ theme }) => ({
  color: theme.palette.text.color3,
  opacity: 0.6,
}));

const DetailPanelCell = ({ label, children, testId, tooltip, ...otherProps }: DetailPanelCellProps) => {
  const { palette } = useTheme();

  return (
    <TableCell {...otherProps}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Text variant="caption" size="XS" tag="div" color={palette.text.color3} data-testid={`${testId}-header`}>
          {label}
        </Text>
        <Tooltip {...tooltip} size="S">
          <StyledTooltipInfoTrigger />
        </Tooltip>
      </Stack>

      <Text variant="body-regular" size="M" tag="div" data-testid={`${testId}-value`}>
        {children}
      </Text>
    </TableCell>
  );
};

export default DetailPanelCell;
