import { useListContractsQuery, Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '../../../../../contexts/AccountContext';
import { useMemo } from 'react';
import { AvailableDate } from './types';
import { isRollingContract } from '../../computed';
import { useUserContractsPermissions } from '../../hooks/useUserContractsPermissions';
import { ContractPermissions } from '../../types';

const getContractId = (contract: Contract): string | undefined => {
  return contract.record.contractId;
};

export const getEarliestDate = (availableDates: AvailableDate[], defaultDate: AvailableDate): AvailableDate => {
  const datesSorted = availableDates
    .filter((d) => !!d.date)
    .sort((d1, d2) => ((d1?.date || '0') < (d2.date || '0') ? -1 : 1));

  return datesSorted[0] || defaultDate;
};

type SelectContractsOptionsProps = {
  permissionsFilter?: (contract: ContractPermissions) => boolean;
};

export const useSelectContractsOptions = ({ permissionsFilter }: SelectContractsOptionsProps) => {
  const { accountId } = useAccountContext();

  const { data: contractsData, isLoading } = useListContractsQuery({
    accountId,
  });

  const { permissions } = useUserContractsPermissions(
    contractsData?.contracts.map(({ contract }) => contract.record.contractId) || []
  );

  const options = useMemo(() => {
    return (
      contractsData?.contracts
        .filter(({ contract }) => {
          const isRenewed = !!contract.materialized?.next;
          const hasRenewalInProgress = !!contract.workflow?.hasActiveRenewalRequest;
          return !isRenewed && !hasRenewalInProgress;
        })
        .filter(({ contract }) => {
          const contractPermission = permissions[`${contract.record.contractId}`];
          return contractPermission && (permissionsFilter?.(contractPermission) ?? true);
        })
        .map(({ contract }) => {
          const dates: AvailableDate[] = [
            {
              date: contract.parts.contractual?.lifecycle?.renewalDate ?? undefined,
              translationKey: isRollingContract(contract) ? 'NEXT_ROLL_DATE' : 'RENEWAL_DATE',
            },
            {
              date: contract.parts.contractual?.lifecycle?.endDate ?? undefined,
              translationKey: 'END_DATE',
            },
            {
              date: contract.parts.contractual?.lifecycle?.autoRenewalDeadline ?? undefined,
              translationKey: 'AUTO_RENEWAL_DATE',
            },
          ];

          const dateToDisplay = getEarliestDate(dates, {
            date: contract.parts.contractual?.lifecycle?.startDate ?? undefined,
            translationKey: 'START_DATE',
          });

          return {
            contract,
            id: getContractId(contract) || '',
            dateToDisplay,
          };
        })
        .sort((c1, c2) => {
          return (c1.dateToDisplay.date || '9999-99-99') < (c2.dateToDisplay.date || '9999-99-99') ? -1 : 1; // sort by date, place contracts with no date at the end
        }) || []
    );
  }, [contractsData?.contracts, permissions, permissionsFilter]);

  return { options, isLoading };
};
