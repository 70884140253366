import { RowItem, TreeItem } from './types';

/**
 * Returns true if the left connector of the node should be highlighted.
 * @param item data model of the given node
 * @param row all items on the same row
 */
export const isLeftConnectorHighlighted = (item: RowItem, row: RowItem[]): boolean => {
  const itemIndex = row.indexOf(item);

  // node is on the right side of the group
  if (item.groupIndex >= item.groupCount / 2) {
    // return true if the node itself or any of its right-hand neighbours from the same group is selected
    return (
      item.selected ||
      row.slice(itemIndex + 1, itemIndex + (item.groupCount - item.groupIndex)).some((neighbour) => neighbour.selected)
    );
  }

  // node is on the left side or in the middle of the group
  // return true if any of its left-hand neighbours from the same group is selected
  return row.slice(itemIndex - item.groupIndex, itemIndex).some((neighbour) => neighbour.selected);
};

/**
 * Returns true if the right connector of the node should be highlighted.
 * @param item data model of the given node
 * @param row all items on the same row
 */
export const isRightConnectorHighlighted = (item: RowItem, row: RowItem[]): boolean => {
  const itemIndex = row.indexOf(item);

  // node is on the left side of the group
  if (item.groupIndex < Math.floor(item.groupCount / 2)) {
    // return true if the node itself or any of its left-hand neighbours from the same group is selected
    return item.selected || row.slice(itemIndex - item.groupIndex, itemIndex).some((neighbour) => neighbour.selected);
  }
  // node is on the right side or in the middle of the group
  // return true if any of its right-hand neighbours from the same group is selected
  return row
    .slice(itemIndex + 1, itemIndex + (item.groupCount - item.groupIndex))
    .some((neighbour) => neighbour.selected);
};

/**
 * Define node connectors based on the state of its properties.
 * @param item
 */
export const getNodeConnectors = (item: RowItem) => {
  const halfGroupOffset = (item.groupCount - 1) / 2;

  return {
    // Show top connector for nodes with parent
    // Top connector can be center, right or left oriented in order to make smoother rounded border
    topCenter: Boolean(item.parentId && item.groupIndex === halfGroupOffset),
    topRight: Boolean(item.parentId && item.groupIndex < halfGroupOffset),
    topLeft: Boolean(item.parentId && item.groupIndex > halfGroupOffset),

    // Show bottom connector for expanded nodes with children
    bottom: Boolean(item.expanded && item.children),

    // Show left connector for all nodes except for the first one in the group
    left: Boolean(item.groupIndex > 0),

    // Show right connector for all nodes except for the last one in the group
    right: Boolean(item.groupIndex < item.groupCount - 1),
  };
};

/**
 * Create node model data based on item config provided during initialization.
 * @param userData
 */
export const getNodeData = (userData: TreeItem) => ({
  userData,
  id: userData.id,
  children: userData.children,
  selected: userData.selected ?? false,
  collapsible: userData.collapsible ?? true,
  expanded: userData.collapsible === false,
});

/**
 * Returns an array of rows. Nodes in the rows contain the original data and other auxiliary properties needed
 * for navigation in the chart and its easy rendering.
 *
 * ParentId - unique reference to a parent node, null in case of the root node
 * Offset - the horizontal distance of the node from the center (0) expressed with a negative or positive value
 *                         [  0  ]
 *     [-2.5 ] [-1.5 ] [-0.5 ] [ 0.5 ] [ 1.5 ] [ 2.5 ]
 * [ -3  ] [ -2  ] [ -1  ] [  0  ]
 *
 * GroupCount - the number of nodes in the group the given node belongs to (all nodes in the group has the same parent)
 * GroupIndex - the index of the node in the group (0 for the first node, GroupCount-1 for the last node)
 *
 * @param data
 */
export const getRows = (data: TreeItem) => {
  const rows: RowItem[][] = [];

  // Pointer to the row being processed
  let index = 0;

  // Offset of the left-most node from the center (~ the minimal offset value)
  let leftOffset = 0;

  // Offset of the right-most node from the center (~ the maximal offset value)
  let rightOffset = 0;

  rows.push([
    {
      ...getNodeData(data),
      visible: true,
      parentId: null,
      offset: 0,
      groupIndex: 0,
      groupCount: 1,
    },
  ]);

  while (index < rows.length) {
    const newRow: TreeItem[][] = [];

    // eslint-disable-next-line no-loop-func
    rows[index].forEach((rowItem) => {
      if (rowItem.children) {
        newRow.push(
          rowItem.children.map((child: TreeItem, childIndex: number) => {
            const groupCount = rowItem.children!.length;
            const offset = rowItem.offset + childIndex - (groupCount - 1) / 2;
            if (offset > 0) {
              rightOffset = Math.max(offset, rightOffset);
            } else {
              leftOffset = Math.min(offset, leftOffset);
            }

            return {
              ...getNodeData(child),
              visible: rowItem.expanded,
              parentId: rowItem.id,
              offset,
              groupIndex: childIndex,
              groupCount,
            };
          })
        );
      }
    });
    const flatRow = newRow.flat() as RowItem[];
    if (flatRow.length) {
      rows.push(flatRow);
    }
    index += 1;
  }

  return {
    rows,
    rightOffset,
    leftOffset: Math.abs(leftOffset),
  };
};
