import { useCallback } from 'react';
import { Point } from 'highcharts';
import { mergeOptions } from '../utils/optionsUtils';
import { UpdateOptionsFn } from '../types';

export const useStackedColumnHover = () => {
  const setOtherPointsState = (point: Point, state: 'inactive' | 'normal') => {
    const hoveredX = point.x;
    const allSeries = point.series.chart.series;
    allSeries.forEach((series) => {
      // Need to check the `series` existence for special case when you hover over a point and the same time
      // you try to navigate to the previous page (e.g. with a keyboard shortcut or side button on your mouse).
      // In such case Highchart can be in the destroyed state where `series` is not defined.
      // AND
      // It doesn't make sense to do this for line series, inactive line points look weird.
      if (!series || series.type === 'line') {
        return;
      }
      series.points.forEach((p) => {
        if (
          p.x !== hoveredX &&
          // Check whether Highchart is not destroyed yet. See comment above ^^
          p.setState
        ) {
          p.setState(state);
        }
      });
    });
  };

  const mouseOver = useCallback((event: any) => {
    setOtherPointsState(event.target, 'inactive');
  }, []);

  const mouseOut = useCallback((event: any) => {
    setOtherPointsState(event.target, 'normal');
  }, []);

  return useCallback<UpdateOptionsFn>(
    (options) =>
      mergeOptions(
        {
          plotOptions: {
            column: {
              stacking: 'normal',
            },
            series: {
              point: {
                events: { mouseOver, mouseOut },
              },
            },
          },
        },
        options
      ),
    [mouseOver, mouseOut]
  );
};
