import type { SeriesOptionsType } from 'highcharts';
import { default as useDiscountedUsage, type getDiscountedUsage } from './useDiscountedUsage';
import { default as useHistoricalCoverage, type getHistoricalCoverage } from './useHistoricalCoverage';
import { default as useTargetCoverage, type getTargetCoverage } from './useTargetCoverage';
import { default as useMissedSavings, type getMissedSavings } from './useMissedSavings';

export type SeriesTypes =
  | ({ series: 'discountedUsage' } & getDiscountedUsage)
  | ({ series: 'historicalCoverage' } & getHistoricalCoverage)
  | ({ series: 'targetCoverage' } & getTargetCoverage)
  | ({ series: 'missedSavings' } & getMissedSavings);

export const useGetSeries = () => {
  const hooks: Record<SeriesTypes['series'], (props: any) => SeriesOptionsType> = {
    discountedUsage: useDiscountedUsage(),
    historicalCoverage: useHistoricalCoverage(),
    targetCoverage: useTargetCoverage(),
    missedSavings: useMissedSavings(),
  };

  return (props: SeriesTypes | Array<SeriesTypes>): Array<SeriesOptionsType> => {
    if (Array.isArray(props)) {
      return props.map((p) => hooks[p.series](p));
    }

    return [hooks[props.series](props)];
  };
};
