import React from 'react';
import { API_URLS } from '@vertice/slices/src/constants';
import { Box, useTheme } from '@mui/material';
import { NoVendorLogo } from './assets';

/**
 * VendorLogo component which should follow the latest designs.
 */

export type VendorLogoProps = {
  vendorId?: string;
  size?: number;
  bg?: string;
};

const BASE_SIZE = 32;
const PADDING = 6;

const VendorLogo = ({ vendorId, size = BASE_SIZE, bg }: VendorLogoProps) => {
  const { palette } = useTheme();

  const innerSize = size - PADDING * 2;
  const bgColor = bg ?? palette.core.color1;

  if (!vendorId) {
    return <NoVendorLogo width={size} height={size} />;
  }

  return (
    <Box p={`${PADDING}px`} borderRadius={3} bgcolor={bgColor} height={size} maxWidth={size}>
      <img alt="logo" src={`${API_URLS.LOGOS_URL}/${vendorId}.svg`} width={innerSize} height={innerSize} />
    </Box>
  );
};

export default VendorLogo;
