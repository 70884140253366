import { useTranslation } from 'react-i18next';
import { Offer } from '../Offer';
import { useOfferItemMapping } from '../helpers/itemMapping';
import { Offer as OfferType } from '../../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';

type EnumeratedOfferSectionProps = {
  offer: OfferType;
  baselineOffer: OfferType;
  index: number;
};

export const EnumeratedOfferSection = ({ offer, baselineOffer, index }: EnumeratedOfferSectionProps) => {
  const { t } = useTranslation();
  const { vendor, products, concessions } = useOfferItemMapping(offer);

  const title = t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.OFFER_TITLE', { count: index, name: offer.name });

  return (
    <Offer
      title={title}
      vendor={vendor}
      products={products}
      concessions={concessions}
      offer={offer}
      type="enumerated"
      baselineOffer={baselineOffer}
    />
  );
};
