import React, { FC } from 'react';
import styles from './VendorDiligenceInsights.module.scss';

interface CertificationItemProps {
  icon: React.ReactElement;
  link: string;
}

const CertificationItem: FC<CertificationItemProps> = ({ icon, link }) => (
  <a className={styles.image} href={link} target="_blank" rel="noreferrer">
    {icon}
  </a>
);

export default CertificationItem;
