import { USER_ROLES } from '../../../../../../constants/userRoles';
import { PredefinedWidgetDef } from '../shared/types';
import { LinkedContractsSection } from './LinkedContractsSection';

export const LINKED_CONTRACT_WIDGET_URN_PATTERN = /^widget\/saas\/contracts\/v\d+$/;
export const widgetDef: PredefinedWidgetDef = {
  urnSuffix: LINKED_CONTRACT_WIDGET_URN_PATTERN,
  component: LinkedContractsSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.LINKED_CONTRACTS',
  restrictedRoles: [USER_ROLES.user],
  renderPredicate: (widgetData) => !!widgetData?.contractId,
};
