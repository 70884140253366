import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import React from 'react';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';

type ReadComponent = typeof FormChecklistItemField;
type WriteComponent = typeof FormChecklistItemField;

type CheckboxContractFormEntryProps = ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>;

const CheckboxContractFormEntry = (options: CheckboxContractFormEntryProps) => (
  <ContractFormEntry<ReadComponent, WriteComponent>
    component={FormChecklistItemField}
    readFormFieldDisabled
    writeComponent={FormChecklistItemField}
    {...options}
  />
);

export default CheckboxContractFormEntry;
