import { CustomLayoutProps } from './types';
import CustomSingleLayout from './CustomSingleLayout';
import CustomDoubleLayout from './CustomDoubleLayout';
import { dateRangeCalendarClasses, dayCalendarClasses, pickersSlideTransitionClasses } from '@mui/x-date-pickers-pro';
import { SIZE_DEFINITION } from '../../constants';
import { getTextVariantStyle } from '../../../Text';
import { useTheme } from '@mui/material';
import { useStaticDateRangePickerContext } from '../../context/StaticDateRangePickerContext';

const CustomLayout = (props: CustomLayoutProps) => {
  const { size } = useStaticDateRangePickerContext();
  const { variant } = props;
  const { palette } = useTheme();
  const daysInWeek = 7;
  const weeksInMonth = 6;

  const LayoutComponent = variant === 'single' ? CustomSingleLayout : CustomDoubleLayout;

  return (
    <LayoutComponent
      {...props}
      sx={{
        border: `1px solid ${palette.core.color3}`,
        borderRadius: 8,
        backgroundColor: palette.core.bg,
        [`.${pickersSlideTransitionClasses.root}`]: {
          minHeight: `${
            weeksInMonth * parseInt(SIZE_DEFINITION[size].calendarDaySize.height, 10) +
            (weeksInMonth + 1) * SIZE_DEFINITION[size].weekContainerMargin
          }px`,
          minWidth: `${daysInWeek * parseInt(SIZE_DEFINITION[size].calendarDaySize.width, 10)}px`,
        },
        [`.${dayCalendarClasses.header}`]: {
          borderBottom: `1px solid ${palette.core.color2}`,
        },
        [`.${dayCalendarClasses.weekDayLabel}`]: {
          ...getTextVariantStyle({ variant: 'body-bold', size }),
          color: palette.text.color1,
          width: SIZE_DEFINITION[size].calendarDaySize.width,
          height: SIZE_DEFINITION[size].calendarDaySize.height,
          margin: 0,
        },
        [`.${dayCalendarClasses.weekContainer}`]: {
          margin: `${SIZE_DEFINITION[size].weekContainerMargin}px 0`,
        },
        [`.${dateRangeCalendarClasses.monthContainer}`]: {
          padding: SIZE_DEFINITION[size].defaultPadding,
        },
      }}
    />
  );
};

export default CustomLayout;
