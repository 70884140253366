import { useContext, useMemo } from 'react';
import { removeNullables } from '@verticeone/utils/objects';
import { ListVendorsApiArg, Vendor } from '@vertice/slices';
import { VendorListFilterContext } from '../VendorListFilterContext';
import { VendorListFilter } from '../types';
import useVendorAnalytics from './useVendorAnalytics';
import useListVendorsSmartQuery from '../../../hooks/useListVendorsSmartQuery';

export const VENDORS_LOAD_LIMIT = 100;

export type UseVendorDataReturn = {
  loadedVendors: Vendor[];
  isFetching: boolean;
};

const useVendorData = (): UseVendorDataReturn => {
  const { listFilter, search, category, vendorsOffset } = useContext(VendorListFilterContext);
  const { myVendorIds } = useVendorAnalytics();

  const listVendorsParams = useMemo(() => {
    const filters = [];
    if (listFilter === VendorListFilter.MY_VENDROS && myVendorIds) {
      filters.push(...myVendorIds.map((id) => `vendorId:${id}`));
    }

    if (category) {
      filters.push(`category:${category}`);
    }

    return removeNullables({
      sort_orderBy: 'asc',
      limit: VENDORS_LOAD_LIMIT,
      offset: vendorsOffset,
      query: search,
      filters: filters.length > 0 ? filters : null,
    }) as ListVendorsApiArg;
  }, [myVendorIds, listFilter, search, category, vendorsOffset]);

  const { data: loadedVendors = [], isFetching } = useListVendorsSmartQuery(listVendorsParams, { skip: !myVendorIds });

  return {
    loadedVendors,
    isFetching,
  };
};

export default useVendorData;
