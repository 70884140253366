import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import type { SeriesLineOptions } from 'highcharts';

export type getHistoricalCoverage = {
  target: number;
  data: Array<[number, number | undefined]>;
};

export const HISTORICAL_COVERAGE_ID = 'historical_coverage';

const useHistoricalCoverage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return useCallback(
    ({ target, data }: getHistoricalCoverage): SeriesLineOptions => {
      return {
        id: HISTORICAL_COVERAGE_ID,
        name: t('CLOUD.RESERVED_INSTANCES.HISTORICAL_COVERAGE'),
        type: 'line',
        data,
        zIndex: 2,
        color: palette.error.color1,
        zones: [{ value: target, color: palette.error.color1 }, { color: palette.success.color2 }],
      };
    },
    [palette, t]
  );
};

export default useHistoricalCoverage;
