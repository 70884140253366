import { useCallback } from 'react';
import { useLocaleContext } from '../UtilsContext';

export const MAXIMUM_FRACTION_DIGITS_DYNAMIC = -1;

export interface FormatCurrencyOptions {
  currency: string;
  /**
   * Maximum fraction digits
   * - Default: `2`
   * - `MAXIMUM_FRACTION_DIGITS_DYNAMIC` can be passed to use dynamic fraction digits. That can be useful for
   * combination with compact = true (`1000` -> `'$1K'`, `1100` -> `'$1.1K'`)
   */
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  plusSign?: boolean;
  compact?: boolean;
  withSpace?: boolean;
  locale: string;
}

export const formatCurrency = (
  value: number,
  {
    currency,
    locale,
    maximumFractionDigits = 2,
    minimumFractionDigits,
    plusSign,
    compact,
    withSpace,
  }: FormatCurrencyOptions
) => {
  if (!locale || value === undefined) {
    return '';
  }

  try {
    const result = new Intl.NumberFormat(locale, {
      style: 'currency',
      maximumFractionDigits:
        maximumFractionDigits === MAXIMUM_FRACTION_DIGITS_DYNAMIC ? undefined : maximumFractionDigits,
      minimumFractionDigits,
      currency,
      ...(compact
        ? {
            notation: 'compact',
            compactDisplay: 'short',
          }
        : {}),
    }).format(value);

    const resultWithSign = plusSign && value > 0 ? '+' + result : result;
    const resultWithSpace = withSpace ? resultWithSign.replace(/^(\D+)/, '$1 ') : resultWithSign;

    return resultWithSpace;
  } catch {
    // Intl.NumberFormat throws error if unsupported currency is provided
    return `${currency} ${value}`;
  }
};

export const useFormatCurrency = () => {
  const { locale } = useLocaleContext();
  return useCallback(
    (value: number, options: Omit<FormatCurrencyOptions, 'locale'>) => formatCurrency(value, { locale, ...options }),
    [locale]
  );
};

export const getCurrencySymbol = (locales: string | string[], currency: string): string => {
  try {
    return (
      Intl.NumberFormat(locales, { style: 'currency', currency })
        .formatToParts(0)
        .find((part) => part.type === 'currency')?.value || currency
    );
  } catch (ex) {
    return currency;
  }
};
