import React from 'react';
import { StatsBar } from '@vertice/core/src/components/StatsBar';
import EstimatedSavingsStatsContainer from '../../components/EstimatedSavingsStatsContainer';
import useAnnualSpendData from '../../hooks/useAnnualSpendData';
import AnnualInvoicedSpendStatsContainer from '../../components/AnnualInvoicedSpendStatsContainer';
import AnnualGrossSpendStatsContainer from '../../components/AnnualGrossSpendStatsContainer';

const SpendInsightsStatsCard = () => {
  const annualSpendDataQuery = useAnnualSpendData();
  return (
    <StatsBar>
      <EstimatedSavingsStatsContainer flex={1} />
      <AnnualInvoicedSpendStatsContainer annualSpendDataQuery={annualSpendDataQuery} flex={0.75} />
      <AnnualGrossSpendStatsContainer annualSpendDataQuery={annualSpendDataQuery} flex={0.75} />
    </StatsBar>
  );
};

export default SpendInsightsStatsCard;
