import { ZodErrorMap, defaultErrorMap } from 'zod';
import { TFunction } from 'i18next';

/** Here, we can define custom translations of the Zod validation errors. */
export const getBaseTranslatedErrorMap =
  (t: TFunction): ZodErrorMap =>
  (zodError, ctx) => {
    if (zodError.code === 'invalid_type' && ['null', 'undefined'].includes(zodError.received)) {
      return { message: t('REQUIRED_FIELD') };
    }
    if (zodError.code === 'too_small' && zodError.minimum === 1) {
      return { message: t('REQUIRED_FIELD') };
    }
    return defaultErrorMap(zodError, ctx);
  };
