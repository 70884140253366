import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useDeactivateAccountIntegrationMutation, useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { isValidIntegrationResponse } from 'pages/Preferences/Integrations/utils';
import { JIRA_PROVIDER_ID } from '../constants';

export const useDeactivateJiraIntegration = () => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const deactivateJiraIntegration = async () => {
    const response = await deactivateIntegration({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
    });

    if (isValidIntegrationResponse(response)) {
      await revokeIntegration({ accountId, integrationId: JIRA_PROVIDER_ID });
      navigate(generatePath(routes.PREFERENCES.TAB.ABSOLUTE_PATH, { activeTab: 'integrations' }));
    }
  };

  return { deactivateJiraIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress };
};
