import { createContext } from 'react';
import type { DesignSystemSize, DesignSystemColor } from '../../types';

export type TableContextProps = {
  size: DesignSystemSize;
  color: DesignSystemColor;
};

export const TableContext = createContext<TableContextProps>({
  size: 'M',
  color: 'neutral',
});
