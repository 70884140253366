import { useSelector } from 'react-redux';
import {
  PhoneNumber,
  Timestamp,
  UserSettings,
  CreateNewAccountUserApiResponse,
  useCreateNewAccountUserMutation,
  useAdminUpdateUserSettingsMutation,
  getAccount,
} from '@vertice/slices';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

interface CreateUserProps {
  accountId?: string;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber?: PhoneNumber;
  retentionDate?: Timestamp;
  role: string;
  jobTitle?: string;
  userSettings?: UserSettings;
}

type userKeyProps = keyof CreateUserProps;

export type CreateUserResponse = {
  data: {
    userId: string;
  };
};

export type CreateUserError = {
  error: string;
};

const useCreateUser = () => {
  const { accountId } = useSelector(getAccount);
  const [createUser, { isLoading: isUserCreating }] = useCreateNewAccountUserMutation();
  const [updateUserSettings, { isLoading: isUserSettingsUpdating }] = useAdminUpdateUserSettingsMutation();

  const defaultUserSettings: UserSettings = {
    workflowNotificationSettings: {
      subscriptions: [
        {
          role: 'owner',
          contractRenewal: {
            enabled: true,
            timeThreshold: 'P3M',
          },
          pipelineUpdates: {
            enabled: false,
          },
          proposalsAwaitingApproval: {
            enabled: true,
          },
          newContractsAdded: {
            enabled: false,
          },
        },
        {
          role: 'watcher',
          contractRenewal: {
            enabled: true,
            timeThreshold: 'P3M',
          },
          pipelineUpdates: {
            enabled: false,
          },
          proposalsAwaitingApproval: {
            enabled: true,
          },
          newContractsAdded: {
            enabled: false,
          },
        },
      ],
    },
    notifications: {
      contractRenewal: {
        enabled: true,
        timeThreshold: 'P3M',
      },
      pipelineUpdates: {
        enabled: false,
      },
      proposalsAwaitingApproval: {
        enabled: true,
      },
      newContractsAdded: {
        enabled: false,
      },
    },
  };

  const extract = (object: CreateUserProps, fields: userKeyProps[]) =>
    fields.reduce((acc: any, key: userKeyProps) => {
      const value = object[key];
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

  const isEmpty = (obj: any) => Object.keys(obj).length === 0;

  const create = async (userData: CreateUserProps): Promise<CreateUserResponse | CreateUserError> => {
    const account = userData.accountId ?? accountId;

    const userInfo = extract(userData, [
      'email',
      'firstName',
      'middleName',
      'lastName',
      'phoneNumber',
      'retentionDate',
      'role',
    ]);

    const accountUserProperties = extract(userData, ['jobTitle']);

    const userBody = {
      ...userInfo,
      ...(!isEmpty(accountUserProperties) && { accountUserProperties }),
    };

    if (account && !isEmpty(userBody)) {
      const response = await createUser({ accountId: account, newUser: userBody });
      if ((response as { error: FetchBaseQueryError | SerializedError }).error) {
        return { error: 'Failed to create user.' };
      }
      const createdUser = response as { data: CreateNewAccountUserApiResponse };
      if (createdUser.data) {
        const userId = createdUser.data.userId!;
        if (userData.userSettings) {
          const updateSettingsResponse = await updateUserSettings({
            accountId: account,
            userId,
            userSettings: userData.userSettings,
          });
          if ((updateSettingsResponse as { error: FetchBaseQueryError | SerializedError }).error) {
            return { error: 'Failed to update user settings.' };
          }
        }

        return { data: { userId } };
      }
    }
    return { error: 'Unexpected error.' };
  };

  return {
    defaultUserSettings,
    createUser: create,
    isLoading: isUserCreating || isUserSettingsUpdating,
  };
};

export default useCreateUser;
