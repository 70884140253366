import React from 'react';
import { Stack } from '@mui/material';
import LinkedContractsList from './LinkedContractsList';

const LinkedContractsTab = () => {
  return (
    <Stack p={6}>
      <LinkedContractsList />
    </Stack>
  );
};

export default LinkedContractsTab;
