import React, { ReactNode } from 'react';
import { Stack, useTheme } from '@mui/material';

const LegendControlsBox = ({ children }: { children: ReactNode }) => {
  const { palette } = useTheme();
  return (
    <Stack
      direction="row"
      px={1.75}
      bgcolor={palette.core.bg}
      columnGap={3.5}
      rowGap={2.5}
      borderRadius="8px"
      flexWrap="wrap"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
};

export default LegendControlsBox;
