import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GRID_REORDER_COL_DEF,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { Box, styled } from '@mui/material';
import { DataGrid, DataGridProps } from '@verticeone/design-system/src';
import AddItemFooter from './AddItemFooter';
import NoRowsOverlay from './NoRowsOverlay';
import { useDropdownValueListContext } from './DropdownValueListContextProvider';
import { enhanceRowsWithReorderProperty, preProcessNonEmptyCell } from './utils';
import ToggleSwitchCell from '../cells/ToggleSwitchCell';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import EditableLabelCell from './EditableLabelCell';
import { renderRowReorderCell } from '@mui/x-data-grid-pro/components/GridRowReorderCell';

const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 52;

const MAX_TABLE_HEIGHT = 400;

export interface DropdownValueListProps {
  extraColumns?: GridColDef[];
  dataGridExtraProps?: Partial<DataGridProps<CustomContractFieldDropdownOption>>;
  readOnlyMode?: boolean;
  noBorder?: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
  '& .Mui-error': {
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.error.color1,
  },
}));

const DropdownValueList: FC<DropdownValueListProps> = ({
  extraColumns = [],
  dataGridExtraProps = {},
  readOnlyMode,
  noBorder,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST' });
  const { values, reorderValues } = useDropdownValueListContext();
  const rows = useMemo(() => enhanceRowsWithReorderProperty(values, 'label') || [], [values]);

  const columns: GridColDef[] = [
    {
      ...GRID_REORDER_COL_DEF,
      // This helps to show __reorder__ property in the drag&drop placeholder also for the last added row
      editable: true,
      cellClassName: gridClasses.rowReorderCellContainer,
      renderEditCell: renderRowReorderCell,
    },
    {
      field: 'label',
      headerName: t('LABEL'),
      preProcessEditCellProps: preProcessNonEmptyCell(values),
      valueParser: (value: string) => value.trimStart(),
      renderEditCell: (params: GridRenderCellParams) => <EditableLabelCell {...params} />,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      flex: 1,
    },
    {
      field: 'enabled',
      headerName: t('IS_SHOWN'),
      valueParser: (value?: boolean) => !!value,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      renderCell: (params: GridRenderCellParams) => <ToggleSwitchCell disabled={true} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <ToggleSwitchCell {...params} />,
      width: 120,
    },
  ];

  const columnsToUse = [...columns, ...extraColumns];

  const tableHeight = useMemo(() => HEADER_HEIGHT + values.length * ROW_HEIGHT + FOOTER_HEIGHT, [values]);

  const handleRowOrderChange = async ({ oldIndex, targetIndex }: GridRowOrderChangeParams) => {
    reorderValues(oldIndex, targetIndex);
  };

  return (
    <StyledBox height={tableHeight > MAX_TABLE_HEIGHT ? MAX_TABLE_HEIGHT : 'auto'}>
      <DataGrid
        rows={rows}
        columns={columnsToUse}
        getRowId={(row: GridRowModel) => row.id}
        slots={{
          footer: AddItemFooter,
          noRowsOverlay: () => <NoRowsOverlay />,
        }}
        sortingMode="client"
        isCellEditable={() => false}
        disableRowSelectionOnClick
        hideFooter
        columnHeaderHeight={48}
        autoHeight={tableHeight <= MAX_TABLE_HEIGHT}
        noBorder={noBorder}
        noBorderRadius={noBorder}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        {...dataGridExtraProps}
      />
    </StyledBox>
  );
};

export default DropdownValueList;
