import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';

import { Text } from '@verticeone/design-system/src';
import { LoadableComponent } from '@verticeone/design-system/src';
import { testProps } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import useAnnualSpendData from '../../../../../modules/cloud/hooks/useAnnualSpendData';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useCloudOptimization } from '../../CloudOptimizationContext';
import useOptimizations from '../../dataSource/useOptimizations';

const Tile = styled(Stack)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(6),
  backgroundColor: theme.palette.core.bg,
}));

const ScoreBarTitle = () => {
  const formatCurrency = useFormatCurrency();
  const annualSpendDataQuery = useAnnualSpendData();
  const { isAnnualPeriod } = useCloudOptimization();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS' });
  const multiplier = isAnnualPeriod ? 12 : 1;
  const optimizationsQuery = useOptimizations({
    select: (result) => {
      if (result?.optimizationsQuery?.__typename === 'OptimizationsResult') {
        return result.optimizationsQuery.summary.estimatedSavings.find((item) => item?.category === 'TOTAL')?.saving;
      }
    },
  });

  return (
    <Stack direction="row" gap={0.25} {...testProps('cost-overview')}>
      <Tile {...testProps('net-spend')}>
        <Text variant="body-bold" size="S" color="text2">
          {isAnnualPeriod ? t('NET_ANNUAL_SPEND') : t('NET_MONTHLY_SPEND')}
        </Text>
        <LoadableComponent isLoading={annualSpendDataQuery.isLoading} color={AWS_BRAND_COLOR}>
          <Text variant="heading" size="L" color="text1">
            {formatCurrency(
              (isAnnualPeriod
                ? annualSpendDataQuery?.data?.annualInvoicedSpend
                : annualSpendDataQuery?.data?.monthlyInvoicedSpend) ?? 0,
              { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 }
            )}
          </Text>
        </LoadableComponent>
      </Tile>
      <Tile {...testProps('gross-spend')}>
        <Text variant="body-bold" size="S" color="text2">
          {isAnnualPeriod ? t('GROSS_ANNUAL_SPEND') : t('GROSS_MONTHLY_SPEND')}
        </Text>
        <LoadableComponent isLoading={annualSpendDataQuery.isLoading} color={AWS_BRAND_COLOR}>
          <Text variant="heading" size="L" color="text1">
            {formatCurrency(
              (isAnnualPeriod
                ? annualSpendDataQuery?.data?.annualGrossSpend
                : annualSpendDataQuery?.data?.monthlyGrossSpend) ?? 0,
              { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 }
            )}
          </Text>
        </LoadableComponent>
      </Tile>
      <Tile {...testProps('estimated-savings')}>
        <Text variant="body-bold" size="S" color="text2">
          {isAnnualPeriod ? t('ESTIMATED_ANNUAL_SAVINGS') : t('ESTIMATED_MONTHLY_SAVINGS')}
        </Text>
        <LoadableComponent isLoading={optimizationsQuery.isLoading} color={AWS_BRAND_COLOR}>
          <Text variant="heading" size="L" color="text1">
            {formatCurrency(!!optimizationsQuery.data ? optimizationsQuery.data * multiplier : 0, {
              currency: AWS_DEFAULT_CURRENCY,
              maximumFractionDigits: 0,
            })}
          </Text>
        </LoadableComponent>
      </Tile>
    </Stack>
  );
};

export default ScoreBarTitle;
