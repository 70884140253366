import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const docprocAPI = createApi({
  reducerPath: 'docprocAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.DOCPROC),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
