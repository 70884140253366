import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { amplifyConfig } from '@vertice/dashboard/src/config';
import { logHttpError } from '@vertice/hooks/src/useAwsRum';
import useAmplifyConfigs, { OAuthResponseTypeType } from '@vertice/dashboard/src/hooks/useAmplifyConfigs';
import { useLazyGetUnauthorizedSsoConfigurationQuery } from '@vertice/slices/src/api/accountsAPI';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const SSO_PARAMS = ['userpool', 'client', 'idp', 'oauthdomain'];

export const extractDomain = (email: string) => {
  const hasExplicitDomain = email.includes('/');
  if (hasExplicitDomain) {
    return email.split('/')[0];
  }

  const hasImplicitDomain = email.includes('@');
  if (hasImplicitDomain) {
    return email.split('@')[1];
  }

  return '';
};

export const useHandleLogIn = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const from = location.state || {};
  const [error, setError] = useState('');
  const { changeConfigs } = useAmplifyConfigs(amplifyConfig);
  const [getSsoConfiguration, { data: ssoConfiguration }] = useLazyGetUnauthorizedSsoConfigurationQuery();
  useEffect(() => {
    if (SSO_PARAMS.every((param) => !!searchParams.get(param))) {
      changeConfigs({
        ...amplifyConfig,
        Auth: {
          region: 'eu-west-1',
          userPoolId: searchParams.get('userpool')!,
          userPoolWebClientId: searchParams.get('client')!,
          oauth: {
            domain: searchParams.get('oauthdomain')!,
            redirectSignIn: `${window.location.origin}/`,
            redirectSignOut: `${window.location.origin}/`,
            scope: ['email', 'openid', 'profile'],
            responseType: 'code' as OAuthResponseTypeType,
          },
        },
      });

      const identityProvider = searchParams.get('idp')!;
      try {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        Auth.federatedSignIn({
          customProvider: identityProvider,
          customState: JSON.stringify(from),
        });
      } catch (e) {
        logHttpError('401', {
          endpointName: 'Auth.federatedSignIn',
          originalArgs: {
            customProvider: identityProvider,
            customState: JSON.stringify(from),
          },
          message: JSON.stringify(e),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (ssoConfiguration?.enabled) {
      handleLogIn();
    } else if (ssoConfiguration?.enabled === false) {
      setError(t('LOGIN_SSO.ERROR.NOT_AUTHORIZED'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoConfiguration]);

  useEffect(() => {
    if (location.state?.error) {
      setError(t('LOGIN_SSO.ERROR.UNEXPECTED_ERROR'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleLogIn = () => {
    setError('');

    if (
      ssoConfiguration &&
      ssoConfiguration?.enabled &&
      ssoConfiguration?.oAuthDomain &&
      ssoConfiguration?.clientId &&
      ssoConfiguration?.userPoolId &&
      ssoConfiguration.identityProviderName
    ) {
      changeConfigs({
        ...amplifyConfig,
        Auth: {
          region: amplifyConfig.aws_cognito_region || 'eu-west-1',
          userPoolId: ssoConfiguration.userPoolId,
          userPoolWebClientId: ssoConfiguration.clientId,
          oauth: {
            domain: ssoConfiguration.oAuthDomain,
            redirectSignIn: `${window.location.origin}/`,
            redirectSignOut: `${window.location.origin}/`,
            scope: ['email', 'openid', 'profile'],
            responseType: 'code' as OAuthResponseTypeType,
          },
        },
      });

      try {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        Auth.federatedSignIn({
          customProvider: ssoConfiguration.identityProviderName,
          customState: JSON.stringify(from),
        });
      } catch (e) {
        logHttpError('401', {
          endpointName: 'Auth.federatedSignIn',
          originalArgs: {
            customProvider: ssoConfiguration.identityProviderName,
            customState: JSON.stringify(from),
          },
          message: JSON.stringify(e),
        });
      }
    }
  };

  return {
    handleLogIn: (email: string) => getSsoConfiguration({ domain: extractDomain(email) }),
    error,
    ssoParamsProvided: SSO_PARAMS.every((param) => !!searchParams.get(param)),
  };
};
