import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import {
  useGetColoredItems,
  useGetIsLoading,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { ChartPortal, fromChartPortal } from '@vertice/core/src/components/charts/components/ChartPortal';
import { Box, Stack } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import { Text } from '@verticeone/design-system/src';
import { isNil } from 'lodash';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { Currency } from '@vertice/components';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { LoadableTextComponent } from '@vertice/core/src/components/LoadableTextComponent';
import { useTranslation } from 'react-i18next';
import { getLargestNPointsBasic } from '@vertice/core/src/components/charts/chart-lib-agnostic/utils/getLargestNPoints';
import { formatCurrency } from '@verticeone/utils/formatting';

type SavingsByCategoryPieChartProps = { points?: { name: string; y: number }[]; currency: string; total?: number };

const extractLegendItems = (chart: HighchartsChart, locale: string, currency: string) =>
  chart.series[0].points.map(({ name, y, color }) => ({
    id: name,
    color: color as string,
    label: name,
    value: formatCurrency(y ?? 0, {
      currency,
      locale,
      maximumFractionDigits: 0,
    }),
  }));

const SavingsByCategoryPieChart = ({ points: allPoints, currency, total }: SavingsByCategoryPieChartProps) => {
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredSeries = useGetColoredItems();
  const getIsLoadingSeries = useGetIsLoading();
  const [, rerender] = useState({});
  const { locale } = useLocaleContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ESTIMATED_SAVINGS' });
  const points = useMemo(() => (allPoints ? getLargestNPointsBasic(allPoints, 6) : undefined), [allPoints]);

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      mergeOptions({
        chart: {
          type: 'pie',
          plotBackgroundColor: 'transparent',
          backgroundColor: 'transparent',
          plotBorderWidth: 0,
          height: 300,
          spacing: [0, 0, 0, 0],
        },
        subtitle: {
          useHTML: true,
          floating: true,
          verticalAlign: 'middle',
          text: fromChartPortal({ chart, portalKey: 'subtitle' }),
        },
        plotOptions: {
          pie: { size: 280, innerSize: '83%' },
          series: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: { enabled: false },
        series: [
          {
            type: 'pie',
            data: getIsLoadingSeries(getColoredSeries(points) ?? []),
          },
        ],
      }),
    ]);
  }, [applyStyledHighcharts, chart, getIsLoadingSeries, getColoredSeries, points]);

  useLayoutEffect(() => {
    // Wait for initial Highchart render (possibly with empty div portal targets)
    if (chart) {
      // Force React render portals into Highchart
      rerender({});
      // Wait for React render to finish
      requestAnimationFrame(() => {
        // Position the React-rendered elements
        (chart as any).layOutTitles();
      });
    }
  }, [chart, options]);

  return (
    <Stack position="relative">
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <Box px={6}>
        <Legend direction="column" items={chart ? extractLegendItems(chart, locale, currency) : []} />
      </Box>
      <ChartPortal chart={chart} portalKey="subtitle">
        <Stack direction="column" alignItems="center">
          <Text variant="body-bold" size="S" color="text2">
            {t('SAVINGS')}
          </Text>
          <LoadableTextComponent isLoading={isNil(total)}>
            <Stack direction="row" alignItems="flex-end">
              <Text variant="heading" size="L" color="text1">
                <Currency locale={locale} currency={currency} value={total ?? 0} maximumFractionDigits={0} />
              </Text>
            </Stack>
          </LoadableTextComponent>
        </Stack>
      </ChartPortal>
    </Stack>
  );
};

export default SavingsByCategoryPieChart;
