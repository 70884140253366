import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CustomFilterView } from './types';
import { every, isEmpty, isNil } from 'lodash';
import { getUnifiedStage } from '../../functions';
import { isCancelledContract } from '../../../../computed';

const evaluateCustomFilterView = ({ filters }: CustomFilterView, { contract }: ViewOnContract): boolean => {
  return every([
    !isCancelledContract(contract),
    isEmpty(filters.contractStage) || filters.contractStage!.some((v) => getUnifiedStage(contract) === v),
    isEmpty(filters.contractRollingFrequency) ||
      filters.contractRollingFrequency!.some(
        (r) =>
          contract.parts?.contractual?.lifecycle?.rollFrequency === r ||
          (r === 'NO' && isNil(contract.parts?.contractual?.lifecycle?.rollFrequency))
      ),
  ]);
};

export default evaluateCustomFilterView;
