import { useMemo } from 'react';
import { useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import { useAccountContext } from '../../../../contexts/AccountContext';
import { Property, PropertySource } from '../WorkflowEditor/types';

type OutputSchema = {
  properties: Property[];
};

export const formatPropertyLabel = (variable: string): string => {
  const parts = variable.split(/(?=[A-Z])/);

  return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
};

const getPropertyTypes = (schema: any): string[] => {
  if (typeof schema === 'object') {
    if ('type' in schema) {
      return Array.isArray(schema.type) ? schema.type : [schema.type];
    }
  }

  return [];
};

const convertJsonSchemaToOutputSchema = ({
  jsonSchema,
  source,
}: {
  jsonSchema: any;
  source: PropertySource;
}): OutputSchema => {
  if (!jsonSchema.properties) {
    return { properties: [] };
  }

  return {
    properties: Object.entries(jsonSchema.properties).map(([name, schema]) => {
      const type = getPropertyTypes(schema);

      return {
        label: formatPropertyLabel(name),
        name,
        type,
        source,
        required: jsonSchema.required?.includes(name) || false,
        properties: ['array', 'object'].includes(type[0])
          ? convertJsonSchemaToOutputSchema({ jsonSchema: schema, source }).properties
          : [],
      };
    }),
  };
};

type ServiceUrn = string;

type ServicesOutputSchemas = {
  account: Record<ServiceUrn, OutputSchema>;
  global: Record<ServiceUrn, OutputSchema>;
};

const isUDFService = (kind: any) =>
  typeof kind === 'string' && kind === 'Vertice/ServiceCatalog/Function/FunctionDefinition';

export const useServicesOutputSchemas = (): ServicesOutputSchemas => {
  const { accountId } = useAccountContext();
  const { data: accountServiceCatalogData } = useListServiceCatalogQuery({ accountId });
  const { data: globalServiceCatalogData } = useListServiceCatalogQuery({ accountId: 'GLOBAL' });

  return useMemo(() => {
    if (!accountServiceCatalogData?.items || !globalServiceCatalogData?.items) {
      return {
        account: {},
        global: {},
      };
    }

    const account = accountServiceCatalogData.items.reduce<Record<ServiceUrn, OutputSchema>>(
      (outputSchemas, service) => {
        if (service.urn && service.definition?.Interface?.Output?.JsonSchema) {
          outputSchemas[service.urn] = convertJsonSchemaToOutputSchema({
            jsonSchema: service.definition.Interface.Output.JsonSchema,
            source: { id: service.urn, label: service.name, kind: 'task' },
          });
        }
        return outputSchemas;
      },
      {}
    );

    const global = globalServiceCatalogData.items.reduce<Record<ServiceUrn, OutputSchema>>((outputSchemas, service) => {
      if (
        service.urn &&
        isUDFService(service.kind) &&
        service.definition?.FunctionProvider?.Interface?.Output?.JsonSchema
      ) {
        outputSchemas[service.urn] = convertJsonSchemaToOutputSchema({
          jsonSchema: service.definition.FunctionProvider.Interface.Output.JsonSchema,
          source: { id: service.urn, label: service.name, kind: 'global' },
        });
      }
      return outputSchemas;
    }, {});

    return {
      account,
      global,
    };
  }, [accountServiceCatalogData, globalServiceCatalogData]);
};
