import React from 'react';
import { Typography } from '@mui/material';
import style from './FeedbackLabel.module.scss';

type FeedbackLabelProps = {
  error: string;
};

const FeedbackLabel = ({ error }: FeedbackLabelProps) => {
  if (!error) {
    return null;
  }

  return (
    <div className={style.error}>
      <Typography variant="body-regular-s">{error}</Typography>
    </div>
  );
};

export default FeedbackLabel;
