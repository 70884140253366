import React, { useEffect } from 'react';
import { ShoppingCartOutlined } from '@mui/icons-material';

import BaseTable from '../components/BaseTable';
import { useShoppingCart } from '../../../providers/ShoppingCartProvider';
import { useColumns } from './columns';
import TableCard from '../components/TableCard';
import { LoadableComponent } from '../components/LoadableComponent';
import { useOfferings } from '../../../providers/OfferingsProvider';
import RIOfferingsTableFooter from '../components/tableFooter/TableFooter';
import { sumBy } from 'lodash';

const T_KEY = 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.CUSTOMIZE_PURCHASE_TAB.SHOPPING_CART';

const ShoppingTable = () => {
  const columns = useColumns(T_KEY);
  const { cartItems } = useShoppingCart();
  const { offerings } = useOfferings();

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const shoppingCartData = offerings.data?.filter((riOffering) =>
    cartItems.some((item) => item.id === riOffering.reservedInstancesOfferingId)
  );

  const totalCost = shoppingCartData ? sumBy(shoppingCartData, ({ riCost }) => riCost) : 0;
  const isEmpty = !offerings.data?.length || shoppingCartData?.length === 0;

  return (
    <TableCard icon={ShoppingCartOutlined} tKey={T_KEY}>
      <LoadableComponent error={offerings.error} isLoading={offerings.isFetching} isShoppingCartEmpty={isEmpty}>
        <BaseTable
          data={shoppingCartData ?? []}
          defaultSortModel={{ sort: 'desc', field: 'expectedSavings' }}
          getRowId={(row) => row.reservedInstancesOfferingId}
          tableColumns={columns}
          slots={{
            footer: RIOfferingsTableFooter,
          }}
          slotProps={{ footer: { totalCost, isEmpty } }}
          hideFooter={false}
        />
      </LoadableComponent>
    </TableCard>
  );
};

export default ShoppingTable;
