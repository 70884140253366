import { createRequiredContext } from '@verticeone/utils/contexts';
import { PageHeaderColor } from './types';
import { DesignSystemSize } from '../../types';

export type PageHeaderContext = {
  size: DesignSystemSize;
  color: PageHeaderColor;
};

const { PageHeaderContextProvider, usePageHeaderContext } = createRequiredContext<PageHeaderContext, 'PageHeader'>(
  'PageHeader'
);

export { PageHeaderContextProvider, usePageHeaderContext };
