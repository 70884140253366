import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, OutlinedInput } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Label, Loader } from '@vertice/components';
import {
  useGetUserSettingsQuery,
  useUpdateAccountUserMutation,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
} from '@vertice/slices';
import { useGetAccountUserQuery } from '@vertice/slices/src/openapi/codegen/accountAPI';
import { USER_ROLES } from '@vertice/core/src/constants/userRoles';
import { useSnackbar } from 'notistack';
import { useLoggedUser } from '@verticeone/auth/src';
import styles from './UserInformation.module.scss';
import { Card, CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { TimezoneSelect } from '@vertice/core/src/components/TimezoneSelect';

interface UserSettings {
  timeZone?: string;
}

interface UserData {
  firstName: string;
  lastName: string;
}

interface UserInformationForm {
  user: UserData;
  settings: UserSettings;
  jobTitle: string;
}

const UserInformation = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const { userId: currentUserId, email: userEmail } = useLoggedUser();

  const userRoleTranslationMap = {
    [USER_ROLES.admin]: t('PREFERENCES.USER.ROLES.ADMIN'),
    [USER_ROLES.powerUser]: t('PREFERENCES.USER.ROLES.POWER_USER'),
    [USER_ROLES.user]: t('PREFERENCES.USER.ROLES.USER'),
  };

  const {
    data: userSettings,
    isLoading: isUserSettingsLoading,
    refetch: refetchSettings,
  } = useGetUserSettingsQuery({ userId: currentUserId! }, { skip: !currentUserId, refetchOnMountOrArgChange: true });
  const { data: userProfile, isLoading } = useGetAccountUserQuery(
    { userId: currentUserId!, accountId: accountId! },
    { skip: !currentUserId || !accountId }
  );

  const isAdmin = userProfile?.userRoles?.some((x) => x === USER_ROLES.admin);

  const [updateUserSettings, { isLoading: isUserSettingsUpdating }] = useUpdateUserSettingsMutation();
  const [updateAccountUser, { isLoading: isAccountUserUpdating }] = useUpdateAccountUserMutation();
  const [updateUserData, { isLoading: isUserDataUpdating }] = useUpdateUserMutation();

  const { register, control, handleSubmit, setValue, reset } = useForm<UserInformationForm>();

  const handleSaveUserData = async (updatedUserData: UserData) => {
    const wasUpdated =
      updatedUserData.firstName !== userProfile?.firstName || updatedUserData.lastName !== userProfile?.lastName;
    if (currentUserId && wasUpdated) {
      const data = {
        ...updatedUserData,
      };
      await updateUserData({ userId: currentUserId, body: data });
    }
  };

  const handleSaveUserAccountData = async (title: string) => {
    const wasUpdated = isAdmin && title !== userProfile?.accountUserProperties?.jobTitle;
    if (currentUserId && wasUpdated) {
      const data = {
        accountUserProperties: {
          jobTitle: title,
        },
      };
      await updateAccountUser({ userId: currentUserId, accountId: accountId!, body: data });
    }
  };

  const handleSaveUserSettings = async (updatedUserSettings: UserSettings) => {
    const wasUpdated = updatedUserSettings.timeZone !== userSettings?.timeZone;
    if (currentUserId && updatedUserSettings && wasUpdated) {
      const userSettingsData = {
        timeZone: updatedUserSettings.timeZone || '',
      };
      await updateUserSettings({ userId: currentUserId, userSettings: userSettingsData });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetchSettings();
    }
  };

  const onSubmit: SubmitHandler<UserInformationForm> = async (formData) => {
    const { settings, user: userData, jobTitle } = formData;
    try {
      await Promise.all([
        handleSaveUserData(userData),
        handleSaveUserSettings(settings),
        handleSaveUserAccountData(jobTitle),
      ]);
      enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.SUCCESS'), {
        variant: 'success',
      });
      reset(formData);
    } catch (e) {
      enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.ERROR'), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (userSettings?.timeZone) {
      const userSettingsData = {
        timeZone: userSettings.timeZone,
      };
      setValue('settings', userSettingsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings]);

  useEffect(() => {
    if (userProfile) {
      setValue('user.firstName', userProfile.firstName ?? '');
      setValue('user.lastName', userProfile.lastName ?? '');
      if (isAdmin) setValue('jobTitle', userProfile.accountUserProperties?.jobTitle ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  if (isLoading || isUserSettingsLoading) {
    return (
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle text={t('PREFERENCES.USER.TITLE')} />
        </CardHeader>
        <Box p={6} height={293} position="relative">
          <Loader />
        </Box>
      </Card>
    );
  }

  const isSaving = isUserSettingsUpdating || isAccountUserUpdating || isUserDataUpdating;
  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('PREFERENCES.USER.TITLE')} />
        <CardHeaderActions>
          <Button
            variant="solid"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSaving}
            disabled={isSaving}
          >
            {t('PREFERENCES.USER.SAVE_CHANGES')}
          </Button>
        </CardHeaderActions>
      </CardHeader>
      <div className={styles.form}>
        <div>
          <Label required htmlFor="user.firstName" text={t('PREFERENCES.USER.FIRST_NAME')} />
          <OutlinedInput id="user.firstName" fullWidth {...register('user.firstName', { required: true })} />
        </div>
        <div>
          <Label required htmlFor="user.lastName" text={t('PREFERENCES.USER.LAST_NAME')} />
          <OutlinedInput id="user.lastName" fullWidth {...register('user.lastName', { required: true })} />
        </div>
        <div>
          <Label required htmlFor="publicInformation.email" text={t('PREFERENCES.USER.EMAIL')} />
          <OutlinedInput
            id="publicInformation.email"
            name="publicInformation.email"
            fullWidth
            value={userEmail || ''}
            disabled
          />
        </div>
        <div>
          <Label required htmlFor="jobTitle" text={t('PREFERENCES.USER.JOB_TITLE')} />
          {isAdmin ? (
            <OutlinedInput id="jobTitle" fullWidth {...register('jobTitle', { required: true })} />
          ) : (
            <Text className={styles['job-title']} variant="body-regular" size="M" data-target="jobTitleText">
              {userProfile?.accountUserProperties?.jobTitle || ''}
            </Text>
          )}
        </div>

        <div>
          <Label htmlFor="settings.timeZone" text={t('PREFERENCES.USER.TIME_ZONE')} />
          <Controller
            render={({ field: { ref, value, onChange } }) => (
              <TimezoneSelect ref={ref} value={value ?? null} onChange={onChange} isClearable />
            )}
            name="settings.timeZone"
            control={control}
          />
        </div>
        <div className={styles['roles-container']}>
          <Label text={t('PREFERENCES.USER.ROLE')} />
          <Text className={styles.roles} variant="body-regular" size="M" data-target="userRole">
            {userProfile?.userRoles?.map((userRole) => userRoleTranslationMap[userRole]).join(', ')}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default UserInformation;
