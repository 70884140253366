import { useCallback, useEffect } from 'react';
import { useMainLayout } from 'layouts/Main/LayoutWrapper';

export const useClickOutside = () => {
  const { isSmall, isOpen, close } = useMainLayout();

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const isClickOutside = target.closest('main');
      const isClickInApp = target.closest('#root');

      if (isClickInApp && isClickOutside && isOpen && isSmall) {
        e.stopPropagation();
        e.preventDefault();
        close();
      }
    },
    [isOpen, isSmall, close]
  );

  useEffect(() => {
    document.addEventListener('click', clickOutside, true);
    return () => document.removeEventListener('click', clickOutside, true);
  }, [clickOutside]);
};
