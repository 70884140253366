import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Contract, useContractListData } from './useContractListData';
import { randomId } from '@mui/x-data-grid-generator';
import { UseQueryResult } from '@tanstack/react-query';

type ContractsDataProviderProps = {
  children: React.ReactNode;
};

type ContractContextType = {
  addContract: () => void;
  removeContract: (contractId: string) => void;
} & UseQueryResult<Array<Contract> | null, Error>;

const ContractsDataContext = createContext<ContractContextType>({} as ContractContextType);

const ContractsDataProvider = ({ children }: ContractsDataProviderProps) => {
  const { data: contracts, ...listData } = useContractListData();
  const [data, setData] = useState<Array<Contract> | null>(null);

  useEffect(() => {
    if (contracts) {
      setData(contracts);
    }
  }, [contracts]);

  const addContract = useCallback(() => {
    const rowId = randomId();
    setData((prev) => [
      {
        id: randomId(),
        years: [{ id: rowId, startDate: null, endDate: null, name: '', commitment: null, discount: null }],
        endDate: null,
        startDate: null,
        state: 'new',
      },
      ...(prev ?? []),
    ]);
  }, []);

  const removeContract = useCallback((contractId: string) => {
    setData((prev) => prev?.filter((item) => item.id !== contractId) ?? null);
  }, []);

  return (
    <ContractsDataContext.Provider
      value={
        {
          data,
          addContract,
          removeContract,
          ...listData,
        } as ContractContextType
      }
    >
      {children}
    </ContractsDataContext.Provider>
  );
};

export const useContractData = () => useContext(ContractsDataContext);

export default ContractsDataProvider;
