import React, { FC, useCallback, useEffect, useState } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import smartlookClient from 'smartlook-client';
import { useGetUserQuery } from '@vertice/slices';
import { getFullName } from '@verticeone/utils/formatting';
import { Button, ButtonProps } from '@verticeone/design-system/src';
import { useAuthentication } from '@verticeone/auth/src';

type CalendlyButtonProps = {
  calendlyUrl?: string;
} & ButtonProps;

const CalendlyButton: FC<CalendlyButtonProps> = ({ calendlyUrl, variant = 'solid', children, ...otherProps }) => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const { user } = useAuthentication();
  const { data } = useGetUserQuery({ userId: user?.userId! }, { skip: !user?.userId });
  const { email, firstName, lastName } = data ?? {};

  useEffect(() => {
    if (!document.getElementById('calendly-root')) {
      const calendlyDiv = document.createElement('div');
      calendlyDiv.setAttribute('id', 'calendly-root');
      document.body.appendChild(calendlyDiv);
    }

    return () => {
      document.getElementById('calendly-root')?.remove();
    };
  }, []);

  const handleClose = useCallback(() => {
    setCalendlyOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setCalendlyOpen(true);
  }, []);

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (calendlyOpen) {
        if (smartlookClient.initialized()) {
          smartlookClient.track('help', { wasTriggered: true });
        }
        handleClose();
      }
    },
  });

  return (
    <>
      <Button variant={variant} size="XS" color="primary" onClick={handleOpen} {...otherProps}>
        {children}
      </Button>
      <PopupModal
        // TODO: Add event handling (https://github.com/tcampb/react-calendly#usecalendlyeventlistener); Not specified!
        prefill={{
          firstName,
          email: email ?? undefined,
          lastName,
          name: getFullName({ firstName, lastName }) || undefined,
        }}
        pageSettings={{
          primaryColor: '6000FC',
          textColor: '000',
          hideGdprBanner: true,
        }}
        url={calendlyUrl!}
        onModalClose={handleClose}
        open={calendlyOpen}
        rootElement={document.getElementById('calendly-root')!}
      />
    </>
  );
};

export default CalendlyButton;
