export type NodeId = string;
export type EdgeId = string;
export type ServiceUrn = string;

export type Path = {
  nodes: NodeId[];
  edges: EdgeId[];
};

export type PropertySource = {
  id: EdgeId | NodeId | ServiceUrn;
  label?: string;
  kind: 'edge' | 'task' | 'global';
};

type PropertyBase = {
  label: string;
  name: string;
  source: PropertySource;
};

export type SimpleProperty = PropertyBase;

export type ExtendedProperty = PropertyBase & {
  type: string[];
  required: boolean;
  properties: Property[];
};

export type Property = ExtendedProperty | SimpleProperty;

export const isExtendedProperty = (property: Property): property is ExtendedProperty => 'type' in property;
