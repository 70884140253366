import { useDeleteWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { enqueueSnackbar } from 'notistack';
import { useInvalidateWorkflowVersions } from './useInvalidateWorkflowVersions';
import { WorkflowVersionRow } from '../../types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useSimpleDialogContext } from '@verticeone/design-system/src';
import { useLocalizeVersionName } from '../../utils';

export const useDeleteWorkflowVersion = (row: WorkflowVersionRow, { onComplete }: { onComplete: () => void }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [deleteWorkflow, { isLoading: isDeletingWorkflowVersion }] = useDeleteWorkflowVersionMutation();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();
  const localizeVersionName = useLocalizeVersionName();

  const { getConfirmation } = useSimpleDialogContext();

  const deleteWorkflowVersion = useCallback(() => {
    void (async () => {
      const confirmResult = await getConfirmation({
        title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DELETE_CONFIRMATION.TITLE'),
        description: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DELETE_CONFIRMATION.DESCRIPTION'),
        okButton: {
          label: t('ENTITIES.WORKFLOW_VERSION.ACTIONS.DELETE'),
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        },
        cancelButton: {
          label: t('DIALOG.BUTTONS.CANCEL'),
        },
      });

      if (confirmResult.status === 'cancel') {
        onComplete();
        return;
      }

      const response = await deleteWorkflow({ accountId, workflowId: row.id, workflowVersion: row.versionId });
      const versionName = localizeVersionName(row.versionName);
      if (!('error' in response)) {
        enqueueSnackbar(t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_DELETED', { name: versionName }), {
          variant: 'success',
        });
        invalidateWorkflowVersions();
      } else {
        enqueueSnackbar(
          t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_DELETE_FAILED', { name: versionName }),
          {
            variant: 'error',
          }
        );
      }
      onComplete();
    })();
  }, [
    accountId,
    deleteWorkflow,
    getConfirmation,
    invalidateWorkflowVersions,
    onComplete,
    localizeVersionName,
    row.id,
    row.versionId,
    row.versionName,
    t,
  ]);

  return {
    deleteWorkflowVersion,
    isDeletingWorkflowVersion,
  };
};
