import {
  DataTable,
  MonitoringValueCheckResult,
  MulticlassBinnedTestResult,
  TableType,
  TimeSeriesDataPoint,
  TimeSeriesDataValue,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { chain, keyBy, range, sumBy } from 'lodash';
import format from 'date-fns/format';
import { addMonths } from 'date-fns';

export type CategoryValues = {
  id: string;
  data: number[];
};

export const DATE_FORMAT = 'yyyy-MM-dd';
const NUMBER_OF_MONTHS = 12;
export const fillMissingMonths = <ValuesType>(
  dataPoints: { time: string; values: ValuesType }[],
  startDate: Date,
  fillWith: ValuesType
): { time: string; values: ValuesType }[] => {
  const dataPointsByDate = keyBy(dataPoints, (p) => format(new Date(p.time), DATE_FORMAT));

  return range(NUMBER_OF_MONTHS).map((i) => {
    const monthStartStr = format(addMonths(startDate, i), DATE_FORMAT);
    return (
      dataPointsByDate[monthStartStr] ?? {
        time: monthStartStr,
        values: fillWith,
      }
    );
  });
};

export const sumPositiveDataPointValues = ({ values }: TimeSeriesDataPoint, unitIndex = 0) =>
  sumPositiveTimeStreamValues(values, unitIndex);

export const sumPositiveTimeStreamValues = (values: TimeSeriesDataValue[], unitIndex = 0) =>
  sumBy(values, ({ values: unitValues }) => (unitValues[unitIndex] >= 0 ? unitValues[unitIndex] : 0));

export const sumTimeSeriesValues = (values: TimeSeriesDataValue[], unitIndex = 0) =>
  sumBy(values, ({ values: unitValues }) => unitValues[unitIndex]);

export const findFirstCurrency = (dataPoints: TimeSeriesDataPoint[], unitIndex = 0) => {
  for (const dataPoint of dataPoints) {
    const currency = dataPoint.values[0]?.units[unitIndex];
    if (typeof currency !== 'undefined') {
      return currency;
    }
  }
  return undefined;
};

export const isoDateToTimestamp = (isoDate: string) => new Date(isoDate).getTime();

/**
 * Returns map of every category used in the datasource with the sum of all its values
 * {
 *   'Usage': 4500
 *   'Tax': 150
 *   'Credit': -4000
 * }
 *
 * @param allCostUsageValues Data source
 */
export const sumValuesByDimension = (allCostUsageValues: TimeSeriesDataValue[][], unitIndex = 0) => {
  const usedCategories = {} as { [name: string]: number };
  allCostUsageValues.forEach((costUsageValuesPerX) => {
    costUsageValuesPerX.forEach((seriesItem) => {
      const categoryName = seriesItem.dimensions?.[0];
      const savedCategoryValue = usedCategories[categoryName] || 0;
      if (categoryName) {
        usedCategories[categoryName] = savedCategoryValue + seriesItem.values[unitIndex];
      }
    });
  });
  return usedCategories;
};

export const getLargestNCategories = (
  monthlyData: TimeSeriesDataValue[][],
  {
    maxNumberOfSeries,
    aggregatedCategory,
    unitIndex,
  }: { maxNumberOfSeries: number; aggregatedCategory: string; unitIndex?: number }
): string[] => {
  const usedCategories = sumValuesByDimension(monthlyData, unitIndex);

  // We try to get the bigger positive values on top and negative values on the bottom to avoid the small values with the rounded corners
  return Object.entries(usedCategories)
    .sort(([, a], [, b]) => b - a)
    .reduce((acc, [name, value], index) => {
      if (index < maxNumberOfSeries) {
        return [...acc, name];
      }
      if (index === maxNumberOfSeries) {
        return [...acc, aggregatedCategory];
      }
      return acc;
    }, [] as string[]);
};

/**
 * Returns an array containing data for used categories in given order.
 * [
 *    {id: 'Usage', data: [7, 8, 6, 5, 4]},
 *    {id: 'Tax', data: [1, 4, 5, 4, 8]},
 *    {id: 'Others', data: [17, 10, 16, 6, 11]},
 * ]
 * @param allCostUsageValues Data source
 * @param usedCategories IDs of relevant categories
 * @param aggregatedCategory ID of the category which is used as an aggregate for all categories which are not in the usedCategories array
 * @param unitIndex if a value with more than 1 unit is present, use the one at the specified index.
 */
export const getSeriesByCategory = (
  allCostUsageValues: TimeSeriesDataValue[][],
  usedCategories: string[],
  { aggregatedCategory, unitIndex = 0 }: { aggregatedCategory?: string; unitIndex?: number } = {}
): CategoryValues[] => {
  const allCostUsageValuesGroupedByCategories: { [category: string]: number }[] = allCostUsageValues.map((valuesAtX) =>
    chain(valuesAtX)
      .filter(({ dimensions }) => dimensions.length > 0 && Boolean(dimensions[0]))
      .groupBy(({ dimensions }) =>
        usedCategories.includes(dimensions[0]) && dimensions[0] !== aggregatedCategory
          ? dimensions[0]
          : aggregatedCategory
      )
      .mapValues((itemsInCategory) =>
        // Sum values in category
        itemsInCategory.reduce((sum, { values }) => sum + values[unitIndex], 0)
      )
      .value()
  );

  return usedCategories.map((category) => ({
    id: category,
    data: allCostUsageValuesGroupedByCategories.map((categoriesAtX) => categoriesAtX[category] ?? 0),
  }));
};

/**
 * Creates two level hierarchy from two or more dimensional data.
 * @param rawData
 */
export const getHierarchyData = (rawData: TimeSeriesDataPoint[]) =>
  chain(rawData)
    .flatMap((x) => x.values)
    .groupBy((x) => x.dimensions[0])
    .map((valuesPerCategory, category) => ({
      name: category,
      value: sumPositiveTimeStreamValues(valuesPerCategory),
      children: chain(valuesPerCategory)
        .groupBy((x) => x.dimensions[1])
        .map((valuesPerProduct, product) => ({
          name: product,
          value: sumPositiveTimeStreamValues(valuesPerProduct),
        }))
        .value(),
    }))
    .value();

/**
 * Converts string value to the value specified by type parameter.
 * @param field
 * @param type
 */
const parseValue = (field: string, type?: string) => {
  switch (type) {
    case 'double':
      return Number(field);
    case 'integer':
      return Number(field);
    case 'epochtime':
      return Number(field) * 1000;
    case 'timestamp':
      return Number(field);
    case 'datetime':
    case 'isodate':
      return isoDateToTimestamp(field);
    case 'boolean':
      return field === 'true';
    default:
      return field;
  }
};

/**
 * Transforms TableData or MonitoringValueCheckResult to the array of objects with prop names specified by the second argument
 * and parsed values (i.e. numeric values are returned as numbers instead of strings)
 * @param tableData
 * @param template
 */
export const getTableData = (
  tableData: TableType | MonitoringValueCheckResult | DataTable,
  template?: { [key: string]: string }
) => {
  const { columns, data, dataTypes } = tableData;

  return data?.map((record: string[]) => {
    return record.reduce((acc, field, index) => {
      const fieldName = columns[index];
      const fieldType = dataTypes?.[index];
      const mappedTo = template ? template[fieldName] : fieldName;

      if (mappedTo) {
        return {
          ...acc,
          [mappedTo]: parseValue(field, fieldType),
        };
      }

      return acc;
    }, {});
  });
};

/**
 * Transforms ChartData or MonitoringValueCheckResult to the array of arrays
 * and parsed values (i.e. numeric values are returned as numbers instead of strings)
 * @param tableData
 */
export const getChartData = (tableData: TableType | MonitoringValueCheckResult | DataTable) => {
  const { data, dataTypes } = tableData;
  return data?.map((record: Array<string>) => {
    return record.map((field, index) => {
      return parseValue(field, dataTypes![index]);
    });
  });
};

export const getTestTableData = (tableData: MulticlassBinnedTestResult, template?: { [key: string]: string }) => {
  const { labelCategories, labelTypes, labels } = tableData;

  return labels?.map((record: string[]) => {
    return record.reduce((acc, field, index) => {
      const fieldName = labelCategories![index];
      const fieldType = labelTypes?.[index];
      const mappedTo = template ? template[fieldName] : fieldName;

      if (mappedTo) {
        return {
          ...acc,
          [mappedTo]: parseValue(field, fieldType),
        };
      }

      return acc;
    }, {});
  });
};

type TransformIntoTemplateObjectArrayParams = {
  labels: string[];
  data: (string | number)[][];
  template: { [key: string]: string };
};

export const transformIntoTemplateObjectArray = ({
  labels,
  data,
  template,
}: TransformIntoTemplateObjectArrayParams) => {
  return data.map((record) => {
    return record.reduce((acc, field, index) => {
      const fieldName = labels[index];
      const mappedTo = template[fieldName];

      if (mappedTo) {
        return {
          ...acc,
          [mappedTo]: field,
        };
      }

      return acc;
    }, {});
  });
};

/**
 * Returns an array of indexes. Each item of the main array is mapped to an index of the same item in the indexed array.
 * Example: The mainArray ['A', 'B', 'C', 'D'] and indexedArray ['D', 'A', 'B'] should result into [1, 2, undefined, 0]
 * @param mainArray
 * @param indexedArray
 */
export const getLabelIndexMapping = (mainArray: string[], indexedArray: string[]) => {
  return mainArray.map((mainLabel) => {
    const index = indexedArray.findIndex((indexedLabel) => mainLabel === indexedLabel);
    if (index >= 0) {
      return index;
    }
    return undefined;
  });
};

/**
 * Returns an array of indexes. Each item of the check result is mapped to an index of the same item in the test result.
 * @param checkResult
 * @param testResult
 */
export const getCheckToTestIndexMapping = (
  checkResult: MonitoringValueCheckResult,
  testResult: MulticlassBinnedTestResult
) => {
  const { columns, data } = checkResult;
  const { labelCategories, labels } = testResult;

  if (!labels || !labelCategories) {
    return data.map(() => undefined);
  }

  // Find the indexes for each item in the test record
  const testToChecklabelIndexMapping = getLabelIndexMapping(labelCategories, columns);

  return data.map((checkRecord) => {
    const index = labels.findIndex((testRecord) => {
      return testRecord.every((testRecordItem, testRecordItemIndex) => {
        const checkRecordItemIndex = testToChecklabelIndexMapping[testRecordItemIndex];
        const checkRecordItem = checkRecordItemIndex !== undefined ? checkRecord[checkRecordItemIndex] : undefined;
        return testRecordItem === checkRecordItem;
      });
    });

    if (index >= 0) {
      return index;
    }

    return undefined;
  });
};
