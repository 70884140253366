import { useFormatDate, useFormattedTimeAgo } from '@verticeone/utils/formatting';
import TwoRowCell from './TwoRowCell';

type DateCellProps = {
  date: string;
};

export const DateCell = ({ date: dateString }: DateCellProps) => {
  const formatDate = useFormatDate();
  const { getFullFormattedTimeAgo } = useFormattedTimeAgo();

  const formattedDate = formatDate(dateString);
  const formattedTimeAgo = getFullFormattedTimeAgo(new Date(dateString));

  return <TwoRowCell subtitle={formattedDate} title={formattedTimeAgo} />;
};
