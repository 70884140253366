import React from 'react';

import { FormData } from './schema';
import { OfferFormEntry } from '../shared/formFields/OfferFormEntry';
import { useWatch } from 'react-hook-form';
import { useAccountSettings } from '../../../../../../../hooks/useAccountSettings';
import { Loader } from '@verticeone/design-system/src';

export const BaselineOfferForm = () => {
  const parentAccountId = useWatch<FormData, 'parentAccountId'>({ name: 'parentAccountId' });
  const { data: accountSettings, isLoading } = useAccountSettings(parentAccountId || undefined);

  if (isLoading) {
    return <Loader />;
  }

  return <OfferFormEntry<FormData> name="baselineOffer" defaultCurrency={accountSettings?.preferredCurrency} />;
};
