import { useTranslation } from 'react-i18next';
import { Filter } from '../types';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import usePaymentTermsOptions from '../../../../hooks/usePaymentTermsOptions';

const PaymentTermsFilter = () => {
  const { t } = useTranslation();
  const options = usePaymentTermsOptions();

  return (
    <GenericMultiselectFilter
      name={Filter.PAYMENT_TERMS}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.PAYMENT_TERMS')}
      testId={'payment-terms-filter'}
    />
  );
};

export default PaymentTermsFilter;
