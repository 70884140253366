import { FC, RefObject, forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

import { Avatar } from '../Avatar';
import { CommentHeader } from './CommentHeader';
import { Text, EllipsisText } from '../Text';
import { CommentMetadata, Content } from './types';
import i18n from '../../translate';
import { Grid } from '../Grid';
import { TextMention } from '../Mentions';
import { VerticeVIcon } from '../../assets';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import { CommentHeaderMenu } from './CommentHeaderMenu';
import { RemoveCommentCallback } from '../Comments';

export type CommentVariant = 'default' | 'branded';

export type CommentProps = Omit<CommentMetadata, 'content'> & {
  content: Content[];
  variant: CommentVariant;
  truncate?: boolean;
  truncateLines?: number;
  isEdited?: boolean;
  isEditing?: boolean;
  removeComment: RemoveCommentCallback;
  canEditComents: boolean;
  editComment: (id: string) => void;
};

type CommentContentProps = {
  content: Content[];
};

const CommentContent: FC<CommentContentProps> = ({ content }) => {
  return content.map((value, i) => (typeof value === 'string' ? value : <TextMention key={i} {...value} />));
};

const BadgeContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.global.color.purple['10'].main,
  borderRadius: '50%',
  position: 'absolute',
  right: '-20%',
  bottom: '-22%',
  width: theme.spacing(3.75),
  height: theme.spacing(3.75),
  svg: {
    margin: theme.spacing(0),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const Clickable = styled('div')(() => ({
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline',
  },
}));

export const Comment = forwardRef<HTMLDivElement, CommentProps>(
  (
    {
      variant,
      content,
      truncate = false,
      truncateLines = 5,
      isCommentAuthor,
      removeComment,
      editComment,
      isEditing,
      messageId,
      isEdited = false,
      isDeleted,
      canEditComents,
      ...metadataProps
    },
    ref
  ) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLSpanElement>(null);

    const { width } = useWindowDimensions();
    const { palette } = useTheme();

    useLayoutEffect(() => {
      if (!contentRef.current) return;

      setIsTruncated(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }, [contentRef, width]);

    const isBranded = variant === 'branded';
    const userAvatarAccent = isCommentAuthor ? 'secondary' : 'neutral';

    const handleShowMore = () => setIsExpanded(true);

    const brandBgColor = isEditing ? palette.primary.color3 : palette.primary.color4;
    const baseBgColor = isEditing ? palette.core.color2 : palette.core.bg;

    return (
      <Stack padding={4} width={'100%'} bgcolor={isBranded ? brandBgColor : baseBgColor} gap={2} ref={ref}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <Stack direction="row" alignItems="center" gap={2}>
            <Avatar
              personId={metadataProps.personId}
              personName={metadataProps.personName}
              size="XS"
              badge={
                isBranded && (
                  <BadgeContainer container alignItems="center" justifyItems="center">
                    <VerticeVIcon />
                  </BadgeContainer>
                )
              }
              color={isBranded ? 'primary' : userAvatarAccent}
              invertColors={isCommentAuthor}
            />
            <CommentHeader isBranded={isBranded} isCommentAuthor={isCommentAuthor} {...metadataProps} />
          </Stack>
          <CommentHeaderMenu
            canEditComents={canEditComents}
            messageId={messageId}
            isDeleted={isDeleted}
            removeComment={removeComment}
            isCommentAuthor={isCommentAuthor}
            editComment={editComment}
          />
        </Stack>
        <Stack paddingLeft={8} alignItems="top" gap={2}>
          <CommentMessage
            content={content}
            contentRef={contentRef}
            handleShowMore={handleShowMore}
            isExpanded={isExpanded}
            isTruncated={isTruncated}
            truncate={truncate}
            truncateLines={truncateLines}
            isDeleted={isDeleted}
            isEdited={isEdited}
          />
        </Stack>
      </Stack>
    );
  }
);

type CommentMessageProps = {
  isDeleted?: boolean;
  truncate: boolean;
  truncateLines: number;
  isTruncated: boolean;
  isExpanded: boolean;
  isEdited?: boolean;
  content: Content[];
  contentRef: RefObject<HTMLSpanElement>;
  handleShowMore: () => void;
};
const CommentMessage: FC<CommentMessageProps> = ({
  content,
  contentRef,
  isExpanded,
  isTruncated,
  truncate,
  truncateLines,
  isEdited,
  isDeleted,
  handleShowMore,
}) => {
  const { t } = useTranslation(undefined, { i18n });

  if (isDeleted) {
    return (
      <Text sx={{ fontStyle: 'italic' }} variant="body-regular" size="S" color="text3">
        {t('COMMENTS.MESSAGE_DELETED_BY_ADMIN')}
      </Text>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="flex-end" gap={1}>
        {!truncate || isExpanded ? (
          <Text variant="body-regular" size="S" whiteSpace="break-spaces">
            <CommentContent content={content} />
          </Text>
        ) : (
          <EllipsisText
            variant="body-regular"
            size="S"
            lineClamp={truncateLines}
            ref={contentRef}
            whiteSpace="break-spaces"
          >
            <CommentContent content={content} />
          </EllipsisText>
        )}
        {isEdited && (
          <Text size="XS" variant="body-regular" color="text3" whiteSpace="break-spaces">
            ({t('COMMENTS.EDITED')})
          </Text>
        )}
      </Stack>
      {truncate && isTruncated && !isExpanded && (
        <Clickable>
          <Text size="S" variant="body-regular" onClick={handleShowMore} whiteSpace="break-spaces">
            {t('COMMENTS.EXPAND_COMMENT')}
          </Text>
        </Clickable>
      )}
    </>
  );
};
