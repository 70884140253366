import { ComponentType } from 'react';
import { DateFieldProps as MuiDateFieldProps } from '@mui/x-date-pickers-pro';
import { DateFieldProps } from './styledVariants/common';
import { DateFieldVariant } from './DateField';
import OutlinedDateField from './styledVariants/OutlinedDateField';
import { Dayjs } from 'dayjs';
import SolidDateField from './styledVariants/SolidDateField';
import GhostDateField from './styledVariants/GhostDateField';

const variantDefinitions: Record<
  DateFieldVariant,
  { component: ComponentType<DateFieldProps>; muiVariant: NonNullable<MuiDateFieldProps<Dayjs, boolean>['variant']> }
> = {
  ghost: { component: GhostDateField, muiVariant: 'standard' },
  solid: { component: SolidDateField, muiVariant: 'filled' },
  outlined: { component: OutlinedDateField, muiVariant: 'outlined' },
};

export default variantDefinitions;
