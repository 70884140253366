import { useCallback, useState } from 'react';
import { DrilldownEventObject } from 'highcharts';
import { mergeOptions } from '../utils/optionsUtils';
import { UpdateOptionsFn } from '../types';

/**
 * Listens for drilldown/drillup clicks and provides the currently opened serie ID for reading.
 */
export const useWatchDrilldownSerieId = () => {
  const [drilldownSerieId, setDrilldownSerieId] = useState<string | undefined>(undefined);

  const handleDrilldown = useCallback(({ seriesOptions }: DrilldownEventObject) => {
    setDrilldownSerieId(seriesOptions?.id);
  }, []);

  const handleDrillup = useCallback((event: any) => {
    setDrilldownSerieId(undefined);
  }, []);

  const apply = useCallback<UpdateOptionsFn>(
    (options) =>
      mergeOptions(
        {
          chart: {
            events: { drilldown: handleDrilldown, drillup: handleDrillup },
          },
        },
        options
      ),
    [handleDrilldown, handleDrillup]
  );

  return { applyWatchDrilldownSerieId: apply, drilldownSerieId };
};
