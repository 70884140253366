import React, { useRef } from 'react';
import { Stack } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorCode } from 'react-dropzone';
import { styled, css } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import i18n from '../../translate';
import { Text } from '../Text';
import { IconButton } from '../IconButton/IconButton';
import { CircularProgressBar } from '../ProgressBar';
import type { DesignSystemSize } from '../../types';
import { useThemeContext } from '../../theme/ThemeProvider';
import { type FileAccepted, type FileRejected, formatSize, isFileRejectedError, getShortenFileName } from './utils';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '../Dialog';
import { Button } from '../Button';
import { useFileupload } from './Fileupload';
import { useGetErrorMessage } from './hooks';
import { useResizeObserver } from '../../utils';

import { ReactComponent as DocIcon } from './assets/doc.svg';
import { ReactComponent as CsvIcon } from './assets/csv.svg';
import { ReactComponent as JpgIcon } from './assets/jpg.svg';
import { ReactComponent as PdfIcon } from './assets/pdf.svg';
import { ReactComponent as PngIcon } from './assets/png.svg';
import { ReactComponent as XlsIcon } from './assets/xls.svg';

export type Column = 'content' | 'actions' | 'icon' | 'name' | 'date' | 'size' | 'remove';

type FileItemProps = {
  file: FileAccepted | FileRejected;
  size?: DesignSystemSize;
  hiddenColumns?: Array<Column>;
  hideErrorFiles?: boolean;
  maxFileNameLength?: number;
  dynamicFileNameLength?: boolean;
  isDisabled?: boolean;
  onFileNameClick?: (file: FileAccepted | FileRejected) => void;
};

type StyledWithError = {
  $hasError: boolean;
  $size: DesignSystemSize;
  theme?: Theme;
};

type StyledIconProps = {
  $isUploading: boolean;
  $isDisabled: boolean;
} & StyledWithError;

type DeleteDialogProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  file: FileAccepted | FileRejected;
};

const iconSize: Record<DesignSystemSize, { size: number; padding: number }> = {
  XL: { size: 30, padding: 5 },
  L: { size: 27, padding: 4.5 },
  M: { size: 24, padding: 4 },
  S: { size: 21, padding: 3.5 },
  XS: { size: 18, padding: 3 },
  XXS: { size: 15, padding: 2.5 },
};

const FileItemWrapper = styled(Stack)<Omit<StyledWithError, '$size'>>(({ theme, $hasError }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(8),
  padding: '1rem',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  border: `1px solid ${$hasError ? theme.palette.error.color1 : theme.palette.neutral.color3}`,
  background: theme.palette.input.bg,
  '&:hover': {
    backgroundColor: theme.palette.input.color1,
  },
}));

const ContentWrapper = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ActionWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const IconWrapper = styled(Stack)<StyledWithError>(({ theme, $hasError, $size }) => ({
  padding: `${iconSize[$size].padding}px`,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette[$hasError ? 'error' : 'neutral'].color4,
}));

const styledIconCss = css<StyledIconProps>(({ theme, $hasError, $isUploading, $isDisabled, $size }) => ({
  width: `${iconSize[$size].size}px`,
  height: `${iconSize[$size].size}px`,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  color: (() => {
    if ($hasError) {
      return theme.palette.error.color1;
    }
    if ($isUploading || $isDisabled) {
      return theme.palette.inactive.color2;
    }
    return theme.palette.neutral.color2;
  })(),
}));

const StyledDefaultIcon = styled(InsertDriveFileOutlinedIcon)<StyledIconProps>(() => styledIconCss);
const StyledPdfIcon = styled(PdfIcon)<StyledIconProps>(() => styledIconCss);
const StyledXlsIcon = styled(XlsIcon)<StyledIconProps>(() => styledIconCss);
const StyledJpgIcon = styled(JpgIcon)<StyledIconProps>(() => styledIconCss);
const StyledDocIcon = styled(DocIcon)<StyledIconProps>(() => styledIconCss);
const StyledCsvIcon = styled(CsvIcon)<StyledIconProps>(() => styledIconCss);
const StyledPngIcon = styled(PngIcon)<StyledIconProps>(() => styledIconCss);

const getIcon = (name: string) => {
  const ext = name.split('.').pop()?.toLowerCase() ?? '';
  const icons = [
    { type: ['pdf'], icon: StyledPdfIcon },
    { type: ['csv'], icon: StyledCsvIcon },
    { type: ['doc', 'docx'], icon: StyledDocIcon },
    { type: ['xls', 'xlsx'], icon: StyledXlsIcon },
    { type: ['jpg', 'jpeg'], icon: StyledJpgIcon },
    { type: ['png'], icon: StyledPngIcon },
  ];
  return icons.find((icon) => icon.type.includes(ext))?.icon ?? StyledDefaultIcon;
};

const infoSizeMap: Record<DesignSystemSize, DesignSystemSize> = {
  XL: 'M',
  L: 'S',
  M: 'XS',
  S: 'XS',
  XS: 'XXS',
  XXS: 'XXS',
};

const progressBarSizeMap: Record<DesignSystemSize, DesignSystemSize> = {
  XL: 'M',
  L: 'S',
  M: 'XS',
  S: 'XXS',
  XS: 'XXS',
  XXS: 'XXS',
};

const textWidth: Record<DesignSystemSize, number> = {
  XL: 20,
  L: 18,
  M: 16,
  S: 14,
  XS: 12,
  XXS: 10,
};

const DEFAULT_MAX_FILE_NAME_LENGTH = 25;

const DeleteDialog = ({ isOpen, setIsOpen, file }: DeleteDialogProps) => {
  const { removeFiles } = useFileupload();
  const { t } = useTranslation(undefined, { i18n });

  return (
    <Dialog open={isOpen} size="M" testId="close-dialog">
      <DialogHeader> {t('DRAG_AND_DROP.FILE_ITEM.HEADER')}</DialogHeader>
      <DialogContent>
        <Text variant="body-regular" size="XL" color="text2">
          <Trans
            i18n={i18n}
            i18nKey="DRAG_AND_DROP.FILE_ITEM.DESCRIPTION"
            tOptions={{ fileName: getShortenFileName(file.file.name, DEFAULT_MAX_FILE_NAME_LENGTH) }}
            components={{ highlight: <Text variant="body-bold" color="text2" size="XL" /> }}
          />
        </Text>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={2} alignSelf="flex-end">
          <Button variant="outline" size="M" color="primary" onClick={() => setIsOpen(false)}>
            {t('DIALOG.BUTTONS.CANCEL')}
          </Button>
          <Button
            variant="solid"
            size="M"
            color="primary"
            onClick={() => {
              setIsOpen(false);
              void removeFiles([file]);
            }}
          >
            {t('DIALOG.BUTTONS.DELETE')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

type FileNameColumnProps = {
  file: FileAccepted | FileRejected;
  isInactive: boolean;
  hasError: boolean;
  size: DesignSystemSize;
  maxFileNameLength: number;
  dynamicFileNameLength?: boolean;
  containerRef?: React.MutableRefObject<HTMLInputElement>;
  onFileNameClick?: (file: FileAccepted | FileRejected) => void;
};

const FileNameColumn = ({
  containerRef,
  maxFileNameLength,
  dynamicFileNameLength,
  file,
  size,
  isInactive,
  hasError,
  onFileNameClick,
}: FileNameColumnProps) => {
  const { width: containerWidth } = useResizeObserver(containerRef);
  const shortenedFileNameLength = dynamicFileNameLength
    ? Math.max(Math.floor(containerWidth / textWidth[size]), 10)
    : maxFileNameLength;

  const handleFileClick = () => {
    if (!isInactive) {
      onFileNameClick?.(file);
    }
  };

  const isClickable = !isInactive && onFileNameClick;

  return (
    <Text
      variant="body-regular"
      size={size}
      onClick={handleFileClick}
      sx={{ cursor: isClickable ? 'pointer' : 'text', alignSelf: 'flex-start' }}
      color={(() => {
        if (isInactive) {
          return 'inactive2';
        }
        if (hasError) {
          return 'error1';
        }
        return 'text1';
      })()}
      title={file.file.name}
    >
      {getShortenFileName(file.file.name, shortenedFileNameLength)}
    </Text>
  );
};

export const FileItem = ({
  size: customSize,
  file,
  hiddenColumns,
  isDisabled,
  hideErrorFiles,
  maxFileNameLength = DEFAULT_MAX_FILE_NAME_LENGTH,
  dynamicFileNameLength,
  onFileNameClick,
}: FileItemProps) => {
  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const { size: parentSize, filesProgress, updateFileProgress } = useFileupload();
  const { locale } = useThemeContext();
  const hasError = isFileRejectedError(file);
  const getErrorMessage = useGetErrorMessage();
  const isColumnEnabled = (column: Column) => !hiddenColumns?.includes(column) ?? true;
  const isInProgress = filesProgress[file.file.name]?.status === 'uploading';
  const size = customSize || parentSize || 'XL';
  const Icon = getIcon(file.file.name);

  if (!!hideErrorFiles && hasError) {
    return null;
  }

  return (
    <>
      <DeleteDialog file={file} isOpen={isDeleteDialogOpen} setIsOpen={setIsDeleteDialogOpen} />
      <FileItemWrapper $hasError={hasError}>
        {isColumnEnabled('content') && (
          <ContentWrapper>
            <Stack direction="row" alignItems="center" gap="1rem" flexGrow={1}>
              {isColumnEnabled('icon') && (
                <IconWrapper $size={size} $hasError={hasError}>
                  <Icon
                    $hasError={hasError}
                    $isUploading={isInProgress}
                    $size={size}
                    $isDisabled={isDisabled ?? false}
                  />
                </IconWrapper>
              )}
              <Stack gap={2.5} ref={containerRef} flexGrow={1}>
                {isColumnEnabled('name') && (
                  <FileNameColumn
                    file={file}
                    containerRef={containerRef}
                    maxFileNameLength={maxFileNameLength}
                    dynamicFileNameLength={dynamicFileNameLength}
                    size={size}
                    isInactive={!!(isInProgress || isDisabled)}
                    hasError={hasError}
                    onFileNameClick={onFileNameClick}
                  />
                )}
                {hasError &&
                  file.errors.map((error) => (
                    <Text
                      key={error.code}
                      variant="body-regular"
                      size={infoSizeMap[size]}
                      color="error1"
                      sx={{ opacity: 0.4 }}
                    >
                      {getErrorMessage(error.code as ErrorCode)}
                    </Text>
                  ))}
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" gap={8}>
              {!hasError && isColumnEnabled('size') && !isInProgress && (
                <Text variant="body-bold" size={infoSizeMap[size]} color={isDisabled ? 'inactive2' : 'text3'}>
                  {formatSize(file.file.size)}
                </Text>
              )}

              {isColumnEnabled('date') && !isInProgress && (
                <Text variant="body-bold" size={infoSizeMap[size]} color={isDisabled ? 'inactive2' : 'text3'}>
                  {new Intl.DateTimeFormat(locale, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }).format(file.file.lastModified)}
                </Text>
              )}
            </Stack>
          </ContentWrapper>
        )}
        {isColumnEnabled('actions') && (
          <ActionWrapper>
            {isInProgress && (
              <CircularProgressBar
                size={size}
                variant="icon-button"
                percent={filesProgress[file.file.name].progress}
                color="success"
              >
                <IconButton
                  isCircle
                  icon={CloseIcon}
                  size={progressBarSizeMap[size]}
                  variant="ghost"
                  color={hasError ? 'error' : 'neutral'}
                  onClick={() => updateFileProgress({ file, status: 'cancelled' })}
                />
              </CircularProgressBar>
            )}
            {isColumnEnabled('remove') && !isInProgress && (
              <IconButton
                icon={DeleteIcon}
                size={size}
                variant="ghost"
                color={hasError ? 'error' : 'neutral'}
                onClick={() => setIsDeleteDialogOpen(true)}
                disabled={isDisabled}
              />
            )}
          </ActionWrapper>
        )}
      </FileItemWrapper>
    </>
  );
};
