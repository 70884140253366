import React, { ReactNode } from 'react';
import { Box, Stack, styled } from '@mui/material';
import { ConnectorConfig } from './types';
import Connector from './Connector';
import { AWS_BRAND_COLOR } from '../../constants';

const StyledNode = styled('div')<{
  width: number;
  strokeWidth: number;
  selected: boolean;
  $clickable: boolean;
}>(({ theme, width, strokeWidth, selected, $clickable }) => ({
  width: width + 'px',
  border: strokeWidth + 'px solid',
  borderColor: selected ? theme.palette[AWS_BRAND_COLOR].color2 : theme.palette.core.color4,
  borderRadius: '16px',
  cursor: $clickable ? 'pointer' : 'default',
}));

type NodeWrapperProps = {
  width: number;
  horizontalPadding: number;
  verticalPadding: number;
  strokeWidth: number;
  connectors: ConnectorConfig;
  selected?: boolean;
  highlightLeftConnector: boolean;
  highlightRightConnector: boolean;
  onClick?: (event: any) => void;
  children: ReactNode;
};

/**
 * Convert the overall node connector config to border config for top-left connector.
 * @param config Node connector configuration
 */
const getUpperLeftBorders = (config: ConnectorConfig) => ({
  top: config.left,
  right: config.topLeft || config.topCenter,
});

/**
 * Convert the overall node connector config to border config for top-right connector.
 * @param config Node connector configuration
 */
const getUpperRightBorders = (config: ConnectorConfig) => ({
  top: config.right,
  left: config.topRight,
});

/**
 * Convert the overall node connector config to border config for bottom-left connector.
 * @param config Node connector configuration
 */
const getBottomLeftBorders = (config: ConnectorConfig) => ({ right: config.bottom });

const NodeWrapper = ({
  width,
  horizontalPadding,
  verticalPadding,
  strokeWidth,
  connectors,
  selected,
  highlightLeftConnector,
  highlightRightConnector,
  onClick,
  children,
}: NodeWrapperProps) => {
  const handleClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Stack>
      <Stack direction="row" height={verticalPadding}>
        <Connector
          strokeWidth={strokeWidth}
          borders={getUpperLeftBorders(connectors)}
          rounded={!connectors.right || (selected && !connectors.topCenter)}
          verticalHighlight={selected}
          horizontalHighlight={highlightLeftConnector}
        />
        <Connector
          strokeWidth={strokeWidth}
          borders={getUpperRightBorders(connectors)}
          rounded={!connectors.left || (selected && !connectors.topCenter)}
          verticalHighlight={selected}
          horizontalHighlight={highlightRightConnector}
        />
      </Stack>
      <Box px={horizontalPadding + 'px'}>
        <StyledNode
          width={width}
          strokeWidth={strokeWidth}
          selected={!!selected}
          $clickable={!!onClick}
          onClick={handleClick}
        >
          {children}
        </StyledNode>
      </Box>
      <Stack direction="row" minHeight={verticalPadding} flexGrow={1}>
        <Connector strokeWidth={strokeWidth} borders={getBottomLeftBorders(connectors)} verticalHighlight={selected} />
      </Stack>
    </Stack>
  );
};

export default NodeWrapper;
