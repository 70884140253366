import styled from '@mui/styled-engine';
import { TaskColorVariant } from './types';

export const GradientWrapper = styled('div')<{ $colorVariant: TaskColorVariant; $withShadow?: boolean }>(
  ({ theme, $colorVariant, $withShadow = false }) => ({
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    borderRadius: 10,
    padding: 2,
    overflow: 'hidden',
    boxShadow: $withShadow
      ? theme.palette.global.getShadow({
          // shadows are not defined for neutral and transparent colors - use core color instead
          color: $colorVariant === 'neutral' || $colorVariant === 'transparent' ? 'core' : $colorVariant,
          type: 'soft',
          depth: '1z',
          distance: '50',
        })
      : 'none',

    '&::before': {
      content: '""',
      position: 'absolute',
      background: `radial-gradient(72.52% 106.66% at 0% 27.48%, ${theme.palette[$colorVariant].color2} 0%, #F8FAFC 100%)`,
      animation: 'spinner 4s linear infinite',
      paddingBottom: 'calc(100% * 1.41421356237)',
      width: 'calc(100% * 1.41421356237)',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) rotate(0deg)',
      borderRadius: '100%',
      zIndex: -1,
    },

    '@keyframes spinner': {
      '0%': {
        transform: 'translate(-50%, -50%) rotate(0deg)',
      },
      '100%': {
        transform: 'translate(-50%, -50%) rotate(360deg)',
      },
    },
  })
);
