import { styled } from '@mui/material';
import PatternColorSquare, { PatternColorSquareProps } from './PatternColorSquare';
import React from 'react';

const BaseColorSquare = styled('div', {
  // `color` is also a native HTML attribute. We don't want to fill that one.
  shouldForwardProp: (propName) => propName !== 'color' && propName !== 'outlined' && propName !== 'borderColor',
})<{
  color: string;
  outlined?: boolean;
  borderColor?: string;
}>(({ color, outlined, borderColor }) => ({
  borderRadius: 4,
  width: 14,
  height: 14,
  backgroundColor: outlined ? 'none' : color,
  border: outlined ? `2px dashed ${color}` : borderColor ? `1px solid ${borderColor}` : 'none',
}));

type ColorSquareProps = {
  color: string | PatternColorSquareProps;
  outlined?: boolean;
  borderColor?: string;
};

const ColorSquare = ({ color, outlined, borderColor }: ColorSquareProps) => {
  if (typeof color === 'string') {
    return <BaseColorSquare color={color} outlined={outlined} borderColor={borderColor} />;
  }

  return <PatternColorSquare {...color} borderColor={borderColor} />;
};

export default ColorSquare;
