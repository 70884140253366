import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Switch } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import {
  useUpdateDataSourceMutation,
  useDataSourceQuery,
  DataSourceStatus,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { WizardStepProps, TestIntegrationParams } from './Wizard';
import WizardStepTemplate from './WizardStepTemplate';

type DataSourceManagementStepProps = WizardStepProps & {
  usersDataSourceCode: string;
  applicationDataSourceCodes?: string[];
  accountId: string;
  nextStep: string;
  previousStep: string;
  setIntegrationParams?: (params: TestIntegrationParams) => void;
};

const DataSourceManagementStep = ({
  setStep,
  stepValue,
  nextStep,
  previousStep,
  accountId,
  usersDataSourceCode,
  applicationDataSourceCodes,
  setIntegrationParams,
}: DataSourceManagementStepProps) => {
  const { t } = useTranslation();

  const [updateDataSource] = useUpdateDataSourceMutation();
  const { data: dataSourceQuery } = useDataSourceQuery({ accountId, code: usersDataSourceCode });
  const [userAnalyticsChecked, setUserAnalyticsChecked] = React.useState<boolean>(false);

  useEffect(() => {
    setUserAnalyticsChecked(dataSourceQuery?.dataSource?.status === DataSourceStatus.Active);
  }, [dataSourceQuery]);

  useEffect(() => {
    if (setIntegrationParams) {
      setIntegrationParams({ users: userAnalyticsChecked });
    }
  }, [setIntegrationParams, userAnalyticsChecked]);

  const handleOnUserAnalyticsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateDataSource({
      accountId,
      dataSourceCode: usersDataSourceCode,
      input: {
        status: event.target.checked ? DataSourceStatus.Active : DataSourceStatus.Inactive,
      },
    })
      .then((res) => {
        if ('error' in res) {
          setUserAnalyticsChecked(!event.target.checked);
        } else {
          setUserAnalyticsChecked(res.data.updateDataSource.status === DataSourceStatus.Active);
        }
      })
      .catch(() => {
        setUserAnalyticsChecked(!event.target.checked);
      });
  };

  const handleNextStepClick = async () => {
    const isDefined = (v?: string): v is string => v !== undefined;

    const dataSourceCodeUpdates =
      applicationDataSourceCodes?.filter(isDefined).map((code) =>
        updateDataSource({
          accountId,
          dataSourceCode: code,
          input: {
            status: DataSourceStatus.Active,
          },
        })
      ) || [];

    await Promise.all(dataSourceCodeUpdates).then(() => setStep(nextStep));
  };

  const header = (
    <Stack gap="8px">
      <Stack direction="row" gap="6px">
        <Text variant="heading" size="M">
          {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.SELECT_DATA_FIDELITY')}
        </Text>
      </Stack>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.SELECT_DATA_FIDELITY_DESCRIPTION')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.DATA_FIDELITY')}
      </Text>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Switch disabled defaultChecked />
        </Grid>
        <Grid item xs={10}>
          <Stack direction="column" gap={1}>
            <Text variant="button-bold" size="M" color="text1">
              {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.APPLICATION_DATA')}
            </Text>
            <Text variant="body-regular" size="M" color="text3">
              {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.APPLICATION_DATA_DESCRIPTION')}
            </Text>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Switch checked={userAnalyticsChecked} onChange={handleOnUserAnalyticsChange} />
        </Grid>
        <Grid item xs={10}>
          <Stack direction="column" gap={1}>
            <Text variant="button-bold" size="M" color="text1">
              {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.CAPTURE_USER_DATA')}
            </Text>
            <Text variant="body-regular" size="M" color="text3">
              {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.CAPTURE_USER_DATA_DESCRIPTION')}
            </Text>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="solid" color="primary" size="S" isCaption onClick={handleNextStepClick}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};

export default DataSourceManagementStep;
