import React from 'react';
import { useTranslation } from 'react-i18next';
import NoRowsOverlay from '../NoRowsOverlay';

const NoRows = () => {
  const { t } = useTranslation();
  return <NoRowsOverlay text={t('CONCESSIONS_LIST.NO_CONCESSIONS')} />;
};

export default NoRows;
