import React, { useEffect } from 'react';
import { DashboardCustomizeOutlined } from '@mui/icons-material';

import BaseTable from '../components/BaseTable';
import SelectFilterItem from '../components/filter/SelectFilterItem';
import { useColumns } from './columns';
import TableCard from '../components/TableCard';
import { LoadableComponent } from '../components/LoadableComponent';
import { useOfferings } from '../../../providers/OfferingsProvider';
import { useInstanceData } from '../../../providers/InstanceProvider';

const T_KEY = 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.CUSTOMIZE_PURCHASE_TAB.TABLE';

const CustomizeTable = () => {
  const columns = useColumns(T_KEY);
  const { omittedColumns } = useInstanceData();

  const { filter, offerings } = useOfferings({
    parseOptions: {
      proposedNumberOfInstances: 1,
    },
    filterByShoppingCart: false,
  });

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const filterItems: {
    [key: string]: JSX.Element;
  } = {
    offeringType: (
      <SelectFilterItem
        key="offeringType"
        id="offeringType"
        tKey="CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.CUSTOMIZE_PURCHASE_TAB.FILTER.OFFERING_TYPE"
        minWidth={160}
      />
    ),
    duration: (
      <SelectFilterItem
        key="duration"
        id="duration"
        tKey="CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.CUSTOMIZE_PURCHASE_TAB.FILTER.DURATION"
      />
    ),
    offeringClass: (
      <SelectFilterItem
        key="offeringClass"
        id="offeringClass"
        tKey="CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.CUSTOMIZE_PURCHASE_TAB.FILTER.OFFERING_CLASS"
      />
    ),
  };

  const filteredSelectFilterItems = Object.keys(filterItems)
    .filter((key) => !omittedColumns?.includes(key))
    .map((key) => filterItems[key]);

  return (
    <TableCard icon={DashboardCustomizeOutlined} tKey={T_KEY} filterComponent={<>{filteredSelectFilterItems}</>}>
      <LoadableComponent
        error={offerings.error}
        isLoading={offerings.isFetching}
        isEmpty={!offerings.data?.length}
        isFilterApplied={filter.isApplied}
      >
        <BaseTable
          data={offerings.data ?? []}
          defaultSortModel={{ sort: 'desc', field: 'expectedSavings' }}
          getRowId={(row) => row.reservedInstancesOfferingId}
          tableColumns={columns}
        />
      </LoadableComponent>
    </TableCard>
  );
};

export default CustomizeTable;
