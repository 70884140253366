import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import { useFilter, useFilterGroupData } from '../FilterProvider';
import { useFilterPopover } from './FilterPopoverProvider';
import { Button } from '../../../Button';
import { Text } from '../../../Text';
import i18n from '../../../../translate';

type FilterFooterProps = {
  hideClearAll?: boolean;
  hideTotalSelected?: boolean;
};

const StyledWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
}));

const StyledButton = styled(Button)({
  flex: 1,
});

const FilterFooter = ({ hideClearAll = false, hideTotalSelected = false }: FilterFooterProps) => {
  const { t } = useTranslation(undefined, { i18n });
  const { color, handleApply, handleClear } = useFilter();
  const { group } = useFilterGroupData();
  const popover = useFilterPopover();
  const [selectedCount, setSelectedCount] = useState(group.getActiveSelectedCount());
  const [isDirty, setIsDirty] = useState(group.getIsDirty());

  const handleCancel = useCallback(() => {
    group.revert();
    popover.close();
  }, [group, popover]);

  const clearAll = useCallback(() => {
    group.clear();
    handleClear();
  }, [group, handleClear]);

  useEffect(() => {
    const id = group.addUpdateListener(() => {
      setSelectedCount(group.getActiveSelectedCount());
      setIsDirty(group.getIsDirty());
    });

    return () => group.removeUpdateListener(id);
  }, [group]);

  return (
    <StyledWrapper paddingY={4} direction="column" alignItems="flex-start" gap={4} alignSelf="stretch">
      {!hideClearAll && !hideTotalSelected && (
        <Stack paddingX={4} direction="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
          {!hideTotalSelected && (
            <Text variant="caption" size="S">
              {selectedCount} {t('FILTER.BUTTONS.SELECTED')}
            </Text>
          )}
          {!hideClearAll && (
            <Button variant="plain" disabled={selectedCount === 0} onClick={clearAll}>
              {t('FILTER.BUTTONS.CLEAR_ALL')}
            </Button>
          )}
        </Stack>
      )}
      <Stack paddingX={4} direction="row" alignItems="flex-start" gap={2} alignSelf="stretch">
        <StyledButton variant="outline" size="M" color={color} onClick={handleCancel}>
          {t('FILTER.BUTTONS.CANCEL')}
        </StyledButton>
        <StyledButton variant="solid" size="M" color={color} disabled={!isDirty} onClick={handleApply}>
          {t('FILTER.BUTTONS.APPLY')}
        </StyledButton>
      </Stack>
    </StyledWrapper>
  );
};

export default FilterFooter;
