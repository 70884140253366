import React from 'react';
import { Box, CardProps } from '@mui/material';
import { Card } from '@verticeone/design-system/src';
import ContractCategorySelect from '@vertice/dashboard/src/pages/Dashboard/components/ContractCategorySelect';
import { DepartmentCosts } from '@vertice/dashboard/src/pages/Dashboard/components/DepartmentCosts';
import { useDashboardContext } from '@vertice/dashboard/src/pages/Dashboard/DashboardContextProvider';

export type DepartmentsCardProps = CardProps;

export const DepartmentsCard = (props: DepartmentsCardProps) => {
  const { allowCategoryChange } = useDashboardContext();

  return (
    <Card p={6} gap={6} {...props}>
      {allowCategoryChange && (
        <Box width={256} alignSelf="flex-end">
          <ContractCategorySelect />
        </Box>
      )}
      <Box flexGrow={1} justifyContent="center">
        <DepartmentCosts />
      </Box>
    </Card>
  );
};
