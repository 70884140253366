import { analyticsApi as api } from '../../api/analyticsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    viewTabularAnalytics: build.query<ViewTabularAnalyticsApiResponse, ViewTabularAnalyticsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/analytics/tabular/view/${queryArg.viewName}` }),
    }),
    viewAnalyticsForCostPerDepartment: build.query<
      ViewAnalyticsForCostPerDepartmentApiResponse,
      ViewAnalyticsForCostPerDepartmentApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/analytics/aggregated/view/annualCostPerDepartment`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as analyticsAPICodegen };
export type ViewTabularAnalyticsApiResponse = /** status 200 Tabular view */ {
  view?: {
    viewName?:
      | 'TABULAR_SAVINGS'
      | 'TABULAR_SAVINGS_BY_CONTRACT_CATEGORY'
      | 'TABULAR_SAVINGS_BY_DEPARTMENT'
      | 'TABULAR_TOP_CONTRACTS_BY_SAVINGS';
    recalculatedForTimestamp?: string;
    viewData?: {
      data?: (
        | TabularTopContractBySavings
        | TabularSaving
        | TabularSavingByContractCategory
        | TabularSavingByDepartment
      )[];
    };
  };
};
export type ViewTabularAnalyticsApiArg = {
  accountId: Identifier;
  viewName:
    | 'TABULAR_SAVINGS'
    | 'TABULAR_SAVINGS_BY_CONTRACT_CATEGORY'
    | 'TABULAR_SAVINGS_BY_DEPARTMENT'
    | 'TABULAR_TOP_CONTRACTS_BY_SAVINGS';
};
export type ViewAnalyticsForCostPerDepartmentApiResponse =
  /** status 200 Aggregated view on annual cost per department */ {
    view: {
      annualCostPerDepartment?: {
        departmentName: string;
        annualCost: Money;
      }[];
    };
  };
export type ViewAnalyticsForCostPerDepartmentApiArg = {
  accountId: Identifier;
};
export type Identifier = string;
export type TabularTopContractBySavings = {
  contractId: Identifier;
  contractVersion: number;
  departmentId: Identifier;
  vendorId: Identifier;
  vendorName: string;
  productNames: string;
  currency: string;
  totalSavings: number;
  savingsPercentage: number;
  contractCurrency: string;
};
export type TabularSaving = {
  totalSavings: number;
  realizedSavings: number;
  unrealizedSavings: number;
  scopeReductionSavings: number;
  currency: string;
};
export type TabularSavingByContractCategory = {
  category?: string;
  realizedSavings?: number;
  spend?: number;
  averageSavings?: number;
  currency?: string;
};
export type TabularSavingByDepartment = {
  departmentName?: string;
  realizedSavings?: number;
  spend?: number;
  averageSavings?: number;
  currency?: string;
};
export type Money = {
  amount: number;
  currency: string;
};
export const {
  useViewTabularAnalyticsQuery,
  useLazyViewTabularAnalyticsQuery,
  useViewAnalyticsForCostPerDepartmentQuery,
  useLazyViewAnalyticsForCostPerDepartmentQuery,
} = injectedRtkApi;
