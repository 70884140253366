import React from 'react';
import { Grid, Stack, GridProps } from '@mui/material';
import { LoadableTextComponent, LoadableTextComponentProps } from '../../LoadableTextComponent';

export type StatsBodyProps = {
  isLoading: LoadableTextComponentProps['isLoading'];
  title: React.ReactNode;
  value: React.ReactNode;
  badge?: React.ReactNode;
} & Omit<GridProps, 'title'>;

type GridItemProps = { isVisible?: boolean } & GridProps;

const GridItem = ({ isVisible, children, ...otherProps }: GridItemProps) => {
  if (isVisible) {
    return (
      <Grid item {...otherProps}>
        {children}
      </Grid>
    );
  }

  return null;
};

export const StatsBody = ({ title, value, badge, isLoading, ...otherProps }: StatsBodyProps) => (
  <Grid container justifyContent="space-between" alignItems="center" {...otherProps}>
    <GridItem isVisible>
      <Stack direction="column">
        {title}
        <LoadableTextComponent isLoading={isLoading}>{value}</LoadableTextComponent>
      </Stack>
    </GridItem>
    <GridItem isVisible={!!badge}>{badge}</GridItem>
  </Grid>
);
