import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ContractCategory } from '../types';

export type CategoryOption = {
  label: string;
  value: ContractCategory;
};

export type useContractCategoryOptionsReturn = CategoryOption[];

const useContractCategoryOptions = (): useContractCategoryOptionsReturn => {
  const { t } = useTranslation();

  const contractCategoryOptions = useMemo(
    () => [
      {
        label: t('DASHBOARD.CONTRACT_CATEGORY_SELECT.SAAS_SPEND'),
        value: ContractCategory.SAAS,
      },
      {
        label: t('DASHBOARD.CONTRACT_CATEGORY_SELECT.NON_SAAS_SPEND'),
        value: ContractCategory.NON_SAAS,
      },
      {
        label: t('DASHBOARD.CONTRACT_CATEGORY_SELECT.ALL_SPEND'),
        value: ContractCategory.ALL_SPEND,
      },
    ],
    [t]
  );

  return contractCategoryOptions;
};

export default useContractCategoryOptions;
