import React from 'react';
import { Stack } from '@mui/material';
import { SageMakerWidget, ComputeWidget } from './SavingsPlans/CloudOptimizeTab/widgets/Overview';
import { useCloudContext } from '../../../modules/cloud/CloudContext';

const Overview = () => {
  const { subFeatures } = useCloudContext();

  return (
    <Stack gap={4}>
      {subFeatures.sprioComputeSP && <ComputeWidget />}
      {subFeatures.sprioSagemakerSP && <SageMakerWidget />}
    </Stack>
  );
};

export default Overview;
