import { LoadableAdvanced } from '@verticeone/utils/async';
import { useLatestOptimizationCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useMemo } from 'react';
import { getTableData } from '../../utils/graphDataUtils';

export type ExpiringReservedInstance = {
  id: string;
  instanceTenancy: string;
  instanceType: string;
  scope: string;
  productDescription: string;
  instanceCount: string;
  offeringClass: string;
  offeringType: string;
  duration: number;
  start: number;
  end: number;
};

const useExpiringReservedInstancesData = (monthWindow = 6): LoadableAdvanced<ExpiringReservedInstance[]> => {
  const { accountId } = useSelector(getAccount);

  const { data: rawExpiringInstances, error } = useLatestOptimizationCheckQuery({
    accountId: accountId!,
    checkCode: 'EXPIRING_INSTANCE_RESERVATIONS',
  });

  const computed = useMemo(() => {
    if (!rawExpiringInstances || rawExpiringInstances.monitoringLatestQuery?.__typename !== 'MonitoringResult')
      return undefined;

    const checkItems = rawExpiringInstances.monitoringLatestQuery?.items;
    if (!checkItems || checkItems.length === 0) return undefined;

    const checkResult = checkItems[0].results;
    if (checkResult?.__typename !== 'MonitoringValueCheckResult') return undefined;

    return getTableData(checkResult, {
      reserved_instances_id: 'id',
      instance_tenancy: 'instanceTenancy',
      instance_type: 'instanceType',
      scope: 'scope',
      product_description: 'productDescription',
      instance_count: 'instanceCount',
      offering_class: 'offeringClass',
      offering_type: 'offeringType',
      duration: 'duration',
      start: 'start',
      end: 'end',
    }) as ExpiringReservedInstance[];
  }, [rawExpiringInstances]);

  return {
    error: error,
    isEmpty: Boolean(computed && computed.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useExpiringReservedInstancesData;
