import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryProps } from '../../../ContractFormEntry';
import { keyBy } from 'lodash';
import { ContractFormData } from '../../../types';
import { useListAccountUsersQuery } from '@vertice/slices';
import { useContractContext } from '../../../ContractContext';
import UserReadOnlyListField, { UserReadOnlyListFieldType } from '../../UserReadOnlyListField';
import { getSelectOptions } from './utils';

type ReadComponent = UserReadOnlyListFieldType<ContractFormData>;

type UsersReadOnlyFormProps = Omit<
  ContractFormEntryProps<ReadComponent, ReadComponent>,
  'component' | 'componentProps'
> & { emptyValueLabel: string };

const ListUsersReadOnlyField = (options: UsersReadOnlyFormProps) => {
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const accountId = contractContext.accessVia.accountId;
  const { data: usersData } = useListAccountUsersQuery({ accountId: accountId! }, { skip: !accountId });

  const usersOptions = useMemo(() => (usersData?.users ? getSelectOptions(usersData.users) : undefined), [usersData]);

  const userMap = useMemo(() => keyBy(usersOptions, 'value'), [usersOptions]);

  return (
    <ContractFormEntry<ReadComponent, ReadComponent>
      {...options}
      component={UserReadOnlyListField}
      componentProps={{
        emptyValue: options.emptyValueLabel,
        getUserData: (value) => {
          const userId = value.userId;
          const userItem = userId ? userMap[userId as string] : undefined;
          return userItem?.user;
        },
      }}
    />
  );
};

export default ListUsersReadOnlyField;
