import { FC, useMemo } from 'react';
import { Content, ContentProps, InputContent } from './Content';
import { Segment } from '../types';

export type OverlayContentProps = Omit<ContentProps, 'content'> & {
  selectionStart: number | null;
  selectionEnd: number | null;
  segments: Segment[];
};
export const OverlayContent: FC<OverlayContentProps> = ({
  cursorRef,
  segments,
  variant,
  size,
  selectionStart,
  selectionEnd,
}) => {
  const overlayContent = useMemo(
    () =>
      segments.map((segment): InputContent => {
        if (segment.type === 'mention') {
          return segment;
        }

        const { key, label, indexInPlaintext } = segment;
        const renderCursor =
          selectionStart &&
          selectionStart === selectionEnd &&
          selectionStart >= indexInPlaintext &&
          selectionStart <= indexInPlaintext + label.length;

        if (!renderCursor) {
          return segment;
        }

        const splitIndex = selectionStart - indexInPlaintext;
        const start = label.substring(0, splitIndex);
        const end = label.substring(splitIndex);

        return { key, type: 'cursor', start, end };
      }),
    [segments, selectionStart, selectionEnd]
  );

  return (
    <>
      {overlayContent.map((content) => (
        <Content key={content.key} content={content} size={size} variant={variant} cursorRef={cursorRef} />
      ))}
    </>
  );
};
