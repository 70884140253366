// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ContractCheck } from '@vertice/assets';
import { FC } from 'react';
import { ProgressText } from './ProgressText';
import { Stack } from '@mui/material';
import { Text, Button, Card } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { GenericRequestIcon } from '../../../../components/icons/Icons';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';

type ProgressDialogContentProps = {
  isCreatingRequest: boolean;
  vendorName: string;
  onGoToRequestDetails: () => void;
  onCreateNewRequest: () => void;
  requestType: 'PURCHASE' | 'RENEWAL';
};

export const ProgressDialogContent: FC<ProgressDialogContentProps> = ({
  isCreatingRequest,
  vendorName,
  onGoToRequestDetails,
  onCreateNewRequest,
  requestType,
}) => {
  const { t } = useTranslation();

  return (
    <Stack height={400} justifyContent="center">
      {isCreatingRequest ? (
        <Stack alignItems="center" gap={8}>
          <GenericRequestIcon size="XL" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />
          <Stack gap={1} alignItems="center">
            <Text variant="body-bold" color="text1">
              {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.PREPARING_REQUEST')}
            </Text>
            <ProgressText vendorName={vendorName} />
          </Stack>
        </Stack>
      ) : (
        <Stack alignItems="center" gap={8}>
          <ContractCheck width={100} height={100} />
          <Stack gap={4} alignItems="center">
            <Text variant="body-bold" color="text1">
              {t(`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.${requestType}_REQUEST_SUBMITTED`, {
                vendorName,
              })}
            </Text>
            <Card>
              <Stack padding={4}>
                <Text variant="body-regular" color="text2">
                  {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.REQUEST_NEXT_STEPS')}
                </Text>
                <ul>
                  <li>
                    <Text variant="body-regular" color="text2">
                      {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.REQUEST_NEXT_STEPS_1')}
                    </Text>
                  </li>
                  <li>
                    <Text variant="body-regular" color="text2">
                      {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.REQUEST_NEXT_STEPS_2')}
                    </Text>
                  </li>
                  <li>
                    <Text variant="body-regular" color="text2">
                      {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.REQUEST_NEXT_STEPS_3')}
                    </Text>
                  </li>
                </ul>
              </Stack>
            </Card>
          </Stack>
          <Stack gap={8} direction="row" justifyContent="stretch" width="100%" paddingInline={10}>
            <Button fullWidth variant="outline" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={onCreateNewRequest}>
              {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.SUBMIT_ANOTHER_REQUEST')}
            </Button>
            <Button fullWidth variant="solid" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={onGoToRequestDetails}>
              {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.VIEW_REQUEST_DETAILS')}
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
