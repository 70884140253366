import React from 'react';
import { useTranslation } from 'react-i18next';
import FullPageMessage, { CallToActionLinkButton } from '../../components/FullPageMessage';

const PageNotFound = ({ verticalFill = 'container' }: { verticalFill?: 'container' | 'window' }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PAGE_NOT_FOUND' });
  return (
    <FullPageMessage
      verticalFill={verticalFill}
      title="404"
      subtitle={t('TITLE')}
      message={t('EXPLANATION')}
      callToAction={<CallToActionLinkButton to="/" label={t('RETURN_TO_WEBSITE')} />}
    />
  );
};

export default PageNotFound;
