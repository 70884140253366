import { FC } from 'react';
import Stack from '@mui/material/Stack';
import { DesignSystemDensity, DesignSystemSize } from '../../types';
import styled from '@mui/material/styles/styled';
import { StyledCardHeaderCaption } from './CardHeaderCaption';
import { getTextVariantStyle } from '../Text/utils';
import { StyledCardHeaderTitleIcon, StyledCardHeaderTitle } from './CardHeaderTitle';
import {
  StyledCardHeaderSubtitle,
  StyledCardHeaderSubtitleBullet,
  StyledCardHeaderSubtitleDimmed,
  StyledCardHeaderSubtitleIcon,
} from './CardHeaderSubtitle';
import { StyledCardHeaderDescription } from './CardHeaderDescription';
import { StyledCardHeaderActionPadding } from './CardHeaderActionPadding';
import { StyledCardHeaderActions } from './CardHeaderActions';

export type CardHeaderProps = {
  size?: DesignSystemSize;
  density?: DesignSystemDensity;
  children: React.ReactNode;
  noDivider?: boolean;
};

type CardSizeParams = {
  verticalSpacing: number;
  iconSpacing: number;
  contentSpacing: number;
  titleIconSize: number;
  headerCaptionSize: DesignSystemSize;
  subtitleIconSize: number;
  subtitleBulletSize: number;
};

export const cardHeaderPaddingDefinitions: Record<DesignSystemDensity, number> = {
  high: 16,
  medium: 24,
  low: 32,
};

const sizeDefinitions: Record<DesignSystemSize, CardSizeParams> = {
  XL: {
    verticalSpacing: 10,
    iconSpacing: 10,
    contentSpacing: 10,
    titleIconSize: 40,
    subtitleIconSize: 20,
    subtitleBulletSize: 7.2,
    headerCaptionSize: 'S',
  },
  L: {
    verticalSpacing: 9,
    iconSpacing: 9,
    contentSpacing: 8,
    titleIconSize: 36,
    subtitleIconSize: 18,
    subtitleBulletSize: 7.2,
    headerCaptionSize: 'S',
  },
  M: {
    verticalSpacing: 8,
    iconSpacing: 8,
    contentSpacing: 6,
    titleIconSize: 32,
    subtitleIconSize: 16,
    subtitleBulletSize: 4.9,
    headerCaptionSize: 'XS',
  },
  S: {
    verticalSpacing: 7,
    iconSpacing: 7,
    contentSpacing: 4,
    titleIconSize: 24,
    subtitleIconSize: 14,
    subtitleBulletSize: 4.9,
    headerCaptionSize: 'XS',
  },
  XS: {
    verticalSpacing: 8,
    iconSpacing: 6,
    contentSpacing: 4,
    titleIconSize: 18,
    subtitleIconSize: 12,
    subtitleBulletSize: 2.5,
    headerCaptionSize: 'XXS',
  },
  XXS: {
    verticalSpacing: 7,
    iconSpacing: 5,
    contentSpacing: 4,
    titleIconSize: 14,
    subtitleIconSize: 10,
    subtitleBulletSize: 2.5,
    headerCaptionSize: 'XXS',
  },
};

const StyledCardHeader = styled(Stack)<{
  $size: DesignSystemSize;
  $density: DesignSystemDensity;
  $withDivider: boolean;
}>(({ theme, $size, $density, $withDivider }) => {
  return {
    padding: cardHeaderPaddingDefinitions[$density],
    gap: sizeDefinitions[$size].verticalSpacing,
    borderBottom: $withDivider ? `1px solid ${theme.palette.core.color3}` : 'none',
    position: 'relative',
    [`${StyledCardHeaderCaption}`]: {
      ...getTextVariantStyle({ variant: 'label', size: sizeDefinitions[$size].headerCaptionSize }),
      color: theme.palette.text.color3,
    },
    [`${StyledCardHeaderTitle}`]: {
      ...getTextVariantStyle({
        variant: 'heading',
        size: $size,
      }),
      paddingRight: sizeDefinitions[$size].contentSpacing,
    },
    [`${StyledCardHeaderTitleIcon}`]: {
      marginRight: sizeDefinitions[$size].iconSpacing,
      svg: {
        width: sizeDefinitions[$size].titleIconSize,
        height: sizeDefinitions[$size].titleIconSize,
        color: theme.palette.neutral.color2,
      },
    },
    [`${StyledCardHeaderSubtitle},${StyledCardHeaderSubtitleDimmed}`]: {
      ...getTextVariantStyle({
        variant: 'body-bold',
        size: $size,
      }),
      paddingRight: sizeDefinitions[$size].contentSpacing,
    },
    [`${StyledCardHeaderDescription}`]: {
      ...getTextVariantStyle({
        variant: 'body-regular',
        size: $size,
      }),
      paddingRight: sizeDefinitions[$size].contentSpacing,
    },
    [`${StyledCardHeaderSubtitleBullet}`]: {
      width: sizeDefinitions[$size].subtitleBulletSize,
      height: sizeDefinitions[$size].subtitleBulletSize,
      paddingRight: sizeDefinitions[$size].contentSpacing,
    },
    [`${StyledCardHeaderSubtitleIcon}`]: {
      marginRight: sizeDefinitions[$size].iconSpacing,
      svg: {
        width: sizeDefinitions[$size].subtitleIconSize,
        height: sizeDefinitions[$size].subtitleIconSize,
        color: theme.palette.neutral.color2,
      },
    },
    [`${StyledCardHeaderActions}`]: {
      top: cardHeaderPaddingDefinitions[$density],
      right: cardHeaderPaddingDefinitions[$density],
      position: 'absolute',
    },
    [`${StyledCardHeaderActionPadding}`]: {
      padding: sizeDefinitions[$size].iconSpacing,
    },
  };
});

export const CardHeader: FC<CardHeaderProps> = ({
  children,
  size = 'M',
  density = 'medium',
  noDivider = false,
  ...otherProps
}) => {
  return (
    <StyledCardHeader $size={size} $density={density} $withDivider={!noDivider} {...otherProps}>
      {children}
    </StyledCardHeader>
  );
};
