import { type FlatNamespace, i18n, type KeyPrefix } from 'i18next';
import { createRequiredContext } from '../contexts/createRequiredContext';
import { PropsWithChildren, useLayoutEffect, useMemo } from 'react';
import type { $Tuple } from 'react-i18next/helpers';
import { FallbackNs, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTranslation } from 'react-i18next';

/**
 * Creates a special context and corresponding hooks for using i18next instance in React in a completely isolated way.
 * This is useful, if you have multiple modules (utils package, design system, etc.) that each brings its own
 * translations.
 *
 * Note: This helper is designed so that you cannot access the i18n instance directly, only through our
 *       `useTranslation` hook via our special isolated context. This way, the app correctly reacts to language changes
 *       without the need to manually specify the i18n instance in each `useTranslation` call.
 *
 * @param createInstance A function that creates a new i18next instance with project-specific configuration.
 */
export const createScopedI18nextHooks = (createInstance: () => i18n) => {
  const { I18nContextProvider: RawI18nContextProvider, useI18nContext } = createRequiredContext<i18n, 'I18n'>('I18n');

  return {
    /**
     * The provider that passes i18n instance through your app or micro-frontend.
     *
     * @param locale The locale to use in the context.
     */
    I18nContextProvider: ({ locale, children }: PropsWithChildren<{ locale: string }>) => {
      const i18nInstance = useMemo(() => createInstance(), []);
      useLayoutEffect(() => {
        void i18nInstance.changeLanguage(locale);
      }, [i18nInstance, locale]);

      return <RawI18nContextProvider value={i18nInstance}>{children}</RawI18nContextProvider>;
    },

    /**
     * Same as useTranslation from i18next, but uses the i18n instance from the context.
     */
    useTranslation: <
      Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
      KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined
    >(
      ns?: Ns,
      options?: UseTranslationOptions<KPrefix>
    ): UseTranslationResponse<FallbackNs<Ns>, KPrefix> => {
      const i18nInstance = useI18nContext();
      return useTranslation<Ns, KPrefix>(ns, { i18n: i18nInstance, ...options });
    },
  };
};
