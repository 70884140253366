import React, { RefObject, SetStateAction, useCallback, useRef, useState } from 'react';
import { createRequiredContext } from '@verticeone/design-system/src';

export enum ContractTab {
  OBJECTIVES = 'OBJECTIVES', // visible for in progress
  REQUEST_DETAILS = 'REQUEST_DETAILS',
  DOCUMENTS = 'DOCUMENTS',
  SAVINGS_BREAKDOWN = 'SAVINGS_BREAKDOWN',
  PRODUCTS = 'PRODUCTS',
  WORKFLOW = 'WORKFLOW',
  LINKED_CONTRACTS = 'LINKED_CONTRACTS',
  CUSTOM = 'CUSTOM',

  // IAT specific
  PURCHASING = 'PURCHASING',
}

export type TabId = ContractTab | null | undefined;
export type SetTabIdOptions = { scrollIntoView?: boolean };

export type ContractTabNavigationData = {
  tabId: TabId;

  /**
   * Sets active tab. If you pass `{ scrollIntoView: true }` as second parameter,
   * it also scrolls to the scrollTargetRef (typically the Tabs element)
   */
  setTabId: (selectedTab: SetStateAction<TabId>, options?: SetTabIdOptions) => void;

  /**
   * Reference to Tabs element used by `setTabId` and `scrollToTabs`
   */
  scrollTargetRef: RefObject<HTMLDivElement>;

  scrollToTabs: () => void;
};

export const {
  ContractTabNavigationContextProvider: ContractTabNavigationContextProviderRaw,
  useContractTabNavigationContext,
} = createRequiredContext<ContractTabNavigationData, 'ContractTabNavigation'>('ContractTabNavigation');

/**
 * Prepares data for ContractTabNavigationContext
 */
export const useContractTabNavigationContextData = (): ContractTabNavigationData => {
  const [tabId, setTabIdRaw] = useState<TabId>();
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  const scrollToTabs = useCallback(() => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const setTabId = useCallback(
    (selectedTab: SetStateAction<TabId>, options: SetTabIdOptions = {}) => {
      setTabIdRaw(selectedTab);
      if (options.scrollIntoView) {
        // Give React a chance to re-render tabs before scrolling
        requestAnimationFrame(() => scrollToTabs());
      }
    },
    [scrollToTabs]
  );

  return {
    tabId,
    setTabId,
    scrollTargetRef,
    scrollToTabs,
  };
};

/**
 *  A provider to keep track of the last selected tab on the contract page - used for navigation between linked contracts
 */
export const ContractTabNavigationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const contextData = useContractTabNavigationContextData();

  return (
    <ContractTabNavigationContextProviderRaw value={contextData}>{children}</ContractTabNavigationContextProviderRaw>
  );
};
