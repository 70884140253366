import { StageIndicator } from '../../StageIndicator/StageIndicator';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { getOrdinalPhase } from '../../StageIndicator/phaseUtils';

export type StageIndicatorCellProps = {
  viewOnContract: ViewOnContract;
};

const simplifiedStagesOrdered = [
  'DRAFT',
  'WORKFLOW_IN_PROGRESS',
  'NOT_YET_LIVE',
  'LIVE',
  'EXPIRED_NATURAL',
  'EXPIRED_PLANNED',
  'EXPIRED_UNINTENTIONAL',
  'TERMINATED',
  'CANCELLED_FIXED_TERM',
  'CANCELLED_REQUEST',
  'DELETED',
] as const;

export const stageIndicatorCellValueGetter = (viewOnContract: ViewOnContract) => {
  const simplifiedStage = viewOnContract.contract.materialized?.simplifiedStage;
  if (!simplifiedStage) {
    return -1;
  }
  return (
    simplifiedStagesOrdered.indexOf(simplifiedStage) +
    0.1 * (getOrdinalPhase(viewOnContract.contract.workflow?.stage).phase ?? 0)
  );
};

export const StageIndicatorCell = ({ viewOnContract: { contract } }: StageIndicatorCellProps) => (
  <StageIndicator contract={contract} />
);
