import React from 'react';
import { styled, Grid } from '@mui/material';

type CardProps = {
  children: React.ReactNode;
};

/** @deprecated Use @verticeone/design-system/src/components/Card instead. */
const Card = styled((props) => <Grid direction="row" container {...props} />)<CardProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  borderRadius: '1rem',
  backgroundColor: theme.palette.core.bg,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.core.color3}`,

  '& > .MuiGrid-item': {
    marginTop: '-1px',
  },

  '& > .MuiGrid-item:last-child': {
    marginBottom: '-1px',
  },
}));

export default Card;
