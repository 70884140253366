import { GridPreProcessEditCellProps, GridSortCellParams } from '@mui/x-data-grid-pro';
import { DepartmentItem, User } from './types';
import { Department } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DEFAULT_DEPARTMENT_TAG_PREFIX } from '../saas/contract/utils/tagUtils';
import { gridStringOrNumberComparator } from '@mui/x-data-grid/hooks/features/sorting/gridSortingUtils';

export const normalizeDepartmentName = (value: string) => value.trim().replace(/\s\s+/g, ' ');

export const preProcessNonEmptyCell = (departments: DepartmentItem[]) => (params: GridPreProcessEditCellProps) => {
  const departmentId = params.row.id;
  const departmentName = normalizeDepartmentName(params.props.value);
  const isEmpty = !departmentName;
  const nameExists =
    departmentName &&
    departments.some((dep: DepartmentItem) => dep.label === departmentName && dep.id !== departmentId);
  return { ...params.props, error: isEmpty || nameExists };
};

export const labelComparator = (
  v1: string,
  v2: string,
  params1: GridSortCellParams<string>,
  params2: GridSortCellParams<string>
) => {
  if (!v1 || !v2) {
    return 0; //This will keep newly added row always at the end regardless of sort direction
  }
  return gridStringOrNumberComparator(v1, v2, params1, params2);
};

export const ownerComparator = (
  v1: User,
  v2: User,
  params1: GridSortCellParams<User>,
  params2: GridSortCellParams<User>
) => {
  const model = params1.api.state.rows.dataRowIdToModelLookup;
  const v1Item = model[params1.rowNode.id];
  const v2Item = model[params2.rowNode.id];

  if (!v1Item.label || !v2Item.label) {
    return 0; //This will keep newly added row always at the end regardless of sort direction
  }

  if (!v1) {
    return 1;
  }

  if (!v2) {
    return -1;
  }

  return gridStringOrNumberComparator(v1.name, v2.name, params1, params2);
};

export const overrideDepartmentItem = (
  departmentToSet: DepartmentItem,
  departments?: DepartmentItem[],
  id?: string
) => {
  const effectiveId = id ?? departmentToSet.id;
  return departments?.reduce((acc: DepartmentItem[], item: DepartmentItem) => {
    if (item.id === effectiveId) {
      acc.push(departmentToSet);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const mapDepartmentToDepartmentItem = (department: Department, userMap: Record<string, User>) => {
  const departmentOwner = department.owner?.userId ? userMap[department.owner.userId] : undefined;
  const departmentWatchers = department.watchers?.map((user) => userMap[user.userId]).filter((user) => user);

  return {
    id: department.id!,
    label: department.label,
    owner: departmentOwner ?? null,
    watchers: departmentWatchers ?? [],
    assignedContractCount: department.assignedContractCount,
    isDefaultDepartment: department.id?.startsWith(DEFAULT_DEPARTMENT_TAG_PREFIX),
  };
};
