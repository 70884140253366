import React from 'react';
import { useTaskFormContext } from '../TaskFormContext';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';

export const TaskFormControlLabel = ({ disabled: disabledByProp = false, ...restProps }: FormControlLabelProps) => {
  const disabledByContext = useTaskFormContext().readOnly;
  const disabled = disabledByProp || disabledByContext;

  return <FormControlLabel disabled={disabled} {...restProps} />;
};
