import RITransactionHistory from '@vertice/dashboard/src/modules/cloud/cards/RIPurchaseCard/RITransactionHistory';
import RIPendingTransactions from '@vertice/dashboard/src/modules/cloud/cards/RIPurchaseCard/RIPendingTransactions';
import { Stack } from '@mui/material';
import Layout from './components/Layout';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import React from 'react';

const RIOTransactionsInner = () => {
  return (
    <Stack gap={4} flexDirection="column" flex={1}>
      <RIPendingTransactions />
      <RITransactionHistory />
    </Stack>
  );
};

const RIOTransactions = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.NAVBAR' });

  return (
    <Layout
      getCanBeViewed={({ rio, sprio, sprioEC2RI, sprioRDSRI, sprioEC2SP, sprioComputeSP, sprioSagemakerSP }) =>
        rio && sprio && (sprioEC2RI || sprioRDSRI || sprioEC2SP || sprioComputeSP || sprioSagemakerSP)
      }
      breadcrumbs={[
        {
          label: t('RIO_NEW.MAIN'),
          to: ROUTES.CLOUD_RIO,
        },
        {
          label: t('RIO_NEW.TRANSACTIONS'),
        },
      ]}
      title={t('RIO_NEW.TRANSACTIONS')}
    >
      <RIOTransactionsInner />
    </Layout>
  );
};

export default RIOTransactions;
