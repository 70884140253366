import React, { FC } from 'react';

import { Card } from '@verticeone/design-system/src';
import { DataGrid } from '@verticeone/design-system/src';

import { WorkflowRow } from './types';
import { useWorkflowsTableColumns } from './useWorkflowsTableColumns';

type WorkflowsTableSkeletonProps = {
  serviceRef?: string;
};

export const WorkflowsTableSkeleton: FC<WorkflowsTableSkeletonProps> = ({ serviceRef }) => {
  const columns = useWorkflowsTableColumns(serviceRef!);
  return (
    <Card>
      <DataGrid<WorkflowRow>
        color="neutral"
        columns={columns}
        loading
        loadingStyle="skeleton"
        rows={[]}
        noBorder
        noBorderRadius
        loadingRowCount={2}
        getRowHeight={() => 60}
        initialState={{
          columns: {
            columnVisibilityModel: {
              isDefault: false,
            },
          },
        }}
      ></DataGrid>
    </Card>
  );
};
