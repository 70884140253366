import { ContractContextData } from '../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

const useEditContractURLState = (contractContext: ContractContextData) => {
  const location = useLocation();
  const navigate = useNavigate();

  // enable contract edit mode when the URL contains the "edit" hash
  useEffect(
    () => {
      if (location.hash === '#edit') {
        contractContext.editMode.enable();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contractContext.fetchedContract?.contract?.record.contractId, location.hash]
  );

  const clearEditHash = useCallback(() => navigate('', { replace: true }), [navigate]);

  // clear edit hash from URL when edit mode is switched off
  useEffect(() => {
    if (!contractContext.editMode.isEditing) {
      clearEditHash();
    }
  }, [contractContext.editMode.isEditing, clearEditHash]);
};

export default useEditContractURLState;
