import {
  MonitoringValue,
  MulticlassBinnedTestResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { weightedMean } from '@vertice/dashboard/src/modules/cloud/utils/weightedMean';
import type { EC2DataEnhanced } from './instances/products/EC2/dataSource/useEC2Data';
import type { RDSDataEnhanced } from './instances/products/RDS/dataSource/useRDSData';
import type { CalculateCoverageReturnType, CardsData } from './types';
import { getTestTableData } from '../../../../../modules/cloud/utils/graphDataUtils';

const sortCardsDataBasedOnData = (a: CardsData, b: CardsData) => {
  const coveragePercentageA = a.calculatedCoverage.coveragePercentage;
  const coveragePercentageB = b.calculatedCoverage.coveragePercentage;
  const instancesTypesCountA = a.calculatedCoverage.instancesTypesCount;
  const instancesTypesCountB = b.calculatedCoverage.instancesTypesCount;
  const disabledA = a.disabled;
  const disabledB = b.disabled;

  if (!disabledA && disabledB) {
    return -1;
  }

  if (disabledA && !disabledB) {
    return 1;
  }

  if (instancesTypesCountA < instancesTypesCountB) {
    return 1;
  }
  if (instancesTypesCountA > instancesTypesCountB) {
    return -1;
  }

  if (coveragePercentageA < coveragePercentageB) {
    return 1;
  }

  if (coveragePercentageA > coveragePercentageB) {
    return -1;
  }

  return 0;
};

const sortCardsDataBasedOnTitle = (a: CardsData, b: CardsData) => (a.title > b.title ? 1 : -1);

export const sortCardsData = (a: CardsData, b: CardsData) =>
  sortCardsDataBasedOnData(a, b) || sortCardsDataBasedOnTitle(a, b);

export const calculateMonitoringValuesCoverage = (data?: MonitoringValue): CalculateCoverageReturnType => {
  type ExtractedTestData = {
    on_demand_cost: number;
    ri_coverage: number;
    sp_coverage: number;
    total_usage: number;
  };

  const noDataResult: CalculateCoverageReturnType = {
    isEmpty: true,
    coveragePercentage: 0,
    coverageColor: 'secondary2',
    coverage: 0,
    onDemandUsage: 0,
    instancesTypesCount: 0,
  };

  if (!data) {
    return noDataResult;
  }

  const result = data?.results as MulticlassBinnedTestResult;
  const testData = getTestTableData(result, {
    on_demand_cost: 'cost',
    ri_coverage: 'ri_coverage',
    sp_coverage: 'sp_coverage',
    total_usage: 'usage',
  }) as ExtractedTestData[];

  const values = testData.map(({ ri_coverage }) => ri_coverage) ?? [];
  const usages = testData.map(({ total_usage }) => total_usage) ?? [];

  if (values.length === 0) {
    return noDataResult;
  }

  const instancesTypesCount = result?.labels?.length ?? 0;
  const coverage = weightedMean(values, usages);
  const coverageColor = coverage > 1 ? 'error2' : 'secondary2';
  const onDemandUsage = Math.max(1 - coverage, 0);
  const coveragePercentage = coverage * 100;

  return { isEmpty: false, coveragePercentage, coverageColor, coverage, onDemandUsage, instancesTypesCount };
};

export const calculateEC2RICoverage = (data?: EC2DataEnhanced[]): CalculateCoverageReturnType => {
  const noDataResult: CalculateCoverageReturnType = {
    isEmpty: true,
    coveragePercentage: 0,
    coverageColor: 'secondary2',
    coverage: 0,
    spCoverage: 0,
    onDemandUsage: 0,
    instancesTypesCount: 0,
  };

  if (!data || data.length === 0) {
    return noDataResult;
  }

  const riCoverages = data.map((item) => item.riCoverage);
  const totalUnits = data.map((item) => item.totalUnits);
  const spCoverages = data.map((item) => item.spCoverage);
  const coverage = weightedMean(riCoverages, totalUnits);
  const spCoverage = weightedMean(spCoverages, totalUnits);
  const onDemandUsage = Math.max(1 - coverage - spCoverage, 0);
  const coverageColor = coverage > 1 ? 'error2' : 'secondary2';
  const coveragePercentage = coverage * 100;
  const instancesTypesCount = data.length;

  return {
    isEmpty: false,
    coveragePercentage,
    coverageColor,
    coverage,
    spCoverage,
    onDemandUsage,
    instancesTypesCount,
  };
};

export const calculateRDSRICoverage = (data?: RDSDataEnhanced[]): CalculateCoverageReturnType => {
  const noDataResult: CalculateCoverageReturnType = {
    isEmpty: true,
    coveragePercentage: 0,
    coverageColor: 'secondary2',
    coverage: 0,
    onDemandUsage: 0,
    instancesTypesCount: 0,
  };

  if (!data || data.length === 0) {
    return noDataResult;
  }

  const riCoverages = data.map((item) => item.riCoverage);
  const totalUnits = data.map((item) => item.totalUnits);
  const coverage = weightedMean(riCoverages, totalUnits);
  const onDemandUsage = Math.max(1 - coverage, 0);
  const coverageColor = coverage > 1 ? 'error2' : 'secondary2';
  const coveragePercentage = coverage * 100;
  const instancesTypesCount = data.length;

  return { isEmpty: false, coveragePercentage, coverageColor, coverage, onDemandUsage, instancesTypesCount };
};
