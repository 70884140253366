import { FC } from 'react';

import { Card } from '@verticeone/design-system/src';
import { Placeholder } from '@verticeone/design-system/src';

export const TaskCardPlaceholder: FC = () => {
  return (
    <Card>
      <Placeholder width="100%" height={80} />
    </Card>
  );
};
