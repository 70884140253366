import { useFileupload } from './Fileupload';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '../Dialog';
import { Text } from '../Text';
import { Stack } from '@mui/material';
import { Button } from '../Button';
import { FileAccepted, FileRejected } from './utils';

export type FilesToReplace = {
  accepted: FileAccepted[];
  rejected: FileRejected[];
};

type ReplaceDialogProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  filesToReplace?: FilesToReplace;
};

export const ReplaceDialog = ({ isOpen, setIsOpen, filesToReplace }: ReplaceDialogProps) => {
  const { addFiles, removeFiles } = useFileupload();
  const { t } = useTranslation(undefined, { i18n });

  const handleReplace = async (files?: FilesToReplace) => {
    if (files) {
      await removeFiles([...files.accepted, ...files.rejected]);
      void addFiles(files.accepted, files.rejected);
    }
  };

  return (
    <Dialog open={isOpen} size="M" testId="close-dialog">
      <DialogHeader> {t('DRAG_AND_DROP.REPLACE_DIALOG.HEADER')}</DialogHeader>
      <DialogContent>
        <Text variant="body-regular" size="XL" color="text2">
          {t('DRAG_AND_DROP.REPLACE_DIALOG.DESCRIPTION')}
        </Text>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={2} alignSelf="flex-end">
          <Button variant="outline" size="M" color="primary" onClick={() => setIsOpen(false)}>
            {t('DIALOG.BUTTONS.CANCEL')}
          </Button>
          <Button
            variant="solid"
            size="M"
            color="primary"
            onClick={() => {
              setIsOpen(false);
              void handleReplace(filesToReplace);
            }}
          >
            {t('DRAG_AND_DROP.REPLACE_DIALOG.REPLACE')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
