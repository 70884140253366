import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { VisoTrustIcon } from '@vertice/assets';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { IntegrationCardIcon } from 'pages/Preferences/Integrations/components/IconWrappers';
import { WorkflowsSetupCard } from '../components/WorkflowsSetupCard';

type VisoTrustSetupCardProps = {
  providerId: string;
};

export const VisoTrustSetupCard: FC<VisoTrustSetupCardProps> = ({ providerId }) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  return (
    <WorkflowsSetupCard
      providerId={providerId}
      providerName={t('PREFERENCES.INTEGRATIONS.VISOTRUST.SETUP_CARD.TITLE')}
      providerIcon={
        <IntegrationCardIcon>
          <VisoTrustIcon />
        </IntegrationCardIcon>
      }
      description={t('PREFERENCES.INTEGRATIONS.VISOTRUST.SETUP_CARD.DESCRIPTION')}
      setupRoute={generatePath(routes.PREFERENCES.TAB.VISOTRUST.ABSOLUTE_PATH, { activeTab: 'integrations' })}
    />
  );
};
