export enum SCIMSetupType {
  ENTRA = 'ENTRA',
  OKTA = 'OKTA',
}

export type SCIMSetupProps = {
  accountId: string;
  accountSCIMEndpointURL: string;
  handleCloseSetup: () => void;
};

export const copyToClipboard = (text?: string, setCallback?: (value: boolean) => void) => {
  if (!text || !setCallback) {
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      setCallback(true);
      setTimeout(() => setCallback(false), 2000);
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.error('Async: Could not copy text: ', err);
    }
  );
};
