import React, { useCallback, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import {
  monthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { TotalSpendData } from './useTotalSpendGraphData';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';

type TotalSpendGraphProps = {
  data: TotalSpendData;
};

const TotalSpendGraph = ({ data }: TotalSpendGraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const tooltipXAxisValueFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
  });

  const { currency, grossSpend, invoicedSpend, months } = data;

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: { type: 'column' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, currency) },
          },
          xAxis: {
            categories: months,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true },
          series: [
            {
              name: t('TOTAL_SPEND.GROSS_SPEND'),
              type: 'column',
              color: palette.visualization.divergent.primary['-20'],
              states: {
                hover: { color: palette.visualization.divergent.primary['-50'] },
              },
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    point.series.points.forEach((p) => {
                      if (p !== point) {
                        p.update({ color: palette.visualization.divergent.primary['-10'] }, false);
                      }
                    });
                  },
                  mouseOut(e) {
                    const point = this;
                    point.series.points.forEach((p) => {
                      if (p !== point) {
                        p.update({ color: palette.visualization.divergent.primary['-20'] }, false);
                      }
                    });
                  },
                },
              },
              borderRadius: 8,
              data: grossSpend,
            },
            {
              name: t('TOTAL_SPEND.INVOICED_SPEND'),
              type: 'line',
              color: palette.core.color6,
              lineWidth: 2,
              data: invoicedSpend,
              // Attempt to avoid being overlapped by xAxis
              clip: false,
            },
          ],
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, locale, currency, months, t, grossSpend, invoicedSpend]
  );

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const legendItems = extractLegendItemsFromSeries(options);

  return (
    <Stack gap={1.5}>
      {legendItems.length > 0 && (
        <Box alignSelf="flex-end" px={6}>
          <Legend items={legendItems} />
        </Box>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => (
          <TooltipWrapper>
            {!!points && points.length > 0 && (
              <TooltipValueWithTimeInfo value="" timeInfo={tooltipXAxisValueFormatter.format(new Date(points[0].x!))} />
            )}
            {points?.map((point) => {
              const formattedValue = formatCurrency(point.y!, {
                currency,
                maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
              });
              return (
                <TooltipValueWithTimeInfo
                  value={formattedValue}
                  valueSuffix={point.series.userOptions.name}
                  key={point.series.name}
                />
              );
            })}
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default TotalSpendGraph;
