import { useCumulativeSumChartData } from './useCumulativeSumChartData';
import CumulativeSumChart from './CumulativeSumChart';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';

const CumulativeSumTab = () => {
  const { data, error, isFetching } = useCumulativeSumChartData();

  return (
    <LoadableContentWrapper isEmpty={!data} error={error} isLoading={isFetching || (!data && !error)}>
      <CumulativeSumChart data={data!} />
    </LoadableContentWrapper>
  );
};

export default CumulativeSumTab;
