import React from 'react';
import { SageMaker, Compute } from './product';
import { useCloudContext } from '../../../../../modules/cloud/CloudContext';
import { useRoutes } from '@verticeone/router/src';
import NotImplemented from '../../components/NotImplemented';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { CloudOptimizeProvider, Tab } from './useCloudOptimize';
import { CloudOptimizeContent } from './components';

const CloudOptimizeTab = () => {
  const { subFeatures } = useCloudContext();
  const routes = useRoutes();

  const tabs: Tab[] = [];

  if (subFeatures.sprioComputeSP) {
    tabs.push({
      id: 'compute',
      component: Compute,
    });
  }

  if (subFeatures.sprioEC2SP) {
    tabs.push({
      id: 'ec2',
      component: NotImplemented,
    });
  }

  if (subFeatures.sprioSagemakerSP) {
    tabs.push({
      id: 'sagemaker',
      component: SageMaker,
    });
  }

  const routeConfig = {
    path: ROUTES.CLOUD_RIO_SAVINGS_PLANS_DETAIL,
    route: routes.CLOUD.RIO.SP.OPTIMIZE.DETAIL,
  };

  return (
    <CloudOptimizeProvider tabs={tabs} routeConfig={routeConfig}>
      <CloudOptimizeContent />
    </CloudOptimizeProvider>
  );
};

export default CloudOptimizeTab;
