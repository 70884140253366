import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../types';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { isNil } from 'lodash';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { useCallback } from 'react';

export type UseFormatContractCurrencyReturn = (value?: string | number) => string | undefined;

/** Returns formatter that formats numbers with base currency from ContractContext. */
const useFormatContractCurrency = (): UseFormatContractCurrencyReturn => {
  const currency = useWatch<ContractFormData>({ name: 'parts.contractual.financial.baseCurrency' });
  const formatCurrency = useFormatCurrency();

  return useCallback(
    (value?: string | number) => {
      const numericValue = typeof value === 'string' ? parseFloat(value) : value;
      if (isNil(numericValue)) {
        return undefined;
      }

      return formatCurrency(numericValue, { currency: currency ?? DEFAULT_CURRENCY, maximumFractionDigits: 0 });
    },
    [currency, formatCurrency]
  );
};

export default useFormatContractCurrency;
