import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { RenewalDeadline } from '../../types';
import { isContractInStagesFn, isPlannedForExpiration, isRollingContract } from '../../computed';
import dayjs from 'dayjs';

/**
 * FE deadline = When the contract is marked for expiration, we don't technically have a deadline to act on/send
 * notifications or anything...
 * Still, we want to render it in the deadline cell.
 */
export const getFeDeadline = (contract: Contract): RenewalDeadline | undefined => {
  return isPlannedForExpiration(contract) && !contract.materialized?.flags?.alreadyRenewed
    ? { deadlineDate: contract.parts.contractual?.lifecycle?.renewalDate }
    : contract.materialized?.renewalDeadline ?? undefined;
};

export const shouldShowRollingFrequency = (contract: Contract) =>
  isContractInStagesFn(['DRAFT', 'LIVE', 'NOT_YET_LIVE'])(contract) && !contract.materialized?.flags?.alreadyRenewed;

export const hasFeDeadlineOrRollingFrequency = (contract: Contract) =>
  getFeDeadline(contract)?.deadlineDate || (isRollingContract(contract) && shouldShowRollingFrequency(contract));

export const getTimeLeft = (deadline: dayjs.Dayjs): string | undefined => {
  const daysFromNow = deadline.diff(dayjs(), 'day');
  if (daysFromNow < 0) {
    return '{overdue}';
  } else if (daysFromNow === 0) {
    return '{today}';
  } else if (daysFromNow === 1) {
    return `${daysFromNow} {day_left}`;
  } else if (daysFromNow < 13) {
    return `${daysFromNow} {days_left}`;
  } else if (daysFromNow < 91) {
    return `${Math.ceil(daysFromNow / 7)} {weeks_left}`;
  }

  return undefined;
};
