import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons, ImageHeader } from '@vertice/core/src/components/Dialog/Components';
import { VerticeSlackIntegration560 } from '@vertice/assets';
import { Text } from '@verticeone/design-system/src';
import { styled } from '@mui/material';

const useStartingStep = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goNext: () => wizard.goToStep('enter_email'),
  };
};

const StyledList = styled('ul')({
  paddingLeft: '16px',
  margin: 0,
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const StartingStep: React.FC<WizardStepProps> = () => {
  const { goBack, goNext } = useStartingStep();
  const { t } = useTranslation();

  return (
    <Content>
      <ImageHeader>
        <VerticeSlackIntegration560 />
      </ImageHeader>
      <Header
        title={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.STARTING_STEP.TITLE')}
        titleDimmedPart={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.STARTING_STEP.TITLE_DIMMED_PART')}
        subtitle={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.STARTING_STEP.SUBTITLE')}
      />
      <InnerScrollable>
        <Text variant={'caption'} size="XS" color="text2">
          {t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.STARTING_STEP.PREREQUISITES')}
        </Text>
        <StyledList>
          <li>
            <Text variant={'body-regular'} color={'text1'}>
              {t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.STARTING_STEP.PREREQUISITE_1')}
            </Text>
          </li>
          <li>
            <Text variant={'body-regular'} color={'text1'}>
              {t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.STARTING_STEP.PREREQUISITE_2')}
            </Text>
          </li>
        </StyledList>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: goNext,
          title: t('DIALOG.BUTTONS.NEXT'),
        }}
      />
    </Content>
  );
};

export default StartingStep;
