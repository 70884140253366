import { ErrorOption, FieldPath, UseFormReturn, FieldValues } from 'react-hook-form';
import { forEach } from 'lodash';

/**
 * @file
 * This file is a set of opinionated utils for react-hook-form.
 *
 * The goal is to reduce boilerplate for common use cases.
 */

export type SubmitErrors<FormDataType extends FieldValues> = Partial<Record<FieldPath<FormDataType>, ErrorOption>>;

export type GoodFormOnSubmitCallback<FormDataType extends FieldValues> = (
  formData: FormDataType
) => Promise<SubmitErrors<FormDataType> | void>;

export type GoodFormProps<FormDataType extends FieldValues> = {
  defaultValues: FormDataType;
  onSubmit: (formData: FormDataType) => Promise<SubmitErrors<FormDataType> | void>;
};

/** When submit ends with errors, this function helps to apply the errors to all the problematic fields. */
export const applySubmitErrors = <FormDataType extends FieldValues>(
  submitErrors: SubmitErrors<FormDataType>,
  formMethods: UseFormReturn<FormDataType, unknown>
) => {
  forEach(submitErrors, (error, fieldPath) => {
    if (!error) return;
    formMethods.setError(fieldPath as FieldPath<FormDataType>, error);
  });
};

/** Hook that provides opinionated methods for react-hook-form, e.g.
 * - handleSubmit - a better alternative to formMethods.handleSubmit that adds support for handling submit errors.
 *   You can return promise to get proper handling of submit errors and submit button disabling/loading.
 * */
export const useGoodFormUtils = <FormDataType extends FieldValues>(
  formMethods: UseFormReturn<FormDataType, unknown>
) => {
  const handleSubmit = (onSubmit: GoodFormOnSubmitCallback<FormDataType>) =>
    formMethods.handleSubmit(async (formData) => {
      const submitErrors = await onSubmit(formData as FormDataType);
      if (submitErrors) {
        applySubmitErrors(submitErrors, formMethods);
      }
    });

  return {
    handleSubmit,
  };
};
