import { Stage } from '@vertice/core/src/modules/saas/schedule/components/ScheduleView/types';
import {
  ContractListColumn,
  FilterView,
  PREDEFINED_VIEW_IN_PROGRESS,
} from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import { isUpcomingContract } from './filter';

export type DashboardStage = Exclude<Stage, 'RECENTLY_COMPLETED'>;

export enum ContractGridType {
  DashboardInProgress = 'IN_PROGRESS',
  DashboardRecentlyCompleted = 'RECENTLY_COMPLETED',
  DashboardUpcoming = 'UPCOMING',
}

export const DASHBOARD_FILTERS: Record<DashboardStage, FilterView> = {
  [ContractGridType.DashboardInProgress]: {
    ...PREDEFINED_VIEW_IN_PROGRESS,
    columns: [
      ContractListColumn.VENDOR,
      ContractListColumn.ANNUAL_COST,
      ContractListColumn.STAGE,
      ContractListColumn.AUTO_RENEWAL_DATE,
      ContractListColumn.RENEWAL_DATE,
      ContractListColumn.TARGET_SIGN_DATE,
    ],
  },
  [ContractGridType.DashboardUpcoming]: {
    type: 'predefined',
    key: 'upcoming',
    filterFunction: isUpcomingContract,
    labelToTranslate: 'ENTITIES.CONTRACT.LABELS.LIST_FILTERS.UPCOMING',
    columns: [
      ContractListColumn.VENDOR,
      ContractListColumn.ANNUAL_COST,
      ContractListColumn.AUTO_RENEWAL_DATE,
      ContractListColumn.RENEWAL_DATE,
    ],
  },
} as const;
