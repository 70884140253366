import { useMemo } from 'react';
import { ContractEntityCategory } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';

export type ContractCategoryOption = {
  value: ContractEntityCategory;
  label: string;
};

export const useContractCategoryOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      Array<ContractEntityCategory>('SAAS', 'OTHER').map(
        (value): ContractCategoryOption => ({
          value,
          label: t(`ENTITIES.CONTRACT.CONTRACT_CATEGORIES.${value}`),
        })
      ),
    [t]
  );
};
