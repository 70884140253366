import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import DataGridPriceCell from '@vertice/core/src/components/DataGridPriceCell/DataGridPriceCell';
import { FormControl } from '@mui/material';

const AmountCellEditable = (params: GridCellParams) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <DataGridPriceCell placeholder={t('CONCESSIONS_LIST.PLACEHOLDER.VALUE')} {...params} />
    </FormControl>
  );
};

export default AmountCellEditable;
