import { createPortableRoutes } from '@verticeone/router/src/routes/utils';
import { applicationsRoutes } from '../modules/applications/routes';
import { pageNotFoundRoutes } from '../modules/notFound/routes';
import { intelligentWorkflowsRoutes } from '../modules/intelligentWorkflows/routes';

export const contractRoutes = createPortableRoutes({
  CONTRACTS: {
    path: '/contracts',
    children: {
      DETAIL: {
        path: ':contractId',
      },
    },
  },
} as const);

export const coreRoutes = {
  ...pageNotFoundRoutes,
  ...applicationsRoutes,
  ...contractRoutes,
  ...intelligentWorkflowsRoutes,
};
