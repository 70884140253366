import React from 'react';
import { Stack } from '@mui/material';
import type { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';

import type { OptimizationTestRow } from '../useOptimizationTestsData';
import DefaultColumn from './DefaultColumn';
import EffortChip from '../Components/EffortChip';
import useOptimizationTestsSearchParamsStateStore from '../useOptimizationTestsTableSearchParamsStateStore';

type CategoryColumnProps = GridRenderCellParams<OptimizationTestRow, any, any, GridTreeNodeWithRender>;

const EffortChips = ({ row }: { row: OptimizationTestRow }) => {
  const effortPriority: Record<'LOW' | 'MEDIUM' | 'HIGH', number> = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
  };

  const efforts = row.recommendations
    .map(({ effort }) => effort)
    .filter(Boolean)
    .sort((a, b) => effortPriority[a!] - effortPriority[b!]);

  return (
    <Stack gap={2} alignItems="center" direction="row">
      {efforts.map((effort) => (
        <EffortChip key={effort} effort={effort} />
      ))}
    </Stack>
  );
};

const CategoryColumn = (params: CategoryColumnProps) => {
  const { state } = useOptimizationTestsSearchParamsStateStore();

  return (
    <Stack direction="row" gap={2} flex={1} justifyContent="space-between">
      <DefaultColumn {...params} />
      {!state.expandedTests.includes(params.row.code) && <EffortChips row={params.row} />}
    </Stack>
  );
};

export default CategoryColumn;
