import { Divider } from '@mui/material';
import { GridColumnMenuContainer, type GridColumnMenuProps } from '@mui/x-data-grid-pro';
import { MenuContextProvider } from '../../Menu/MenuContext';
import { GridColumnMenuSortItem } from './SortGridMenuItems';
import { FilterGridMenuItem } from './FilterGridMenuItem';

const ColumnMenu = ({ ...props }: GridColumnMenuProps) => (
  <MenuContextProvider value={{ color: 'neutral' }}>
    <GridColumnMenuContainer {...props}>
      <GridColumnMenuSortItem onClick={props.hideMenu} colDef={props.colDef} />
      {props.colDef.filterable && <Divider /> && <FilterGridMenuItem onClick={props.hideMenu} colDef={props.colDef} />}
    </GridColumnMenuContainer>
  </MenuContextProvider>
);

export default ColumnMenu;
