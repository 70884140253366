import React, { forwardRef, useCallback, useState } from 'react';
import { DateFieldProps as MuiDateFieldProps } from '@mui/x-date-pickers-pro';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../../../types';
import variantDefinitions from './variantDefinitions';
import { testProps } from '../../../../utils/testProperties';
import { Dayjs } from 'dayjs';

export type DateFieldVariant = 'outlined' | 'solid' | 'ghost';
export const allVariants: DateFieldVariant[] = ['outlined', 'solid', 'ghost'];

export type DateFieldProps = TestProps &
  CustomizedProps &
  Omit<MuiDateFieldProps<Dayjs, boolean>, 'size' | 'variant' | 'color'> & {
    variant?: DateFieldVariant;
    size?: DesignSystemSize;
    color?: DesignSystemColor;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
  };

const MUI_VARIANTS_WITH_UNDERLINE = ['standard', 'filled'];

const DateField = forwardRef<HTMLDivElement, DateFieldProps>(
  (
    {
      variant = 'outlined',
      color = 'primary',
      size = 'M',
      hiddenLabel = false,
      InputProps,
      value,
      defaultValue,
      onChange,
      onKeyDown,
      onWheel,
      startAdornment,
      endAdornment,
      testId,
      ...otherProps
    },
    ref
  ) => {
    const { component: StyledDateField, muiVariant } = variantDefinitions[variant] ?? variantDefinitions.outlined;
    const isControlled = typeof value !== 'undefined';

    // We mirror internal value upwards for uncontrolled use-cases so that we can apply styles based on current value.
    const [mirroredValue, setMirroredValue] = useState(isControlled ? null : defaultValue);
    const handleChange = useCallback<NonNullable<DateFieldProps['onChange']>>(
      (day, ...props) => {
        if (!isControlled) {
          setMirroredValue(day);
        }
        if (onChange) {
          onChange(day, ...props);
        }
      },
      [onChange, isControlled]
    );

    const handleWheel = useCallback<NonNullable<DateFieldProps['onWheel']>>(
      (e, ...props) => {
        if (onWheel) {
          onWheel(e, ...props);
        }
      },
      [onWheel]
    );

    const handleKeyDown = useCallback<NonNullable<DateFieldProps['onKeyUp']>>(
      (e, ...props) => {
        if (onKeyDown) {
          onKeyDown(e, ...props);
        }
      },
      [onKeyDown]
    );

    return (
      <StyledDateField
        ref={ref}
        variant={muiVariant}
        size={size}
        hiddenLabel={hiddenLabel}
        color={color}
        InputProps={{
          ...(MUI_VARIANTS_WITH_UNDERLINE.includes(muiVariant) ? { disableUnderline: true } : {}),
          startAdornment,
          endAdornment,
          ...InputProps,
        }}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onWheel={handleWheel}
        {...otherProps}
        {...testProps(testId, 'date-field')}
        $internalValue={isControlled ? value : mirroredValue}
      />
    );
  }
);

export default DateField;
