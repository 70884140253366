import React from 'react';
import type { DesignSystemSize } from '../../types';
import { DialogWrapper } from './DialogWrapper';
import { useDialog } from './DialogContext';
import { testProps } from '../../utils/testProperties';

type DialogContentProps = {
  children: React.ReactNode;
  size?: DesignSystemSize;
};

export const DialogContent = ({ children, size }: DialogContentProps) => {
  const dialog = useDialog();

  return (
    <DialogWrapper $size={size ?? dialog.size} {...testProps(undefined, 'dialogContent')}>
      {children}
    </DialogWrapper>
  );
};
