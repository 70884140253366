import { useFormContext } from 'react-hook-form';
import { NewPurchaseFormData } from '../../types';
import { useCallback, useState } from 'react';
import {
  CreateRequestApiResponse,
  PurchaseRequestInput,
  useCreateRequestMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { formDataToExternalContract } from '../../../utils/convertors';
import { useUploadContractFileV2Mutation } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useWaitForRequestStage } from '@vertice/core/src/modules/saas/contract/utils/useWaitForRequestStage';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';
import useInvalidateContracts from '@vertice/core/src/modules/saas/contract/hooks/useInvalidateContracts';
import { isNil } from 'lodash';

/**
 *  Create Contracts V2 request for New Purchase
 */
export const useCreateNewPurchaseRequest = (onSuccess: () => void): [() => void, boolean] => {
  const { accountId, accountDetails } = useAccountContext();
  const { watch, setValue } = useFormContext<NewPurchaseFormData>();
  const formValues = watch();

  const [inProgress, setInProgress] = useState(false);
  const [createRequestMutation] = useCreateRequestMutation();
  const [uploadContractFile] = useUploadContractFileV2Mutation();
  const waitForRequestStage = useWaitForRequestStage();
  const invalidateContracts = useInvalidateContracts();

  const createRequest = useCallback(() => {
    void (async () => {
      try {
        if (isNil(formValues.vendor)) {
          // this should never happen as the vendor is always pre-filled
          throw new Error('Vendor is not selected');
        }

        const requestInput: PurchaseRequestInput = {
          accountBuyer: accountDetails?.associatedManager,
          contractCategory: 'SAAS',
          baseCurrency: formValues.contractCurrency ?? DEFAULT_CURRENCY,
          vendorType: formValues.vendor.type,
          vendorId: formValues.vendor.id,
          vendorName: formValues.vendor.name,
          departmentId: formValues.departmentId,
          additionalNotes: formValues.additionalNotes,
          contractLength: formValues.desiredContractLength,
          signingEntity: formValues.signingEntity,
          targetSignDate: keepOnlyLocalDatePart(formValues.targetSignDate),
          startDate: keepOnlyLocalDatePart(formValues.startDate),
          userPlannedBudget: formValues.approvedBudget,
          applicationOrigin: 'CFA',
          vendorProducts: formValues.products,
          external: formDataToExternalContract(formValues),
        };

        setInProgress(true);

        const createRequestResponse = await createRequestMutation({
          accountId: accountId!,
          createRequest: {
            type: 'PURCHASE',
            name: `${formValues.vendor.name} New Purchase`,
            input: requestInput,
          },
        });

        const requestReference = (createRequestResponse as { data: CreateRequestApiResponse }).data?.ref;
        if (requestReference) {
          const requestId = parseRequestRef(requestReference).requestId;
          const contractId = requestId; // ID of the contract created is the same as the request id

          await waitForRequestStage(accountId, requestId);
          invalidateContracts();

          // Upload contract documents
          for await (const file of formValues.files) {
            await uploadContractFile({
              accountId,
              contractId,
              fileName: file.name,
              body: file,
            });
          }

          setValue('newContractId', contractId);
          onSuccess();
        }
      } finally {
        setInProgress(false);
      }
    })();
  }, [
    formValues,
    accountDetails,
    createRequestMutation,
    accountId,
    waitForRequestStage,
    setValue,
    onSuccess,
    uploadContractFile,
    invalidateContracts,
  ]);

  return [createRequest, inProgress];
};
