import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Vendor } from '@vertice/slices';
import { Box, Stack } from '@mui/material';
import { Loader } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import VendorItem from './components/VendorItem';
import useVendorAnalytics from './hooks/useVendorAnalytics';
import useVendorData, { VENDORS_LOAD_LIMIT } from './hooks/useVendorData';
import { VendorListFilterContext } from './VendorListFilterContext';
import VendorsPageHeader from './components/VendorsPageHeader';
import { useRequestsAnalytics } from './hooks/useRequestsAnalytics';

const Vendors = () => {
  const { t } = useTranslation();
  const { listFilter, search, category, vendorsOffset, setVendorsOffset } = useContext(VendorListFilterContext);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  let clearVendors = false;

  const onShowNextVendors = () => setVendorsOffset(vendorsOffset + VENDORS_LOAD_LIMIT);

  useEffect(() => {
    setVendors([]);
    setVendorsOffset(0);
    // Late update handling
    // eslint-disable-next-line react-hooks/exhaustive-deps
    clearVendors = true;
  }, [listFilter, search, category]);

  const { analyticsPerVendor, isLoading: isAnalyticsLoading } = useVendorAnalytics();
  const { loadedVendors, isFetching: isLoadingVendors } = useVendorData();
  const { requestsPerVendor, isRequestsLoading } = useRequestsAnalytics();

  useEffect(() => {
    if (!isLoadingVendors && loadedVendors.length > 0) {
      setTimeout(() => setVendors([...(clearVendors ? [] : vendors), ...loadedVendors]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedVendors]);

  return (
    <Stack>
      <VendorsPageHeader />
      <Stack px={8} pb={8} gap={7}>
        <Stack mr="-40px" gap={10} sx={{ flexFlow: 'wrap' }}>
          {vendors?.map((vendor: Vendor) => (
            <VendorItem
              vendor={vendor}
              key={vendor.id}
              contractCounts={
                vendor.id && analyticsPerVendor ? analyticsPerVendor[vendor.id]?.contractCounts : undefined
              }
              requestsCount={
                vendor.id && requestsPerVendor ? requestsPerVendor[vendor.id]?.activeRequestsCount : undefined
              }
              addedProducts={vendor.id && analyticsPerVendor ? analyticsPerVendor[vendor.id]?.productNames : undefined}
            />
          ))}
        </Stack>

        {isAnalyticsLoading || isLoadingVendors || isRequestsLoading ? (
          <Box>
            <Loader />
          </Box>
        ) : (
          VENDORS_LOAD_LIMIT === loadedVendors.length && (
            <Button variant="plain" size="S" onClick={onShowNextVendors}>
              {t('VENDORS.SHOW_NEXT', { vendorsCount: VENDORS_LOAD_LIMIT })}
            </Button>
          )
        )}
      </Stack>
    </Stack>
  );
};

export default Vendors;
