import { createRequiredContext } from '../contexts/createRequiredContext';
import { PropsWithChildren, useMemo } from 'react';

// Note: We're keeping the shape the same as Locale context in core and other packages. This way we can easily move
//       components between packages without changing their code too much.
export type LocaleContextData = {
  locale: string;
};

const { LocaleContextProvider: RawLocaleContextProvider, useLocaleContext } = createRequiredContext<
  LocaleContextData,
  'Locale'
>('Locale');

export const LocaleContextProvider = ({ locale, children }: PropsWithChildren<{ locale: string }>) => {
  const contextData = useMemo(() => ({ locale }), [locale]);
  return <RawLocaleContextProvider value={contextData}>{children}</RawLocaleContextProvider>;
};

export { useLocaleContext };
