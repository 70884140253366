import React, { useMemo } from 'react';
import { isNotNil } from '@verticeone/utils/validation';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useTranslation } from 'react-i18next';
import { IconButton, TabProps } from '@verticeone/design-system/src';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import { useHasProductsWithBenchmarkingData } from '@vertice/core/src/hooks/useHasProductsWithBenchmarkingData';

export type UseProductTabBenchmarkingDataReturn = TabProps | undefined;

export const useProductTabBenchmarkingProps = (): UseProductTabBenchmarkingDataReturn => {
  const { t } = useTranslation();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const contract = contractContext.fetchedContract.contract;

  const contractProductIds = useMemo(
    () => contract.parts.contractual?.products?.map((product) => product.productId).filter(isNotNil),
    [contract.parts.contractual?.products]
  );

  const showBenchmarkingButton = useHasProductsWithBenchmarkingData({
    vendorId: contract.parts.contractual?.vendor?.vendorId,
    products: contractProductIds,
  });

  return useMemo(() => {
    if (!showBenchmarkingButton) {
      return undefined;
    }

    return {
      icon: (
        <IconButton
          icon={LeaderboardOutlinedIcon}
          size="XS"
          color="primary"
          component="div"
          aria-label={t('ENTITIES.CONTRACT.LABELS.BENCHMARK')}
          sx={{ pointerEvents: 'none' }}
        />
      ),
      iconPosition: 'end',
    };
  }, [showBenchmarkingButton, t]);
};
