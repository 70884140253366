import React, { FC } from 'react';
import ScheduleView from '@vertice/core/src/modules/saas/schedule/components/ScheduleView';
import { useScheduleItems } from '../hooks/useScheduleItems';

const Calendar: FC = () => {
  const scheduleItems = useScheduleItems();

  return <ScheduleView items={scheduleItems} />;
};

export default Calendar;
