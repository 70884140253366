import { TypographyOptions } from '@mui/material/styles/createTypography';

const headingM = {
  fontWeight: 600,
  fontSize: '1.5rem',
  lineHeight: '120%',
  letterSpacing: '-0.02em',
};
const headingS = {
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: '120%',
  letterSpacing: '-0.01em',
};
const headingXS = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '120%',
};

const commonXL = {
  fontWeight: 400,
  fontSize: '1.25rem',
  lineHeight: '125%',
  letterSpacing: '-0.01em',
};
const commonL = {
  fontWeight: 400,
  fontSize: '1.125rem',
  lineHeight: '125%',
  letterSpacing: '-0.01em',
};
const commonM = {
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '130%',
};
const commonS = {
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '120%',
};

const commonXS = {
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: '135%',
  letterSpacing: '0.01em',
};
const commonXXS = {
  fontWeight: 400,
  fontSize: '0.625rem',
  lineHeight: '135%',
  letterSpacing: '0.01em',
};

export const typography: TypographyOptions = {
  fontFamily: ['Inter', 'sans-serif'].join(','),
  fontSize: 12,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  h1: headingM,
  h2: headingS,
  h3: headingXS,
  button: {
    textTransform: 'none',
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.05em',
  },
  'display-l': {
    fontWeight: 600,
    fontSize: '4.5rem',
    lineHeight: '105%',
    letterSpacing: '-0.03em',
  },
  'display-m': {
    fontWeight: 600,
    fontSize: '3.75rem',
    lineHeight: '105%',
    letterSpacing: '-0.03em',
  },
  'display-s': {
    fontWeight: 600,
    fontSize: '3rem',
    lineHeight: '105%',
    letterSpacing: '-0.03em',
  },
  'heading-xl': {
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '115%',
    letterSpacing: '-0.03em',
  },
  'heading-l': {
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  'heading-m': headingM,
  'heading-s': headingS,
  'heading-xs': headingXS,
  'heading-xxs': {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '120%',
    letterSpacing: '0.01em',
  },
  'body-bold-xl': {
    ...commonXL,
    fontWeight: 600,
  },
  'body-bold-l': {
    ...commonL,
    fontWeight: 600,
  },
  'body-bold-m': {
    ...commonM,
    fontWeight: 600,
  },
  'body-bold-s': {
    ...commonS,
    fontWeight: 600,
  },
  'body-bold-xs': {
    ...commonXS,
    fontWeight: 600,
  },
  'body-bold-xxs': {
    ...commonXXS,
    fontWeight: 600,
  },
  'body-regular-xl': {
    ...commonXL,
  },
  'body-regular-l': {
    ...commonL,
  },
  'body-regular-m': {
    ...commonM,
  },
  'body-regular-s': {
    ...commonS,
  },
  'body-regular-xs': {
    ...commonXS,
  },
  'body-regular-xxs': {
    ...commonXXS,
    fontWeight: 600,
  },
  'button-xl': {
    ...commonXL,
    fontWeight: 600,
    lineHeight: '100%',
  },
  'button-l': {
    ...commonL,
    fontWeight: 600,
    lineHeight: '100%',
  },
  'button-m': {
    ...commonM,
    fontWeight: 600,
    lineHeight: '100%',
  },
  'button-s': {
    ...commonS,
    fontWeight: 600,
    lineHeight: '100%',
  },
  'button-xs': {
    ...commonXS,
    fontWeight: 600,
    lineHeight: '100%',
  },
  'button-xxs': {
    ...commonXXS,
    fontWeight: 600,
    lineHeight: '100%',
  },
  'link-xl': {
    ...commonXL,
    textDecorationLine: 'underline',
  },
  'link-l': {
    ...commonL,
    textDecorationLine: 'underline',
  },
  'link-m': {
    ...commonM,
    textDecorationLine: 'underline',
  },
  'link-s': {
    ...commonS,
    textDecorationLine: 'underline',
  },
  'link-xs': {
    ...commonXS,
    textDecorationLine: 'underline',
  },
  'link-xxs': {
    ...commonXXS,
    textDecorationLine: 'underline',
  },
  'code-m': {
    ...commonM,
    fontFamily: 'JetBrains-Mono',
    fontWeight: 500,
  },
  'code-s': {
    ...commonS,
    fontFamily: 'JetBrains-Mono',
    fontWeight: 500,
  },
  'label-xl': {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'label-l': {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'label-m': {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'label-s': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'label-xs': {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'label-xxs': {
    fontWeight: 600,
    fontSize: '0.625rem',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'caption-xl': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '100%',
  },
  'caption-l': {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '100%',
  },
  'caption-m': {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '100%',
  },
  'caption-s': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '100%',
  },
  'caption-xs': {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '100%',
  },
  'caption-xxs': {
    fontWeight: 600,
    fontSize: '0.625rem',
    lineHeight: '100%',
  },
};
