import React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { GridSelect } from '@verticeone/design-system/src';
import { CustomFieldIdentifier, FieldItem } from '../../types';
import { orderBy, uniqBy } from 'lodash';
import useFieldTypes from '../../useFieldTypes';
import { Text } from '@verticeone/design-system/src';
import InactiveCell from './InactiveCell';
import { Stack, styled } from '@mui/material';
import { getUsedFieldTypes } from '../../utils';

type OptionCellContainerProps = {
  $error?: boolean;
};

const OptionCellContainer = styled(Stack)<OptionCellContainerProps>(({ theme, $error }) => ({
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  border: `1px solid ${$error ? theme.palette.error.color1 : theme.palette.transparent}`,
}));

type EditableFieldTypeProps = GridRenderCellParams<FieldItem, CustomFieldIdentifier | undefined> & {
  error?: boolean;
};

const EditableFieldTypeCell = ({ error, id, value, field, row }: EditableFieldTypeProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION' });
  const apiRef = useGridApiContext();
  const usedFieldTypes = getUsedFieldTypes(apiRef);
  const { availableFieldTypes } = useFieldTypes({ excluded: usedFieldTypes });
  const options = value ? uniqBy([...availableFieldTypes, value], 'id') : availableFieldTypes;
  const orderedOptions = orderBy(options, 'label');

  if (!row.isNew) {
    return (
      <InactiveCell>
        <Text variant="body-regular">{value?.label ?? ''}</Text>
      </InactiveCell>
    );
  }

  return (
    <OptionCellContainer $error={error}>
      <GridSelect
        value={value}
        options={orderedOptions}
        isMulti={false}
        isClearable={true}
        isDisabled={!row.isNew}
        placeholder={t('SELECT_FIELD_TYPE')}
        menuPlacement="auto"
        getOptionValue={(option) => (option as CustomFieldIdentifier).id}
        getOptionLabel={(option) => (option as CustomFieldIdentifier).label}
        onChange={(newValue) => {
          void apiRef.current.setEditCellValue({ id, field, value: newValue });
        }}
      />
    </OptionCellContainer>
  );
};

export default EditableFieldTypeCell;
