import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { keyBy } from 'lodash';
import { ContractFormData } from '../../types';
import usePaymentTermsOptions from '../../hooks/usePaymentTermsOptions';

type PaymentTermsFormEntryProps = Omit<ContractFormEntryProps<any, any>, 'component' | 'writeComponent'>;

type PaymentTermsSelectOption = { value: string; label: string };

const PaymentTermsFormEntry = (options: PaymentTermsFormEntryProps) => {
  const paymentTermsOptions = usePaymentTermsOptions();
  const paymentTermsMap = useMemo(() => keyBy(paymentTermsOptions, 'value'), [paymentTermsOptions]);

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, PaymentTermsSelectOption, false>
    >
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? paymentTermsMap[value]?.label : undefined),
        ...options.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: paymentTermsOptions,
        boxValue: (value) => (value ? paymentTermsMap[value as string] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
    />
  );
};

export default PaymentTermsFormEntry;
