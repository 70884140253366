import React, { ForwardedRef } from 'react';
import { Stack } from '@mui/material';
import { CustomContentProps, useSnackbar, SnackbarContent, WithDescription } from 'notistack';
import {
  type SvgIconComponent,
  InfoOutlined,
  CheckCircleOutlineOutlined,
  WarningAmberOutlined,
  ErrorOutline,
  CloseOutlined,
} from '@mui/icons-material';

import type { WithRequired } from '../../types/WithRequired';
import { Alert, AlertIconButton } from '@verticeone/design-system/src';
import { DesignSystemColor } from '@verticeone/design-system/src';

const severityIcons: Partial<Record<DesignSystemColor, SvgIconComponent>> = {
  info: InfoOutlined,
  success: CheckCircleOutlineOutlined,
  warning: WarningAmberOutlined,
  error: ErrorOutline,
};

const Notification = React.forwardRef(
  (
    {
      id,
      variant,
      message,
      style,
      className,
      description,
      action,
    }: WithRequired<CustomContentProps & WithDescription, 'message'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { closeSnackbar } = useSnackbar();

    const messageString = message?.toString() ?? '';

    return (
      <SnackbarContent ref={ref} role="alert" style={style} className={className}>
        <Alert
          icon={severityIcons[variant]}
          color={variant ?? 'info'}
          variant="solid"
          size="M"
          // If there is only message with no description, we render it as subtitle.
          title={description ? messageString : undefined}
          subtitle={description ?? messageString}
        >
          <Stack direction="row" gap={1}>
            {typeof action === 'function' ? action(id) : action}
            <AlertIconButton icon={CloseOutlined} onClick={() => closeSnackbar(id)} />
          </Stack>
        </Alert>
      </SnackbarContent>
    );
  }
);

export default Notification;
