import React from 'react';
import { MultiChipContainer } from '@verticeone/design-system/src';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { FieldItem, FieldType } from '../../types';
import { Box, Stack, styled } from '@mui/material';
import { EditNoteOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@verticeone/design-system/src';
import InactiveCell from './InactiveCell';
import { useCustomFieldsListContext } from '../../CustomFieldsListContextProvider';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

type OptionCellContainerProps = {
  $error?: boolean;
};

const StyledChipLabel = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const OptionCellContainer = styled(Stack)<OptionCellContainerProps>(({ theme, $error }) => ({
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${$error ? theme.palette.error.color1 : theme.palette.transparent}`,
  gap: theme.spacing(2),
}));

type OptionCellProps = GridRenderCellParams<FieldItem, CustomContractFieldDropdownOption[] | undefined> & {
  error?: boolean;
};

const EditableOptionCell = ({ error, id, field, value, row }: OptionCellProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const isDropdown = row.fieldType?.type === FieldType.DROPDOWN;
  const { setDialogContext } = useCustomFieldsListContext();

  const handleOpenDialog = () => {
    setDialogContext({
      values: apiRef.current.state.editRows[id].options.value,
      onSave: (newValue) => {
        return apiRef.current.setEditCellValue({ id, field, value: newValue });
      },
    });
  };

  if (!isDropdown) {
    return <InactiveCell />;
  }

  return (
    <OptionCellContainer $error={error} px={2}>
      <Box minWidth={0} flexGrow={1}>
        <MultiChipContainer
          size="M"
          isActive={false}
          maxChips={3}
          chips={
            value?.map((option: CustomContractFieldDropdownOption) => {
              return {
                children: <StyledChipLabel>{option.label}</StyledChipLabel>,
                label: option.label,
                color: option.enabled ? 'primary' : 'neutral',
                variant: 'ghost',
                isActive: false,
              };
            }) ?? []
          }
        />
      </Box>
      <IconButton
        variant="ghost"
        color="neutral"
        onClick={handleOpenDialog}
        icon={EditNoteOutlined}
        aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.SAVE_CHANGES')}
        sx={{ flexShrink: 0 }}
      />
    </OptionCellContainer>
  );
};

export default EditableOptionCell;
