import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  useGetUserSettingsQuery,
  UserSettings,
  useUpdateUserSettingsMutation,
} from '@vertice/slices/src/openapi/codegen/userAPI';

export type UseUserSettingsReturn = {
  settings: UserSettings | undefined;
  isLoading: boolean;
  saveSettings: (updatedSettings: UserSettings, withNotification?: boolean) => Promise<void>;
};

const useUserSettings = ({ userId, skip }: { userId?: string; skip?: boolean }): UseUserSettingsReturn => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetUserSettingsQuery(
    { userId: userId! },
    { skip: skip || !userId, refetchOnMountOrArgChange: true }
  );

  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const saveSettings = async (updatedSettings: UserSettings, withNotification = true) => {
    if (!userId) return;

    await updateUserSettings({ userId, userSettings: updatedSettings });
    if (withNotification) {
      enqueueSnackbar(t('PREFERENCES.NOTIFICATIONS.SUCCESS'), {
        variant: 'success',
      });
    }
  };

  return { settings: userSettings, isLoading: isUserSettingsLoading, saveSettings };
};

export default useUserSettings;
