import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Select, SimpleOption } from '@verticeone/design-system/src';
import { useListVendorCategoriesQuery } from '@vertice/slices';
import { SingleValue } from 'react-select';
import { VendorListFilterContext } from '../../VendorListFilterContext';

export const CategoryFilter: FC = () => {
  const { t } = useTranslation();
  const { category, setCategory } = useContext(VendorListFilterContext);

  const { data: categories } = useListVendorCategoriesQuery();
  const categoryOptions = categories?.items?.map((v) => ({ label: v, value: v })) ?? [];

  const onChange = (option: SingleValue<SimpleOption>) => setCategory(option?.value);

  return (
    <Stack minWidth={240}>
      <Select<SimpleOption, false>
        variant="solid"
        size="M"
        isClearable={true}
        options={categoryOptions}
        value={categoryOptions?.find(({ value }) => value === category)}
        placeholder={t('VENDORS.ALL_CATEGORIES')}
        onChange={onChange}
        testId={'category-filter'}
      />
    </Stack>
  );
};
