import { ActivityFrequencyData } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

export enum Bin {
  DAILY = 'DAILY',
  TWICE_A_WEEK = 'TWICE_A_WEEK',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  OCCASIONALLY = 'OCCASIONALLY',
}
export const Bins = Object.values(Bin);

export type ActivityFrequencyForChart = {
  values: Array<number>;
  mostCommon: { bin: Bin; value: number };
};

export const extractActivityFrequency = (activityFrequency: ActivityFrequencyData = []): ActivityFrequencyForChart => ({
  values: Bins.map((bin) => parseInt(activityFrequency.find((item) => item.binStart === bin)?.value || '0', 10)),
  mostCommon: activityFrequency
    .map((item) => ({ bin: item.binStart as Bin, value: parseInt(item.value, 10) }))
    .reduce((acc, curr) => (acc.value > curr.value ? acc : curr), { bin: Bin.OCCASIONALLY, value: 0 }),
});

export const ACTIVITY_FREQUENCIES = ['daily', 'twiceAWeek', 'weekly', 'biweekly', 'monthly', 'occasionally'] as const;
export type ActivityFrequency = (typeof ACTIVITY_FREQUENCIES)[number];
