import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import { licenses } from './config';
import { store } from './redux/store';
import App from './App';

LicenseInfo.setLicenseKey(licenses.muiProKey);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
