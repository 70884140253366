import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';
import { GetSsoConfigurationApiArg, GetSsoConfigurationApiResponse } from '../openapi/codegen/accountAPI';

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.ACCOUNTS),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getUnauthorizedSsoConfiguration: builder.query<GetSsoConfigurationApiResponse, GetSsoConfigurationApiArg>({
      query: (queryArg) => ({ url: `/accounts/sso/configuration`, params: { domain: queryArg.domain } }),
      extraOptions: {
        unauthorized: true,
      },
    }),
  }),
});

export const { useLazyGetUnauthorizedSsoConfigurationQuery } = accountsApi;
