import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Loader } from '@vertice/components';
import { useAddSsoDomainMutation, useGetSsoDetailsQuery, useRemoveSsoDomainMutation } from '@vertice/slices';
import DeleteIcon from '@mui/icons-material/Delete';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import StepsIndicator from './StepsIndicator';

import styles from './SamlIntegrationRegisterDomain.module.scss';
import commonStyles from './SamlIntegration.module.scss';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const domainRegexpStr = '[a-z0-9\\.]+';
const domainRegexp = new RegExp(domainRegexpStr);

const SamlIntegrationRegisterDomain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useAccountContext();
  const [addSsoDomain, { isLoading: isAddSsoDomainLoading }] = useAddSsoDomainMutation();
  const [removeSsoDomain, { isLoading: isRemoveSsoDomainLoading }] = useRemoveSsoDomainMutation();
  const { data: ssoDetails, isFetching } = useGetSsoDetailsQuery({ accountId: accountId! }, { skip: !accountId });

  useEffect(() => {
    if (ssoDetails) {
      if (ssoDetails.properties?.registeredDomains?.length) {
        setDomains(ssoDetails.properties?.registeredDomains);
      }
    }
  }, [ssoDetails]);

  const [domains, setDomains] = useState<string[]>(['']);
  const toUploadConfigStep = () => navigate(`${ROUTES.SAML}/${ROUTES.SAML_UPLOAD_CONFIG}`);
  const toTeamMemberStep = async () => {
    if (!accountId) {
      return;
    }

    const registeredDomains = ssoDetails?.properties?.registeredDomains || [];
    const filteredDomains = domains.filter((domain) => Boolean(domain)).filter((domain) => domainRegexp.test(domain));
    const newDomains: string[] = [];
    const deletedDomains: string[] = [];
    if (registeredDomains.length > 0) {
      filteredDomains.forEach((domain) => {
        if (registeredDomains.every((registeredDomain) => registeredDomain !== domain)) {
          newDomains.push(domain);
        }
      });

      registeredDomains.forEach((registeredDomain) => {
        if (filteredDomains.every((domain) => registeredDomain !== domain)) {
          deletedDomains.push(registeredDomain);
        }
      });
    } else {
      newDomains.push(...filteredDomains);
    }

    const deleteDomainsRequests = deletedDomains
      .filter((domain) => Boolean(domain))
      .map((domain) => removeSsoDomain({ accountId, domain }).unwrap());

    try {
      await Promise.all(deleteDomainsRequests);
    } catch (e) {
      enqueueSnackbar(t('SAML.SSO_DOMAIN.ERROR.DELETE'), {
        variant: 'error',
      });
    }

    const addDomainsRequests = newDomains
      .filter((domain) => Boolean(domain))
      .map((domain) => addSsoDomain({ accountId, accountSsoDomain: { domain } }).unwrap());

    try {
      await Promise.all(addDomainsRequests);
      navigate(`${ROUTES.SAML}/${ROUTES.SAML_TEAM_MEMBERS}`);
    } catch (e) {
      enqueueSnackbar(t('SAML.SSO_DOMAIN.ERROR.ADD'), {
        variant: 'error',
      });
    }
  };

  const addDomainInput = () => {
    if (domains.length < 10) {
      setDomains([...domains, '']);
    }
  };
  const deleteDomainInput = (index: number) => setDomains(domains.filter((domain, i) => i !== index));

  const onInputChange = (index: number, value: string) =>
    setDomains(domains.map((domain, i) => (i === index ? value : domain)));

  const isNextDisabled = domains.every((domain) => !domainRegexp.test(domain));
  const isLoading = !ssoDetails || isRemoveSsoDomainLoading || isAddSsoDomainLoading;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.SSO_DOMAIN.TITLE')}</Typography>
      </div>

      <div className={commonStyles['inner-body']}>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.SSO_DOMAIN.BODY_1')}</Typography>
        </div>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.SSO_DOMAIN.BODY_2')}</Typography>
        </div>

        <div className={styles['sso-domain']}>
          {t('SAML.SSO_DOMAIN.SSO_DOMAIN')}
          <span className={styles.asterisk}>*</span>
        </div>

        {isFetching && <Loader />}
        {!isFetching &&
          domains.map((domain, i) => (
            <div className={styles['domain-input-container']} key={`domain-${i}`}>
              <input
                placeholder={t('SAML.SSO_DOMAIN.INPUT_PLACEHOLDER')}
                className={styles['domain-input']}
                value={domain}
                onChange={(e: React.FormEvent<HTMLInputElement>) => onInputChange(i, e.currentTarget.value)}
                pattern={domainRegexpStr}
              />
              {domains.length > 1 && (
                <div className={styles.delete} onClick={() => deleteDomainInput(i)}>
                  <DeleteIcon />
                </div>
              )}
            </div>
          ))}

        {domains.length < 10 && (
          <Button variant="contained" className={styles['add-domain']} onClick={addDomainInput}>
            {t('SAML.SSO_DOMAIN.ADD_DOMAIN')}
          </Button>
        )}

        <div className={commonStyles['nav-buttons']}>
          <Button onClick={toUploadConfigStep} className={commonStyles['nav-button']} variant="outlined">
            {t('SAML.BACK')}
          </Button>
          <Button
            onClick={toTeamMemberStep}
            className={commonStyles['nav-button']}
            variant="contained"
            disabled={isNextDisabled || isLoading}
          >
            {isLoading ? <Loader size={16} /> : t('SAML.NEXT')}
          </Button>
        </div>
      </div>

      <div className={commonStyles.steps}>
        <StepsIndicator total={5} current={4} />
      </div>
    </div>
  );
};

export default SamlIntegrationRegisterDomain;
