import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system/src';
import { Tier } from '../types';
import { isNil } from 'lodash';

const TierCell = ({ value }: GridCellParams<any, Tier>) => {
  return <Text variant="body-regular">{isNil(value) ? '' : value.label}</Text>;
};

export default TierCell;
