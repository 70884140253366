import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Auth } from 'aws-amplify';
import { FormControl, Typography } from '@mui/material';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useLocation, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { FeedbackLabel, Form, FormLabel, SubmitButton } from '@vertice/components/src/Form';
import { EmailField } from '@vertice/core/src/components/EmailField';
import { SigninVerifyApiResponse, useSigninVerifyMutation } from '@vertice/slices';
import AuthContainer from '../../components/AuthContainer/AuthContainer';
import authStyles from '../../components/Auth.module.scss';
import RedirectButton from 'modules/auth/components/RedirectButton';

const ResetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signinVerification] = useSigninVerifyMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const defaultFormValues = {
    email: '',
  };

  const handleReset = async (form: typeof defaultFormValues) => {
    setIsSubmitting(true);

    try {
      const result = await signinVerification({ body: { email: form.email } });
      if ((result as { error: FetchBaseQueryError }).error) {
        enqueueSnackbar(t('AUTH.ERRORS.UNSPECIFIED_ERROR'), {
          key: 'reset-password-error',
          variant: 'error',
        });
      } else {
        const status = (result as { data: SigninVerifyApiResponse }).data.status;
        switch (status) {
          case 'PASSWORD_NOT_MANAGED':
            navigate(`${ROUTES.SSO_LOGIN}`, { state: { email: form.email, status }, replace: true });
            break;
          case 'PASSWORD_MANAGED':
            await Auth.forgotPassword(form.email);
            navigate(ROUTES.SUBMIT_RESET_PASSWORD, { state: { from, email: form.email }, replace: true });
            break;
          case 'MUST_ACCEPT_INVITATION':
            enqueueSnackbar(t('AUTH.ERRORS.MUST_ACCEPT_INVITATION'), {
              description: t('AUTH.ERRORS.CONTACT_ADMIN'),
              variant: 'error',
            });
            break;
          case 'NOT_FOUND':
            enqueueSnackbar(t('AUTH.ERRORS.USER_NOT_FOUND'), {
              variant: 'error',
            });
            break;
          default:
            enqueueSnackbar(t('AUTH.ERROR.HEADER'), {
              variant: 'error',
            });
            break;
        }
      }
    } catch (e) {
      if (e && typeof e === 'object' && !!Object.prototype.hasOwnProperty.call(e, 'message')) {
        const errorObject = e as { message: string };
        if (errorObject?.message) {
          setError(errorObject.message);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <AuthContainer>
      <Typography variant="heading-s">{t('RESET_PASSWORD.HEADER')}</Typography>
      <Form defaultValues={defaultFormValues} onSubmit={handleReset} className={authStyles.form}>
        <FormControl fullWidth variant="outlined">
          <FormLabel for="email">{t('LOGIN_SSO.EMAIL')}</FormLabel>
          <EmailField<typeof defaultFormValues>
            id="email"
            name="email"
            autoFocus
            required
            placeholder={t('LOGIN_SSO.EMAIL')}
          />
        </FormControl>
        <FeedbackLabel error={error} />
        <div className={authStyles.actions}>
          <SubmitButton isLoading={isSubmitting}>{t('RESET_PASSWORD.SEND_CODE')}</SubmitButton>
          <RedirectButton to={ROUTES.LOGIN}>{t('RESET_PASSWORD.GO_TO_LOGIN')}</RedirectButton>
        </div>
      </Form>
    </AuthContainer>
  );
};

export default ResetPassword;
