import { z } from 'zod';
import { TFunction } from 'i18next';

export const nonEmptyString = z.string().min(1);

export const email = (t: TFunction) => z.string().email({ message: t('FORMS.ERROR.INVALID_EMAIL') });

export const emailOrEmpty = (t: TFunction) => email(t).nullish().or(z.literal(''));

export const phoneNumber = (t: TFunction) =>
  z.string().refine((val) => /^\+[1-9][0-9]{0,24}$/g.test(val?.replace(/\s/g, '')), {
    message: t('FORMS.ERROR.INVALID_PHONE_NUMBER'),
  });

export const url = (t: TFunction) => z.string().url({ message: t('FORMS.ERROR.INVALID_URL') });
