import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { Divider, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAuthenticator } from '@aws-amplify/ui-react';
import FormControl from '@mui/material/FormControl';
import { FeedbackLabel, Form, FormLabel, PasswordInput, SubmitButton } from '@vertice/components/src/Form';
import { EmailField } from '@vertice/core/src/components/EmailField';
import { amplifyConfig } from '@vertice/dashboard/src/config';
import useAmplifyConfigs from '@vertice/dashboard/src/hooks/useAmplifyConfigs';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import AuthContainer from '../../components/AuthContainer/AuthContainer';
import { useHandleLogIn } from './utils';
import SSOLoginButton from './components/SSOLoginButton';
import RedirectButton from 'modules/auth/components/RedirectButton';
import authStyles from '../../components/Auth.module.scss';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { handleLogIn, error } = useHandleLogIn();
  const { changeConfigs } = useAmplifyConfigs(amplifyConfig);
  const { route } = useAuthenticator((context) => [context.route]);
  const [isLoading, setIsLoading] = useState(false);

  const defaultFormValues = {
    email: searchParams.get('userEmail') || '',
    password: '',
  };

  type LoginFormData = typeof defaultFormValues;

  const onSubmit = (form: LoginFormData) => {
    setIsLoading(true);
    changeConfigs(amplifyConfig);
    handleLogIn({
      email: form.email.trim(),
      password: form.password.trim(),
    }).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(location.state ?? {}, { replace: true });
    }

    if (location.state?.notificationKey) {
      enqueueSnackbar(t(location.state?.notificationKey), {
        variant: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, navigate, location.state]);

  return (
    <AuthContainer>
      <Form defaultValues={defaultFormValues} onSubmit={onSubmit} className={authStyles.form} autoComplete="on">
        <div className={authStyles['form-wrapper']}>
          <FormControl fullWidth variant="outlined">
            <FormLabel for="email">{t('LOGIN.EMAIL')}</FormLabel>
            <EmailField<LoginFormData> id="email" name="email" autoFocus placeholder={t('LOGIN.EMAIL')} />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <FormLabel for="password">{t('LOGIN.PASSWORD')}</FormLabel>
            <PasswordInput<LoginFormData> required id="password" placeholder={t('LOGIN.PASSWORD')} />
          </FormControl>
          <FeedbackLabel error={error} />
        </div>
        <div className={authStyles.actions}>
          <SubmitButton isLoading={isLoading}>
            <Typography variant="label-s">{t('LOGIN.LOGIN')}</Typography>
          </SubmitButton>
          <RedirectButton to={ROUTES.RESET_PASSWORD}>{t('LOGIN.FORGOT_PASSWORD')}</RedirectButton>
          <Divider className={clsx('uppercase', authStyles.divider)}>
            <Typography variant="caption">{t('LOGIN.OR')}</Typography>
          </Divider>
          <SSOLoginButton />
        </div>
      </Form>
    </AuthContainer>
  );
};

export default Login;
