import React, { FC } from 'react';
import ConcessionsListBase, { ConcessionsListProps as ConcessionsListBaseProps } from './ConcessionsList';
import WithActions from './WithActions';
import ConcessionsContextProvider from './ConcessionsContextProvider';
import { ConcessionItem } from './types';

interface ConcessionsListProps extends ConcessionsListBaseProps {
  withActions?: boolean;
  excludeDeleteAction?: boolean;
  allowSavingFor?: Array<string>;
  currency?: string;
  selectedConcessions: ConcessionItem[];
  setSelectedConcessions: (newProducts: ConcessionItem[]) => void;
}

export const ConcessionsList: FC<ConcessionsListProps> = ({
  withActions,
  excludeDeleteAction,
  allowSavingFor,
  currency,
  selectedConcessions,
  setSelectedConcessions,
  ...rest
}) => {
  const listBase = (
    <ConcessionsListBase
      selectedConcessions={selectedConcessions}
      setSelectedConcessions={setSelectedConcessions}
      {...rest}
    />
  );
  return (
    <ConcessionsContextProvider currency={currency}>
      {withActions ? (
        <WithActions {...{ excludeDeleteAction, allowSavingFor, selectedConcessions, setSelectedConcessions }}>
          {listBase}
        </WithActions>
      ) : (
        listBase
      )}
    </ConcessionsContextProvider>
  );
};
