import { PredefinedFormDef } from '../shared/types';
import { PurchaseIntakeSimpleForm } from './PurchaseIntakeSimpleForm';
import { zodSchema, FormData } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/purchase\/intake\/simple\/v\d+$/,
  component: PurchaseIntakeSimpleForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    vendor: getValue('vendor'),
    products: getValue('products', []),
    targetSignDate: getValue('targetSignDate'),
    additionalNotes: getValue('additionalNotes'),
  }),
};
