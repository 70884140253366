import { Button } from '@verticeone/design-system/src';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';
import { Divider } from '@verticeone/design-system/src';
import { Stack } from '@mui/material';

type DiscardChangesDialogProps = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
};

export const DiscardChangesDialog: React.FC<DiscardChangesDialogProps> = ({ open, onClose, onDiscard }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} size="M" width={480} noPadding>
      <DialogHeader>
        <Text variant="heading" size="M">
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_CHANGES_DIALOG.HEADER')}
        </Text>
      </DialogHeader>
      <Divider />
      <DialogContent>
        <Stack gap={2}>
          <Text variant="body-bold" size="M" color="text2">
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_CHANGES_DIALOG.DESCRIPTION_HEADER')}
          </Text>
          <Text variant="body-regular" size="M" color="text2">
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_CHANGES_DIALOG.DESCRIPTION')}
          </Text>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_CHANGES_DIALOG.CANCEL')}
        </Button>
        <Button onClick={onDiscard} color="secondary" variant="solid">
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_CHANGES_DIALOG.DISCARD')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
