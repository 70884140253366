import { PaletteOptions } from '@mui/material/styles/createPalette';
import { global } from './global';

export const lightPalette: PaletteOptions = {
  mode: 'light',
  global,
  core: {
    bg: global.color.white.main,
    color1: global.color.coolGray['10'].main,
    color2: global.color.coolGray['20'].main,
    color3: global.color.coolGray['30'].main,
    color4: global.color.coolGray['40'].main,
    color5: global.color.coolGray['50'].main,
    color6: global.color.coolGray['80'].main,
  },
  inactive: {
    main: global.color.coolGray['50'].main,
    color1: global.color.coolGray['60'].main,
    color2: global.color.coolGray['50'].main,
    color3: global.color.coolGray['30'].main,
    color4: global.color.coolGray['20'].main,
    hover: {
      color1: global.color.coolGray['60'].hover,
      color2: global.color.coolGray['50'].hover,
      color3: global.color.coolGray['30'].hover,
      color4: global.color.coolGray['20'].hover,
    },
  },
  input: {
    bg: global.color.white.main,
    color1: global.color.coolGray['10'].main,
    color2: global.color.coolGray['20'].main,
  },
  text: {
    color1: global.color.coolGray['100'].main,
    color2: global.color.coolGray['70'].main,
    color3: global.color.coolGray['50'].main,
    color4: global.color.coolGray['40'].main,
    color5: global.color.white.main,
  },
  primary: {
    main: global.color.purple['70'].main,
    color1: global.color.purple['80'].main,
    color2: global.color.purple['70'].main,
    color3: global.color.purple['30'].main,
    color4: global.color.purple['20'].main,
    hover: {
      color1: global.color.purple['80'].hover,
      color2: global.color.purple['70'].hover,
      color3: global.color.purple['30'].hover,
      color4: global.color.purple['20'].hover,
    },
  },
  secondary: {
    main: global.color.blue['60'].main,
    color1: global.color.blue['70'].main,
    color2: global.color.blue['60'].main,
    color3: global.color.blue['30'].main,
    color4: global.color.blue['20'].main,
    hover: {
      color1: global.color.blue['70'].hover,
      color2: global.color.blue['60'].hover,
      color3: global.color.blue['30'].hover,
      color4: global.color.blue['20'].hover,
    },
  },
  tertiary: {
    main: global.color.magenta['60'].main,
    color1: global.color.magenta['70'].main,
    color2: global.color.magenta['60'].main,
    color3: global.color.magenta['30'].main,
    color4: global.color.magenta['20'].main,
    hover: {
      color1: global.color.magenta['70'].hover,
      color2: global.color.magenta['60'].hover,
      color3: global.color.magenta['30'].hover,
      color4: global.color.magenta['20'].hover,
    },
  },
  neutral: {
    main: global.color.coolGray['70'].main,
    contrastText: global.color.white.main,

    color1: global.color.coolGray['80'].main,
    color2: global.color.coolGray['70'].main,
    color3: global.color.coolGray['30'].main,
    color4: global.color.coolGray['20'].main,

    hover: {
      color1: global.color.coolGray['80'].hover,
      color2: global.color.coolGray['70'].hover,
      color3: global.color.coolGray['30'].hover,
      color4: global.color.coolGray['20'].hover,
    },
  },
  error: {
    main: global.color.red['70'].main,
    color1: global.color.red['70'].main,
    color2: global.color.red['60'].main,
    color3: global.color.red['30'].main,
    color4: global.color.red['20'].main,
    hover: {
      color1: global.color.red['70'].hover,
      color2: global.color.red['60'].hover,
      color3: global.color.red['30'].hover,
      color4: global.color.red['20'].hover,
    },
  },
  warning: {
    main: global.color.orange['70'].main,
    color1: global.color.orange['70'].main,
    color2: global.color.orange['60'].main,
    color3: global.color.orange['30'].main,
    color4: global.color.orange['20'].main,
    hover: {
      color1: global.color.orange['70'].hover,
      color2: global.color.orange['60'].hover,
      color3: global.color.orange['30'].hover,
      color4: global.color.orange['20'].hover,
    },
  },
  success: {
    main: global.color.green['70'].main,
    color1: global.color.green['70'].main,
    color2: global.color.green['60'].main,
    color3: global.color.green['30'].main,
    color4: global.color.green['20'].main,
    hover: {
      color1: global.color.green['70'].hover,
      color2: global.color.green['60'].hover,
      color3: global.color.green['30'].hover,
      color4: global.color.green['20'].hover,
    },
  },
  info: {
    main: global.color.blue['70'].main,
    color1: global.color.blue['70'].main,
    color2: global.color.blue['60'].main,
    color3: global.color.blue['30'].main,
    color4: global.color.blue['20'].main,
    hover: {
      color1: global.color.blue['70'].hover,
      color2: global.color.blue['60'].hover,
      color3: global.color.blue['30'].hover,
      color4: global.color.blue['20'].hover,
    },
  },
  gradient: global.gradient.light,
  transparent: {
    main: global.transparency.coolGray['40'],
    color1: global.transparency.coolGray['50'],
    color2: global.transparency.coolGray['40'],
    color3: global.transparency.coolGray['30'],
    color4: global.transparency.coolGray['20'],
    hover: {
      color1: global.transparency.coolGray['45'],
      color2: global.transparency.coolGray['35'],
      color3: global.transparency.coolGray['25'],
      color4: global.transparency.coolGray['15'],
    },
  },
  visualization: {
    sequential: {
      primary: {
        10: '#9e7ffa',
        20: '#a881e4',
        30: '#b283cf',
        40: '#bd85ba',
        50: '#c787a5',
        60: '#d18990',
        70: '#dc8b7b',
        80: '#e68d66',
        90: '#f08f51',
        100: '#fb923c',
      },
      secondary: {
        10: '#80acfa',
        20: '#8ca5fa',
        30: '#999efb',
        40: '#a698fb',
        50: '#b391fc',
        60: '#bf8afc',
        70: '#cc84fd',
        80: '#d97dfd',
        90: '#e676fe',
        100: '#f370ff',
      },
      tertiary: {
        10: '#5dd288',
        20: '#6ec785',
        30: '#7fbc83',
        40: '#90b280',
        50: '#a1a77e',
        60: '#b39c7b',
        70: '#c49279',
        80: '#d58776',
        90: '#e67c74',
        100: '#f87272',
      },
    },
    divergent: {
      primary: {
        '-50': '#8b42ff',
        '-40': '#b487ff',
        '-30': '#cdb1ff',
        '-20': '#dccaff',
        '-10': '#e5d9ff',
        '+10': '#fcdecd',
        '+20': '#fad1ba',
        '+30': '#f8b986',
        '+40': '#f29665',
        '+50': '#e9590c',
      },
      secondary: {
        '-50': '#0057fa',
        '-40': '#6096fc',
        '-30': '#99bcfd',
        '-20': '#bcd3fe',
        '-10': '#d1e1fe',
        '+10': '#fad6d8',
        '+20': '#f7c5c7',
        '+30': '#f2a8aa',
        '+40': '#ea7879',
        '+50': '#dc2828',
      },
      tertiary: {
        '-50': '#e52ec7',
        '-40': '#ee7bdd',
        '-30': '#f4aaeb',
        '-20': '#f7c6f3',
        '-10': '#f9d7f8',
        '+10': '#ccf1d9',
        '+20': '#bae9cb',
        '+30': '#9cdcb3',
        '+40': '#6ac68b',
        '+50': '#16a249',
      },
    },
    monochromatic: {
      primary: {
        10: global.color.blue[100].main,
        20: global.color.blue[90].main,
        30: global.color.blue[80].main,
        40: global.color.blue[70].main,
        50: global.color.blue[60].main,
        60: global.color.blue[50].main,
        70: global.color.blue[40].main,
        80: global.color.blue[30].main,
        90: global.color.blue[20].main,
        100: global.color.blue[10].main,
      },
      secondary: {
        10: global.color.purple[100].main,
        20: global.color.purple[90].main,
        30: global.color.purple[80].main,
        40: global.color.purple[70].main,
        50: global.color.purple[60].main,
        60: global.color.purple[50].main,
        70: global.color.purple[40].main,
        80: global.color.purple[30].main,
        90: global.color.purple[20].main,
        100: global.color.purple[10].main,
      },
      tertiary: {
        10: global.color.magenta[100].main,
        20: global.color.magenta[90].main,
        30: global.color.magenta[80].main,
        40: global.color.magenta[70].main,
        50: global.color.magenta[60].main,
        60: global.color.magenta[50].main,
        70: global.color.magenta[40].main,
        80: global.color.magenta[30].main,
        90: global.color.magenta[20].main,
        100: global.color.magenta[10].main,
      },
      error: {
        10: global.color.red[100].main,
        20: global.color.red[90].main,
        30: global.color.red[80].main,
        40: global.color.red[70].main,
        50: global.color.red[60].main,
        60: global.color.red[50].main,
        70: global.color.red[40].main,
        80: global.color.red[30].main,
        90: global.color.red[20].main,
        100: global.color.red[10].main,
      },
      warning: {
        10: global.color.orange[100].main,
        20: global.color.orange[90].main,
        30: global.color.orange[80].main,
        40: global.color.orange[70].main,
        50: global.color.orange[60].main,
        60: global.color.orange[50].main,
        70: global.color.orange[40].main,
        80: global.color.orange[30].main,
        90: global.color.orange[20].main,
        100: global.color.orange[10].main,
      },
      success: {
        10: global.color.green[100].main,
        20: global.color.green[90].main,
        30: global.color.green[80].main,
        40: global.color.green[70].main,
        50: global.color.green[60].main,
        60: global.color.green[50].main,
        70: global.color.green[40].main,
        80: global.color.green[30].main,
        90: global.color.green[20].main,
        100: global.color.green[10].main,
      },
    },
  },
};
