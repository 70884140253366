import { CardGridColDef, ValidRowModel } from './types';
import { Stack } from '@mui/material';
import { CardGridHeaderColumn } from './CardHeaderColumn';
import { useCardGridState } from './CardGridStateContext';

type CardGridHeaderProps<R extends ValidRowModel> = {
  columns: CardGridColDef<R>[];
};

export const CardGridHeader = <R extends ValidRowModel>({ columns }: CardGridHeaderProps<R>) => {
  const { sortModel, setSortModel } = useCardGridState<R>();

  return (
    <Stack direction="row" alignContent="stretch" width="100%" role="row" /* <- for testing & a11y */>
      {columns.map((column) => (
        <CardGridHeaderColumn key={column.field} sortModel={sortModel} column={column} onSortChange={setSortModel} />
      ))}
    </Stack>
  );
};
