import { Amplify } from 'aws-amplify';
import useLocalStorage from 'use-local-storage';
import { useCallback } from 'react';

const LOCAL_STORAGE_NAME = 'amplifyConfig';

export type OAuthResponseTypeType = 'token' | 'code';

interface oauthInterface {
  domain: string;
  redirectSignIn: string;
  redirectSignOut: string;
  scope: string[];
  responseType: OAuthResponseTypeType;
}

interface AuthInterface {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oauth: oauthInterface;
}

interface AmplifyConfig {
  aws_cognito_region?: string;
  aws_user_pools_id?: string;
  aws_user_pools_web_client_id?: string;
  Auth?: AuthInterface;
}

/**
 * Allows to apply & change Amplify configuration + preserve the changed config in local storage.
 *
 * This is useful for temporarily enabling SSO config instead of the standard (email+password) one.
 */
const useAmplifyConfigs = (defaultConfigs: AmplifyConfig) => {
  const [configs, saveConfigs] = useLocalStorage<AmplifyConfig>(LOCAL_STORAGE_NAME, defaultConfigs);

  const changeConfigs = useCallback(
    (conf: AmplifyConfig) => {
      saveConfigs(conf);
      Amplify.configure(conf);
    },
    [saveConfigs]
  );

  const configure = useCallback(() => {
    Amplify.configure(configs);
  }, [configs]);

  const clear = useCallback(() => {
    saveConfigs(undefined);
  }, [saveConfigs]);

  return { changeConfigs, configure, clear };
};

export default useAmplifyConfigs;
