import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system/src';
import { DesignSystemSize } from '@verticeone/design-system/src';
import PricingDataRequestDialog from './PricingDataRequestDialog';
import { useRequestBenchmarkingData } from '../../hooks/useRequestBenchmarkingData';

export type TriggerBenchmarkingRequestButtonProps = {
  vendorId?: string;
  productId?: string;
  buttonSize?: DesignSystemSize;
};

export const RequestBenchmarkingDataButton = ({
  vendorId,
  productId,
  buttonSize = 'S',
}: TriggerBenchmarkingRequestButtonProps) => {
  const { t } = useTranslation();
  const { triggerRequest, isRequestInProgress } = useRequestBenchmarkingData({ vendorId, productId });
  const [openedDialog, setOpenedDialog] = useState<boolean>(false);

  const openDialog = () => {
    setOpenedDialog(true);
  };
  const closeDialog = () => {
    setOpenedDialog(false);
  };

  const onSuccess = async (note: string) => {
    await triggerRequest(note);
    closeDialog();
  };

  return (
    <div>
      <Button size={buttonSize} variant={productId ? 'solid' : 'ghost'} color="primary" onClick={openDialog}>
        {t('VENDOR.PRODUCTS.REQUEST_BENCHMARKING_BUTTON')}
      </Button>
      <PricingDataRequestDialog
        open={openedDialog}
        onClose={closeDialog}
        onSuccess={onSuccess}
        disabled={isRequestInProgress}
      />
    </div>
  );
};
