import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { API_URLS } from '@vertice/slices';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { PREFERENCES_PAGES } from '@vertice/dashboard/src/router/oldConstants';
import { SCIMEntraSetup } from './SCIMEntraSetup';
import { SCIMOktaSetup } from './SCIMOktaSetup';
import { SCIMSetupType } from './common';

type SCIMSetupWizardProps = {
  type: SCIMSetupType;
};

export const SCIMSetupWizard = ({ type }: SCIMSetupWizardProps) => {
  const { accountId } = useAccountContext();
  const { SCIM } = API_URLS;
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const handleCloseSetup = () => navigate(routes.PREFERENCES.TAB, { activeTab: PREFERENCES_PAGES.INTEGRATIONS });

  const accountSCIMEndpointURL = `${SCIM}/accounts/${accountId}/scim`;

  if (type === SCIMSetupType.ENTRA) {
    return (
      <SCIMEntraSetup
        accountId={accountId}
        accountSCIMEndpointURL={accountSCIMEndpointURL}
        handleCloseSetup={handleCloseSetup}
      />
    );
  }

  return (
    <SCIMOktaSetup
      accountId={accountId}
      accountSCIMEndpointURL={accountSCIMEndpointURL}
      handleCloseSetup={handleCloseSetup}
    />
  );
};
