import { useCallback } from 'react';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import type { SeriesArearangeOptions } from 'highcharts';

export type getMissedSavings = {
  target: number;
  data: Array<[number, number | undefined]>;
};

export const MISSED_SAVINGS_ID = 'missed_savings';

const useMissedSavings = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  return useCallback(
    ({ data, target }: getMissedSavings): SeriesArearangeOptions => {
      return {
        id: MISSED_SAVINGS_ID,
        name: t('CLOUD.RESERVED_INSTANCES.MISSED_SAVINGS'),
        type: 'arearange',
        data: data?.map((v) => [...v, target]),
        color: rgba(palette.error.color1, 0.2),
        lineWidth: 0,
        fillOpacity: 0.2,
        zIndex: 1,
        zones: [
          { value: target, color: rgba(palette.error.color1, 0.2) },
          { color: rgba(palette.success.color2, 0.2) },
        ],
        enableMouseTracking: false,
      };
    },
    [palette, t]
  );
};

export default useMissedSavings;
