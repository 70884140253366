import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { TaskRow } from '../types';
import { CardGridColDef } from '../TaskList/CardGrid/types';
import { TaskName } from '../TaskList/taskListColumns/TaskName';
import { TaskAssignees } from '../TaskList/taskListColumns/TaskAssignees';
import { TaskCreatedAt } from '../TaskList/taskListColumns/TaskCreatedAt';
import { TaskClosedAt } from '../TaskList/taskListColumns/TaskClosedAt';
import { TaskRequestName } from '../TaskList/taskListColumns/TaskRequestName';

type UseTaskColumnsProps = {
  showCompleted: boolean;
};

export const useTaskColumns = ({ showCompleted }: UseTaskColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const stageSpecificColumns: CardGridColDef<TaskRow>[] = showCompleted
      ? [
          {
            field: 'closedAt',
            headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.CLOSED_AT_COLUMN'),
            sortingOrder: ['desc', 'asc'],
            renderCell: ({ row }) => <TaskClosedAt taskRow={row} />,
            flex: 1,
          },
        ]
      : [
          {
            field: 'createdAt',
            headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.DAYS_ACTIVE_COLUMN'),
            renderCell: ({ row }) => <TaskCreatedAt taskRow={row} />,
            flex: 1,
          },
        ];

    const columns: CardGridColDef<TaskRow>[] = [
      {
        field: 'name',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_NAME_COLUMN'),
        flex: 1.5,
        renderCell: ({ row }) => <TaskName taskRow={row} />,
        renderHeader: () => <Stack paddingLeft={12}>{t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_NAME_COLUMN')}</Stack>,
      },
      {
        field: 'assignees',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.ASSIGNEE_COLUMN'),
        renderCell: ({ row }) => <TaskAssignees taskRow={row} />,
        sortable: false,
        flex: 1,
      },
      ...stageSpecificColumns,
      {
        field: 'requestName',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.REQUEST_COLUMN'),
        flex: 1,
        renderCell: ({ row }) => <TaskRequestName taskRow={row} />,
      },
    ];

    return columns;
  }, [showCompleted, t]);
};
