import React from 'react';
import { Card } from '@verticeone/design-system/src';
import { useInstanceData } from './providers/InstanceProvider';

type InstanceProps = {
  children: React.ReactNode;
};

const Instance = ({ children }: InstanceProps) => {
  const { item } = useInstanceData();

  return (
    <Card key={item.id} data-testid={item.id}>
      {children}
    </Card>
  );
};

export default Instance;
