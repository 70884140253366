import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const SERVICES_TAG_PREFIX = 'Services';

export const servicesAPI = createApi({
  reducerPath: 'servicesAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.SERVICES),
  refetchOnMountOrArgChange: true,
  tagTypes: [SERVICES_TAG_PREFIX],
  endpoints: () => ({}),
});
