import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';

type TooltipValueWithTimeInfoProps = {
  value: string;
  valueSuffix?: string;
  timeInfo?: string;
};

const TooltipValueWithTimeInfo = ({ value, valueSuffix, timeInfo }: TooltipValueWithTimeInfoProps) => {
  const { palette } = useTheme();
  return (
    <Stack direction="column" gap="2px" alignItems="center">
      <Typography variant="body-bold-s">
        {value}
        {valueSuffix && (
          <Box component="span" color={palette.text.color3}>
            {' '}
            {valueSuffix}
          </Box>
        )}
      </Typography>
      {timeInfo && (
        <Typography variant="body-regular-s" color={palette.text.color4}>
          {timeInfo}
        </Typography>
      )}
    </Stack>
  );
};

export default TooltipValueWithTimeInfo;
