import {
  AccessVia,
  ContractContextDataOptionalFetched,
  ContractContextDataRequireFetched,
  ContractFormData,
  FetchedDataTypeFromMode,
  FieldsConfig,
  FormSupportedMode,
} from '../types';
import { DeepPartial } from 'react-hook-form';
import useContractHookForm from './useContractHookForm';
import useContractEditMode from './useContractEditMode';
import useContractSaving from './useContractSaving';
import useContractAutoResetOnFetchedContractChange from './useContractAutoResetOnFetchedContractChange';
import useContractFieldsRules from './useContractFieldsRules';
import { useContractValidateFilledFieldsOnSwitchToEditMode } from './useContractValidateFilledFieldsOnSwitchToEditMode';

export type UseContractOptions = {
  /**
   * Form pre-fill values.
   *
   * In case of REQUIRE_FETCHED mode, these overrides are applied locally over the existing fetched contract.
   * In case of DONT_REQUIRE_FETCHED, these overrides are applied over empty form.
   */
  defaultFormDataOverrides?: DeepPartial<ContractFormData>;

  /** If enabled, the form goes directly to the edit mode and the edit mode cannot be cancelled. */
  onlyEditMode?: boolean;

  /**
   * If enabled, sends to server even restricted (typically internal) fields.
   * @default false
   */
  allowRestrictedFields?: boolean;
};

/**
 * Prepares ContractContextData that is then typically passed through context.
 *
 * See {@link ContractContextData} for more info on what is this data.
 *
 * DEV NOTES: It can contain some computed variables/methods that require to be placed on this global level. Use sparingly though,
 * prefer putting computed stuff into small specialized hooks or to {@link ./ContractContext.tsx}.
 */

const defaultFieldsConfig = { isVisibleAsDefault: true, isWritableAsDefault: true, getRules: () => ({}) };

const useContract = <Mode extends FormSupportedMode>(
  mode: Mode,
  accessVia: AccessVia,
  fetchedContract: FetchedDataTypeFromMode<Mode>,
  fieldsConfig: FieldsConfig<Mode> = defaultFieldsConfig,
  { defaultFormDataOverrides, onlyEditMode = false, allowRestrictedFields = false }: UseContractOptions = {}
): 'REQUIRE_FETCHED' extends Mode ? ContractContextDataRequireFetched : ContractContextDataOptionalFetched => {
  /*
   * HOW WE ORGANIZE THINGS IN HERE
   * We split the logic into sub-hooks by features. One takes charge of saving, one of edit mode, etc.
   *
   * - Every hook should take one object of options. These are easier to read than a long list of positional arguments.
   * - Dependencies between hooks are visualized by passing the whole hooks return objects.
   *   Meaning, if a hook A needs data from hook B, it should typically receive the whole return value of B.
   *   (we don't want to mess with tiny variables here).
   *
   * TESTING: There's a dumb version of this hook for testing purposes: `useFakeContract`.
   */

  const hookForm = useContractHookForm({ fetchedContract, fieldsConfig, defaultFormDataOverrides });
  const editMode = useContractEditMode({ hookForm, onlyEditMode });
  const saving = useContractSaving({
    accessVia,
    fetchedContract,
    allowRestrictedFields,
    onlyEditMode,
    hookForm,
    editMode,
  });
  useContractAutoResetOnFetchedContractChange({ hookForm, fetchedContract });
  useContractValidateFilledFieldsOnSwitchToEditMode({ hookForm, editMode });

  const fieldsRules = useContractFieldsRules({ hookForm, fetchedContract, fieldsConfig, editMode });

  // Reset form on locator change

  return {
    ...(fetchedContract && mode === 'REQUIRE_FETCHED'
      ? {
          formSupportedMode: 'REQUIRE_FETCHED',
          fetchedContract,
        }
      : { formSupportedMode: 'DONT_REQUIRE_FETCHED' }),
    accessVia,
    hookForm,
    fieldsRules,
    saving,
    editMode,
    // We don't want `as unknown as ...` here, because that would disable type-checks.
  } as ContractContextDataOptionalFetched as 'REQUIRE_FETCHED' extends Mode
    ? ContractContextDataRequireFetched
    : ContractContextDataOptionalFetched;
};

export default useContract;
