import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import {
  Button,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderTitle,
  Text,
} from '@verticeone/design-system/src';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { JiraSetupStep, StepContentProps } from '../types';
import { ProjectForm, ProjectFormData } from './ProjectForm';

type ProjectCardProps = StepContentProps & {
  isConnectionEditMode: boolean;
};

export const ProjectCard = ({ activeStep, setStep, isConnectionEditMode }: ProjectCardProps) => {
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);

  const handleProjectSubmit: GoodFormOnSubmitCallback<ProjectFormData> = (formData) => {
    if (isEditMode) {
      setIsEditMode(false);
    }
    setStep(JiraSetupStep.SUCCESS);

    return new Promise((resolve) => {
      resolve();
    });
  };

  const isConfigurationStep = activeStep === JiraSetupStep.CONFIGURATION;
  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle icon={DescriptionOutlinedIcon} text={t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_CARD.TITLE')} />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_CARD.DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
        {isSuccessStep && (
          <CardHeaderActions>
            <Button variant="outline" size="S" color="primary" onClick={() => setIsEditMode(true)}>
              {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_CARD.ACTION_BUTTON')}
            </Button>
          </CardHeaderActions>
        )}
      </CardHeader>
      <Stack gap={1} p={6}>
        <ProjectForm
          defaultValues={{ jiraProject: '' }}
          onSubmit={handleProjectSubmit}
          activeStep={activeStep}
          isDisabled={(!isConfigurationStep && !isEditMode) || isConnectionEditMode}
        />
      </Stack>
    </Card>
  );
};
