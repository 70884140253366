import React from 'react';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { FormControl, Stack } from '@mui/material';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { useTranslation } from 'react-i18next';

export const PurchasingManagerPermissions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={3}>
      <TextFieldCaption label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.LABEL')} size="XS" />
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="contactVendorDirectly"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.CONTACT_VENDOR_DIRECTLY_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.CONTACT_VENDOR_DIRECTLY_SUBTITLE')}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussMultiYearDeals"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_MULTI_YEAR_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_MULTI_YEAR_SUBTITLE')}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussPlannedGrowth"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_PANNED_GROWTH_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_PANNED_GROWTH_SUBTITLE')}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussCompetitors"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_COMPETITORS_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_COMPETITORS_SUBTITLE')}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussCaseStudies"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_CASE_STUDIES_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_CASE_STUDIES_SUBTITLE')}
        />
      </FormControl>
    </Stack>
  );
};
