import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Divider } from '@vertice/core/src/components/Dialog/Components';

type HeadingProps = {
  title: string;
  titleDimmedPart?: string;
  subtitle?: string;
  centered?: boolean;
};

export const Header: React.FC<HeadingProps> = ({ title, titleDimmedPart, subtitle, centered }) => (
  <>
    <Stack padding={8} gap={4} textAlign={centered ? 'center' : undefined}>
      <Text variant="heading" size="M" tag="div" color="text1">
        {title}
        {titleDimmedPart !== undefined && (
          <Text variant="heading" size="M" tag="span" color={'text3'}>
            &nbsp;{titleDimmedPart}
          </Text>
        )}
      </Text>

      {subtitle !== undefined && (
        <Text color="text2" variant="body-regular" tag="div" size="S">
          {subtitle}
        </Text>
      )}
    </Stack>
    <Divider />
  </>
);
