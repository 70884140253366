import { useTranslation } from 'react-i18next';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import { Filter } from '../types';
import useUnifiedStageOptions from '../../../../hooks/useUnifiedStageOptions';

const StageFilter = () => {
  const { t } = useTranslation();
  const options = useUnifiedStageOptions();

  return (
    <GenericMultiselectFilter
      name={Filter.STAGE}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.STAGE')}
      testId={'stage-filter'}
    />
  );
};

export default StageFilter;
