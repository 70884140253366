import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import { keyBy, sumBy } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { ChartPortal, fromChartPortal } from '@vertice/core/src/components/charts/components/ChartPortal';
import { Stack } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { Text } from '@verticeone/design-system/src';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useTranslation } from 'react-i18next';
import {
  AWS_DEFAULT_CURRENCY,
  AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
} from '@vertice/dashboard/src/modules/cloud/constants';
import { EBSStorageData } from './types';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { toConstantCase } from '@verticeone/utils/strings';
import { TFunction } from 'i18next';

const graphId = 'ebsStorageUsageGraph';

const TRANSLATION_PREFIX = 'CLOUD.EBS_STORAGE_DISTRIBUTION';

type CustomPoint = Highcharts.Point & {
  states: {
    hover: {
      color: string;
    };
  };
};

const extractLegendItems = (chart: HighchartsChart, t: TFunction<'en', typeof TRANSLATION_PREFIX>) =>
  (chart.series[0].points as CustomPoint[]).map(({ name, states, color }) => ({
    id: name,
    color: ((states?.hover?.color ?? color) as string) ?? 'transparent',
    label: t(`GENERATIONS.${toConstantCase(name)}`),
  }));

type Props = {
  data: EBSStorageData;
};

const EBSProvisionedStorageDistributionGraph = ({ data }: Props) => {
  const [, rerender] = useState({});
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredSeries = useGetColoredItems();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation(undefined, { keyPrefix: TRANSLATION_PREFIX });

  const totalPositive = useMemo(() => sumBy(data, ({ cost }) => cost), [data]);
  const rowsByGeneration = useMemo(() => keyBy(data, ({ generation }) => generation), [data]);
  const points = useMemo(
    () =>
      data
        ?.map(({ generation, cost }) => ({
          name: generation,
          y: cost,
        }))
        .sort((a, b) => a.y - b.y),
    [data]
  );

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      mergeOptions({
        chart: {
          type: 'pie',
          plotBackgroundColor: 'transparent',
          backgroundColor: 'transparent',
          plotBorderWidth: 0,
          height: 366,
          spacing: [24, 0, 24, 0],
        },
        plotOptions: {
          pie: { innerSize: '80%' },
          series: {
            dataLabels: {
              formatter: function () {
                return fromChartPortal({ chart, portalKey: `${(this as any).key}-${graphId}` });
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            data: getColoredSeries(points) ?? [],
            dataLabels: {
              enabled: true,
              crop: false,
              y: -10,
              useHTML: true,
              connectorPadding: 5,
              distance: 40,
              padding: 5,
              style: {
                textAlign: 'center',
              },
            },
          },
        ],
      }),
    ]);
  }, [applyStyledHighcharts, getColoredSeries, points, chart]);

  useLayoutEffect(() => {
    // Wait for initial Highchart render (possibly with empty div portal targets)
    if (chart) {
      // Force React render portals into Highchart
      rerender({});
      // Wait for React render to finish
      requestAnimationFrame(() => {
        // Position the React-rendered elements
        chart.series.forEach((ser) => (ser as any).redraw());
      });
    }
  }, [chart, options]);

  return (
    <Stack>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <Legend items={chart ? extractLegendItems(chart, t) : []} />
      {totalPositive
        ? data?.map((row) => (
            <ChartPortal chart={chart} portalKey={`${row.generation}-${graphId}`} key={`${row.generation}-${graphId}`}>
              <Stack direction="column" alignItems="center">
                <Text variant="body-bold" size="S" color="text2">
                  {t(`GENERATIONS.${toConstantCase(row.generation)}`)}
                </Text>
                <Text variant="heading" size="XS" color="text1">
                  {Math.round((row.cost / totalPositive) * 100)}%
                </Text>
              </Stack>
            </ChartPortal>
          ))
        : null}
      <HighchartTooltip chart={chart}>
        {({ point }) => {
          const row = rowsByGeneration[point.name];

          return (
            <TooltipWrapper>
              <TooltipSeriesValuePair
                seriesName={t('TOOLTIP.COST')}
                value={formatCurrency(row.cost, {
                  currency: AWS_DEFAULT_CURRENCY,
                  maximumFractionDigits: AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
                })}
              />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default EBSProvisionedStorageDistributionGraph;
