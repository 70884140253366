// Helper text
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { CSSObject, Theme } from '@mui/material/styles';
import { DateFieldProps as MuiDateFieldProps } from '@mui/x-date-pickers-pro';
import { mapValues } from 'lodash';
import { DesignSystemSize } from '../../../../../types';
import { getTextVariantStyle } from '../../../../Text/utils';
import { DateFieldProps as ParentDateFieldProps } from '../DateField';
import sizeDefinitions, { SizeDef } from './sizeDefinitions';
import { Dayjs } from 'dayjs';

type BaseDateFieldProps = Omit<MuiDateFieldProps<Dayjs, boolean>, 'size' | 'color' | 'ref'>;

export type DateFieldProps = BaseDateFieldProps & {
  $internalValue: unknown;
  color: NonNullable<ParentDateFieldProps['color']>;
  size: NonNullable<ParentDateFieldProps['size']>;
};

export type StyledDateFieldProps = BaseDateFieldProps & {
  theme: Theme;
  $color: DateFieldProps['color'];
  $size: DateFieldProps['size'];
  $internalValue: DateFieldProps['$internalValue'];
};

export const helperTextStyles = ({ theme: { palette }, $size }: StyledDateFieldProps) => {
  const textVariantStyle = getTextVariantStyle({
    variant: 'body-regular',
    size: sizeDefinitions[$size].helperTextSize,
  });
  const { fontSize } = textVariantStyle;
  return {
    [`.${formHelperTextClasses.root}.${formHelperTextClasses.error}`]: {
      color: palette.error.color1,
    },
    [`.${formHelperTextClasses.root}`]: {
      position: 'absolute',
      top: '100%',
      marginTop: sizeDefinitions[$size].helperTextGap,
      marginLeft: sizeDefinitions[$size].paddingX,
      marginRight: sizeDefinitions[$size].paddingX,
      ...textVariantStyle,
      // need to use important so the override from muiOverrides.scss is not applied
      fontSize: `${fontSize} !important`,
      color: palette.text.color3,
    },
  };
};

export const inputBaseAdornedStyles = ({
  size,
  noPaddings = false,
}: {
  size: DesignSystemSize;
  noPaddings?: boolean;
}) => ({
  ...(noPaddings
    ? {}
    : {
        '.MuiInputBase-root': {
          '&.MuiInputBase-adornedStart': { paddingLeft: sizeDefinitions[size].paddingX },
          '&.MuiInputBase-adornedEnd': { paddingRight: sizeDefinitions[size].paddingX },
        },
      }),
  '.MuiInputBase-input': {
    '&.MuiInputBase-inputAdornedStart': { paddingLeft: 0 },
    '&.MuiInputBase-inputAdornedEnd': { paddingRight: 0 },
  },
});

export const spinButtonStyles: CSSObject = {
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export const makePadding = ({ paddingY, paddingX, labelLineHeight, gapY }: SizeDef, showLabel: boolean) => ({
  paddingTop: showLabel ? paddingY + labelLineHeight + gapY : paddingY,
  paddingRight: paddingX,
  paddingBottom: paddingY,
  paddingLeft: paddingX,
});

/** Subtract value from all paddings. */
export const subtractPadding = (
  cssProps: {
    paddingTop?: number;
    paddingRight?: number;
    paddingBottom?: number;
    paddingLeft?: number;
  },
  value: number
) =>
  mapValues(cssProps, (propValue, key) =>
    ['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'].includes(key) && typeof propValue !== 'undefined'
      ? propValue - value
      : value
  );
