import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { TextFieldCaption, Text } from '@verticeone/design-system/src';

import FormSelectField2 from '../../../../../forms/fields/FormSelectField2';
import FormTextField from '../../../../../forms/fields/FormTextField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { useGatewayTypeOptions, Option } from './gatewayTypeOptions';
import { EditGatewayFormData } from './schema';

type EditGatewayFormProps = {
  onDirty: VoidFunction;
};

const GATEWAY_TYPE_SELECT_ID = 'gateway-type-select';
const EDGE_NAME_TEXT_FIELD_ID = 'edge-name-text-field';

export const EditGatewayForm: FC<EditGatewayFormProps> = ({ onDirty }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY' });

  const { formState } = useFormContext<EditGatewayFormData>();

  const { fields } = useFieldArray<EditGatewayFormData, 'edges'>({
    name: 'edges',
  });

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const gatewayTypeOptions = useGatewayTypeOptions();

  return (
    <Stack gap={5} direction="column" width="100%">
      <Stack gap={1}>
        <TextFieldCaption label={t('TYPE')} size="XS" htmlFor={GATEWAY_TYPE_SELECT_ID} />
        <FormSelectField2<EditGatewayFormData, Option, false>
          id={GATEWAY_TYPE_SELECT_ID}
          unboxValue={(selectValue) => selectValue?.value}
          boxValue={(value) => gatewayTypeOptions.find((option) => option.value === value)}
          name="gatewayType"
          variant="outlined"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          options={gatewayTypeOptions}
          isClearable={false}
          size="S"
          isDisabled
        />
      </Stack>
      <Stack direction="column" gap={4}>
        <Text variant="heading" size="XS" color="text1">
          {t('EDGES.TITLE')}
        </Text>
        {fields.map((field, index) => (
          <Stack key={field.id}>
            <TextFieldCaption
              label={t('EDGES.LABEL', { index: index + 1 })}
              size="XS"
              htmlFor={`${EDGE_NAME_TEXT_FIELD_ID}-${index}`}
            />
            <FormTextField<EditGatewayFormData>
              id={`${EDGE_NAME_TEXT_FIELD_ID}-${index}`}
              name={`edges.${index}.name`}
              size="S"
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              placeholder={t('EDGES.PLACEHOLDER')}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
