import React from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import PasswordInput from './PasswordInput';
import type { PasswordInputProps } from './PasswordInput';
import { FieldValues } from 'react-hook-form/dist/types/fields';

type ConfirmPasswordInputProps<FormDataType extends FieldValues> = PasswordInputProps<FormDataType> & {
  watchFor: FieldPath<FormDataType>;
  onValidationResult?: (result: boolean) => void;
  error?: boolean;
};

const ConfirmPasswordInput = <FormDataType extends FieldValues = never>({
  id,
  watchFor,
  onValidationResult,
  required,
  validate,
  error,
  ref: _, // we don't want this ref in otherProps
  ...otherProps
}: ConfirmPasswordInputProps<FormDataType>) => {
  const { watch } = useFormContext<FormDataType>();

  return (
    <PasswordInput<FormDataType>
      id={id}
      required={required}
      error={error}
      validate={{
        ...validate,
        isSame: (value) => {
          // TODO: Fix type as soon as we implement field type checking
          const same = value === (watch(watchFor) as unknown as string);
          onValidationResult?.(same);
          return same;
        },
      }}
      {...otherProps}
    />
  );
};

export default ConfirmPasswordInput;
