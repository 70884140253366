import React from 'react';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { isNil } from 'lodash';
import ViewSettingsFormEntry from '../ViewSettingsFormEntry';
import { useTranslation } from 'react-i18next';
import useUnifiedStageOptions from '../../../../../../hooks/useUnifiedStageOptions';

const FormContractStageMultiselect = () => {
  const { t } = useTranslation();
  const contractStageOptions = useUnifiedStageOptions();

  return (
    <ViewSettingsFormEntry
      name="filters.contractStage"
      label={t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_STAGE')}
      component={FormSelectField2}
      componentProps={{
        isMulti: true,
        size: 'S',
        options: contractStageOptions,
        placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
        boxValue: (values?: string[]) =>
          (values ?? [])
            .map((value) => contractStageOptions.find((option) => option.value === value))
            .filter((x) => !isNil(x)),
        unboxValue: (options?: typeof contractStageOptions) => options?.map((option) => option.value) ?? [],
      }}
      width={12}
    />
  );
};

export default FormContractStageMultiselect;
