import { CommonIntakeFormFormData } from '../types';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';

export type NewPurchaseFormData = CommonIntakeFormFormData & {
  type: 'new_purchase';
  startDate?: string;
  desiredContractLength: number | null;
  newContractId?: string;
  newContractVersion?: number;
};

export const NewPurchaseFormEntry = createTypedFormEntry<NewPurchaseFormData>();

export const isNewPurchaseContractFormData = (formData: CommonIntakeFormFormData): formData is NewPurchaseFormData =>
  formData.type === 'new_purchase';
