import { USER_ROLES } from '@vertice/core/src/constants/userRoles';

const GROUP_TO_ROLE_MAPPING: Record<string, string> = {
  'role:account:admin': USER_ROLES.admin,
  'role:account:user:power': USER_ROLES.powerUser,
  'role:account:user:restricted': USER_ROLES.user,
};

const mapGroupToRole = (group: string): string | undefined => GROUP_TO_ROLE_MAPPING[group] ?? undefined;

export default mapGroupToRole;
