import { useMemo } from 'react';

export type validationRules =
  | 'isValidLength'
  | 'isContainsLowercase'
  | 'isContainsUppercase'
  | 'isContainsNumber'
  | 'isContainsSpecialCharacter';

const regex = /[`~|;:,<>'!@#$%^&?*\][}{)(+=._"\\/-]/;

export const passwordValidationSchema: Record<validationRules, (password: string) => boolean> = {
  isValidLength: (value: string) => value.length >= 10,
  isContainsLowercase: (value: string) => /[a-z]/.test(value),
  isContainsUppercase: (value: string) => /[A-Z]/.test(value),
  isContainsNumber: (value: string) => /[0-9]/.test(value),
  isContainsSpecialCharacter: (value: string) => regex.test(value),
};

const useValidatePassword = (password: string): { [key in validationRules]: boolean } =>
  useMemo(
    () => ({
      isValidLength: passwordValidationSchema.isValidLength(password),
      isContainsLowercase: passwordValidationSchema.isContainsLowercase(password),
      isContainsUppercase: passwordValidationSchema.isContainsUppercase(password),
      isContainsNumber: passwordValidationSchema.isContainsNumber(password),
      isContainsSpecialCharacter: passwordValidationSchema.isContainsSpecialCharacter(password),
    }),
    [password]
  );

export default useValidatePassword;
