import { Point, WithDrilldown } from '../../chart-lib-agnostic/types';
import { SeriesOptionsType } from 'highcharts';
import { HighchartsPoint } from '../types';

const getHighchartsSerieAndDrilldown = <PointType extends Point, S extends SeriesOptionsType>(
  points: WithDrilldown<PointType>[],
  getHighchartsPointBase: (p: WithDrilldown<PointType>) => HighchartsPoint,
  getDrilldownSerieBase: (p: WithDrilldown<PointType>) => S
): {
  mainSeriePoints: HighchartsPoint[];
  drilldownSeries: S[];
} => ({
  mainSeriePoints: points.map((p) => ({
    ...getHighchartsPointBase(p),
    drilldown: p.drilldown && p.drilldown.length > 0 ? p.name : undefined,
  })),
  drilldownSeries: points.map((p) => ({
    ...getDrilldownSerieBase(p),
    name: p.name,
    id: p.name,
    data: p.drilldown?.map((dp) => getHighchartsPointBase(dp)) ?? [],
  })),
});

export default getHighchartsSerieAndDrilldown;
