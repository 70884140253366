import * as z from 'zod';

const edgeSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const editGatewayFormSchema = z.object({
  gatewayType: z.enum(['Exclusive', 'Join', 'Fork']),
  edges: z.array(edgeSchema),
});

export type EditGatewayFormData = z.infer<typeof editGatewayFormSchema>;
