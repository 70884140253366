import { useCallback } from 'react';
import Highcharts from 'highcharts';
import format from 'date-fns/format';
import { millisecondsInHour, millisecondsInMinute } from 'date-fns';

const Accuracy = {
  minute: {
    interval: millisecondsInMinute,
    formatter: 'MMM dd',
  },
  hour: {
    interval: millisecondsInHour,
    formatter: 'MMM dd',
  },
  day: {
    interval: millisecondsInHour * 24,
    formatter: 'MMM dd',
  },
  week: {
    interval: millisecondsInHour * 24 * 7,
    formatter: 'MMM dd',
  },
  month: {
    interval: millisecondsInHour * 24 * 30,
    formatter: 'MMM dd',
  },
  year: {
    interval: millisecondsInHour * 24 * 365,
    formatter: 'MMM dd',
  },
};

export type getDateTimeProps = {
  accuracy: keyof typeof Accuracy;
};

const useDateTime = () => {
  return useCallback(({ accuracy }: getDateTimeProps): Highcharts.XAxisOptions => {
    const { interval, formatter } = Accuracy[accuracy];

    return {
      type: 'datetime',
      labels: {
        formatter: ({ value }) => format(new Date(value), formatter),
      },
      minTickInterval: interval,
      showFirstLabel: false,
    };
  }, []);
};

export default useDateTime;
