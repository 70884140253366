import React from 'react';
import { Card } from '@verticeone/design-system/src';

type OverviewProps = {
  children: React.ReactNode;
};

const Overview = ({ children }: OverviewProps) => <Card gap={6}>{children}</Card>;

export default Overview;
