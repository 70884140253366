import {
  DataTableResult,
  ErroredQueryResult,
  useAnalyticsDrawerAccountsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useDeferredQuery, HookResult } from '@verticeone/utils/api';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';
import { useComparisonDrawer } from './ComparisonDrawerContext';

const useDrawerAccountsData = () => {
  const { period, granularity } = useCloudAnalytics();
  const { selectionIds, isOpened } = useComparisonDrawer();
  const { periodOneStart: periodStart, periodOneEnd: periodEnd } = period;
  const { accountId } = useAccountContext();
  const skip = !accountId || !isOpened;

  return useDeferredQuery(
    useAnalyticsDrawerAccountsQuery,
    {
      accountId,
      periodStart,
      periodEnd,
      granularity,
      selectionIds: selectionIds.join(','),
      limit: 200,
    },
    { skip, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  ) as HookResult<DataTableResult | ErroredQueryResult>;
};

export default useDrawerAccountsData;
