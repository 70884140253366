import React, { FC } from 'react';

import { PropsWithContract } from '../types';
import { Field } from './Field';
import { useFormatDate } from '@verticeone/utils/formatting';

export const SignDate: FC<PropsWithContract> = ({ contract }) => {
  const formatDate = useFormatDate();
  const signingDate = contract.parts.contractual?.signatory?.signingDate;
  return (
    <Field
      labelTranslationKey="ENTITIES.CONTRACT.LABELS.SIGN_DATE"
      value={signingDate ? formatDate(signingDate) : undefined}
    />
  );
};
