import jwtDecode, { JwtPayload } from 'jwt-decode';

/**
 * Decode a JWT token from the given string, the input token must be a base64 encoded string
 * @param token Token to decode
 */
export function parseBase64EncodedToken<T>(token: string | null): (JwtPayload & T) | undefined {
  if (token) {
    const data = window.atob(token);
    try {
      return jwtDecode<JwtPayload & T>(data);
    } catch (e) {
      return undefined;
    }
  } else {
    return undefined;
  }
}
