import { Select } from '@verticeone/design-system/src';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from '../types';
import { useCustomFieldFilters } from '../hooks/useCustomFieldFilters';
import { keyBy, mapValues } from 'lodash';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { MultiValue } from 'react-select';

type FilterConfigDropdownValue = {
  label: string;
  value: Filter;
};

export type FilterSelectProps = {
  values?: Filter[];
  onSelectionChanged: (value?: Filter[]) => void;
};

export const FilterSelect = ({ values, onSelectionChanged }: FilterSelectProps) => {
  const { t } = useTranslation();
  const { availableFilters } = useContext(ContractListContext);
  const customFieldFilters = useCustomFieldFilters();
  const customFilterLabelsByFilterName = useMemo(
    () => mapValues(keyBy(customFieldFilters, 'filterName'), (filterConfig) => filterConfig.label),
    [customFieldFilters]
  );

  const filterLabelMap = useMemo(
    () => ({
      [Filter.VENDOR_PRODUCT]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.VENDOR_PRODUCT'),
      [Filter.RENEWAL_STATUS]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.RENEWAL_STATUS'),
      [Filter.THRESHOLD]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.THRESHOLD'),
      [Filter.DEPARTMENT]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.DEPARTMENT'),
      [Filter.OWNER]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.OWNER'),
      [Filter.SIGNING_ENTITY]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.SIGNING_ENTITY'),
      [Filter.REGION]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.REGION'),
      [Filter.ROLLING_FREQUENCY]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.ROLLING_FREQUENCY'),
      [Filter.BILLING_FREQUENCY]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.BILLING_FREQUENCY'),
      [Filter.PAYMENT_TERMS]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.PAYMENT_TERMS'),
      [Filter.STAGE]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.STAGE'),
      [Filter.CONTRACT_ORIGIN]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.CONTRACT_ORIGIN'),
      [Filter.CONTRACT_CATEGORY]: t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.LABELS.CONTRACT_CATEGORY'),
      ...customFilterLabelsByFilterName,
    }),
    [customFilterLabelsByFilterName, t]
  );

  const options = useMemo(
    () =>
      availableFilters?.map((filter) => ({
        label: filterLabelMap[filter as keyof typeof filterLabelMap],
        value: filter,
      })),
    [availableFilters, filterLabelMap]
  );

  const onChange = (selection: MultiValue<FilterConfigDropdownValue>) =>
    onSelectionChanged(selection.length ? selection?.map((value) => value.value) : undefined);

  return (
    <Select<FilterConfigDropdownValue, true>
      variant="outlined"
      size="S"
      isMulti={true}
      isClearable={true}
      options={options}
      value={options?.filter(({ value }) => values?.includes(value))}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      placeholder={t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.SEARCH_FILTER')}
      onChange={onChange}
      maxMenuHeight={300}
      testId={'filter-visibility-select'}
    />
  );
};
