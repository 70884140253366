import { Stack } from '@mui/material';
import { Text, ChipButton } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { OfferType } from './types';
import { useOfferFooterValues } from './helpers/utils';
import { Offer } from '../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';

type OfferFooterProps = {
  offer: Offer;
  baselineOffer?: Offer;
  type: OfferType;
};

type OfferFooterItemProps = {
  title: string;
  value: string;
  variant?: 'default' | 'saving' | 'additional';
  percentageChipValue?: number;
};

const getFooterItemColor = (variant: 'default' | 'saving' | 'additional'): string => {
  switch (variant) {
    case 'saving':
      return 'success';
    case 'additional':
      return 'text3';
    default:
      return 'text1';
  }
};

const OfferFooterItem = ({ title, value, variant = 'default', percentageChipValue }: OfferFooterItemProps) => {
  const formatPercentage = useFormatPercentage();
  const isDefault = variant === 'default';
  const isAdditional = variant === 'additional';
  return (
    <Stack gap={3} direction={'row'}>
      <Text variant="heading" size={isDefault ? 'S' : 'XS'} color={isAdditional ? 'text3' : 'text1'} width={'50%'}>
        {title}
      </Text>
      <Stack direction="row" width={'50%'} gap={1} alignItems="end">
        <Text
          variant="heading"
          size={variant === 'default' ? 'S' : 'XS'}
          color={getFooterItemColor(variant)}
          textAlign={{ xs: 'right', md: 'left' }}
        >
          {value}
        </Text>
        {percentageChipValue !== undefined && (
          <ChipButton size="S" variant="outline" isActive={false} color="success" disabled={variant === 'additional'}>
            {formatPercentage(percentageChipValue)}
          </ChipButton>
        )}
      </Stack>
    </Stack>
  );
};

export const OfferFooter = ({ offer, baselineOffer, type }: OfferFooterProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { getOfferFooterValues } = useOfferFooterValues({ offer, baselineOffer, type });

  const { baselineAnnualCost, annualCost, baselineTotalCost, totalCost, savings } = getOfferFooterValues();

  const shouldShowConcessionSavings = type !== 'baseline' && savings && !!savings.concessionSavings;
  const shouldShowTotalSavings =
    type !== 'baseline' && savings && (savings.concessionSavings > 0 || savings.baseSavings > 0);

  const totalAnnualSavings = (savings?.concessionSavings ?? 0) + (savings?.baseSavings ?? 0);
  const totalAnnualSavingsPercentage = type !== 'baseline' ? totalAnnualSavings / baselineAnnualCost : undefined;
  const diffVsBaseline = type !== 'baseline' ? totalCost - baselineTotalCost : undefined;

  return (
    <Stack py={4} px={6} gap={1}>
      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.OFFER_COST')}
        value={formatCurrency(totalCost, { currency: offer.baseCurrency })}
        variant="additional"
      />

      {diffVsBaseline && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.DIFF_VS_BASELINE')}
          value={formatCurrency(diffVsBaseline, { currency: offer.baseCurrency, plusSign: true })}
          variant="additional"
          percentageChipValue={0.1}
        />
      )}

      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ANNUAL_COST')}
        value={formatCurrency(annualCost, { currency: offer.baseCurrency })}
      />

      {shouldShowConcessionSavings && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONCESSION_SAVINGS')}
          value={formatCurrency(savings.concessionSavings, { currency: offer.baseCurrency })}
          variant="saving"
        />
      )}
      {shouldShowTotalSavings && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.TOTAL_ANNUAL_SAVINGS')}
          value={formatCurrency(totalAnnualSavings, { currency: offer.baseCurrency })}
          percentageChipValue={totalAnnualSavingsPercentage}
          variant="saving"
        />
      )}
    </Stack>
  );
};
