import { sumBy } from 'lodash';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { ViewResult } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

const ROW_HEIGHT = 60;
const HEADER_HEIGHT = 48;
const BOTTOM_OFFSET = 12;
const VISIBLE_ROW_COUNT = 10;
const MIN_VISIBLE_ROW_COUNT = 1;

export const getTableHeight = <T extends Array<object>>(
  filteredData?: T,
  isTableLoading?: boolean,
  loadingHeight?: string
) => {
  const bottomOffset = filteredData && filteredData.length > VISIBLE_ROW_COUNT ? BOTTOM_OFFSET : 0;
  const visibleRows = Math.min(filteredData?.length || MIN_VISIBLE_ROW_COUNT, VISIBLE_ROW_COUNT);
  const calculatedHeight = `${visibleRows * ROW_HEIGHT + HEADER_HEIGHT + bottomOffset}px`;

  return isTableLoading && loadingHeight ? loadingHeight : calculatedHeight;
};

export const prepareStatsData = <T>(data?: T[]) => {
  if (data?.length === 0) {
    return;
  }

  const cost = sumBy(data, 'cost');
  const previousCost = sumBy(data, 'previousCost');
  const difference = cost - previousCost;
  const percentage = previousCost ? difference / previousCost : 0;

  return {
    cost,
    previousCost,
    difference,
    percentage,
  };
};

type AdditionalProps = {
  [key: string]: any;
};

export const prepareTableData = <T extends { difference: number | null }>(
  mapping: { [key: string]: string },
  rawData?: ViewResult,
  additionalProps: AdditionalProps = {}
) => {
  if (rawData?.__typename !== 'DataTableResult' || !rawData.table) {
    return;
  }

  const tableData = getTableData(rawData.table, mapping) as T[];

  const changeIndex = rawData.table?.columns?.findIndex((column) => column === 'gross_change');

  return tableData.map((data, index) => {
    const originalDifference = rawData.table?.data?.[index][changeIndex];
    const difference = originalDifference !== null ? data.difference : null;

    return {
      ...data,
      difference,
      ...additionalProps,
    };
  });
};
