import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextFieldCaption } from '@verticeone/design-system/src';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import { PropsWithContract } from '../types';
import { isContractExisting } from '@vertice/core/src/modules/saas/contract/computed';

export const NegotiatedCost: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();

  const annualCostWithVertice = contract.parts.overview?.annualCostWithVertice;
  const totalCostValueWithVertice = contract.parts.overview?.totalCostValueWithVertice;

  if (isContractExisting(contract)) return null;

  return (
    <Stack gap={1} flex={1}>
      <TextFieldCaption
        htmlFor={id}
        label={t('ENTITIES.CONTRACT.LABELS.NEGOTIATED_COST')}
        size="XS"
        tooltip={{
          title: t('ENTITIES.CONTRACT.LABELS.NEGOTIATED_COST'),
          content: t('ENTITIES.CONTRACT.TOOLTIPS.NEGOTIATED_COST'),
        }}
      />

      <LargeValue
        id={id}
        primaryValue={formatContractCurrency(annualCostWithVertice)}
        primaryTestId={'annualCostWithVerticeValue'}
        primaryColor="text1"
        secondaryValue={
          totalCostValueWithVertice
            ? t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalCostValueWithVertice) })
            : undefined
        }
        secondaryTestId={'totalCostValueWithVerticeValue'}
      />
    </Stack>
  );
};
