import { countBy, map, orderBy, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  ResourceCode,
  RiResult,
  useReservedInstancesByCodeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import type { RegionCode } from '@vertice/dashboard/src/modules/cloud/regions';
import { InstanceSize, Service } from '../types';
import { ALLOWED_RI_STATES } from '../constants';
import { getInstanceFamily, getInstanceSize, useCloudInventory } from '../useCloudInventory';
import getRICost, { ONE_YEAR, Price, PAYMENT_OPTIONS } from '../../../utils/getRiCost';
import { useTranslation } from 'react-i18next';

const THREE_YEARS = ONE_YEAR * 3;

export type InstancesDetailData = {
  region: RegionCode;
  instanceFamily: string;
  product: string;
  graphData: {
    accounts: {
      [key: string]: number;
    };
    class: {
      standard: number;
      convertible: number;
    };
    term: {
      threeYears: number;
      oneYear: number;
    };
    payment: {
      noUpfront: number;
      upfront: number;
    };
  };
  sizeGraphData: Array<{
    name: InstanceSize;
    value: number;
  }>;
  tableData: Array<{
    id: string;
    size: string;
    term: number;
    tenancy: string;
    operatingSystem: string;
    paymentOptions: string;
    offeringClass: string;
    riCost: number;
    retentionDate: number;
    isExpired: boolean;
  }>;
};

const RESOURCE_CODE_BY_TAB_ID: {
  [key: string]: ResourceCode;
} = {
  ec2: ResourceCode.RiEc2,
  rds: ResourceCode.RiRds,
  ebsSnapshot: ResourceCode.EbsSnapshot,
  ebsVolume: ResourceCode.EbsVolume,
  organizationResource: ResourceCode.OrganizationResource,
};

const useGetTopAccounts = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.INVENTORY.DETAIL.CHARTS.ACCOUNT' });

  return (accountCounts: { [key: string]: number }, totalRIs: number, numberOfAccounts = 4) => {
    // Sort the accounts by size in descending order
    const sortedAccounts = Object.entries(accountCounts)
      .map(([accountId, count]) => ({ accountId, percentage: (count / totalRIs) * 100 }))
      .sort((a, b) => b.percentage - a.percentage);

    // Pick the first numberOfAccounts accounts
    const topAccounts = sortedAccounts.slice(0, numberOfAccounts).reduce((acc, { accountId, percentage }) => {
      acc[accountId] = percentage;
      return acc;
    }, {} as { [key: string]: number });

    // Group the rest under the name "Other"
    const otherPercentage = sortedAccounts
      .slice(numberOfAccounts)
      .reduce((total, { percentage }) => total + percentage, 0);
    if (otherPercentage > 0) {
      topAccounts[t('OTHERS')] = otherPercentage;
    }

    return topAccounts;
  };
};

export const useGetInstancesDetailData = () => {
  const { accountId } = useSelector(getAccount);
  const { showExpired, activeTab } = useCloudInventory();
  const getTopAccounts = useGetTopAccounts();

  const activeTabId = activeTab?.id as Service;
  const resourceCode = RESOURCE_CODE_BY_TAB_ID[activeTabId];

  const { data } = useReservedInstancesByCodeQuery(
    {
      accountId: accountId!,
      code: resourceCode,
    },
    { skip: !resourceCode }
  );

  return ({
    region,
    instanceFamily,
    product,
  }: {
    region: RegionCode;
    instanceFamily: string;
    product: string;
  }): InstancesDetailData | null => {
    if (data?.resourceQuery?.__typename === 'ResourceQueryResult') {
      const today = new Date();
      const filteredData =
        data?.resourceQuery?.items?.filter((resourceItem) => {
          const item = resourceItem?.resource as RiResult;
          const retentionDate = new Date(item.retentionDate * 1000);

          return (
            (showExpired || retentionDate >= today) &&
            ALLOWED_RI_STATES.includes(item.state || '') &&
            resourceItem.region === region &&
            getInstanceFamily(item.instance?.type, activeTab.instanceTypePosition) === instanceFamily &&
            (activeTabId !== 'rds' || item.instance?.product === product)
          );
        }) ?? [];

      const accountCounts = countBy(filteredData, 'accountId');
      const totalRIs = sum(Object.values(accountCounts));
      const accounts = getTopAccounts(accountCounts, totalRIs);

      const getPercentage = (a: number, b: number) => (a / (a + b)) * 100;

      const standard = filteredData.filter((item) => (item.resource as RiResult).offering?.class === 'standard').length;
      const convertible = filteredData.filter(
        (item) => (item.resource as RiResult).offering?.class === 'convertible'
      ).length;
      const threeYears = filteredData.filter((item) => (item.resource as RiResult).duration === THREE_YEARS).length;
      const oneYear = filteredData.filter((item) => (item.resource as RiResult).duration === ONE_YEAR).length;
      const noUpfront = filteredData.filter((item) =>
        [PAYMENT_OPTIONS.NO_UPFRONT, PAYMENT_OPTIONS.PARTIAL_UPFRONT].includes(
          (item.resource as RiResult).offering?.type as PAYMENT_OPTIONS
        )
      ).length;
      const upfront = filteredData.filter((item) =>
        [PAYMENT_OPTIONS.ALL_UPFRONT, PAYMENT_OPTIONS.PARTIAL_UPFRONT].includes(
          (item.resource as RiResult).offering?.type as PAYMENT_OPTIONS
        )
      ).length;

      return {
        region,
        instanceFamily,
        product,
        graphData: {
          accounts,
          class: {
            standard: getPercentage(standard, convertible),
            convertible: getPercentage(convertible, standard),
          },
          term: {
            threeYears: getPercentage(threeYears, oneYear),
            oneYear: getPercentage(oneYear, threeYears),
          },
          payment: {
            noUpfront: getPercentage(noUpfront, upfront),
            upfront: getPercentage(upfront, noUpfront),
          },
        },
        sizeGraphData: orderBy(
          map(
            countBy(
              map(filteredData, (resourceItem) => {
                const item = resourceItem?.resource as RiResult;
                return getInstanceSize(item?.instance?.type, activeTab.instanceTypePosition) ?? '';
              })
            ),
            (value, name: string) => ({ name: name as InstanceSize, value: (value / filteredData.length) * 100 })
          ),
          ['value'],
          ['desc']
        ),
        tableData: filteredData.map((resourceItem) => {
          const item = resourceItem?.resource as RiResult;

          return {
            id: item.id ?? '',
            quantity: item.instance?.count ?? 0,
            tenancy: item.instance?.tenancy ?? '',
            operatingSystem: item.instance?.product ?? '',
            size: getInstanceSize(item?.instance?.type, activeTab.instanceTypePosition) ?? '',
            term: item.duration ? item.duration / ONE_YEAR : 0,
            paymentOptions: item.offering?.type ?? '',
            offeringClass: item.offering?.class ?? '',
            riCost:
              item.offering && item.instance && item.duration && item.price
                ? getRICost(item.offering.type, item.instance.count, item.duration, item.price as Price)
                : 0,
            retentionDate: item.retentionDate ?? 0,
            isExpired: item.retentionDate ? new Date(item.retentionDate * 1000) < today : false,
          };
        }),
      };
    }

    return null;
  };
};
