import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';

type GetScrollBoundariesResult = {
  top: boolean;
  bottom: boolean;
};

const defaultBoundaries: GetScrollBoundariesResult = { top: false, bottom: false };

// detects whether the container is scrolled to the top or bottom
export const useScrollBoundaries = (disabled?: boolean) => {
  const [scrollBoundaries, setScrollBoundaries] = useState<GetScrollBoundariesResult>(defaultBoundaries);

  useEffect(() => {
    if (disabled) {
      setScrollBoundaries(defaultBoundaries);
    }
  }, [disabled]);

  const onScrollHandler = useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      let currentBoundaries: GetScrollBoundariesResult = defaultBoundaries;

      if (!disabled) {
        const scrollTop = event.currentTarget.scrollTop;
        const scrollHeight = event.currentTarget.scrollHeight;
        const clientHeight = event.currentTarget.clientHeight;

        const isBottom = clientHeight === scrollHeight - scrollTop;
        const isTop = scrollTop === 0;
        const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

        currentBoundaries = {
          top: (isBottom || isBetween) && !(isTop && isBottom),
          bottom: (isTop || isBetween) && !(isTop && isBottom),
        };
      }

      if (!isEqual(scrollBoundaries, currentBoundaries)) {
        setScrollBoundaries(currentBoundaries);
      }
    },
    [disabled, scrollBoundaries]
  );

  return useMemo(() => ({ onScrollHandler, scrollBoundaries }), [onScrollHandler, scrollBoundaries]);
};
