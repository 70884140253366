import { FC } from 'react';
import { SetupConnection } from './SetupConnection';

type StepContentProps = {
  // future properties
};

export const StepContent: FC<StepContentProps> = () => {
  return <SetupConnection />;
};
