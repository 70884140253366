import React from 'react';
import { Stack, useTheme } from '@mui/material';

import ScoreBarStats from './ScoreBar/ScoreBarStats';
import BreakdownChart from './ScoreBar/ScoreBarChart';
import ScoreBarTitle from './ScoreBar/ScoreBarTitle';

const HeaderSummary = () => {
  const { palette } = useTheme();

  return (
    <Stack gap={0.25} bgcolor={palette.core.color3}>
      <ScoreBarTitle />
      <Stack>
        <ScoreBarStats />
        <BreakdownChart />
      </Stack>
    </Stack>
  );
};

export default HeaderSummary;
