import React, { useId } from 'react';
import { StatsBody, StatsContainer, StatsContainerProps, Title, Value } from '@vertice/core/src/components/StatsBar';
import { ChipButton } from '@verticeone/design-system/src';
import useRecommendationsData from '../hooks/useRecommendationsData';
import { useTranslation } from 'react-i18next';
import { AWS_DEFAULT_CURRENCY } from '../constants';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { Text } from '@verticeone/design-system/src';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import useAnnualSpendData from '../hooks/useAnnualSpendData';
import SolidStatsTile from '@vertice/core/src/components/StatsBar/StatsTile/SolidStatsTile';
import { useTheme } from '@mui/material';

const EstimatedSavingsStatsContainer = (props: StatsContainerProps) => {
  const id = useId();
  const recsDataQuery = useRecommendationsData();
  const totalSpendData = useAnnualSpendData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ESTIMATED_SAVINGS_STATS_CONTAINER' });
  const navigate = useNavigate();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();
  const { palette } = useTheme();

  const annualSavings = (recsDataQuery.stats?.totalMonthlyRecValue ?? 0) * 12;
  const annualGrossSpend = totalSpendData.data?.annualGrossSpend ?? 0;
  const savingsFraction = annualGrossSpend === 0 ? 0 : annualSavings / annualGrossSpend;

  return (
    <StatsContainer {...props}>
      <SolidStatsTile color="success">
        <StatsBody
          isLoading={recsDataQuery.isLoading || totalSpendData.isLoading}
          title={
            <Title tooltip={{ content: t('TOOLTIP_INFO') }} id={id}>
              {t('ESTIMATED_SAVINGS_TITLE')}
            </Title>
          }
          value={
            <Value aria-labelledby={id}>
              {formatCurrency(annualSavings, {
                maximumFractionDigits: 0,
                currency: AWS_DEFAULT_CURRENCY,
              })}
              {savingsFraction > 0 && (
                <Text variant="heading" size="M" color={palette.global.transparency.white['50']}>
                  {' '}
                  ({formatPercentage(savingsFraction)})
                </Text>
              )}
            </Value>
          }
          badge={
            <ChipButton variant="ghost" size="S" color="success" onClick={() => navigate(ROUTES.CLOUD_OPTIMIZATION)}>
              {recsDataQuery.stats?.breachingRecsCount
                ? t('NUM_OF_ACTIONS', { count: recsDataQuery.stats.breachingRecsCount })
                : t('MONITORING')}
            </ChipButton>
          }
        />
      </SolidStatsTile>
    </StatsContainer>
  );
};

export default EstimatedSavingsStatsContainer;
