import { SvgIconComponent } from '@mui/icons-material';
import { ReactElement, createContext, useContext } from 'react';
import { Stack, useTheme } from '@mui/material';
import { gradientBorder } from '../../utils/css/gradientBorder';
import { Text } from '../Text';
import { DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { Palette } from '@mui/material/styles';
import assertExhausted from '../../utils/assertExhausted';
import { baseSizes, reducedSizes } from '../../guidelines/Sizing/sizings';
import { testProps } from '../../utils/testProperties';
import { Button, ButtonProps } from '../Button';
import { IconButton, IconButtonProps } from '../IconButton';
import { isShadowColor } from '../../theme/palette/global/getShadow';

export type AlertVariant = 'solid' | 'ghost' | 'outline';

export type AlertProps = TestProps & {
  title?: ReactElement | string;
  subtitle?: ReactElement | string;
  color?: DesignSystemColor;
  icon?: SvgIconComponent;
  size?: DesignSystemSize;
  variant?: AlertVariant;
  children?: React.ReactNode;
  noBorderRadius?: boolean;
};

type AlertVariantParams = {
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  subtitleOpacity?: number;
  boxShadow?: string;
};

type ButtonContextType = {
  variant: AlertVariant;
  color: DesignSystemColor;
  size: DesignSystemSize;
};

const ButtonContext = createContext<ButtonContextType>({
  variant: 'ghost',
  color: 'info',
  size: 'M',
});

const getAlertVariantParams = (
  variant: AlertVariant,
  color: DesignSystemColor,
  palette: Palette
): AlertVariantParams => {
  switch (variant) {
    case 'solid':
      return {
        textColor: palette.text.color5,
        backgroundColor: palette[color].color2,
        boxShadow: palette.global.getShadow({
          color: color === 'info' ? 'secondary' : isShadowColor(color) ? color : 'core',
          type: 'soft',
          depth: '2z',
          distance: '50',
        }),
      };
    case 'ghost':
      return {
        textColor: palette[color].color1,
        subtitleOpacity: 0.6,
        backgroundColor: palette[color].color4,
        borderColor: palette[color].color3,
      };
    case 'outline':
      return {
        textColor: palette[color].color1,
        subtitleOpacity: 0.6,
        backgroundColor: palette.core.bg,
        borderColor: palette[color].color2,
      };
    default:
      assertExhausted(variant);
      return {};
  }
};

const alertIconSizes: Record<DesignSystemSize, string> = {
  XXS: '12px',
  XS: '16px',
  S: '20px',
  M: '24px',
  L: '32px',
  XL: '36px',
};

export const Alert: React.FC<AlertProps> = ({
  title,
  subtitle,
  icon: Icon,
  color = 'info',
  size = 'M',
  variant = 'ghost',
  children,
  testId,
  noBorderRadius = false,
}) => {
  const { palette } = useTheme();
  const variantParams = getAlertVariantParams(variant, color, palette);
  const iconSize = alertIconSizes[size];
  const padding = baseSizes[size];
  const iconSpacing = reducedSizes[size];
  const borderRadius = noBorderRadius ? 0 : '16px';
  return (
    <ButtonContext.Provider value={{ variant, color: color, size }}>
      <Stack
        role="alert"
        {...testProps(testId, 'alert')}
        direction={'row'}
        padding={padding}
        bgcolor={variantParams.backgroundColor}
        borderRadius={borderRadius}
        border={`1px solid ${variantParams.borderColor}`}
        alignItems="center"
        gap={padding}
        boxShadow={variantParams.boxShadow}
        // only solid variant has gradient border
        sx={
          variant === 'solid'
            ? gradientBorder({ width: '1px', color: palette.gradient.black40ToTransparent, radius: borderRadius })
            : undefined
        }
      >
        <Stack direction={'row'} alignItems={'center'} gap={iconSpacing} flex={1}>
          {Icon && (
            <Stack aria-hidden justifyContent={'center'} sx={{ opacity: 0.6 }} color={variantParams.textColor}>
              <Icon sx={{ width: iconSize, height: iconSize }} />
            </Stack>
          )}
          <Stack flex={1}>
            {title && (
              <Text variant="body-bold" size={size} color={variantParams.textColor}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                variant="body-regular"
                size={size}
                color={variantParams.textColor}
                sx={{ opacity: variantParams.subtitleOpacity }}
              >
                {subtitle}
              </Text>
            )}
          </Stack>
        </Stack>
        {children && (
          <Stack direction="row" gap={1}>
            {children}
          </Stack>
        )}
      </Stack>
    </ButtonContext.Provider>
  );
};

const getAlertButtonProps = (
  variant: AlertVariant,
  color: DesignSystemColor,
  size: DesignSystemSize
): Pick<ButtonProps, 'variant' | 'size' | 'color' | 'sx'> => ({
  variant: variant === 'outline' ? 'ghost' : 'solid',
  color: variant === 'solid' ? 'transparent' : color,
  size,
});

export const AlertButton = <C extends React.ElementType>(props: ButtonProps<C>) => {
  const { variant, color, size } = useContext(ButtonContext);
  return <Button<C> {...props} {...getAlertButtonProps(variant, color, size)} />;
};

export const AlertIconButton = <C extends React.ElementType>(props: IconButtonProps<C>) => {
  const { variant, color, size } = useContext(ButtonContext);
  return <IconButton<C> {...props} {...getAlertButtonProps(variant, color, size)} />;
};
