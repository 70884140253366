import {
  useRequestProductBenchmarkingInsightMutation,
  useRequestVendorBenchmarkingInsightMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export type UseRequestBenchmarkingDataParams = {
  productId?: string;
  vendorId?: string;
};

export type UseRequestBenchmarkingDataReturn = {
  triggerRequest: (note: string) => Promise<void>;
  isRequestInProgress: boolean;
};

export const useRequestBenchmarkingData = ({
  productId,
  vendorId,
}: UseRequestBenchmarkingDataParams): UseRequestBenchmarkingDataReturn => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const [requestBenchmarkingInsightVendor, { isLoading: isVendorRequestLoading }] =
    useRequestVendorBenchmarkingInsightMutation();
  const [requestBenchmarkingInsightProduct, { isLoading: isProductRequestLoading }] =
    useRequestProductBenchmarkingInsightMutation();

  const triggerRequest = async (note: string) => {
    let response;
    if (productId) {
      response = await requestBenchmarkingInsightProduct({
        accountId: accountId,
        productId: productId!,
        vendorId: vendorId!,
        body: {
          requestNote: note,
        },
      });
    } else {
      response = await requestBenchmarkingInsightVendor({
        accountId: accountId,
        vendorId: vendorId!,
        body: {
          requestNote: note,
        },
      });
    }
    if ('data' in response)
      enqueueSnackbar(t('VENDOR.PRODUCTS.BENCHMARK_REQUEST_SUCCESS'), {
        variant: 'success',
      });
  };

  return {
    triggerRequest,
    isRequestInProgress: isVendorRequestLoading || isProductRequestLoading,
  };
};
