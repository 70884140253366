import {
  ListApplicationsForDiscoveryQuery,
  ListApplicationsForDiscoveryQueryVariables,
  ListApplicationsWithMeasureReportsQuery,
  ListApplicationsWithMeasureReportsQueryVariables,
  api,
} from './generated/usageGraphQL';
import { ApplicationForDiscovery, ApplicationWithMeasureReportsListItemRaw } from './derivedTypes';

import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';

const USAGE_TAG = 'Bffe_Usage';
export const APPLICATIONS_TAG = 'Applications';
const TRANSACTIONS_TAG = 'Transactions';
const VENDORS_TAG = 'Vendors';
const JOBS_TAG = 'Jobs';

export const enhancedUsageGraphQL = api.enhanceEndpoints({
  addTagTypes: [USAGE_TAG, APPLICATIONS_TAG, TRANSACTIONS_TAG, VENDORS_TAG, JOBS_TAG],
  endpoints: {
    listApplicationsForDiscovery: {
      // enhanced endpoint - if nextToken is null, it tries to retrieve all subsequent pages on the background
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled, getCacheEntry, requestId }) {
        if (queryArgs.nextToken === null) {
          const getItemsFn = (response: ListApplicationsForDiscoveryQuery) =>
            response.listApplicationsWithMeasure?.items || ([] as ApplicationForDiscovery[]);
          const getNextTokenFn = (response: ListApplicationsForDiscoveryQuery) =>
            // need to check if the requestId is still the same, because the query might have been invalidated
            getCacheEntry().requestId === requestId ? response.listApplicationsWithMeasure?.nextToken : null;

          const queryResult = await queryFulfilled;
          const nextToken = getNextTokenFn(queryResult.data);
          const initialItems = getItemsFn(queryResult.data);

          if (nextToken) {
            await fetchAllPages<
              ListApplicationsForDiscoveryQuery,
              ListApplicationsForDiscoveryQueryVariables,
              ApplicationForDiscovery
            >({
              fetchFn: async (args) => dispatch(api.endpoints.listApplicationsForDiscovery.initiate(args)),
              getNextTokenFn,
              getItemsFn,
              fetchArgs: { ...queryArgs, nextToken },
              preferCache: true,
              onPartialData: (partialItems) => {
                // need to check if the requestId is still the same, because the query might have been invalidated
                if (getCacheEntry().requestId === requestId) {
                  dispatch(
                    api.util.updateQueryData('listApplicationsForDiscovery', queryArgs, (draft) => {
                      draft!.listApplicationsWithMeasure!.items = [...partialItems, ...initialItems];
                    })
                  );
                }
              },
            });
          }
        }
      },
      providesTags: [APPLICATIONS_TAG],
    },
    listApplicationsWithMeasureReports: {
      // enhanced endpoint - if nextToken is null, it tries to retrieve all subsequent pages on the background
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled, getCacheEntry, requestId }) {
        if (queryArgs.nextToken === null) {
          const getItemsFn = (response: ListApplicationsWithMeasureReportsQuery) =>
            response.listApplicationsWithMeasure?.items || ([] as ApplicationWithMeasureReportsListItemRaw[]);
          const getNextTokenFn = (response: ListApplicationsWithMeasureReportsQuery) =>
            // need to check if the requestId is still the same, because the query might have been invalidated
            getCacheEntry().requestId === requestId ? response.listApplicationsWithMeasure?.nextToken : null;

          const queryResult = await queryFulfilled;
          const nextToken = getNextTokenFn(queryResult.data);
          const initialItems = getItemsFn(queryResult.data);

          if (nextToken) {
            await fetchAllPages<
              ListApplicationsWithMeasureReportsQuery,
              ListApplicationsWithMeasureReportsQueryVariables,
              ApplicationWithMeasureReportsListItemRaw
            >({
              fetchFn: async (args) => dispatch(api.endpoints.listApplicationsWithMeasureReports.initiate(args)),
              getNextTokenFn,
              getItemsFn,
              fetchArgs: { ...queryArgs, nextToken },
              preferCache: true,
              onPartialData: (partialItems) => {
                // need to check if the requestId is still the same, because the query might have been invalidated
                if (getCacheEntry().requestId === requestId) {
                  dispatch(
                    api.util.updateQueryData('listApplicationsWithMeasureReports', queryArgs, (draft) => {
                      draft!.listApplicationsWithMeasure!.items = [...partialItems, ...initialItems];
                    })
                  );
                }
              },
            });
          }
        }
      },
      providesTags: [APPLICATIONS_TAG],
    },
    getApplicationWithMeasureReports: {
      providesTags: [APPLICATIONS_TAG],
    },
  },
});
