import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormEntry from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { useFormContext } from 'react-hook-form';
import { SlackIntegrationData } from '../types';
import { FulfillUserAuthorizationApiResponse, useFulfillUserAuthorizationMutation } from '@vertice/slices';
import { useLoggedUser } from '@verticeone/auth/src';
import { ROUTES, PREFERENCES_PAGES } from '@vertice/dashboard/src/router/oldConstants';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const useEnterCodeStep = () => {
  const wizard = useWizard();
  const navigate = useNavigate();

  return {
    goBack: wizard.goBack,
    leaveWizard: () => navigate(`${ROUTES.PREFERENCES}/${PREFERENCES_PAGES.USER}`),
  };
};

const EnterCodeStep: React.FC<WizardStepProps> = () => {
  const { goBack, leaveWizard } = useEnterCodeStep();
  const { t } = useTranslation();
  const { watch } = useFormContext<SlackIntegrationData>();
  const { accountId } = useAccountContext();
  const { userId: currentUserId } = useLoggedUser();
  const { enqueueSnackbar } = useSnackbar();
  const [incorrectCode, setIncorrectCode] = React.useState(false);

  const accessCode = watch('accessCode');
  useEffect(() => {
    setIncorrectCode(false);
  }, [accessCode]);

  const [fulfillSlackUserAuthorization, { isLoading }] = useFulfillUserAuthorizationMutation();
  const fulfill = useCallback(() => {
    if (accessCode !== null) {
      void fulfillSlackUserAuthorization({
        accountId: accountId!,
        userId: currentUserId,
        body: {
          authorizationToken: accessCode.toString(),
        },
      }).then((response) => {
        if (!(response as { data: FulfillUserAuthorizationApiResponse })?.data?.ok) {
          setIncorrectCode(true);
          return;
        }
        enqueueSnackbar(t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_CODE_STEP.SUCCESS_SNACKBAR'), {
          variant: 'success',
        });
        leaveWizard();
      });
    }
  }, [accessCode, fulfillSlackUserAuthorization, accountId, currentUserId, enqueueSnackbar, t, leaveWizard]);

  const isNextDisabled = !(accessCode && accessCode.toString().length >= 4);

  return (
    <Content>
      <Header
        title={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_CODE_STEP.TITLE')}
        subtitle={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_CODE_STEP.SUBTITLE')}
      />
      <InnerScrollable>
        <FormEntry<SlackIntegrationData, typeof FormNumberField>
          width={12}
          required
          name="accessCode"
          label={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_CODE_STEP.CODE_LABEL')}
          component={FormNumberField}
          componentProps={{
            error: incorrectCode,
            helperText: incorrectCode
              ? t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_CODE_STEP.INCORRECT_CODE')
              : undefined,
          }}
        />
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: fulfill,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: isNextDisabled,
          isLoading,
        }}
      />
    </Content>
  );
};

export default EnterCodeStep;
