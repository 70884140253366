import { useCallback } from 'react';
import type { TFunction } from 'i18next';
import { formatPercentage } from '@verticeone/utils/formatting';
import { useCloudContext } from '../../../../../modules/cloud/CloudContext';
import type { OptimizationTestRow } from './useOptimizationTestsData';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useTranslation } from 'react-i18next';

const replaceWithHashedStrings = (sentence?: string) => {
  if (!sentence) {
    return '';
  }

  return sentence
    .split(' ')
    .map((word) =>
      Array.from(word, (char, i) => {
        const isUpperCase = char === char.toUpperCase();
        const base = isUpperCase ? 65 : 97; // ASCII value for 'A' or 'a'
        return String.fromCharCode(((char.charCodeAt(0) + i) % 26) + base);
      }).join('')
    )
    .join(' ');
};

export const genericValueFormatter = (value: string, t: TFunction) => {
  if (value === 'true') {
    return t('TRUE');
  }

  if (value === 'false') {
    return t('FALSE');
  }

  return value;
};

export const basicColorGetter = (breaching: boolean) => (breaching ? AWS_BRAND_COLOR : 'neutral');

export const useValueFormatter = () => {
  const { locale } = useLocaleContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.RESULTS' });

  return useCallback(
    (value: string, type: string) => {
      switch (type) {
        case 'percentage':
          return formatPercentage(parseFloat(value), { locale, minimumFractionDigits: 0, maximumFractionDigits: 1 });
        case 'integer':
        case 'string':
        case 'boolean':
          return genericValueFormatter(value, t);
        case 'items':
          return t('N_ITEMS', { count: parseInt(value) });
        default:
          return null;
      }
    },
    [locale, t]
  );
};

export const useDataVisibility = () => {
  const { subFeatures } = useCloudContext();

  return {
    spendAuditVisibleTests: (subFeatures.visibleTests as string).split(',').map((test) => test.trim()),
    canViewFullVersion: !subFeatures.activeSpendAudit,
  };
};

export const useTransformToObfuscated = () => {
  const { spendAuditVisibleTests } = useDataVisibility();

  return useCallback(
    (dataToBeObfuscated: Array<OptimizationTestRow>) =>
      dataToBeObfuscated.map((row) => {
        if (spendAuditVisibleTests.includes(row.code)) {
          // If the test is visible, return the original data
          return row;
        }

        return {
          ...row,
          code: replaceWithHashedStrings(row.code),
          name: replaceWithHashedStrings(row.name),
          description: replaceWithHashedStrings(row.description),
          recommendations: row.recommendations.map((rec) => ({
            ...rec,
            name: replaceWithHashedStrings(rec.code),
          })),
        } as OptimizationTestRow;
      }),
    [spendAuditVisibleTests]
  );
};
