import { useDispatch } from 'react-redux';
import { BFFE_CONTRACT_LIST_TAG, enhancedBffeSaasAPI } from '@vertice/slices';
import { useCallback } from 'react';

const useInvalidateContractList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(enhancedBffeSaasAPI.util.invalidateTags([BFFE_CONTRACT_LIST_TAG])), [dispatch]);
};

export default useInvalidateContractList;
