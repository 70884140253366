import React from 'react';
import { Grid, Stack } from '@mui/material';
import ContractFormEntry from '@vertice/core/src/modules/saas/contract/ContractFormEntry';
import FormReadOnlyText from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { useTranslation } from 'react-i18next';
import CheckboxContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/CheckboxContractFormEntry';
import EditorContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/EditorContractFormEntry';

const SummaryDetailsTab = () => {
  const { t } = useTranslation();

  return (
    <Stack p={6} gap={6}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="classification.region"
            label={t('ENTITIES.CONTRACT.LABELS.REGION')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="parts.contractual.vendor.purchasedFromReseller"
            label={t('ENTITIES.CONTRACT.LABELS.FROM_RESELLER')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.vendor.resellerName"
            label={t('ENTITIES.CONTRACT.LABELS.RESELLER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.signatory.signer.name"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.signatory.signer.email"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.signatory.signingEntity.name"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.vendor.vendorContact.name"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_NAME')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <ContractFormEntry
            name="parts.contractual.vendor.vendorContact.email"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_EMAIL')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
        <Grid item xs={12}>
          <EditorContractFormEntry
            name="additionalDetails.external.notes"
            label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_NOTES')}
            width={12}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SummaryDetailsTab;
