import { useTranslation } from 'react-i18next';
import { differenceInDays, differenceInHours, format } from 'date-fns';
import { Text } from '@verticeone/design-system/src';
import React from 'react';
import type { DesignSystemSize } from '@verticeone/design-system/src';

type LastEvaluatedProps = {
  updatedAt: Date | null;
  textSize?: DesignSystemSize;
};

const LastEvaluated = ({ updatedAt, textSize }: LastEvaluatedProps) => {
  const { t } = useTranslation();
  const lastEvaluated = updatedAt ? format(updatedAt, 'HH:mm - dd/MM/yyyy') : null;

  const getTimeDifference = (dateTime: Date | null): string => {
    if (!dateTime) return '';

    const now = new Date();
    const hoursDifference = differenceInHours(now, dateTime);
    if (hoursDifference < 24) {
      return `(${t('CLOUD.CLOUD_OPTIMIZATION.HOURS_AGO', { count: hoursDifference })})`;
    }

    const daysDifference = differenceInDays(now, dateTime);
    return `(${t('CLOUD.CLOUD_OPTIMIZATION.DAYS_AGO', { count: daysDifference })})`;
  };

  if (!lastEvaluated) {
    return null;
  }

  return (
    <Text variant="body-regular" size={textSize ?? 'M'} color="text2" textAlign="right" display="inline-block">
      {t('CLOUD.CLOUD_OPTIMIZATION.LAST_EVALUATED')}: {lastEvaluated} {getTimeDifference(updatedAt)}
    </Text>
  );
};

export default LastEvaluated;
