import React from 'react';
import { Grid, GridProps, styled } from '@mui/material';

type CardHeaderProps = {
  children: React.ReactNode;
};

/** @deprecated Use CardHeader from @verticeone/design-system/src/components/Card/CardHeader instead */
const CardHeader = styled((props: GridProps) => <Grid item {...props} />)<CardHeaderProps>(({ theme }) => ({
  position: 'relative',
  zIndex: '3',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '1.5rem',
  isolation: 'isolate',
  border: `1px solid ${theme.palette.core.color3}`,
  marginLeft: '-1px',
  marginRight: '-1px',
}));

export default CardHeader;
