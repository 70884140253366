import { Text, TextProps } from '../Text';
import { useDialog } from './DialogContext';
import type { DesignSystemSize } from '../../types';

type DialogLabelProps = Omit<TextProps, 'variant'>;

const dialogSizeToLabelSizeMap: Record<DesignSystemSize, DesignSystemSize> = {
  XL: 'S',
  L: 'S',
  M: 'XS',
  S: 'XS',
  XS: 'XXS',
  XXS: 'XXS',
};

export const DialogLabel = (props: DialogLabelProps) => {
  const { size, align } = useDialog();
  const textSize = props.size ?? dialogSizeToLabelSizeMap[size];
  const textAlign = props.align ?? align;

  return <Text {...props} variant="label" size={textSize} textAlign={textAlign} color="text3" />;
};
