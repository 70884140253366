import {
  ApplicationDataHealth,
  ApplicationDataHealthCheck,
  ApplicationWithMeasureReportsListItem,
} from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import {
  AlarmDay,
  DataHealthCheckSeverity,
  DataHealthCheckStatus,
  ErrorLogCategory,
  License,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';

export const isLicenseSeatBased = (license: License) =>
  license.type === 'SEAT_BASED' && license.quantity !== undefined && license.quantity > 0;

export const getUtilization = (applicationWithMeasures: ApplicationWithMeasureReportsListItem): number | null =>
  applicationWithMeasures.license?.type === 'SEAT_BASED' &&
  applicationWithMeasures.license?.quantity &&
  applicationWithMeasures.license.quantity > 0
    ? parseInt(applicationWithMeasures.measureReports?.uniqueUserAccessCountLast28d?.at(0)?.value || '0', 10) /
      applicationWithMeasures.license.quantity
    : null;

export const getEstimatedUtilization = (activeUsers: string, userCount?: number) =>
  userCount ? parseInt(activeUsers, 10) / userCount : undefined;

export const getActivityFrequency = (
  applicationWithMeasures: ApplicationWithMeasureReportsListItem
): string | null | undefined => {
  const activityFrequency = applicationWithMeasures.measureReports?.activityFrequencyLast56d;

  if (!activityFrequency || !activityFrequency.length) {
    return null;
  }

  const mostActiveBin = activityFrequency.reduce((prev, current) =>
    prev && parseInt(prev.value, 10) > parseInt(current.value, 10) ? prev : current
  );

  if (mostActiveBin?.value === '0') return null;

  return mostActiveBin?.binStart;
};

export const hasWarnings = (dataHealth: ApplicationDataHealth): boolean =>
  dataHealth?.checks?.some(isCheckWarning) || false;

export const hasInfo = (dataHealth: ApplicationDataHealth): boolean => dataHealth?.checks?.some(isCheckInfo) || false;

export const isCheckWarning = (check: ApplicationDataHealthCheck): boolean =>
  check.severity === DataHealthCheckSeverity.Warning && check.status === DataHealthCheckStatus.Failed;

export const isCheckInfo = (check: ApplicationDataHealthCheck): boolean =>
  check.severity === DataHealthCheckSeverity.Info && check.status === DataHealthCheckStatus.Failed;

export const filterDailyAlarms = (alarmsPerDay?: AlarmDay[]) =>
  alarmsPerDay?.map(({ alarms, date }) => {
    const sortedAlarms = [...(alarms ?? [])].sort(
      (a, b) => new Date(b.errorLog?.createdAt ?? '').getTime() - new Date(a.errorLog?.createdAt ?? '').getTime()
    );
    const filteredAlarms = sortedAlarms.filter((alarm, index, self) =>
      alarm.errorLog?.category &&
      [ErrorLogCategory.InvalidToken, ErrorLogCategory.AuthenticationIssues].includes(alarm.errorLog.category)
        ? self.findIndex((a) => a.providerName === alarm.providerName) === index
        : true
    );

    return { date, alarms: filteredAlarms };
  });
