import { every, some } from 'lodash';

export type LoadableAdvanced<PayloadType> = {
  isLoading: boolean;
  data?: PayloadType;
  error: unknown;
  isEmpty: boolean;
  refetch?: () => void;
};

export type LoadingStatus = { isLoading: boolean; error: unknown; isEmpty: boolean };

export const getMergedLoadingStatus = (...loadables: LoadableAdvanced<unknown>[]): LoadingStatus => ({
  isLoading: some(loadables, (l) => l.isLoading || !l.data),
  isEmpty: every(loadables, (l) => l.isEmpty),
  error: loadables.find((l) => l.error)?.error,
});

export const getLoadingStatus = ({ isLoading, isEmpty, error }: LoadableAdvanced<unknown>): LoadingStatus => ({
  isLoading,
  isEmpty,
  error,
});
