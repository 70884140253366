import { ReactNode, useMemo } from 'react';
import { Chart } from 'highcharts';
import ReactDOM from 'react-dom';

export type PortalIdGenerator = {
  chart: Chart | null;
  portalKey?: string;
};

export type PortalProps = {
  children: ReactNode;
} & PortalIdGenerator;

export type PortalWrapperProps = {
  chart: Chart | null;
  children: ReactNode;
};

const generatePortalId = (chartId: number, portalKey?: string) => {
  return ['highcharts-portal', chartId, portalKey?.toLowerCase()?.replace(/[\W_]+/g, '-'), 'key']
    .filter((item) => item !== undefined)
    .join('-');
};

export const fromChartPortal = ({ chart, portalKey }: PortalIdGenerator) => {
  if (chart) {
    return `<div id="${generatePortalId(chart.index, portalKey)}"></div>`;
  }
  return undefined;
};

export const ChartPortal = ({ chart, children, portalKey }: PortalProps) => {
  const portalId = useMemo(() => {
    if (chart) {
      return generatePortalId(chart.index, portalKey);
    }
    return null;
  }, [chart, portalKey]);

  const node = portalId && document.getElementById(portalId);
  if (!chart || !node) {
    return null;
  }

  return ReactDOM.createPortal(children, node, portalId);
};
