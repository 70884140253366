// EXPERIMENATAL, DON'T USE IN CFA
import React, { ReactElement, RefAttributes } from 'react';
import { styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CSSObjectWithLabel } from 'react-select';
import AsyncSelect from 'react-select/async';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import Select from 'react-select/dist/declarations/src/Select';

type SelectFieldProps<T> = {
  loadOptions: (inputValue: string) => Promise<T[]>;
  getOptionLabel: (option: T) => string;
  id: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
};

const selectStyle = {
  option: (styles: CSSObjectWithLabel) => ({
    ...styles,
    backgroundColor: 'white',
    color: 'var(--tokens-color-light-text-1)',
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    borderRadius: '10px',
    padding: '5px 10px',
  }),
  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
};

/**
 * We omit support for the Select's theme property to not interfere with our MUI Theme prop.
 */
type AsyncWithoutThemeAsyncProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = Omit<
  StateManagerProps<Option, IsMulti, Group> & AsyncAdditionalProps<Option, Group>,
  'theme'
>;

type AsyncSelectWithoutTheme = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncWithoutThemeAsyncProps<Option, IsMulti, Group> & RefAttributes<Select<Option, IsMulti, Group>>
) => ReactElement;

const StyledReactAsyncSelect = styled(AsyncSelect)(({ theme }) => ({
  background: theme.palette.core.color1,
})) as AsyncSelectWithoutTheme;

const ReactSelectAsyncField = <T,>({
  id,
  name,
  required,
  disabled,
  loadOptions,
  getOptionLabel,
}: SelectFieldProps<T>) => {
  // eslint-disable-next-line no-restricted-syntax
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <StyledReactAsyncSelect<T>
          onBlur={onBlur}
          onChange={onChange}
          loadOptions={loadOptions}
          cacheOptions
          defaultOptions
          styles={selectStyle}
          getOptionLabel={getOptionLabel}
          value={value || ''}
          ref={ref}
          id={id}
          isDisabled={disabled}
          menuPlacement="auto"
          menuPosition="absolute"
          menuPortalTarget={document.body}
          isClearable
        />
      )}
    />
  );
};

export default ReactSelectAsyncField;
