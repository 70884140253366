import React from 'react';
import ContractFormEntry from '../../ContractFormEntry';
import { AnnualCostOverrideCheckboxField } from '../AnnualCostOverrideCheckboxField';

type Component = typeof AnnualCostOverrideCheckboxField;

const AdvancedBaselineCostOverrideFormEntry = () => {
  return (
    <ContractFormEntry<Component, Component>
      name="costModel.baseline.model.annualCostOverride.enabled"
      width={12}
      component={AnnualCostOverrideCheckboxField}
      readFormFieldDisabled
      writeComponent={AnnualCostOverrideCheckboxField}
    />
  );
};

export default AdvancedBaselineCostOverrideFormEntry;
