import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import { useTranslation } from 'react-i18next';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import annotations from 'highcharts/modules/annotations';

import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { Card } from '@verticeone/design-system/src/components/Card';
import { getTextVariantStyle } from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  cssObjectToString,
  dayInMonthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useGraphData } from './useGraphData';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { annotationStyle, getHourly } from '../../../components/utils';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);
annotations(Highcharts);

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

const useGraphSeries = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.SAGEMAKER.GRAPH' });
  const { data, isFetching } = useGraphData();

  return useMemo(
    () => ({
      hasDataFetched: !isFetching && data !== null,
      categories: data?.map((item) => item.date) ?? [],
      lastDataTimeIndex: data?.findIndex((item) => item.spPurchaseRecommendation !== null),
      lastDataTime: data?.find((item) => item.spPurchaseRecommendation !== null),
      series: [
        {
          id: 'onDemandUsage',
          type: 'column' as const,
          name: t('ON_DEMAND_USAGE'),
          color: 'transparent',
          data: data?.map((item) => item.onDemandUsage) ?? [],
          custom: {
            isVisible: true,
            legendOutlined: true,
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color5,
          },
        },
        {
          id: 'spCoveredUsage',
          type: 'column' as const,
          name: t('SAVINGS_PLANS_COVERED_USAGE'),
          color: palette.visualization.monochromatic.primary['80'],
          data: data?.map((item) => item.spCoveredUsage) ?? [],
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.primary['50'],
            },
          },
          custom: {
            isVisible: true,
            legendColor: palette.visualization.monochromatic.primary['50'],
          },
        },
        {
          id: 'averageUsage',
          type: 'line' as const,
          name: t('AVERAGE_USAGE'),
          color: palette.text.color1,
          data: data?.map((item) => item.averageUsage) ?? [],
          custom: {
            isVisible: true,
            legendColor: palette.text.color1,
          },
        },
        {
          id: 'totalUsage',
          type: 'line' as const,
          name: t('TOTAL_USAGE'),
          color: palette.text.color1,
          dashStyle: 'Dot' as const,
          data: data?.map((item) => item.totalUsage) ?? [],
          custom: {
            isVisible: true,
            legendColor: getColorPattern(palette.text.color1),
          },
        },
        {
          id: 'spPurchaseRecommendation',
          type: 'line' as const,
          name: t('SAVINGS_PLANS_PURCHASE_RECOMMENDATION'),
          color: palette.visualization.monochromatic.tertiary['50'],
          data: data?.map((item) => item.spPurchaseRecommendation) ?? [],
          custom: {
            isVisible: false,
            legendColor: palette.visualization.monochromatic.tertiary['50'],
          },
        },
      ],
    }),
    [data, isFetching, palette, t]
  );
};

type GraphProps = Omit<ReturnType<typeof useGraphSeries>, 'hasDataFetched'>;

const Graph = ({ series, categories, lastDataTimeIndex, lastDataTime }: GraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.SAGEMAKER.GRAPH' });
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const [chart, setChartRef] = useChartRef();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const formatDate = useFormatDate();
  const legend = series.map(({ custom: { legendColor: color, legendOutlined: outlined }, name: label, id }) => ({
    id,
    color,
    label,
    outlined,
  }));

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { spacingTop: 12 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          xAxis: {
            categories,
            minPadding: 0,
            maxPadding: 0,
            labels: { formatter: dayInMonthFormatter },
            plotLines: [
              {
                color: palette.core.color6,
                value: lastDataTimeIndex,
                width: 1,
                zIndex: 3,
              },
            ],
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) => {
                return `<span style="${cssObjectToString(yLabelStyles(palette))}">${formatCurrency(value as number, {
                  currency: AWS_DEFAULT_CURRENCY,
                })}</span>`;
              },
              x: 50,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(getTextVariantStyle({ variant: 'button-bold', size: 'S' }) as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          annotations: [
            {
              draggable: '',
              labels: [
                {
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  useHTML: true,
                  style: {
                    ...(getTextVariantStyle({ variant: 'button-bold', size: 'M' }) as Highcharts.CSSObject),
                    color: palette.text.color5,
                  },
                  text: `<div style="${cssObjectToString(annotationStyle(palette))}">${t('AMOUNT_PER_HOUR', {
                    amount: formatCurrency(getHourly(lastDataTime?.spPurchaseRecommendation ?? 0), {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 2,
                    }),
                  })}</div>`,
                  point: {
                    x: (lastDataTimeIndex ?? 0) + 3,
                    y: lastDataTime?.spPurchaseRecommendation ?? 0,
                    xAxis: 0,
                    yAxis: 0,
                  },
                },
              ],
              labelOptions: {
                x: 0,
                y: 16,
              },
            },
          ],
          tooltip: {
            shared: true,
          },
          series,
        },
        opts
      ),
  ]);

  return (
    <>
      <Box marginLeft="auto" marginTop={4} marginRight={6}>
        {<Legend items={legend} />}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ x, points }) => {
          const filteredPoints = points?.filter((point) => (point.series.userOptions.custom as any).isVisible);

          if (!!filteredPoints?.length) {
            return (
              <TooltipWrapper minWidth="370px">
                <TooltipValueWithTimeInfo value="" timeInfo={formatDate(x!.toString())} />
                {filteredPoints?.map((point) => {
                  const custom = point.series.userOptions.custom as any;

                  return (
                    <TooltipSeriesValuePair
                      seriesColor={custom.tooltipColor ?? custom.legendColor}
                      seriesName={point.series.userOptions.name}
                      value={(Math.round(point!.y! * 100) / 100).toString()}
                      key={point.series.userOptions.id}
                    />
                  );
                })}
              </TooltipWrapper>
            );
          }
        }}
      </HighchartTooltip>
    </>
  );
};

const GraphContainer = () => {
  const { hasDataFetched, ...otherProps } = useGraphSeries();
  const { palette } = useTheme();

  return (
    <Card>
      <Stack bgcolor={palette.core.color1} pb={4} height={485}>
        <LoadableComponent isLoading={!hasDataFetched} color={AWS_BRAND_COLOR}>
          <Graph {...otherProps} />
        </LoadableComponent>
      </Stack>
    </Card>
  );
};

export default GraphContainer;
