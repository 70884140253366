import { useCallback } from 'react';
import { type ColorScheme, useDivergentColorPalette } from '@verticeone/design-system/src';
import { useTheme } from '@mui/material';
import type { HighchartsPoint } from '../types';

type ColoredItem = {
  color: string;
  states: Record<'hover' | 'inactive', Record<'color', string>>;
};

export const useGetIsLoading = () => {
  const theme = useTheme();

  return useCallback(
    (points: HighchartsPoint[] | null | undefined, isLoading?: boolean) => {
      if (!points || isLoading) {
        return [{ name: '', y: 1, color: theme.palette.core.color2 }];
      }

      return points;
    },
    [theme]
  );
};

export const getColoredItem = (color: ColorScheme): ColoredItem => ({
  color: color.normal,
  states: {
    hover: { color: color.dark },
    inactive: { color: color.light },
  },
});

export const useGetColoredItems = () => {
  const graphColors = useDivergentColorPalette();

  return useCallback(
    <P extends HighchartsPoint>(points: P[] | null | undefined): Array<P & ColoredItem> | undefined => {
      return points?.map(
        (item, index) =>
          ({
            ...item,
            ...getColoredItem(graphColors[index % graphColors.length]),
          } as P & ColoredItem)
      );
    },
    [graphColors]
  );
};
