import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styles from './VendorDiligenceInsights.module.scss';

interface AccordionLegalProps {
  ariaControls: string;
  accordionSummaryId: string;
  title: string;
  accordionDetails: React.ReactNode;
}

const AccordionLegal: FC<AccordionLegalProps> = ({ ariaControls, accordionSummaryId, title, accordionDetails }) => (
  <Accordion className={`legal-accordion ${styles['legal-accordion']}`} defaultExpanded>
    <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} aria-controls={ariaControls} id={accordionSummaryId}>
      <Typography variant="body-bold-s">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <div className={styles['legal-accordion-details']}>{accordionDetails}</div>
    </AccordionDetails>
  </Accordion>
);

export default AccordionLegal;
