import React from 'react';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import styled from '@mui/material/styles/styled';
import { DateField as MuiDateField } from '@mui/x-date-pickers-pro';
import { rgba } from 'polished';
import { DesignSystemSize } from '../../../../../types';
import { getTextVariantStyle } from '../../../../Text/utils';
import { lineHeightToHeight } from '../utils';
import * as common from './common';
import sizeDefinitions from './sizeDefinitions';
import { forwardRef } from 'react';

const labelToTextGap: Record<DesignSystemSize, number> = { XL: 2, L: 2, M: 2, S: 1, XS: 1, XXS: 1 };

const getLabelStyle = (size: DesignSystemSize, shrinked: boolean) => {
  const { labelLineHeight } = sizeDefinitions[size];
  const gap = labelToTextGap[size];
  const top = shrinked ? labelLineHeight * 0.15 : (labelLineHeight + gap) / 2;
  return {
    transform: `translate(0, ${top}px) scale(${shrinked ? 0.7 : 1})`,
    maxWidth: '100%',
  };
};

const getTextTopMargin = (size: DesignSystemSize, hiddenLabel: boolean) => {
  const { labelLineHeight } = sizeDefinitions[size];
  const gap = labelToTextGap[size];
  return hiddenLabel ? 0 : labelLineHeight + gap;
};

/** WARNING: Don't use directly in apps. Rather use via DateField with a specified variant. */
const StyledGhostDateField = styled(MuiDateField)<common.StyledDateFieldProps>(
  ({ theme: { palette }, $color, $size, hiddenLabel = false }) => {
    const textStyle = getTextVariantStyle({ variant: 'body-regular', size: $size });
    return {
      '.MuiInput-root': {
        ...textStyle,
        marginTop: getTextTopMargin($size, hiddenLabel),
      },

      // Input
      '.MuiInput-input': {
        // Caret in HTML Input forces effective lineHeight to be at least cca 115% of the fontSize, even if
        // you manually set <115% lineHeight. To fully support and respect <115% lineHeights, we explicitly set the
        // height of the input to the lineHeight. We use converter to turn lineHeight percentage (relative to fontSize)
        // into something that can be fed to the height CSS prop.
        height: lineHeightToHeight(textStyle.lineHeight ?? 1),
        padding: 0,
        color: palette.text.color1,
      },
      '.MuiInput-input::placeholder': {
        color: palette.text.color3,
        opacity: 1,
      },
      '.MuiInput-input::selection': {
        background: rgba(palette[$color].color2, 0.2),
      },
      '.MuiInput-input.Mui-disabled': {
        color: palette.inactive.color2,
      },
      '.MuiInput-root.Mui-error .MuiInput-input::selection': {
        background: rgba(palette.error.color1, 0.2),
      },

      // Label
      '&:hover .MuiInputLabel-standard.MuiInputLabel-shrink:not(.Mui-focused)': {
        color: palette[$color].hover.color2,
      },
      '.MuiInputLabel-standard:not(.Mui-focused)': {
        color: palette.text.color3,
      },
      '.MuiInputLabel-standard.Mui-focused': {
        color: palette[$color].color3,
      },
      '.MuiInputLabel-standard.MuiInputLabel-standard': {
        ...textStyle,
        ...getLabelStyle($size, false),
      },
      '.MuiInputLabel-standard.MuiInputLabel-shrink': {
        ...getLabelStyle($size, true),
      },
      '.MuiInputLabel-standard.Mui-error.Mui-error.Mui-error': {
        color: palette.error.color1,
      },
      '.MuiInputLabel-standard.Mui-disabled.Mui-disabled.Mui-disabled': {
        color: palette.inactive.color2,
      },

      // Helper text
      [`.${formHelperTextClasses.root}.${formHelperTextClasses.error}`]: {
        color: palette.error.color1,
      },
      [`.${formHelperTextClasses.root}`]: {
        position: 'absolute',
        top: '100%',
        marginTop: sizeDefinitions[$size].helperTextGap,
        ...getTextVariantStyle({ variant: 'body-regular', size: sizeDefinitions[$size].helperTextSize }),
        color: palette.text.color3,
      },

      // Shared
      ...common.inputBaseAdornedStyles({ size: $size, noPaddings: true }),
      ...common.spinButtonStyles,
    };
  }
);

const GhostDateField = forwardRef<HTMLDivElement, common.DateFieldProps>(({ size, color, ...otherProps }, ref) => (
  <StyledGhostDateField ref={ref} $size={size} $color={color} {...otherProps} />
));

export default GhostDateField;
