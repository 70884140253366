import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import { CheckboxFilterValue, Filter } from '../types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { CustomContractFieldCheckBoxType } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type CustomCheckboxFieldFilterProps = {
  config: CustomContractFieldCheckBoxType & { filterName: Filter };
};

const CustomCheckboxFieldFilter = ({ config }: CustomCheckboxFieldFilterProps) => {
  const { t } = useTranslation();
  const checkboxOptions = useMemo(
    () => [
      {
        label: t('ENTITIES.FILTERS.CUSTOM_CHECKBOX_FILTER.YES'),
        value: CheckboxFilterValue.TRUE,
      },
      {
        label: t('ENTITIES.FILTERS.CUSTOM_CHECKBOX_FILTER.NO'),
        value: CheckboxFilterValue.FALSE,
      },
    ],
    [t]
  );

  return (
    <GenericMultiselectFilter
      name={config.filterName}
      placeholder={config.label}
      testId={`${config.filterName}-filter`}
      options={checkboxOptions}
    />
  );
};

export default CustomCheckboxFieldFilter;
