import { FormProvider, useForm } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { t } from 'i18next';
import { Stack } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { GoodFormProps, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { Button, IconWrapper, SimpleOption, Text } from '@verticeone/design-system/src';
import { JiraSetupStep } from '../types';
import { FORM_FIELD_WIDTH } from '../constants';

const projectFormDataSchema = z.object({
  jiraProject: z.string(),
});

export type ProjectFormData = z.infer<typeof projectFormDataSchema>;
type ProjectFormProps = GoodFormProps<ProjectFormData> & {
  activeStep: JiraSetupStep;
  isDisabled: boolean;
};

const ProjectFormEntry = createTypedFormEntry<ProjectFormData>();

export const ProjectForm = ({ defaultValues, onSubmit, activeStep, isDisabled }: ProjectFormProps) => {
  const formMethods = useForm<ProjectFormData>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(projectFormDataSchema),
  });
  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);

  const options = ['Project GREEN', 'Project RED', 'Project BLUE', 'Project PINK', 'Project BROWN'].map<SimpleOption>(
    (value) => ({
      value,
      label: value,
    })
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={goodFormMethods.handleSubmit(onSubmit)}>
        <Stack gap={8} maxWidth={FORM_FIELD_WIDTH}>
          <ProjectFormEntry
            component={FormSelectField2}
            componentProps={{
              options,
              boxValue: (value: SimpleOption) => ({ value, label: value }),
              unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
              isClearable: false,
              placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_PLACEHOLDER'),
            }}
            disabled={isDisabled}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_LABEL')}
            name="jiraProject"
            required
            tooltip={{ content: t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_TOOLTIP') }}
          />
          {!isDisabled || activeStep !== JiraSetupStep.SUCCESS ? (
            <Button
              variant="solid"
              size="M"
              color="primary"
              type="submit"
              isLoading={submitButtonProps.isLoading}
              disabled={submitButtonProps.disabled || isDisabled}
              sx={{ alignSelf: 'flex-start' }}
            >
              {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.SUBMIT_BUTTON')}
            </Button>
          ) : (
            <Stack direction="row" gap={2}>
              <IconWrapper icon={CheckCircleOutlineIcon} color="success" />
              <Text variant="button-bold" size="M" color="success">
                {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_SAVED')}
              </Text>
            </Stack>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
