export enum DataHealthType {
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export enum DataHealthCode {
  LICENSE_COUNT_MISSING = 'LICENSE_COUNT_MISSING',
  CONTRACT_LINK_MISSING = 'CONTRACT_LINK_MISSING',
  AUTH_EVENTS_ONLY = 'AUTH_EVENTS_ONLY',
}
