import React from 'react';
import { Grid, GridProps, styled } from '@mui/material';
import type { StyledComponent } from 'styled-components';

export type StatsBarProps = Omit<GridProps, 'container'> & { header?: React.ReactNode };

export const StatsBarBase = styled((props) => <Grid {...props} />)(({ theme }) => ({
  flexDirection: 'row',
  flexFlow: 'row',
  alignItems: 'stretch',
  borderRadius: '16px',
  overflow: 'hidden',
  gap: '1px',
  border: `1px solid ${theme.palette.core.color3}`,
  backgroundColor: theme.palette.core.color3,
})) as StyledComponent<typeof Grid, GridProps>;

export const StatsBar = ({ header, children, ...statsBarProps }: StatsBarProps) => {
  if (!header) {
    return (
      <StatsBarBase {...statsBarProps} container>
        {children}
      </StatsBarBase>
    );
  }

  return (
    <Grid container {...statsBarProps}>
      <Grid item xs={12}>
        {header}
      </Grid>
      <StatsBarBase container item xs={12} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 'none' }}>
        {children}
      </StatsBarBase>
    </Grid>
  );
};
