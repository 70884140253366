import { LoadableAdvanced } from '@verticeone/utils/async';
import {
  AccountValue,
  OrgResourceType,
  useOrgHierarchyQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { isoDateToTimestamp } from 'modules/cloud/utils/graphDataUtils';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export type AccountTableData = {
  accounts: AccountValue[];
  latestUpdatedAt?: number;
};

export const useAccountTableData = (): LoadableAdvanced<AccountTableData> => {
  const { accountId } = useAccountContext();

  const {
    data: orgAccountsQuery,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useOrgHierarchyQuery(
    { accountId: accountId!, orgResourceTypes: [OrgResourceType.Account, OrgResourceType.ManagementAccount] },
    { skip: !accountId }
  );

  const accountData = useMemo(() => {
    const query = orgAccountsQuery?.resourceQuery;
    if (query?.__typename === 'ResourceQueryResult') {
      const accounts = query.items?.slice().map((resourceItem) => resourceItem.resource as AccountValue) || [];
      return {
        accounts,
        latestUpdatedAt: accounts.reduce((acc, account) => Math.max(acc, isoDateToTimestamp(account.updatedAt)), 0),
      };
    }
    return undefined;
  }, [orgAccountsQuery]);

  return {
    error: error,
    isEmpty: Boolean(accountData?.accounts && accountData?.accounts.length === 0),
    isLoading: isLoading || isFetching,
    data: accountData,
    refetch: refetch,
  };
};
