import { useState } from 'react';
import { Stack } from '@mui/material';

import { JiraSetupStep, StepContentProps } from '../types';
import { ConnectionCard } from './ConnectionCard';
import { ProjectCard } from './ProjectCard';

export const StepContent = ({ activeStep, setStep }: StepContentProps) => {
  const [isConnectionEditMode, setIsConnectionEditMode] = useState(false);

  const handleSetIsConnectionEditMode = (isEditMode: boolean) => {
    setIsConnectionEditMode(isEditMode);
  };

  const isConnectionStep = activeStep === JiraSetupStep.CONNECTION;

  return (
    <Stack gap={3}>
      <ConnectionCard
        activeStep={activeStep}
        setStep={setStep}
        isEditMode={isConnectionEditMode}
        setIsEditMode={handleSetIsConnectionEditMode}
      />
      {!isConnectionStep && (
        <ProjectCard activeStep={activeStep} setStep={setStep} isConnectionEditMode={isConnectionEditMode} />
      )}
    </Stack>
  );
};
