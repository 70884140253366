import { ViewSettingsFormData } from './schema';
import { CustomizationView, CustomizationViewInput } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { chain } from 'lodash';

import { CustomFilterView } from '../types';
import { ContractListColumn } from '../../../types';

export const apiCustomizationViewToForm = (apiView: CustomizationView): CustomFilterView => ({
  id: apiView.id,
  label: apiView.label,

  // [{key: 'a', value: 'b'}] => {a: 'b'}
  filters: apiView.filters.reduce((acc, filter) => {
    acc[filter.key] = filter.value;
    return acc;
  }, {} as Record<string, string[]>),

  columns: chain(apiView.columns).orderBy('order').map('key').value(),
});

export const formToApiCustomizationView = (form: ViewSettingsFormData): CustomizationViewInput => {
  const columns = (form.columns.length > 0 ? form.columns : [ContractListColumn.VENDOR]).map((key, order) => ({
    key,
    order,
  }));
  return {
    label: form.label,
    filtersVersion: '1',
    filters: Object.entries(form.filters).map(([key, value]) => ({
      key,
      value: value ?? [],
    })),
    columns,
  };
};
