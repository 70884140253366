const units = ['B', 'KB', 'MB', 'GB'];

export const formatBytes = (bytes: number) => {
  let l = 0;
  let n = bytes;

  while (n >= 1000) {
    l += 1;
    n /= 1000;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};
