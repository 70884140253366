import React from 'react';
import { Grid, GridProps, useTheme, styled } from '@mui/material';

const StyledContainer = styled(Grid)(({ theme }) => ({
  borderRadius: '16px',
  padding: '16px',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.core.color3}`,
}));

export type BannerProps = {
  color?: string;
  children?: React.ReactNode;
} & Omit<GridProps, 'color'>;

export const Banner = ({ color, children, ...otherProps }: BannerProps) => {
  const { palette } = useTheme();

  return (
    <StyledContainer {...otherProps} container bgcolor={color ? color : palette.core.bg}>
      {children}
    </StyledContainer>
  );
};
