import { useCallback } from 'react';
import { ProductItem } from '../types';

type UseProductsProps<T extends ProductItem> = {
  selectedProducts?: T[];
  setSelectedProducts: (newProducts: T[]) => void;
};

const useSelectedProducts = <T extends ProductItem>({ selectedProducts, setSelectedProducts }: UseProductsProps<T>) => {
  const updateProduct = useCallback(
    (updatedProduct: T) => {
      if (!selectedProducts) {
        return;
      }

      setSelectedProducts(
        selectedProducts?.reduce((acc: T[], item: T) => {
          if (item.id === updatedProduct.id) {
            acc.push({
              ...item,
              ...updatedProduct,
            });
          } else {
            acc.push(item);
          }
          return acc;
        }, [])
      );
    },
    [selectedProducts, setSelectedProducts]
  );

  const addProduct = useCallback(
    (newProduct: T) => {
      if (!selectedProducts) {
        return;
      }

      setSelectedProducts([...selectedProducts, newProduct]);
    },
    [selectedProducts, setSelectedProducts]
  );

  const removeProduct = useCallback(
    (id: string) => {
      if (!selectedProducts) {
        return;
      }

      setSelectedProducts(
        selectedProducts?.reduce((acc: T[], item) => {
          if (item.id !== id) {
            acc.push(item);
          }
          return acc;
        }, [])
      );
    },
    [selectedProducts, setSelectedProducts]
  );

  return {
    addProduct,
    updateProduct,
    removeProduct,
  };
};

export default useSelectedProducts;
