import { TaskAssignmentConfiguration } from '../../../../definitionsTypes';
import { EXPRESSION_ALIASES } from '../../../../task/ExpressionAliases';
import { DEFINITION_VERSION } from '../../../../definitions/constants';
import { formatUserRef, parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { useTranslation } from 'react-i18next';
import { useAccountUsersById } from '../../../../../../hooks/useAccountUsersById';
import { useEffect, useMemo, useState } from 'react';
import { getFullName } from '@verticeone/utils/formatting';

// convert task assignment configurations to assignment select values
export const getAssigneesFromAssignmentConfigurations = (
  assignmentConfigurations: TaskAssignmentConfiguration[]
): string[] => {
  const result: string[] = [];

  assignmentConfigurations.forEach((assignmentConfiguration) => {
    if (assignmentConfiguration.type === 'STATIC') {
      result.push(...(assignmentConfiguration.assignment?.map((a) => parseUserRef(a).userId) || []));
    } else if (assignmentConfiguration.type === 'REQUEST_OWNER') {
      result.push('REQUEST_OWNER');
    } else if (assignmentConfiguration.type === 'EXPRESSION') {
      assignmentConfiguration.assignment?.forEach((a) => {
        const matchingAlias = EXPRESSION_ALIASES.find((alias) => alias.assignmentItem === a);
        if (matchingAlias) {
          result.push(matchingAlias.code);
        }
      });
    }
  });

  return result;
};

// convert assignment select values to task assignment configurations
export const getAssignmentConfigurationsFromAssignees = (assignees: string[]): TaskAssignmentConfiguration[] => {
  const staticAssignmentConfiguration: TaskAssignmentConfiguration = {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'STATIC',
    assignment: [],
  };
  const expressionAssignmentConfiguration: TaskAssignmentConfiguration = {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'EXPRESSION',
    expression_type: 'JMESPathExpression',
    assignment: [],
  };
  const requestOwnerAssignmentConfiguration: TaskAssignmentConfiguration = {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'REQUEST_OWNER',
  };
  let hasRequestOwnerAssignment = false;

  assignees.forEach((assignee) => {
    const matchingAlias = EXPRESSION_ALIASES.find((alias) => alias.code === assignee);
    if (matchingAlias) {
      expressionAssignmentConfiguration.assignment?.push(matchingAlias.assignmentItem);
    } else {
      staticAssignmentConfiguration.assignment?.push(formatUserRef(assignee));
    }
  });

  return [
    ...(hasRequestOwnerAssignment ? [requestOwnerAssignmentConfiguration] : []),
    ...(expressionAssignmentConfiguration.assignment?.length ? [expressionAssignmentConfiguration] : []),
    ...(staticAssignmentConfiguration.assignment?.length ? [staticAssignmentConfiguration] : []),
  ];
};

export type Option = {
  readonly value: string;
  readonly label: string;
};

export type GroupOption = {
  label: string;
  options: Option[];
  singleOption: boolean;
  checkedFirst: boolean;
};

// get grouped options for the assignee select
export const useAssignmentOptions = (
  currentAssignees: string[],
  sortAssignees: boolean,
  allowContractOwnerAssignment?: boolean
): GroupOption[] => {
  const { t } = useTranslation();
  const { usersById } = useAccountUsersById();

  const options = useMemo(() => {
    const dynamicUsersOption: Option[] = EXPRESSION_ALIASES.map((alias) => ({
      value: alias.code,
      label: t(`INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.DYNAMIC_USERS.${alias.code}`),
    }))
      .filter((option) => option.value !== 'CONTRACT_OWNER' || allowContractOwnerAssignment)
      .sort((o1, o2) => o1.label.localeCompare(o2.label));

    const userOptions: Option[] = Object.values(usersById)
      .map((user) => ({
        value: user.userId,
        label: getFullName(user),
      }))
      .sort((u1, u2) => u1.label.localeCompare(u2.label));

    return [
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DYNAMIC_USERS_GROUP'),
        options: dynamicUsersOption,
        singleOption: true,
        checkedFirst: false,
      },
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.STATIC_USERS_GROUP'),
        options: userOptions,
        singleOption: false,
        checkedFirst: true,
      },
    ];
  }, [allowContractOwnerAssignment, t, usersById]);

  const [sortedOptions, setSortedOptions] = useState(options);

  useEffect(() => {
    if (sortAssignees) {
      setSortedOptions(sortOptions(options, currentAssignees));
    }
  }, [currentAssignees, options, sortAssignees]);

  return sortedOptions;
};

// sort assignee select groups options to move checked options to the top
export const sortOptions = (groupOptions: GroupOption[], checkedAssignees: string[]): GroupOption[] => {
  return groupOptions.slice().map((groupOption) => {
    if (!groupOption.checkedFirst) {
      return groupOption;
    }
    const sortedOptions = groupOption.options.slice().sort((o1, o2) => {
      const isChecked1 = checkedAssignees.includes(o1.value);
      const isChecked2 = checkedAssignees.includes(o2.value);
      let checkValue = 0;
      if (groupOption.checkedFirst && isChecked1 !== isChecked2) {
        checkValue = isChecked1 && !isChecked2 ? -1 : 1;
      }
      const x = checkValue || o1.label.localeCompare(o2.label);
      return x;
    });
    return {
      ...groupOption,
      options: sortedOptions,
    };
  });
};
