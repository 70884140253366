import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const featuresApi = createApi({
  reducerPath: 'featuresApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.FEATURES),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
