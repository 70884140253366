import { z } from 'zod';

import { SecurityScoreSchema } from '../shared/schemas';
import { zodSchema as SecurityQuestionnaireFormSchema } from '../SecurityQuestionnaireForm/schema';
import { ApprovalStatus } from './SecurityApprovalForm';

export const zodSchema = z
  .object({
    securityApproved: z.string().transform((value) => value === ApprovalStatus.APPROVED),
    securityApprovalNotes: z.string().optional(),
    securityScore: SecurityScoreSchema,
  })
  .merge(SecurityQuestionnaireFormSchema);

export type FormData = z.infer<typeof zodSchema>;
