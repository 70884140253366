import { CircularProgress } from '@mui/material';
import styled from '@mui/material/styles/styled';
import type { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';
import circularProgressClasses from '@mui/material/CircularProgress/circularProgressClasses';
import { testProps } from '../../utils/testProperties';
import { DesignSystemColor, TestProps } from '../../types';

export type LoaderProps = TestProps &
  Omit<CircularProgressProps, 'color'> & {
    color?: DesignSystemColor;
  };

const StyledLoaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'inherit',
  minHeight: '100%',
});

const StyledCircularProgress = styled(CircularProgress)<{ $color: DesignSystemColor }>(
  ({ $color, theme: { palette } }) => ({
    [`&.${circularProgressClasses.root}`]: {
      color: palette[$color].color2,
    },
  })
);

export const Loader = ({ size, color = 'primary', className, testId, ...otherProps }: LoaderProps) => (
  <StyledLoaderWrapper {...testProps(testId, 'loader')} role="progressbar" className={className}>
    <StyledCircularProgress $color={color} {...otherProps} size={size || 40} />
  </StyledLoaderWrapper>
);
