import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatBar, StatBarItem, StatBarDivider } from '@verticeone/design-system/src';
import { useEDPTotalEligibleSpend } from './useSummaryData';
import { LoadableComponent } from '@verticeone/design-system/src';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

type SummaryProps = {
  totalCommitment: number;
  avgDiscount: number;
};

const Summary = ({ avgDiscount, totalCommitment }: SummaryProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_EDP.CONTRACT.SUMMARY' });
  const { data, isFetching } = useEDPTotalEligibleSpend();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  return (
    <Stack p={4} pt={0}>
      <StatBar>
        <LoadableComponent isLoading={isFetching}>
          <StatBarItem
            title={t('TOTAL_COMMITMENT')}
            value={formatCurrency(totalCommitment, {
              maximumFractionDigits: 1,
              compact: true,
              currency: AWS_DEFAULT_CURRENCY,
            })}
            testId="total-commitment"
          />
          <StatBarDivider />
          <StatBarItem
            title={t('SPEND_IN_TERM')}
            value={[
              formatCurrency(data?.spendInTerm ?? 0, {
                maximumFractionDigits: 1,
                compact: true,
                currency: AWS_DEFAULT_CURRENCY,
              }),
              formatCurrency(data?.totalSpend ?? 0, {
                maximumFractionDigits: 1,
                compact: true,
                currency: AWS_DEFAULT_CURRENCY,
              }),
            ].join(' / ')}
            testId="spend-in-term"
          />
          <StatBarDivider />
          <StatBarItem
            title={t('AVG_SAVED_DISCOUNT')}
            value={formatPercentage(avgDiscount)}
            testId="avg-saved-discount"
          />
          <StatBarDivider />
          <StatBarItem
            title={t('TERM_SAVINGS_TO_DATE')}
            value={formatCurrency(data?.termSavingsToDate ?? 0, {
              maximumFractionDigits: 1,
              compact: true,
              currency: AWS_DEFAULT_CURRENCY,
            })}
            color="success"
            testId="term-savings-to-date"
          />
        </LoadableComponent>
      </StatBar>
    </Stack>
  );
};

export default Summary;
