import { useTranslation } from 'react-i18next';
import React from 'react';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import FormReadOnlyText from '../../../../forms/fields/FormReadOnlyText';
import ContractFormEntry from '../../ContractFormEntry';

export const ScopeReductionTCVFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();

  return (
    <ContractFormEntry
      name="parts.overview.scopeReductionSavings"
      label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_TCV')}
      component={FormReadOnlyText}
      componentProps={{
        formatter: formatContractCurrency,
        textProps: {
          variant: 'body-regular',
          color: 'text3',
        },
      }}
      width={12}
    />
  );
};
