import { useMemo } from 'react';
import atomWithFormValues from '@vertice/core/src/modules/forms/utils/atomWithFormValues';
import { selectAtom } from 'jotai/utils';
import { ContractFormData, FetchedDataTypeFromMode, FieldsConfig, FormSupportedMode } from '../types';
import { UseFormReturn } from 'react-hook-form';
import { UseContractEditModeReturn } from './useContractEditMode';
import { Atom } from 'jotai';
import { FieldsConfigEvaluationContext } from '../utils/fieldsConfigUtils';

export type UseContractFieldsRulesParams<Mode extends FormSupportedMode> = {
  hookForm: UseFormReturn<ContractFormData>;
  fetchedContract: FetchedDataTypeFromMode<Mode>;
  fieldsConfig: FieldsConfig<Mode>;
  editMode: UseContractEditModeReturn;
};

/** Functions and atoms for consumption of fieldsConfig by the form. */
export type UseContractFieldsRulesReturn = {
  /**
   * Atom with latest evaluated fields rules and other stuff relevant for computing state of each form field.
   * It is in an atom so that we don't trigger unnecessary re-renders when the form state changes.
   */
  fieldsConfigEvalContextAtom: Atom<FieldsConfigEvaluationContext>;
};

/**
 * Processes fieldsConfig and provides function and atoms for its consumption by the form.
 */
const useContractFieldsRules = <Mode extends FormSupportedMode>({
  hookForm,
  fetchedContract,
  fieldsConfig,
  editMode: { isEditing },
}: UseContractFieldsRulesParams<Mode>): UseContractFieldsRulesReturn => {
  const formValuesAtom = useMemo(() => atomWithFormValues(hookForm), [hookForm]);

  const evalContextAtom = useMemo(
    () =>
      selectAtom(
        formValuesAtom,
        (formData): FieldsConfigEvaluationContext => ({
          fieldsConfig: {
            isWritableAsDefault: fieldsConfig.isWritableAsDefault,
            isVisibleAsDefault: fieldsConfig.isVisibleAsDefault,
            fieldRules: fieldsConfig.getRules({ ...fetchedContract, formData }),
          },
          fetchedContract,
          formData: hookForm.getValues(),
          isEditing,
        })
      ),
    [formValuesAtom, fieldsConfig, fetchedContract, hookForm, isEditing]
  );

  return {
    fieldsConfigEvalContextAtom: evalContextAtom,
  };
};

export default useContractFieldsRules;
