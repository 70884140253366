import { useMemo } from 'react';
import dagre from 'dagre';
import unique from 'lodash/uniq';

import { formatPropertyLabel, useServicesOutputSchemas } from '../WorkflowViewer/useServicesOutputSchema';
import { getDagreGraphFromWorkflowModel } from '../WorkflowRenderer/getDagreGraphFromWorkflowModel';
import {
  isFlowEdgeMappingConfiguration,
  isTaskIOMappingConfiguration,
  isVerticeFormConfiguration,
} from '../../pocWorkflowSchema';
import { ProcessDefinition } from '../../definitionsTypes';
import { useWorkflowRendererContext } from '../WorkflowRenderer/WorkflowRendererContext';
import { Property, NodeId, Path, EdgeId } from './types';

const removeLabelPostfixFromEdgeId = (edgeId: EdgeId) => edgeId.replace(/-(start|end)$/, '');

const findAllPathsBetweenNodes = ({
  graph,
  fromNodeId,
  toNodeId,
}: {
  graph: dagre.graphlib.Graph;
  fromNodeId: NodeId;
  toNodeId: NodeId;
}): Path[] => {
  const paths: Path[] = [];
  const visited = new Set<string>();

  const doDepthFirstSearch = (currentNode: NodeId, nodePath: NodeId[], edgePath: EdgeId[]) => {
    nodePath.push(currentNode);
    visited.add(currentNode);

    if (currentNode === toNodeId) {
      paths.push({ nodes: [...nodePath], edges: [...edgePath] });
    } else {
      const neighbors = (graph.successors(currentNode) || []) as unknown as NodeId[];
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          const edgeId = graph.edge(currentNode, neighbor).id;
          edgePath.push(edgeId);
          doDepthFirstSearch(neighbor, nodePath, edgePath);
          edgePath.pop();
        }
      }
    }

    nodePath.pop();
    visited.delete(currentNode);
  };

  doDepthFirstSearch(fromNodeId, [], []);
  return paths;
};

const START_NODE_ID_FALLBACK = 'start';

const getProcessDefinitionStartNodeId = (processDefinition: ProcessDefinition) => {
  const startNode = processDefinition.process.start[0];

  if (!startNode) {
    return START_NODE_ID_FALLBACK;
  }

  return startNode.start.id;
};

export const useGatewayVariables = ({
  gatewayId,
  processDefinition,
}: {
  gatewayId: NodeId;
  processDefinition: ProcessDefinition;
}) => {
  const { model } = useWorkflowRendererContext();
  const { account: accountServicesSchemas, global: globalServicesSchema } = useServicesOutputSchemas();
  const graph = getDagreGraphFromWorkflowModel(model);

  const requestProperties = useMemo(() => {
    const allPaths = findAllPathsBetweenNodes({
      graph,
      fromNodeId: getProcessDefinitionStartNodeId(processDefinition),
      toNodeId: gatewayId,
    });

    const allNodesIdsFromStartToGateway = unique(allPaths.map((path) => path.nodes).flat());
    const allEdgesIdsFromStartToGateway = unique(
      allPaths.map((path) => path.edges.map(removeLabelPostfixFromEdgeId)).flat()
    );

    const allTasksFromStartToGateway = (processDefinition.process.tasks ?? []).filter(({ task }) =>
      allNodesIdsFromStartToGateway.includes(task.id)
    );

    const allEdgesFromStartToGateway = (processDefinition.process.flow?.flow?.edges ?? []).filter(({ edge }) =>
      allEdgesIdsFromStartToGateway.includes(edge.id)
    );

    const properties: Property[] = [];

    for (const { task } of allTasksFromStartToGateway) {
      if (!task.configurations) {
        continue;
      }

      const taskIOMappingConfigurationOutputFields =
        task.configurations.find(isTaskIOMappingConfiguration)?.mapping.outputFields;

      if (taskIOMappingConfigurationOutputFields?.length) {
        taskIOMappingConfigurationOutputFields.forEach((outputField) => {
          properties.push({
            name: outputField.name,
            label: formatPropertyLabel(outputField.name),
            source: {
              id: task.id,
              label: task.name,
              kind: 'task',
            },
          });
        });
        continue;
      }

      const taskVerticeFormConfigurationFormUrn = task.configurations.find(isVerticeFormConfiguration)?.formUrn;

      if (!taskVerticeFormConfigurationFormUrn) {
        continue;
      }

      const outputSchema = accountServicesSchemas[taskVerticeFormConfigurationFormUrn];

      outputSchema?.properties.forEach((property) => {
        properties.push(property);
      });
    }

    for (const { edge } of allEdgesFromStartToGateway) {
      if (!edge.configurations) {
        continue;
      }

      const edgeMappingConfigurationFields = edge.configurations.find(isFlowEdgeMappingConfiguration)?.mapping.fields;

      if (!edgeMappingConfigurationFields?.length) {
        continue;
      }

      edgeMappingConfigurationFields.forEach((field) => {
        properties.push({
          label: formatPropertyLabel(field.name),
          name: field.name,
          source: {
            id: edge.id,
            label: edge.name,
            kind: 'edge',
          },
        });
      });
    }

    return properties;
  }, [gatewayId, accountServicesSchemas, processDefinition, graph]);

  const globalUDFProperties = useMemo(() => {
    return Object.values(globalServicesSchema).flatMap((schema) => schema.properties);
  }, [globalServicesSchema]);

  return {
    requestProperties,
    globalUDFProperties,
  };
};
