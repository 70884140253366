import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudWatchHistoricalSpendGraph from './CloudWatchHistoricalSpendGraph';
import useCloudWatchHistoricalSpendData from './useCloudWatchHistoricalSpendData';
import { Card, CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system/src';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import { CalendarTodayOutlined } from '@mui/icons-material';

const CloudWatchHistoricalSpendCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useCloudWatchHistoricalSpendData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('CLOUD_WATCH_HISTORICAL_SPEND.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
      </CardHeader>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus} pt="22px" pb={6}>
          <CloudWatchHistoricalSpendGraph data={data!} />
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default CloudWatchHistoricalSpendCard;
