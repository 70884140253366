import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ContractFormData } from '../types';

export type UseContractEditModeParams = {
  hookForm: UseFormReturn<ContractFormData>;
  onlyEditMode: boolean;
};

export type UseContractEditModeReturn = {
  isEditing: boolean;
  enable: () => void;
  cancel: () => void;
  stopAndResetForm: (formData: ContractFormData) => void;
};

/**
 * Manages switching between read and edit mode of the contract form.
 *
 * Makes sure that the form is reset on each switch.
 */
const useContractEditMode = ({ hookForm, onlyEditMode }: UseContractEditModeParams): UseContractEditModeReturn => {
  // Editing
  const [isEditing, setIsEditing] = useState(onlyEditMode);

  const enable = useCallback(() => {
    setIsEditing(true);
    // This reset needs to be here. Without it, we had whole form re-rendering and performance issues.
    hookForm.reset();
  }, [hookForm]);

  const cancel = useCallback(() => {
    if (onlyEditMode) {
      throw new Error('cancelEditing() is not supported with enabled `onlyEditMode` flag.');
    }
    setIsEditing(false);
    hookForm.reset();
  }, [hookForm, onlyEditMode]);

  const stopAndResetForm = useCallback(
    (formData: ContractFormData) => {
      setIsEditing(false);
      hookForm.reset(formData);
    },
    [hookForm]
  );

  return {
    isEditing,
    enable,
    cancel,
    stopAndResetForm,
  };
};

export default useContractEditMode;
