import React, { useCallback, useMemo, useState } from 'react';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { Box, Stack, useTheme } from '@mui/material';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import HighchartsReact from 'highcharts-react-official';
import { HighchartsIntervalPoint } from './HighchartsIntervalPoint';
import { yLabelCurrencyFormatter } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import ConfidenceIntervalLegendIcon from './legendIcons/ConfidenceIntervalLegendIcon';
import { BenchmarkData, BenchmarkingPeriods } from './useGetBenchmarkData';
import { useTranslation } from 'react-i18next';
import { getAxisTitleStyle } from '../utils';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipContent from './TooltipContent';
import { BENCHMARKING_CURRENCY } from './constants';
import NumerosityGraph from './NumerosityGraph';
import { StarLegendIcon } from './legendIcons/StarLegendIcon';
import useBenchmarking from '../../../../vendor/hooks/useBenchmarking';

// Define custom chart type to display median with interval
HighchartsIntervalPoint();

export type PricingBenchmarkGraphProps = {
  data: BenchmarkData;
  withNumerosity?: boolean;
  period?: BenchmarkingPeriods;
};

const axisTitleStyle = getAxisTitleStyle();

const inactiveOpacity = 0.2;

const PricingBenchmarkGraph = ({
  data,
  withNumerosity,
  period = BenchmarkingPeriods.MONTHLY,
}: PricingBenchmarkGraphProps) => {
  const { t } = useTranslation();
  const { locale } = useLocaleContext();
  const { palette } = useTheme();
  const { benchmarkingStage2 } = useBenchmarking();

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const applyStyledHighcharts = useStyledHighcharts();

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        (opts) =>
          mergeOptions(
            {
              chart: {
                type: 'intervalpoint',
                height: withNumerosity ? 410 : 372,
                spacingLeft: 24,
                spacingBottom: withNumerosity ? 12 : 6,
              },
              tooltip: {
                hideDelay: 30,
              },
              xAxis: {
                labels: {
                  enabled: !withNumerosity,
                },
                categories: data.categories,
                title: !withNumerosity
                  ? {
                      text: t('PRODUCT.PRICING_BENCHMARK_CARD.X_AXIS_TITLE'),
                      y: 6,
                      x: -50,
                      style: {
                        color: palette.text.color2,
                        ...axisTitleStyle,
                      },
                    }
                  : {},
              },
              yAxis: {
                labels: {
                  align: 'right', // We use right alignment because useXAxisOffset doesn't work here
                  formatter: yLabelCurrencyFormatter(palette, locale, BENCHMARKING_CURRENCY, 0, 0),
                },
                min: 0,
                offset: 24,
                title: {
                  text: t(
                    'PRODUCT.PRICING_BENCHMARK_CARD.Y_AXIS_TITLE_' +
                      (period === BenchmarkingPeriods.MONTHLY ? 'MONTHLY' : 'ANNUALLY')
                  ),
                  x: -6,
                  style: {
                    color: palette.text.color2,
                    ...axisTitleStyle,
                  },
                },
              },
              plotOptions: {
                series: {
                  custom: {
                    intervalColor: palette.core.color4,
                    youAreHereColor: palette.core.color6,
                  },
                  states: {
                    inactive: {
                      enabled: true,
                      opacity: inactiveOpacity,
                    },
                  },
                  point: {
                    events: {
                      mouseOver() {
                        const point = this;
                        point.series.points.forEach((p) => {
                          if (p !== point) {
                            p.graphic?.attr({ opacity: inactiveOpacity });
                          }
                        });
                      },
                      mouseOut() {
                        const point = this;
                        point.series.points.forEach((p) => {
                          p.graphic?.animate({ opacity: 1 });
                        });
                      },
                    },
                  },
                },
              },
              series: data.series,
            },
            opts
          ),
      ]),
    [applyStyledHighcharts, withNumerosity, data.categories, data.series, t, palette, locale, period]
  );

  const legendItems = [
    {
      id: 'confidence',
      label: t('PRODUCT.PRICING_BENCHMARK_CARD.CONFIDENCE_INTERVAL'),
      custom: <ConfidenceIntervalLegendIcon />,
    },
    ...(benchmarkingStage2 && data.isYouAreHereDataAvailable
      ? [
          {
            id: 'youAreHere',
            label: t('PRODUCT.PRICING_BENCHMARK_CARD.YOU_ARE_HERE'),
            custom: <StarLegendIcon fill={options.plotOptions?.series?.custom?.youAreHereColor} />,
          },
        ]
      : []),
    ...extractLegendItemsFromSeries(options),
  ];

  return (
    <Stack>
      <Stack gap={1.5}>
        {legendItems.length > 0 && (
          <Box alignSelf="flex-end" px={6}>
            <Legend items={legendItems} />
          </Box>
        )}
        <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      </Stack>
      {withNumerosity && <NumerosityGraph data={data} />}
      <HighchartTooltip chart={chart}>
        {(context) => (
          <TooltipWrapper>
            <TooltipContent context={context} period={period} />
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default PricingBenchmarkGraph;
