import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Wizard } from '@vertice/core/src/components/Wizard';
import StartingStep from './Steps/StartingStep';
import EnterEmailStep from './Steps/EnterEmailStep';
import EnterCodeStep from './Steps/EnterCodeStep';
import { useLoggedUser } from '@verticeone/auth/src';
import { SlackIntegrationData } from './types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PREFERENCES_PAGES, ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { Box } from '@mui/material';

const defaultValues: SlackIntegrationData = {
  slackEmail: '',
  accessCode: null,
};

const SlackIntegrationWizard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formMethods = useForm<SlackIntegrationData>({ defaultValues, mode: 'onChange' });

  const { email: userEmail } = useLoggedUser();
  useEffect(() => {
    formMethods.setValue('slackEmail', userEmail);
  }, [formMethods, userEmail]);

  const handleOnClose = useCallback(() => {
    navigate(`${ROUTES.PREFERENCES}/${PREFERENCES_PAGES.USER}`);
  }, [navigate]);

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={(e) => e.preventDefault()} p={10}>
        <Wizard
          initialStep="start"
          onClose={handleOnClose}
          closeButtonText={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.CANCEL_BUTTON_TEXT')}
        >
          <StartingStep stepId="start" />
          <EnterEmailStep stepId="enter_email" />
          <EnterCodeStep stepId="enter_code" />
        </Wizard>
      </Box>
    </FormProvider>
  );
};

export default SlackIntegrationWizard;
