import React from 'react';
import { Grid } from '@mui/material';

import CardHeader from '../../components/CardHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import CardBody from '../../components/CardBody';
import Instance from '../../components/Instance';
import InstanceStats from '../../components/InstanceStats';
import InstanceTable from '../../components/InstanceTable';
import InstanceGraph from '../../components/InstanceGraph';
import ExpandableRow from '../../components/ExpandableRow';
import MissingRiLookupBanner from '../../components/MissingRiLookupBanner';
import CardActionsRow from '../../components/CardActionsRow';
import PurchaseRIButton from '../../components/PurchaseRIButton';
import TargetsAndControlButton from '../../components/TargetsAndControlButton';
import OfferingsProvider from '../../components/providers/OfferingsProvider';

const InstanceLayout = () => (
  <Instance>
    <CardHeader>
      <CardHeaderTitle />
    </CardHeader>
    <MissingRiLookupBanner />
    <CardBody>
      <InstanceStats />
      <ExpandableRow>
        <Grid container spacing={4} direction="row" alignItems="stretch">
          <Grid item xs={12} md={12} lg={4}>
            <InstanceTable />
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <OfferingsProvider
              defaultFilter={{
                offeringType: 'No Upfront',
                duration: 31536000,
              }}
            >
              <InstanceGraph />
            </OfferingsProvider>
          </Grid>
        </Grid>
      </ExpandableRow>
    </CardBody>
    <CardActionsRow>
      <TargetsAndControlButton />
      <PurchaseRIButton />
    </CardActionsRow>
  </Instance>
);

export default InstanceLayout;
