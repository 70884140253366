import useAwsRum from '@vertice/hooks/src/useAwsRum';
import { enableRum, rumConfigs, rumCredentials } from '../config';
import { Outlet } from 'react-router-dom';
import SnackbarProvider from '@vertice/slices/src/slices/Notifications/Provider';
import { ErrorBoundary } from 'react-error-boundary';
import { SmallScreenWarning } from '@vertice/components';
import { ErrorFallback } from '../components/ErrorFallback/ErrorFallback';
import { SimpleDialogContextProvider } from '@verticeone/design-system/src';

declare function cwr(operation: string, payload: any): void;

export const ProviderTree = () => {
  useAwsRum({ config: rumConfigs, rumCredentials, enableRum });

  const errorHandler = (error: Error, info: { componentStack: string }) => {
    if (typeof cwr === 'function') {
      cwr('recordError', error);
    }
  };

  return (
    <SnackbarProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <SimpleDialogContextProvider>
          <Outlet />
        </SimpleDialogContextProvider>
      </ErrorBoundary>
      <SmallScreenWarning />
    </SnackbarProvider>
  );
};
