import React, { FC } from 'react';

import { PropsWithContract } from '../types';
import { Field } from './Field';
import { useFormatDate } from '@verticeone/utils/formatting';

export const RenewalDate: FC<PropsWithContract> = ({ contract }) => {
  const renewalDate = contract.parts.contractual?.lifecycle?.renewalDate;
  const formatDate = useFormatDate();
  return (
    <Field
      labelTranslationKey="ENTITIES.CONTRACT.LABELS.RENEWAL_DATE"
      value={renewalDate ? formatDate(renewalDate) : undefined}
    />
  );
};
