import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import { FilterView } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Loader, Tabs } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';
import StageIndicator from './StageIndicator';
import { ContractGridType, DASHBOARD_FILTERS, DashboardStage } from './types';
import { Box } from '@mui/material';
import { Divider } from '@verticeone/design-system/src';
import { getFilterKey } from '@vertice/core/src/modules/saas/contract/components/ContractList/functions';
import Requests from './Requests';
import Contracts from './Contracts';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const defaultFilterView = DASHBOARD_FILTERS.IN_PROGRESS;

const DashboardTable: FC = () => {
  const { t } = useTranslation();

  const [activeFilterView, setActiveFilterView] = useState<FilterView>(defaultFilterView);

  const filterType = useMemo(() => {
    for (const [type, filter] of Object.entries(DASHBOARD_FILTERS)) {
      if (getFilterKey(filter) === getFilterKey(activeFilterView)) {
        return type as DashboardStage;
      }
    }
  }, [activeFilterView]);

  return (
    <>
      <Box px={10}>
        <Tabs
          color="primary"
          value={filterType}
          onChange={(_: SyntheticEvent, newValue: DashboardStage) => setActiveFilterView(DASHBOARD_FILTERS[newValue])}
        >
          <Tab
            label={t(`DASHBOARD.TABS.IN_PROGRESS`)}
            value={ContractGridType.DashboardInProgress}
            icon={<StageIndicator stage="IN_PROGRESS" />}
            iconPosition="start"
          />
          <Tab
            label={t(`DASHBOARD.TABS.UPCOMING`)}
            value={ContractGridType.DashboardUpcoming}
            icon={<StageIndicator stage="UPCOMING" />}
            iconPosition="start"
          />
        </Tabs>
      </Box>

      <Box>
        <Divider />
        <TableContent filterView={activeFilterView} filterType={filterType} />
      </Box>
    </>
  );
};

type TableContentProps = {
  filterType: DashboardStage | undefined;
  filterView: FilterView;
};
const TableContent: FC<TableContentProps> = ({ filterType, filterView }) => {
  const { getFeature, isLoading } = useFeatures();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel | undefined>({ pageSize: 25, page: 0 });

  if (isLoading) {
    return <Loader />;
  }

  if (getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.legacyPipeline) {
    return <Contracts paginationModel={paginationModel} setPaginationModel={setPaginationModel} filter={filterView} />;
  }

  return (
    <>
      {filterType === 'IN_PROGRESS' && (
        <Requests paginationModel={paginationModel} setPaginationModel={setPaginationModel} />
      )}
      {filterType === 'UPCOMING' && (
        <Contracts paginationModel={paginationModel} setPaginationModel={setPaginationModel} filter={filterView} />
      )}
    </>
  );
};

export default DashboardTable;
