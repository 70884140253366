import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices';
import { useLazyRiListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

import type { RDSDataEnhanced } from './useRDSData';
import type { BaseFilter, UseOfferingData } from '../../../types';
import { productToDBEMap } from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/Rds/products';

type RDSFilter = Pick<BaseFilter, 'duration' | 'offeringType'>;

export const useRDSRiOfferingData = (): UseOfferingData<RDSDataEnhanced, RDSFilter> => {
  const { accountId } = useSelector(getAccount);
  const [getRIListOfferings, { isFetching }] = useLazyRiListOfferingsQuery();

  const findKeyInProductToDBEMap = (databaseEngine: string, databaseEdition: string, licenseModel: string) => {
    for (let [key, value] of Object.entries(productToDBEMap)) {
      if (
        value.databaseEngine === databaseEngine &&
        value.databaseEdition === databaseEdition &&
        value.licenseModel === licenseModel
      ) {
        return key;
      }
    }
    return databaseEngine.toLowerCase();
  };

  return async (item: RDSDataEnhanced, filter: RDSFilter) => {
    const productDescription = findKeyInProductToDBEMap(item.databaseEngine, item.databaseEdition, item.licenseModel);

    const response = await getRIListOfferings({
      service: 'rds',
      accountId: accountId!,
      region: item.region,
      instanceFamily: `db.${item.instanceFamily}`,
      instanceSize: item.sizeFlexible ? item.flexGroupMinSize : item.instanceSize,
      productDescription,
      multiAZ: false,
      ...filter,
    });

    return {
      data: response.data,
      error: response.error,
      isFetching,
    };
  };
};
