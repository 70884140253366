import { Slide, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuthType, useAuthentication } from '@verticeone/auth/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useState } from 'react';
import { InvertedThemeProvider } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const AssumeRoleWarningBannerContent = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { user, signOut } = useAuthentication();
  const { accountDetails } = useAccountContext();
  const roles = user?.roles || [];
  const customerAccountName = accountDetails?.name || '';
  const [dismissed, setDismissed] = useState(false);
  return (
    <Slide in={!dismissed} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        bgcolor={palette.error.color2}
        alignItems="center"
        position="fixed"
        left={0}
        width="100%"
        zIndex={1202}
      >
        <InvertedThemeProvider>
          <Text variant="body-bold" size="S" color="text1" align="center" p={1} flex={1}>
            {t('ASSUME_ROLE.WARNING_BANNER', { customerAccountName, roleName: roles.join(', ') })}
          </Text>
          <Button variant="outline" color="transparent" size="XS" onClick={() => void signOut()}>
            {t('AUTH.LOG_OUT')}
          </Button>
          <Button
            variant="plain"
            color="transparent"
            size="S"
            title={t('ASSUME_ROLE.DISMISS_BANNER')}
            onClick={() => setDismissed(true)}
          >
            <IconWrapper icon={CloseOutlinedIcon} size="S" />
          </Button>
        </InvertedThemeProvider>
      </Stack>
    </Slide>
  );
};

const AssumeRoleWarningBanner = () => {
  const { type: authType } = useAuthentication();
  return authType === AuthType.ASSUME_ROLE ? <AssumeRoleWarningBannerContent /> : null;
};

export default AssumeRoleWarningBanner;
