/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { addDays, addMonths, format, startOfMonth } from 'date-fns';
import {
  Stack,
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export type FilterOption = '6_months' | '3_months' | '1_month';

type TopProductsBySpendFilterProps = {
  selected: FilterOption;
  onChange: (selected: FilterOption) => void;
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: 8,
  [`&.${toggleButtonClasses.root}`]: {
    padding: '7px 14px',
    color: theme.palette.neutral.color2,
    opacity: 0.4,
  },

  [`&.${toggleButtonClasses.root}:hover`]: {
    backgroundColor: 'transparent',
  },

  [`&.Mui-selected`]: {
    backgroundColor: 'transparent',
    opacity: 1,
  },
}));

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: '8px 24px',
  gap: '24px',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.core.color1,
}));

const monthBeginning = startOfMonth(new Date());
const DATE_FORMAT = 'MMM yyyy';

const TopProductsBySpendFilter = ({ selected, onChange }: TopProductsBySpendFilterProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();

  const handleFilterChange = (event: unknown, value: FilterOption) => {
    if (value !== null) {
      onChange(value);
    }
  };

  const createTooltipLabel = (monthWindow: number) => {
    const start = format(addMonths(monthBeginning, -monthWindow), DATE_FORMAT);
    const end = format(addDays(monthBeginning, -1), DATE_FORMAT);

    return monthWindow > 1 ? `${start} - ${end}` : start;
  };

  return (
    <StyledContainer direction="row">
      <Typography variant="button-s" color={palette.text.color2}>
        {t('TOP_PRODUCTS_BY_SPEND.FILTER.SPEND_IN_PREVIOUS')}
      </Typography>
      <ToggleButtonGroup size="small" value={selected} exclusive onChange={handleFilterChange}>
        <StyledToggleButton value="6_months">
          <Tooltip title={createTooltipLabel(6)}>
            <Typography variant="button-s">{t('TOP_PRODUCTS_BY_SPEND.FILTER.6_MONTHS')}</Typography>
          </Tooltip>
        </StyledToggleButton>
        <StyledToggleButton value="3_months">
          <Tooltip title={createTooltipLabel(3)}>
            <Typography variant="button-s">{t('TOP_PRODUCTS_BY_SPEND.FILTER.3_MONTHS')}</Typography>
          </Tooltip>
        </StyledToggleButton>
        <StyledToggleButton value="1_month">
          <Tooltip title={createTooltipLabel(1)}>
            <Typography variant="button-s">{t('TOP_PRODUCTS_BY_SPEND.FILTER.1_MONTH')}</Typography>
          </Tooltip>
        </StyledToggleButton>
      </ToggleButtonGroup>
    </StyledContainer>
  );
};

export default TopProductsBySpendFilter;
