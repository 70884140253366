import { GridGroupingColDefOverride } from '@mui/x-data-grid-pro';
import { RequestRow } from './types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ParentGroupingCell } from './components/cells/GroupCells';
import { DateCell } from './components/cells/DateCell';
import { RequestStatusCell } from './components/cells/RequestStatusCell';
import { AssigneesCell } from './components/cells/AssigneesCell';
import { DataGridColDef } from '@verticeone/design-system/src';
import {
  RequestSkeletonCell,
  BaseSkeletonCell,
  AssigneesSkeletonCell,
  ActionsSkeletonCell,
} from './components/cells/SkeletonCells';
import { KeyDateCell, getKeyDates } from './components/cells/KeyDateCell';
import { ActionCells } from './components/cells/ActionCells';
import { getKeyCosts, KeyCostCell } from './components/cells/KeyCostCell';

const useCommonColumns = (): DataGridColDef<RequestRow>[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });

  return useMemo(() => {
    const columns: DataGridColDef<RequestRow>[] = [
      {
        field: 'lastActivityAt',
        headerName: t('COLUMNS.LAST_ACTIVITY_AT'),
        valueGetter: ({ row }) => row.lastActivityAt || undefined,
        renderCell: ({ value }) => <DateCell date={value} />,
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 175,
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: t('COLUMNS.STATUS'),
        renderCell: ({ row }) =>
          row.isPartial ? (
            <BaseSkeletonCell />
          ) : (
            <RequestStatusCell
              status={row.status!}
              customValue={row.resultType}
              activeTasks={row.activeTasks}
              completedTasks={row.completedTasks}
            />
          ),
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 235,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'assignedTo',
        headerName: t('COLUMNS.ASSIGNED_TO'),
        renderCell: ({ row }) =>
          row.isPartial ? <AssigneesSkeletonCell /> : <AssigneesCell assignees={row.taskAssignees} />,
        renderSkeletonCell: () => <AssigneesSkeletonCell />,
        minWidth: 140,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'keyDate',
        headerName: t('COLUMNS.KEY_DATE'),
        valueGetter: ({ row }) => {
          const keyDates = getKeyDates(row);
          return keyDates.length > 0 ? keyDates[0].value : '-';
        },
        renderCell: ({ row }) => <KeyDateCell row={row} />,
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 140,
        disableColumnMenu: true,
        flex: 1,
      },
    ];

    return columns;
  }, [t]);
};

const useKeyCostColumn = (): DataGridColDef<RequestRow> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });

  return useMemo(() => {
    return {
      field: 'keyCost',
      headerName: t('COLUMNS.KEY_COST'),
      valueGetter: ({ row }) => {
        const keyCosts = getKeyCosts(row.keyCosts);
        return keyCosts.length > 0 ? keyCosts[keyCosts.length - 1].value : '-';
      },
      renderCell: ({ row }) => <KeyCostCell row={row} />,
      renderSkeletonCell: () => <BaseSkeletonCell />,
      align: 'left',
      headerAlign: 'left',
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
    };
  }, [t]);
};

const useActionColumn = (): DataGridColDef<RequestRow> => {
  return useMemo(() => {
    return {
      field: 'actions',
      type: 'actions',
      minWidth: 110,
      renderSkeletonCell: () => <ActionsSkeletonCell />,
      renderCell: ({ row }) => (row.isPartial ? <ActionsSkeletonCell /> : <ActionCells row={row} />),
    };
  }, []);
};

export const useRequestsColumns = (): DataGridColDef<RequestRow>[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });
  const commonColumns = useCommonColumns();
  const keyCostColumn = useKeyCostColumn();
  const actionColumn = useActionColumn();

  return useMemo(() => {
    const columns: DataGridColDef<RequestRow>[] = [
      ...commonColumns,
      keyCostColumn,
      {
        field: 'started',
        headerName: t('COLUMNS.STARTED'),
        valueGetter: ({ row }) => row.createdAt || undefined,
        renderCell: ({ value }) => <DateCell date={value} />,
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 120,
        flex: 1,
        disableColumnMenu: true,
      },
      actionColumn,
    ];

    return columns;
  }, [t, commonColumns, keyCostColumn, actionColumn]);
};

export const useRequestsDashboardColumns = (): DataGridColDef<RequestRow>[] => {
  const commonColumns = useCommonColumns();
  const keyCostColumn = useKeyCostColumn();
  const actionColumn = useActionColumn();

  return useMemo(() => [keyCostColumn, ...commonColumns, actionColumn], [keyCostColumn, commonColumns, actionColumn]);
};

export const useGroupingColumn = (): GridGroupingColDefOverride<RequestRow> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });

  return useMemo(
    () => ({
      renderCell: (params) => <ParentGroupingCell {...params} />,
      valueGetter: ({ row }) => row.requestName,
      sortable: true,
      minWidth: 300,
      flex: 1,
      headerName: t('COLUMNS.REQUEST'),
      renderSkeletonCell: () => <RequestSkeletonCell />,
      colSpan: ({ row }) => {
        if (row.parentId) {
          return 3;
        }
        return undefined;
      },
    }),
    [t]
  );
};
