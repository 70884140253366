import { TooltipFormatterContextObject } from 'highcharts';

export type SortTooltipPointsType = (
  point1: TooltipFormatterContextObject,
  point2: TooltipFormatterContextObject
) => number;

export const sortTooltipPointsFunction =
  (orderedCategories: string[], revertNegative?: boolean) =>
  (point1: TooltipFormatterContextObject, point2: TooltipFormatterContextObject) => {
    const firstValue = point1.y!;
    const secondValue = point2.y!;
    const firstIndex = orderedCategories.indexOf(point1.series.name);
    const secondIndex = orderedCategories.indexOf(point2.series.name);

    if (firstValue > 0 && secondValue > 0) {
      return firstIndex - secondIndex;
    }

    if (firstValue < 0 && secondValue < 0) {
      if (revertNegative) {
        return secondIndex - firstIndex;
      }
      return firstIndex - secondIndex;
    }
    return secondValue - firstValue;
  };
