import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

import { DesignSystemSize } from '../../types';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { IconWrapper } from '../IconWrapper';
import { BreadcrumbBaseItem } from './types';
import { isLabelString } from './utils';

const CurrentPageWrapper = styled(Stack)(({ theme }) => ({
  '& .copy-link-icon': {
    display: 'none',
  },
  '&:hover': {
    cursor: 'pointer',
    '& .copy-link-icon': {
      display: 'flex',
    },
    '& .MuiTypography-root': {
      color: theme.palette.neutral.hover.color1,
    },
  },
}));

const TooltipWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

type CurrentPageItemProps = Required<Pick<BreadcrumbBaseItem, 'label' | 'copyToClipboardUrl'>> & {
  size: DesignSystemSize;
};

export const CurrentPageItem = ({ label, copyToClipboardUrl, size }: CurrentPageItemProps) => {
  const { t } = useTranslation();

  const [tooltipText, setTooltipText] = useState(t('CORE.COMPONENTS.BREADCRUMBS_V2.COPY_TO_CLIPBOARD'));

  const copyToClipboard = (url: string) => {
    void navigator.clipboard.writeText(url);

    setTooltipText(t('CORE.COMPONENTS.BREADCRUMBS_V2.COPIED_TO_CLIPBOARD'));
    setTimeout(() => {
      setTooltipText(t('CORE.COMPONENTS.BREADCRUMBS_V2.COPY_TO_CLIPBOARD'));
    }, 2000);
  };

  if (isLabelString(label))
    return (
      <CurrentPageWrapper direction="row" alignItems="center">
        <Text variant="button-bold" size={size} color="neutral1">
          {label}
        </Text>
        <TooltipWrapper className="copy-link-icon">
          <Tooltip content={tooltipText} size="XS" noArrow>
            <IconWrapper icon={LinkIcon} size={size} onClick={() => copyToClipboard(copyToClipboardUrl)} />
          </Tooltip>
        </TooltipWrapper>
      </CurrentPageWrapper>
    );

  return label;
};
