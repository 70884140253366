import React from 'react';
import { Grid, Stack } from '@mui/material';
import ContractOwnerFormEntry from './form/workflow/ContractOwnerFormEntry';
import ContractWatchersFormEntry from './form/workflow/ContractWatchersFormEntry';
import { useTranslation } from 'react-i18next';
import ListUsersReadOnlyField from './form/workflow/ListUsersReadOnlyField';

const WorkflowTab = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Stack p={6}>
        <Grid container direction="row" spacing={8}>
          <Grid item xs={12} md={6}>
            <ListUsersReadOnlyField
              name="classification.departmentOwner"
              label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT_OWNERS')}
              emptyValueLabel={t('ENTITIES.CONTRACT.PLACEHOLDERS.OWNERS_NO_ITEMS')}
              width={12}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ListUsersReadOnlyField
              name="classification.departmentWatchers"
              label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT_WATCHERS')}
              emptyValueLabel={t('ENTITIES.CONTRACT.PLACEHOLDERS.WATCHERS_NO_ITEMS')}
              width={12}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack p={6}>
        <Grid container direction="row" spacing={8}>
          <Grid item xs={12} md={6}>
            <ContractOwnerFormEntry
              name="classification.contractOwner"
              label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_OWNERS')}
              width={12}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContractWatchersFormEntry
              name="classification.contractWatchers"
              label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_WATCHERS')}
              width={12}
            />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default WorkflowTab;
