import { AccountUser } from '@vertice/slices';
import { getFullName } from '@verticeone/utils/formatting';
import { SelectOption } from './types';

export const getSelectOptions = (users: AccountUser[], usersToExclude?: { userId: string }[]) => {
  const usersToExcludeIds = usersToExclude?.map((watcher: { userId: string }) => watcher.userId) ?? [];

  // Filter excluded users out and map into SelectOption type
  return users.reduce((acc: SelectOption[], user) => {
    if (!usersToExcludeIds.includes(user.userId)) {
      acc.push({
        value: user.userId,
        label: getFullName(user) || user.email!,
        user,
      });
    }
    return acc;
  }, []);
};
