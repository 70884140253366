import React from 'react';
import Stack from '@mui/material/Stack';

type ToolbarRowProps = {
  children: React.ReactNode;
};

const ToolbarRow = ({ children }: ToolbarRowProps) => <Stack direction="row" children={children} />;

export default ToolbarRow;
