import { useMemo } from 'react';
import { useGetExchangeRatesQuery } from '@vertice/slices';
import { useTranslation } from 'react-i18next';
import { useGetCurrencyParts } from '@verticeone/utils/currency';
import { useFormatNumber } from '@verticeone/utils/formatting';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type ExchangeRatesProps = {
  currencies: string[];
  exchangeRates?: { [arg: string]: any };
};

const defaultCurrencies = ['GBP', 'EUR'];

export const getRates = ({ currencies, exchangeRates }: ExchangeRatesProps) => {
  if (exchangeRates === undefined) {
    return undefined;
  }

  return currencies
    .filter((currency) => currency && currency !== 'USD')
    .map((currency) => ({
      currency,
      rate: exchangeRates[currency],
    }));
};

export const useExchangeCurrencyHint = () => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.TOOLTIPS.EXCHANGE_CURRENCY_HINT' });
  const getCurrencyParts = useGetCurrencyParts();
  const formatNumber = useFormatNumber();

  const { data: exchangeRates, isLoading } = useGetExchangeRatesQuery({ accountId: accountId! }, { skip: !accountId });

  return useMemo(() => {
    const getExchangeCurrencyHint = (currencies?: string[]) => {
      const rates = getRates({
        currencies: currencies ?? defaultCurrencies,
        exchangeRates: exchangeRates?.rates,
      });

      const formattedRates = rates
        ?.map((item) => {
          const symbol = getCurrencyParts(item.currency, 0).currency;
          const formattedRate = formatNumber(item.rate, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return `$/${symbol} ${formattedRate}`;
        })
        .join(', ');

      if (!formattedRates) {
        return t(currencies ? 'SINGLE_VALUE_LOADING' : 'GENERIC_LOADING');
      }

      return t(currencies ? 'SINGLE_VALUE' : 'GENERIC', { rateList: formattedRates });
    };

    return {
      getExchangeCurrencyHint,
      isLoading,
    };
  }, [isLoading, exchangeRates?.rates, t, getCurrencyParts, formatNumber]);
};
