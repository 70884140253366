import React, { DetailedHTMLProps, FormHTMLAttributes } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { DefaultValues } from 'react-hook-form/dist/types/form';

type BaseFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
type FormProps<T extends FieldValues> = Omit<BaseFormProps, 'onSubmit'> & {
  onSubmit: (form: T) => void;
  defaultValues: DefaultValues<T>;
  children: React.ReactNode;
};

const Form = <T extends FieldValues>({ children, defaultValues, onSubmit, ...otherProps }: FormProps<T>) => {
  const methods = useForm<T>({ defaultValues: { ...defaultValues } });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...otherProps}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
