import { Donut, DonutProps } from './Donut';
import { Pie, PieProps } from './Pie';

export type GaugeProps = (DonutProps | PieProps) & {
  variant?: 'pie' | 'donut';
};

export const Gauge = ({ variant, ...otherProps }: GaugeProps) => {
  return variant === 'pie' ? <Pie {...otherProps} /> : <Donut {...otherProps} />;
};
