import { forwardRef, FunctionComponent, SVGProps } from 'react';
import type { SvgIconComponent } from '@mui/icons-material';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import type { CustomizedProps, DesignSystemSize } from '../../types';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import styled from '@mui/material/styles/styled';
import { svgIconClasses } from '@mui/material/SvgIcon';

export type BaseIconProps = CustomizedProps & SvgIconProps;

export type IconWrapperProps = {
  size?: DesignSystemSize;
  icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
} & BaseIconProps;

const StyledIconWrapper = styled('div')<{ $size: DesignSystemSize }>(({ $size }) => ({
  display: 'flex',
  [`& .${svgIconClasses.root}, svg`]: {
    width: baseSizes[$size],
    height: baseSizes[$size],
  },
}));

export const IconWrapper = forwardRef<SVGSVGElement, IconWrapperProps>(
  ({ size = 'M', icon: Icon, ...otherProps }, ref) => (
    <StyledIconWrapper $size={size}>
      <Icon {...otherProps} ref={ref} />
    </StyledIconWrapper>
  )
);
