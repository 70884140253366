import { legalApi as api } from '../../api/legalAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVendorSecurity: build.query<GetVendorSecurityApiResponse, GetVendorSecurityApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/vendors/${queryArg.vendorId}/securityTasks` }),
    }),
    requestVendorSecurityStandardQuestionnaires: build.mutation<
      RequestVendorSecurityStandardQuestionnairesApiResponse,
      RequestVendorSecurityStandardQuestionnairesApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/vendors/${queryArg.vendorId}/standardQuestionnaires`,
        method: 'POST',
      }),
    }),
    legalSubscription: build.mutation<LegalSubscriptionApiResponse, LegalSubscriptionApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/features/legalSubscription`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as legalAPICodegen };
export type GetVendorSecurityApiResponse = /** status 200 Vendor Legal security info
 */ VendorLegalSecurityTasks;
export type GetVendorSecurityApiArg = {
  accountId: UuidIdentifier;
  vendorId: UuidIdentifier;
};
export type RequestVendorSecurityStandardQuestionnairesApiResponse = /** status 200 Request standard Questioner
 */ undefined;
export type RequestVendorSecurityStandardQuestionnairesApiArg = {
  accountId: UuidIdentifier;
  vendorId: UuidIdentifier;
};
export type LegalSubscriptionApiResponse = /** status 200 Request legal insights subscription
 */ undefined;
export type LegalSubscriptionApiArg = {
  accountId: UuidIdentifier;
};
export type Timestamp = string | null;
export type SecurityTask = {
  accountId?: string;
  vendorId?: string;
  recordType?: string;
  uploadedDate?: Timestamp;
  taskType?: 'CUSTOM' | 'STANDARD';
  taskStatus?: 'OPEN' | 'COMPLETED';
};
export type VendorLegalSecurityTasks = {
  tasks?: SecurityTask[];
};
export type UuidIdentifier = string;
export const {
  useGetVendorSecurityQuery,
  useLazyGetVendorSecurityQuery,
  useRequestVendorSecurityStandardQuestionnairesMutation,
  useLegalSubscriptionMutation,
} = injectedRtkApi;
