import { CommonIntakeFormFormData } from '../types';
import { useFormContext } from 'react-hook-form';
import { useGetAccountSettingsQuery } from '@vertice/slices';
import { useEffect } from 'react';

export const useSetFormCurrency = (accountId?: string) => {
  const { setValue } = useFormContext<CommonIntakeFormFormData>();
  const { data: accountSettings } = useGetAccountSettingsQuery({ accountId: accountId! }, { skip: !accountId });
  useEffect(() => {
    if (accountSettings?.preferredCurrency) {
      setValue('contractCurrency', accountSettings?.preferredCurrency);
    }
  }, [accountSettings, setValue]);
};
