import { useTranslation } from 'react-i18next';
import i18n from '../../../../translate';

export const useErrorMessage = () => {
  const { t } = useTranslation(undefined, { i18n });

  const getErrorMessage = ({
    error,
    type,
    dateFrom,
    dateTo,
  }: {
    error: string | null;
    type: 'from' | 'to';
    dateFrom?: string | null;
    dateTo?: string | null;
  }) => {
    if (!error) return undefined;

    switch (error) {
      case 'maxDate': {
        if (type === 'from') {
          return t('DATE_RANGE_PICKER.ERROR.DATE_FROM_MAX_DATE', { date: dateTo });
        }
        return t('DATE_RANGE_PICKER.ERROR.DATE_TO_MAX_DATE', { date: dateTo });
      }
      case 'minDate': {
        if (type === 'from') {
          return t('DATE_RANGE_PICKER.ERROR.DATE_FROM_MIN_DATE', { date: dateFrom });
        }
        return t('DATE_RANGE_PICKER.ERROR.DATE_TO_MIN_DATE', { date: dateFrom });
      }
      case 'invalidDate':
        return t('DATE_RANGE_PICKER.ERROR.INVALID_DATE');
      default:
        return error;
    }
  };

  return {
    getErrorMessage,
  };
};
