import { Offer } from '../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { useOfferTotalCostMutation } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useEffect, useState } from 'react';

export const useOfferTotalCost = (offer?: Offer) => {
  const [calculateTotalCost] = useOfferTotalCostMutation();
  const [offerTotalCost, setOfferTotalCost] = useState<number | null>(null);

  useEffect(() => {
    if (offer === undefined) {
      return;
    }
    void calculateTotalCost({
      body: [offer],
    })
      .unwrap()
      .then((response) => {
        setOfferTotalCost(response[0]);
      });
  }, [calculateTotalCost, offer]);

  return offerTotalCost;
};
