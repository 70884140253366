import { DateRange, PickersShortcutsProps } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { Stack, useTheme } from '@mui/material';
import { SIZE_DEFINITION } from '../constants';
import { Button } from '../../Button';
import * as React from 'react';
import { useStaticDateRangePickerContext } from '../context/StaticDateRangePickerContext';

type CustomShortcutsProps = PickersShortcutsProps<DateRange<Dayjs>>;

const CustomShortcuts = (props: CustomShortcutsProps) => {
  const { size } = useStaticDateRangePickerContext();
  const { items, onChange, isValid } = props;
  const { palette, spacing } = useTheme();
  const shortcutsPadding = SIZE_DEFINITION[size].shortcutsPadding.map((padding) => spacing(padding)).join(' ');

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, 'accept', item);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Stack
      gap={SIZE_DEFINITION[size].shortcutsGap}
      padding={shortcutsPadding}
      borderRight={`1px solid ${palette.core.color3}`}
    >
      {resolvedItems.map((item) => {
        return (
          <Button
            key={item.label}
            variant="plain"
            sx={{
              '&.MuiButton-root, &.MuiButton[disabled]': {
                padding: 0,
                justifyContent: 'flex-start',
                backgroundColor: 'transparent !important',
                border: 'none',
                '&:hover': {
                  padding: 0,
                },
              },
            }}
            size={size}
            {...item}
          >
            {item.label}
          </Button>
        );
      })}
    </Stack>
  );
};

export default CustomShortcuts;
