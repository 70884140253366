import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { Link } from '@verticeone/design-system/src';
import { AWSLogo } from '@vertice/assets';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import SentimentSatisfiedTwoToneIcon from '@mui/icons-material/SentimentSatisfiedTwoTone';
import CloudTwoToneIcon from '@mui/icons-material/CloudTwoTone';
import { Button } from '@verticeone/design-system/src';
import { AWS_STEPS, AWSStepProps } from '../AWSSetupWizard';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import AWSTextItemWithIcon from '../Components/AWSTextItemWithIcon';
import { Text } from '@verticeone/design-system/src';
import { TextField } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

interface AWSPermissionProps extends AWSStepProps {}

const TERRAFORM_REPOSITORY = 'https://github.com/VerticeOne/terraform-aws-vertice-integration';
const CLOUDFORMATION_REPOSITORY = 'https://github.com/VerticeOne/cloudformation-aws-vertice-cco-integration';

const AWSPermission = ({ setStep }: AWSPermissionProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const onBack = () => setStep(AWS_STEPS.START);
  const onNext = () => setStep(AWS_STEPS.COST_AND_USAGE);

  const header = (
    <Text variant="heading" size="M">
      {t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.TITLE')}
    </Text>
  );

  const content = (
    <Stack gap={6}>
      <Stack gap={6}>
        <AWSTextItemWithIcon
          icon={<AWSLogo />}
          title={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.AWS_PARTNER_TITLE')}
          text={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.AWS_PARTNER_TEXT')}
        />
        <AWSTextItemWithIcon
          icon={<SecurityTwoToneIcon color="primary" />}
          title={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.SECURITY_TITLE')}
          text={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.SECURITY_TEXT')}
        />
        <AWSTextItemWithIcon
          icon={<SentimentSatisfiedTwoToneIcon color="primary" />}
          title={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.FIT_FOR_PURPOSE_TITLE')}
          text={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.FIT_FOR_PURPOSE_TEXT')}
        />
        <AWSTextItemWithIcon
          icon={<CloudTwoToneIcon color="primary" />}
          title={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.CLOUD_TITLE')}
          text={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.CLOUD_TEXT')}
        />
      </Stack>
      <Divider />
      <Stack gap={6}>
        <Text variant="body-regular">{t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.TEMPLATE_TEXT')}</Text>
        <Link target="blank" withArrow color="primary" href={TERRAFORM_REPOSITORY}>
          {t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.TERRAFORM')}
        </Link>
        <Link target="blank" withArrow color="primary" href={CLOUDFORMATION_REPOSITORY}>
          {t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.CLOUD_FORMATION')}
        </Link>
        <Text variant="body-regular">{t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.EXTERNAL_ID_TEXT')}</Text>
        <TextField
          label={t('PREFERENCES.INTEGRATIONS.AWS.PERMISSIONS.EXTERNAL_ID')}
          value={accountId}
          fullWidth
          disabled
        />
      </Stack>
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption onClick={onNext}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
    />
  );
};

export default AWSPermission;
