import React from 'react';
import styled from '@mui/material/styles/styled';
import MuiTextField from '@mui/material/TextField';
import { rgba } from 'polished';
import { getTextVariantStyle } from '../../Text/utils';
import { lineHeightToHeight } from '../utils';
import sizeDefinitions, { SizeDef } from './sizeDefinitions';
import * as common from './common';
import { forwardRef } from 'react';

const LABEL_SIZE_MULTIPLIER = 0.7;
const BORDER_WIDTH = 1;

const getLabelStyle = ({ paddingY, paddingX, labelLineHeight, gapY }: SizeDef, shrinked: boolean) => {
  const left = paddingX - BORDER_WIDTH;
  const top = shrinked ? -labelLineHeight * 0.4 : paddingY - BORDER_WIDTH;
  return {
    transform: `translate(${left}px, ${top}px) scale(${shrinked ? LABEL_SIZE_MULTIPLIER : 1})`,
  };
};

/** WARNING: Don't use directly in apps. Rather use via TextField with a specified variant. */
const StyledOutlinedTextField = styled(MuiTextField)<common.StyledTextFieldProps>((props) => {
  const {
    theme: { palette },
    $color,
    $size,
    $internalValue,
  } = props;
  const textStyle = getTextVariantStyle({ variant: 'body-regular', size: $size });
  return {
    '.MuiOutlinedInput-root': {
      borderRadius: 8,
      ...textStyle,
    },

    // Outline
    '.MuiOutlinedInput-notchedOutline': {
      border: `${BORDER_WIDTH}px solid ${
        $internalValue || $internalValue === 0 ? palette.core.color6 : palette.core.color3
      }`,
      padding: `0 ${sizeDefinitions[$size].paddingX - BORDER_WIDTH}px 0 ${
        sizeDefinitions[$size].paddingX - BORDER_WIDTH - 5
      }px`,
      transition: 'border 200ms cubic-bezier(0.0,0,0.2,1)',
    },
    '.MuiOutlinedInput-notchedOutline legend': {
      fontSize: `${LABEL_SIZE_MULTIPLIER}em`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette[$color].hover.color2,
    },
    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `${BORDER_WIDTH}px solid ${palette[$color].color3}`,
    },
    '.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.error.color2,
    },
    '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.inactive.color2,
    },

    // Input

    '.MuiOutlinedInput-input': {
      // Caret in HTML Input forces effective lineHeight to be at least cca 115% of the fontSize, even if
      // you manually set <115% lineHeight. To fully support and respect <115% lineHeights, we explicitly set the
      // height of the input to the lineHeight. We use converter to turn lineHeight percentage (relative to fontSize)
      // into something that can be fed to the height CSS prop.
      height: lineHeightToHeight(textStyle.lineHeight ?? 1),
      // Simulate innerBorder from Figma by subtracting border width from padding.
      ...common.subtractPadding(common.makePadding(sizeDefinitions[$size], false), BORDER_WIDTH),
      ...textStyle,
      color: palette.text.color1,
    },
    '.MuiOutlinedInput-input::placeholder': {
      color: palette.text.color3,
      opacity: 1,
    },
    '.MuiOutlinedInput-input::selection': {
      background: rgba(palette[$color].color2, 0.2),
    },
    '.MuiOutlinedInput-input.Mui-disabled': {
      color: palette.inactive.color2,
    },
    '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input::selection': {
      background: rgba(palette.error.color1, 0.2),
    },

    // Label

    '&:hover .MuiInputLabel-outlined.MuiInputLabel-shrink:not(.Mui-focused)': {
      color: palette[$color].hover.color2,
    },
    '.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      color: palette.text.color3,
    },
    '.MuiInputLabel-outlined:not(.Mui-focused).MuiFormLabel-filled': {
      color: palette.core.color6,
    },
    '.MuiInputLabel-outlined.Mui-focused': {
      color: palette[$color].color3,
    },
    '.MuiInputLabel-outlined.MuiInputLabel-outlined': {
      ...textStyle,
      ...getLabelStyle(sizeDefinitions[$size], false),
    },
    '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      ...getLabelStyle(sizeDefinitions[$size], true),
    },
    '.MuiInputLabel-outlined.Mui-error.Mui-error.Mui-error': {
      color: palette.error.color1,
    },
    '.MuiInputLabel-outlined.Mui-disabled.Mui-disabled.Mui-disabled': {
      color: palette.inactive.color2,
    },

    // Shared
    ...common.inputBaseAdornedStyles({ size: $size }),
    ...common.helperTextStyles(props),
    ...common.spinButtonStyles,
  };
});

const OutlinedTextField = forwardRef<HTMLDivElement, common.TextFieldProps>(({ size, color, ...otherProps }, ref) => (
  <StyledOutlinedTextField ref={ref} $size={size} $color={color} {...otherProps} />
));

export default OutlinedTextField;
