import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system/src';
import { ChipButton } from '@verticeone/design-system/src';
import { ButtonGroup } from '@verticeone/design-system/src';
import { Loader } from '@verticeone/design-system/src';
import useVendorAnalytics from '../../hooks/useVendorAnalytics';
import { VendorListFilterContext } from '../../VendorListFilterContext';
import { VendorListFilter } from '../../types';

const MyVendorButton = () => {
  const { t } = useTranslation();
  const { listFilter, setListFilter } = useContext(VendorListFilterContext);
  const { myVendorIds, isLoading: isAnalyticsLoading } = useVendorAnalytics();

  const allVendorsMode = listFilter === VendorListFilter.ALL_VENDORS;

  return (
    <ButtonGroup activeButtonKeys={allVendorsMode ? 0 : 1} variant="ghost" color="primary">
      <Button onClick={() => setListFilter(VendorListFilter.ALL_VENDORS)} sx={{ height: '100%' }}>
        {t('VENDORS.ALL_VENDORS')}
      </Button>
      <Button onClick={() => setListFilter(VendorListFilter.MY_VENDROS)} sx={{ height: '100%' }}>
        {t('VENDORS.MY_VENDORS')}
        {isAnalyticsLoading ? (
          <Loader size="XS" />
        ) : (
          <ChipButton variant="solid" size="XS" color="primary" isActive={false}>
            {myVendorIds?.length ?? 0}
          </ChipButton>
        )}
      </Button>
    </ButtonGroup>
  );
};

export default MyVendorButton;
