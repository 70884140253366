import { FC } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '../IconButton';
import { DesignSystemSize } from '../../types';
import { Tooltip, TooltipProps } from '../Tooltip';

type CardInfoBoxProps = {
  size?: DesignSystemSize;
  tooltip: Pick<TooltipProps, 'title' | 'content'>;
};

export const CardInfoBox: FC<CardInfoBoxProps> = ({ size = 'M', tooltip }) => (
  <Tooltip {...tooltip} size={size}>
    <IconButton size={size} variant="plain" icon={InfoOutlined} isActive={false} />
  </Tooltip>
);
