import React from 'react';

import BaseSavingsOverTimeGraph, {
  useSavingsData,
} from '@vertice/dashboard/src/pages/Cloud/components/SavingsOverTimeGraph';

const SavingsOverTimeGraph = () => {
  const code = 'ALL';
  const { data, isLoading } = useSavingsData(code);

  return (
    <BaseSavingsOverTimeGraph code={code} isLoading={isLoading} values={data?.values ?? []} dates={data?.dates ?? []} />
  );
};

export default SavingsOverTimeGraph;
