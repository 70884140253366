import { EllipsisTextCell } from './EllipsisTextCell';
import React, { useMemo } from 'react';
import { BillingFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import useBillingFrequencyOptions from '../../../hooks/useBillingFrequencyOptions';
import { keyBy } from 'lodash';

export type BillingFrequencyCellProps = { value: BillingFrequency };

export const BillingFrequencyCell = ({ value }: BillingFrequencyCellProps) => {
  const billingFrequencyOptions = useBillingFrequencyOptions();
  const billingFrequencyMap = useMemo(() => keyBy(billingFrequencyOptions, 'value'), [billingFrequencyOptions]);

  return <EllipsisTextCell value={billingFrequencyMap[value]?.label} />;
};
