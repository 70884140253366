import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DoubleCurrencySavingsMoney } from '../types';
import doubleCurrencyMoneyCellValueGetter from './doubleCurrencyMoneyCellValueGetter';
import {
  getAnnualTotalSavings,
  getAnnualTotalSavingsPercentage,
} from '@vertice/core/src/modules/saas/savings/computed';

const totalSavingsCellValueGetter = (
  contract: Contract,
  accountCurrency: string,
  exchangeCurrency: (value: number, sourceCurrency: string) => number
): DoubleCurrencySavingsMoney | undefined => {
  const savingsMoney = doubleCurrencyMoneyCellValueGetter(
    getAnnualTotalSavings(contract),
    contract,
    accountCurrency,
    exchangeCurrency
  );

  if (!savingsMoney) {
    return undefined;
  }

  return {
    ...savingsMoney,
    savingsPercentage: getAnnualTotalSavingsPercentage(contract),
  };
};

export default totalSavingsCellValueGetter;
