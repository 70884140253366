import { Box, Stack } from '@mui/material';
import CheckboxContractFormEntry from '../form/CheckboxContractFormEntry';
import { useVisibleCustomFields } from './useVisibleCustomFields';
import FormReadOnlyText from '../../../../forms/fields/FormReadOnlyText';
import FormTextField from '../../../../forms/fields/FormTextField';
import ContractFormEntry from '../../ContractFormEntry';
import { Loader } from '@verticeone/design-system/src';
import { useFormatNumber } from '@verticeone/utils/formatting';
import DateContractFormEntry from '../form/DateContractFormEntry';
import CustomDropdownFormEntry from '../form/CustomDropdownFormEntry';
import { CustomContractFieldDropdownType } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import EditorContractFormEntry from '../form/EditorContractFormEntry';
import { FieldPath } from 'react-hook-form';
import { ContractFormData } from '../../types';
import { Grid } from '@verticeone/design-system/src';
import FormDecimalNumberField from '../../../../forms/fields/FormDecimalNumberField';

export const CustomFields = () => {
  const formatNumber = useFormatNumber();
  const { visibleFieldsByType } = useVisibleCustomFields();

  if (!visibleFieldsByType) {
    return (
      <Box p={6}>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Stack p={6} gap={8}>
        <Grid container direction="row" spacing={8} hideIfEmpty>
          {visibleFieldsByType['CHECKBOX']?.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={4}>
              <CheckboxContractFormEntry
                name={`customFields.${field.id}` as FieldPath<ContractFormData>}
                label={field.label}
                width={12}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container direction="row" spacing={8} hideIfEmpty>
          {visibleFieldsByType['TEXT']?.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={4}>
              <ContractFormEntry
                name={`customFields.${field.id}` as FieldPath<ContractFormData>}
                label={field.label}
                component={FormReadOnlyText}
                writeComponent={FormTextField}
                width={12}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container direction="row" spacing={8} hideIfEmpty>
          {visibleFieldsByType['DECIMAL']?.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={4}>
              <ContractFormEntry
                name={`customFields.${field.id}` as FieldPath<ContractFormData>}
                label={field.label}
                component={FormReadOnlyText}
                componentProps={{
                  formatter: (value: number) =>
                    value ? formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null,
                }}
                writeComponent={FormDecimalNumberField}
                width={12}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container direction="row" spacing={8} hideIfEmpty>
          {visibleFieldsByType['DATE']?.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={4}>
              <DateContractFormEntry
                name={`customFields.${field.id}` as FieldPath<ContractFormData>}
                label={field.label}
                width={12}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container direction="row" spacing={8} hideIfEmpty>
          {visibleFieldsByType['DROPDOWN']?.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={4}>
              <CustomDropdownFormEntry
                name={`customFields.${field.id}` as FieldPath<ContractFormData>}
                label={field.label}
                items={(field as CustomContractFieldDropdownType).options}
                width={12}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container direction="row" spacing={8} hideIfEmpty>
          {visibleFieldsByType['TEXTAREA']?.map((field) => (
            <Grid key={field.id} item xs={12}>
              <EditorContractFormEntry
                name={`customFields.${field.id}` as FieldPath<ContractFormData>}
                label={field.label}
                writeComponentProps={{
                  textLimit: 10000,
                }}
                width={12}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
};
