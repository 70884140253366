import React, { createContext, MutableRefObject, useCallback, useContext, useRef, useState } from 'react';

type FilterContextProps = {
  gap: number;
  listHeight: string;
  expanded: boolean;
  toggleExpanded: () => void;
  dropdownListRef: MutableRefObject<HTMLDivElement>;
};

type FilterContextProviderProps = {
  children: React.ReactNode;
};

const BaseDropdownContext = createContext<FilterContextProps>({} as FilterContextProps);

const BaseDropdownProvider = ({ children }: FilterContextProviderProps) => {
  const dropdownListRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [gap, setGap] = useState(1);
  const [listHeight, setListHeight] = useState('auto');
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = useCallback(() => {
    setListHeight(`${dropdownListRef.current?.clientHeight}px`);

    setTimeout(() => {
      setExpanded((isExpanded) => {
        if (!isExpanded) {
          setGap(1);
          setTimeout(() => {
            setListHeight('auto');
          }, 500);
        } else {
          setTimeout(() => {
            setGap(0);
          }, 500);
        }

        return !isExpanded;
      });
    }, 0);
  }, []);

  return (
    <BaseDropdownContext.Provider
      value={{
        gap,
        listHeight,
        expanded,
        toggleExpanded,
        dropdownListRef,
      }}
    >
      {children}
    </BaseDropdownContext.Provider>
  );
};

export const useBaseDropdown = () => useContext(BaseDropdownContext);

export default BaseDropdownProvider;
