import React from 'react';
import styled from '@mui/material/styles/styled';
import MuiTextField from '@mui/material/TextField';
import { rgba } from 'polished';
import { getTextVariantStyle } from '../../Text/utils';
import { lineHeightToHeight } from '../utils';
import sizeDefinitions, { SizeDef } from './sizeDefinitions';
import * as common from './common';
import { gradientBorder, gradientBorderColor } from '../../../utils/css/gradientBorder';
import { forwardRef } from 'react';

const BORDER_WIDTH = 1;

const getLabelStyle = ({ paddingY, paddingX, labelLineHeight, gapY }: SizeDef, shrinked: boolean) => {
  const left = paddingX;
  const top = shrinked ? paddingY : paddingY + (labelLineHeight + gapY) / 2;
  return {
    transform: `translate(${left}px, ${top}px) scale(${shrinked ? 0.78 : 1})`,
    maxWidth: '100%',
  };
};

/** WARNING: Don't use directly in apps. Rather use via TextField with a specified variant. */
const StyledSolidTextField = styled(MuiTextField)<common.StyledTextFieldProps>((props) => {
  const {
    theme: { palette },
    $color,
    $size,
    hiddenLabel,
  } = props;
  const textStyle = getTextVariantStyle({ variant: 'body-regular', size: $size });
  return {
    // Note: our solid = MUI filled

    '.MuiFilledInput-root': {
      color: palette.text.color1,
      backgroundColor: palette.input.bg,
      boxShadow: `0 0 0 8px ${rgba(palette[$color].color4, 0)}`,
      transition: ['background-color 200ms cubic-bezier(0.0,0,0.2,1)', 'box-shadow 400ms ease-out'].join(', '),
      ...gradientBorder({
        width: `${BORDER_WIDTH}px`,
        color: `linear-gradient(0, ${rgba(palette.text.color1, 0.1)}, transparent)`,
        radius: '8px',
        transition: '200ms cubic-bezier(0.0,0,0.2,1)',
      }),

      '&.Mui-error': { ...gradientBorderColor(palette.error.color2) },
      '&.Mui-focused': {
        boxShadow: `0 0 0 4px ${palette[$color].color4}`,
        ...gradientBorderColor(palette[$color].color2),
        '&.Mui-error': {
          boxShadow: `0 0 0 4px ${palette.error.color3}`,
          ...gradientBorderColor(palette.error.color2),
        },
      },
      // Disabled wins over hover
      '&.Mui-disabled.Mui-disabled': {
        background: palette.inactive.color3,
      },
    },

    '&:hover .MuiFilledInput-root:not(.Mui-focused)': {
      backgroundColor: palette.input.color1,
    },

    // Input

    '.MuiFilledInput-root.Mui-error .MuiFilledInput-input::selection': {
      background: rgba(palette.error.color1, 0.2),
    },
    '.MuiFilledInput-input': {
      // Caret in HTML Input forces effective lineHeight to be at least cca 115% of the fontSize, even if
      // you manually set <115% lineHeight. To fully support and respect <115% lineHeights, we explicitly set the
      // height of the input to the lineHeight. We use converter to turn lineHeight percentage (relative to fontSize)
      // into something that can be fed to the height CSS prop.
      height: lineHeightToHeight(textStyle.lineHeight ?? 1),
      ...common.makePadding(sizeDefinitions[$size], !hiddenLabel),
      BORDER_WIDTH,
      ...textStyle,

      '&::selection': {
        background: rgba(palette[$color].color2, 0.2),
      },
      '&.Mui-disabled': {
        color: palette.inactive.color2,
      },
    },

    // Label

    '.MuiInputLabel-filled:not(.Mui-focused)': { color: palette.text.color3 },
    '&:hover .MuiInputLabel-filled.MuiInputLabel-shrink': { color: palette[$color].hover.color2 },
    // Repeat .Mui-error to increase specificity and win over :hover above.
    '.MuiInputLabel-root.Mui-error.Mui-error': { color: palette.error.color1 },
    '.MuiInputLabel-filled': {
      ...textStyle,
      ...getLabelStyle(sizeDefinitions[$size], false),

      '&.MuiInputLabel-shrink': { ...getLabelStyle(sizeDefinitions[$size], true) },
      '&.Mui-disabled.Mui-disabled': { color: palette.inactive.color2 },
    },

    // Shared
    ...common.inputBaseAdornedStyles({ size: $size }),
    ...common.helperTextStyles(props),
    ...common.spinButtonStyles,
  };
});

const SolidTextField = forwardRef<HTMLDivElement, common.TextFieldProps>(({ size, color, ...otherProps }, ref) => (
  <StyledSolidTextField ref={ref} $size={size} $color={color} {...otherProps} />
));

export default SolidTextField;
