import { createContext, useState } from 'react';
import { ActivityFilter, EMPTY_FILTER, HealthFilter, UsageTabs } from '../UsageDetail/utils';

type UsageState = {
  lastActiveHealthFilter?: HealthFilter;
  setLastActiveHealthFilter: (filter: HealthFilter) => void;
  activeTab: UsageTabs;
  setActiveTab: (tab: UsageTabs) => void;
  userActivityFilter: ActivityFilter;
  setUserActivityFilter: (filter: ActivityFilter) => void;
};

const defaultUsageState = {
  setLastActiveHealthFilter: () => null,
  activeTab: UsageTabs.APPLICATIONS,
  setActiveTab: () => null,
  userActivityFilter: { ...EMPTY_FILTER, inactive: true },
  setUserActivityFilter: () => null,
};

export const UsageContext = createContext<UsageState>(defaultUsageState);

export const UsageContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [lastActiveHealthFilter, setLastActiveHealthFilter] = useState<HealthFilter>(HealthFilter.HEALTHY);
  const [activeTab, setActiveTab] = useState<UsageTabs>(defaultUsageState.activeTab);
  const [userActivityFilter, setUserActivityFilter] = useState(defaultUsageState.userActivityFilter);

  return (
    <UsageContext.Provider
      value={{
        lastActiveHealthFilter,
        setLastActiveHealthFilter,
        activeTab,
        setActiveTab,
        userActivityFilter,
        setUserActivityFilter,
      }}
    >
      {children}
    </UsageContext.Provider>
  );
};
