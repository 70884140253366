import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { TrendingDownTwoTone, TrendingFlatTwoTone, TrendingUpTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { CommonFormEntryProps } from './types';
import { TaskFormEntry } from './TaskFormEntry';

export const RenewalTypeFormEntry = <FormDataType extends FieldValues>({
  name,
  label,
  required,
}: CommonFormEntryProps<FormDataType>) => {
  const { t } = useTranslation();

  return (
    <TaskFormEntry<any, typeof FormToggleButtonField>
      name={name}
      label={label}
      required={required}
      component={FormToggleButtonField}
      componentProps={{
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        buttonHeight: 32,
        compact: true,
        optionsFilter: (selected, disabled = false) => !disabled || selected,
        options: [
          {
            title: t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE_UPGRADE'),
            value: 'UPGRADE',
            icon: TrendingUpTwoTone,
          },
          {
            title: t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE_FLAT'),
            value: 'FLAT_RENEWAL',
            icon: TrendingFlatTwoTone,
          },
          {
            title: t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE_DOWNGRADE'),
            value: 'DOWNGRADE',
            icon: TrendingDownTwoTone,
          },
        ],
      }}
    />
  );
};
