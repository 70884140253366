import { useCallback } from 'react';
import {
  type RdsReservationsQuery,
  useLazyRdsReservationsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

import type { RDSDataEnhanced } from './useRDSData';
import type { ReservationsData, UseReservationsData } from '../../../types';
import { fillMissingPastReservationsData } from '../../../utils';
import { startOfDay, subDays } from 'date-fns';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export const useRDSReservationData = (): UseReservationsData<RDSDataEnhanced> => {
  const { accountId } = useAccountContext();
  const [getReservationData] = useLazyRdsReservationsQuery();

  const parsedData = useCallback((items: RdsReservationsQuery | undefined) => {
    if (items?.athenaViewQuery?.__typename !== 'DataTableResult' || !items.athenaViewQuery.table) {
      return undefined;
    }

    const tableData = getTableData(items.athenaViewQuery.table, {
      timestamp: 'day',
      instance_family: 'instanceFamily',
      reserved_capacity: 'reservedCapacity',
      pre_installed_sw: 'operatingSystem',
      region: 'region',
      license_model: 'licenseModel',
      on_demand_usage: 'onDemandUsage',
      reserved_usage: 'reservedUsage',
    }) as ReservationsData[];

    return fillMissingPastReservationsData<ReservationsData>(tableData, subDays(startOfDay(new Date()), 2));
  }, []);

  const fetch = useCallback(
    async (item: RDSDataEnhanced): Promise<{ error: any; data: ReservationsData[] | undefined }> => {
      const response = await getReservationData({
        accountId: accountId!,
        region: item.region,
        instanceFamily: item.instanceFamily,
        licenseModel: item.licenseModel,
        databaseEngine: item.databaseEngine,
        databaseEdition: item.databaseEdition || 'NA',
      });

      if (!response.error && response.data?.athenaViewQuery?.__typename === 'DeferredQueryResult') {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return await fetch(item);
      }

      return {
        error: response.error,
        data: parsedData(response.data),
      };
    },
    [getReservationData, accountId, parsedData]
  );

  return async (item: RDSDataEnhanced) => {
    return await fetch(item);
  };
};
