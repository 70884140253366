import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';

import { Divider } from './Divider';

type SubSectionProps = {
  children: ReactNode;
  title: string;
};

export const SubSection: FC<SubSectionProps> = ({ children, title }) => {
  return (
    <Stack gap={4}>
      <Divider text={title} />
      <Stack>{children}</Stack>
    </Stack>
  );
};
