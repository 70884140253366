import React, { Dispatch, FC, SetStateAction } from 'react';
import DropdownValueListBase, { DropdownValueListProps as DropdownValueListBaseProps } from './DropdownValueList';
import WithActions from './WithActions';
import DropdownValueListContextProvider from './DropdownValueListContextProvider';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

interface DropdownValueListProps extends DropdownValueListBaseProps {
  selectedValues: CustomContractFieldDropdownOption[];
  setSelectedValues: Dispatch<SetStateAction<CustomContractFieldDropdownOption[]>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
  withActions?: boolean;
  excludeDeleteAction?: boolean;
  allowSavingFor?: Array<string>;
}

export const DropdownValueList: FC<DropdownValueListProps> = ({
  selectedValues,
  setSelectedValues,
  setError,
  withActions = true,
  excludeDeleteAction,
  allowSavingFor,
  ...rest
}) => {
  const listBase = <DropdownValueListBase readOnlyMode={!withActions} {...rest} />;
  return (
    <DropdownValueListContextProvider
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      setError={setError}
    >
      {withActions ? <WithActions {...{ excludeDeleteAction, allowSavingFor }}>{listBase}</WithActions> : listBase}
    </DropdownValueListContextProvider>
  );
};
