import React from 'react';
import type { DesignSystemSize } from '../../types';
import { Text } from '../Text';
import { DialogWrapper } from './DialogWrapper';
import { useDialog } from './DialogContext';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '../IconButton';
import { Stack } from '@mui/material';
import { Align } from './types';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import { testProps } from '../../utils/testProperties';
import { IconWrapper } from '../IconWrapper';
import type { SvgIconComponent } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';

type DialogHeaderProps = {
  children: React.ReactNode;
  size?: DesignSystemSize;
  align?: Align;
  icon?: SvgIconComponent;
};

const iconDirectionMap: Record<Align, 'row' | 'column'> = {
  left: 'row',
  center: 'column',
};

export const DialogHeader = ({ children, size, align, icon }: DialogHeaderProps) => {
  const { palette } = useTheme();
  const dialog = useDialog();
  const dialogSize = size ?? dialog.size;
  const dialogAlign = align ?? dialog.align;
  const hasCloseButton = dialog.setOpen !== undefined;

  const handleClose = () => {
    if (dialog.setOpen) {
      dialog.setOpen(false);
    }
  };

  return (
    <DialogWrapper $size={dialogSize} {...testProps(undefined, 'dialogHeader')}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          flexGrow={1}
          direction={iconDirectionMap[dialogAlign as Align]}
          alignItems="center"
          gap={baseSizes[dialogSize]}
        >
          {icon ? <IconWrapper icon={icon} htmlColor={palette.neutral.color1} /> : null}
          <Text variant="heading" size={dialogSize} color="text1" textAlign={dialogAlign}>
            {children}
          </Text>
        </Stack>
        {hasCloseButton && (
          <IconButton
            size={dialogSize}
            variant="ghost"
            color="neutral"
            icon={CloseOutlinedIcon}
            onClick={handleClose}
          />
        )}
      </Stack>
    </DialogWrapper>
  );
};
