import React from 'react';
import useEc2RIUtilizationData from './useEc2RIUtilizationData';
import RIUtilizationTable from '../RIUtilizationTable';
import { LoadableContentWrapper } from '../../../components/LoadableContentWrapper';

const Ec2RIUtilizationCard = () => {
  const { data, ...loadingStatus } = useEc2RIUtilizationData();

  return (
    <LoadableContentWrapper {...loadingStatus}>
      <RIUtilizationTable data={data!} />
    </LoadableContentWrapper>
  );
};

export default Ec2RIUtilizationCard;
