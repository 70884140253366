import React, { FC } from 'react';
import { IconWrapper } from '@verticeone/design-system/src';
import { PersonOutline } from '@mui/icons-material';
import { Text } from '@verticeone/design-system/src';
import { Stack } from '@mui/material';
import { WorkflowTaskNodeType } from '../../../model/types';
import { useTranslation } from 'react-i18next';

type TaskVerticeAssignmentProps = {
  textColor: string;
  taskNodeType?: WorkflowTaskNodeType;
  status?: string;
};

export const TaskVerticeAssignment: FC<TaskVerticeAssignmentProps> = ({ status, taskNodeType, textColor }) => {
  const { t } = useTranslation();

  if (taskNodeType !== 'VERTICE_SERVICE' || (status !== 'ACTIVE' && status !== 'COMPLETED')) {
    return null;
  }

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <IconWrapper icon={PersonOutline} htmlColor={textColor} size="S" />
      <Stack>
        <Text variant="button-bold" size="XS" color={textColor}>
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.VERTICE')}
        </Text>
      </Stack>
    </Stack>
  );
};
