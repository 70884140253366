import { ReactNode, useCallback } from 'react';
import {
  components,
  type MenuProps,
  type ClearIndicatorProps,
  type DropdownIndicatorProps,
  type MultiValue,
  type MultiValueGenericProps,
  type PropsValue,
  type ValueContainerProps,
  type OptionProps,
  type SelectComponentsConfig,
  type MultiValueRemoveProps,
} from 'react-select';
import { ArrowDropDownOutlined, Close } from '@mui/icons-material';
import styled from '@mui/material/styles/styled';
import { IconWrapper } from '../IconWrapper';
import { Theme } from '@mui/material/styles';
import { DesignSystemSize } from '../../types';
import { GroupBase, SelectColor } from './types';
import { CircularProgress, Stack } from '@mui/material';
import { baseSizes, multiItemGapSizes, reducedSizes } from '../../guidelines/Sizing/sizings';
import { ChipButton, ValuesAggregatorChip } from '../ChipButton';
import { Checkbox } from '../Checkbox';
import sizeDefinitions from '../TextField/styledVariants/sizeDefinitions';

const StyledIcon = styled(IconWrapper)<{ $isDisabled?: boolean }>(({ theme: { palette }, $isDisabled }) => ({
  color: !!$isDisabled ? palette.inactive.color2 : palette.text.color3,
}));

const StyledLabel = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // avoid text being vertically clipped
  margin: -2,
  padding: 2,
});

const StyledValueContainer = styled('div')<{
  theme: Theme;
  $size: DesignSystemSize;
  $isEmpty: boolean;
  $wrapItems: boolean;
}>(({ $size, $isEmpty, $wrapItems }) => ({
  alignItems: 'center',
  display: $isEmpty ? 'grid' : 'flex',
  flex: '1',
  flexWrap: $wrapItems ? 'wrap' : 'nowrap',
  rowGap: reducedSizes[$size],
  position: 'relative',
  overflow: 'hidden',
  paddingLeft: sizeDefinitions[$size].paddingX,
  paddingTop: sizeDefinitions[$size].paddingY,
  paddingBottom: sizeDefinitions[$size].paddingY,
  boxSizing: 'border-box',
}));

// convert value `PropsValue<Option> = MultiValue<Option> | SingleValue<Option>;` to `MultiValue<Option>`
// as even for isMulti = true the value can be SingleValue<Option> when the component was just switched from single to multi
const getMultiValue = <Option extends unknown = unknown>(value: PropsValue<Option>): MultiValue<Option> => {
  if (Array.isArray(value)) {
    return value;
  } else if (value === null) {
    return [];
  } else {
    return [value] as MultiValue<Option>;
  }
};

type UseStyledSelectComponentsFc = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  size: DesignSystemSize,
  color: SelectColor,
  maxMultiChips?: number,
  footer?: ReactNode
) => SelectComponentsConfig<Option, IsMulti, Group>;

export const useStyledSelectComponents: UseStyledSelectComponentsFc = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  size: DesignSystemSize,
  color: SelectColor,
  maxMultiChips?: number,
  footer?: ReactNode
) => {
  const Menu = useCallback(
    (props: MenuProps<Option, IsMulti, Group>) => (
      <components.Menu {...props}>
        {props.children}
        {footer}
      </components.Menu>
    ),
    [footer]
  );

  const DropdownIndicator = useCallback(
    (props: DropdownIndicatorProps<Option, IsMulti, Group>) => (
      <components.DropdownIndicator {...props}>
        <StyledIcon icon={ArrowDropDownOutlined} size={size} $isDisabled={props.isDisabled} />
      </components.DropdownIndicator>
    ),
    [size]
  );

  const ClearIndicator = useCallback(
    (props: ClearIndicatorProps<Option, IsMulti, Group>) => (
      <components.ClearIndicator {...props}>
        <StyledIcon icon={Close} size={size} {...{ 'data-testid': 'clear-select' }} />
      </components.ClearIndicator>
    ),
    [size]
  );

  const LoadingIndicator = useCallback(() => <CircularProgress size={baseSizes[size]} color={color} />, [size, color]);

  const ValueContainer = useCallback(
    (props: ValueContainerProps<Option, IsMulti, Group>) => (
      <StyledValueContainer
        $size={size}
        $isEmpty={getMultiValue(props.selectProps.value).length === 0}
        $wrapItems={props.isMulti && maxMultiChips === undefined}
      >
        {props.children}
      </StyledValueContainer>
    ),
    [maxMultiChips, size]
  );

  // customized only for the multi-select (isMulti===true)
  const OptionComponent = useCallback(
    (props: OptionProps<Option, IsMulti, Group>) =>
      props.selectProps.isMulti ? (
        <components.Option {...props}>
          <Stack direction="row" alignItems="center" gap={reducedSizes[size]}>
            <Checkbox
              size={size}
              color={color}
              sx={{ padding: 0 }}
              disabled={props.isDisabled}
              checked={props.isSelected}
              inputProps={{
                // prevent checkbox from catching focus and thus closing Select menu
                onClick: (e) => {
                  props.selectOption(props.data);
                  e.stopPropagation();
                },
              }}
            />
            {props.selectProps.getOptionLabel(props.data)}
          </Stack>
        </components.Option>
      ) : (
        <components.Option {...props} />
      ),
    [color, size]
  );

  // following components used only in multi-select (isMulti===true)
  const MultiValueContainer = useCallback(
    ({ selectProps, data, children }: MultiValueGenericProps<Option, IsMulti, Group>) => {
      const multiValue = getMultiValue(selectProps.value);
      const index = multiValue.indexOf(data);

      if (maxMultiChips === undefined || index < maxMultiChips) {
        return (
          <ChipButton
            size={size}
            color={color}
            variant="ghost"
            disabled={selectProps.isDisabled}
            title={selectProps.getOptionLabel(data)}
            sx={{ marginRight: multiItemGapSizes[size] }}
          >
            {children}
          </ChipButton>
        );
      }
      // rest of values above maxMultiChips is rendered as single chip button
      if (index === maxMultiChips) {
        return (
          <ValuesAggregatorChip
            values={multiValue.slice(index).map((option) => selectProps.getOptionLabel(option))}
            size={size}
            color={color}
            disabled={selectProps.isDisabled}
            sx={{ flexShrink: 0, marginRight: reducedSizes[size] }}
          />
        );
      } else {
        return null;
      }
    },
    [maxMultiChips, size, color]
  );

  const MultiValueLabel = useCallback(
    (props: MultiValueGenericProps<Option, IsMulti, Group>) => <StyledLabel>{props.children}</StyledLabel>,
    []
  );

  const MultiValueRemove = useCallback(
    (props: MultiValueRemoveProps<Option, IsMulti, Group>) => (
      <components.MultiValueRemove {...props}>
        <IconWrapper icon={Close} size={size} sx={{ opacity: 0.6, marginLeft: 1 }} />
      </components.MultiValueRemove>
    ),
    [size]
  );

  return {
    Menu,
    DropdownIndicator,
    ClearIndicator,
    LoadingIndicator,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    Option: OptionComponent,
    ValueContainer,
  };
};
