import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import { Table, TableBody, TableContainer } from '@verticeone/design-system/src';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

import InstanceTableRow from './InstanceTableRow';
import { useInstanceData } from '../providers/InstanceProvider';

const InstanceTable = () => {
  const { item } = useInstanceData();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.STATS_TABLE' });

  return (
    <TableContainer>
      <Table size="XL" aria-label={t('LABEL')}>
        <TableBody>
          <InstanceTableRow
            testId="ri_units_total_usage"
            label={t('COLUMN.RI_UNITS_TOTAL_USAGE.TITLE')}
            value={`${Math.round(item.riUnits)} / ${Math.round(item.totalUnits)}`}
            tooltip={{
              title: t('COLUMN.RI_UNITS_TOTAL_USAGE.TOOLTIP.TITLE'),
              content: t('COLUMN.RI_UNITS_TOTAL_USAGE.TOOLTIP.CONTENT'),
            }}
          />
          <InstanceTableRow
            testId="ri_utilization"
            label={t('COLUMN.RI_UTILIZATION.TITLE')}
            value={formatPercentage(item.reservedUtilization, {
              maximumFractionDigits: 2,
            })}
            tooltip={{
              title: t('COLUMN.RI_UTILIZATION.TOOLTIP.TITLE'),
              content: t('COLUMN.RI_UTILIZATION.TOOLTIP.CONTENT'),
            }}
          />
          <InstanceTableRow
            testId="on_demand_rate"
            label={t('COLUMN.ON_DEMAND_RATE.TITLE')}
            value={formatCurrency(item.onDemandRate, {
              currency: AWS_DEFAULT_CURRENCY,
              maximumFractionDigits: 4,
            })}
            tooltip={{
              title: t('COLUMN.ON_DEMAND_RATE.TOOLTIP.TITLE'),
              content: t('COLUMN.ON_DEMAND_RATE.TOOLTIP.CONTENT'),
            }}
          />
          <InstanceTableRow
            testId="effective_rate"
            label={t('COLUMN.EFFECTIVE_RATE.TITLE')}
            value={formatCurrency(item.effectiveRate, {
              currency: AWS_DEFAULT_CURRENCY,
              maximumFractionDigits: 4,
            })}
            tooltip={{
              title: t('COLUMN.EFFECTIVE_RATE.TOOLTIP.TITLE'),
              content: t('COLUMN.EFFECTIVE_RATE.TOOLTIP.CONTENT'),
            }}
          />
          <InstanceTableRow
            testId="effective_discount"
            label={t('COLUMN.EFFECTIVE_DISCOUNT.TITLE')}
            value={formatPercentage((item.onDemandRate - item.effectiveRate) / item.onDemandRate)}
            tooltip={{
              title: t('COLUMN.EFFECTIVE_DISCOUNT.TOOLTIP.TITLE'),
              content: t('COLUMN.EFFECTIVE_DISCOUNT.TOOLTIP.CONTENT'),
            }}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstanceTable;
