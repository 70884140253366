import { MutableRefObject, useCallback, useState } from 'react';

type Position = { top: number; left: number; x: number; y: number } | null;

export const useDragToMoveEvents = (canvasReference: MutableRefObject<HTMLElement | null>) => {
  const [dragPosition, setDragPosition] = useState<Position>(null);

  const onMouseDown = useCallback(
    (event: any) => {
      const canvasElement = canvasReference.current;
      if (!canvasElement) {
        return;
      }
      canvasElement.style.cursor = 'grabbing';
      setDragPosition({
        left: canvasElement.scrollLeft,
        top: canvasElement.scrollTop,
        // Get the current mouse position
        x: event.clientX,
        y: event.clientY,
      });
    },
    [setDragPosition, canvasReference]
  );

  const onMouseUp = useCallback(
    (event: any) => {
      const canvasElement = canvasReference.current;
      if (!canvasElement) {
        return;
      }
      canvasElement.style.cursor = 'grab';
      setDragPosition(null);
    },
    [setDragPosition, canvasReference]
  );

  const onMouseMove = useCallback(
    (event: any) => {
      const canvasElement = canvasReference.current;
      if (!canvasElement) {
        return;
      }
      if (dragPosition) {
        // How far the mouse has been moved
        const dx = event.clientX - dragPosition.x;
        const dy = event.clientY - dragPosition.y;

        // Scroll the element
        canvasElement.scrollTop = dragPosition.top - dy;
        canvasElement.scrollLeft = dragPosition.left - dx;
      }
    },
    [dragPosition, canvasReference]
  );

  return {
    onMouseDown,
    onMouseUp,
    onMouseMove,
  };
};
