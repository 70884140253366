import React, { createContext, useContext } from 'react';
import type { RoutesType } from '@verticeone/router/src/routes/RoutesType';
import type { AttachedRouteList } from './utils';

type NavigateContextProviderProps = {
  children: React.ReactNode;
  routes: AttachedRouteList;
};

const RoutesContext = createContext<RoutesType>({} as RoutesType);

const RoutesContextProvider = ({ children, routes }: NavigateContextProviderProps) => {
  return <RoutesContext.Provider value={routes}>{children}</RoutesContext.Provider>;
};

export const useRoutes = () => useContext(RoutesContext);

export default RoutesContextProvider;
