import React, { FC, MouseEvent } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Typography } from '@mui/material';
import {
  AddCircle as AddCircleIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { formatBytes } from './utils';
import styles from './Dropzone.module.scss';

interface DropzoneProps {
  onFileAccepted: (files: FileWithPath[]) => void;
  acceptedFiles: FileWithPath[];
  onRemoveFile: (path: string) => void;
}

/** @deprecated use Dropzone from design system instead */
const Dropzone: FC<DropzoneProps> = ({ onFileAccepted, acceptedFiles, onRemoveFile }) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({ multiple: false, onDropAccepted: onFileAccepted });

  const handleRemove = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (acceptedFiles[0]?.path) {
      onRemoveFile(acceptedFiles[0].path);
    }
  };

  return (
    <div
      {...getRootProps({
        className: clsx(styles.dropzone, styles['dropzone-input'], {
          [styles['dropzone-input-with-file']]: !!acceptedFiles.length,
        }),
      })}
    >
      <input {...getInputProps()} />
      {!!acceptedFiles.length && (
        <div className={styles['dropzone-input-file-remove']}>
          <IconButton aria-label="remove" onClick={handleRemove}>
            <CloseIcon className={styles['close-icon']} />
          </IconButton>
        </div>
      )}
      <div className={styles['dropzone-info']}>
        {acceptedFiles.length ? (
          <>
            <InsertDriveFileOutlinedIcon className={styles['file-icon']} />
            <Typography variant="button-xs" className={styles['dropzone-input-file-name']}>
              {acceptedFiles[0].name}
            </Typography>
            <Typography variant="body-regular-xs" className={styles['dropzone-input-file-size']}>
              {formatBytes(acceptedFiles[0].size)}
            </Typography>
          </>
        ) : (
          <>
            <AddCircleIcon className={styles['add-icon']} />
            <Typography variant="button-s" className={styles['dropzone-input-empty-text']}>
              {t('DROPZONE.DEFAULT_INFO_TEXT')}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
