import React from 'react';
import { Grid, Stack } from '@mui/material';
import ContractFormEntry from '@vertice/core/src/modules/saas/contract/ContractFormEntry';
import FormReadOnlyText from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import { useTranslation } from 'react-i18next';
import EditorContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/EditorContractFormEntry';
import DateContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/DateContractFormEntry';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import PriceContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/PriceContractFormEntry';

const PurchaseObjectivesTab = () => {
  const { t } = useTranslation();

  return (
    <Stack p={6} gap={8}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <DateContractFormEntry
            name="workflow.externalDocuments.targetSignDate"
            label={t('ENTITIES.CONTRACT.LABELS.TARGET_SIGN_DATE')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="workflow.externalDocuments.desiredContractLength"
            label={t('ENTITIES.CONTRACT.LABELS.DESIRED_CONTRACT_LENGTH')}
            component={FormReadOnlyText}
            componentProps={{
              formatter: (value: number) => (value ? `${value} ${t('UNITS.MONTH', { count: value ?? 0 })}` : null),
            }}
            writeComponent={FormNumberField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PriceContractFormEntry
            name="workflow.externalDocuments.plannedBudget"
            label={t('ENTITIES.CONTRACT.LABELS.TARGET_BUDGET')}
            width={12}
          />
        </Grid>
        <Grid item xs={12}>
          <EditorContractFormEntry
            name="additionalDetails.external.notes"
            label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_NOTES')}
            width={12}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PurchaseObjectivesTab;
