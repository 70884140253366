import { ContractFormData } from '../types';
import { FieldPath, UseFormReturn } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { UseContractEditModeReturn } from './useContractEditMode';

export type UseContractValidateFilledFieldsOnSwitchToEditModeParams = {
  hookForm: UseFormReturn<ContractFormData>;
  editMode: UseContractEditModeReturn;
};

/**
 * Recursively finds all non-empty fields in the object, returns the list of their paths.
 */
const getNonEmptyFields = <T extends Record<string, any>>(obj: T): FieldPath<T>[] => {
  const paths: FieldPath<T>[] = [];
  for (const [key, value] of Object.entries(obj)) {
    if (isEmpty(value)) {
      continue;
    }
    if (typeof value === 'object') {
      paths.push(...getNonEmptyFields(value).map((path) => `${key}.${path}` as FieldPath<T>));
    } else {
      paths.push(key as FieldPath<T>);
    }
  }
  return paths;
};

const validateAllNonEmptyFields = (hookForm: UseFormReturn<ContractFormData>) =>
  hookForm.trigger(getNonEmptyFields(hookForm.getValues()));

export const useContractValidateFilledFieldsOnSwitchToEditMode = ({
  hookForm,
  editMode,
}: UseContractValidateFilledFieldsOnSwitchToEditModeParams): void => {
  const prevIsEditing = useRef(editMode.isEditing);
  useEffect(() => {
    if (!prevIsEditing.current && editMode.isEditing) {
      void validateAllNonEmptyFields(hookForm);
    }
    prevIsEditing.current = editMode.isEditing;
  }, [hookForm, editMode.isEditing]);
};
