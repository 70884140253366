import React from 'react';
import { Stack, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Text, TextProps } from '@verticeone/design-system/src';

export const BulletList = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: theme.spacing(6),

  li: {
    marginBottom: theme.spacing(1),
  },

  'li:last-of-type': {
    marginBottom: 0,
  },
}));

export const ScopeReductionSavingsTooltip = () => {
  const { t } = useTranslation();

  const textProps = {
    size: 'S',
    variant: 'body-regular',
    color: 'text4',
  } satisfies TextProps;

  return (
    <Stack gap={2}>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_1')}</Text>
      <Text {...textProps}>
        <Trans
          i18nKey="ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_2"
          components={{ b: <Text {...textProps} sx={{ textDecoration: 'underline ' }} /> }}
        />
      </Text>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_3')}</Text>
      <BulletList>
        <li>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_3_ITEM_1')}</li>
        <li>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_3_ITEM_2')}</li>
        <li>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_3_ITEM_3')}</li>
      </BulletList>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_4')}</Text>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_5')}</Text>
    </Stack>
  );
};
