import Highcharts from 'highcharts';
import { default as useDateTime, type getDateTimeProps } from './useDateTime';

export type XAxisTypes = { type: 'dateTime' } & getDateTimeProps;

export const useGetXAxis = () => {
  const hooks: Record<XAxisTypes['type'], (props: any) => Highcharts.XAxisOptions> = {
    dateTime: useDateTime(),
  };

  return (props: XAxisTypes | Array<XAxisTypes>): Highcharts.XAxisOptions | Array<Highcharts.XAxisOptions> => {
    if (Array.isArray(props)) {
      return props.map((p) => hooks[p.type](p));
    }

    return hooks[props.type](props);
  };
};
