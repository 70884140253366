import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useSnackbar } from 'notistack';

import {
  useConnectAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import {
  Button,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderTitle,
  Text,
} from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import { JIRA_PROVIDER_ID, DEFAULT_POLLING_INTERVAL } from '../constants';
import { JiraSetupStep, StepContentProps } from '../types';
import { ConnectionForm, ConnectionFormData } from './ConnectionForm';

type ConnectionCardProps = StepContentProps & {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
};

type ConnectionFormType =
  | {
      domain: string;
      username: string;
    }
  | undefined;

export const ConnectionCard = ({ activeStep, setStep, isEditMode, setIsEditMode }: ConnectionCardProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();

  const [pollingInterval, setPollingInterval] = useState(0);
  const [isConnectionEditDialogOpened, setIsConnectionEditDialogOpened] = useState(false);

  const isTestingConnection = pollingInterval > 0;

  const { data: jiraIntegration } = useGetAccountIntegrationQuery(
    {
      accountId,
      integrationId: JIRA_PROVIDER_ID,
    },
    { pollingInterval: isTestingConnection ? pollingInterval : undefined }
  );

  useEffect(() => {
    const connectionStatus = jiraIntegration?.connection?.status;

    if (connectionStatus === 'OK' && isTestingConnection) {
      setPollingInterval(0);
      setStep(JiraSetupStep.CONFIGURATION);
    }
    if (connectionStatus === 'FAILED' && isTestingConnection) {
      setPollingInterval(0);
      enqueueSnackbar({
        message: t('PREFERENCES.INTEGRATIONS.JIRA.SNACKBAR.TEST_CONNECTION_ERROR_MESSAGE'),
        description: t('PREFERENCES.INTEGRATIONS.JIRA.SNACKBAR.TEST_CONNECTION_ERROR_DESC'),
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, isTestingConnection, jiraIntegration?.connection?.status, setStep, t]);

  const testConnection: GoodFormOnSubmitCallback<ConnectionFormData> = async (formData) => {
    await revokeAccountIntegration({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
    });

    await connectAccountIntegrationMutation({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
      integrationConnectionInput: {
        parameters: {
          secret: {
            domain: formData.workspaceUrl,
            username: formData.administratorEmail,
            api_key: formData.apiKey,
          },
          connectionForm: {
            domain: formData.workspaceUrl,
            username: formData.administratorEmail,
          },
        },
      },
    });

    await testAccountIntegrationMutation({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
      testName: 'connection',
    });

    setPollingInterval(DEFAULT_POLLING_INTERVAL);

    if (isEditMode) {
      setIsEditMode(false);
    }
  };

  const editConnection = () => {
    if (isSuccessStep) {
      setIsConnectionEditDialogOpened(true);
      return;
    }

    setStep(JiraSetupStep.CONNECTION);
  };

  const handleEditConnectionSubmit = () => {
    setIsEditMode(false);
    setIsConnectionEditDialogOpened(false);
  };

  const isConnectionStep = activeStep === JiraSetupStep.CONNECTION;
  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;
  const isFormSubmitted = !isConnectionStep && !isEditMode && !isTestingConnection;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle icon={SettingsOutlinedIcon} text={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.TITLE')} />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
        {isFormSubmitted && (
          <CardHeaderActions>
            <Button variant="outline" size="S" color="primary" onClick={editConnection}>
              {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.ACTION_BUTTON')}
            </Button>
            <IntegrationSetupDialog
              header={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.HEADER')}
              content={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.CONTENT')}
              cancelButtonLabel={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.CANCEL_BUTTON')}
              buttonLabel={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.SUBMIT_BUTTON')}
              isOpened={isConnectionEditDialogOpened}
              onSubmit={handleEditConnectionSubmit}
              onClose={() => setIsConnectionEditDialogOpened(false)}
            />
          </CardHeaderActions>
        )}
      </CardHeader>
      <Stack gap={1} p={6}>
        <ConnectionForm
          defaultValues={{
            workspaceUrl: (jiraIntegration?.parameters?.connectionForm as ConnectionFormType)?.domain ?? '',
            administratorEmail: (jiraIntegration?.parameters?.connectionForm as ConnectionFormType)?.username ?? '',
            apiKey: '',
          }}
          onSubmit={testConnection}
          activeStep={activeStep}
          isLoading={isTestingConnection}
          isSubmitted={isFormSubmitted}
          setIsEditMode={setIsEditMode}
        />
      </Stack>
    </Card>
  );
};
