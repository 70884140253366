import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system/src';

const TargetsAndControlButton = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });

  // TODO - enable actions when implemented
  const actionsDisabled = true;

  return (
    <Button testId="targets-and-controls" disabled={actionsDisabled} variant="ghost" size="M" color="neutral">
      {t('ACTIONS_ROW.TARGETS_AND_CONTROLS')}
    </Button>
  );
};

export default TargetsAndControlButton;
