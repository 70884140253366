export const getColorPattern = (color: string) => {
  return {
    pattern: {
      path: {
        d: 'M 5 0 L 0 5 M -1 1 L 1 -1 M 6 4 L 4 6',
        strokeWidth: 1,
      },
      width: 5,
      height: 5,
      color,
    },
  };
};
