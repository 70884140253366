import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import patternFill from 'highcharts/modules/pattern-fill';
import { Box, Divider, Stack, useTheme } from '@mui/material';

import { Loader } from '@verticeone/design-system/src';
import { Card } from '@verticeone/design-system/src';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import EmptyStateError from '@vertice/dashboard/src/modules/cloud/components/EmptyStateError';

import { useShoppingCart } from '../../providers/ShoppingCartProvider';
import { useInstanceData } from '../../providers/InstanceProvider';
import { useFormatNumber, calculateShoppingCartUnits, calculateRecommendedShoppingCartUnits } from '../utils';

import useRiPurchaseChartData from './useRiPurchaseChartData';
import { useOfferings } from '../../providers/OfferingsProvider';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);

const ROW_HEIGHT = 65;
const ROWS_COUNT = 4;
const chartHeight = ROW_HEIGHT * ROWS_COUNT;

const BuyInstanceDrawerGraph = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { cartItems, filterByShoppingCart } = useShoppingCart();
  const { item: instance, reservations } = useInstanceData();
  const TOTAL_USAGE = t('CLOUD.RIO.OPTIMIZE.CHART.SERIES_NAME.TOTAL_USAGE');
  const RESERVED_USAGE = t('CLOUD.RIO.OPTIMIZE.CHART.SERIES_NAME.RESERVED_USAGE');
  const RESERVED_UNUSED = t('CLOUD.RIO.OPTIMIZE.CHART.SERIES_NAME.RESERVED_UNUSED');
  const TARGET_RIS = t('CLOUD.RIO.OPTIMIZE.CHART.SERIES_NAME.TARGET_RIS');
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const { offerings: recommendedOfferings } = useOfferings({ filterByShoppingCart: false });
  const formatNumber = useFormatNumber();

  useEffect(() => {
    reservations.fetch();
  }, [reservations]);

  useEffect(() => {
    recommendedOfferings.fetch();
  }, [recommendedOfferings]);

  const recommendedOffering = recommendedOfferings.data?.[0];
  const totalNormalizedUnits = recommendedOffering?.normalizedUnits || 0;

  const shoppingCartUnits = filterByShoppingCart
    ? calculateShoppingCartUnits(cartItems, instance.riInstanceNormalizationFactor)
    : calculateRecommendedShoppingCartUnits(recommendedOffering, instance.riInstanceNormalizationFactor);

  const { options, legendItems, tooltipExtractor } = useRiPurchaseChartData({
    riReservationsData: reservations.data,
    totalNormalizedUnits: totalNormalizedUnits,
    shoppingCartUnits: shoppingCartUnits,
  });

  if (
    reservations.isFetching ||
    recommendedOfferings.isFetching ||
    recommendedOfferings.isUninitialized ||
    reservations.isUninitialized
  ) {
    return (
      <Card>
        <Stack height={`${chartHeight}px`} bgcolor={theme.palette.core.color1}>
          <Loader color={AWS_BRAND_COLOR} />
        </Stack>
      </Card>
    );
  }

  if (recommendedOfferings.error) {
    return <EmptyStateError />;
  }

  return (
    <Card>
      <Stack height={`${chartHeight}px`} bgcolor={theme.palette.core.color1}>
        <Box marginLeft="auto" marginTop={4} marginRight={6}>
          {legendItems.length > 0 && <Legend items={legendItems} />}
        </Box>
        <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
        <HighchartTooltip chart={chart}>
          {(context) => {
            const tooltipContent = tooltipExtractor(context);

            const hasTotalValue =
              tooltipContent.total && !tooltipContent.items?.find(({ name }) => name === TARGET_RIS)?.value;

            const riUsed = context.points?.find(({ series }) => series.userOptions.name === RESERVED_USAGE)?.y ?? 0;
            const riUnused = context.points?.find(({ series }) => series.userOptions.name === RESERVED_UNUSED)?.y ?? 0;
            const ownedRis = hasTotalValue ? formatNumber(Number(riUsed) + Number(riUnused)).toString() : null;
            const totalUsage = tooltipContent.items?.find(({ name }) => name === TOTAL_USAGE);

            return (
              <TooltipWrapper>
                {tooltipContent.date && <TooltipValueWithTimeInfo value="" timeInfo={tooltipContent.date} />}
                {totalUsage && (
                  <>
                    <TooltipSeriesValuePair
                      seriesColor={totalUsage.color as string}
                      seriesName={totalUsage.name}
                      value={totalUsage.value}
                      borderColor={theme.palette.core.color5}
                    />
                    <Divider sx={{ borderColor: theme.palette.global.color.coolGray['70'].main }} />
                  </>
                )}
                {tooltipContent.items
                  ?.filter(({ name }) => name !== TOTAL_USAGE)
                  .map((item, index: number) => (
                    <TooltipSeriesValuePair
                      seriesColor={item.color as string}
                      seriesName={item.name}
                      value={item.value}
                      key={item.name || index}
                    />
                  ))}
                {ownedRis !== null && (
                  <TooltipTotal label={t('CLOUD.RIO.OPTIMIZE.CHART.TOOLTIP.OWNED_RIS')} value={ownedRis} />
                )}
              </TooltipWrapper>
            );
          }}
        </HighchartTooltip>
      </Stack>
    </Card>
  );
};

export default BuyInstanceDrawerGraph;
