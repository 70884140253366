import { useMemo } from 'react';
import useTermFormatter from '@vertice/dashboard/src/modules/cloud/hooks/useTermFormater';

import { useInstanceList } from '../../../../providers/InstanceListProvider';
import { useInstanceData } from '../../../../providers/InstanceProvider';
import { useOfferings } from '../../../../providers/OfferingsProvider';

export const useModalData = () => {
  const { product } = useInstanceList();
  const { item } = useInstanceData();
  const { offerings } = useOfferings();
  const formatTerm = useTermFormatter();

  return useMemo(
    () => ({
      product: product.toUpperCase(),
      region: item.region,
      instanceFamily: item.instanceFamily,
      instanceType: item.sizeFlexible ? item.flexGroupMinSize : item.instanceSize,
      details:
        offerings.data?.map((riOffering) => ({
          reservedInstancesOfferingId: riOffering.reservedInstancesOfferingId,
          term: riOffering.term ? formatTerm(riOffering.term) : '',
          quantity: riOffering.quantity,
          riCost: riOffering.riCost,
          monthlyCost: riOffering.monthlyCost,
          upfrontCost: riOffering.upfrontCost,
          expectedSavings: riOffering.expectedSavings,
        })) ?? [],
    }),
    [product, item, offerings, formatTerm]
  );
};
