import React from 'react';
import { Popover as BasePopover } from '@mui/material';
import styled from '@mui/material/styles/styled';

import { useFilterPopover } from './FilterPopoverProvider';
import { useFilterGroupData } from '../FilterProvider';
import FilterFooter from './FilterFooter';

type PopoverProps = {
  children: React.ReactNode;
};

export const FilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1px',
  width: '360px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.core.color3}`,
  background: theme.palette.core.color3,
  boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', distance: '60', depth: '1z' }),
  overflow: 'auto',
}));

const FilterPopover = ({ children }: PopoverProps) => {
  const { anchorEl, close } = useFilterPopover();
  const { groupId } = useFilterGroupData();

  return (
    <BasePopover id={groupId} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={close} keepMounted>
      <FilterWrapper>
        {children}
        <FilterFooter />
      </FilterWrapper>
    </BasePopover>
  );
};

export default FilterPopover;
