import { DoubleCurrencyMoney } from '../types';
import { Contract, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Tooltip } from '@verticeone/design-system/src';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { isNil } from 'lodash';
import { useExchangeToAccountCurrency } from '@vertice/core/src/modules/currency/useExchangeToAccountCurrency';
import { useExchangeCurrency, UseExchangeCurrencyReturn } from '@vertice/core/src/modules/currency/useExchangeCurrency';

const getContractEffectiveCurrency = (contract: Contract, accountCurrency: string) =>
  contract.parts.contractual?.financial?.baseCurrency ?? accountCurrency;

/** @return Savings in the currency of the current (not previous) contract */
const getSavingsVsPrevious = (
  viewOnContract: ViewOnContract,
  accountCurrency: string,
  exchangeCurrency: UseExchangeCurrencyReturn['exchangeCurrency']
) => {
  const { contract: thisContract, prev: prevContract } = viewOnContract;

  const prevContractCostInThisContractCurrency = prevContract
    ? exchangeCurrency({
        value: prevContract.parts.overview?.effectiveAnnualCost ?? 0,
        sourceCurrency: getContractEffectiveCurrency(prevContract, accountCurrency),
        targetCurrency: getContractEffectiveCurrency(thisContract, accountCurrency),
      })
    : undefined;

  return Math.max(
    (prevContractCostInThisContractCurrency ?? 0) - (thisContract.parts.overview?.annualCostWithVertice ?? 0),
    0
  );
};

const NegotiatedCostCell = ({
  value,
  row,
}: {
  value?: DoubleCurrencyMoney;
  row: ViewOnContract;
  prevContract?: ViewOnContract;
}) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { accountCurrency } = useExchangeToAccountCurrency();
  const { exchangeCurrency } = useExchangeCurrency();

  const thisContractEffectiveCurrency = getContractEffectiveCurrency(row.contract, accountCurrency);

  return (
    <Tooltip
      tooltip={
        row.prev && (
          <div>
            {!isNil(row.prev.parts.overview?.effectiveAnnualCost) && (
              <div>
                {formatCurrency(
                  exchangeCurrency({
                    value: row.prev.parts.overview.effectiveAnnualCost,
                    sourceCurrency: getContractEffectiveCurrency(row.prev, accountCurrency),
                    targetCurrency: thisContractEffectiveCurrency,
                  }) ?? NaN,
                  {
                    currency: thisContractEffectiveCurrency,
                    maximumFractionDigits: 0,
                  }
                )}{' '}
                {t('ENTITIES.CONTRACT.LABELS.PREVIOUS_CONTRACT_COST')}
              </div>
            )}

            <div>
              {formatCurrency(getSavingsVsPrevious(row, accountCurrency, exchangeCurrency), {
                currency: thisContractEffectiveCurrency,
                maximumFractionDigits: 0,
              })}{' '}
              {t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_PREVIOUS')}
            </div>
          </div>
        )
      }
    >
      <span>
        {value
          ? formatCurrency(value?.contractCurrencyValue, {
              currency: value?.contractCurrency,
              maximumFractionDigits: 0,
            })
          : null}
      </span>
    </Tooltip>
  );
};

export default NegotiatedCostCell;
