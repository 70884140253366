import {
  ContractCreate,
  useCreateContractMutation,
  useLazyResolveUserPermissionsOnContractQuery,
  useUpdateContractMutation,
  useUploadContractFileV2Mutation,
  ViewOnContract,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useCallback } from 'react';
import { AccessVia, ContractFormData, FetchedDataTypeFromMode, FormSupportedMode } from '../types';
import {
  contractToPurchaseRequestInputPresetConfiguration,
  contractToRenewalRequestInputPresetConfiguration,
  fetchedContractToForm,
  formToApiContract,
  formToMinimalApiPurchaseRequest,
} from '../transformations';
import { augmentWithLineage, augmentWithManagedBy, ContractWorkflowsServiceUrn } from '../utils/augmentContract';
import { UseFormReturn } from 'react-hook-form';
import { UseContractEditModeReturn } from './useContractEditMode';
import { useWaitForRequestStage } from '../utils/useWaitForRequestStage';
import { parseRequestRef } from '../../../../hooks/workflows/refUtils';
import useInvalidateContracts from '../hooks/useInvalidateContracts';
import {
  useCreateContractWithoutInvalidationMutation,
  useUpdateContractWithoutInvalidationMutation,
} from '@vertice/slices';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import useInvalidateContractList from '../hooks/useInvalidateContractList';
import { useDefaultCostModel } from '../costModels/useDefaultCostModel';

const UPDATES_NOT_SUPPORTED_ERROR = 'Contract updates work only in useContract("REQUIRE_FETCHED", ...) mode.';

export type UseContractSavingParams<Mode extends FormSupportedMode> = {
  accessVia: AccessVia;
  fetchedContract: FetchedDataTypeFromMode<Mode>;
  allowRestrictedFields: boolean;
  onlyEditMode: boolean;
  hookForm: UseFormReturn<ContractFormData>;
  editMode: UseContractEditModeReturn;
};

export type UseContractSavingReturn = {
  updateContract: (formData: ContractFormData) => Promise<void>;

  updateContractWithPermissionCheck: (formData: ContractFormData) => Promise<void>;

  /** @returns contract ID of the created contract */
  createNewPurchase: (formData: ContractFormData) => Promise<string>;

  /** @returns contract ID of the created contract */
  createExisting: (formData: ContractFormData) => Promise<string>;

  /** @returns contract ID of the created contract */
  createRenewal: (parentContractId: string, formData: ContractFormData, outsideVertice: boolean) => Promise<string>;
};

const useContractSaving = <Mode extends FormSupportedMode>({
  accessVia,
  fetchedContract,
  allowRestrictedFields,
  onlyEditMode,
  hookForm,
  editMode: { stopAndResetForm },
}: UseContractSavingParams<Mode>): UseContractSavingReturn => {
  const [apiUpdateContract] = useUpdateContractMutation();
  const [apiUpdateContractWithoutInvalidation] = useUpdateContractWithoutInvalidationMutation();
  const [apiCreateContract] = useCreateContractMutation();
  const [apiCreateContractWithoutInvalidation] = useCreateContractWithoutInvalidationMutation();
  const [apiCreateRequest] = useCreateRequestMutation();
  const [apiUploadContractFile] = useUploadContractFileV2Mutation();
  const [resolvePermissions] = useLazyResolveUserPermissionsOnContractQuery();
  const waitForRequestStage = useWaitForRequestStage();
  const invalidateContracts = useInvalidateContracts();
  const invalidateContractList = useInvalidateContractList();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { className: defaultCostModelClass } = useDefaultCostModel();

  /** Call after saving to API has finished. */
  const onUpdateCompleted = useCallback(
    (newViewOnContract: ViewOnContract) => {
      if (!fetchedContract) {
        throw new Error("Contract updates work only in useContract('REQUIRE_FETCHED', ...) mode.");
      }

      const newFormData = fetchedContractToForm({ ...fetchedContract, ...newViewOnContract });
      if (!onlyEditMode) {
        stopAndResetForm(newFormData);
      } else {
        hookForm.reset(newFormData);
      }
    },
    [fetchedContract, onlyEditMode, stopAndResetForm, hookForm]
  );

  const updateContract = useCallback(
    async (formData: ContractFormData) => {
      if (!fetchedContract) {
        throw new Error(UPDATES_NOT_SUPPORTED_ERROR);
      }
      const { viewOnContract } = await apiUpdateContract({
        accountId: accessVia.accountId,
        contractId: fetchedContract.contract.record.contractId,
        updateContractRequest: {
          contract: formToApiContract(formData, 'update', { allowRestrictedFields, defaultCostModelClass }),
        },
      }).unwrap();
      onUpdateCompleted(viewOnContract);
    },
    [
      accessVia.accountId,
      allowRestrictedFields,
      defaultCostModelClass,
      apiUpdateContract,
      fetchedContract,
      onUpdateCompleted,
    ]
  );

  const updateContractWithPermissionCheck = useCallback(
    async (formData: ContractFormData) => {
      if (!fetchedContract) {
        throw new Error(UPDATES_NOT_SUPPORTED_ERROR);
      }
      const { viewOnContract } = await apiUpdateContractWithoutInvalidation({
        accountId: accessVia.accountId,
        contractId: fetchedContract.contract.record.contractId,
        updateContractRequest: {
          contract: formToApiContract(formData, 'update', { allowRestrictedFields, defaultCostModelClass }),
        },
      }).unwrap();

      // We need to check if logged user is still allowed to view the updated contract
      // If he is a regular user and removed himself as a contract owner, he should not see the contract anymore
      const userContractPermissions = await resolvePermissions({
        accountId: accessVia.accountId,
        contractId: fetchedContract.contract.record.contractId,
      });
      const userCanViewContract =
        (!userContractPermissions.error && userContractPermissions?.data?.permissions.r) ?? false;

      if (userCanViewContract) {
        invalidateContracts();
        onUpdateCompleted(viewOnContract);
      } else {
        // Invalidate only contract list to avoid error when fetching data for updated contract that's no longer accessible
        invalidateContractList();
        navigate(routes.CONTRACTS);
      }
    },
    [
      accessVia.accountId,
      allowRestrictedFields,
      defaultCostModelClass,
      apiUpdateContractWithoutInvalidation,
      fetchedContract,
      invalidateContracts,
      invalidateContractList,
      navigate,
      onUpdateCompleted,
      resolvePermissions,
      routes.CONTRACTS,
    ]
  );

  const uploadDocuments = useCallback(
    async (contractId: string, files?: File[]) => {
      for await (const file of files ?? []) {
        await apiUploadContractFile({
          accountId: accessVia.accountId,
          contractId: contractId,
          fileName: file.name,
          body: file,
        });
      }
    },
    [accessVia.accountId, apiUploadContractFile]
  );

  const createNewPurchase = useCallback(
    async (formData: ContractFormData) => {
      // Create the request as a first thing so that it fails fast if there is a missing required field.
      // (we don't want to create the contract and then fail to create the request because of missing fields)
      const purchaseRequestInput = formToMinimalApiPurchaseRequest(formData);

      const { viewOnContract } = await apiCreateContract({
        accountId: accessVia.accountId,
        createContractRequest: {
          contract: augmentWithManagedBy(
            formToApiContract(formData, 'create', { allowRestrictedFields, defaultCostModelClass }) as ContractCreate,
            ContractWorkflowsServiceUrn
          ),
        },
      }).unwrap();

      const contract = viewOnContract.contract;
      const createdContractId = contract.record.contractId;

      await uploadDocuments(createdContractId, formData.files);

      const request = await apiCreateRequest({
        accountId: accessVia.accountId,
        createRequest: {
          type: 'PURCHASE',
          name: `${contract.parts.contractual?.vendor?.vendorName} New Purchase`,
          input: purchaseRequestInput,

          // We link the request to the contract we've created above.
          configurations: [contractToPurchaseRequestInputPresetConfiguration(contract)],
        },
      }).unwrap();

      await waitForRequestStage(accessVia.accountId, parseRequestRef(request.ref).requestId);
      invalidateContracts();

      return createdContractId;
    },
    [
      apiCreateContract,
      accessVia.accountId,
      allowRestrictedFields,
      defaultCostModelClass,
      uploadDocuments,
      apiCreateRequest,
      waitForRequestStage,
      invalidateContracts,
    ]
  );

  const createExisting = useCallback(
    async (formData: ContractFormData) => {
      const { viewOnContract } = await apiCreateContract({
        accountId: accessVia.accountId,
        createContractRequest: {
          contract: formToApiContract(formData, 'create', {
            allowRestrictedFields,
            defaultCostModelClass,
          }) as ContractCreate,
        },
      }).unwrap();

      const contract = viewOnContract.contract;
      const createdContractId = contract.record.contractId;

      await uploadDocuments(createdContractId, formData.files);

      return createdContractId;
    },
    [accessVia.accountId, allowRestrictedFields, defaultCostModelClass, apiCreateContract, uploadDocuments]
  );

  const createRenewal = useCallback(
    async (parentContractId: string, formData: ContractFormData, outsideVertice: boolean) => {
      const contractWithLineage = augmentWithLineage(
        formToApiContract(formData, 'create', { allowRestrictedFields, defaultCostModelClass }) as ContractCreate,
        parentContractId
      );

      // immediate invalidation would refetch parent contract -> "can't be renewed" message would show up for a few secs (ugly)
      const { viewOnContract } = await apiCreateContractWithoutInvalidation({
        accountId: accessVia.accountId,
        createContractRequest: {
          contract: outsideVertice
            ? contractWithLineage
            : augmentWithManagedBy(contractWithLineage, ContractWorkflowsServiceUrn),
        },
      }).unwrap();

      const contract = viewOnContract.contract;
      const createdContractId = contract.record.contractId;

      await uploadDocuments(createdContractId, formData.files);

      if (!outsideVertice) {
        const request = await apiCreateRequest({
          accountId: accessVia.accountId,
          createRequest: {
            type: 'RENEWAL',
            name: `${contract.parts.contractual?.vendor?.vendorName} Renewal`,
            input: {
              parentContractId,
            },

            // We link the request to the contract we've created above.
            configurations: [contractToRenewalRequestInputPresetConfiguration(parentContractId, contract)],
          },
        }).unwrap();

        await waitForRequestStage(accessVia.accountId, parseRequestRef(request.ref).requestId);
        invalidateContracts();
      }
      return createdContractId;
    },
    [
      accessVia.accountId,
      allowRestrictedFields,
      defaultCostModelClass,
      apiCreateContractWithoutInvalidation,
      apiCreateRequest,
      uploadDocuments,
      waitForRequestStage,
      invalidateContracts,
    ]
  );

  return {
    updateContract,
    updateContractWithPermissionCheck,
    createNewPurchase,
    createExisting,
    createRenewal,
  };
};

export default useContractSaving;
