import { ReactElement, RefObject, useMemo } from 'react';
import {
  ContractTab,
  ContractTabNavigationData,
  useContractTabNavigationContext,
} from '../ContractTabNavigationContext';
import { TabProps } from '@verticeone/design-system/src';

export type UseContractTabNavigationParams = {
  tabs: TabDefinition[];
};

export type UseContractTabNavigationReturn = {
  visibleTabs: TabDefinition[];
  activeTab: TabDefinition;

  /**
   * Sets active tab. If you pass `{ scrollIntoView: true }` as second parameter,
   * it also scrolls to the scrollTargetRef (typically the Tabs element)
   */
  setActiveTab: ContractTabNavigationData['setTabId'];

  /**
   * If you call `setActiveTab(someTab, { scrollIntoView: true })`,
   * the viewport scrolls to this ref (typically the Tabs element)
   */
  scrollTargetRef: RefObject<HTMLDivElement>;
};

export type TabDefinition = {
  id: ContractTab;
  label: string;
  element: ReactElement;
  isVisible: boolean;
  otherProps?: TabProps;
};

const useContractTabNavigation = ({ tabs }: UseContractTabNavigationParams): UseContractTabNavigationReturn => {
  const tabNavigationContext = useContractTabNavigationContext();
  const visibleTabs = useMemo(() => tabs.filter((tab) => tab.isVisible), [tabs]);

  const activeTab = useMemo(() => {
    const lastSelectedTab = tabNavigationContext.tabId;
    const selectedVisibleTab = visibleTabs.find((tab) => tab.id === lastSelectedTab);
    return selectedVisibleTab ?? visibleTabs[0];
  }, [tabNavigationContext.tabId, visibleTabs]);

  return {
    visibleTabs,
    activeTab,
    setActiveTab: tabNavigationContext.setTabId,
    scrollTargetRef: tabNavigationContext.scrollTargetRef,
  };
};

export default useContractTabNavigation;
