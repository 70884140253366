type Frequency = 'Hourly' | 'Daily' | 'Monthly' | 'Yearly';

export const ONE_YEAR = 31536000;
export enum PAYMENT_OPTIONS {
  NO_UPFRONT = 'No Upfront',
  PARTIAL_UPFRONT = 'Partial Upfront',
  ALL_UPFRONT = 'All Upfront',
}

export type Price = {
  fixed: number;
  recurring: Array<{
    amount: number;
    frequency: Frequency;
  }>;
};

const getRICost = (payment?: string, instanceCount?: number, duration?: number, price?: Price): number => {
  if (!payment || !instanceCount || !duration || !price) {
    return 0;
  }

  const frequencyToYear: Record<Frequency, number> = {
    Hourly: 24 * 365,
    Daily: 365,
    Monthly: 12,
    Yearly: 1,
  };
  const { amount, frequency } = price.recurring[0];
  const calculatedPrice = amount * frequencyToYear[frequency] * instanceCount * (duration / ONE_YEAR);

  const upfrontCost = price.fixed * instanceCount;

  if (payment === PAYMENT_OPTIONS.PARTIAL_UPFRONT) {
    return upfrontCost + calculatedPrice;
  }

  if (payment === PAYMENT_OPTIONS.ALL_UPFRONT) {
    return upfrontCost;
  }

  return calculatedPrice;
};

export default getRICost;
