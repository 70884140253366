import {
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
  PickersLayoutRoot,
  usePickerLayout,
} from '@mui/x-date-pickers-pro';
import { Stack, SxProps } from '@mui/material';
import { SIZE_DEFINITION } from '../../constants';
import { Divider } from '../../../Divider';
import * as React from 'react';
import DateRangeInput from '../DateRangeInput/DateRangeInput';
import { useStaticDateRangePickerContext } from '../../context/StaticDateRangePickerContext';
import { testProps } from '../../../../utils/testProperties';
import { CustomLayoutProps } from './types';
import { Theme } from '@mui/material/styles';
import InfoText from '../InfoText';

type CustomDoubleLayoutProps = CustomLayoutProps & {
  sx?: SxProps<Theme>;
};

const CustomDoubleLayout = (props: CustomDoubleLayoutProps) => {
  const { size } = useStaticDateRangePickerContext();
  const { shortcuts, content, actionBar } = usePickerLayout(props);
  const { localValues, onChangeDateFrom, onChangeDateTo, testId, sx, infoText } = props;
  const [dateFrom, dateTo] = localValues || [null, null];
  const padding = SIZE_DEFINITION[size].defaultPadding;

  return (
    <PickersLayoutRoot
      ownerState={props}
      {...testProps(testId, 'static-date-range-picker')}
      sx={{
        [`.${pickersLayoutClasses.actionBar}`]: {
          gridColumn: 1,
          gridRow: 2,
        },
        [`.${pickersLayoutClasses.contentWrapper}`]: {
          gridColumn: 2,
          gridRow: 1,
          width: 'min-content',
        },
        ...sx,
      }}
    >
      {shortcuts}
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {infoText && (
          <>
            <InfoText>{infoText}</InfoText>
            <Divider />
          </>
        )}
        {content}
        {!actionBar.props.hidden && (
          <>
            <Divider />
            <Stack direction="row" padding={padding} justifyContent="space-between">
              <DateRangeInput
                dateFrom={dateFrom}
                dateTo={dateTo}
                onChangeDateFrom={onChangeDateFrom}
                onChangeDateTo={onChangeDateTo}
              />
              {actionBar}
            </Stack>
          </>
        )}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
};

export default CustomDoubleLayout;
