import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { isNumber } from 'lodash';

import { UseFilterGroup, useFilterGroup } from './utils/useFilterGroup';

type FilterGroupContextProps = {
  groupId: string;
  group: UseFilterGroup;
  path: string;
  level: number;
};

type FilterGroupContextProviderProps = {
  groupId: string;
  label: string;
  children: React.ReactNode;
};

const FilterGroupContext = createContext<FilterGroupContextProps>({} as FilterGroupContextProps);

const FilterGroupProvider = ({ children, groupId, label }: FilterGroupContextProviderProps) => {
  const parent = useFilterGroupData();
  const group = useFilterGroup(groupId, label);
  const path = useMemo(() => [parent.path, groupId].filter((i) => !!i).join('.'), [parent.path, groupId]);

  useEffect(() => {
    let id: string;
    if (parent.group) {
      id = group.addUpdateListener(parent.group.handleUpdate);
      parent.group.addItem(group);
    }

    return () => {
      if (parent.group) {
        group.removeUpdateListener(id);
      }
    };
  }, [parent, group]);

  return (
    <FilterGroupContext.Provider
      value={{
        groupId,
        group,
        path,
        level: isNumber(parent.level) ? parent.level + 1 : 0,
      }}
    >
      {children}
    </FilterGroupContext.Provider>
  );
};

export const useFilterGroupData = () => useContext(FilterGroupContext);

export default FilterGroupProvider;
