import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CloudOptimizationContextProps {
  isAnnualPeriod: boolean;
  setIsAnnualPeriod: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloudOptimizationContext = createContext<CloudOptimizationContextProps | undefined>(undefined);

export const CloudOptimizationProvider = ({ children }: { children: ReactNode }) => {
  const [isAnnualPeriod, setIsAnnualPeriod] = useState(true);

  return (
    <CloudOptimizationContext.Provider value={{ isAnnualPeriod, setIsAnnualPeriod }}>
      {children}
    </CloudOptimizationContext.Provider>
  );
};

export const useCloudOptimization = (): CloudOptimizationContextProps => {
  const context = useContext(CloudOptimizationContext);
  if (!context) {
    throw new Error('useCloudOptimization must be used within a CloudOptimizationProvider');
  }
  return context;
};
