import { GridComparatorFn, GridPreProcessEditCellProps, GridRowModel, GridSortCellParams } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';
import { ProductItem, ReducedColor, Tier } from './types';
import { DesignSystemColor } from '@verticeone/design-system/src';
import { gridStringOrNumberComparator } from '@mui/x-data-grid/hooks/features/sorting/gridSortingUtils';

export const preProcessNonEmptyCell = (params: GridPreProcessEditCellProps) => {
  const hasError = !params.props.value || params.props.value === '';
  return { ...params.props, error: hasError };
};

export const productComparator =
  (vendorProductsMap: Record<string, Product>) =>
  (v1: string, v2: string, params1: GridSortCellParams<string>, params2: GridSortCellParams<string>) => {
    if (!v1 || !v2) {
      return 0; //This will keep newly added row always at the end regardless of sort direction
    }

    return gridStringOrNumberComparator(vendorProductsMap[v1]?.name, vendorProductsMap[v2]?.name, params1, params2);
  };

export const tierComparator: GridComparatorFn<Tier> = (
  v1: Tier,
  v2: Tier,
  params1: GridSortCellParams<Tier>,
  params2: GridSortCellParams<Tier>
) => {
  const valueGetter = (v: Tier) => v.label;
  return genericComparator<Tier>(valueGetter)(v1, v2, params1, params2);
};

export const valueComparator = (
  v1: string | number,
  v2: string | number,
  params1: GridSortCellParams<string | number>,
  params2: GridSortCellParams<string | number>
) => {
  const valueGetter = (v: string | number) => v;
  return genericComparator<string | number>(valueGetter)(v1, v2, params1, params2);
};

const genericComparator =
  <T>(valueGetter: (value: T) => string | number) =>
  (v1: T, v2: T, params1: GridSortCellParams<T>, params2: GridSortCellParams<T>) => {
    const model = params1.api.state.rows.dataRowIdToModelLookup;
    const v1Item = model[params1.rowNode.id];
    const v2Item = model[params2.rowNode.id];

    if (!v1Item.productId || !v2Item.productId) {
      return 0; //This will keep newly added row always at the end regardless of sort direction
    }

    return gridStringOrNumberComparator(valueGetter(v1), valueGetter(v2), params1, params2);
  };

export const getRowId = (row: GridRowModel) => row.id;

export const asProductItem = (newRow: GridRowModel, vendorProducts: Product[]): ProductItem => {
  return {
    id: newRow.id,
    productId: newRow.productId,
    annualCost: newRow.annualCost,
    numberOfLicences: newRow.numberOfLicences,
    licenseType: newRow.licenseType
      ? {
          id: newRow.licenseType.id,
          label: newRow.licenseType.label,
        }
      : null,
    name: vendorProducts.find((prod) => prod.id === newRow.productId)?.name || '',
  };
};

// Reduce DesignSystemColor to color which are suported by Select component
export const getReducedColor = (color: DesignSystemColor): ReducedColor => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'tertiary':
    case 'neutral':
      return color;
    default:
      return 'neutral';
  }
};
