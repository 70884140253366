import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { ScheduleDetails, Stage } from './types';

export type ScheduleView<T> = T & {
  scheduleDate: string;
};

const WEEKS_BEFORE = 4; // how many weeks before the actual week
const WEEKS_AFTER = 14; // how many wees after the current week
const WEEKS_COUNT = WEEKS_BEFORE + WEEKS_AFTER;

const getWeekStart = (date: Dayjs) => date.startOf('day').startOf('week');

export function useScheduleView<T extends ScheduleDetails>(items: T[] | undefined) {
  const empty = !items;

  const series = useMemo(() => {
    // Initiate map with things that we know
    const dateMap = new Map(
      Array(WEEKS_COUNT)
        .fill(undefined)
        .map((_, i) => {
          const week = getWeekStart(dayjs().subtract(WEEKS_BEFORE - i, 'week'));
          return [
            week.toString(),
            {
              week,
              isCurrentWeek: getWeekStart(dayjs()).isSame(week),
              IN_PROGRESS: [] as ScheduleView<T>[],
              RECENTLY_COMPLETED: [] as ScheduleView<T>[],
              UPCOMING: [] as ScheduleView<T>[],
            },
          ];
        })
    );

    // Fill map with states
    items?.forEach((item) => {
      const { scheduleStage, scheduleDate } = item.schedule;
      if (!scheduleDate || !scheduleStage) {
        return;
      }

      const scheduleWeekStartKey = getWeekStart(dayjs(scheduleDate)).toString();
      const weekStartKeyString = scheduleWeekStartKey.toString();

      if (!dateMap.has(weekStartKeyString)) {
        return;
      }

      // We checked for existence in previous if statement
      dateMap.get(scheduleWeekStartKey)![scheduleStage].push({
        ...item,
        scheduleDate,
      });
    });

    return Array.from(dateMap.values());
  }, [items]);

  return { empty, series };
}

export const stageColor = (stage: Stage) => {
  switch (stage) {
    case 'IN_PROGRESS':
      return 'var(--visualization-color-sequential-primary-light-20)';
    case 'RECENTLY_COMPLETED':
      return 'var(--visualization-color-sequential-secondary-light-20)';
    case 'UPCOMING':
      return 'var(--visualization-color-sequential-primary-light-90)';
  }
};
