import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './components/Layout';
import { Outlet } from 'react-router-dom';

const RIOOverviewLayout = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });

  return (
    <Layout
      getCanBeViewed={({ rio, sprio }) => rio && sprio}
      breadcrumbs={[
        {
          label: t('NAVBAR.RIO_NEW.MAIN'),
        },
      ]}
      title={t('RIO_NEW.OVERVIEW.TITLE')}
    >
      <Outlet />
    </Layout>
  );
};
export default RIOOverviewLayout;
