import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { ReactNode, useMemo } from 'react';
import { TaskRow } from '../types';
import { createRequiredContext } from '@verticeone/design-system/src';

export type TaskContextData = {
  task: TaskRow;
  taskOverview?: TaskOverview;
};

const { TaskContextProvider: RawTaskContextProvider, useTaskContext } = createRequiredContext<TaskContextData, 'Task'>(
  'Task'
);

export { useTaskContext };

export const TaskContextProvider = ({ task, taskOverview, children }: TaskContextData & { children: ReactNode }) => {
  const value = useMemo(() => ({ task, taskOverview }), [task, taskOverview]);
  return <RawTaskContextProvider value={value}>{children}</RawTaskContextProvider>;
};
