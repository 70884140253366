import { useTranslation } from 'react-i18next';
import { Filter } from '../types';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import { useContractCategoryOptions } from '../../../../hooks/useContractCategoryOptions';

const ContractCategoryFilter = () => {
  const { t } = useTranslation();
  const options = useContractCategoryOptions();

  return (
    <GenericMultiselectFilter
      name={Filter.CONTRACT_CATEGORY}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.CONTRACT_CATEGORY')}
      testId={'contract-category-filter'}
    />
  );
};

export default ContractCategoryFilter;
