import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

import CardGrid from '../../../../task/TaskList/CardGrid';
import { TaskRow } from '../../../../task/types';
import { useRequestTasks } from '../../useRequestTasks';
import { useRequestTaskColumns } from '../../useRequestTaskColumns';
import { OnRowClickEvent } from '../../../../task/TaskList/CardGrid/types';
import { TaskDrawer } from '../../../../task/TaskDrawer/TaskDrawer';
import { useRequestContext } from '../../RequestContext';
import { Definitions } from '../../../../definitionsTypes';
import { isProcessDefinition } from '../../../../pocWorkflowSchema';
import { ActiveChildRequest } from './ActiveChildRequest';
import { SubSection } from './SubSection';

const getHasWorkflowServiceTask = (workflowVersion?: WorkflowVersion) => {
  const workflowDefinition = workflowVersion?.workflowDefinition as Definitions | undefined;
  const processDefinition = workflowDefinition?.definitions.find(isProcessDefinition);

  return processDefinition?.process.tasks?.some(({ task }) => task.taskType === 'Service');
};

export const TasksSection = () => {
  const { requestId } = useRequestContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL' });

  const { activeTasks, allTasks, isLoadingTasks, activeChildRequests } = useRequestTasks(requestId);

  const { workflowVersion } = useRequestContext();
  const hasWorkflowServiceTask = getHasWorkflowServiceTask(workflowVersion);

  const taskColumns = useRequestTaskColumns();

  const [drawerTaskId, setDrawerTaskId] = useState<string | undefined>(undefined);
  const onOpenTask: OnRowClickEvent<TaskRow> = (params) => {
    setDrawerTaskId(params.row.id);
  };
  const drawerTask = useMemo(() => {
    return allTasks.find((row) => row.id === drawerTaskId);
  }, [allTasks, drawerTaskId]);

  return (
    <Stack gap={4}>
      <SubSection title={t('SECTIONS.REQUEST_TASKS')}>
        <CardGrid<TaskRow>
          columns={taskColumns}
          rows={activeTasks}
          hideHeaders
          loading={isLoadingTasks}
          loadingRowCount={1}
          activeRowId={drawerTaskId}
          onRowClick={onOpenTask}
          slots={{
            noRowsOverlay: () => <Text variant="body-regular">{t('NO_TASKS')}</Text>,
          }}
          rowHeight={67}
          sortModel={[{ field: 'createdAt', sort: 'desc' }]}
        />
      </SubSection>
      {hasWorkflowServiceTask && (
        <SubSection title={t('SECTIONS.SUB_PROCESSES')}>
          {activeChildRequests.length > 0 ? (
            <>
              {activeChildRequests.map((request) => (
                <ActiveChildRequest key={request.id} request={request} />
              ))}
            </>
          ) : (
            <Text variant="body-regular">{t('NO_ACTIVE_SUB_PROCESSES')}</Text>
          )}
        </SubSection>
      )}
      {drawerTaskId && <TaskDrawer onClose={() => setDrawerTaskId(undefined)} taskRow={drawerTask} />}
    </Stack>
  );
};
