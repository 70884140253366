import { CostModelMaterialized } from '../types';
import { isNil } from 'lodash';
import { isAdvancedLinearApproximationModel } from './AdvancedLinearApproximation/AdvancedLinearApproximation';
import { ContractModel2 } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const isAdvancedCostModel = (
  main?: CostModelMaterialized | ContractModel2,
  other?: CostModelMaterialized | ContractModel2
) => {
  // If the main cost model is not defined, the decision is made based on the other cost model
  return isNil(main) ? isAdvancedLinearApproximationModel(other) : isAdvancedLinearApproximationModel(main);
};
