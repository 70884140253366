import { Button } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';

type FilterButtonProps = {
  label: string;
  onClick: () => void;
  active: boolean;
  disabled?: boolean;
};

export const FilterButton = ({ label, onClick, active, disabled = false }: FilterButtonProps) => {
  return (
    <Button
      variant={active ? 'solid' : 'ghost'}
      size="S"
      color="neutral"
      onClick={onClick}
      aria-pressed={active}
      disabled={disabled}
    >
      <Text variant="button-bold" size="S">
        {label}
      </Text>
    </Button>
  );
};
