import { styled } from '@mui/material';

export const BulletList = styled('ul')`
  margin: 0;
  padding-left: 14px;

  li {
    margin-bottom: 8px;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
`;
