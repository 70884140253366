import { useCallback, useEffect, useRef, useState } from 'react';
import { PaginationToken } from '@vertice/slices';
import { isPlainObject } from '@reduxjs/toolkit';

type OptionalTokenObject = { nextToken?: PaginationToken | undefined };

type UseFetchPaginatedProps<ApiResponse, ApiArgs extends OptionalTokenObject, ItemType extends object> = {
  fetchFn: (args: ApiArgs, preferCache: boolean) => Promise<{ data?: ApiResponse }>;
  getNextTokenFn: (response: ApiResponse) => PaginationToken | undefined;
  getItemsFn: (response: ApiResponse) => ItemType[];
  fetchArgs: ApiArgs;
  preferCache?: boolean;
  skip?: boolean;

  /** If true, the items will be updated as they are fetched, rather than all at once at the end. */
  progressiveLoading?: boolean;
};

export type UseFetchPaginatedReturn<ItemType extends object> = {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  refetch: () => Promise<void>;
  items?: ItemType[];
};

export const fetchAllPages = async <ApiResponse, ApiArgs extends OptionalTokenObject, ItemType extends object>({
  fetchFn,
  getNextTokenFn,
  getItemsFn,
  fetchArgs,
  preferCache,
  onPartialData,
}: {
  fetchFn: (args: ApiArgs, preferCache: boolean) => Promise<{ data?: ApiResponse }>;
  getNextTokenFn: (response: ApiResponse) => PaginationToken | undefined;
  preferCache: boolean;
  getItemsFn: (response: ApiResponse) => ItemType[];
  fetchArgs: ApiArgs;
  onPartialData?: (partialItems: ItemType[]) => void;
}) => {
  const temporaryItems: ItemType[] = [];
  let nextToken: PaginationToken | undefined = fetchArgs.nextToken;
  do {
    const { data } = await fetchFn({ ...fetchArgs, nextToken }, preferCache);
    if (data !== undefined) {
      temporaryItems.push(...getItemsFn(data));
      if (onPartialData) {
        onPartialData(temporaryItems);
      }
      nextToken = getNextTokenFn(data);
    } else {
      nextToken = null;
    }
  } while (nextToken);

  return temporaryItems;
};

/*
  This doesn't work with Tag invalidation - create a custom endpoint via injectEndpoints API in case of need.
 */
export const useFetchPaginated = <ApiResponse, ApiArgs extends OptionalTokenObject, ItemType extends object>({
  fetchFn,
  fetchArgs,
  getItemsFn,
  getNextTokenFn,
  preferCache = true,
  skip = false,
  progressiveLoading = false,
}: UseFetchPaginatedProps<ApiResponse, ApiArgs, ItemType>): UseFetchPaginatedReturn<ItemType> => {
  const [items, setItems] = useState<ItemType[] | undefined>(undefined);
  const isFirstLoadRef = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(!skip);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  // Token is used to detect changes in fetchArgs
  const serializedArgs = serializeArgs({ fetchArgs });

  const refetch = useCallback(async () => {
    setIsFetching(true);
    try {
      const effectivePreferCache = preferCache && isFirstLoadRef.current;
      isFirstLoadRef.current = false;

      const allItems = await fetchAllPages({
        fetchFn,
        getNextTokenFn,
        getItemsFn,
        fetchArgs,
        preferCache: effectivePreferCache,
        onPartialData: progressiveLoading ? setItems : undefined,
      });

      if (!progressiveLoading) {
        setItems(allItems);
      }
      setIsLoading(false);
      setIsFetching(false);
    } catch (error) {
      setIsError(true);
    }
    // Keep initial render values of: fetchArgs, getItemsFn, getNextTokenFn, preferCache, progressiveLoading
    // as they are typically not memoized in the caller component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFn, serializedArgs]);

  useEffect(() => {
    if (skip) return;
    setIsLoading(true);
    setItems(undefined); // throw away obsolete items
    void refetch();
  }, [refetch, skip, serializedArgs]);

  return {
    isLoading,
    isFetching,
    isError,
    items,
    refetch,
  };
};

export const serializeArgs = ({ fetchArgs }: { fetchArgs: any }) =>
  JSON.stringify(fetchArgs, (key, value) =>
    isPlainObject(value)
      ? Object.keys(value)
          .sort()
          .reduce<any>((acc, item) => {
            acc[item] = (value as any)[item];
            return acc;
          }, {})
      : value
  );
