import { useCallback } from 'react';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

type DateFormatOptions = {
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';
};

type DateFormatterProps = {
  value: number;
} & DateFormatOptions;

export const useDateFormat = () => {
  const { locale } = useLocaleContext();

  //prettier-ignore
  return useCallback((value: number, options: DateFormatOptions) => {
    return new Intl.DateTimeFormat(locale, options).format(new Date(value));
  }, [locale]);
};

const DateFormatter = ({ value, ...options }: DateFormatterProps) => {
  const formatter = useDateFormat();

  return <>{formatter(value, options)}</>;
};

export default DateFormatter;
