import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import Highcharts, { XAxisLabelsOptions } from 'highcharts';
import { mergeOptions } from './utils/optionsUtils';

/**
 * @deprecated Use plugins/useStyledHighcharts.ts instead
 */
const useStyledHighcharts = (optionOverrides: Highcharts.Options = {}) => {
  const { typography, palette } = useTheme();

  return useMemo(() => {
    const labels: XAxisLabelsOptions = {
      style: {
        color: palette.text.color1,
        fontSize: typography['body-regular-s'].fontSize as string,
        fontFamily: typography.fontFamily,
      },
    };

    return mergeOptions(optionOverrides, {
      credits: {
        enabled: false, // hides Highcharts license watermark
      },
      chart: {
        type: 'column',
        plotBackgroundColor: palette.core.color1,
        backgroundColor: palette.core.color1,
        spacing: [10, 0, 0, 0],
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: { ...labels, align: 'left', x: 24, useHTML: true },
        maxPadding: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'Dot',
        gridLineColor: palette.core.color5,
        plotLines: [
          {
            color: palette.core.color4,
            width: 1,
            zIndex: 3,
            value: 0,
          },
        ],
      },
      tooltip: {
        style: {
          fontFamily: 'inherit',
          pointerEvents: 'auto',
          top: '0px',
        },
        padding: 0,
        backgroundColor: 'transparent',
        borderColor: 'none',
        shadow: false,
      },
      xAxis: {
        labels: { ...labels, y: 42, useHTML: true },
        gridLineWidth: 0,
        lineWidth: 0,
        tickWidth: 0,
        maxPadding: 0,
        minPadding: 0,
      },
      plotOptions: {
        series: {
          marker: {
            symbol: 'circle',
            lineWidth: 2,
            lineColor: palette.core.bg,
          },
        },
        column: {
          pointPadding: 0.05,
          borderWidth: 0,
          groupPadding: 0,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        pie: {
          size: 254,
          states: {
            hover: {
              enabled: true,
              halo: {
                size: 0,
              },
            },
            inactive: {
              opacity: 1,
            },
          },
          innerSize: '85%',
          dataLabels: {
            connectorColor: palette.core.color4,
            style: {
              fontFamily: typography.fontFamily,
              color: palette.text.color2,
              fontWeight: typography['body-bold-s'].fontWeight?.toString(),
              fontSize: typography['body-bold-s'].fontSize as string,
            },
          },
        },
      },
      legend: { enabled: false },
      title: { text: '' },
    });
  }, [typography, palette, optionOverrides]);
};

export default useStyledHighcharts;
