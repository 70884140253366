export const productToDBEMap = {
  'aurora-mysql': { databaseEngine: 'Aurora MySQL', databaseEdition: 'NA', licenseModel: 'nlr' },
  'aurora-postgresql': { databaseEngine: 'Aurora PostgreSQL', databaseEdition: 'NA', licenseModel: 'nlr' },
  'custom-sqlserver-ee(li)': { databaseEngine: 'Custom SQL Server', databaseEdition: 'Enterprise', licenseModel: 'li' },
  'custom-sqlserver-se(li)': { databaseEngine: 'Custom SQL Server', databaseEdition: 'Standard', licenseModel: 'li' },
  'custom-sqlserver-web(li)': { databaseEngine: 'Custom SQL Server', databaseEdition: 'Web', licenseModel: 'li' },
  mariadb: { databaseEngine: 'MariaDB', databaseEdition: 'NA', licenseModel: 'nlr' },
  mysql: { databaseEngine: 'MySQL', databaseEdition: 'NA', licenseModel: 'nlr' },
  'oracle-ee(byol)': { databaseEngine: 'Oracle', databaseEdition: 'Enterprise', licenseModel: 'byol' },
  'oracle-se2(li)': { databaseEngine: 'Oracle', databaseEdition: 'Standard Two', licenseModel: 'li' },
  'oracle-se2 (byol)': { databaseEngine: 'Oracle', databaseEdition: 'Standard Two', licenseModel: 'byol' },
  postgresql: { databaseEngine: 'PostgreSQL', databaseEdition: 'NA', licenseModel: 'nlr' },
  'sqlserver-ee(li)': { databaseEngine: 'SQL Server', databaseEdition: 'Enterprise', licenseModel: 'li' },
  'sqlserver-ex(li)': { databaseEngine: 'SQL Server', databaseEdition: 'Express', licenseModel: 'li' },
  'sqlserver-se(li)': { databaseEngine: 'SQL Server', databaseEdition: 'Standard', licenseModel: 'li' },
  'sqlserver-web(li)': { databaseEngine: 'SQL Server', databaseEdition: 'Web', licenseModel: 'li' },
} as const;

export type ProductType = keyof typeof productToDBEMap;
