import React from 'react';
import BaseCloudAnalyticsStats from '../../BaseCloudTab/Stats/BaseCloudAnalyticsStats';
import useAccountServicesData from '../Table/useAccountServicesData';

type CloudAccountServicesStatsProps = {
  linkedAccountId: string;
};

const CloudAccountServicesStats = ({ linkedAccountId }: CloudAccountServicesStatsProps) => {
  const { data, isLoading } = useAccountServicesData({
    linkedAccountId,
  });
  return <BaseCloudAnalyticsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountServicesStats;
