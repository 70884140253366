import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Request as BaseRequest } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Request as LegacyRequest } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isRequestRef, parseRequestRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { RequestRow } from './types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { groupBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useListRequestsQuery as useListRequestsLegacyQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { getDataForWidget } from '../RequestDetailPage/widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from '../RequestDetailPage/widgets/Details/widgetDef';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { useListServiceRequestsPaginatedQuery } from '@vertice/slices';
import { LINKED_CONTRACT_WIDGET_URN_PATTERN } from '../RequestDetailPage/widgets/LinkedContracts/widgetDef';
import {
  FINAL_OFFER_WIDGET_URN_PATTERN,
  BASELINE_OFFER_WIDGET_URN_PATTERN,
} from '../RequestDetailPage/widgets/Offer/widgetDef';

export const KEY_DATES_WIDGET_URN_PATTERN = /^widget\/saas\/key-dates\/v\d+$/;
export const KEY_COSTS_WIDGET_URN_PATTERN = /^widget\/saas\/key-costs\/v\d+$/;

export const mapRequestRow = (request: BaseRequest | Request): RequestRow => {
  const { requestId } = parseRequestRef(request.ref);
  const parentRequestId = request.parentRequestRef ? parseRequestRef(request.parentRequestRef).requestId : undefined;
  const vendor = getDataForWidget(request, DETAILS_WIDGET_URN_PATTERN)?.vendor ?? request.verticeReserved?.vendor;
  const contractsWidget =
    getDataForWidget(request, LINKED_CONTRACT_WIDGET_URN_PATTERN) ??
    ('input' in request
      ? { contractId: request.input?.contractId }
      : { contractId: request.verticeReserved?.contractId });
  const keyDateWidget = getDataForWidget(request, KEY_DATES_WIDGET_URN_PATTERN);
  const keyCostWidget = getDataForWidget(request, KEY_COSTS_WIDGET_URN_PATTERN);
  const baselineOfferWidget = getDataForWidget(request, BASELINE_OFFER_WIDGET_URN_PATTERN);
  const finalOfferWidget = getDataForWidget(request, FINAL_OFFER_WIDGET_URN_PATTERN);

  return {
    requestId: requestId,
    requestName: request.name,
    requestType: request.serviceName,
    resultType: request.resultType,
    createdAt: request.createdAt,
    lastActivityAt: request.updatedAt,
    closedAt: request.closedAt,
    status: request.status,
    parentId: parentRequestId,
    vendor: vendor,
    ownerId: request.owner ? parseUserRef(request.owner).userId : undefined,
    keyDates: keyDateWidget,
    keyCosts: keyCostWidget,
    contracts: contractsWidget,
    offersFinal: finalOfferWidget,
    offersBaseline: baselineOfferWidget,
    isPartial: true,
  };
};

const addLegacyRequestRows = (items: LegacyRequest[]): RequestRow[] => {
  const groupedByContractId = groupBy(items, 'contractId');
  const uniqueItems = Object.values(groupedByContractId).map((group) => {
    return group.reduce((previousItem, item) => {
      if (!previousItem || new Date(item.createdAt) > new Date(previousItem.createdAt)) {
        return item;
      }
      return previousItem;
    });
  });

  return uniqueItems.map((request) => {
    const { requestId } = parseRequestRef(request.ref);
    const vendor = request.vendor;

    return {
      requestId: request.contractId || requestId,
      requestName: request.name,
      createdAt: request.createdAt,
      lastActivityAt: request.lastActivityAt,
      closedAt: request.closedAt,
      status: request.status,
      isLegacy: true,
      vendor,
    };
  });
};

export const useRequests = ({ skip }: { skip?: boolean } = {}) => {
  const { accountId } = useAccountContext();

  const {
    data: requests,
    isLoading: isLoadingRequests,
    refetch,
  } = useListServiceRequestsPaginatedQuery(
    {
      accountId,
    },
    { skip }
  );

  const {
    data: legacyRequests,
    isLoading: isLoadingLegacyRequests,
    refetch: refetchLegacyRequests,
  } = useListRequestsLegacyQuery({ accountId }, { skip });

  const isLoading = useMemo(
    () => isLoadingRequests || isLoadingLegacyRequests,
    [isLoadingRequests, isLoadingLegacyRequests]
  );
  const refetchAll = useCallback(async () => {
    await Promise.all([refetch(), refetchLegacyRequests()]);
  }, [refetch, refetchLegacyRequests]);

  useNotificationWebSocketSubscription({
    filter: isRequestRef,
    callback: () => {
      void refetchAll();
    },
  });

  const { rows, ids } = useMemo(() => {
    if (!requests?.items || !legacyRequests?.items) {
      return { rows: [], ids: new Set() };
    }

    const legacyRequestItems = addLegacyRequestRows(legacyRequests.items);
    const requestItems = requests?.items.map(mapRequestRow) || [];
    let requestRows: RequestRow[] = [...requestItems, ...legacyRequestItems];
    if (accountId !== 'VERTICE_WORKFLOW_ADMINISTRATION') {
      requestRows = requestRows.filter((row) => !row.parentId);
    }
    const requestIds = new Set(requestRows.map((row) => row.requestId));

    return { rows: requestRows, ids: requestIds };
  }, [accountId, requests?.items, legacyRequests?.items]);

  return {
    requests: rows,
    requestIds: ids,
    isLoadingRequests: isLoading,
    refetch: refetchAll,
  };
};
