import styled from '@mui/material/styles/styled';
import { Stack } from '@mui/material';

const CommentsWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  borderRadius: theme.spacing(4),
  maxHeight: '100%',
  border: `1px solid ${theme.palette.core.color3}`,
  overflow: 'hidden',
}));

export default CommentsWrapper;
