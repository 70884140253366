import React from 'react';
import ContractFormEntry from '../../ContractFormEntry';
import { AnnualCostOverrideCheckboxField } from '../AnnualCostOverrideCheckboxField';

type Component = typeof AnnualCostOverrideCheckboxField;

const AdvancedNegotiatedCostOverrideFormEntry = () => {
  return (
    <ContractFormEntry<Component, Component>
      name="costModel.negotiated.model.annualCostOverride.enabled"
      width={12}
      component={AnnualCostOverrideCheckboxField}
      readFormFieldDisabled
      writeComponent={AnnualCostOverrideCheckboxField}
    />
  );
};

export default AdvancedNegotiatedCostOverrideFormEntry;
