import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system/src';
import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system/src';
import { SecurityQuestionnaireOverview } from '../shared/formSections/SecurityQuestionnaireOverview';
import { SecurityScoreFormEntry } from './formFields/SecurityScoreFormEntry';

export const SecurityAssessmentForm = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={8}>
      <Stack alignItems="start">
        <SecurityScoreFormEntry name={'securityScore'} />
      </Stack>
      <Accordion variant="label" size="XS" defaultExpanded={true}>
        <AccordionSummary
          title={
            <Text variant="label" size="XS" color="text1">
              {t('INTAKE_FORM.SECURITY_ASSESSMENT.DETAILS')}
            </Text>
          }
        />
        <AccordionDetails>
          <SecurityQuestionnaireOverview />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
