import { useTranslation } from 'react-i18next';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import { Filter } from '../types';
import { useMemo } from 'react';

const ContractOriginFilter = () => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      {
        value: 'EXISTING',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.EXISTING'),
      },
      {
        value: 'RENEWAL_WITHOUT_VERTICE',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.RENEWAL_WITHOUT_VERTICE'),
      },
      {
        value: 'RENEWAL_WITH_VERTICE',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.RENEWAL_WITH_VERTICE'),
      },
      {
        value: 'PURCHASE_WITH_VERTICE',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.PURCHASE_WITH_VERTICE'),
      },
    ],
    [t]
  );

  return (
    <GenericMultiselectFilter
      name={Filter.CONTRACT_ORIGIN}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.CONTRACT_ORIGIN')}
      testId={'contract-origin-filter'}
    />
  );
};

export default ContractOriginFilter;
