/**
 * @deprecated
 *   You most likely want to use our `omitEmptyValues(obj)` utility instead OR `omitBy(obj, isNil)` from `lodash`.
 *
 *   If you really insist on the legacy behavior, please use `pickBy(obj, value => value)` from `lodash`.
 * @param obj
 */
export function removeNullables<T extends Record<string, unknown>>(obj: T): T {
  const res = Object.entries(obj).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return res as T;
}
