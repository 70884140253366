import { Box, Stack, useTheme } from '@mui/material';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { Text } from '@verticeone/design-system/src';
import { Gauge } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { getChipColorBasedOnValue, getPricingScoreValues } from './utils';
import { PricingRates } from './types';
import { IconWrapper } from '@verticeone/design-system/src';
import type { SvgIconComponent } from '@mui/icons-material';
import { HandymanTwoTone, QueryStatsTwoTone, BalanceTwoTone } from '@mui/icons-material';

type ScoreItemProps = {
  icon: SvgIconComponent;
  title: string;
  text: string;
  value: number;
};

const ScoreItem = ({ icon, title, text, value }: ScoreItemProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <IconWrapper size="L" icon={icon} />
          <Text variant="body-bold" size="S">
            {title}
          </Text>
        </Stack>
        <Text variant="body-regular" size="S" color="text2">
          {text}
        </Text>
      </Stack>
      <Box flexShrink={0} flexBasis={42}>
        <Gauge variant="donut" value={value} color={getChipColorBasedOnValue(value)} />
      </Box>
    </Stack>
  );
};

export const VendorPricingCard = (rates: PricingRates) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { simplicityScore, transparencyScore, parityScore, overallScore } = getPricingScoreValues(rates);

  return (
    <Card minWidth={370} height="100%">
      <CardHeader size="S">
        <CardHeaderTitle text={t('VENDOR.PRICING.TITLE')} />
        <CardHeaderDescription>{t('VENDOR.PRICING.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <Stack flexGrow={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          py={4}
          px={6}
          gap={3}
          borderBottom={`1px solid ${palette.core.color3}`}
        >
          <Text variant="body-bold" size="M">
            {t('VENDOR.PRICING.OVERALL_PRICING_CLARITY_SCORE')}
          </Text>
          <Gauge variant="pie" size={48} value={overallScore} color={getChipColorBasedOnValue(overallScore)} />
        </Stack>
        <Stack p={6} gap={4} bgcolor={palette.core.color1} flexGrow={1}>
          <ScoreItem
            icon={HandymanTwoTone}
            title={t('VENDOR.PRICING.SIMPLICITY.TITLE')}
            text={t('VENDOR.PRICING.SIMPLICITY.DESCRIPTION')}
            value={simplicityScore}
          />
          <ScoreItem
            icon={QueryStatsTwoTone}
            title={t('VENDOR.PRICING.TRANSPARENCY.TITLE')}
            text={t('VENDOR.PRICING.TRANSPARENCY.DESCRIPTION')}
            value={transparencyScore}
          />
          <ScoreItem
            icon={BalanceTwoTone}
            title={t('VENDOR.PRICING.PARITY.TITLE')}
            text={t('VENDOR.PRICING.PARITY.DESCRIPTION')}
            value={parityScore}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
