import React, { useEffect } from 'react';
import { ProductList, ProductListProps } from '../ProductList';
import { CostModelProductItem, RollingFrequency } from './types';
import { asCostModelProductItem } from './utils';
import { useExtraColumns } from './useExtraColumns';

export type CostModelItemListProps = Omit<ProductListProps<CostModelProductItem>, 'extraColumns' | 'getProductItem'> & {
  rollingFrequency: RollingFrequency;
};

export const CostModelProductList = ({ rollingFrequency, ...otherProps }: CostModelItemListProps) => {
  const { columns, columnVisibilityModel } = useExtraColumns({ rollingFrequency });
  const { selectedProducts, setSelectedProducts } = otherProps;

  useEffect(() => {
    if (rollingFrequency !== 'NO' && selectedProducts?.some((product) => !!product.allocationSpan)) {
      setSelectedProducts(selectedProducts?.map((product) => ({ ...product, allocationSpan: undefined })));
    }
  }, [selectedProducts, rollingFrequency, setSelectedProducts]);

  return (
    <ProductList<CostModelProductItem>
      extraColumns={columns}
      getProductItem={asCostModelProductItem(otherProps.vendorId)}
      {...otherProps}
      dataGridExtraProps={{
        ...otherProps.dataGridExtraProps,
        columnVisibilityModel: columnVisibilityModel,
      }}
    />
  );
};
