import { PropsWithChildren } from 'react';
import { LocaleContextProvider } from './LocaleContext';
import { I18nContextProvider } from './I18nContext';

/**
 * Provider for all utility hooks that require translations or localization context.
 */
export const UtilsContextProvider = ({ locale, children }: PropsWithChildren<{ locale: string }>) => (
  <LocaleContextProvider locale={locale}>
    <I18nContextProvider locale={locale}>{children}</I18nContextProvider>
  </LocaleContextProvider>
);
