import { useMemo } from 'react';
import {
  ContractContextDataRequireFetched,
  ContractLocator,
  FetchedContract,
  FieldsConfig,
} from '@vertice/core/src/modules/saas/contract/types';
import useFetchContract from '@vertice/core/src/modules/saas/contract/hooks/useFetchContract';
import { Card, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Button } from '@verticeone/design-system/src';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import { VerticeVWhiteIcon } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { useFormatDate } from '@verticeone/utils/formatting';
import useContract from '@vertice/core/src/modules/saas/contract/useContract';
import { ContractContextProvider } from '@vertice/core/src/modules/saas/contract/ContractContext';
import NegotiatedCostContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/NegotiatedCostContractFormEntry';
import TotalSavingsContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/TotalSavingsContractFormEntry';
import { Grid } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import AdvancedNegotiatedCostFormEntry from '@vertice/core/src/modules/saas/contract/components/form/AdvancedNegotiatedCostFormEntry';
import AdvancedAnnualSavingsContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/AdvancedAnnualSavingsContractFormEntry';

// We keep this as a separate component so that we can access the contract context.
const CostFields = () => {
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  return (
    <Grid container hideIfEmpty direction="row" p={6} spacing={8}>
      <Grid item hideIfEmpty xs={12} xl={6}>
        {advancedCostModels ? <AdvancedNegotiatedCostFormEntry /> : <NegotiatedCostContractFormEntry />}
      </Grid>
      <Grid item hideIfEmpty xs={12} xl={6}>
        {advancedCostModels ? <AdvancedAnnualSavingsContractFormEntry /> : <TotalSavingsContractFormEntry />}
      </Grid>
    </Grid>
  );
};

const ContractRenewedCard = ({
  nextFetchedContract,
  fieldsConfig,
}: {
  nextFetchedContract: FetchedContract;
  fieldsConfig?: FieldsConfig<'REQUIRE_FETCHED'>;
}) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();
  const formatDate = useFormatDate();

  const contractContext = useContract(
    'REQUIRE_FETCHED',
    nextFetchedContract.accessedVia,
    nextFetchedContract,
    fieldsConfig
  );
  const completedDate = nextFetchedContract.contract.parts.contractual?.signatory?.signingDate;

  return (
    <ContractContextProvider value={contractContext}>
      <Card>
        <HighlightedCardHeader size="S">
          <CardHeaderTitle text={t('SAAS.CONTRACT_RENEWED_CARD.TITLE')} icon={VerticeVWhiteIcon} />
          <CardHeaderActions>
            <Stack gap={0.5}>
              <Text variant="label" color="text1" size="XS">
                {t(`ENTITIES.CONTRACT.STATUSES.COMPLETED`)}
              </Text>
              {completedDate ? (
                <Text variant="body-regular" color="text1" size="M">
                  {formatDate(completedDate)}
                </Text>
              ) : (
                <Text variant="body-regular" size="M" color="inactive1">
                  —
                </Text>
              )}
            </Stack>
          </CardHeaderActions>
        </HighlightedCardHeader>
        <CostFields />
        <Stack p={4.5} spacing={4} alignItems="start">
          <Button
            fullWidth
            variant="plain"
            color="neutral"
            size="M"
            component={RouterLink}
            to={generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, {
              contractId: nextFetchedContract.contract.record.contractId,
            })}
          >
            {t('ENTITIES.CONTRACT.ACTIONS.VIEW_NEW_CONTRACT')}
          </Button>
        </Stack>
      </Card>
    </ContractContextProvider>
  );
};

export const shouldCardBeVisible = ({ materialized }: Contract, isEditing: boolean) =>
  !isEditing &&
  materialized?.next &&
  materialized.next.contractOrigin === 'RENEWAL_WITH_VERTICE' &&
  materialized.next.simplifiedStage !== 'WORKFLOW_IN_PROGRESS';

export const useContractRenewedCard = (
  { fetchedContract, editMode: { isEditing } }: ContractContextDataRequireFetched,
  fieldsConfig?: FieldsConfig<'REQUIRE_FETCHED'>
) => {
  const nextContractLocator = useMemo((): ContractLocator | undefined => {
    if (!shouldCardBeVisible(fetchedContract.contract, isEditing)) {
      return undefined;
    }
    const nextContractId = fetchedContract.contract.materialized?.next?.contractId;
    return nextContractId ? { accessVia: fetchedContract.accessedVia, contractId: nextContractId } : undefined;
  }, [fetchedContract.accessedVia, fetchedContract.contract, isEditing]);

  const nextFetchedContractQuery = useFetchContract(nextContractLocator);

  return useMemo(
    () =>
      !nextFetchedContractQuery.isLoading
        ? () => <ContractRenewedCard nextFetchedContract={nextFetchedContractQuery.data} fieldsConfig={fieldsConfig} />
        : null,
    [nextFetchedContractQuery, fieldsConfig]
  );
};
