import { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { useGetAccountIntegrationQuery, useGetSlackIntegrationConfigQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Loader } from '@verticeone/design-system/src';
import { IntegrationSetup } from './IntegrationSetup';
import { SLACK_PROVIDER_ID, VERTICE_PROVIDER_ID, Step } from '../common';
import { InitialStep } from './Steps';
import { ConnectionStep } from './Steps';

export const SlackSetupWizardV2 = () => {
  const { accountId } = useAccountContext();

  const { data: slackIntegrationData, isLoading: isLoadingSlackIntegrationData } = useGetAccountIntegrationQuery(
    { accountId: accountId, integrationId: SLACK_PROVIDER_ID },
    { skip: !accountId }
  );
  const { data: verticeIntegrationData, isLoading: isLoadingVerticeIntegrationData } = useGetAccountIntegrationQuery(
    { accountId: accountId, integrationId: VERTICE_PROVIDER_ID },
    { skip: !accountId }
  );
  const { data: configData, isLoading: isLoadingConfigData } = useGetSlackIntegrationConfigQuery({
    accountId,
  });

  const [step, setStep] = useState<Step>(Step.START);
  const handleSetStep = (newStep: Step) => {
    setStep(newStep);
  };

  const effectiveStep = useMemo(() => {
    if (
      step === Step.START &&
      slackIntegrationData?.status === 'ACTIVE' &&
      verticeIntegrationData?.status === 'ACTIVE' &&
      configData?.automaticallyConnectMatchedUsers !== undefined
    ) {
      return Step.CONNECTION;
    }
    return step;
  }, [
    configData?.automaticallyConnectMatchedUsers,
    slackIntegrationData?.status,
    step,
    verticeIntegrationData?.status,
  ]);

  if (isLoadingSlackIntegrationData || isLoadingVerticeIntegrationData || isLoadingConfigData) {
    return (
      <Box sx={{ height: '100%' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <IntegrationSetup activeStep={effectiveStep} setStep={handleSetStep}>
      <InitialStep stepValue={Step.START} />
      <ConnectionStep stepValue={Step.CONNECTION} />
    </IntegrationSetup>
  );
};
