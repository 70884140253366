import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const FormEntrySkeleton = () => (
  <Stack gap={1} position="relative">
    <Skeleton variant="text" animation="wave" sx={{ width: '30%' }} />
    <Skeleton
      variant="text"
      animation="wave"
      sx={{ width: '50%', backgroundColor: 'rgba(0,0,0,0.05)' }}
      height="40px"
    />
  </Stack>
);
