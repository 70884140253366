import React from 'react';
import { Stack } from '@mui/material';
import ScoreBarChart from './ScoreBarChart';
import ScoreBarStats from './ScoreBarStats';

export type ScoreBarChartItem = {
  name: string;
  value: number;
  textColor: string;
  seriesColor: string;
};

type ScoreBarProps = {
  values: ScoreBarChartItem[];
};

const ScoreBar = ({ values }: ScoreBarProps) => {
  return (
    <Stack gap={4}>
      <ScoreBarStats values={values} />
      <ScoreBarChart values={values} />
    </Stack>
  );
};

export default ScoreBar;
