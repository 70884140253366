import React, { ElementType } from 'react';

import FormEntry, {
  ComponentPropsMinusTheAutoFilledOnes,
  FormEntryProps,
} from '@vertice/core/src/modules/forms/fields/FormEntry';

import { ContractFormData } from './types';
import {
  useIsContractFieldEditable,
  useIsContractFieldRequired,
  useIsContractFieldVisible,
} from './hooks/fieldsRulesHooks';

export type ContractFormEntryProps<
  ReadComponent extends ElementType,
  WriteComponent extends ElementType
> = FormEntryProps<ContractFormData, ReadComponent> & {
  writeComponent?: WriteComponent;
  writeComponentProps?: ComponentPropsMinusTheAutoFilledOnes<WriteComponent>;

  /** When rendering ReadComponent, pass disabled=true prop to it. */
  readFormFieldDisabled?: boolean;
};

/** Utility type for custom field that has the read + write component fixed and pre-defined and you can change only their properties.  */
export type ContractFormEntryPropsWithFixedComponents<
  ReadComponent extends ElementType,
  WriteComponent extends ElementType
> = Omit<ContractFormEntryProps<ReadComponent, WriteComponent>, 'component' | 'writeComponent'>;

const ContractFormEntry = <ReadComponent extends ElementType, WriteComponent extends ElementType>({
  name,
  writeComponent,
  writeComponentProps,
  component,
  componentProps,
  readFormFieldDisabled = false,
  disabled = false,
  ...otherProps
}: ContractFormEntryProps<ReadComponent, WriteComponent>) => {
  const isVisible = useIsContractFieldVisible(name);
  const isEditable = useIsContractFieldEditable(name);
  const isRequired = useIsContractFieldRequired(name);

  if (!isVisible) return null;

  return isEditable && writeComponent ? (
    <FormEntry<ContractFormData, WriteComponent>
      name={name}
      component={writeComponent}
      componentProps={writeComponentProps!}
      required={isRequired}
      disabled={disabled}
      {...otherProps}
    />
  ) : (
    <FormEntry<ContractFormData, ReadComponent>
      name={name}
      component={component}
      componentProps={componentProps!}
      disabled={readFormFieldDisabled || disabled}
      required={isEditable && isRequired}
      {...otherProps}
    />
  );
};

export default ContractFormEntry;
