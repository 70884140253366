import React from 'react';
import RoleSelectorWithRoles from './RoleSelectorWithRoles';
import { USER_ROLES, UserRoleType } from '@vertice/core/src/constants/userRoles';

type RoleSelectorProps = {
  role: UserRoleType;
  onRoleChanged: (role: UserRoleType) => void;
  disabled?: boolean;
};

const RoleSelector = ({ role, onRoleChanged, disabled }: RoleSelectorProps) => {
  const roles = [USER_ROLES.user, USER_ROLES.powerUser, USER_ROLES.admin];

  return (
    <RoleSelectorWithRoles
      roles={roles as UserRoleType[]}
      selected={role as UserRoleType}
      disabled={disabled}
      onRoleChanged={onRoleChanged}
    />
  );
};

export default RoleSelector;
