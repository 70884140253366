import React, { useMemo } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import { Text } from '@verticeone/design-system/src';
import type { GraphSeries } from './Graph';

type GraphLegendProps = {
  series: GraphSeries;
};

const GraphLegend = ({ series }: GraphLegendProps) => {
  const { palette } = useTheme();

  const legendItems = useMemo(
    () =>
      series.map((item) => ({
        color: item.states.hover.color,
        label: item.name,
        value: item.data[0],
        id: item.name,
      })),
    [series]
  );

  if (legendItems.length) {
    return (
      <Box alignSelf="flex-start">
        <Stack direction="row" bgcolor={palette.core.color1} py={1} px={2} borderRadius={2} gap={3.5}>
          {legendItems.map((item) => (
            <Stack direction="row" alignItems="center" gap={2} key={item.id}>
              <ColorSquare color={item.color} />
              <Stack direction="row" gap={1}>
                <Text variant="body-regular" size="S" color="text1">
                  {item.label}
                </Text>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  }
};

export default GraphLegend;
