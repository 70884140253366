import { useMemo, useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, styled } from '@mui/material';
import { SortByAlpha } from '@mui/icons-material';
import { SortByZaIcon } from '@verticeone/design-system/src';
import {
  GridSortDirection,
  gridSortModelSelector,
  useGridApiContext,
  GridColumnMenuItemProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system/src';

const SortByDesc = styled((props) => <SortByZaIcon {...props} />)({
  width: '1.1em',
  height: '1.1em',
  fontSize: '0.95rem',
  svg: {
    fill: '#0000008a',
  },
});

const GridColumnMenuSortItem = ({ colDef, onClick }: GridColumnMenuItemProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const sortModel = useGridSelector(apiRef, gridSortModelSelector);

  const sortDirection = useMemo(() => {
    if (!colDef) {
      return null;
    }

    return sortModel.find((item) => item.field === colDef.field)?.sort;
  }, [colDef, sortModel]);

  const onSortMenuItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick(event);
      const direction = event.currentTarget.getAttribute('data-value') || null;
      apiRef.current.sortColumn(colDef, direction as GridSortDirection);
    },
    [apiRef, colDef, onClick]
  );

  if (!colDef || !colDef.sortable) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={onSortMenuItemClick} data-value="asc" disabled={sortDirection === 'asc'}>
        <ListItemIcon>
          <SortByAlpha fontSize="small" />
        </ListItemIcon>
        <Text variant="button-bold">{t('GRID_COLUMN_MENU.SORT_ASCENDING')}</Text>
      </MenuItem>
      <MenuItem onClick={onSortMenuItemClick} data-value="desc" disabled={sortDirection === 'desc'}>
        <ListItemIcon>
          <SortByDesc />
        </ListItemIcon>
        <Text variant="button-bold">{t('GRID_COLUMN_MENU.SORT_DESCENDING')}</Text>
      </MenuItem>
    </>
  );
};

/**
 * @deprecated please use @verticeone/design-system/src/components/DataGrid/components instead
 */
export default GridColumnMenuSortItem;
