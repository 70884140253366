import { useEffect, useState } from 'react';

type Callback = () => void;

export const usePolling = (pollingFn: () => void, interval: number) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingCount, setPollingCount] = useState<number>(0);
  const [onStopCallback, setOnStopCallback] = useState<Callback | null>(null);
  const [onStartCallback, setOnStartCallback] = useState<Callback | null>(null);

  const start = () => {
    setPollingCount(0);
    setIsPolling(true);
    onStartCallback?.();
  };

  const stop = () => {
    setIsPolling(false);
    onStopCallback?.();
  };

  const onStop = (callback: Callback) => {
    setOnStopCallback(callback);
  };

  const onStart = (callback: Callback) => {
    setOnStartCallback(callback);
  };

  useEffect(() => {
    let pollingId: NodeJS.Timeout;

    if (isPolling) {
      pollingId = setInterval(() => {
        pollingFn();
        setPollingCount((prev) => ++prev);
      }, interval);
    }

    return () => {
      if (pollingId) {
        clearInterval(pollingId);
      }
    };
  }, [isPolling, pollingFn, interval, setPollingCount]);

  return {
    start,
    stop,
    isPolling,
    pollingCount,
    onStart,
    onStop,
  };
};
