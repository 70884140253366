import { Stack, styled } from '@mui/material';

const TooltipWrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.color5,
  backgroundColor: theme.palette.core.color6,
  borderRadius: 8,
  padding: '7px',
  gap: '7px',
  zIndex: 1000,
}));

export default TooltipWrapper;
