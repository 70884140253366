import { Box, styled } from '@mui/material';

export const VerticalLine = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: 4,
  backgroundColor: theme.palette.core.color3,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}));

export const MessageButton = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',
  border: `1px solid ${theme.palette.core.color5}`,
  borderRadius: 6,
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 8,
  paddingRight: 8,
}));
