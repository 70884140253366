import { StylesConfig } from 'react-select';
import { MakeStylesParams } from './common';
import { getTextVariantStyle } from '../../Text/utils';
import { GroupBase } from '../types';

export const makeOutlinedStylesConfig = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  theme: { palette },
  error,
  color,
  size,
}: MakeStylesParams): StylesConfig<Option, IsMulti, Group> => ({
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: 30,
    transition: [
      'background-color 200ms cubic-bezier(0.0,0,0.2,1)',
      'box-shadow 400ms ease-out',
      'border 200ms cubic-bezier(0.0,0,0.2,1)',
    ].join(', '),
    background: 'transparent',
    borderColor: (() => {
      if (state.isDisabled) {
        return palette.inactive.color2;
      }
      if (error) {
        return palette.error.color1;
      }
      if (state.isFocused) {
        return palette[color].color3;
      }
      if (state.hasValue) {
        return palette.core.color6;
      }
      return palette.core.color3;
    })(),
    borderWidth: 1,
    borderRadius: 8,
    boxShadow: 'none',
    ...getTextVariantStyle({ variant: 'body-regular', size }),

    '&:hover': {
      borderColor: !state.isFocused ? palette[error ? 'error' : color].hover.color2 : undefined,
    },
  }),
});
