import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router';
import { FormControl, Typography } from '@mui/material';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { parseBase64EncodedToken } from '@verticeone/utils/api';
import { useTranslation } from 'react-i18next';
import { EmailField } from '@vertice/core/src/components/EmailField';
import { FeedbackLabel, Form, FormLabel, SubmitButton } from '@vertice/components/src/Form';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AuthContainer from '../../components/AuthContainer/AuthContainer';
import authStyles from '../../components/Auth.module.scss';
import { useHandleLogIn } from './utils';
import RedirectButton from 'modules/auth/components/RedirectButton';
import { Invitation } from '../../types';

const useDefaultFormValuesFromUrl = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const getEmail = () => {
    if (searchParams.has('token')) {
      return parseBase64EncodedToken<Invitation>(searchParams.get('token'))?.userEmail ?? '';
    }
    if (searchParams.has('userEmail')) {
      return searchParams.get('userEmail') ?? '';
    }

    return location.state?.email;
  };

  return {
    email: getEmail(),
  };
};

function SSOLogin() {
  const { t } = useTranslation();
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const defaultFormValues = useDefaultFormValuesFromUrl();
  const { handleLogIn, error, ssoParamsProvided } = useHandleLogIn();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (form: typeof defaultFormValues) => {
    setIsLoading(true);
    handleLogIn(form.email.trim()).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(location.state ?? {}, { replace: true });
    }

    if (location.state?.status === 'PASSWORD_NOT_MANAGED') {
      enqueueSnackbar(t('AUTH.ERRORS.PASSWORD_UNABLE_TO_RESET'), {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, navigate, location.state]);

  return (
    <AuthContainer>
      <Typography variant="heading-s">{t('LOGIN_SSO.HEADER')}</Typography>
      <Form defaultValues={defaultFormValues} onSubmit={onSubmit} className={authStyles.form}>
        <div className={authStyles['form-wrapper']}>
          <FormControl fullWidth variant="outlined">
            <FormLabel for="email">{t('LOGIN_SSO.EMAIL')}</FormLabel>
            <EmailField<typeof defaultFormValues>
              id="email"
              name="email"
              autoFocus
              required
              placeholder={t('LOGIN_SSO.EMAIL')}
            />
          </FormControl>
          <FeedbackLabel error={error} />
        </div>
        <div className={authStyles.actions}>
          <SubmitButton isLoading={isLoading || ssoParamsProvided}>
            <Typography variant="label-s">{t('LOGIN_SSO.LOGIN')}</Typography>
          </SubmitButton>
          <RedirectButton to={ROUTES.LOGIN}>{t('LOGIN_SSO.LOGIN_PASSWORD')}</RedirectButton>
        </div>
      </Form>
    </AuthContainer>
  );
}

export default SSOLogin;
