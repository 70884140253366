import { ContractLocator, FetchedContract, LoadableContract } from '../types';
import { useGetContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useMemo } from 'react';

/**
 * Fetches all raw data from API that are necessary for rendering a basic contract form.
 *
 * DESIGN NOTE: We like to keep the fetching here in a separate hook so that loading state/spinner rendering can be
 * handled separately and the real-deal `useContract` hook (with computed stuff and complex logic) can already count
 * on the data being there.
 */
const useFetchedContract = (locator?: ContractLocator): LoadableContract<FetchedContract> => {
  if (locator && locator.accessVia.type !== 'account') {
    console.warn('useFetchedContract: accessVia.type !== "account" is not supported yet. Using "account".');
  }

  const contractQuery = useGetContractQuery(
    locator ? { contractId: locator.contractId, accountId: locator.accessVia.accountId } : undefined!,
    { skip: !locator, refetchOnMountOrArgChange: false }
  );
  const viewOnContract = contractQuery.data?.viewOnContract;
  const { contract } = viewOnContract ?? {};

  return useMemo((): LoadableContract<FetchedContract> => {
    const common = {
      isFetching: contractQuery.isFetching,
      refetch: contractQuery.refetch,
    };
    return !locator || contractQuery.isLoading || !contract
      ? {
          isLoading: true,
          ...common,
        }
      : {
          isLoading: false,
          data: {
            // Stick only to raw data from API. Avoid putting computed fields here.
            contract,
            accessedVia: locator.accessVia,
          },
          ...common,
        };
  }, [contractQuery.isFetching, contractQuery.refetch, contractQuery.isLoading, locator, contract]);
};

export default useFetchedContract;
