import { FieldPath, FieldValues } from 'react-hook-form';
import { createContext, useContext } from 'react';
import { ZodSchema } from 'zod';
import { isNestedFieldOptional } from '@verticeone/utils/validation';

/*
 * DESIGN NOTES:
 *
 * This context should be consumed only through hooks provided below.
 *
 * These hooks deliberately don't leak the used schema library (zod) to the consumers. This way we can change the schema
 * library without affecting the consumers.
 */

export type FormSchemaContextData = ZodSchema;

const FormSchemaContext = createContext<FormSchemaContextData | null>(null);

/**
 * Context that makes schema available to the form fields, so that they can check
 * requiredness of fields and stuff like that...
 */
export const FormSchemaProvider = FormSchemaContext.Provider;

export const useIsSchemaFieldRequired = <TFieldValues extends FieldValues>(fieldPath: FieldPath<TFieldValues>) => {
  const schema = useContext(FormSchemaContext);
  return schema ? !isNestedFieldOptional(schema, fieldPath) : undefined;
};
