import React, { createContext, useContext, useEffect, useState } from 'react';
import useWindowDimensions from '@vertice/hooks/src/useWindowDimensions';

const VIEW_BREAKPOINT = 1440;

type LayoutWrapperProps = {
  children: string | JSX.Element | JSX.Element[];
};

type LayoutContextType = {
  isSmall: boolean;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  setOpen: (isOpen: boolean) => void;
  setOpenOverride: (isOpenOverride: boolean | undefined) => void;
};

const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);

export const useMainLayout = () => useContext(LayoutContext) as unknown as LayoutContextType;

const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
  const { width } = useWindowDimensions();
  const isSmall = width < VIEW_BREAKPOINT;
  const [isOpen, setOpen] = useState(!isSmall);
  /**
   * Allow the open state to be overriden by a particular page
   */
  const [isOpenOverride, setOpenOverride] = useState<boolean | undefined>(!isSmall);

  const layoutContext: LayoutContextType = {
    isSmall,
    isOpen: isOpenOverride !== undefined ? isOpenOverride : isOpen,
    setOpen,
    setOpenOverride,
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => (isOpenOverride !== undefined ? setOpenOverride(!isOpenOverride) : setOpen(!isOpen)),
  };

  useEffect(() => {
    setOpen(!isSmall);
  }, [isSmall]);

  return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};

export default LayoutWrapper;
