import React from 'react';
import { Stack } from '@mui/material';
import DataSourceProvider from '../../../DataSourceProvider';
import {
  PurchaseSPButton,
  Recommendation,
  TotalCostInfo,
  DataLoaderComponent,
  MissingSPBanner,
} from '../../components';
import Summary from '../../widgets/Overview/product/SageMaker/Summary';
import Graph from './Graph';

const SageMaker = () => (
  <Stack p={6} gap={4}>
    <DataSourceProvider datasource="SageMaker">
      <DataLoaderComponent>
        <MissingSPBanner pb={6} />
        <Summary />
        <Graph />
        <TotalCostInfo />
        <Recommendation />
        <PurchaseSPButton />
      </DataLoaderComponent>
    </DataSourceProvider>
  </Stack>
);

export default SageMaker;
