import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

type FilterPopoverContextProps = {
  close: () => void;
  open: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
};

type FilterPopoverContextProviderProps = {
  children: React.ReactNode;
};

const FilterPopoverContext = createContext<FilterPopoverContextProps>({} as FilterPopoverContextProps);

const FilterPopoverContextProvider = ({ children }: FilterPopoverContextProviderProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), []);
  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <FilterPopoverContext.Provider value={{ close, open, isOpen, anchorEl }}>{children}</FilterPopoverContext.Provider>
  );
};

export const useFilterPopover = () => useContext(FilterPopoverContext);

export default FilterPopoverContextProvider;
