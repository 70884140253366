import Stack from '@mui/material/Stack';
import React from 'react';

type ColumnProps = {
  direction: 'row' | 'column';
  children: React.ReactNode;
};

const Column = ({ direction, children }: ColumnProps) => (
  <Stack width="100%" direction={direction} justifyContent="space-between">
    {children}
  </Stack>
);

export default Column;
