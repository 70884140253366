import type { Color, PaletteColors, ColorVariants } from '../types';

type PaletteColorState = {
  main: Color;
  hover: Color;
};

type ColorPalette = Record<Extract<PaletteColors, 'white' | 'black'>, PaletteColorState> &
  Record<Exclude<PaletteColors, 'white' | 'black'>, Record<ColorVariants, PaletteColorState>>;

export const colorPalette: ColorPalette = {
  white: { main: '#ffffff', hover: '#191919' },
  black: { main: '#000000', hover: '#F2F2F2' },
  coolGray: {
    10: { main: '#F8FAFC', hover: '#E1E3E7' },
    20: { main: '#F1F5F9', hover: '#DADFE4' },
    30: { main: '#E1E7EF', hover: '#CDD3DC' },
    40: { main: '#C6D2E1', hover: '#B4BFCF' },
    50: { main: '#94A3B8', hover: '#A3B0C2' },
    60: { main: '#65758B', hover: '#7A889C' },
    70: { main: '#48566A', hover: '#626E7F' },
    80: { main: '#344256', hover: '#515D6E' },
    90: { main: '#1D283A', hover: '#3F4858' },
    100: { main: '#0F1729', hover: '#323949' },
  },
  gray: {
    10: { main: '#FAFAFA', hover: '#E3E3E4' },
    20: { main: '#F4F4F5', hover: '#DEDEDF' },
    30: { main: '#E4E4E7', hover: '#D0D0D3' },
    40: { main: '#D0D0D7', hover: '#BFBFC5' },
    50: { main: '#A1A1AA', hover: '#AEAEB6' },
    60: { main: '#727279', hover: '#87878D' },
    70: { main: '#52525B', hover: '#6B6B73' },
    80: { main: '#3F3F46', hover: '#5B5B61' },
    90: { main: '#27272A', hover: '#474749' },
    100: { main: '#18181B', hover: '#3A3A3C' },
  },
  warmGray: {
    10: { main: '#FAFAF9', hover: '#E4E3E2' },
    20: { main: '#F5F5F4', hover: '#DFDFDE' },
    30: { main: '#E8E5E3', hover: '#D3D0CE' },
    40: { main: '#D7D0CC', hover: '#C3BEB9' },
    50: { main: '#A8A29F', hover: '#B4AFAC' },
    60: { main: '#77726E', hover: '#8C8683' },
    70: { main: '#56524E', hover: '#6F6C68' },
    80: { main: '#44403C', hover: '#5F5C58' },
    90: { main: '#292524', hover: '#484544' },
    100: { main: '#1C1917', hover: '#3D3B39' },
  },
  blue: {
    10: { main: '#F0F5FF', hover: '#D8DEEC' },
    20: { main: '#E0ECFF', hover: '#CAD6EC' },
    30: { main: '#C2D9FF', hover: '#ACC4EC' },
    40: { main: '#80ACFA', hover: '#709BE7' },
    50: { main: '#3079F8', hover: '#4E8CF8' },
    60: { main: '#0057FA', hover: '#246FFB' },
    70: { main: '#0041C2', hover: '#2459C9' },
    80: { main: '#002685', hover: '#244699' },
    90: { main: '#001B66', hover: '#243C7D' },
    100: { main: '#001242', hover: '#24335E' },
  },
  purple: {
    10: { main: '#F2F0FF', hover: '#DDD8EC' },
    20: { main: '#F0E5FF', hover: '#DBCEEC' },
    30: { main: '#D3BDFE', hover: '#C1ACEB' },
    40: { main: '#9E7FFA', hover: '#9070E8' },
    50: { main: '#925FF2', hover: '#A276F4' },
    60: { main: '#8B42FF', hover: '#995AFF' },
    70: { main: '#6000F0', hover: '#7724F4' },
    80: { main: '#39008A', hover: '#56249D' },
    90: { main: '#290066', hover: '#47247D' },
    100: { main: '#1B0042', hover: '#3B245E' },
  },
  magenta: {
    10: { main: '#FCF0FF', hover: '#E9D9EB' },
    20: { main: '#F9E6FF', hover: '#E6CFEB' },
    30: { main: '#F2B2FF', hover: '#DFA2EB' },
    40: { main: '#F370FF', hover: '#E068EB' },
    50: { main: '#E543E5', hover: '#E85FE9' },
    60: { main: '#E52EC7', hover: '#E84BCF' },
    70: { main: '#B11B93', hover: '#BE3BA4' },
    80: { main: '#851472', hover: '#973587' },
    90: { main: '#590D52', hover: '#712F6D' },
    100: { main: '#380638', hover: '#552956' },
  },
  red: {
    10: { main: '#FEF1F2', hover: '#ECD8DB' },
    20: { main: '#FDE3E6', hover: '#EBCED2' },
    30: { main: '#FABDC6', hover: '#E9AAB3' },
    40: { main: '#F87272', hover: '#E76668' },
    50: { main: '#EF4343', hover: '#F15E5E' },
    60: { main: '#DC2828', hover: '#E14444' },
    70: { main: '#B40822', hover: '#C02C42' },
    80: { main: '#91081C', hover: '#A12B3C' },
    90: { main: '#620916', hover: '#7A2C37' },
    100: { main: '#4F0814', hover: '#682B34' },
  },
  orange: {
    10: { main: '#FFF2EB', hover: '#EBDBD3' },
    20: { main: '#FFE1D1', hover: '#EBCBBC' },
    30: { main: '#FFC3A3', hover: '#EBB295' },
    40: { main: '#FB923C', hover: '#E78436' },
    50: { main: '#F97415', hover: '#FA8636' },
    60: { main: '#E9590C', hover: '#ED6F2D' },
    70: { main: '#B23C00', hover: '#BF5623' },
    80: { main: '#802B00', hover: '#934823' },
    90: { main: '#4D1B00', hover: '#673A23' },
    100: { main: '#330900', hover: '#522B23' },
  },
  yellow: {
    10: { main: '#FEFCE6', hover: '#ECE7CF' },
    20: { main: '#FEF8CD', hover: '#ECE3B8' },
    30: { main: '#FDEC96', hover: '#EBDA8A' },
    40: { main: '#FDDF49', hover: '#EBCE41' },
    50: { main: '#FACC14', hover: '#FBD334' },
    60: { main: '#E7B008', hover: '#EDBE29' },
    70: { main: '#C88A04', hover: '#D29B26' },
    80: { main: '#B56D08', hover: '#BE8229' },
    90: { main: '#73430C', hover: '#885E2D' },
    100: { main: '#4B290C', hover: '#684A2D' },
  },
  green: {
    10: { main: '#E8FCEF', hover: '#D1E6D9' },
    20: { main: '#C5F7D6', hover: '#B2E2C4' },
    30: { main: '#A9EFC3', hover: '#96DBB0' },
    40: { main: '#59DE8A', hover: '#50CB7E' },
    50: { main: '#21C45D', hover: '#40CD74' },
    60: { main: '#16A249', hover: '#36B063' },
    70: { main: '#157F3C', hover: '#359358' },
    80: { main: '#15562F', hover: '#35704C' },
    90: { main: '#03301E', hover: '#254F3D' },
    100: { main: '#022216', hover: '#254437' },
  },
  cyan: {
    10: { main: '#E6FCFE', hover: '#CFE7EA' },
    20: { main: '#BBF5FC', hover: '#ABE1E8' },
    30: { main: '#A4EFF9', hover: '#92DBE5' },
    40: { main: '#20D3EE', hover: '#1FC2DC' },
    50: { main: '#07B6D5', hover: '#27C0DA' },
    60: { main: '#088EAF', hover: '#29A1BE' },
    70: { main: '#0E7490', hover: '#2E88A1' },
    80: { main: '#145B71', hover: '#337285' },
    90: { main: '#144152', hover: '#335D6C' },
    100: { main: '#062A37', hover: '#274954' },
  },
};
