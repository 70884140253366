import React, { FC } from 'react';
import { getFullName } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import { IconWrapper } from '@verticeone/design-system/src';
import { EditOutlined, PersonOutline } from '@mui/icons-material';
import { Text } from '@verticeone/design-system/src';
import { Stack } from '@mui/material';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';
import { findTaskDefinition, useTaskDefinitionAssigneeNames } from './taskDefinitionUtils';
import { TaskState, WorkflowTaskNodeType } from '../../../model/types';
import { parseUserRef, isUserRef } from '../../../../../../hooks/workflows/refUtils';

type TaskAssigneesProps = {
  taskId: string;
  textColor: string;
  taskType?: WorkflowTaskNodeType;
  taskState?: TaskState;
  isEditIconDisplayed?: boolean;
};

type RenderAssigneesProps = {
  isEditor?: boolean;
  assigneesNames: string[] | null;
  textColor: string;
  taskType?: WorkflowTaskNodeType;
};

const ASSIGNEES_RENDER_LIMIT = 2;

const parseFulfilledBy = (fulfilledBy?: string) => {
  if (!fulfilledBy) {
    return { userId: undefined };
  }

  if (isUserRef(fulfilledBy)) {
    try {
      return { userId: parseUserRef(fulfilledBy).userId };
    } catch (e) {
      return { userId: undefined };
    }
  }

  const parts = fulfilledBy.split(':');

  if (parts.length !== 3) {
    return { userId: undefined };
  }

  return {
    userId: parts[parts.length - 1],
  };
};

const RenderAssignees = ({ assigneesNames, textColor, taskType }: RenderAssigneesProps) => {
  const { t } = useTranslation();
  const shouldShowNoAssigneeMessage = !assigneesNames?.length && taskType === 'USER';

  if (shouldShowNoAssigneeMessage) {
    return (
      <Stack>
        <Text variant="button-bold" size="XS" color="warning">
          {t('ENTITIES.WORKFLOW_TASK.LABELS.NO_ASSIGNEE')}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack>
      {(assigneesNames ?? []).slice(0, ASSIGNEES_RENDER_LIMIT).map((fullName) => (
        <Text key={fullName} variant="button-bold" size="XS" color={textColor}>
          {fullName}
        </Text>
      ))}
      {(assigneesNames ?? []).length > ASSIGNEES_RENDER_LIMIT && (
        <Text variant="button-bold" size="XS" color={textColor} textOverflow={'ellipsis'}>
          (+{(assigneesNames ?? []).length - ASSIGNEES_RENDER_LIMIT})
        </Text>
      )}
    </Stack>
  );
};

export const TaskAssignees: FC<TaskAssigneesProps> = ({
  taskId,
  taskType,
  textColor,
  taskState,
  isEditIconDisplayed = true,
}) => {
  const { usersById, loggedUserId, workflowDefinitions } = useWorkflowRendererContext();

  const { t } = useTranslation();

  const { getTaskDefinitionAssigneeNames } = useTaskDefinitionAssigneeNames();

  if (taskType !== 'USER') {
    return null;
  }

  const getUsersLabel = (assignee: string) => {
    const fullName = getFullName(usersById[assignee]) || assignee;
    return assignee === loggedUserId
      ? t('ENTITIES.ACCOUNT_USER.NAME_WITH_YOU_SUFFIX', { userName: fullName })
      : fullName;
  };

  const parsedUserId = parseFulfilledBy(taskState?.fulfilledBy).userId;

  const taskAssigneesNames = parsedUserId
    ? [getUsersLabel(parsedUserId)]
    : (taskState?.assigneeIds ?? []).map(getUsersLabel);

  const taskDefinition = findTaskDefinition(workflowDefinitions, taskId);

  const definitionsAssigneesNames = getTaskDefinitionAssigneeNames(taskDefinition?.task);

  const assigneesNames =
    !taskState?.status || taskState.status === 'PENDING' ? definitionsAssigneesNames : taskAssigneesNames;

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <IconWrapper icon={PersonOutline} htmlColor={textColor} size="S" />
      <RenderAssignees assigneesNames={assigneesNames} textColor={textColor} taskType={taskType} />
      {isEditIconDisplayed && <IconWrapper icon={EditOutlined} htmlColor={textColor} size="S" />}
    </Stack>
  );
};
