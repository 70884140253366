import { useTranslation } from 'react-i18next';

import { OneLoginIcon } from '@vertice/assets';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type OneLoginSetupCardProps = {
  providerId: string;
};

export const OneLoginSetupCard = ({ providerId }: OneLoginSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="OneLogin"
      providerIcon={<OneLoginIcon />}
      description={t('PREFERENCES.INTEGRATIONS.ONELOGIN.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.ONELOGIN_SETUP}`}
    />
  );
};
