import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';
import React from 'react';
import useSavingsPlanInventoryData from '../useSavingsPlanInventoryData';
import SPInventoryTable from '../components/SPInventoryTable';

const SageMaker = () => {
  const { data, ...loadingStatus } = useSavingsPlanInventoryData({ spType: 'SageMaker' });

  return (
    <LoadableContentWrapper {...loadingStatus}>
      <SPInventoryTable data={data!} />
    </LoadableContentWrapper>
  );
};

export default SageMaker;
