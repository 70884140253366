import { gradientBorder } from '../../utils/css/gradientBorder';
import { DesignSystemVariant, DesignSystemColor, DesignSystemSize } from '../../types';
import { Palette } from '@mui/material';
import { allSizes } from '../../utils/collections';
import { CSSProperties } from '@mui/styled-engine';

type ButtonState = 'primary' | 'hover' | 'focus' | 'loading';

export const getPadding = (
  size: DesignSystemSize,
  isCaption: boolean,
  borderSize: string,
  minPadding: number,
  paddingGrowth: number
) => {
  const paddingForSize = minPadding + paddingGrowth * allSizes.indexOf(size);
  const verticalPadding = paddingForSize * (isCaption ? 2 : 1);
  const horizontalPadding = paddingForSize * 2;
  return `calc(${verticalPadding}px - ${borderSize}) calc(${horizontalPadding}px - ${borderSize})`;
};

type ButtonVariantPropertiesConfig = {
  color: DesignSystemColor;
  size: DesignSystemSize;
  isCaption: boolean;
  minPadding: number;
  paddingGrowth: number;
  borderRadius: string;
  borderWidth: string;
  palette: Palette;
};

export const getButtonVariantProperties = ({
  color,
  size,
  isCaption,
  minPadding,
  paddingGrowth,
  borderRadius,
  borderWidth,
  palette,
}: ButtonVariantPropertiesConfig): Record<DesignSystemVariant, Record<ButtonState, CSSProperties>> => {
  const getPaddingForBorderSize = (borderSize: string) =>
    getPadding(size, isCaption, borderSize, minPadding, paddingGrowth);
  return {
    solid: {
      primary: {
        color: palette.text.color5,
        backgroundColor: palette[color].color2,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack10, radius: borderRadius }),
      },
      hover: {
        color: palette.text.color5,
        backgroundColor: palette[color].hover.color1,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack10, radius: borderRadius }),
      },
      focus: {
        color: palette.text.color5,
        backgroundColor: palette[color].color2,
        outline: `4px solid ${palette[color].color3}`,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack10, radius: borderRadius }),
      },
      loading: {
        color: palette.core.color1,
        backgroundColor: palette[color].color2,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack10, radius: borderRadius }),
      },
    },
    ghost: {
      primary: {
        color: palette[color].color1,
        backgroundColor: palette[color].color4,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack05, radius: borderRadius }),
      },
      hover: {
        color: palette[color].hover.color1,
        backgroundColor: palette[color].hover.color3,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack05, radius: borderRadius }),
      },
      focus: {
        color: palette[color].color1,
        backgroundColor: palette[color].color4,
        outline: `2px solid ${palette[color].color1}`,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack05, radius: borderRadius }),
      },
      loading: {
        color: palette[color].color1,
        backgroundColor: palette[color].color4,
        padding: getPaddingForBorderSize(borderWidth),
        ...gradientBorder({ width: borderWidth, color: palette.gradient.transparentToBlack05, radius: borderRadius }),
      },
    },
    outline: {
      primary: {
        color: palette[color].color1,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('1px'),
        border: `1px solid ${palette[color].color3}`,
      },
      hover: {
        color: palette[color].hover.color1,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('1px'),
        border: `1px solid ${palette[color].hover.color3}`,
      },
      focus: {
        color: palette[color].color1,
        backgroundColor: 'transparent',
        outline: `2px solid ${palette[color].color1}`,
        border: 'none',
        padding: getPaddingForBorderSize('0px'),
      },
      loading: {
        color: palette[color].color1,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('1px'),
        border: `1px solid ${palette[color].color3}`,
      },
    },
    plain: {
      primary: {
        color: palette[color].color1,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('0px'),
      },
      hover: {
        color: palette[color].hover.color1,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('0px'),
      },
      focus: {
        color: palette[color].color3,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('0px'),
      },
      loading: {
        color: palette[color].color1,
        backgroundColor: 'transparent',
        padding: getPaddingForBorderSize('0px'),
      },
    },
  };
};
