import { useVisibleCustomFields } from '../../../CustomFields/useVisibleCustomFields';
import { CustomContractFieldSpec } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CustomFieldFilterConfig } from '../FilterPanel';
import { Filter } from '../types';
import { useMemo } from 'react';

export type UseCustomFieldFiltersParams = {
  skip?: boolean;
};

export type UseCustomFieldFiltersReturn = CustomFieldFilterConfig[];

export const useCustomFieldFilters = ({
  skip = false,
}: UseCustomFieldFiltersParams = {}): UseCustomFieldFiltersReturn => {
  const { visibleFieldsByType } = useVisibleCustomFields({ skip });

  const customFieldSpecToCustomFilter = (fieldSpec: CustomContractFieldSpec): CustomFieldFilterConfig => ({
    ...fieldSpec,
    filterName: `custom_${fieldSpec.id}` as Filter,
  });

  return useMemo(
    () => [
      ...(visibleFieldsByType?.['CHECKBOX']?.map(customFieldSpecToCustomFilter) ?? []),
      ...(visibleFieldsByType?.['TEXT']?.map(customFieldSpecToCustomFilter) ?? []),
      ...(visibleFieldsByType?.['DROPDOWN']?.map(customFieldSpecToCustomFilter) ?? []),
    ],
    [visibleFieldsByType]
  );
};
