import React, { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { DateCell } from '../ContractList/Cells/DateCell';
import DatePickerCell from './cells/DatePickerCell';
import { Text } from '@verticeone/design-system/src';
import { CostModelProductItem, RollingFrequency } from './types';
import DataGridPriceCell from '../../../../../components/DataGridPriceCell/DataGridPriceCell';
import { CostCell, EditableLicensesCell } from '../ProductList/cells';
import TierCell from '../ProductList/cells/TierCell';
import EditableTierCell from '../ProductList/cells/EditableTierCell';
import { tierComparator } from '../ProductList/utils';
import { LicenseCostHeaderCell } from './cells/LicenseCostHeaderCell';
import { TotalCostHeaderCell } from './cells/TotalCostHeaderCell';

export type UseExtraColumnsParams = {
  rollingFrequency: RollingFrequency;
};

export const useExtraColumns = ({ rollingFrequency }: UseExtraColumnsParams) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.COST_MODEL_PRODUCT_LIST' });

  return useMemo(() => {
    const isRolling = !!rollingFrequency && rollingFrequency !== 'NO';
    const licenseCostLabel = isRolling ? t(`LICENSE_COST_${rollingFrequency}`) : t('LICENSE_COST_IN_TERM');
    const totalCostLabel = isRolling ? t(`TOTAL_COST_${rollingFrequency}`) : t('TOTAL_COST_IN_TERM');

    const customSpansColumns: GridColDef<CostModelProductItem>[] = [
      {
        field: 'startDate',
        headerName: t('START_DATE'),
        valueGetter: ({ row }) => row.allocationSpan?.startDate,
        valueSetter: ({ row, value }) => ({
          ...row,
          allocationSpan: { ...row.allocationSpan, startDate: value },
        }),
        renderCell: ({ value }) => (value ? <DateCell date={value} /> : null),
        renderEditCell: (params) => <DatePickerCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
      },
      {
        field: 'endDate',
        headerName: t('END_DATE'),
        valueGetter: ({ row }) => row.allocationSpan?.endDate,
        valueSetter: ({ row, value }) => ({
          ...row,
          allocationSpan: { ...row.allocationSpan, endDate: value } as CostModelProductItem['allocationSpan'],
        }),
        renderCell: ({ value }) => (value ? <DateCell date={value} /> : null),
        renderEditCell: (params) => <DatePickerCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
      },
    ];

    const columns: GridColDef<CostModelProductItem>[] = [
      {
        field: 'licenseType',
        headerName: t('TIER'),
        renderCell: (params) => <TierCell {...params} />,
        renderEditCell: (params) => <EditableTierCell {...params} />,
        sortComparator: tierComparator,
        disableColumnMenu: true,
        editable: true,
        flex: 1.3,
      },
      {
        field: 'licenseCost',
        headerName: licenseCostLabel,
        renderHeader: isRolling ? undefined : (params) => <LicenseCostHeaderCell {...params} />,
        renderCell: (params) => <CostCell {...params} />,
        renderEditCell: (params) => <DataGridPriceCell placeholder={t('LICENSE_COST_PLACEHOLDER')} {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value: licenseCost }) => {
          const { numberOfLicences, totalCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences,
            licenseCost,
            totalCost: numberOfLicences && licenseCost ? numberOfLicences * licenseCost : totalCost,
          };
        },
        editable: true,
        flex: 1,
      },
      {
        field: 'numberOfLicences',
        headerName: t('LICENSES'),
        renderCell: (params) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>,
        renderEditCell: (params) => <EditableLicensesCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value: numberOfLicences }) => {
          const { licenseCost, totalCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences,
            licenseCost,
            totalCost: numberOfLicences && licenseCost ? numberOfLicences * licenseCost : totalCost,
          };
        },
        editable: true,
        flex: 0.5,
        minWidth: 80,
      },
      {
        field: 'totalCost',
        headerName: totalCostLabel,
        renderHeader: isRolling ? undefined : (params) => <TotalCostHeaderCell {...params} />,
        renderCell: (params) => <CostCell {...params} />,
        renderEditCell: (params) => <DataGridPriceCell placeholder={totalCostLabel} {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value }) => {
          const { numberOfLicences, licenseCost, totalCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences: value && licenseCost && !numberOfLicences ? value / licenseCost : numberOfLicences,
            licenseCost: value && numberOfLicences && !licenseCost ? value / numberOfLicences : licenseCost,
            totalCost: !licenseCost || !numberOfLicences ? value : totalCost,
          };
        },
        editable: true,
        flex: 1,
      },
      ...customSpansColumns,
    ];

    return {
      columns,
      columnVisibilityModel: {
        startDate: !isRolling,
        endDate: !isRolling,
      },
    };
  }, [rollingFrequency, t]);
};
