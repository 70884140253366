import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { styled, TextField } from '@mui/material';

const StyledTextField = styled(TextField)`
  & .MuiInput-input {
    padding-left: 12px;
  }
`;

const pattern = /^[0-9]*(\.?[0-9]?[0-9]?)?$/;

export type DataGridPriceCellProps = GridCellParams & {
  placeholder?: string;
};

const DataGridPriceCell = ({ id, value, field, placeholder }: DataGridPriceCellProps) => {
  const apiRef = useGridApiContext();
  const [price, setPrice] = useState<string>();
  const preparePrice = (numericPrice: any) =>
    numericPrice === null || numericPrice === undefined ? '' : (+numericPrice!).toFixed(2);

  useEffect(() => {
    setPrice(preparePrice(value));
  }, [value]);

  const handleValueChange = (newValue: string, isOnBlur?: boolean) => {
    setPrice(newValue);
    if (isOnBlur) {
      const numericValue = !newValue || isNaN(+newValue) ? null : +newValue;
      void apiRef.current.setEditCellValue({ id, field, value: numericValue });
    }
  };

  const onPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (pattern.test(e.target.value)) {
      handleValueChange(e.target.value);
    }
  };

  const onPriceBlur = (e: FocusEvent<HTMLInputElement>) => {
    const v = e.target.value ? parseFloat(e.target.value).toFixed(2) : '';
    handleValueChange(v, true);
  };

  return (
    <StyledTextField
      value={price ?? ''}
      onChange={onPriceChange}
      onBlur={onPriceBlur}
      placeholder={placeholder}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

export default DataGridPriceCell;
