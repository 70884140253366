import React, { forwardRef } from 'react';
import styled from '@mui/material/styles/styled';
import type { DesignSystemVariant, DesignSystemSize } from '../../types';
import type { SvgIconComponent } from '@mui/icons-material';
import { IconWrapper } from '../IconWrapper';
import { Button, ButtonProps, DefaultComponentType } from '../Button';
import { testProps } from '../../utils/testProperties';

export type IconButtonProps<C extends React.ElementType = DefaultComponentType> = ButtonProps<C> & {
  icon: SvgIconComponent;
  fullWidth?: boolean;
};

type StyledIconButtonProps = {
  $size: DesignSystemSize;
  $fullWidth: boolean;
  variant: DesignSystemVariant;
};

export const sizeMap: Record<DesignSystemSize, string> = {
  XL: '40px',
  L: '36px',
  M: '32px',
  S: '28px',
  XS: '24px',
  XXS: '20px',
};

const StyledIconButton = styled(Button)<StyledIconButtonProps>(({ $size, $fullWidth, variant }) => ({
  padding: 0,
  width: $fullWidth ? '100%' : `calc(${sizeMap[$size]} + ${variant === 'outline' ? '2px' : '0px'})`,
  height: `calc(${sizeMap[$size]} + ${variant === 'outline' ? '2px' : '0px'})`,
  '&:hover, &[disabled], &:focus-visible, &:active': {
    padding: 0,
  },
}));

export type IconButtonComponentType = <C extends React.ElementType = DefaultComponentType>(
  props: IconButtonProps<C>
) => JSX.Element;

export const IconButton = forwardRef(
  <C extends React.ElementType = DefaultComponentType>(
    { size = 'M', icon, color, fullWidth, variant = 'solid', testId, ...otherProps }: IconButtonProps<C>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => (
    <StyledIconButton
      {...testProps(testId, 'icon-button')}
      {...otherProps}
      variant={variant}
      $size={size}
      $fullWidth={fullWidth}
      color={color}
      ref={ref}
    >
      <IconWrapper size={size} icon={icon} />
    </StyledIconButton>
  )
) as IconButtonComponentType;
