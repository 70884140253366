import React, { useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import { useTranslation } from 'react-i18next';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import annotations from 'highcharts/modules/annotations';

import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { Card, CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { getTextVariantStyle } from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  cssObjectToString,
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { useGraphData } from './useGraphData';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { getDiagonalCrossPattern, getDotPattern } from './utils';
import { ToggleSwitch } from '@verticeone/design-system/src/components/ToggleSwitch';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';
import { Text } from '@verticeone/design-system/src/components/Text';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { annotationStyle, getHourly } from '../../../components/utils';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);
annotations(Highcharts);

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

type GraphSeriesProps = {
  isOnDemandUsageOnly: boolean;
};

const useGraphSeries = ({ isOnDemandUsageOnly }: GraphSeriesProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.COMPUTE.GRAPH' });
  const { data, isFetching } = useGraphData();

  const dynamicItems = useMemo(
    () => [
      {
        id: 'fargateOnDemandCost',
        type: 'area' as const,
        name: t('FARGATE_LABEL'),
        color: palette.visualization.divergent.tertiary['+50'],
        lineWidth: 1,
        fillColor: getDiagonalCrossPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20']),
        data: data?.map((item) => item.fargateOnDemandCost) ?? [],
        custom: {
          legendColor: palette.visualization.divergent.tertiary['+20'],
          tooltipColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.tertiary['+20']
          ),
          tooltipLabel: t('FARGATE_ON_DEMAND_LABEL'),
          hideLegend: false,
          hideTooltip: false,
        },
        index: 1,
      },
      {
        id: 'fargateSpCoveredCost',
        type: 'area' as const,
        name: t('FARGATE_LABEL'),
        color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20']),
        data: data?.map((item) => item.fargateSpCoveredCost) ?? [],
        custom: {
          legendColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20']),
          tooltipColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20'], 5),
          tooltipLabel: t('FARGATE_SP_RI_LABEL'),
          hideLegend: true,
          hideTooltip: false,
        },
        index: 2,
        visible: !isOnDemandUsageOnly,
      },
      {
        id: 'ec2OnDemandCost',
        type: 'area' as const,
        name: t('EC2_LABEL'),
        color: palette.visualization.divergent.secondary['-50'],
        fillColor: getDiagonalCrossPattern(
          palette.transparent.color2,
          palette.visualization.divergent.secondary['-20']
        ),
        lineWidth: 1,
        data: data?.map((item) => item.ec2OnDemandCost) ?? [],
        custom: {
          legendColor: palette.visualization.divergent.secondary['-20'],
          tooltipColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.secondary['-20']
          ),
          tooltipLabel: t('EC2_ON_DEMAND_LABEL'),
          hideLegend: false,
          hideTooltip: false,
        },
        index: 3,
      },
      {
        id: 'ec2SpCoveredCost',
        type: 'area' as const,
        name: t('EC2_LABEL'),
        color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20']),
        data: data?.map((item) => item.ec2SpCoveredCost) ?? [],
        custom: {
          legendColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20']),
          tooltipColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20'], 5),
          tooltipLabel: t('EC2_SP_RI_LABEL'),
          hideLegend: true,
          hideTooltip: false,
        },
        index: 4,
        visible: !isOnDemandUsageOnly,
      },
      {
        id: 'lambdaOnDemandCost',
        type: 'area' as const,
        name: t('LAMBDA_LABEL'),
        color: palette.visualization.divergent.primary['-50'],
        fillColor: getDiagonalCrossPattern(palette.transparent.color2, palette.visualization.divergent.primary['-30']),
        lineWidth: 1,
        data: data?.map((item) => item.lambdaOnDemandCost) ?? [],
        custom: {
          legendColor: palette.visualization.divergent.primary['-30'],
          tooltipColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.primary['-30']
          ),
          tooltipLabel: t('LAMBDA_ON_DEMAND_LABEL'),
          hideLegend: false,
          hideTooltip: false,
        },
        index: 5,
      },
      {
        id: 'lambdaSpCoveredCost',
        type: 'area' as const,
        name: t('LAMBDA_LABEL'),
        color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.primary['-20']),
        data: data?.map((item) => item.lambdaSpCoveredCost) ?? [],
        custom: {
          legendColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.primary['-20']),
          tooltipColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.primary['-20'], 5),
          tooltipLabel: t('LAMBDA_SP_RI_LABEL'),
          hideLegend: true,
          hideTooltip: false,
        },
        index: 6,
        visible: !isOnDemandUsageOnly,
      },
    ],
    [data, isOnDemandUsageOnly, palette, t]
  );

  return useMemo(
    () => ({
      isFetching,
      categories: data?.map((item) => item.date) ?? [],
      lastDataTimeIndex: data?.findIndex((item) => item.spRiPurchaseRecommendation !== null),
      series: [
        {
          id: 'costEWMA',
          type: 'spline' as const,
          name: t('AVERAGE_COST_LABEL'),
          color: palette.text.color1,
          dashStyle: 'Dash' as const,
          data: data?.map((item) => item.costEWMA) ?? [],
          custom: {
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color1,
            hideLegend: false,
            hideTooltip: false,
          },
          visible: isOnDemandUsageOnly,
          zIndex: 1,
        },
        {
          id: 'spRiPurchaseRecommendation',
          type: 'spline' as const,
          name: t('COVERAGE_RECOMMENDATION_LABEL'),
          color: palette.tertiary.color2,
          data: data?.map((item) => item.spRiPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.tertiary.color2,
            tooltipColor: palette.tertiary.color2,
            hideLegend: false,
            hideTooltip: true,
          },
        },
        {
          id: 'onDemandUsage',
          type: 'line' as const,
          name: t('ON_DEMAND_USAGE_LABEL'),
          data: [],
          custom: {
            legendColor: getDiagonalCrossPattern(palette.text.color3, palette.core.color2),
            hideLegend: false,
            hideTooltip: false,
          },
        },
        {
          id: 'coveredBySPRI',
          type: 'line' as const,
          name: t('USAGE_COVERED_BY_SP_RI_LABEL'),
          data: [],
          custom: {
            legendColor: getDotPattern(palette.text.color3, palette.core.color2, 5),
            hideLegend: false,
            hideTooltip: false,
          },
        },
        ...dynamicItems,
      ].filter(Boolean),
    }),
    [data, dynamicItems, isFetching, isOnDemandUsageOnly, palette, t]
  );
};

type GraphProps = Omit<ReturnType<typeof useGraphSeries>, 'isFetching'>;

const Graph = ({ series, categories, lastDataTimeIndex }: GraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.COMPUTE.GRAPH' });
  const { locale } = useLocaleContext();
  const { palette } = useTheme();
  const [chart, setChartRef] = useChartRef();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const formatDate = useFormatDate();
  const formatCurrency = useFormatCurrency();

  const legend = series
    .filter(({ visible, custom: { hideLegend } }) => visible !== false && hideLegend !== true)
    .map(({ custom: { legendColor: color }, name: label, id }) => ({ id, color, label, outlined: id === 'costEWMA' }));

  const spPurchaseRecommendation =
    series?.find((item) => item.id === 'spRiPurchaseRecommendation')?.data?.[lastDataTimeIndex ?? 0] ?? 0;

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { spacingTop: 12 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            area: {
              stacking: 'normal',
            },
            series: {
              marker: {
                radius: 4,
                enabled: false,
                states: {
                  inactive: { opacity: 0 },
                },
              },
            },
          },
          rangeSelector: {
            selected: 0,
          },
          xAxis: {
            categories,
            minPadding: 0,
            maxPadding: 0,
            labels: { formatter: dayInMonthFormatter },
            plotLines: [
              {
                color: palette.core.color6,
                value: lastDataTimeIndex,
                width: 1,
                zIndex: 3,
              },
            ],
          },
          yAxis: {
            labels: {
              formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 0, 0, true),
              x: 50,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(getTextVariantStyle({ variant: 'button-bold', size: 'S' }) as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          annotations: [
            {
              draggable: '',
              labels: [
                {
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  useHTML: true,
                  style: {
                    ...(getTextVariantStyle({ variant: 'button-bold', size: 'M' }) as Highcharts.CSSObject),
                    color: palette.text.color5,
                  },
                  text: `<div style="${cssObjectToString(annotationStyle(palette))}">${t('AMOUNT_PER_HOUR', {
                    amount: formatCurrency(getHourly(spPurchaseRecommendation ?? 0), {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 2,
                    }),
                  })}</div>`,
                  point: {
                    x: (lastDataTimeIndex ?? 0) + 3,
                    y: spPurchaseRecommendation,
                    xAxis: 0,
                    yAxis: 0,
                  },
                },
              ],
              labelOptions: {
                x: 0,
                y: 16,
              },
            },
          ],
          tooltip: {
            shared: true,
          },
          series,
        },
        opts
      ),
  ]);

  return (
    <>
      <Box marginLeft="auto" marginTop={4} marginRight={6}>
        {<Legend items={legend} />}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ x, points }) => {
          const filteredPoints = points?.filter((point) => !(point.series.userOptions.custom as any).hideTooltip);

          if (!!filteredPoints?.length) {
            return (
              <TooltipWrapper>
                <TooltipValueWithTimeInfo value="" timeInfo={formatDate(x!.toString())} />
                {filteredPoints?.map((point) => (
                  <TooltipSeriesValuePair
                    seriesColor={(point.series.userOptions.custom as any).tooltipColor}
                    seriesName={(point.series.userOptions.custom as any).tooltipLabel || point.series.userOptions.name}
                    value={formatCurrency(point!.y ?? 0, {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    key={point.series.userOptions.id}
                    borderColor={palette.core.color5}
                  />
                ))}
              </TooltipWrapper>
            );
          }
        }}
      </HighchartTooltip>
    </>
  );
};

const GraphContainer = () => {
  const [isOnDemandUsageOnly, setIsOnDemandUsageOnly] = useState(false);
  const { isFetching, ...otherProps } = useGraphSeries({
    isOnDemandUsageOnly,
  });
  const { palette } = useTheme();
  const toggleOnDemandUsageOnlyId = 'toggle-on-demand-usage-only';
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.COMPUTE.GRAPH' });

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle text={t('TITLE')} />
        <CardHeaderActions>
          <Stack gap={2} alignItems="center" direction="row">
            <ToggleSwitch
              id={toggleOnDemandUsageOnlyId}
              size="S"
              color="tertiary"
              checked={isOnDemandUsageOnly}
              onChange={() => {
                setIsOnDemandUsageOnly(!isOnDemandUsageOnly);
              }}
              {...testProps('toggle-on-demand-usage-only', 'switch')}
            />
            <Text variant="body-regular" tag="label" htmlFor={toggleOnDemandUsageOnlyId}>
              {t('TOGGLE')}
            </Text>
          </Stack>
        </CardHeaderActions>
      </CardHeader>
      <Stack bgcolor={palette.core.color1} pb={4} height={456}>
        <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
          <Graph {...otherProps} />
        </LoadableComponent>
      </Stack>
    </Card>
  );
};

export default GraphContainer;
