import { useCallback, useState } from 'react';

/**
 * @example
 * type Features = Array<
 *   | { feature: 'spendInsights'; allowedRecords: Array<'invoice' | 'contact' | 'item'> }
 *   | { feature: 'test'; allowedRecords: Array<'test' | 'test2'> }
 * >;
 */
type Features = Array<{ feature: 'spendInsights'; allowedRecords: Array<'invoice' | 'contact' | 'item'> }>;

type FeatureNames = Features[number]['feature'];
type FeatureByName<Name extends FeatureNames> = Extract<Features[number], { feature: Name }>;
type AllowedRecords<Name extends FeatureNames> = FeatureByName<Name>['allowedRecords'];

export const useIntegrationFeatures = () => {
  const [features, setFeatures] = useState<Features>([
    { feature: 'spendInsights', allowedRecords: ['invoice', 'contact', 'item'] },
  ]);

  const getFeatureAllowedRecords = <FeatureName extends FeatureNames>(feature: FeatureName) =>
    (features.find((item) => item.feature === feature)?.allowedRecords ?? []) as AllowedRecords<FeatureName>;

  const isFeatureEnabled = (feature: FeatureNames) => features.some((item) => item.feature === feature);

  const hasFeatureRecords = useCallback(
    <FeatureName extends FeatureNames>(feature: FeatureName, allowedRecords: AllowedRecords<FeatureName>) => {
      const selectedFeature = features.find((item) => item.feature === feature);
      if (selectedFeature) {
        return (allowedRecords as string[]).every((record) =>
          (selectedFeature.allowedRecords as string[]).includes(record)
        );
      }
      return false;
    },
    [features]
  );

  const changeFeature = useCallback(
    (feature: FeatureNames) => {
      setFeatures((featureList) => {
        if (featureList.some((item) => item.feature === feature)) {
          return featureList.filter((item) => item.feature !== feature);
        }
        return [...featureList, { feature, allowedRecords: [] }];
      });
    },
    [setFeatures]
  );

  const changeFeatureRecords = useCallback(
    <FeatureName extends FeatureNames>(feature: FeatureName, allowedRecords: AllowedRecords<FeatureName>) => {
      setFeatures((featureList) => {
        return [
          ...featureList.filter((item) => item.feature !== feature),
          {
            feature,
            allowedRecords: hasFeatureRecords(feature, allowedRecords) ? [] : allowedRecords,
          } as Features[number],
        ];
      });
    },
    [hasFeatureRecords, setFeatures]
  );

  return {
    changeFeature,
    changeFeatureRecords,
    hasFeatureRecords,
    isFeatureEnabled,
    getFeatureAllowedRecords,
  };
};
