import React from 'react';
import { Grid, styled } from '@mui/material';

type CardFooterProps = {
  children: React.ReactNode;
};

const CardFooter = styled((props) => <Grid item {...props} />)<CardFooterProps>(({ theme }) => ({
  position: 'relative',
  zIndex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '1.5rem',
  isolation: 'isolate',
  backgroundColor: theme.palette.primary.color3,
  border: '1px solid var(--tokens-color-light-core-3)',
  marginLeft: '-1px',
  marginRight: '-1px',
}));

export default CardFooter;
