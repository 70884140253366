import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './en.json';
import enTimezones from './timezonesEng.json';
import enCountries from './countries.json';
import enRecommendations from './cloud/recommendationsByCode_en.json';
import enTestsByCode from './cloud/testsByCode_en.json';

export const resources = {
  en: {
    translation: enTranslation,
    cloudRecommendations: enRecommendations,
    cloudTests: enTestsByCode,
    timezones: enTimezones,
    countries: enCountries,
  },
};

void i18n.use(initReactI18next).init({
  defaultNS: 'translation',
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
