import GenericTextFilter from './GenericTextFilter';
import { CustomContractFieldTextType } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';
import { Filter } from '../types';

export type CustomTextFieldFilterProps = {
  config: CustomContractFieldTextType & { filterName: Filter };
};

const CustomTextFieldFilter = ({ config }: CustomTextFieldFilterProps) => {
  const { t } = useTranslation();

  return (
    <GenericTextFilter
      name={config.filterName}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.SEARCH_CUSTOM', { fieldName: config.label })}
      testId={`${config.filterName}-filter`}
    />
  );
};

export default CustomTextFieldFilter;
