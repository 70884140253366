import React, { useEffect } from 'react';
import { type NavigationTab, useBuyInstanceDrawerTabs } from './BuyInstanceDrawerTabs';
import { useShoppingCart } from '../providers/ShoppingCartProvider';

type BuyInstanceDrawerShoppingTableProps = {
  tabId: NavigationTab;
  filterByShoppingCart?: boolean;
  children: React.ReactNode;
};

const BuyInstanceDrawerTab = ({ tabId, filterByShoppingCart, children }: BuyInstanceDrawerShoppingTableProps) => {
  const { getIsVisible } = useBuyInstanceDrawerTabs();
  const { setFilterByShoppingCart } = useShoppingCart();

  useEffect(() => {
    if (getIsVisible(tabId)) {
      setFilterByShoppingCart(!!filterByShoppingCart);
    }
  }, [getIsVisible, tabId, setFilterByShoppingCart, filterByShoppingCart]);

  if (!getIsVisible(tabId)) {
    return null;
  }

  return children;
};

export default BuyInstanceDrawerTab;
