import { useEffect } from 'react';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

function useNavigateToContractWithDelay(contractId?: string, delay = 2500) {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  useEffect(() => {
    const id = setTimeout(() => {
      if (contractId) {
        navigate(routes.CONTRACTS.DETAIL, { contractId });
      } else {
        navigate(routes.CONTRACTS);
      }
    }, delay);
    return () => clearTimeout(id);
  }, [contractId, navigate, routes.CONTRACTS, routes.CONTRACTS.DETAIL, delay]);
}

export default useNavigateToContractWithDelay;
