import React from 'react';
import { Box, useTheme } from '@mui/material';

export type InactiveCellProps = {
  children?: React.ReactNode;
};

const InactiveCell = ({ children }: InactiveCellProps) => {
  const { palette } = useTheme();

  return (
    <Box px={4} display="flex" alignItems="center" width="100%" height="100%" bgcolor={palette.inactive.color3}>
      {children}
    </Box>
  );
};

export default InactiveCell;
