import { useTranslation } from 'react-i18next';
import { Filter } from '../types';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import useRollingFrequencyOptions from '../../../../hooks/useRollingFrequencyOptions';

const RollingFrequencyFilter = () => {
  const { t } = useTranslation();
  const options = useRollingFrequencyOptions();

  return (
    <GenericMultiselectFilter
      name={Filter.ROLLING_FREQUENCY}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.ROLLING_FREQUENCY')}
      testId={'rolling-frequency-filter'}
    />
  );
};

export default RollingFrequencyFilter;
