import { Grid, styled } from '@mui/material';
import { useParams } from 'react-router-dom';

import { hasApplicationData } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import UsageTile from '../components/UsageTile';
import ActiveUser from '../components/ActiveUser';
import { useApplication } from '../hooks/useApplication';
import UseLinkedContracts from '../components/LinkedContracts/LinkedContracts';
import DataHealth from '../components/DataHealth/DataHealth';
import ActivityFrequency from '../components/ActivityFrequency/ActivityFrequency';

const StyledGridItem = styled(Grid)`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Application = () => {
  const { applicationId } = useParams();
  const { application, isFetching } = useApplication(applicationId);
  const appHasData = hasApplicationData(application);

  if (!application) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      <StyledGridItem item xs={12}>
        {appHasData && <UsageTile application={application} />}
      </StyledGridItem>
      <StyledGridItem item xs={12} md={7}>
        <ActiveUser application={application} />
      </StyledGridItem>
      <StyledGridItem item xs={12} md={5}>
        <ActivityFrequency isLoading={isFetching} application={application} />
        <DataHealth application={application} />
        <UseLinkedContracts application={application} />
      </StyledGridItem>
    </Grid>
  );
};
