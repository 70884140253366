import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  Order,
  useLazyRioTableQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { Button } from '@verticeone/design-system/src';
import React from 'react';
import { IconWrapper } from '@verticeone/design-system/src';
import { FileDownloadOutlined } from '@mui/icons-material';

type RioTableDataDownloadButtonProps = {
  code: string;
};

const RioTableDataDownloadButton = ({ code }: RioTableDataDownloadButtonProps) => {
  const { accountId } = useAccountContext();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [getRequest] = useLazyRioTableQuery({
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.testDataTableQuery?.table ? result.data.testDataTableQuery.link : null,
    }),
  });

  const downloadFile = (link?: string) => {
    if (!link) {
      return;
    }

    setIsDownloading(false);
    const a = document.createElement('a');
    a.href = link;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownload = async () => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);

    const response = await getRequest({
      code,
      columns: [],
      accountId,
      orderColumns: [],
      order: Order.Desc,
      withLink: true,
    });

    downloadFile(response?.data?.testDataTableQuery?.link);

    setIsDownloading(false);
  };

  return (
    <Button variant="solid" color="tertiary" size="M" isLoading={isDownloading} onClick={handleDownload}>
      <IconWrapper icon={FileDownloadOutlined} />
    </Button>
  );
};

export default RioTableDataDownloadButton;
