import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { gridClasses, type GridSortModel } from '@mui/x-data-grid-pro';

import { ifExpression } from '@verticeone/design-system/src';
import { DataGrid } from '@verticeone/design-system/src';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import {
  Order,
  useRioTableQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type ColumnOverride<Columns extends Array<string>> = (
  value: string | number,
  row: Record<Columns[number], any>
) => React.ReactNode;

type AlignType = 'left' | 'right' | 'center';

type ColumnSettingsType = {
  align?: AlignType;
  headerAlign?: AlignType;
  flex?: number;
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
};

type RIOGenericTableProps<Columns extends Array<string>> = {
  code: string;
  dataViewName?: string;
  testId: string;
  columns: Columns;
  getRowId: (row: Record<Columns[number], any>) => string;
  translationKey: string;
  onLoad?: (data: Array<Record<Columns[number], any>>) => void;
  renderCell?: Partial<Record<Columns[number], ColumnOverride<Columns>>>;
  valueGetter?: Partial<Record<Columns[number], ColumnOverride<Columns>>>;
  defaultSortColumns?: { field: Columns[number]; sort: 'asc' | 'desc' };
  visibleRowsCount?: number;
  limit?: number;
  columnSettings?: Partial<Record<Columns[number], ColumnSettingsType>>;
  columnVisibilityModel?: Partial<Record<Columns[number], boolean>>;
};

const ROW_HEIGHT = 48;

const RIOGenericTable = <Columns extends Array<string>>({
  code,
  dataViewName,
  testId,
  columns,
  getRowId,
  renderCell,
  valueGetter,
  translationKey,
  defaultSortColumns,
  visibleRowsCount = 10,
  limit,
  onLoad,
  columnSettings,
  columnVisibilityModel,
}: RIOGenericTableProps<Columns>) => {
  const theme = useTheme();
  const { accountId } = useAccountContext();
  const { t } = useTranslation(undefined, { keyPrefix: translationKey });
  const [sortModel, setSortModel] = useState<GridSortModel>([defaultSortColumns].filter((i) => !!i) as GridSortModel);

  const { data: tableData, isFetching } = useRioTableQuery(
    {
      code,
      dataViewName,
      columns,
      accountId: accountId!,
      orderColumns: [sortModel[0]?.field].filter((i) => !!i),
      order: (sortModel[0]?.sort?.toUpperCase() as Order) ?? Order.Desc,
      withLink: false,
      limit,
    },
    {
      skip: !accountId,
      selectFromResult: (result) => {
        const data = result.data?.testDataTableQuery?.table
          ? (getTableData(result.data.testDataTableQuery.table) as Array<Record<Columns[number], any>>)
          : [];

        onLoad?.(data);
        return { ...result, data };
      },
    }
  );

  const tableColumns = useMemo(() => {
    return columns.map((key) => ({
      field: key,
      headerName: t(`COLUMN.${key.toUpperCase()}`),
      editable: false,
      ...((columnSettings as any)?.[key] || { flex: 1 }), // flex: 1 as fallback
      ...ifExpression(renderCell && key in renderCell, {
        renderCell: ({ value, row }: { value: any; row: any }) => (renderCell as any)[key](value, row),
      }),
      ...ifExpression(valueGetter && key in valueGetter, {
        valueGetter: ({ value, row }: { value: any; row: any }) => (valueGetter as any)[key](value, row),
      }),
    }));
  }, [columnSettings, columns, renderCell, t, valueGetter]);

  const rowsCount = tableData?.length || 1;
  const height = ROW_HEIGHT * Math.min(rowsCount, visibleRowsCount) + ROW_HEIGHT; // rows + header

  return (
    <Stack height={`${height}px`}>
      <DataGrid
        loading={isFetching}
        loadingStyle="skeleton"
        onSortModelChange={setSortModel}
        columns={tableColumns as any}
        rows={tableData || []}
        initialState={{
          ...ifExpression(!!defaultSortColumns, {
            sorting: {
              sortModel: [defaultSortColumns],
            },
          }),
          ...ifExpression(!!columnVisibilityModel, {
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }),
        }}
        testId={testId}
        sortingMode="client"
        color={AWS_BRAND_COLOR}
        disableRowSelectionOnClick
        showColumnVerticalBorder
        showCellVerticalBorder
        size="M"
        hideFooter
        getRowId={getRowId}
        noBorder
        noBorderRadius
        scrollbarSize={12}
        sx={{
          flex: 1,
          [`.${gridClasses.cell}`]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
          },
          [`.${gridClasses.columnHeader}`]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
          },
        }}
      />
    </Stack>
  );
};

export default RIOGenericTable;
