import { DesignSystemSize } from '../../types';

/**
 * Size scales that are used across the design system
 */

export const baseSizes: Record<DesignSystemSize, string> = {
  XL: '20px',
  L: '18px',
  M: '16px',
  S: '14px',
  XS: '12px',
  XXS: '10px',
};

export const reducedSizes: Record<DesignSystemSize, string> = {
  XL: '10px',
  L: '9px',
  M: '8px',
  S: '7px',
  XS: '6px',
  XXS: '5px',
};

export const multiItemGapSizes: Record<DesignSystemSize, string> = {
  XL: '6px',
  L: '6px',
  M: '4px',
  S: '4px',
  XS: '2px',
  XXS: '2px',
};
