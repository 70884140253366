import React from 'react';
import { Stack, styled } from '@mui/material';
import NavigationItem, { NavigationItemProps } from './NavigationItem';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';

type NavigationDropdownProps = {
  isVisible?: boolean;
  children?: React.ReactNode;
} & NavigationItemProps;

const NavigationDropdownChildren = styled(Stack)(({ theme }) => ({
  position: 'relative',
  paddingLeft: '33px',
  '&:before': {
    display: 'flex',
    content: '""',
    width: '1px',
    height: 'calc(100% - 10px)',
    position: 'absolute',
    left: '20px',
    top: '5px',
    opacity: '0.6',
    background: theme.palette.neutral.color2,
  },
}));

const NavigationDropdown = ({ isVisible = true, children, ...otherProps }: NavigationDropdownProps) => {
  const { isOpen } = useMainLayout();
  if (!isVisible) {
    return null;
  }

  return (
    <Stack direction="column">
      <NavigationItem {...otherProps} />
      {isOpen && <NavigationDropdownChildren>{children}</NavigationDropdownChildren>}
    </Stack>
  );
};

export default NavigationDropdown;
