import React from 'react';
import { Grid, GridProps, styled } from '@mui/material';
import type { StyledComponent } from 'styled-components';

import { StatsTile } from './StatsTile/StatsTile';

export type StatsContainerProps = Omit<GridProps, 'item'>;

export const StatsContainer = styled((props) => <Grid item {...props} />)({
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  [StatsTile]: {
    '&:not(:first-child)': {
      paddingLeft: '6px',
    },
    '&:not(:last-child)': {
      paddingRight: '6px',
    },
  },
}) as StyledComponent<typeof Grid, StatsContainerProps>;
