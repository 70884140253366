import MuiInputAdornment, { InputAdornmentProps as MuiInputAdornmentProps } from '@mui/material/InputAdornment';
import styled from '@mui/material/styles/styled';
import { Theme } from '@mui/material/styles';
import { CustomizedProps, DesignSystemColor, DesignSystemSize } from '../../types';
import { TextFieldVariant } from '../TextField';
import { textFieldVariantDefinitions } from '../TextField';
import sizeDefinitions from '../TextField/styledVariants/sizeDefinitions';
import { allSizes } from '../../utils/collections';
import { getTextVariantStyle } from '../Text';

export type InputAdornmentProps = CustomizedProps &
  Omit<MuiInputAdornmentProps, 'size' | 'variant' | 'color'> & {
    variant?: TextFieldVariant;
    size?: DesignSystemSize;
    color?: DesignSystemColor;
  };

const StyledInputAdornment = styled(MuiInputAdornment)<MuiInputAdornmentProps & { theme: Theme }>(
  ({ theme: { palette } }) => ({
    '&.MuiInputAdornment-root': {
      color: palette.text.color3,
    },

    '&.MuiInputAdornment-filled:not(.MuiInputAdornment-hiddenLabel)': Object.fromEntries(
      allSizes.map((size) => {
        const { labelLineHeight, gapY } = sizeDefinitions[size];
        return [`&.MuiInputAdornment-size${size}`, { marginTop: labelLineHeight + gapY }];
      })
    ),
    '&.MuiInputAdornment-root.MuiInputAdornment-positionStart': Object.fromEntries(
      allSizes.map((size) => [`&.MuiInputAdornment-size${size}`, { marginRight: sizeDefinitions[size].gapX }])
    ),
    '&.MuiInputAdornment-root.MuiInputAdornment-positionEnd': Object.fromEntries(
      allSizes.map((size) => [`&.MuiInputAdornment-size${size}`, { marginLeft: sizeDefinitions[size].gapX }])
    ),

    '&.MuiInputAdornment-root > .MuiTypography-root': {
      color: palette.text.color3,
    },

    ...Object.fromEntries(
      allSizes.map((size) => [
        `&.MuiInputAdornment-size${size} > .MuiTypography-root`,
        getTextVariantStyle({ variant: 'body-regular', size }),
      ])
    ),
  })
);

export const InputAdornment = ({ variant, ...others }: InputAdornmentProps) => (
  <StyledInputAdornment variant={variant ? textFieldVariantDefinitions[variant]?.muiVariant : undefined} {...others} />
);
