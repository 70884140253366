import { useAuthentication } from './AuthenticationContext';
import AuthUser from './AuthUser';

/**
 * Convenience hook that returns the currently logged-in user.
 *
 * WARNING: It throws an error if the user is not logged in, so use only in contexts where the user is guaranteed to be
 * logged in.
 *
 * For most use-cases, this is convenient because we don't have to check user to be `undefined`.
 *
 * However, if you need a hook that will work even before auth finishes, use `const { user } = useAuthentication();` directly.
 */
const useLoggedUser = (): AuthUser => {
  const { user } = useAuthentication();
  if (!user) {
    throw new Error('You are trying to use useLoggedUser in a moment when the user is not yet available.');
  }
  return user;
};

export default useLoggedUser;
