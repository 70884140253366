import React from 'react';
import { Grid } from '@mui/material';
import SpendInsightsStatsCard from '../../../../modules/cloud/cards/SpendInsightsStatsCard/SpendInsightsStatsCard';
import ForecastCard from '../../../../modules/cloud/cards/ForecastCard/ForecastCard';
import SpendByServiceLineCard from '../../../../modules/cloud/cards/SpendByServiceLineCard/SpendByServiceLineCard';
import TotalSpendCard from '../../../../modules/cloud/cards/TotalSpendCard/TotalSpendCard';
import SpendByTransactionTypeCard from '../../../../modules/cloud/cards/SpendByTransactionTypeCard/SpendByTransactionTypeCard';
import SpendByAccountCard from '../../../../modules/cloud/cards/SpendByAccountCard/SpendByAccountCard';
import TopProductsBySpendCard from '../../../../modules/cloud/cards/TopProductsBySpendCard/TopProductsBySpendCard';

const CloudInsightsSpendTab = () => (
  <Grid container rowSpacing={4} columnSpacing={4}>
    <Grid item sm={12}>
      <SpendInsightsStatsCard />
    </Grid>
    <Grid item sm={12}>
      <ForecastCard />
    </Grid>
    <Grid container item sm={12} direction="row" alignItems="stretch" spacing={4}>
      <Grid item sm={12} lg={6}>
        <SpendByServiceLineCard />
      </Grid>
      <Grid item sm={12} lg={6}>
        <TopProductsBySpendCard />
      </Grid>
    </Grid>
    <Grid item sm={12}>
      <TotalSpendCard />
    </Grid>
    <Grid item sm={12}>
      <SpendByTransactionTypeCard />
    </Grid>
    <Grid item sm={12}>
      <SpendByAccountCard />
    </Grid>
  </Grid>
);

export default CloudInsightsSpendTab;
