import { PickersActionBarProps } from '@mui/x-date-pickers-pro';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translate';
import { Button } from '../../Button';
import { Stack } from '@mui/material';
import * as React from 'react';
import { useStaticDateRangePickerContext } from '../context/StaticDateRangePickerContext';

type CustomActionBarProps = PickersActionBarProps;

const CustomActionBar = ({ onAccept, onClear, onCancel, actions, ...otherProps }: CustomActionBarProps) => {
  const { size, color } = useStaticDateRangePickerContext();
  const { t } = useTranslation(undefined, { i18n });

  if (actions == null || actions.length === 0) {
    return null;
  }

  const items = actions?.map((actionType) => {
    switch (actionType) {
      case 'clear':
        return (
          <Button
            variant="outline"
            color={color}
            size={size}
            testId="clear-action-button"
            onClick={onClear}
            key={actionType}
          >
            {t('DATE_RANGE_PICKER.ACTION_BAR_ACTIONS.CLEAR')}
          </Button>
        );
      case 'cancel':
        return (
          <Button
            variant="outline"
            color={color}
            size={size}
            testId="cancel-action-button"
            onClick={onCancel}
            key={actionType}
          >
            {t('DATE_RANGE_PICKER.ACTION_BAR_ACTIONS.CANCEL')}
          </Button>
        );
      case 'accept':
        return (
          <Button
            variant="solid"
            color={color}
            size={size}
            testId="accept-action-button"
            onClick={onAccept}
            key={actionType}
          >
            {t('DATE_RANGE_PICKER.ACTION_BAR_ACTIONS.APPLY')}
          </Button>
        );
      default:
        return null;
    }
  });

  return (
    <Stack direction="row" gap={2}>
      {items}
    </Stack>
  );
};

export default CustomActionBar;
