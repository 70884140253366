import React from 'react';
import { Stack } from '@mui/material';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';

import SavingsGraph from './SavingsGraph';
import EligibleSpendChart from './BreakdownChart/EligibleSpendChart';
import Contracts from './Contracts';
import SpendMonitoringChart from './SpendMonitoringChart';
import EDPBanner from './EDPBanner';
import { useEDPConfigurationData } from './Configurations/useEDPConfigurationData';
import SpendBreakdownChart from './BreakdownChart/SpendBreakdownChart';
import { ELIGIBILITY_THRESHOLD } from './constants';

const Layout = () => {
  const { subFeatures } = useCloudContext();
  const { data, error, isLoading } = useEDPConfigurationData();
  const isEdpInPlace = data?.isActive && !subFeatures.limitedEDP;
  const isContractsVisible =
    (data?.isActive || data?.eligibleSpend! > ELIGIBILITY_THRESHOLD) && !subFeatures.limitedEDP;

  return (
    <LoadableContentWrapper isEmpty={!data} error={error} isLoading={isLoading || !!data?.isEligibleSpendLoading}>
      <Stack gap={4}>
        <EDPBanner data={data!} />
        {isEdpInPlace ? <SpendBreakdownChart /> : <EligibleSpendChart />}
        {isEdpInPlace && <SpendMonitoringChart />}
        {isEdpInPlace && <SavingsGraph />}
        {isContractsVisible && <Contracts />}
      </Stack>
    </LoadableContentWrapper>
  );
};

export default Layout;
