import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Text } from '../Text';
import type { DesignSystemColor } from '../../types';
import { Tooltip, TooltipInfoTrigger } from '../Tooltip';
import { testProps } from '../../utils/testProperties';

export type BaseStatBarItemProps = {
  title: string | ReactNode;
  value: string | ReactNode;
  color?: DesignSystemColor;
  isInGroup?: boolean;
  orientation?: 'horizontal' | 'vertical';
  tooltip?: {
    title: string;
    content: string;
  };
  testId?: string;
  className?: string;
};

export type StatBarItemProps = Omit<BaseStatBarItemProps, 'isInGroup'>;

const Orientation = {
  horizontal: 'row',
  vertical: 'column',
} as const;

const Align = {
  horizontal: 'center',
  vertical: 'flex-start',
} as const;

export const StatBarItem = ({
  isInGroup = false,
  orientation = 'vertical',
  color,
  value,
  title,
  tooltip,
  testId,
  className,
}: BaseStatBarItemProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      p={!!isInGroup ? 2 : 4}
      direction={Orientation[orientation]}
      bgcolor={color ? palette[color].color2 : palette.core.bg}
      alignItems={Align[orientation]}
      flex="1 0 0"
      alignSelf="stretch"
      className={className}
      {...testProps(testId, 'stat-bar-item')}
    >
      <Stack direction="row" gap={1}>
        <Text
          variant="caption"
          size="M"
          color={color ? `${color}3` : 'text2'}
          className="header"
          testId="stat-bar-item-title"
        >
          {title}
        </Text>
        {!!tooltip && (
          <Tooltip {...tooltip} size="S">
            <TooltipInfoTrigger sx={{ color: color ? palette[color].color3 : palette.text.color2 }} />
          </Tooltip>
        )}
      </Stack>
      <Text variant="heading" size="M" color={color ? 'text5' : 'text1'} className="value" testId="stat-bar-item-value">
        {value}
      </Text>
    </Stack>
  );
};
