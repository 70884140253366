import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';
import {
  taggingApiCodegen,
  ListTagAssignmentsApiArg,
  ListTagAssignmentsApiResponse,
  TagAssignment,
} from '../openapi/codegen/taggingAPI';

const TAG_TYPE = 'Tagging';

export const enhancedTaggingAPI = taggingApiCodegen
  .enhanceEndpoints<typeof TAG_TYPE>({
    addTagTypes: [TAG_TYPE],
    endpoints: {
      /*
      listTags: {
        providesTags: [TAG_TYPE],
      },
      createTag: {
        invalidatesTags: [TAG_TYPE],
      },
      updateTag: {
        invalidatesTags: [TAG_TYPE],
      },
      deleteTag: {
        invalidatesTags: [TAG_TYPE],
      },
     */
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listTagAssignmentsPaginated: build.query<ListTagAssignmentsApiResponse, ListTagAssignmentsApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const tagAssignments = await fetchAllPages<
            ListTagAssignmentsApiResponse,
            ListTagAssignmentsApiArg,
            TagAssignment
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/assignments`,
                params: {
                  ref: args.ref,
                  tagId: args.tagId,
                  nextToken: args.nextToken,
                  maxResults: args.maxResults,
                },
              });
              return { data: queryResponse.data as ListTagAssignmentsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.tagAssignments || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { tagAssignments } };
        },
        providesTags: [],
      }),
    }),
  });

export const { useLazyListTagAssignmentsPaginatedQuery } = enhancedTaggingAPI;
