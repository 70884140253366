import React from 'react';
import { Stack } from '@mui/material';

import { Dropzone, ErrorMessageList, FileList, Fileupload } from '@verticeone/design-system/src';
import { Loader } from '@verticeone/design-system/src';

import {
  acceptedTypes,
  customErrorCodes,
  maxFileBytesSize,
  validator,
} from '@vertice/core/src/modules/saas/contract/components/DocumentUpload/utils';
import { useTaskContext } from '../../../../TaskContext';
import useRequestDocuments from '../../../../../../request/RequestDetailPage/useRequestDocuments';
import { useTaskFormContext } from '../TaskFormContext';

export const DocumentsSection = () => {
  const { taskOverview } = useTaskContext();
  const { readOnly } = useTaskFormContext();

  const requestId = taskOverview?.request?.ref.split('/').pop();
  const { files, isFetching, uploadFiles, deleteFile, downloadFile, allowDeletion } = useRequestDocuments(
    requestId ? requestId : ''
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack>
      <Fileupload
        multiple
        acceptedTypes={acceptedTypes}
        maxFileSize={maxFileBytesSize}
        files={files}
        onAddFiles={uploadFiles}
        onRemoveFiles={deleteFile}
        validator={validator}
        customErrorCodes={customErrorCodes}
      >
        <Stack gap={4}>
          <FileList
            size="M"
            hideErrorFiles
            dynamicFileNameLength
            onFileNameClick={downloadFile}
            hiddenColumns={allowDeletion && !readOnly ? [] : ['remove']}
          />
          {!readOnly && (
            <Stack height={210} alignItems="center">
              <Dropzone size="M" />
            </Stack>
          )}
          <ErrorMessageList size="S" />
        </Stack>
      </Fileupload>
    </Stack>
  );
};
