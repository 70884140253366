import React from 'react';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import useS3IntelligentTieringData from './useS3IntelligentTieringData';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import S3IntelligentTieringGraph from './S3IntelligentTieringGraph';
import { CalendarTodayOutlined } from '@mui/icons-material';
import GenericCard from '../../../../pages/Cloud/CloudOptimization/Recommendation/components/GenericCard';
import GenericTable from '../../../../pages/Cloud/CloudOptimization/Recommendation/components/GenericTable/GenericTable';
import { IntFormatter } from '../../../../pages/Cloud/CloudOptimization/Recommendation/components/GenericTable';

const S3IntelligentTieringCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useS3IntelligentTieringData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('S3_INTELLIGENT_TIERING.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
        <CardHeaderDescription>{t('S3_INTELLIGENT_TIERING.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus} pt={6} pb={6}>
          <S3IntelligentTieringGraph data={data!} />
          <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-61.TABLE'} show={['title']}>
            <GenericTable
              code="VT-60"
              testId="s3-intelligent-tiering"
              translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-61.TABLE"
              columns={
                ['account_id', 'resource_id', 'sum_usage_amount', 'sum_unblended_cost', 'proposed_cost_int'] as const
              }
              getRowId={(row) => `${row.resource_id}-${row.account_id}`}
              defaultSortColumns={{ field: 'proposed_cost_int', sort: 'desc' }}
              renderCell={{
                sum_usage_amount: (value) => (
                  <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />
                ),
                sum_unblended_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
                proposed_cost_int: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
              }}
            />
          </GenericCard>
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default S3IntelligentTieringCard;
