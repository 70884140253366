import React from 'react';
import { styled } from '@mui/material';
import { GridColDef, GridSortItem, GridSortDirection, GridRowIdGetter, gridClasses } from '@mui/x-data-grid-pro';
import { DataGridProps } from '@verticeone/design-system/src';

import { DataGrid } from '@verticeone/design-system/src';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useInstanceData } from '../../../providers/InstanceProvider';

export type BaseTableProps<C extends Record<string, any>> = {
  data: Array<C>;
  defaultSortModel: {
    field: keyof C;
    sort: GridSortDirection;
  };
  getRowId: GridRowIdGetter<C>;
  tableColumns: Array<GridColDef<C>>;
} & Partial<Pick<DataGridProps, 'slots' | 'slotProps' | 'hideFooter'>>;

const StyledDataGrid = styled(DataGrid<Record<string, any>>)({
  [`.${gridClasses.columnHeaderTitle}`]: {
    textTransform: 'uppercase',
  },
  [`.${gridClasses.cell}.${gridClasses['cell--editing']}`]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [`.${gridClasses.cell}.${gridClasses['cell--editable']}:focus-within`]: {
    outline: 'unset',
  },
});

const BaseTable = <C extends Record<string, any>>({
  data,
  getRowId,
  tableColumns,
  defaultSortModel,
  ...otherProps
}: BaseTableProps<C>) => {
  const { omittedColumns } = useInstanceData();

  const filteredTableColumns = tableColumns.filter((column) => !omittedColumns?.includes(column.field));

  return (
    <StyledDataGrid
      {...otherProps}
      initialState={{
        sorting: {
          sortModel: [defaultSortModel as GridSortItem],
        },
      }}
      size="M"
      headerSize="XS"
      getRowId={getRowId as GridRowIdGetter}
      columns={filteredTableColumns as any}
      rows={data}
      noBorder
      noBorderRadius
      disableRowSelectionOnClick
      autoHeight={!data?.length}
      color={AWS_BRAND_COLOR}
      autosizeOnMount={true}
    />
  );
};

export default BaseTable;
