import { default as usePercentage, type getPercentageProps } from './usePercentage';
import Highcharts from 'highcharts';

export type YAxisTypes = { type: 'percentage' } & getPercentageProps;

export const useGetYAxis = () => {
  const hooks: Record<YAxisTypes['type'], (props: any) => Highcharts.YAxisOptions> = {
    percentage: usePercentage(),
  };

  return (props: YAxisTypes | Array<YAxisTypes>): Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions> => {
    if (Array.isArray(props)) {
      return props.map((p) => hooks[p.type](p));
    }

    return hooks[props.type](props);
  };
};
