import { FC, ReactElement, useState } from 'react';
import { Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import NotificationsPreferences from '@vertice/core/src/modules/notifications/NotificationsPreferences';
import { Tab } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';

export const MODAL_TABS = {
  TEAM_MEMBER: 'team-member',
  NOTIFICATIONS: 'notifications',
};

export interface LocationWithState extends Location {
  state: {
    currentTab?: string;
  };
}

interface UserFormNotificationsTabContentProps {
  teamMemberComponent: ReactElement;
  userId: string;
}

const UserFormNotificationsTabContent: FC<UserFormNotificationsTabContentProps> = ({ teamMemberComponent, userId }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(MODAL_TABS.TEAM_MEMBER);

  return (
    <Stack height="100%">
      <TabsBottomLineWrapper>
        <Tabs onChange={(e, value) => setCurrentTab(value)} value={currentTab}>
          <Tab label={t('PREFERENCES.DIRECTORY.CONTACT_MODAL.TABS.USER')} value={MODAL_TABS.TEAM_MEMBER} />
          <Tab label={t('PREFERENCES.DIRECTORY.CONTACT_MODAL.TABS.NOTIFICATIONS')} value={MODAL_TABS.NOTIFICATIONS} />
        </Tabs>
      </TabsBottomLineWrapper>
      <Box paddingY={4} overflow="auto">
        {currentTab === MODAL_TABS.TEAM_MEMBER && teamMemberComponent}
        {currentTab === MODAL_TABS.NOTIFICATIONS && <NotificationsPreferences userId={userId} />}
      </Box>
    </Stack>
  );
};

export default UserFormNotificationsTabContent;
