import { AccountUser, useListAccountUsersQuery } from '@vertice/slices';
import { keyBy } from 'lodash';
import { useAccountContext } from '../contexts/AccountContext';
import { useMemo } from 'react';

export type UsersByIdReturn = Record<string, AccountUser>;

export type AccountUsersByIdReturn = {
  usersById: UsersByIdReturn;
  isLoadingUsers: boolean;
};

export const useAccountUsersById = (options?: { skip: boolean }): AccountUsersByIdReturn => {
  const { accountId } = useAccountContext();
  const { data: usersData, isLoading: isLoadingUsers } = useListAccountUsersQuery({ accountId }, options);
  return useMemo(() => {
    const usersById = keyBy(usersData?.users, 'userId');
    return {
      usersById,
      isLoadingUsers,
    };
  }, [isLoadingUsers, usersData?.users]);
};
