import { contractWorkflowsV2ApiCodegen } from '../openapi/codegen/contractWorkflowsV2Api';

const PREFIX = 'contractWorkflowsV2';
const REQUESTS = `${PREFIX}Requests`;
const REQUEST_CONFIG = `${PREFIX}RequestConfig`;
const WORKFLOW_CONFIG = `${PREFIX}WorkflowConfig`;

type TagType = typeof REQUESTS | typeof REQUEST_CONFIG | typeof WORKFLOW_CONFIG;

export const enhancedContractWorkflowsV2API = contractWorkflowsV2ApiCodegen.enhanceEndpoints<TagType>({
  addTagTypes: [REQUESTS, REQUEST_CONFIG],
  endpoints: {
    getAccountConfig: {
      providesTags: [WORKFLOW_CONFIG],
    },
    setAccountConfig: {
      invalidatesTags: [WORKFLOW_CONFIG],
    },
    listRequests: {
      providesTags: [REQUESTS],
    },
    getRequest: {
      providesTags: [REQUESTS],
    },
    createRequest: {
      invalidatesTags: [REQUESTS],
    },
    getDefaultWorkflow: {
      providesTags: [REQUEST_CONFIG],
    },
    setDefaultWorkflow: {
      invalidatesTags: [REQUEST_CONFIG],
    },
    terminateRequest: {
      invalidatesTags: [REQUESTS],
    },
  },
});
