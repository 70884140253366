import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@verticeone/design-system/src';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';

type FormSelectFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  children: React.ReactNode;
};

/** @deprecated Use FormSelectField2 instead. */
const FormSelectField = <FormDataType extends FieldValues = never>({
  children,
  id,
  name,
  required,
  disabled,
}: FormSelectFieldProps<FormDataType>) => {
  const { control } = useFormContext<FormDataType>();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TextField
          SelectProps={{ MenuProps: { sx: { maxHeight: 450 } } }}
          select
          variant="outlined"
          hiddenLabel
          onBlur={onBlur}
          onChange={onChange}
          value={value || ''}
          inputRef={ref}
          id={id}
          disabled={disabled}
        >
          {children}
        </TextField>
      )}
    />
  );
};

export default FormSelectField;
