import React from 'react';
import { FieldValues } from 'react-hook-form';

import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { CommonFormEntryProps } from './types';

export const DateFormEntry = <FormDataType extends FieldValues>({
  name,
  label,
  required,
  width = 6,
}: CommonFormEntryProps<FormDataType>) => {
  return (
    <TaskFormEntry<any, typeof FormDateField>
      name={name}
      label={label}
      required={required}
      component={FormDateField}
      componentProps={{
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
      width={width}
    />
  );
};
