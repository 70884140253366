import React, { createContext, ReactNode, useContext } from 'react';
import { DesignSystemColor, DesignSystemSize } from '../../../types';
import { Dayjs } from 'dayjs';

interface StaticDateRangePickerProviderProps {
  children: ReactNode;
  color: DesignSystemColor;
  size: DesignSystemSize;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  dateFormat?: string;
}

interface StaticDateRangePickerContextProps {
  color: DesignSystemColor;
  size: DesignSystemSize;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  dateFormat?: string;
}

const StaticDateRangePickerContext = createContext<StaticDateRangePickerContextProps | undefined>(undefined);

export const useStaticDateRangePickerContext = () => {
  const context = useContext(StaticDateRangePickerContext);
  if (!context) {
    throw new Error('useStaticDateRangePickerContext must be used within a StaticDateRangePickerProvider');
  }
  return context;
};

const StaticDateRangePickerProvider = ({
  children,
  color,
  size,
  minDate,
  maxDate,
  dateFormat,
}: StaticDateRangePickerProviderProps) => {
  return (
    <StaticDateRangePickerContext.Provider value={{ color, size, minDate, maxDate, dateFormat }}>
      {children}
    </StaticDateRangePickerContext.Provider>
  );
};

export default StaticDateRangePickerProvider;
