import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import {
  ForecastPrecisionType,
  TimeSeriesDataPoint,
  useUsageCostPerRecordTypeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useDeferredQuery } from '@verticeone/utils/api';
import {
  DATE_FORMAT,
  fillMissingMonths,
  findFirstCurrency,
  sumPositiveDataPointValues,
  sumTimeSeriesValues,
} from '../../utils/graphDataUtils';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { AWS_DEFAULT_CURRENCY } from '../../constants';

export type TotalSpendData = {
  currency: string;

  /** All months in the X axis in the format of yyyy-MM-dd. */
  months: string[];

  /** Money values. The number of items should correspond to the number of months. */
  grossSpend: number[];

  invoicedSpend: number[];
};

export const prepareTotalSpendGraphData = (dataPointsRaw: TimeSeriesDataPoint[], startDate: Date) => {
  const dataPoints = fillMissingMonths(dataPointsRaw, startDate, []);
  const grossSpend = dataPoints.map((p) => sumPositiveDataPointValues(p));
  return {
    months: dataPoints.map(({ time }) => time!),
    grossSpend,
    invoicedSpend: dataPoints.map(({ values }) => Math.max(0, sumTimeSeriesValues(values, 0))),
    currency: findFirstCurrency(dataPoints) || AWS_DEFAULT_CURRENCY,
  };
};

export const getDefaultStartDate = () => addMonths(startOfMonth(new Date()), -11);

const useTotalSpendGraphData = (): LoadableAdvanced<TotalSpendData> => {
  const { accountId } = useSelector(getAccount);
  const startDate = useMemo(getDefaultStartDate, []);
  const { data: dataPointsRaw, error } = useDeferredQuery(
    useUsageCostPerRecordTypeQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const computed = useMemo(() => {
    if (!dataPointsRaw) return undefined;
    return prepareTotalSpendGraphData(dataPointsRaw, startDate);
  }, [dataPointsRaw, startDate]);

  return {
    error: error,
    isEmpty: Boolean(dataPointsRaw && dataPointsRaw.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useTotalSpendGraphData;
