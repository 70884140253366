import { useMemo } from 'react';
import useRecommendationsData from '../../hooks/useRecommendationsData';
import { isNil, map, sumBy } from 'lodash';
import { AWS_DEFAULT_CURRENCY } from '../../constants';

const MONTHS_COUNT = 12;

const useSavingsByCategoryData = () => {
  const { stats, error, byCategoryWithoutTagDuplicities } = useRecommendationsData();

  return useMemo(() => {
    const data =
      byCategoryWithoutTagDuplicities &&
      map(byCategoryWithoutTagDuplicities, (recsInCategory, categoryName) => ({
        name: categoryName,
        y: sumBy(recsInCategory, ({ saving }) => saving) * MONTHS_COUNT,
      }));
    return {
      isLoading: isNil(byCategoryWithoutTagDuplicities),
      error,
      currency: AWS_DEFAULT_CURRENCY,
      totalAnnualSaving: stats?.totalMonthlyRecValue && stats?.totalMonthlyRecValue * MONTHS_COUNT,
      data,
      isEmpty: !!data && data.length === 0,
    };
  }, [byCategoryWithoutTagDuplicities, error, stats?.totalMonthlyRecValue]);
};

export default useSavingsByCategoryData;
