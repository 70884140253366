import React from 'react';
import { styled } from '@mui/material';

const first = (
  <path d="M0 3C0 1.61929 1.11929 0.5 2.5 0.5H22.4V5.5H2.5C1.11929 5.5 0 4.38071 0 3Z" className="inside" />
);

const last = (
  <path
    d="M0.599976 0.5H20.5C21.8807 0.5 23 1.61929 23 3C23 4.38071 21.8807 5.5 20.5 5.5H0.599976V0.5Z"
    className="inside"
  />
);

const middle = <rect x="0.200012" y="0.5" width="22.4" height="5" className="inside" />;

const StyledBarSvg = styled('svg')<{ $filled: boolean }>(({ theme: { palette }, $filled }) => ({
  width: '25px',
  height: '5px',
  '.inside': {
    fill: palette.mode === 'dark' ? palette.global.color.white.main : palette.primary[$filled ? 'color2' : 'color3'],
    opacity: palette.mode === 'dark' && !$filled ? 0.3 : 1,
  },
}));

interface StatusBarProps {
  numberOfElements: number;
  breakIndex: number;
}

const StatusBar: React.FC<StatusBarProps> = ({ numberOfElements, breakIndex }) => {
  if (numberOfElements < 2) {
    return null;
  }

  const items = [first, ...new Array(numberOfElements - 2).fill(middle), last].map((value, index) => ({
    value,
    key: index,
  }));

  return (
    <div>
      {items.map(({ value, key }, index) => (
        <StyledBarSvg
          $filled={index < breakIndex}
          key={key}
          viewBox="0 0 25 6"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {value}
        </StyledBarSvg>
      ))}
    </div>
  );
};

export default StatusBar;
