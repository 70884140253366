import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { print } from 'graphql';
import { API_URLS } from '@vertice/slices';
import { useAuthentication } from '@verticeone/auth/src';

type CloudClientContextProviderProps = {
  children: React.ReactNode;
};

export const useCloudClient = () => {
  const queryClient = useQueryClient();
  const { getJwtToken } = useAuthentication();

  const fetchCloudOptimization = async <TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    variables: TVariables
  ): Promise<TResult> => {
    const response = await fetch(API_URLS.CLOUDOPTIMIZATION_GRAPHQL_URL, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${await getJwtToken()}`,
      },
      body: JSON.stringify({
        query: print(document),
        variables,
      }),
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch: ${response.statusText}. Body: ${await response.text()}`);
    }

    return await response.json().then((res) => {
      return new Promise(function (resolve, reject) {
        if (res.errors) {
          reject(res.errors);
        } else {
          resolve(res.data);
        }
      });
    });
  };

  return { fetchCloudOptimization, queryClient };
};

const CloudClientContextProvider = ({ children }: CloudClientContextProviderProps) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 60000,
          },
        },
      }),
    []
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default CloudClientContextProvider;
