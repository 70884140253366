import { useActivateAccountIntegrationMutation } from '@vertice/slices';
import {
  useActivateProviderMutation,
  useLazyGetProviderQuery,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { sleep } from '@verticeone/utils/async';
import { ErrorResponse, ValidResponse } from './types';

export const splitByWords = (originalString: string, highlightedWords: string[]): string[] =>
  highlightedWords.length > 0
    ? highlightedWords.reduce(
        (acc, highlighted) => {
          const newArray: string[] = [];
          acc.forEach((substring) => {
            const index = substring.indexOf(highlighted);
            if (index < 0) {
              newArray.push(substring);
            } else {
              const endIndex = index + highlighted.length;
              newArray.push(substring.substring(0, index));
              newArray.push(substring.substring(index, endIndex));
              newArray.push(substring.substring(endIndex));
            }
          });
          return newArray;
        },
        [originalString]
      )
    : [originalString];

const tryWithRetries = <T>(fn: () => Promise<T>, check: (args: T) => boolean, retries = 3): Promise<T> =>
  new Promise((resolve, reject) => {
    fn()
      .then((result) => {
        if (check(result)) {
          resolve(result);
        } else if (retries > 0) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          sleep(1000).then(() =>
            tryWithRetries(fn, check, retries - 1)
              .then(resolve)
              .catch(reject)
          );
        } else {
          reject();
        }
      })
      .catch(() => {
        if (retries > 0) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          sleep(1000).then(() =>
            tryWithRetries(fn, check, retries - 1)
              .then(resolve)
              .catch(reject)
          );
        } else {
          reject();
        }
      });
  });

export const useActivateIntegrationAndProvider = (integrationId: string, providerId: string, accountId: string) => {
  const [activateAccountIntegration, { isLoading: isLoadingActivateAccountIntegration }] =
    useActivateAccountIntegrationMutation();
  const [activateInsightProvider, { isLoading: isLoadingActivateInsightProvider }] = useActivateProviderMutation();
  const [triggerGetProviderQuery, { isLoading: isLoadingTriggerGetProviderQuery }] = useLazyGetProviderQuery();

  return {
    activate: () =>
      new Promise((resolve, reject) => {
        activateAccountIntegration({
          accountId: accountId!,
          integrationId,
        })
          .then(() => {
            if (providerId) {
              tryWithRetries(
                () =>
                  triggerGetProviderQuery({
                    accountId: accountId!,
                    providerId,
                  }),
                (queryData) => queryData.data?.provider !== undefined,
                3
              )
                .then(() =>
                  activateInsightProvider({
                    accountId: accountId!,
                    providerId,
                  }).then(resolve)
                )
                .catch(reject);
            } else {
              resolve(null);
            }
          })
          .catch(reject);
      }),
    isLoading:
      isLoadingActivateAccountIntegration || isLoadingActivateInsightProvider || isLoadingTriggerGetProviderQuery,
  };
};

export const isValidIntegrationResponse = <T>(
  response: ValidResponse<T> | ErrorResponse
): response is ValidResponse<T> => {
  return (response as ValidResponse<T>).data !== undefined;
};
