import { ReactNode } from 'react';

import { StyledEngineProvider } from '@mui/styled-engine';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider, PickersLocaleText } from '@mui/x-date-pickers-pro';
import { CssBaseline } from '@mui/material';
import { Dayjs } from 'dayjs';

import { ThemeProvider, ThemeVariant } from '../theme';
import { LocaleContextProvider, useLocale } from '../utils/formatting/LocaleContext';

export type DesignSystemProviderProps = {
  children: ReactNode | ReactNode[];
  locale: string;
  preselectedTheme?: ThemeVariant;
};

const LOCALE_TEXT_CONFIG: Partial<PickersLocaleText<Dayjs>> = {
  fieldMonthPlaceholder: ({ format }) => format,
};

/**
 * Provider for common design system wrappers and providers.
 */
export const DesignSystemProvider = (props: DesignSystemProviderProps) => {
  const { children, locale, preselectedTheme } = props;

  // Temporarily use locale context copied from @vertice/core before the formatters get moved to
  // the lightweight @verticeone/utils package.
  const localeContext = useLocale(locale);

  return (
    <LocaleContextProvider value={localeContext}>
      <ThemeProvider locale={localeContext.locale} preselectedTheme={preselectedTheme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={LOCALE_TEXT_CONFIG}>
          <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </LocaleContextProvider>
  );
};
