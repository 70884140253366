import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { getTableData } from '../../../../../modules/cloud/utils/graphDataUtils';
import { type TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

export type DataColumns =
  | 'updated_at'
  | 'aws_infra_spend'
  | 'aws_marketplace_spend'
  | 'eligible_marketplace_spend'
  | 'non_eligible_marketplace_spend'
  | 'commitment'
  | 'total_edp_eligible_spend';

const EDPSpendBreakdownQuery = graphql(`
  query EDPSpendBreakdown($accountId: String!) {
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(
          columns: [
            "updated_at"
            "aws_infra_spend"
            "aws_marketplace_spend"
            "eligible_marketplace_spend"
            "non_eligible_marketplace_spend"
            "commitment"
            "total_edp_eligible_spend"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const useSpendBreakdownData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['EDPSpendBreakdown'],
    queryFn: () =>
      fetchCloudOptimization(EDPSpendBreakdownQuery, {
        accountId,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        return (getTableData(data.athenaViewQuery.table as TableType)?.[0] as Record<DataColumns, number>) ?? null;
      }

      return null;
    },
  });
};
