import React, { createContext, useCallback, useContext, useRef } from 'react';
import type { DesignSystemColor, DesignSystemSize } from '../../../../types';
import type { TAdapter } from '../../adapters/TAdapter';
import type { SerializedData } from './utils/types';
import { useFilterPopover } from '../Popover';
import { useFilterGroupData } from './FilterGroupProvider';

export type OnChange = (event: 'clear' | 'apply', data: SerializedData) => void;

type FilterContextProps = {
  color: DesignSystemColor;
  size: DesignSystemSize;
  handleClear: () => void;
  handleApply: () => void;
  storageAdapter?: TAdapter;
  filterData?: Record<string, any>;
};

type FilterContextProviderProps = {
  children: React.ReactNode;
  color: DesignSystemColor;
  size: DesignSystemSize;
  onChange?: OnChange;
  storageAdapter?: TAdapter;
  filterData?: Record<string, any>;
};

const FilterContext = createContext<FilterContextProps>({} as FilterContextProps);

const FilterProvider = ({
  children,
  filterData,
  color,
  size,
  storageAdapter,
  onChange,
}: FilterContextProviderProps) => {
  const popover = useFilterPopover();
  const { group, groupId } = useFilterGroupData();
  const clearDebounceTimeout = useRef<NodeJS.Timeout | null>(null); //clearAll should call serialize only once

  const handleClear = useCallback(() => {
    if (clearDebounceTimeout.current) {
      clearTimeout(clearDebounceTimeout.current);
    }

    clearDebounceTimeout.current = setTimeout(() => {
      const data = group.serialize();

      storageAdapter?.updateData(groupId, data);
      onChange?.('clear', data);
    }, 0);
  }, [group, groupId, onChange, storageAdapter]);

  const handleApply = useCallback(() => {
    popover.close();
    group.apply();

    const data = group.serialize();

    storageAdapter?.updateData(groupId, data);
    onChange?.('apply', data);
  }, [group, groupId, onChange, popover, storageAdapter]);

  return (
    <FilterContext.Provider
      value={{
        filterData,
        color,
        size,
        storageAdapter,
        handleClear,
        handleApply,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);

export default FilterProvider;
