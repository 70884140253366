import { createContext } from 'react';
import type { ButtonGroupProps } from './ButtonGroup';

type ButtonGroupContextProps = {
  className?: string;
  color?: ButtonGroupProps['color'];
  size?: ButtonGroupProps['size'];
  variant?: ButtonGroupProps['variant'];
  disabled?: boolean;
};

export const ButtonGroupContext = createContext<ButtonGroupContextProps>({});
