import React, { ReactElement } from 'react';
import NotConfigured from './NotConfigured/NotConfigured';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import useCloudPermissions from '../../modules/cloud/hooks/useCloudPermissions';
import useCloudIntegrationStatus from '../../modules/cloud/hooks/useCloudIntegrationStatus';
import useCloudFeature, { CloudSubFeature } from '../../modules/cloud/hooks/useCloudFeature';
import { CloudContextProvider } from '../../modules/cloud/CloudContext';
import { Loader } from '@verticeone/design-system/src';
import { AWS_BRAND_COLOR } from '../../modules/cloud/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Stack } from '@mui/material';

export type LoadableAWSPageWrapperProps = {
  children: ReactElement;
  isLoading?: boolean;

  /**
   * Optional extra condition for page to be viewable.
   *
   * If provided, page is available only if this function returns true.
   * If it returns false, 404 page is shown.
   *
   * @param subFeatures Info about what sub-features are enabled/disabled. Can be useful to make the decision.
   */
  getCanBeViewed?: (subFeatures: Record<CloudSubFeature, boolean>) => boolean;
};

const LoadableAWSPageWrapper: React.FC<LoadableAWSPageWrapperProps> = ({ children, isLoading, getCanBeViewed }) => {
  const cloudFeature = useCloudFeature();
  const { accountType } = useAccountContext();
  const integrationStatus = useCloudIntegrationStatus({
    skip: cloudFeature.isLoading || !cloudFeature.data.isMainFeatureEnabled,
  });
  const permissions = useCloudPermissions();

  if (cloudFeature.isLoading || integrationStatus.isLoading || permissions.isLoading) {
    return (
      <Stack height="100%">
        <Loader color={AWS_BRAND_COLOR} />
      </Stack>
    );
  }

  if (
    !cloudFeature.data.isMainFeatureEnabled ||
    !permissions.data.canUserAccessCCO ||
    (getCanBeViewed && !getCanBeViewed(cloudFeature.data.subFeatures))
  ) {
    return <PageNotFound />;
  }

  if (
    cloudFeature.data.isMainFeatureEnabled &&
    !integrationStatus.data.isActive &&
    !(cloudFeature.data.subFeatures.activateIntegration && accountType === 'DEMO')
  ) {
    return <NotConfigured />;
  }

  if (isLoading) {
    return (
      <Stack height="100%">
        <Loader color={AWS_BRAND_COLOR} />
      </Stack>
    );
  }

  return (
    <CloudContextProvider subFeatures={cloudFeature.data.subFeatures} permissions={permissions.data}>
      {children}
    </CloudContextProvider>
  );
};

export default LoadableAWSPageWrapper;
