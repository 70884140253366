import React, { FC, MouseEvent, useState } from 'react';
import { IconButton } from '@verticeone/design-system/src';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { AssignRequestOwnerDialog } from './AssignRequestOwnerDialog';
import { TerminateRequestDialog } from './TerminateRequestDialog';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';

type RequestHeaderMenuProps = {
  request: Request;
  isRequestTerminationEnabled: boolean;
};

export const RequestHeaderMenu: FC<RequestHeaderMenuProps> = ({ request, isRequestTerminationEnabled }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  // Assign owner dialog
  const [assignOwnerDialogOpened, setAssignOwnerDialogOpened] = useState(false);
  const openAssignOwnerDialog = () => setAssignOwnerDialogOpened(true);
  const closeAssignOwnerDialog = () => setAssignOwnerDialogOpened(false);

  const handleChangeOwnerMenuItemClick = () => {
    openAssignOwnerDialog();
    closeMenu();
  };

  // Terminate request dialog
  const [terminateRequestOpen, setTerminateRequestDialogOpened] = useState(false);
  const openTerminateRequestDialog = () => setTerminateRequestDialogOpened(true);
  const closeTerminateRequestDialog = () => setTerminateRequestDialogOpened(false);

  const handleTerminateRequestMenuItemClick = () => {
    openTerminateRequestDialog();
    closeMenu();
  };

  const isRequestTerminationContentDisplayed =
    isRequestTerminationEnabled && Array<Request['status']>('ACTIVE', 'PENDING').includes(request.status);

  return (
    <>
      <IconButton icon={MoreVertIcon} onClick={openMenu} variant="plain" size="XL" />
      <Menu anchorEl={anchorEl} open={menuOpened} onClose={closeMenu}>
        <MenuItem onClick={handleChangeOwnerMenuItemClick}>
          {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.ACTIONS.CHANGE_OWNER')}
        </MenuItem>
        {isRequestTerminationContentDisplayed && (
          <MenuItem onClick={handleTerminateRequestMenuItemClick}>
            {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.ACTIONS.TERMINATE_REQUEST')}
          </MenuItem>
        )}
      </Menu>
      <AssignRequestOwnerDialog request={request} isOpened={assignOwnerDialogOpened} onClose={closeAssignOwnerDialog} />
      {isRequestTerminationContentDisplayed && (
        <TerminateRequestDialog
          request={request}
          isOpened={terminateRequestOpen}
          onClose={closeTerminateRequestDialog}
        />
      )}
    </>
  );
};
