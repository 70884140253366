import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';
import { type ToolbarContextValue, useToolbar } from './ToolbarContext';
import { toolbarSizeDict } from './constants';

const StyledDivider = styled(Stack)<ToolbarContextValue>(({ theme, variant, size, disabled }) => ({
  width: '1px',
  margin: `${toolbarSizeDict[size]} 0`,
  backgroundColor: theme.palette.core[variant === 'ghost' || disabled ? 'color3' : 'color2'],
}));

const ToolbarDivider = () => {
  const { variant, size, disabled } = useToolbar();

  return <StyledDivider variant={variant} size={size} disabled={disabled} />;
};

export default ToolbarDivider;
