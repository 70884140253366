import FormReadOnlyText, { FormReadOnlyTextType } from '../../../../forms/fields/FormReadOnlyText';
import FormNumberField, { FormNumberFieldType } from '../../../../forms/fields/FormNumberField';
import ContractFormEntry from '../../ContractFormEntry';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';
import { useContractContext } from '../../ContractContext';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import { ScopeReductionSavingsTooltip } from '../ScopeReductionSavingsTooltip';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormNumberFieldType<ContractFormData>;

export const AdvancedScopeReductionCostFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const overrideEnabled = useWatch<ContractFormData>({
    name: 'costModel.negotiated.model.scopeReductionSavingsOverride.enabled',
  });
  const defaultScopeReductionValue = useWatch<ContractFormData>({ name: 'parts.overview.annualScopeReductionSavings' });

  useEffect(() => {
    if (!overrideEnabled) {
      contractContext.hookForm.setValue(
        'costModel.negotiated.model.scopeReductionSavingsOverride.amount',
        defaultScopeReductionValue
      );
    }
  }, [defaultScopeReductionValue, contractContext.hookForm, overrideEnabled]);

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      name="costModel.negotiated.model.scopeReductionSavingsOverride.amount"
      label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_ANNUALIZED')}
      component={FormReadOnlyText}
      componentProps={{ formatter: formatContractCurrency }}
      writeComponent={FormNumberField}
      writeComponentProps={{ allowsFloat: true }}
      width={12}
      disabled={!overrideEnabled}
      tooltip={{ content: <ScopeReductionSavingsTooltip />, maxWidth: '500px' }}
    />
  );
};
