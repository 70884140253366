import React, { ComponentType, useMemo } from 'react';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Loader } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { useGetVendorByIdQuery, useListVendorsPageContractsPaginatedQuery } from '@vertice/slices';
import VendorPageHeader from './VendorPageHeader';
import OverviewTab from '@vertice/dashboard/src/modules/saas/vendor/tabs/OverviewTab';
import ContractsTab from '@vertice/dashboard/src/modules/saas/vendor/tabs/ContractsTab';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRoutes } from '@verticeone/router/src';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';

export enum VendorTab {
  OVERVIEW = 'overview',
  CONTRACTS = 'contracts',
}

type TabDef = {
  id: VendorTab;
  label: string;
  component: ComponentType;
  isVisible: boolean;
};

function Vendor() {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const navigate = useNavigate();
  const { id: vendorId, activeTab: activeTabId } = useParams();

  const { data: contractsData, isLoading: isLoadingContracts } = useListVendorsPageContractsPaginatedQuery(
    { accountId, vendorId: vendorId! },
    { skip: !vendorId }
  );

  const { data: vendorData, isLoading: isLoadingVendorData } = useGetVendorByIdQuery(
    { vendorId: vendorId! },
    { skip: !vendorId }
  );

  const showContractsTab = useMemo(() => !!(contractsData && contractsData.contracts.length), [contractsData]);

  const tabs: TabDef[] = useMemo(
    () => [
      { id: VendorTab.OVERVIEW, label: t('VENDOR.TAB.OVERVIEW'), component: OverviewTab, isVisible: true },
      {
        id: VendorTab.CONTRACTS,
        label: t('VENDOR.TAB.CONTRACTS'),
        component: ContractsTab,
        isVisible: showContractsTab,
      },
    ],
    [t, showContractsTab]
  );

  const visibleTabs = useMemo(() => tabs.filter((tab) => tab.isVisible), [tabs]);
  const activeTab = useMemo(() => visibleTabs.find(({ id }) => id === activeTabId ?? ''), [visibleTabs, activeTabId]);
  if (!isLoadingContracts && !activeTab) {
    return (
      <Navigate
        replace={true}
        to={generatePath(routes.VENDORS.VENDOR.TAB.ABSOLUTE_PATH, { id: vendorId!, activeTab: visibleTabs[0].id })}
      />
    );
  }

  if (!vendorId || (!isLoadingVendorData && !vendorData)) {
    return <PageNotFound />;
  }

  return (
    <Stack>
      <VendorPageHeader vendorId={vendorId} />
      {activeTab ? (
        <>
          <TabsBottomLineWrapper paddingX={10}>
            <Tabs
              variant="outlined"
              value={activeTab.id}
              onChange={(_, value) => navigate(`../${value}`, { relative: 'path' })}
            >
              {visibleTabs.map(({ id, label }) => (
                <Tab key={id} value={id} label={label} />
              ))}
            </Tabs>
          </TabsBottomLineWrapper>
          <activeTab.component />
        </>
      ) : (
        <Loader />
      )}
    </Stack>
  );
}

export default Vendor;
