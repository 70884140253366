import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { chain } from 'lodash';

import { Tooltip } from '@verticeone/design-system/src';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
  EntraSourceIcon,
  GoogleSourceIcon,
  JumpCloudSourceIcon,
  OktaSourceIcon,
  OneLoginSourceIcon,
  EntraIcon,
  GoogleIcon,
  JumpCloudIcon,
  OktaIcon,
  OneLoginIcon,
} from '@vertice/assets';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

type DiscoverySourcesCellProps = {
  sources: ApplicationWithMeasureReportsListItem['sources'];
  useSourceIcons?: boolean;
  size?: number;
  spacing?: number;
};

type IconWithTooltipProps = {
  icon: JSX.Element;
  tooltip: string;
  size?: number;
};

const IconWithTooltip = ({ icon, tooltip }: IconWithTooltipProps) => (
  <Tooltip title={tooltip} size="S">
    <span>{icon}</span>
  </Tooltip>
);

/*
provider IDs:
  Provider:Google:Workspace:SocialLogin
  Provider:Google:Workspace:SAML
  Provider:Azure
  Provider:JumpCloud
  Provider:Okta
  Provider:OneLogin
  Provider:Simulator
*/

export const DiscoverySourcesCell = ({
  sources,
  useSourceIcons = true,
  size = 32,
  spacing = -3,
}: DiscoverySourcesCellProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" height={size} spacing={spacing}>
      {chain(sources || [])
        .map((item) => item.id)
        .sort()
        .uniq()
        .map((source) => {
          switch (source) {
            case 'Provider:Google:Workspace:SocialLogin':
              return (
                <IconWithTooltip
                  icon={
                    useSourceIcons ? (
                      <GoogleSourceIcon width={size} height={size} />
                    ) : (
                      <GoogleIcon width={size} height={size} />
                    )
                  }
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.GOOGLE_SOCIAL')}
                  key={source}
                />
              );
            case 'Provider:Google:Workspace:SAML':
              return (
                <IconWithTooltip
                  icon={
                    useSourceIcons ? (
                      <GoogleSourceIcon width={size} height={size} />
                    ) : (
                      <GoogleIcon width={size} height={size} />
                    )
                  }
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.GOOGLE_SSO')}
                  key={source}
                />
              );
            case 'Provider:Azure':
              return (
                <IconWithTooltip
                  icon={
                    useSourceIcons ? (
                      <EntraSourceIcon width={size} height={size} />
                    ) : (
                      <EntraIcon width={size} height={size} />
                    )
                  }
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.ENTRA')}
                  key={source}
                />
              );
            case 'Provider:JumpCloud':
              return (
                <IconWithTooltip
                  icon={
                    useSourceIcons ? (
                      <JumpCloudSourceIcon width={size} height={size} />
                    ) : (
                      <JumpCloudIcon width={size} height={size} />
                    )
                  }
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.JUMPCLOUD')}
                  key={source}
                />
              );
            case 'Provider:Okta':
              return (
                <IconWithTooltip
                  icon={
                    useSourceIcons ? (
                      <OktaSourceIcon width={size} height={size} />
                    ) : (
                      <OktaIcon width={size} height={size} />
                    )
                  }
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.OKTA')}
                  key={source}
                />
              );
            case 'Provider:OneLogin':
              return (
                <IconWithTooltip
                  icon={
                    useSourceIcons ? (
                      <OneLoginSourceIcon width={size} height={size} />
                    ) : (
                      <OneLoginIcon width={size} height={size} />
                    )
                  }
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.ONELOGIN')}
                  key={source}
                />
              );
            case 'Provider:Simulator': // demo only
              return (
                <IconWithTooltip
                  icon={<OktaSourceIcon width={size} height={size} />}
                  tooltip={t('APPLICATIONS.TOOLTIPS.SOURCE.OKTA')}
                  key={source}
                />
              );
            default:
              return null;
          }
        })
        .value()}
    </Stack>
  );
};
