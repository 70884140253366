import dayjs from 'dayjs';

import type { RiListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import type { RDSDataEnhanced } from './useRDSData';
import type { ShoppingCartItemType } from '../../components/providers/ShoppingCartProvider';
import type { ChartSeriesDefinition, OfferingData } from '../../../types';
import { SECOND_IN_MILLISECONDS, calculateCosts } from '../../../utils';

export type RdsRiOffering = OfferingData<{
  multiAZ: boolean;
}>;

export const parseRdsRiOfferings = (
  data: RiListOfferingsQuery | undefined,
  instance: RDSDataEnhanced,
  cartItems?: ShoppingCartItemType[] | null
): RdsRiOffering[] => {
  if (
    data?.riListOfferingsQuery?.__typename !== 'RIOfferingsRDSResult' ||
    data?.riListOfferingsQuery?.items === undefined ||
    data?.riListOfferingsQuery?.items?.length === 0
  ) {
    return [];
  }

  const items = !!cartItems
    ? data.riListOfferingsQuery.items.filter((item) =>
        cartItems?.some((cardItem) => cardItem.id === item.reservedDBInstancesOfferingId)
      )
    : data?.riListOfferingsQuery?.items;

  return items.map(
    ({
      reservedDBInstancesOfferingId,
      multiAZ,
      duration,
      offeringType,
      dbInstanceClass,
      recurringCharges,
      currencyCode,
      fixedPrice,
      usagePrice,
    }) => {
      const quantity =
        cartItems?.find((item) => item.id === reservedDBInstancesOfferingId)?.quantity ||
        instance.proposedNumberOfInstances;

      const { normalizedUnits, durationOnDemandCost, riCost, expectedSavings, upfrontCost, monthlyCost } =
        calculateCosts({
          duration,
          onDemandCost: instance.onDemandCost,
          onDemandUsage: instance.onDemandUsage,
          riInstanceNormalizationFactor: instance.riInstanceNormalizationFactor,
          recurringCharges,
          fixedPrice,
          usagePrice,
          quantity,
        });

      return {
        reservedInstancesOfferingId: reservedDBInstancesOfferingId,
        instanceType: dbInstanceClass,
        term: Math.round(dayjs.duration(duration * SECOND_IN_MILLISECONDS).asMonths()),
        offeringType,
        multiAZ,
        normalizedUnits,
        onDemandCost: durationOnDemandCost,
        riCost,
        expectedSavings,
        upfrontCost,
        monthlyCost,
        quantity,
        currencyCode,
      };
    }
  );
};

export const chartSeries: ChartSeriesDefinition[] = [
  {
    id: 'on_demand_usage',
    type: 'line',
    valueGetter: (item) => Number(item.onDemandUsage),
  },
  {
    id: 'total_usage',
    type: 'spline',
    valueGetter: (item) => Number(item.reservedUsage) + Number(item.onDemandUsage),
    zIndex: 1,
  },
  {
    id: 'reserved_unused',
    type: 'column',
    valueGetter: (item) =>
      Number(item.reservedCapacity) ? Number(item.reservedCapacity) - Number(item.reservedUsage) : null,
  },
  {
    id: 'reserved_usage',
    type: 'column',
    valueGetter: (item) => Number(item.reservedUsage),
  },
];
