import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useUuid } from '../../hooks/useUuid';
import { NotifMessage, SubscriberCallback } from './types';
import { useNotificationWebSocketContext } from './NotificationWebSocketContext';

type UseNotificationWebSocketSubscription = {
  filter: ((message: NotifMessage) => boolean) | boolean;
  callback: SubscriberCallback;
};

export const useNotificationWebSocketSubscription = ({ filter, callback }: UseNotificationWebSocketSubscription) => {
  const { subscribe, unsubscribe } = useNotificationWebSocketContext();
  const [lastMessage, setLastMessage] = useState<NotifMessage | null>(null);
  const id = useUuid();

  useEffect(() => {
    subscribe({
      id,
      callback: (msg) => {
        if ((typeof filter === 'function' && !filter(msg)) || (typeof filter === 'boolean' && !filter)) return;

        // Do not update state if the message is the same to avoid unnecessary rerenders
        setLastMessage((lastMsg) => (isEqual(lastMsg, msg) ? lastMsg : msg));

        callback(msg);
      },
    });

    return () => unsubscribe(id);
  }, [id, filter, callback, subscribe, unsubscribe]);

  return { lastMessage };
};
