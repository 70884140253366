import { useAccountContext } from '../../../../contexts/AccountContext';
import { useAuthorizer } from '@verticeone/auth/src';
import { useMemo } from 'react';
import { ContractPermissions } from '../types';
import { ReducerItem, AuthRequestProps } from '@verticeone/auth/src/authorization/reducer';

// exported for testing purposes
export const toContractPermissions = (
  contractId: string,
  permissions: Array<ReducerItem<string>>
): ContractPermissions => ({
  userCanReadContract: permissions.some(
    ({ metadata: { id: permissionId }, isAllowed }) => permissionId.startsWith(`${contractId}_read`) && isAllowed
  ),
  userCanWriteContract: permissions.some(
    ({ metadata: { id: permissionId }, isAllowed }) => permissionId.startsWith(`${contractId}_write`) && isAllowed
  ),
  userCanExecuteContract: permissions.some(
    ({ metadata: { id: permissionId }, isAllowed }) => permissionId.startsWith(`${contractId}_execute`) && isAllowed
  ),
});

// exported for testing purposes
export const toAuthorizerRequests = (accountId: string, contractId: string): AuthRequestProps[] => {
  return [
    {
      id: `${contractId}_read`,
      action: 'contract:ReadContract',
      object: `urn:verticeone:vertice:${accountId}:cm2:contract/${contractId}`,
    },
    {
      id: `${contractId}_read_restricted`,
      action: 'contract:ReadContractRestricted',
      object: `urn:verticeone:vertice:${accountId}:cm2:contract/${contractId}`,
    },
    {
      id: `${contractId}_write`,
      action: 'contract:WriteContract',
      object: `urn:verticeone:vertice:${accountId}:cm2:contract/${contractId}`,
    },
    {
      id: `${contractId}_write_restricted`,
      action: 'contract:WriteContractRestricted',
      object: `urn:verticeone:vertice:${accountId}:cm2:contract/${contractId}`,
    },
    {
      id: `${contractId}_execute`,
      action: 'contract:ExecuteContract',
      object: `urn:verticeone:vertice:${accountId}:cm2:contract/${contractId}`,
    },
    {
      id: `${contractId}_execute_restricted`,
      action: 'contract:ExecuteContractRestricted',
      object: `urn:verticeone:vertice:${accountId}:cm2:contract/${contractId}`,
    },
  ];
};

export const useUserContractsPermissions = (contractIds: string[]) => {
  const { accountId } = useAccountContext();

  const permissions = useAuthorizer(contractIds.flatMap((id) => toAuthorizerRequests(accountId, id)));

  const contractPermissions = useMemo(() => {
    return contractIds.reduce<Record<string, ContractPermissions>>((acc, contractId) => {
      acc[contractId] = toContractPermissions(contractId, permissions);
      return acc;
    }, {});
  }, [contractIds, permissions]);

  return {
    permissions: contractPermissions,
  };
};
