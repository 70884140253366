import React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useDataGridContext } from '@verticeone/design-system/src';
import { DatePicker } from '@verticeone/design-system/src';

const DatePickerCell = ({ id, field, value }: GridRenderCellParams) => {
  const { size } = useDataGridContext();
  const apiRef = useGridApiContext();

  return (
    <DatePicker
      onChange={(newValue) => {
        void apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
      size={size}
      value={value ?? null}
    />
  );
};

export default DatePickerCell;
