import { Task } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { parseUserRef, parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { getFullName } from '@verticeone/utils/formatting';
import { UsersByIdReturn } from '../../../hooks/useRelevantUsersById';

export const getTaskRows = (tasks: Task[], usersById: UsersByIdReturn) => {
  return (
    tasks.map((task) => {
      const assignees =
        task?.assignment?.map(parseUserRef).map(({ userId }) => ({
          fullName: getFullName(usersById[userId]) || userId,
          id: userId,
          email: usersById[userId]?.email,
          valid: !!usersById[userId],
        })) || [];
      return {
        assignees,
        id: parseUserTaskRef(task.ref).taskId,
        ...task,
      };
    }) || []
  );
};

// add test
