import { UpdateOptionsFn } from '../types';
import Highcharts from 'highcharts';
import { mergeWith, isArray } from 'lodash';

/**
 * Starts with an empty object and applies `optionsUpdaters` one by one. These can be used to modify and enhance the
 * Highcharts options object.
 *
 * @example
 * buildOptions([
 *   (options) => ({
 *     ...options,
 *     xAxis: { left: 5 }
 *   }),
 *   (options) => ({
 *     ...options,
 *     xAxis: {
 *       left: (options.xAxis?.left ?? 0) + 4
 *     }
 *   }),
 *   (options) => ({
 *     ...options,
 *     chart: {
 *       events: {
 *         render: onRender,
 *       },
 *     },
 *   }),
 * ])
 * // Returns
 * {
 *   xAxis: { left: 9 },
 *   chart: {
 *     events: {
 *       render: onRender
 *     }
 *   }
 * }
 *
 * // This is especially useful for composing different reusable configs
 * buildOptions([
 *   applyMyCustomAxesDesign,
 *   applyHighlightHoveredColumn,
 *   applyCustomFunkyTheme,
 * ])
 */
export const buildOptions = (optionsUpdaters: UpdateOptionsFn[]): Highcharts.Options =>
  optionsUpdaters.reduce((options, apply) => apply(options), {} as Highcharts.Options);

/**
 * Customizer function for option merge
 * @param original
 * @param override
 */
const overrideArrays = (original: unknown, override: unknown) => {
  if (isArray(original) && override !== undefined) {
    return override;
  }
};

/**
 * Deep merges two objects with Highcharts Options. Applies `newOptions` on top of `oldOptions`
 * If the original value is array, it is replaced with the new value
 * @example
 * mergeOptions(
 *   {
 *     chart: {
 *       events: {
 *         render: onRender,
 *       },
 *     },
 *     xAxis: { left: 7 },
 *   },
 *   {
 *     chart: { type: 'column' },
 *     xAxis: { left: 5 },
 *   }
 * )
 * // Returns
 * {
 *   chart: {
 *     type: 'column',
 *     events: {
 *       render: onRender,
 *     },
 *   },
 *   xAxis: { left: 7 }
 * }
 *
 * @example 2
 * buildOptions([
 *   (oldOpt) => mergeOptions(
 *     {
 *       chart: {
 *         events: {
 *           render: onRender,
 *         },
 *       },
 *       xAxis: { left: 7 },
 *     },
 *     oldOpt
 *   )
 * ])
 *
 * // Returns
 * {
 *   chart: {
 *     type: 'column',
 *     events: {
 *       render: onRender,
 *     },
 *   },
 *   xAxis: { left: 7 }
 * }
 * @example 3
 * buildOptions([
 *   mergeOptions(
 *     {
 *       chart: {
 *         events: {
 *           render: onRender,
 *         },
 *       },
 *       xAxis: { left: 7 },
 *     },
 *   )
 * ])
 *
 * // Returns
 * {
 *   chart: {
 *     type: 'column',
 *     events: {
 *       render: onRender,
 *     },
 *   },
 *   xAxis: { left: 7 }
 * }
 */

export function mergeOptions(newOptions: Highcharts.Options): UpdateOptionsFn;
export function mergeOptions(newOptions: Highcharts.Options, oldOptions: Highcharts.Options): Highcharts.Options;
export function mergeOptions(newOptions: Highcharts.Options, oldOptions?: Highcharts.Options) {
  // We wrap the generic lodash merge
  const mergeFn = (opt: Highcharts.Options) => mergeWith(opt, newOptions, overrideArrays);

  if (oldOptions) {
    // if oldOptions is passed, call mergeFn as mergeFn(newOptions)(oldOptions): Highcharts.Options
    return mergeFn(oldOptions);
  }

  // if oldOptions is not passed, return UpdateOptionsFn
  return mergeFn;
}
