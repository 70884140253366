import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import ContractSummaryCards from '@vertice/dashboard/src/modules/saas/contract/cards/ContractSummaryCard';
import {
  isContractInProgress,
  isContractPurchase,
  isContractRenewal,
  isContractRenewalInProgress,
} from '@vertice/core/src/modules/saas/contract/computed';
import PurchaseContractCard from '@vertice/dashboard/src/modules/saas/contract/cards/PurchaseContractCard';
import RenewalContractCard from '@vertice/dashboard/src/modules/saas/contract/cards/RenewalContractCard';
import { useFieldsConfig, useNextContractFieldsConfig } from './schemas/fieldsConfig';

export const shouldBeRenderedAsPurchaseCard = (contract: Contract) =>
  isContractPurchase(contract) && isContractInProgress(contract);

export const shouldBeRenderedAsRenewalCard = (contract: Contract) =>
  isContractRenewal(contract) && isContractRenewalInProgress(contract);

/**
 * Renders the contract using the most appropriate card component - either purchase/renewal card or summary.
 */
const useMostAppropriateConfigForContract = (contract: Contract) => {
  const fieldsConfig = useFieldsConfig();
  const nextContractFieldsConfig = useNextContractFieldsConfig();

  if (shouldBeRenderedAsPurchaseCard(contract)) {
    return { component: PurchaseContractCard, fieldsConfig: nextContractFieldsConfig };
  }

  if (shouldBeRenderedAsRenewalCard(contract)) {
    return { component: RenewalContractCard, fieldsConfig: nextContractFieldsConfig };
  }

  return { component: ContractSummaryCards, fieldsConfig: fieldsConfig };
};

export default useMostAppropriateConfigForContract;
