import { useEffect, useLayoutEffect, useRef } from 'react';

function useAutoProgressDelay(callback: () => void) {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setTimeout(() => savedCallback.current(), 2500);
    return () => clearTimeout(id);
  }, []);
}

export default useAutoProgressDelay;
