import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import DynamicFeedToneIcon from '@mui/icons-material/DynamicFeedTwoTone';

import { Card } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';

import { useRecommendationData } from './RecommendationProvider';

const Descriptions = () => {
  const { palette } = useTheme();
  const { item } = useRecommendationData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_RECOMMENDATIONS' });
  const { t: tRecommendations } = useTranslation('cloudRecommendations', { keyPrefix: item.code });
  const { empty: isEmptyResults } = item;
  const content = isEmptyResults
    ? t('EMPTY_RESULTS_DESCRIPTION')
    : tRecommendations('DESCRIPTION', { defaultValue: 'NOT_FOUND' });

  const title = isEmptyResults ? t('ADDITIONAL_DETAILS') : t('DESCRIPTION');

  if (content === 'NOT_FOUND') {
    return null;
  }

  return (
    <Card padding={6} gap={6}>
      <Text variant="heading" size="S" color="text1" tag="h2">
        <Stack direction="row" gap="7px" alignItems="center">
          <IconWrapper icon={DynamicFeedToneIcon} size="XS" htmlColor={palette.inactive.color2} />
          {title}
        </Stack>
      </Text>
      <Text variant="body-regular" size="M" color="text2" maxWidth="900px" whiteSpace="pre-line">
        {content}
      </Text>
    </Card>
  );
};

export default Descriptions;
