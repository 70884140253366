import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import { useWatch } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { SecurityScoreNone, SecurityScoreLow, SecurityScoreMedium, SecurityScoreHigh } from '@vertice/assets';
import { Text } from '@verticeone/design-system/src';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';

import { useRequestContext } from '../../../../../request/RequestDetailPage/RequestContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { FormToggleButtonField } from '../../../../../../forms/fields/FormToggleButtonField';
import { TaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { SecurityQuestionnaireOverview } from '../shared/formSections/SecurityQuestionnaireOverview';
import { FormCardWrapper } from '../shared/formSections/FormCardWrapper';
import { SecurityScore } from '../shared/constants';
import { SecurityApprovalCompletedFormEntry } from './formFields/SecurityApprovalCompletedFormEntry';
import { FormData } from './schema';

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

const getSecurityScoreIcon = (score?: SecurityScore) => {
  if (score === SecurityScore.LOW) return SecurityScoreLow;
  if (score === SecurityScore.MODERATE) return SecurityScoreMedium;
  if (score === SecurityScore.HIGH) return SecurityScoreHigh;
  return SecurityScoreNone;
};

export const SecurityApprovalForm = () => {
  const { t } = useTranslation();
  const { request } = useRequestContext();
  const taskData = useWatch<FormData>();

  const ScoreIcon = getSecurityScoreIcon(taskData.securityScore);

  return (
    <Stack gap={8}>
      <FormCardWrapper>
        <Text variant="caption" size="S" color="text1">
          {t('INTAKE_FORM.SECURITY_APPROVAL.HEADER', { vendorName: request?.input.vendor.name })}
        </Text>

        {request?.status === 'COMPLETED' && taskData.securityApproved !== undefined ? (
          <SecurityApprovalCompletedFormEntry securityApproved={taskData.securityApproved} />
        ) : (
          <TaskFormEntry<FormData, typeof FormToggleButtonField>
            name="securityApproved"
            required={true}
            component={FormToggleButtonField}
            componentProps={{
              compact: true,
              options: [
                {
                  title: t('INTAKE_FORM.SECURITY_APPROVAL.APPROVED'),
                  value: ApprovalStatus.APPROVED,
                  color: 'success',
                  isActive: taskData.securityApproved,
                },
                {
                  title: t('INTAKE_FORM.SECURITY_APPROVAL.REJECTED'),
                  value: ApprovalStatus.REJECTED,
                  color: 'error',
                  isActive: !taskData.securityApproved,
                },
              ],
            }}
          />
        )}

        <TaskFormEntry<FormData, typeof FormTextField>
          name="securityApprovalNotes"
          label={t('INTAKE_FORM.SECURITY_APPROVAL.APPROVAL_NOTES')}
          component={FormTextField}
          width={12}
          componentProps={{
            multiline: true,
            rows: 4,
            color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
          }}
        />
      </FormCardWrapper>

      <Accordion variant="label" size="XS" defaultExpanded={true}>
        <AccordionSummary
          title={
            <Text variant="label" size="XS" color="text1">
              {t('INTAKE_FORM.SECURITY_ASSESSMENT.DETAILS')}
            </Text>
          }
        />
        <AccordionDetails>
          <Stack gap={1}>
            <FormCardWrapper>
              <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
                <IconWrapper icon={SecurityIcon} />
                <Text variant="heading" size="XS" color="text1">
                  {t('INTAKE_FORM.SECURITY_APPROVAL.SCORE_TITLE')}
                </Text>
              </Stack>
              <Box alignSelf="center">
                <ScoreIcon />
              </Box>
            </FormCardWrapper>
            <SecurityQuestionnaireOverview />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
