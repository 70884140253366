import { useTranslation, Trans } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import {
  useConnectAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { IntegrationAccordion, IntegrationInput } from '@vertice/components';
import { Button } from '@verticeone/design-system/src';
import { STEPS, JUMPCLOUD_INTEGRATION_ID } from './common';
import { BulletList } from 'pages/Preferences/Integrations/components/WizardSteps';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';

type CollectStepProps = WizardStepProps & {
  accountId: string;
  organizationId: string;
  setOrganizationId: (id: string) => void;
  apiKey: string;
  setApiKey: (apiKey: string) => void;
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
};

export const CollectStep = ({
  setStep,
  accountId,
  organizationId,
  setOrganizationId,
  apiKey,
  setApiKey,
  testingInProgress,
  setTestingInProgress,
}: CollectStepProps) => {
  const { t } = useTranslation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const onBack = () => setStep(STEPS.DATA_SOURCES);
  const onTestConnection = () => {
    // revoke & connect & test
    setTestingInProgress(true);
    void revokeAccountIntegration({
      accountId,
      integrationId: JUMPCLOUD_INTEGRATION_ID,
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      connectAccountIntegrationMutation({
        accountId,
        integrationId: JUMPCLOUD_INTEGRATION_ID,
        integrationConnectionInput: {
          parameters: {
            secret: {
              organizationId,
              apiKey,
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          testAccountIntegrationMutation({
            accountId,
            integrationId: JUMPCLOUD_INTEGRATION_ID,
            testName: 'connection',
          });
        })
        .then(() => {
          setStep(STEPS.CHECK_CONNECTION);
        });
    });
  };

  const header = (
    <Text variant="heading" size="M">
      {t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.GET_SETTINGS')}
    </Text>
  );

  const content = (
    <Stack direction="column" gap="32px">
      <Stack gap="8px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setApiKey(changeEvent.target.value);
          }}
          value={apiKey}
          label={t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.API_KEY')}
          dataTestId="jumpCloudApiKey"
        />
        <IntegrationAccordion
          summary={t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.HOW_TO_FIND_JUMPCLOUD_API_KEY')}
          defaultExpanded
          details={
            <BulletList>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.JUMPCLOUD_KEY_INSTRUCTION_1">
                  Log in to the <Typography variant="body-bold-m">JumpCloud admin console</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.JUMPCLOUD_KEY_INSTRUCTION_2">
                  Navigate to the <Typography variant="body-bold-m">My API Key</Typography> from your profile icon.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.JUMPCLOUD_KEY_INSTRUCTION_3">
                  Copy your <Typography variant="body-bold-m">API Key</Typography> and paste into the field above.
                </Trans>
              </li>
            </BulletList>
          }
        />
      </Stack>
      <Stack gap="8px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setOrganizationId(changeEvent.target.value);
          }}
          value={organizationId}
          label={t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.ORGANIZATION_ID')}
          dataTestId="jumpCloudOrganizationId"
        />

        <IntegrationAccordion
          summary={t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.HOW_TO_FIND_JUMPCLOUD_ORGANIZATION_ID')}
          defaultExpanded
          details={
            <BulletList>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.JUMPCLOUD_ORGANIZATION_ID_INSTRUCTION_1">
                  The <Typography variant="body-bold-m">Organization ID</Typography> is required for any multi-tenant
                  account (where more than one organization has been set in your JumpCloud account).
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.JUMPCLOUD_ORGANIZATION_ID_INSTRUCTION_2">
                  Navigate to the <Typography variant="body-bold-m">Organization Profile</Typography> within{' '}
                  <Typography variant="body-bold-m">Settings</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JUMPCLOUD.USAGE.JUMPCLOUD_ORGANIZATION_ID_INSTRUCTION_3">
                  Under <Typography variant="body-bold-m">General</Typography>, copy your{' '}
                  <Typography variant="body-bold-m">Organization ID</Typography> and paste into the field above.
                </Trans>
              </li>
            </BulletList>
          }
        />
      </Stack>
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          onClick={onTestConnection}
          disabled={testingInProgress}
        >
          {t('PREFERENCES.INTEGRATIONS.TEST_CONNECTION')}
        </Button>,
      ]}
    />
  );
};
