import { useMemo } from 'react';
import { useContractContext } from '../ContractContext';
import { FieldPath } from 'react-hook-form';
import { ContractFormData, FormPlusComputedFieldPaths } from '../types';
import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import * as fieldsConfigUtils from '../utils/fieldsConfigUtils';

/** Performant hook that subscribes to visibility of a field and causes re-renders only when it changes. */
export const useIsContractFieldVisible = (fieldName: FormPlusComputedFieldPaths) => {
  const {
    fieldsRules: { fieldsConfigEvalContextAtom },
  } = useContractContext('DONT_REQUIRE_FETCHED');
  return useAtomValue(
    useMemo(
      () =>
        selectAtom(fieldsConfigEvalContextAtom, (evalContext) =>
          fieldsConfigUtils.isFieldVisible(fieldName, evalContext)
        ),
      [fieldName, fieldsConfigEvalContextAtom]
    )
  );
};

/** Performant hook that subscribes to editability of a field and causes re-renders only when it changes. */
export const useIsContractFieldEditable = (fieldName: FieldPath<ContractFormData>) => {
  const {
    fieldsRules: { fieldsConfigEvalContextAtom },
  } = useContractContext('DONT_REQUIRE_FETCHED');
  return useAtomValue(
    useMemo(
      () =>
        selectAtom(fieldsConfigEvalContextAtom, (evalContext) =>
          fieldsConfigUtils.isFieldEditable(fieldName, evalContext)
        ),
      [fieldName, fieldsConfigEvalContextAtom]
    )
  );
};

/** Performant hook that subscribes to optionality of a field and causes re-renders only when it changes. */
export const useIsContractFieldRequired = (fieldName: FieldPath<ContractFormData>) => {
  const {
    fieldsRules: { fieldsConfigEvalContextAtom },
  } = useContractContext('DONT_REQUIRE_FETCHED');
  return useAtomValue(
    useMemo(
      () =>
        selectAtom(fieldsConfigEvalContextAtom, (evalContext) =>
          fieldsConfigUtils.isFieldRequired(fieldName, evalContext)
        ),
      [fieldName, fieldsConfigEvalContextAtom]
    )
  );
};
