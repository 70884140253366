import React, { FC, useState } from 'react';
import { Text } from '@verticeone/design-system/src';
import { Stack } from '@mui/material';
import { Tooltip } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@verticeone/design-system/src';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

export type ContractIdCellProps = {
  contractId: string;
};

export const ContractIdCell: FC<ContractIdCellProps> = ({ contractId }) => {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = useState(t('SAAS.CONTRACT_LIST.COPY_TO_CLIPBOARD'));

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Text variant="body-regular" size="XS" color="text3">
        {contractId}
      </Text>
      <Tooltip content={tooltipText} size="XS" noArrow minified>
        <IconButton
          icon={ContentCopyOutlinedIcon}
          variant="plain"
          onClick={async (e: any) => {
            e.preventDefault();
            // Copy contractId to clipboard
            await navigator.clipboard.writeText(contractId);
            // Inform user that the ID was copied by changing the tooltip text
            setTooltipText(t('SAAS.CONTRACT_LIST.COPIED_TO_CLIPBOARD'));
            // Set tooltip text back to default after 1s delay
            setTimeout(() => {
              setTooltipText(t('SAAS.CONTRACT_LIST.COPY_TO_CLIPBOARD'));
            }, 1000);
            return false;
          }}
          aria-label="Copy to Clipboard"
        />
      </Tooltip>
    </Stack>
  );
};
