import { accountsAdminApi as api } from '../../api/accountsAdminAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    adminListAccounts: build.query<AdminListAccountsApiResponse, AdminListAccountsApiArg>({
      query: () => ({ url: `/accounts` }),
    }),
    adminCreateAccount: build.mutation<AdminCreateAccountApiResponse, AdminCreateAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts`, method: 'POST', body: queryArg.body }),
    }),
    adminUpdateAccount: build.mutation<AdminUpdateAccountApiResponse, AdminUpdateAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}`, method: 'PUT', body: queryArg.body }),
    }),
    adminObliterateAccount: build.mutation<AdminObliterateAccountApiResponse, AdminObliterateAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/obliterate`, method: 'POST', body: queryArg.body }),
    }),
    adminAddUserToAccount: build.mutation<AdminAddUserToAccountApiResponse, AdminAddUserToAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users`, method: 'POST', body: queryArg.body }),
    }),
    adminAssociateAccountManagerWithAccount: build.mutation<
      AdminAssociateAccountManagerWithAccountApiResponse,
      AdminAssociateAccountManagerWithAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/accountManagers`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    suspendAccount: build.mutation<SuspendAccountApiResponse, SuspendAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/suspendAccount`, method: 'POST' }),
    }),
    unsuspendAccount: build.mutation<UnsuspendAccountApiResponse, UnsuspendAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/unsuspendAccount`, method: 'POST' }),
    }),
    adminAssociateCustomerSuccessManagerWithAccount: build.mutation<
      AdminAssociateCustomerSuccessManagerWithAccountApiResponse,
      AdminAssociateCustomerSuccessManagerWithAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/customerSuccessManagers`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    executionStatus: build.mutation<ExecutionStatusApiResponse, ExecutionStatusApiArg>({
      query: (queryArg) => ({ url: `/executions/status`, method: 'POST', body: queryArg.body }),
    }),
    listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
      query: (queryArg) => ({ url: `/users`, params: { filters: queryArg.filters } }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accountAdminAPICodegen };
export type AdminListAccountsApiResponse =
  /** status 200 List of customer accounts. CompanyInformation response doesn't have to include all attributes since only
companyName is relevant in list.
 */ {
    accountId?: Identifier;
    name?: string;
    companyInformation?: CompanyInformation;
    /** This field is set to DEMO if the account is a demo account, it is undefined otherwise. */
    accountType?: 'DEMO';
    associatedManagerId?: Identifier;
    associatedSuccessManagerId?: Identifier;
    /** This field is set to ACTIVE if the account is active, it is set to INACTIVE otherwise. */
    accountStatus?: 'ACTIVE' | 'INACTIVE' | 'DISABLED' | 'SUSPENDED';
  }[];
export type AdminListAccountsApiArg = void;
export type AdminCreateAccountApiResponse = /** status 201 Provides information about user account.
Presence of executionId property denotes a possibly long running operation such as demo account provisioning, etc.
 */ {
  accountId: Identifier;
  executionId?: ExecutionId;
};
export type AdminCreateAccountApiArg = {
  body: {
    name: string;
    companyInformation?: CompanyInformation;
    accountSettings: AccountSettings;
    memo?: string;
    accountType?: 'NORMAL' | 'DEMO';
    /** CSV string with the list of contracts. Will be directly passed to Step Function as a parameter. Only valid if accountType is DEMO. */
    contracts?: string;
    retentionDate?: Timestamp;
  };
};
export type AdminUpdateAccountApiResponse = /** status 201 Provides information about user account.
Presence of executionId property denotes a possibly long running operation such as demo account provisioning, etc.
 */ {
  accountId: Identifier;
  executionId?: ExecutionId;
};
export type AdminUpdateAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
  body: {
    name: string;
    companyInformation?: CompanyInformation;
    memo?: string;
    accountType?: 'NORMAL' | 'DEMO';
    /** CSV string with the list of contracts. Will be directly passed to Step Function as a parameter. Only valid if accountType is DEMO. */
    contracts?: string;
    retentionDate?: Timestamp;
  };
};
export type AdminObliterateAccountApiResponse = unknown;
export type AdminObliterateAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
  body: {
    force?: boolean;
    /** JSON object with overrides for the task.
        Will be directly passed to Step Function as a parameter.
         */
    taskOverrides?: {
      disableAccount?: boolean;
      stopWorkflows?: boolean;
      deleteContracts?: boolean;
      deleteContractSnapshots?: boolean;
      deleteWorkflows?: boolean;
      deleteODSRecords?: boolean;
      deleteContractEvents?: boolean;
      deleteFiles?: boolean;
      deleteAccount?: boolean;
      useTTL?: boolean;
    };
  };
};
export type AdminAddUserToAccountApiResponse = /** status 201 ...
 */ {
  accountId: Identifier;
  userId: Identifier;
};
export type AdminAddUserToAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
  body: {
    userId: Identifier;
    role: 'admin' | 'user';
    /** Optional arbitrary data which will be stored together with the user role for given account */
    properties?: {
      jobTitle?: string;
    };
  };
};
export type AdminAssociateAccountManagerWithAccountApiResponse = /** status 200 Empty */ void;
export type AdminAssociateAccountManagerWithAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
  body: {
    userId: Identifier;
  };
};
export type SuspendAccountApiResponse = /** status 200 Empty */ void;
export type SuspendAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
};
export type UnsuspendAccountApiResponse = /** status 200 Empty */ void;
export type UnsuspendAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
};
export type AdminAssociateCustomerSuccessManagerWithAccountApiResponse = /** status 200 Empty */ void;
export type AdminAssociateCustomerSuccessManagerWithAccountApiArg = {
  /** Unique identifier for Account record */
  accountId: UuidIdentifier;
  body: {
    userId: Identifier;
  };
};
export type ExecutionStatusApiResponse = /** status 200 ...
 */ {
  status: ExecutionStatus;
};
export type ExecutionStatusApiArg = {
  body: {
    executionId: string;
  };
};
export type ListUsersApiResponse = /** status 200 ...
 */ UserInfo[];
export type ListUsersApiArg = {
  /** Applies filters to resulting list
    Please not that not all filters are compatible, so some queries might not be supported
    Currently supported filters are:
    userGroup=<group_name>
    Filters are applied as
    filters=[filterName:filterValue,anotherFilterName:anotherValue]
    When single filter is used, abbreviated variant is permitted
    filters=filterName:filterValue
     */
  filters?: string;
};
export type Identifier = string;
export type CompanyInformation = {
  companyName?: string;
  country?: string;
  registrationNumber?: string;
  registeredAddress?: string;
  website?: string;
};
export type ExecutionId = string;
export type AccountSettings = {
  /** Alphabetic Code based on Currency Codes (ISO 4217 Standard)
   */
  preferredCurrency: string;
};
export type Timestamp = string | null;
export type UuidIdentifier = string;
export type ExecutionStatus = 'RUNNING' | 'SUCCEEDED' | 'FAILED';
export type UserInfo = {
  userId: Identifier;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
};
export const {
  useAdminListAccountsQuery,
  useLazyAdminListAccountsQuery,
  useAdminCreateAccountMutation,
  useAdminUpdateAccountMutation,
  useAdminObliterateAccountMutation,
  useAdminAddUserToAccountMutation,
  useAdminAssociateAccountManagerWithAccountMutation,
  useSuspendAccountMutation,
  useUnsuspendAccountMutation,
  useAdminAssociateCustomerSuccessManagerWithAccountMutation,
  useExecutionStatusMutation,
  useListUsersQuery,
  useLazyListUsersQuery,
} = injectedRtkApi;
