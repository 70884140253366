import { PredefinedFormDef } from '../shared/types';
import { zodSchema, FormData } from './schema';
import { ContractingForm } from './ContractingForm';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/contracting\/v\d+$/,
  component: ContractingForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    contractSigned: getValue('contractSigned'),
    signingDate: getValue('signingDate'),
    vendorInformed: getValue('vendorInformed'),
    contractSentToSignatory: getValue('contractSentToSignatory'),
    additionalNotes: getValue('additionalNotes'),
  }),
};
