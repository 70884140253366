import React, { ReactNode, useState } from 'react';
import { VendorListFilter } from './types';

type VendorListFilterState = {
  listFilter?: VendorListFilter;
  setListFilter: (listFilter: VendorListFilter) => void;
  search?: string;
  setSearch: (search: string) => void;
  category?: string;
  setCategory: (category?: string) => void;
  vendorsOffset: number;
  setVendorsOffset: (offset: number) => void;
};

const defaultVendorListState = {
  listFilter: VendorListFilter.ALL_VENDORS,
  setListFilter: () => null,
  setSearch: () => null,
  setCategory: () => null,
  vendorsOffset: 0,
  setVendorsOffset: () => null,
};

export const VendorListFilterContext = React.createContext<VendorListFilterState>(defaultVendorListState);

const VendorListFilterContextWrapper = ({ children }: { children: ReactNode }) => {
  const [listFilter, setListFilter] = useState<VendorListFilter>(VendorListFilter.ALL_VENDORS);
  const [search, setSearch] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [vendorsOffset, setVendorsOffset] = useState<number>(0);

  return (
    <VendorListFilterContext.Provider
      value={{
        listFilter,
        setListFilter,
        search,
        setSearch,
        category,
        setCategory,
        vendorsOffset,
        setVendorsOffset,
      }}
    >
      {children}
    </VendorListFilterContext.Provider>
  );
};

export default VendorListFilterContextWrapper;
