import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { CheckTwoTone, CloseTwoTone, SendTwoTone } from '@mui/icons-material';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

import i18n from '../../../translate';
import useKeyboardShortcuts from '../../../utils/useKeyboardShortcuts';
import { MentionUser } from '../../Comment/types';
import { MentionsTextArea } from '../../Mentions';
import { Placeholders } from '../../Mentions/types';
import { EditCommentInternalCallback, SendCommentCallback } from '../types';
import { MENTION_PATTERN_GROUP, useMention } from '../utils';

const CommentInputWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  padding: theme.spacing(4),
  minHeight: 0,
  flexShrink: 0,
  position: 'relative',
}));

const SendCommentButtonWrapper = styled(Stack)<{ $disabled: boolean }>(({ theme, $disabled }) => ({
  position: 'absolute',
  right: theme.spacing(8),
  bottom: theme.spacing(6),
  cursor: $disabled ? 'not-allowed' : 'pointer',
}));

type EditComment = { id: string; content: string };

export type CommentInputProps = {
  currentUser?: string;
  users?: { [x: string]: MentionUser };
  onSend?: SendCommentCallback;
  disabled?: boolean;
  editedComment?: EditComment | null;
  onEdit?: EditCommentInternalCallback;
};

const CommentInput: FC<CommentInputProps> = ({
  currentUser,
  users = {},
  onSend,
  disabled = false,
  editedComment,
  onEdit,
}) => {
  const [draftComment, setDraftComment] = useState<string | undefined>(undefined);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const userMention = useMention(currentUser);
  const { t } = useTranslation(undefined, { i18n });
  const { palette } = useTheme();

  const resetField = () => setComment('');

  const handleSendComment = () => {
    if (comment) {
      setTimeout(() => onSend?.(comment, resetField), 0);
    }
  };

  const handleEditComment = () => {
    if (!editedComment || !comment) return;
    onEdit?.(editedComment.id, comment!);

    // Focus back to input (should be retained, but just in case)
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (editedComment) {
      setDraftComment(comment);
      setComment(editedComment.content);
    } else {
      setComment(draftComment);
      setDraftComment(undefined);
    }
    // Retain focus when changing edit state
    inputRef.current?.focus();
    // Disable exhaustive deps because we don't want to reset the draft comment when the editedComment changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedComment]);

  const { handlers } = useKeyboardShortcuts({
    ignoreLetterPresses: true,
    shortcuts: {
      submit: {
        keys: ['Enter'],
        action: !editedComment ? handleSendComment : handleEditComment,
        onDownCallback: (e) => e.preventDefault(), // Prevents new line on Enter
      },
      newLine: {
        keys: ['Alt', 'Enter'],
        onDownCallback: () => setComment((prev) => `${prev}\n`),
      },
    },
  });

  const props = useMemo(
    () => ({
      trigger: '@',
      markup: `<@${Placeholders.id}>`,
      regex: MENTION_PATTERN_GROUP,
      displayTransform: (id: string) => userMention(users[id]).label,
      appendSpaceOnAdd: true,

      suggestions: Object.entries(users).map(([id, user]) => ({ id, ...userMention(user, '') })),
    }),
    [users, userMention]
  );

  return (
    <CommentInputWrapper justifyContent="center" minHeight={0}>
      <MentionsTextArea
        size="S"
        value={comment}
        label={editedComment ? t('COMMENTS.EDITING') : undefined}
        onChange={(value) => setComment(value)}
        ref={inputRef}
        {...props}
        inputProps={{
          ...handlers,
          disabled: disabled,
          minHeight: 3.5,
          variant: 'outline',
          color: 'transparent',
          placeholder: t('COMMENTS.ADD_COMMENT'),
        }}
      />
      {!editedComment ? (
        <SendCommentButtonWrapper onClick={handleSendComment} $disabled={disabled}>
          <SendTwoTone htmlColor={palette.secondary.color2} fontSize="small" />
        </SendCommentButtonWrapper>
      ) : (
        <SendCommentButtonWrapper $disabled={disabled}>
          <Stack direction="row" gap={2}>
            <CloseTwoTone onClick={() => onEdit?.()} htmlColor={palette.error.color2} fontSize="small" />
            <CheckTwoTone
              onClick={handleEditComment}
              htmlColor={palette.success.color2}
              fontSize="small"
              sx={!comment ? { cursor: 'not-allowed', opacity: 0.5 } : undefined}
            />
          </Stack>
        </SendCommentButtonWrapper>
      )}
    </CommentInputWrapper>
  );
};

export default CommentInput;
