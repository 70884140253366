import { conversationsAPICodegen } from '../openapi/codegen/conversationsAPI';

const PREFIX = 'conversations';
const CONV_MESSAGES = `${PREFIX}Messages`;
const CONV_CURSOR = `${PREFIX}Cursor`;
const CONV_CONVERSATION = `${PREFIX}Conversation`;

type TagType = typeof CONV_MESSAGES | typeof CONV_CURSOR | typeof CONV_CONVERSATION;

export const enhancedConversationsAPI = conversationsAPICodegen.enhanceEndpoints<TagType>({
  addTagTypes: [CONV_MESSAGES],
  endpoints: {
    listMessages: { providesTags: [CONV_MESSAGES] },
    listThreadMessages: { providesTags: [CONV_MESSAGES] },
    createMessage: { invalidatesTags: [CONV_MESSAGES] },
    createThreadMessage: { invalidatesTags: [CONV_MESSAGES] },
    deleteMessage: { invalidatesTags: [CONV_MESSAGES] },
    updateMessage: { invalidatesTags: [CONV_MESSAGES] },

    getCursor: { providesTags: [CONV_CURSOR] },
    getThreadCursor: { providesTags: [CONV_CURSOR] },
    postCursor: { invalidatesTags: [CONV_CURSOR] },
    postThreadCursor: { invalidatesTags: [CONV_CURSOR] },

    listContributors: { providesTags: [CONV_MESSAGES, CONV_CONVERSATION] },
    listConversations: { providesTags: [CONV_CURSOR, CONV_MESSAGES, CONV_CONVERSATION] },
  },
});
