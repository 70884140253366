import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { ReactNode, forwardRef, useEffect, useState, useCallback } from 'react';
import { TextField, TextFieldProps, TextFieldVariant, allTextFieldVariants } from '../TextField';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  IconButtonProps as MuiIconButtonProps,
  InputAdornmentProps as MuiInputAdornmentProps,
  useTheme,
} from '@mui/material';
import { omit } from 'lodash';
import { InputAdornment } from '../InputAdornment';
import { IconButton, IconButtonProps } from '../IconButton';
import { CalendarTodayOutlined } from '@mui/icons-material';
import sizeDefinitions from '../TextField/styledVariants/sizeDefinitions';
import { getTextVariantStyle } from '../Text';
import { checkDateFormatAndWarn } from './utils';

export type DatePickerVariant = TextFieldVariant;
export const allVariants: DatePickerVariant[] = allTextFieldVariants;

export type DatePickerProps = TestProps &
  CustomizedProps & {
    // IMPLEMENTATION NOTE:
    // We don't want to leak the Dayjs type to the outside world, we always use YYYY-MM-DD strings.

    /** Date in the format of YYYY-MM-DD */
    value: string | null;
    /** Called when value changes, the value is always passed as YYYY-MM-DD */
    onChange: (newDate: string | null) => void;

    /** Minimum date allowed to be picked, in the format of YYYY-MM-DD */
    minDate?: string;

    /** Maximum date allowed to be picked, in the format of YYYY-MM-DD */
    maxDate?: string;

    // Convenience props redirected to TextField

    id?: string;
    name?: string;
    onBlur?: () => void;
    color?: DesignSystemColor;
    helperText?: ReactNode;
    hiddenLabel?: boolean;
    error?: boolean;
    disabled?: boolean;
    variant?: DatePickerVariant;
    label?: string;
    size?: DesignSystemSize;

    // For the special ones, there's always the textFieldProps
    textFieldProps?: TextFieldProps;
    shouldDisableDate?: (date: Dayjs) => boolean;
  };

// Existing deprecated date pickers:
// - packages/admin-panel/src/prototype/CostModelPlayground/components/DatePicker.tsx
// - packages/components/src/CustomDesktopDatePicker/CustomDesktopDatePicker.tsx
// - packages/components/src/ReactHookForm/FormDateField/FormDateField.tsx

const TextFieldSlot = forwardRef<HTMLDivElement, MuiTextFieldProps>((props, ref) => {
  const { extraProps, color, setLocalValue, variant, size, ...muiProps } = props as MuiTextFieldProps & {
    extraProps: TextFieldProps;
    setLocalValue: (newValue: Dayjs | null) => void;
  };

  return (
    <TextField
      ref={ref}
      {...muiProps}
      {...extraProps}
      onBlur={(e) => {
        extraProps.onBlur?.(e);
        muiProps.onBlur?.(e);
      }}
      onPaste={(e) => {
        const clipboardDate = e.clipboardData.getData('text');
        const newDate = dayjs(clipboardDate);
        if (newDate?.isValid()) {
          setLocalValue(newDate);
        }
      }}
    />
  );
});

const OpenPickerButton = forwardRef<HTMLButtonElement, MuiIconButtonProps>((props, ref) => {
  const {
    extraProps: { size = 'M' },
    color, // MUI color, we don't care about this one.
    size: _, // MUI size, we don't care about this one.
    ...muiProps
  } = props as MuiIconButtonProps & {
    extraProps: Partial<IconButtonProps>;
  };
  return (
    <IconButton
      variant="plain"
      icon={CalendarTodayOutlined}
      size={size}
      {...muiProps}
      sx={{ color: 'inherit', mx: `${-sizeDefinitions[size].gapX}px` }}
    />
  );
});

const InputAdornmentSlot = forwardRef<HTMLDivElement, MuiInputAdornmentProps>(
  ({ variant, color, children, ...props }, ref) => {
    return (
      <InputAdornment ref={ref} {...props} position="end">
        {children}
      </InputAdornment>
    );
  }
);

const parseNullableDate = (value: string | null): Dayjs | null => (value ? dayjs(value) : null);
const parseOptionalDate = (value?: string): Dayjs | undefined => (value ? dayjs(value) : undefined);

const DAY_WIDTH = 48;
const DAY_HEIGHT = 38;

export const DatePicker = ({
  id,
  value,
  label,
  color = 'primary',
  variant,
  disabled,
  error,
  helperText,
  hiddenLabel,
  minDate,
  maxDate,
  size,
  textFieldProps = {},
  testId,
  onChange,
  shouldDisableDate,
  ...otherProps
}: DatePickerProps) => {
  // holds temporary state including incomplete/invalid values
  const [localValue, setLocalValue] = useState(parseNullableDate(value));
  useEffect(() => {
    setLocalValue(parseNullableDate(value));
  }, [value]);

  useEffect(() => {
    checkDateFormatAndWarn(value, 'value');
    checkDateFormatAndWarn(minDate, 'minDate');
    checkDateFormatAndWarn(maxDate, 'maxDate');
  }, [value, minDate, maxDate]);

  const { palette } = useTheme();

  // Specific props for our DS TextField, especially those that are not supported by the MUI TextField.
  const textFieldExtraProps: TextFieldProps = {
    id,
    label,
    onBlur: (event) => {
      if (localValue && !localValue.isValid()) {
        setLocalValue(null);
        onChange(null);
      }
      textFieldProps.onBlur?.(event);
    },
    color,
    variant,
    hiddenLabel,
    disabled,
    error,
    helperText,
    testId,
    size,
    ...omit(textFieldProps, 'onBlur'),
  };

  const openPickerButtonExtraProps: Partial<IconButtonProps> = { size };

  const setLocalValueOnPaste = useCallback(
    (newValue: Dayjs | null) => {
      setLocalValue((oldValue: Dayjs | null) => {
        if (!oldValue || !newValue || !oldValue.isSame(newValue)) {
          onChange(newValue?.format('YYYY-MM-DD') ?? null);
        }
        return newValue;
      });
    },
    [onChange]
  );

  return (
    <MuiDatePicker<Dayjs>
      value={localValue}
      format="MMM DD, YYYY" // Localized semi-compact format (e.g. "Sep 4, 1986" for US, "4. 9. 1986" for Czech, etc.)
      minDate={parseOptionalDate(minDate)}
      maxDate={parseOptionalDate(maxDate)}
      onChange={(newValue) => {
        setLocalValue(newValue);
        if (newValue && !newValue.isValid()) {
          return;
        }
        onChange(newValue?.format('YYYY-MM-DD') ?? null);
      }}
      dayOfWeekFormatter={(date) => date.format('dd')}
      showDaysOutsideCurrentMonth
      yearsPerRow={3}
      shouldDisableDate={shouldDisableDate}
      disabled={disabled}
      slots={{
        textField: TextFieldSlot as typeof MuiTextField,
        inputAdornment: InputAdornmentSlot,
        openPickerButton: OpenPickerButton,
      }}
      slotProps={{
        textField: { extraProps: textFieldExtraProps, setLocalValue: setLocalValueOnPaste } as MuiTextFieldProps,
        openPickerButton: { extraProps: openPickerButtonExtraProps } as MuiIconButtonProps,
        desktopPaper: {
          sx: {
            backgroundColor: palette.input.bg,
            borderRadius: '16px',
            boxShadow: palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '70' }),
            border: `1px solid ${palette.core.color3}`,
          },
        },
        popper: {
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
          },
        },
        layout: {
          sx: {
            '.MuiDateCalendar-root': {
              width: '400px',
              maxHeight: '400px',
              height: '400px',
            },

            '.MuiPickersSlideTransition-root': {
              minHeight: `${6 * DAY_HEIGHT + 7 * 2 + 100}px`,
            },

            '.MuiPickersCalendarHeader-root': {
              marginTop: '24px',
              paddingRight: '24px',
              marginBottom: '16px',
              paddingLeft: '32px',
            },

            '.MuiDayCalendar-monthContainer': {
              paddingTop: '6px',
            },

            '.MuiPickersCalendarHeader-label': {
              ...getTextVariantStyle({ variant: 'body-bold', size: 'M' }),
              color: palette.text.color1,
            },

            '.MuiPickersDay-hiddenDaySpacingFiller': {
              width: `${DAY_WIDTH}px`,
              height: `${DAY_HEIGHT}px`,
            },

            '.MuiDayCalendar-root': {
              padding: '0 32px',
            },

            '.MuiDayCalendar-header': {
              borderBottom: `1px solid ${palette.core.color2}`,
            },

            '.MuiDayCalendar-weekDayLabel': {
              ...getTextVariantStyle({ variant: 'body-bold', size: 'M' }),
              color: palette.text.color1,
              width: `${DAY_WIDTH}px`,
              height: '52px',
              margin: 0,
            },

            '.MuiYearCalendar-root': {
              width: '400px',
              maxHeight: '320px',
            },

            '.MuiPickersYear-yearButton': {
              ...getTextVariantStyle({ variant: 'body-regular', size: 'M' }),
              width: '100px',
              height: '90px',
              borderRadius: '16px',

              '&:focus': {
                border: `1px solid ${palette.neutral.color3}`,
                background: 'none',
              },

              '&:hover': {
                color: palette.neutral.hover.color1,
                backgroundColor: palette.neutral.hover.color4,
              },

              '&.Mui-selected': {
                ...getTextVariantStyle({ variant: 'body-bold', size: 'M' }),
                color: palette[color].color4,
                backgroundColor: palette[color].color2,
                border: 'none',
                '&:focus': {
                  backgroundColor: palette[color].color2,
                },
                '&:hover': {
                  backgroundColor: palette[color].hover.color2,
                },
              },
            },
          },
        },
        day: {
          disableMargin: true,
          sx: {
            ...getTextVariantStyle({ variant: 'body-regular', size: 'M' }),
            color: palette.neutral.color1,
            borderRadius: '8px',
            width: `${DAY_WIDTH}px`,
            height: `${DAY_HEIGHT}px`,

            '&.MuiPickersDay-dayOutsideMonth': {
              color: palette.inactive.color3,
            },

            '&:focus': {
              border: `1px solid ${palette.neutral.color3}`,
              background: 'none',
            },

            '&:hover': {
              color: palette.neutral.hover.color1,
              backgroundColor: palette.neutral.hover.color4,
            },

            '&.Mui-selected': {
              ...getTextVariantStyle({ variant: 'body-bold', size: 'M' }),
              color: palette[color].color4,
              backgroundColor: palette[color].color2,
              border: 'none',
              '&:focus': {
                backgroundColor: palette[color].color2,
              },
              '&:hover': {
                backgroundColor: palette[color].hover.color2,
              },
            },

            '&.MuiPickersDay-today': {
              border: `1px solid ${palette.neutral.color1}`,
            },

            '&.Mui-disabled.Mui-disabled': {
              color: palette.inactive.color3,
            },
          },
        },
      }}
    />
  );
};
