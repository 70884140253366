import { useMemo } from 'react';
import {
  AccountValue,
  OrgResourceType,
  useOrgHierarchyQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { SerializedError } from '@reduxjs/toolkit';

export type AccountNameMap = { [arg: string]: string };

interface AccountNameMapResult {
  error: ErrorResponse | SerializedError | undefined;
  accountNameMap: { [arg: string]: string } | undefined;
}

const useAccountNameMap = (): AccountNameMapResult => {
  const { accountId } = useSelector(getAccount);
  const { data: orgAccountsQuery, error } = useOrgHierarchyQuery(
    { accountId: accountId!, orgResourceTypes: [OrgResourceType.Account] },
    { skip: !accountId }
  );

  const accountNameMap = useMemo(
    () =>
      orgAccountsQuery?.resourceQuery?.__typename === 'ResourceQueryResult'
        ? orgAccountsQuery?.resourceQuery?.items?.reduce<AccountNameMap>((acc, resourceItem) => {
            const item = resourceItem?.resource as AccountValue;
            acc[item.id] = item.name;
            return acc;
          }, {})
        : undefined,
    [orgAccountsQuery]
  );

  return {
    error,
    accountNameMap,
  };
};

export default useAccountNameMap;
