import React, { ReactNode, useRef } from 'react';
import { Stack, styled } from '@mui/material';
import { useDragToMoveEvents } from './useDragToMoveEvents';

const StyledCanvas = styled(Stack)(({ theme }) => ({
  cursor: 'grab',
  backgroundColor: theme.palette.core.color1,
  backgroundSize: '10px 10px, 80px 80px',
  backgroundImage:
    `linear-gradient(to bottom, transparent 3px, ${theme.palette.core.color1} 3px),` +
    `linear-gradient(to right, ${theme.palette.core.color3} 2px, transparent 2px),` +
    `linear-gradient(to right, transparent 3px, ${theme.palette.core.color1} 3px),` +
    `linear-gradient(to bottom, ${theme.palette.core.color3} 2px, transparent 2px)`,
}));

type TreeCanvasProps = {
  px?: number;
  py?: number;
  children?: ReactNode;
};

const TreeCanvas = ({ children, px, py }: TreeCanvasProps) => {
  const canvasRef = useRef(null);
  const dragEvents = useDragToMoveEvents(canvasRef);

  return (
    <StyledCanvas
      ref={canvasRef}
      py={py}
      direction="row"
      overflow="auto"
      onMouseDown={dragEvents.onMouseDown}
      onMouseMove={dragEvents.onMouseMove}
      onMouseUp={dragEvents.onMouseUp}
    >
      <Stack alignItems="center" flexGrow={1} flexBasis={0} px={px}>
        {children}
      </Stack>
    </StyledCanvas>
  );
};

export default TreeCanvas;
