import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import React, { FC, ReactNode } from 'react';
import { AccountContextType, useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export type PageBehindFeatureFlagProps = {
  isAccessible: (accountContext: AccountContextType) => boolean;
  children: ReactNode;
  fallback?: ReactNode;
};

const ConditionalPage: FC<PageBehindFeatureFlagProps> = ({ isAccessible, fallback = <PageNotFound />, children }) => {
  const accountContextData = useAccountContext();
  if (isAccessible(accountContextData)) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};

export default ConditionalPage;
