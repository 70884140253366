import { Dispatch, SetStateAction } from 'react';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type UseDropdownValueListDataParams = {
  selectedValues: CustomContractFieldDropdownOption[];
  setSelectedValues: Dispatch<SetStateAction<CustomContractFieldDropdownOption[]>>;
};

export type UseDropdownValueListDataReturn = {
  addValue: (values: CustomContractFieldDropdownOption) => void;
  updateValue: (values: CustomContractFieldDropdownOption) => void;
  reorderValues: (oldIndex: number, newIndex: number) => void;
};

const useDropdownValueListData = ({
  selectedValues,
  setSelectedValues,
}: UseDropdownValueListDataParams): UseDropdownValueListDataReturn => {
  const overrideDropdownOption = (
    valueToSet: CustomContractFieldDropdownOption,
    oldValues: CustomContractFieldDropdownOption[]
  ) => {
    const effectiveId = valueToSet.id;
    return oldValues?.reduce((acc: CustomContractFieldDropdownOption[], item: CustomContractFieldDropdownOption) => {
      if (item.id === effectiveId) {
        acc.push(valueToSet);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  const updateValue = async (updatedValue: CustomContractFieldDropdownOption) => {
    setSelectedValues((oldValues) => overrideDropdownOption({ ...updatedValue }, oldValues));
  };

  const addValue = async (newValue: CustomContractFieldDropdownOption) => {
    setSelectedValues((oldValues) => [...oldValues, newValue]);
  };

  const reorderValues = (oldIndex: number, newIndex: number) => {
    setSelectedValues((oldValues) => {
      const rowsClone = [...oldValues];
      const row = rowsClone.splice(oldIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      return rowsClone.map((value, index) => ({
        ...value,
        order: index,
      }));
    });
  };

  return {
    addValue,
    updateValue,
    reorderValues,
  };
};

export default useDropdownValueListData;
