import styled from '@mui/material/styles/styled';
import MuiLink from '@mui/material/Link';
import type { DesignSystemColor, DesignSystemSize } from '../../types';
import { getTextVariantStyle } from '../Text';

type BaseLinkProps = {
  $size: DesignSystemSize;
  $color: DesignSystemColor;
};

export const BaseLink = styled(MuiLink)<BaseLinkProps>(({ theme, $color, $size }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  textDecoration: 'none',
  ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
  color: theme.palette[$color].color1,
  '&:hover': {
    color: theme.palette[$color].hover.color1,
    ...getTextVariantStyle({ variant: 'link', size: $size }),
  },
  '&:focus': {
    color: theme.palette[$color].color3,
    ...getTextVariantStyle({ variant: 'link', size: $size }),
  },
  '&:visited': {
    color: theme.palette[$color].color2,
  },
  '&:not([href])': {
    color: theme.palette.inactive.color2,
    textDecoration: 'none',
  },
}));
