import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { validateFile } from '@vertice/core/src/modules/saas/contract/utils/contractFileValidation';
import { readTextFile } from '@verticeone/utils/file';
import { Dropzone, FileWithPath } from '@vertice/components';
import { useGetSsoDetailsQuery, useSaveSsoSamlConfigMutation } from '@vertice/slices';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import StepsIndicator from './StepsIndicator';
import styles from './SamlIntegrationUploadConfig.module.scss';
import commonStyles from './SamlIntegration.module.scss';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const SamlIntegrationUploadConfig = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();

  const { data: ssoDetails, isLoading: isSsoDetailsLoading } = useGetSsoDetailsQuery(
    { accountId: accountId! },
    { skip: !accountId }
  );

  useEffect(() => {
    if (ssoDetails?.properties?.saml?.metadata?.file) {
      setXmlConfig(ssoDetails?.properties?.saml?.metadata?.file);
      const file = new File([ssoDetails?.properties?.saml?.metadata?.file], 'metadata.xml', { type: 'text/xml' });
      const fileWithPath = Object.assign(file, { path: 'metadata.xml' });
      setFiles([fileWithPath]);
    }
  }, [ssoDetails]);

  const [saveSsoSamlConfig, { isLoading: isSaveSsoSamlConfigLoading }] = useSaveSsoSamlConfigMutation();

  const toCreateAppStep = () => navigate(`${ROUTES.SAML}/${ROUTES.SAML_CREATE_APP}`);
  const toRegisterSsoDomainStep = () => {
    if (xmlConfig) {
      void saveSsoSamlConfig({
        accountId: accountId!,
        accountSsoSamlConfig: {
          saml: {
            metadata: {
              file: xmlConfig,
            },
          },
        },
      })
        .unwrap()
        .then(() => {
          navigate(`${ROUTES.SAML}/${ROUTES.SAML_SSO_DOMAIN}`);
        });
    }
  };

  const [xmlConfig, setXmlConfig] = useState<string | undefined>();
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const handleChangeFiles = (newFiles: FileWithPath[]) => {
    const file = newFiles[0];

    const invalidFileMessage = validateFile(file, {
      extensions: ['.xml', '.xhtml'],
      mimeTypes: ['application/xhtml+xml', 'text/xml'],
    });
    if (invalidFileMessage) {
      // eslint-disable-next-line no-alert
      alert(invalidFileMessage);
      return;
    }

    setFiles(newFiles);
    void readTextFile(file).then((xmlContent) => setXmlConfig(xmlContent));
  };

  const handleRemoveFile = () => {
    setXmlConfig(undefined);
    setFiles([]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.UPLOAD_CONFIG.TITLE')}</Typography>
      </div>

      <div className={commonStyles['inner-body']}>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.UPLOAD_CONFIG.BODY')}</Typography>
        </div>

        <div className={`${styles.upload} config-upload`}>
          <Dropzone onFileAccepted={handleChangeFiles} acceptedFiles={files} onRemoveFile={handleRemoveFile} />
        </div>

        <div className={commonStyles['nav-buttons']}>
          <Button onClick={toCreateAppStep} className={commonStyles['nav-button']} variant="outlined">
            {t('SAML.BACK')}
          </Button>
          <LoadingButton
            size="small"
            onClick={toRegisterSsoDomainStep}
            className={commonStyles['nav-button']}
            variant="contained"
            disabled={!xmlConfig}
            loading={isSaveSsoSamlConfigLoading || isSsoDetailsLoading}
          >
            {t('SAML.NEXT')}
          </LoadingButton>
        </div>
      </div>

      <div className={commonStyles.steps}>
        <StepsIndicator total={5} current={3} />
      </div>
    </div>
  );
};

export default SamlIntegrationUploadConfig;
