import { RadioProps as MuiRadioPropsProps } from '@mui/material/Radio';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';

import { StyledRadioSwitch } from './StyledRadioSwitch';
import { testProps } from '../../utils/testProperties';

export type RadioSwitchProps = CustomizedProps &
  TestProps &
  Omit<MuiRadioPropsProps, 'size' | 'color' | 'disabled'> & {
    size?: DesignSystemSize;
    color?: DesignSystemColor;
    disabled?: boolean;
  };

export const RadioSwitch = ({ color, size = 'M', disabled, testId, ...otherProps }: RadioSwitchProps) => (
  <StyledRadioSwitch
    color={color}
    $size={size}
    disabled={disabled}
    {...testProps(testId, 'radio-switch')}
    {...otherProps}
  />
);
