import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContractCheck } from '@vertice/assets';
import { Header } from '@vertice/core/src/components/Dialog/Components';

type ConfirmationPanelProps = {
  headingTitleKey: string;
  vendorName?: string;
};

export const ConfirmationPanel: React.FC<ConfirmationPanelProps> = ({ vendorName, headingTitleKey }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" alignItems="center" gap={10} flexGrow={1} justifyContent="center">
      <ContractCheck />
      <Header title={t(headingTitleKey, { vendorName })} centered />
    </Stack>
  );
};
