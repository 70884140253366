import { ReactElement, ReactNode } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';

import { useRoutes } from '@verticeone/router/src';
import { BreadcrumbsV2 } from '@verticeone/design-system/src';

const MAX_CARD_WIDTH = 1152;

type IntegrationSetupProps = {
  breadcrumb: {
    label: string;
    icon: ReactElement;
  };
  actionButton: ReactNode;
  stepContent: ReactNode;
};

export const IntegrationSetup = ({ breadcrumb: { label, icon }, actionButton, stepContent }: IntegrationSetupProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const routes = useRoutes();

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
        p={6}
        borderBottom={`1px solid ${palette.core.color3}`}
      >
        <Box paddingY={2}>
          <BreadcrumbsV2
            items={[
              {
                label: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_PREFERENCES'),
                component: Link,
                to: generatePath(routes.PREFERENCES.ABSOLUTE_PATH),
              },
              {
                label: label,
                startAdornment: icon,
              },
            ]}
          />
        </Box>
        {actionButton}
      </Stack>
      <Stack direction="row" justifyContent="center" p={6}>
        <Box flex={1} maxWidth={MAX_CARD_WIDTH}>
          {stepContent}
        </Box>
      </Stack>
    </Stack>
  );
};
