import OrigSelect, { AsyncCreatableProps as OrigSelectProps } from 'react-select/async-creatable';
import { Stack } from '@mui/material';
import { CustomizedProps, TestProps } from '../../types';
import { CustomSelectProps, GroupBase } from '../SelectCommon';
import { useStyledSelectComponents, useSelectStylesConfig } from '../SelectCommon';
import sizeDefinitions from '../TextField/styledVariants/sizeDefinitions';
import { testProps } from '../../utils/testProperties';
import { Text } from '../Text';
import React, { forwardRef, RefAttributes } from 'react';
import { SelectInstance } from 'react-select';

export type SelectAsyncCreatableProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = TestProps &
  CustomizedProps &
  OrigSelectProps<Option, IsMulti, Group> &
  RefAttributes<SelectInstance<Option, IsMulti, Group>> &
  CustomSelectProps;

export type SelectAsyncCreatableComponentType = <
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectAsyncCreatableProps<Option, IsMulti, Group>
) => JSX.Element;

export const SelectAsyncCreatable = forwardRef(
  <
    Option extends unknown = unknown,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    {
      color = 'primary',
      variant = 'outlined',
      size = 'M',
      error = false,
      helperText,
      maxMultiChips,
      className,
      testId,
      components: componentOverrides = {},
      ...reactSelectProps
    }: SelectAsyncCreatableProps<Option, IsMulti, Group>,
    ref: React.Ref<SelectInstance<Option, IsMulti, Group>> | undefined
  ) => {
    const customComponents = useStyledSelectComponents<Option, IsMulti, Group>(size, color, maxMultiChips);
    const stylesConfig = useSelectStylesConfig<Option, IsMulti, Group>({ color, variant, size, error });
    const sizeDef = sizeDefinitions[size];

    return (
      <Stack spacing={`${sizeDef.helperTextGap}px`} className={className} {...testProps(testId, 'select')}>
        <OrigSelect
          ref={ref}
          styles={stylesConfig}
          components={{ ...customComponents, ...componentOverrides }}
          cacheOptions
          defaultOptions
          hideSelectedOptions={false}
          closeMenuOnSelect={!reactSelectProps.isMulti}
          {...reactSelectProps}
        />
        {helperText && (
          <Text
            color={error && !reactSelectProps.isDisabled ? 'error' : 'text3'}
            variant="body-regular"
            size={sizeDef.helperTextSize}
            px={`${sizeDef.paddingX}px`}
          >
            {helperText}
          </Text>
        )}
      </Stack>
    );
  }
) as SelectAsyncCreatableComponentType;
