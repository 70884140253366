import { FormEvent } from 'react';
import { ContractContextData } from '../types';
import useEditContractURLState from './useEditContractURLState';

const useFormUpdateActions = (contractContext: ContractContextData) => {
  useEditContractURLState(contractContext);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await contractContext.hookForm.handleSubmit(contractContext.saving.updateContractWithPermissionCheck)();
  };

  const handleCancel = () => {
    contractContext.editMode.cancel();
  };

  return {
    handleSubmit,
    handleCancel,
  };
};

export default useFormUpdateActions;
