import { DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { useTheme } from '@mui/material';
import { GaugeLabel } from './GaugeLabel';
import { GaugeWrapper } from './GaugeWrapper';

export type PieProps = TestProps & {
  value: number;
  maxValue?: number;
  color?: DesignSystemColor;
  size?: number;
  label?: string;
  labelSize?: DesignSystemSize;
  labelColor?: string;
};

export const Pie = ({
  value,
  maxValue = 100,
  color = 'primary',
  size = 42,
  label,
  labelSize = 'XL',
  labelColor,
  testId,
}: PieProps) => {
  const { palette } = useTheme();

  const radius = size / 4;
  const circumference = 2 * Math.PI * radius;
  const normalizedValue = Math.round((value * circumference) / maxValue);
  const remainingValue = circumference - normalizedValue;
  const offset = circumference / 4;
  const width = '50%';

  return (
    <GaugeWrapper size={size} radius={radius} testId={testId} component="pie-gauge">
      <circle className="background-segment" fill="transparent" stroke={palette[color].color3} strokeWidth={width} />
      {!!value && (
        <circle
          className="value-segment"
          fill="transparent"
          stroke={palette[color].color2}
          strokeWidth={width}
          strokeDasharray={`${normalizedValue} ${remainingValue}`}
          strokeDashoffset={offset}
        />
      )}
      <GaugeLabel label={label ?? value} color={labelColor ?? palette.core.bg} size={labelSize} />
    </GaugeWrapper>
  );
};
