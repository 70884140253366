import { useCallback, useRef } from 'react';
import Highcharts from 'highcharts';
import { mergeOptions } from '../utils/optionsUtils';
import { UpdateOptionsFn } from '../types';

export const useCustomCrosshair = (color: string, strokeWidth: number) => {
  const crosshairRef = useRef<Highcharts.SVGElement>();

  const mouseOver = useCallback(
    (event: any) => {
      const chart = event.target.series.chart;

      const view = chart.renderer;
      const left = chart.plotLeft;
      const top = chart.plotTop;
      const height = chart.plotHeight;
      const x = event.target.plotX;
      const y = event.target.plotY;

      crosshairRef.current = view
        .path(['M', left + x, y + 8, 'L', left + x, top + height])
        .attr({
          'stroke-width': strokeWidth,
          zIndex: event.target.series.options.zIndex,
          stroke: color,
        })
        .add();
    },
    [color, strokeWidth]
  );

  const mouseOut = useCallback(() => {
    if (crosshairRef.current) {
      crosshairRef.current.destroy();
    }
  }, []);

  return useCallback<UpdateOptionsFn>(
    (options) =>
      mergeOptions(
        {
          plotOptions: {
            series: {
              point: {
                events: { mouseOver, mouseOut },
              },
            },
          },
        },
        options
      ),
    [mouseOut, mouseOver]
  );
};
