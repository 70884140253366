import React from 'react';
import { FormControl } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system/src';
import FormSwitchField from '@vertice/core/src/modules/forms/fields/FormSwitchField';
import { useTranslation } from 'react-i18next';

export const PurchaseRequirements = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl variant="outlined">
        <TextFieldCaption label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.LABEL')} size="XS" />
        <FormSwitchField
          name="legalReviewRequired"
          label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.LEGAL_REVIEW_REQUIRED')}
        />
      </FormControl>

      <FormControl variant="outlined">
        <FormSwitchField
          name="securityReviewRequired"
          label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.SECURITY_REVIEW_REQUIRED')}
        />
      </FormControl>

      <FormControl variant="outlined">
        <FormSwitchField name="piiExpectedToBeHeld" label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.PII_TO_BE_HELD')} />
      </FormControl>
    </>
  );
};
