import { taggingApi as api } from '../../api/taggingAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    listTags: build.query<ListTagsApiResponse, ListTagsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tags`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults, tagIdPrefix: queryArg.tagIdPrefix },
      }),
    }),
    createTag: build.mutation<CreateTagApiResponse, CreateTagApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tags`,
        method: 'POST',
        body: queryArg.createTagRequest,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults, tagIdPrefix: queryArg.tagIdPrefix },
      }),
    }),
    updateTag: build.mutation<UpdateTagApiResponse, UpdateTagApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tags/${queryArg.tagId}`,
        method: 'POST',
        body: queryArg.updateTagRequest,
      }),
    }),
    deleteTag: build.mutation<DeleteTagApiResponse, DeleteTagApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/tags/${queryArg.tagId}`, method: 'DELETE' }),
    }),
    getTag: build.query<GetTagApiResponse, GetTagApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/tags/${queryArg.tagId}` }),
    }),
    deleteTagAssignment: build.mutation<DeleteTagAssignmentApiResponse, DeleteTagAssignmentApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tags/${queryArg.tagId}/assignments/${queryArg.ref}`,
        method: 'DELETE',
      }),
    }),
    getTagAssignment: build.query<GetTagAssignmentApiResponse, GetTagAssignmentApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tags/${queryArg.tagId}/assignments/${queryArg.ref}`,
      }),
    }),
    assignTags: build.mutation<AssignTagsApiResponse, AssignTagsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/assignments/${queryArg.ref}`,
        method: 'PUT',
        body: queryArg.assignTagsRequest,
      }),
    }),
    listTagAssignments: build.query<ListTagAssignmentsApiResponse, ListTagAssignmentsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/assignments`,
        params: {
          ref: queryArg.ref,
          tagId: queryArg.tagId,
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as taggingApiCodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type ListTagsApiResponse = /** status 200 List of tags */ {
  tags: Tag[];
  nextToken?: PaginationToken;
};
export type ListTagsApiArg = {
  accountId: Identifier;
  nextToken?: PaginationToken;
  maxResults?: number;
  tagIdPrefix?: string;
};
export type CreateTagApiResponse = /** status 201 Newly created tag */ {
  tag: Tag;
};
export type CreateTagApiArg = {
  accountId: Identifier;
  nextToken?: PaginationToken;
  maxResults?: number;
  tagIdPrefix?: string;
  /** Create tag request */
  createTagRequest: {
    tag: {
      label?: string;
      tagId?: string;
    };
  };
};
export type UpdateTagApiResponse = /** status 200 Updated tag */ {
  tag: Tag;
};
export type UpdateTagApiArg = {
  accountId: Identifier;
  tagId: Identifier;
  /** Update tag request */
  updateTagRequest: {
    label?: string;
  };
};
export type DeleteTagApiResponse = /** status 200 Deleted tag */ object;
export type DeleteTagApiArg = {
  accountId: Identifier;
  tagId: Identifier;
};
export type GetTagApiResponse = /** status 200 Existing tag */ {
  tag: Tag;
};
export type GetTagApiArg = {
  accountId: Identifier;
  tagId: Identifier;
};
export type DeleteTagAssignmentApiResponse = /** status 200 Deleted tag assignment */ object;
export type DeleteTagAssignmentApiArg = {
  accountId: Identifier;
  tagId: Identifier;
  ref: Reference;
};
export type GetTagAssignmentApiResponse = /** status 200 Existing tag */ {
  tagAssignment: TagAssignment;
};
export type GetTagAssignmentApiArg = {
  accountId: Identifier;
  tagId: Identifier;
  ref: Reference;
};
export type AssignTagsApiResponse = /** status 201 Assign tags response */ {
  tagAssignments?: TagAssignment[];
};
export type AssignTagsApiArg = {
  accountId: Identifier;
  ref: Reference;
  /** Assign tags request */
  assignTagsRequest: {
    assignTags?: {
      tagId?: string;
      value?: string;
      label?: string | null;
    }[];
  };
};
export type ListTagAssignmentsApiResponse = /** status 200 List of tag assignments */ {
  tagAssignments: TagAssignment[];
  nextToken?: PaginationToken;
};
export type ListTagAssignmentsApiArg = {
  accountId: Identifier;
  ref?: Reference;
  tagId?: Identifier;
  nextToken?: PaginationToken;
  maxResults?: number;
};
export type Identifier = string;
export type IsoDateTime = string;
export type PrincipalIdentifier = string;
export type Tag = {
  tagId: Identifier;
  accountId: Identifier;
  /** Label to display */
  label: string;
  createdAt?: IsoDateTime;
  createdBy?: PrincipalIdentifier;
  changedAt?: IsoDateTime;
  changedBy?: PrincipalIdentifier;
  changeNumber?: number;
};
export type PaginationToken = string | null;
export type Reference = string;
export type TagAssignment = {
  tagId: Identifier;
  accountId: Identifier;
  taggedRecordReference: Reference;
  value: string;
  /** Label for tag assignment */
  label?: string | null;
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useListTagsQuery,
  useLazyListTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useGetTagQuery,
  useLazyGetTagQuery,
  useDeleteTagAssignmentMutation,
  useGetTagAssignmentQuery,
  useLazyGetTagAssignmentQuery,
  useAssignTagsMutation,
  useListTagAssignmentsQuery,
  useLazyListTagAssignmentsQuery,
} = injectedRtkApi;
