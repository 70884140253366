import { CostModelBase } from './types';
import { useFeatures } from '../../../features/useFeatures';
import { FEATURES } from '../../../features/constants';
import {
  COST_MODEL_CLASS as AnnualCostApproximationClass,
  getCostModelBase as getAnnualCostModelBase,
} from './AnnualCostApproximation';
import {
  COST_MODEL_CLASS as AdvancedLinearApproximationClass,
  getCostModelBase as getAdvancedCostModelBase,
} from './AdvancedLinearApproximation/AdvancedLinearApproximation';

export type UseDefaultCostModelReturn = {
  isLoading: boolean;
  className: string;
  getCostModelBase: () => CostModelBase;
};

export const useDefaultCostModel = (): UseDefaultCostModelReturn => {
  const { isEnabled, isLoading } = useFeatures();
  const advancedCostModels = isEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  return {
    isLoading: isLoading,
    className: advancedCostModels ? AdvancedLinearApproximationClass : AnnualCostApproximationClass,
    getCostModelBase: advancedCostModels ? getAdvancedCostModelBase : getAnnualCostModelBase,
  };
};
