import { useIssueIdentityTokenForTgtMutation } from '@vertice/slices';
import { useCallback, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import useLocalStorage from 'use-local-storage';
import { AuthContextData, AuthType } from '@verticeone/auth/src';
import AuthUser from '@verticeone/auth/src/authentication/AuthUser';
import getAssumeRoleJwtToken from './getAssumeRoleJwtToken';
import mapGroupToRole from './mapGroupToRole';

export type UseAssumeRoleAuthenticatorReturn = AuthContextData & {
  signIn: (token: string) => Promise<boolean>;
  signOut: () => Promise<void>;
  error: AssumeRoleError | null;
};

type AssumeRoleError = { status: number };

export const STORAGE_KEY = 'assumeRoleToken';

const parseLoggedInUser = (assumeRoleToken: string): AuthUser => {
  const decodedJwt = jwtDecode<{
    email: string;
    userId: string;
    aid: string;
    groups: string;
    exp: number;
    name: string;
  }>(assumeRoleToken);

  const role = mapGroupToRole(decodedJwt.groups);
  return {
    userId: decodedJwt.userId,
    email: decodedJwt.email,
    name: decodedJwt.name,
    isIatUser: true,
    accountId: decodedJwt.aid,
    roles: role ? [role] : [],
    expiresAt: decodedJwt.exp * 1000,
  };
};

// When using the hook multiple times, we still want only one watchdog.
let expirationWatchdogTimer: number | undefined = undefined;

/**
 * Periodically checks for expiration and calls the signOut function if expired necessary.
 * @param expiresAt Expiration timestamp in milliseconds. If undefined, the watchdog is gets disabled (no extra signOut gets initiated).
 * @param signOut Function to call when the token expires.
 */
const useExpirationWatchdog = (expiresAt: number | undefined, signOut: () => Promise<void>) => {
  useEffect(() => {
    if (!expiresAt || expirationWatchdogTimer) {
      return;
    }
    // We don't use setTimeout because it gets prolonged after device resumes from sleep.
    expirationWatchdogTimer = window.setInterval(() => {
      if (expiresAt && expiresAt - Date.now() <= 0) {
        void signOut();
      }
    }, 5000);
    return () => {
      window.clearInterval(expirationWatchdogTimer);
      expirationWatchdogTimer = undefined;
    };
  }, [expiresAt, signOut]);
};

const useAssumeRoleAuth = (): UseAssumeRoleAuthenticatorReturn => {
  const [issueIdentityTokenForTgtMutation] = useIssueIdentityTokenForTgtMutation();
  const [error, setError] = useState<AssumeRoleError | null>(null);

  // Thanks to using useLocalStorage library, we can use this hook even multiple times. Every time we store a new value,
  // all the useLocalStorage instances will get updated/rerendered -> every use of useAssumeRoleAuth will
  // know that a login was successful, user signed out etc.
  const [currentJwtToken, saveCurrentJwtToken] = useLocalStorage<string | undefined>(STORAGE_KEY, undefined);

  const signOut = useCallback(async () => {
    saveCurrentJwtToken(undefined);
    setError(null);
  }, [saveCurrentJwtToken]);

  const signIn = useCallback(
    async (token: string): Promise<boolean> => {
      setError(null);

      const parsedToken = jwtDecode<{ aid: string }>(token);
      if (!parsedToken) {
        console.error('Assume role: Cannot parse the token from URL');
        setError({ status: 401 });
        saveCurrentJwtToken(undefined);
        return false;
      }

      const accountId = parsedToken.aid;

      try {
        const { identityToken } = await issueIdentityTokenForTgtMutation({ token, accountId }).unwrap();
        saveCurrentJwtToken(identityToken);
        return true;
      } catch (e) {
        console.error('Assume role failed', e);
        setError(e as AssumeRoleError);
        saveCurrentJwtToken(undefined);
        return false;
      }
    },
    [issueIdentityTokenForTgtMutation, saveCurrentJwtToken]
  );

  const user = useMemo(() => (currentJwtToken ? parseLoggedInUser(currentJwtToken) : undefined), [currentJwtToken]);

  useExpirationWatchdog(user?.expiresAt, signOut);

  return useMemo(
    () => ({ type: AuthType.ASSUME_ROLE, signIn, error, signOut, user, getJwtToken: getAssumeRoleJwtToken }),
    [signIn, error, signOut, user]
  );
};

export default useAssumeRoleAuth;
