import React, { createContext, ReactNode, useContext } from 'react';
import { useRouteNavigate } from '@verticeone/router/src';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { ExtractableRoute } from '@verticeone/router/src/routes/utils';
import { SPServices } from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/types';

export type Tab = {
  id: SPServices;
  component?: () => ReactNode;
};

interface CloudOptimizeContextProps {
  activeTab: Tab;
  setActiveTab: (product: SPServices) => void;
  tabs: Tab[];
}

const initialTab: Tab = {
  id: 'compute',
};

const CloudOptimizeContext = createContext<CloudOptimizeContextProps>({
  activeTab: initialTab,
  setActiveTab: () => {},
  tabs: [],
});

interface CloudOptimizeProviderProps {
  children: React.ReactNode;
  tabs: Tab[];
  routeConfig: {
    path: string;
    route: ExtractableRoute;
  };
}

export const CloudOptimizeProvider = ({ children, tabs, routeConfig }: CloudOptimizeProviderProps) => {
  const { navigate } = useRouteNavigate();
  const { product } = useParams<{ product: SPServices }>();
  const activeTabId = product?.toLowerCase();

  if (tabs.length === 0) {
    return <Navigate replace={true} to={ROUTES.CLOUD_RIO} />;
  }

  if (!activeTabId || !tabs.some((tab) => tab.id === activeTabId)) {
    return (
      <Navigate
        replace={true}
        to={generatePath(routeConfig?.path || '', { activeTab: 'optimize', product: tabs[0]?.id })}
      />
    );
  }

  const setActiveTab = (tabId: SPServices) => {
    if (routeConfig?.route) {
      navigate(routeConfig.route, { product: tabId });
    }
  };

  return (
    <CloudOptimizeContext.Provider
      value={{
        activeTab: tabs.find((tab) => tab.id === activeTabId) || tabs[0],
        setActiveTab,
        tabs,
      }}
    >
      {children}
    </CloudOptimizeContext.Provider>
  );
};

export const useCloudOptimize = (): CloudOptimizeContextProps => useContext(CloudOptimizeContext);
