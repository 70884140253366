import {
  ListContractTasksForUserApiArg,
  ListContractTasksForUserApiResponse,
  Task,
  useLazyListContractTasksForUserQuery,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFetchPaginated } from '../useFetchPaginated';
import { useLoggedUser } from '@verticeone/auth/src';

export const useFirstActiveContractTaskForUser = (accountId: string, contractId?: string) => {
  const [getTasks] = useLazyListContractTasksForUserQuery();
  const { userId } = useLoggedUser();

  const {
    items: tasks,
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
    refetch: refetchTasks,
  } = useFetchPaginated<ListContractTasksForUserApiResponse, ListContractTasksForUserApiArg, Task>({
    fetchFn: getTasks,
    getItemsFn: (data) => data.items || [],
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: { accountId: accountId!, contractId: contractId!, userId: userId! },
    preferCache: false,
    skip: !contractId,
  });

  const firstTask = tasks?.slice().pop();

  return {
    firstTask,
    isLoadingTasks,
    isFetchingTasks,
    refetchTasks,
  };
};
