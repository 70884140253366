import { CustomFieldIdentifier, FieldType } from './types';
import { isNotNil } from '@verticeone/utils/validation';
import { useMemo } from 'react';

export type UseFieldTypesParams = {
  excluded?: CustomFieldIdentifier[];
};

export type UseFieldTypesReturn = {
  allFieldTypes: CustomFieldIdentifier[];
  availableFieldTypes: CustomFieldIdentifier[];
};

const allFieldTypes = [
  { id: 'checkbox01', label: 'Checkbox 1', type: FieldType.CHECKBOX },
  { id: 'checkbox02', label: 'Checkbox 2', type: FieldType.CHECKBOX },
  { id: 'checkbox03', label: 'Checkbox 3', type: FieldType.CHECKBOX },
  { id: 'checkbox04', label: 'Checkbox 4', type: FieldType.CHECKBOX },
  { id: 'checkbox05', label: 'Checkbox 5', type: FieldType.CHECKBOX },

  { id: 'text01', label: 'Text Field 1', type: FieldType.TEXT },
  { id: 'text02', label: 'Text Field 2', type: FieldType.TEXT },
  { id: 'text03', label: 'Text Field 3', type: FieldType.TEXT },
  { id: 'text04', label: 'Text Field 4', type: FieldType.TEXT },
  { id: 'text05', label: 'Text Field 5', type: FieldType.TEXT },

  { id: 'decimal01', label: 'Decimal Number 1', type: FieldType.DECIMAL },
  { id: 'decimal02', label: 'Decimal Number 2', type: FieldType.DECIMAL },
  { id: 'decimal03', label: 'Decimal Number 3', type: FieldType.DECIMAL },
  { id: 'decimal04', label: 'Decimal Number 4', type: FieldType.DECIMAL },
  { id: 'decimal05', label: 'Decimal Number 5', type: FieldType.DECIMAL },

  { id: 'date01', label: 'Date 1', type: FieldType.DATE },
  { id: 'date02', label: 'Date 2', type: FieldType.DATE },
  { id: 'date03', label: 'Date 3', type: FieldType.DATE },
  { id: 'date04', label: 'Date 4', type: FieldType.DATE },
  { id: 'date05', label: 'Date 5', type: FieldType.DATE },

  { id: 'dropdown01', label: 'Dropdown 1', type: FieldType.DROPDOWN },
  { id: 'dropdown02', label: 'Dropdown 2', type: FieldType.DROPDOWN },
  { id: 'dropdown03', label: 'Dropdown 3', type: FieldType.DROPDOWN },
  { id: 'dropdown04', label: 'Dropdown 4', type: FieldType.DROPDOWN },
  { id: 'dropdown05', label: 'Dropdown 5', type: FieldType.DROPDOWN },

  { id: 'textarea01', label: 'Text Area 1', type: FieldType.TEXTAREA },
  { id: 'textarea02', label: 'Text Area 2', type: FieldType.TEXTAREA },
];

const useFieldTypes = ({ excluded }: UseFieldTypesParams): UseFieldTypesReturn => {
  const usedFieldTypes = useMemo(() => excluded?.map((fieldItem) => fieldItem?.id).filter(isNotNil), [excluded]);
  const availableFieldTypes = useMemo(
    () =>
      usedFieldTypes ? allFieldTypes.filter((fieldType) => !usedFieldTypes.includes(fieldType.id)) : allFieldTypes,
    [usedFieldTypes]
  );

  return {
    allFieldTypes,
    availableFieldTypes,
  };
};

export default useFieldTypes;
