import React from 'react';
import { Divider as MuiDivider, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system/src';

type DividerProps = {
  children?: string;
};

export const Divider = ({ children }: DividerProps) => {
  const { palette } = useTheme();
  return (
    <MuiDivider>
      {children !== undefined && (
        <Text variant="caption" size="XXS" style={{ textTransform: 'uppercase', color: palette.text.color3 }}>
          {children}
        </Text>
      )}
    </MuiDivider>
  );
};
