import { CardGridSortModel, ValidRowModel } from './types';

export const sortRows = (rows: ValidRowModel[], sortModel: CardGridSortModel) => {
  return rows.slice().sort((a, b) => {
    for (const sortItem of sortModel) {
      const field = sortItem.field;
      const direction = sortItem.sort;

      // for undefined values, we want to sort them to the beginning
      if (a[field] === undefined) {
        return direction === 'asc' ? -1 : 1;
      }

      const aValue = a[field] ?? '';
      const bValue = b[field] ?? '';

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const result = aValue.localeCompare(bValue);
        if (result !== 0) {
          return direction === 'asc' ? result : -result;
        }
      }

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });
};
