import { Children, ReactNode } from 'react';
import { Stack, styled } from '@mui/material';

import { Text } from '../../../Text';
import {
  BaseFilterDropdown,
  BaseDropdownList,
  BaseExpandIcon,
  BaseDropdownHeader,
  DropdownSelectedCount,
} from '../BaseFilterDropdown';

type FilterDropdownProps = {
  title: string;
  id: string;
  children: ReactNode;
  showAll?: boolean;
  defaultShowItemCount?: number;
};

const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} 0`,
  alignItems: 'center',
  alignSelf: 'stretch',
  cursor: 'pointer',
}));

export const Category = ({ title, children, id, showAll, defaultShowItemCount }: FilterDropdownProps) => {
  const totalChildrenCount = Children.count(children);

  return (
    <BaseFilterDropdown id={id} label={title}>
      <BaseDropdownHeader>
        <StyledHeaderWrapper>
          <Stack flex={1} direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Text variant="heading" size="XS" color="text1">
                {title}
              </Text>
              <Text variant="body-regular" color="text1" size="M" sx={{ opacity: 0.4 }} pl={1}>
                ({totalChildrenCount})
              </Text>
              <BaseExpandIcon />
            </Stack>
            <DropdownSelectedCount />
          </Stack>
        </StyledHeaderWrapper>
      </BaseDropdownHeader>
      <BaseDropdownList showAll={showAll} defaultShowItemCount={defaultShowItemCount}>
        {children}
      </BaseDropdownList>
    </BaseFilterDropdown>
  );
};
