import { useCallback, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import { Box, Palette, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { cssObjectToString } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { yLabelStyles } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { useTranslation } from 'react-i18next';
import { License } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';

type ActiveUsersGraphProps = {
  logins: number[];
  authorizations: number[];
  labels: string[];
  license?: License;
  lastIncompleteBin: number;
};

export const yLabelFormatter =
  (palette: Palette, locale: string) =>
  ({ value }: { value: number | string }) => {
    return `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`;
  };

const ActiveUsersGraph = ({ logins, authorizations, labels, license, lastIncompleteBin }: ActiveUsersGraphProps) => {
  const { locale } = useLocaleContext();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const applyXAxisOffset = useXAxisOffset();

  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();

  const optionOverrides = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        applyStackedColumnHover,
        (opts) =>
          mergeOptions(
            {
              chart: { type: 'column' },
              yAxis: {
                softMax: 10,
                labels: {
                  formatter: yLabelFormatter(palette, locale),
                },
                plotLines:
                  license && license.type === 'SEAT_BASED'
                    ? [
                        {
                          value: license.quantity,
                          color: palette.core.color6,
                          width: 2,
                          dashStyle: 'ShortDash',
                          zIndex: 10,
                        },
                      ]
                    : [],
              },
              xAxis: {
                categories: labels,
                plotLines:
                  lastIncompleteBin >= 0
                    ? [
                        {
                          value: lastIncompleteBin,
                          color: palette.core.color5,
                          width: 2,
                          zIndex: 3,
                        },
                      ]
                    : [],
              },
              plotOptions: {
                column: {
                  stacking: 'normal',
                  borderRadius: 3,
                },
                series: {
                  marker: {
                    enabled: false,
                  },
                },
              },
              series: [
                {
                  id: 'logins',
                  data: logins.map((value) => ({
                    y: value,
                    color: palette.primary.color3,
                    states: {
                      hover: { color: palette.primary.hover.color2 },
                    },
                  })),
                  type: 'column',
                  index: 1,
                },
                {
                  id: 'authorizations',
                  data: authorizations.map((value) => ({
                    y: value,
                    color: palette.secondary.color3,
                    states: {
                      hover: { color: palette.secondary.hover.color2 },
                    },
                  })),
                  type: 'column',
                  index: 0,
                },
              ],
            },
            opts
          ),
      ]),
    [
      applyStyledHighcharts,
      applyStackedColumnHover,
      applyXAxisOffset,
      logins,
      authorizations,
      labels,
      license,
      lastIncompleteBin,
      locale,
      palette,
    ]
  );

  const legendItems = useMemo(() => {
    const legend = [];

    if (logins.some((item) => Boolean(item))) {
      legend.push({
        id: '1',
        label: t('PREFERENCES.INTEGRATIONS.USAGE.LOGINS'),
        color: palette.primary.color3,
      });
    }

    if (authorizations.some((item) => Boolean(item))) {
      legend.push({
        id: '2',
        label: t('PREFERENCES.INTEGRATIONS.USAGE.AUTHORIZATIONS'),
        color: palette.secondary.color3,
      });
    }

    if (license && license.type === 'SEAT_BASED') {
      legend.push({
        id: '3',
        label: t('PREFERENCES.INTEGRATIONS.USAGE.LICENSES'),
        outlined: true,
        color: palette.core.color6,
      });
    }

    if (lastIncompleteBin >= 0) {
      legend.push({
        id: '4',
        label: t('PREFERENCES.INTEGRATIONS.USAGE.DATA_COLLECTION_START'),
        outlined: false,
        color: palette.core.color5,
      });
    }

    return legend;
  }, [license, logins, authorizations, lastIncompleteBin, palette, t]);

  return (
    <Stack>
      <Box alignSelf="flex-end" px={6}>
        <Legend items={legendItems} />
      </Box>
      <HighchartsReact highcharts={Highcharts} options={optionOverrides} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          return (
            <TooltipWrapper>{t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.USER', { count: context.y || 0 })}</TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default ActiveUsersGraph;
