import {
  COST_MODEL_CLASS as AnnualCostApproximationClass,
  transformationsForAnnualCostApproximation,
} from './AnnualCostApproximation';
import {
  COST_MODEL_CLASS as AdvancedLinearApproximationClass,
  transformationsForAdvancedLinearApproximation,
} from './AdvancedLinearApproximation/AdvancedLinearApproximation';

export const CostModelTransformationsMap = {
  [AnnualCostApproximationClass]: transformationsForAnnualCostApproximation,
  [AdvancedLinearApproximationClass]: transformationsForAdvancedLinearApproximation,
};
