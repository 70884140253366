/**
 * TypeScript helper that allows you to check at compile-time that all values of enum were covered in a switch.
 * On runtime, it does nothing.
 *
 * @example
 * type TailLength = 'short' | 'medium' | 'long';
 * // ... in code
 * switch (tailLength) {
 *   case 'short': return 1;
 *   case 'medium': return 2;
 *   default:
 *     // Will scream compile-time that 'long' is not covered. GOOD!
 *     assertExhausted(tailLength);
 *     // Runtime handling... throw error or ignore, whatever you need.
 *     throw new Error('invalid tail length')
 * }
 */
export const assertExhausted = (value: never) => {};
