import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { FormControl, Typography } from '@mui/material';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import {
  ConfirmPasswordInput,
  FeedbackLabel,
  Form,
  FormLabel,
  SubmitButton,
  PasswordInput,
} from '@vertice/components/src/Form';
import authStyles from '../../components/Auth.module.scss';
import AuthContainer from '../../components/AuthContainer/AuthContainer';
import PasswordCriteria from '../../components/PasswordCriteria/PasswordCriteria';
import RedirectButton from 'modules/auth/components/RedirectButton';

const ForceNewPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user: { email, oldPassword },
  } = location.state || { user: {} };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);

  const defaultFormValues = {
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (form: typeof defaultFormValues) => {
    setIsSubmitting(true);
    if (form.password === form.confirmPassword) {
      try {
        const user = await Auth.signIn(email, oldPassword);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, form.password);
        }
      } catch (e) {
        if (e && typeof e === 'object' && !!Object.prototype.hasOwnProperty.call(e, 'message')) {
          const errorObject = e as { message: string };
          if (errorObject?.message) {
            setError(errorObject.message);
          }
        }
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!email || !oldPassword) {
      navigate('/');
    }
  }, [email, oldPassword, navigate]);

  const handlePasswordConfirmValidationResult = (value: boolean) => {
    if (value) {
      setError('');
      setPasswordMismatchError(false);
    } else {
      setError(t('AUTH.ERRORS.PASSWORD_MUST_MATCH'));
      setPasswordMismatchError(true);
    }
  };

  return (
    <AuthContainer>
      <Typography variant="heading-s">{t('FORCE_NEW_PASSWORD.HEADER')}</Typography>
      <Form defaultValues={defaultFormValues} onSubmit={handleSubmit} className={authStyles.form}>
        <FormControl fullWidth variant="outlined">
          <FormLabel for="password" error={passwordMismatchError}>
            {t('SUBMIT_RESET_PASSWORD.NEW_PASSWORD')}
          </FormLabel>
          <PasswordInput<typeof defaultFormValues>
            id="password"
            required
            error={passwordMismatchError}
            placeholder={t('SUBMIT_RESET_PASSWORD.NEW_PASSWORD')}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <FormLabel for="confirmPassword" error={passwordMismatchError}>
            {t('SUBMIT_RESET_PASSWORD.CONFIRM_NEW_PASSWORD')}
          </FormLabel>
          <ConfirmPasswordInput<typeof defaultFormValues>
            id="confirmPassword"
            watchFor="password"
            error={passwordMismatchError}
            onValidationResult={handlePasswordConfirmValidationResult}
            placeholder={t('SUBMIT_RESET_PASSWORD.CONFIRM_NEW_PASSWORD')}
          />
        </FormControl>
        <FeedbackLabel error={error} />
        <PasswordCriteria id="password" />
        <div className={authStyles.actions}>
          <SubmitButton isLoading={isSubmitting}>{t('SUBMIT_RESET_PASSWORD.SUBMIT')}</SubmitButton>
          <RedirectButton to={ROUTES.LOGIN}>{t('SUBMIT_RESET_PASSWORD.GO_TO_LOGIN')}</RedirectButton>
        </div>
      </Form>
    </AuthContainer>
  );
};

export default ForceNewPassword;
