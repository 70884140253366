import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const applicationsRoutes = createPortableRoutes({
  APPLICATIONS: {
    path: '/applications',
    children: {
      USAGE_DETAIL: {
        path: ':applicationId/usage-detail',
      },
    },
  },
} as const);
