import {
  ActivityHookConfiguration,
  TaskAssignmentConfiguration,
  TaskConfiguration,
  TaskDefinition,
  TaskIOMappingConfiguration,
} from '../definitionsTypes';

export const isAssignmentConfiguration = (
  configuration:
    | TaskConfiguration
    | ActivityHookConfiguration
    | TaskIOMappingConfiguration
    | TaskAssignmentConfiguration
): configuration is TaskAssignmentConfiguration => {
  return configuration.kind === 'Tasks:Assignment';
};

export const getTaskAssignmentConfigurations = (task: TaskDefinition): TaskAssignmentConfiguration[] => {
  return task.task.configurations?.filter(isAssignmentConfiguration) ?? [];
};

export const updateTaskDefinition = (
  taskDefinition: TaskDefinition,
  {
    name,
    description,
    assignmentConfigurations,
  }: { name: string; description?: string; assignmentConfigurations: TaskAssignmentConfiguration[] }
): TaskDefinition => {
  const newConfigurations = [
    ...(taskDefinition.task.configurations ?? []).filter((v) => !isAssignmentConfiguration(v)),
    ...assignmentConfigurations,
  ];

  return {
    ...taskDefinition,
    task: {
      ...taskDefinition.task,
      name,
      description,
      configurations: newConfigurations,
    },
  };
};
