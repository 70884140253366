import { scimAPI as api } from '../../api/scimAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExistingScimTokenStatus: build.query<GetExistingScimTokenStatusApiResponse, GetExistingScimTokenStatusApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/sts/tokens/scim/access` }),
    }),
    issueAccessTokenForScim: build.mutation<IssueAccessTokenForScimApiResponse, IssueAccessTokenForScimApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/sts/tokens/scim/access`, method: 'POST' }),
    }),
    listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/scim/Users` }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/scim/Users`, method: 'POST', body: queryArg.body }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/scim/Users/${queryArg.userId}` }),
    }),
    putUser: build.mutation<PutUserApiResponse, PutUserApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/scim/Users/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    patchUser: build.mutation<PatchUserApiResponse, PatchUserApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/scim/Users/${queryArg.userId}`, method: 'PATCH' }),
    }),
    listGroups: build.query<ListGroupsApiResponse, ListGroupsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/scim/Groups` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as scimAPICodegen };
export type GetExistingScimTokenStatusApiResponse = /** status 200 GetExistingSCIMTokenStatus response */ {
  /** Token status */
  status: 'ACTIVE' | 'INACTIVE';
};
export type GetExistingScimTokenStatusApiArg = {
  accountId: string;
};
export type IssueAccessTokenForScimApiResponse = /** status 200 IssueAccessTokenForSCIM response */ {
  /** Access token */
  accessToken: string;
};
export type IssueAccessTokenForScimApiArg = {
  accountId: string;
};
export type ListUsersApiResponse = /** status 200 SCIM response */ object;
export type ListUsersApiArg = {
  accountId: string;
};
export type CreateUserApiResponse = /** status 200 SCIM response */ object;
export type CreateUserApiArg = {
  accountId: string;
  /** SCIM request */
  body: object;
};
export type GetUserApiResponse = /** status 200 SCIM response */ object;
export type GetUserApiArg = {
  accountId: string;
  userId: string;
};
export type PutUserApiResponse = /** status 200 SCIM response */ object;
export type PutUserApiArg = {
  accountId: string;
  userId: string;
  /** SCIM request */
  body: object;
};
export type PatchUserApiResponse = /** status 200 SCIM response */ object;
export type PatchUserApiArg = {
  accountId: string;
  userId: string;
};
export type ListGroupsApiResponse = /** status 200 SCIM response */ object;
export type ListGroupsApiArg = {
  accountId: string;
};
export const {
  useGetExistingScimTokenStatusQuery,
  useLazyGetExistingScimTokenStatusQuery,
  useIssueAccessTokenForScimMutation,
  useListUsersQuery,
  useLazyListUsersQuery,
  useCreateUserMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  usePutUserMutation,
  usePatchUserMutation,
  useListGroupsQuery,
  useLazyListGroupsQuery,
} = injectedRtkApi;
