import { useState } from 'react';
import AWSStartStep from './Steps/AWSStartStep';
import AWSPermission from './Steps/AWSPermission';
import AWSCostAndUsageDetails from './Steps/AWSCostAndUsageDetails';
import AWSVerified from './Steps/AWSConnectionVerified';
import AWSConnectionError from './Steps/AWSConnectionError';
import Wizard, { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';
import { useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { AWS_INTEGRATION_ID } from '../AWSSetupCard/AWSSetupCard';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import useCloudFeature from 'modules/cloud/hooks/useCloudFeature';
import AWSSynthetic from './Steps/AWSSynthetic';

export const AWS_STEPS = {
  START: 'start',
  PERMISSION: 'permission',
  COST_AND_USAGE: 'cost_and_usage',
  CONNECTION_VERIFIED: 'connection_verified',
  CONNECTION_ERROR: 'connection_error',
  SYNTHETIC: 'synthetic',
  SYNTHETIC_VERIFIED: 'synthetic_verified',
  SYNTHETIC_ERROR: 'synthetic_error',
};

export interface AWSStepProps extends WizardStepProps {
  setStep: (step: string) => void;
}

export const AWSSetupWizard = () => {
  const { accountId } = useAccountContext();
  const cloudFeature = useCloudFeature();
  const [step, setStep] = useState(
    !cloudFeature.isLoading &&
      cloudFeature?.data.isMainFeatureEnabled &&
      cloudFeature?.data?.subFeatures.activateIntegration
      ? AWS_STEPS.SYNTHETIC
      : AWS_STEPS.START
  );
  const [reportName, setReportName] = useState('');
  const [s3BucketName, sets3BucketName] = useState('');
  const [roleArn, setRoleArn] = useState('');

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const handleCancellation = async () => {
    if (!accountId || step === AWS_STEPS.START || step === AWS_STEPS.SYNTHETIC) return;
    await revokeAccountIntegration({ accountId, integrationId: AWS_INTEGRATION_ID });
  };

  return (
    <Wizard activeStep={step} onCancel={handleCancellation}>
      <AWSStartStep stepValue={AWS_STEPS.START} setStep={setStep} />
      <AWSPermission stepValue={AWS_STEPS.PERMISSION} setStep={setStep} />
      <AWSCostAndUsageDetails
        stepValue={AWS_STEPS.COST_AND_USAGE}
        setStep={setStep}
        reportName={reportName}
        s3BucketName={s3BucketName}
        roleArn={roleArn}
        setReportName={setReportName}
        sets3BucketName={sets3BucketName}
        setRoleArn={setRoleArn}
      />
      <AWSVerified stepValue={AWS_STEPS.CONNECTION_VERIFIED} setStep={setStep} />
      <AWSConnectionError stepValue={AWS_STEPS.CONNECTION_ERROR} setStep={setStep} />
      <AWSSynthetic stepValue={AWS_STEPS.SYNTHETIC} setStep={setStep} />
      <AWSVerified stepValue={AWS_STEPS.SYNTHETIC_VERIFIED} backValue={AWS_STEPS.SYNTHETIC} setStep={setStep} />
      <AWSConnectionError stepValue={AWS_STEPS.SYNTHETIC_ERROR} backValue={AWS_STEPS.SYNTHETIC} setStep={setStep} />
    </Wizard>
  );
};
