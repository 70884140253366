import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useCloudAnalytics } from '../../../../CloudAnalyticsContext';
import { getTableHeight } from '../../../BaseCloudTab/Table/utils';
import BaseCloudAnalyticsTable from '../../../BaseCloudTab/Table/BaseCloudAnalyticsTable';
import useTagsServicesData, { TableRow } from '../useTagsServicesData';
import ComparisonDrawerProvider from '../../../../components/ComparisonDrawer/ComparisonDrawerContext';
import useDrawerTagsServicesData from '../../../../components/ComparisonDrawer/useDrawerTagsServicesData';

const CloudTagsServicesTable = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const { getLastNode, addChildNode } = useCloudAnalytics();
  const { search, loadingHeight } = getLastNode();
  const { data, ...loadingStatus } = useTagsServicesData();
  const filteredData = data?.tableData?.filter((row) =>
    row.productName?.toLowerCase().includes(search?.toLowerCase() || '')
  );

  const getRowId = (row: GridRowModel<TableRow>) => row.productCode;

  const calculatedHeight = getTableHeight(filteredData);

  const handleRowClick = ({ row }: GridRowModel) => {
    if (!loadingStatus.isLoading) {
      addChildNode({
        id: row.productCode,
        label: row.productName,
        type: 'service',
        loadingHeight: calculatedHeight,
      });
    }
  };

  return (
    <ComparisonDrawerProvider
      instanceSettings={{
        query: useDrawerTagsServicesData,
        mappingFunction: (acc, { product_code, product_name }) => ({
          ...acc,
          [product_code]: product_name || product_code,
        }),
        comparisonColumn: 'product_code',
      }}
    >
      <BaseCloudAnalyticsTable<TableRow>
        height={loadingStatus.isLoading && loadingHeight ? loadingHeight : calculatedHeight}
        data={filteredData || []}
        firstColumn={{
          field: 'productName',
          headerName: t('COLUMN.PRODUCT_NAME'),
        }}
        getRowId={getRowId}
        onRowClick={handleRowClick}
        isLoading={loadingStatus.isLoading}
        disableRowSelectionOnClick={loadingStatus.isLoading}
      />
    </ComparisonDrawerProvider>
  );
};

export default CloudTagsServicesTable;
