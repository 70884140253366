import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { styled } from '@mui/material';

import { useFilterItem } from '../FilterProvider/utils';
import { Text } from '../../../Text';
import { Checkbox } from '../../../Checkbox';
import BaseItem from '../BaseItem';
import { useFilter } from '../FilterProvider';

type FilterItemProps = {
  id: string;
  label: string;
  defaultIsSelected?: boolean;
};

const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: theme.spacing(2),
  alignItems: 'center',
  cursor: 'pointer',
}));

export const FilterCheckbox = ({ id, label, defaultIsSelected = false }: FilterItemProps) => {
  const { color } = useFilter();
  const filterItem = useFilterItem<boolean, { isSelected: boolean }>(
    { id, label, isSelected: defaultIsSelected },
    {
      onInit: (initValue: string | boolean | null) => ({
        isSelected: initValue?.toString() ? initValue.toString() === 'true' : defaultIsSelected,
      }),
      onClear: () => ({ isSelected: false }),
      getIsDirty: (a, b) => a.isSelected !== b.isSelected,
      getSelectedCount: (item) => (item.isSelected ? 1 : 0),
      onSerialize: (item) => ({
        value: item.isSelected,
        metadata: {
          id: item.id,
          isSelected: item.isSelected,
          type: 'boolean',
          label,
        },
      }),
    }
  );

  const item = useAtomValue(filterItem.instance);
  const toggle = useCallback(() => filterItem.update((prev) => ({ isSelected: !prev.isSelected })), [filterItem]);

  return (
    <BaseItem item>
      <StyledLabel>
        <Checkbox checked={item.isSelected} color={color} onClick={toggle} />
        <Text variant="body-regular" size="M" color="text1">
          {label}
        </Text>
      </StyledLabel>
    </BaseItem>
  );
};
