import styled from '@mui/material/styles/styled';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import { gradientBorderColor } from '../../utils/css/gradientBorder';
import { getTextVariantStyle } from '../Text/utils';
import type { ChipButtonProps } from './ChipButton';
import { getButtonVariantProperties, getPadding } from '../Button/variantProperties';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { CSSProperties } from '@mui/styled-engine';
import { DesignSystemSize } from '../../types';

const GAP_BY_SIZE: {
  [key in DesignSystemSize]: number;
} = {
  XL: 6,
  L: 6,
  M: 4,
  S: 4,
  XS: 2,
  XXS: 2,
} as const;

export type UseStylesProps = Required<Pick<ChipButtonProps, 'size' | 'color' | 'variant' | 'disabled'>> & {
  isActive: boolean;
};

export const useStyles = ({ size, variant, color, isActive, disabled }: UseStylesProps) => {
  const { palette } = useTheme();

  const borderWidth = '1px';
  const minPadding = 2.5;
  const paddingGrowth = 0.5;

  const variantProperties = getButtonVariantProperties({
    color,
    size,
    isCaption: false,
    minPadding,
    paddingGrowth,
    borderRadius: '24px',
    borderWidth: '1px',
    palette,
  });

  const iconSize = baseSizes[size];

  return {
    gap: `${GAP_BY_SIZE[size]}px`,
    ...getTextVariantStyle({ variant: 'button-bold', size }),
    ...variantProperties[variant]['primary'],
    ...(isActive
      ? {
          '&:hover': variantProperties[variant].hover,
          '&:focus-visible, &:active': variantProperties[variant].focus,
        }
      : { display: 'inline-flex' }),

    ...(disabled && {
      // Increase specificity to win over the .Mui-disabled selector.
      // We don't use the .Mui-disabled directly because the class is not there when isActive === false and
      // MUI Box component is used as a base instead of MUI Button.
      '&&': {
        color: palette.inactive.color2,
        backgroundColor: palette.inactive.color4,
        padding: getPadding(size, false, borderWidth, minPadding, paddingGrowth),
        border: `${borderWidth} solid ${palette.inactive.color4}`,
        ...gradientBorderColor(palette.inactive.color4),
      },
    }),
    [`.${svgIconClasses.root}`]: {
      width: iconSize,
      height: iconSize,
    },
  };
};

export const BaseChipButton = styled(MuiButton)<MuiButtonProps & { $styles: CSSProperties }>(({ $styles }) => ({
  ...$styles,
  minWidth: '10px',
  height: 'fit-content',
  lineHeight: '100%',
  fontWeight: '600',
  borderRadius: '24px',
  position: 'relative',
}));
