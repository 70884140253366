import React from 'react';
import { Stack } from '@mui/material';
import type { DesignSystemSize } from '../../types';
import { ButtonGroupContext } from '../ButtonGroup';
import { DialogWrapper } from './DialogWrapper';
import { useDialog } from './DialogContext';
import { Align } from './types';
import { reducedSizes } from '../../guidelines/Sizing/sizings';
import { testProps } from '../../utils/testProperties';

type DialogActionsProps = {
  children: React.ReactNode;
  size?: DesignSystemSize;
  align?: Align;
};

const alignToJustifyContentMap: Record<Align, 'flex-end' | 'center'> = {
  left: 'flex-end', // this sounds wired, but it's correct buy design
  center: 'center',
};

export const DialogActions = ({ children, size, align }: DialogActionsProps) => {
  const dialog = useDialog();
  const dialogSize = size ?? dialog.size;
  const dialogAlign = align ?? dialog.align;
  const gap = reducedSizes[dialogSize] ?? reducedSizes['M'];

  return (
    <DialogWrapper $size={dialogSize} {...testProps(undefined, 'dialogActions')}>
      <ButtonGroupContext.Provider value={{ size: dialogSize, color: 'primary' }}>
        <Stack
          direction="row"
          gap={gap}
          alignItems="center"
          justifyContent={alignToJustifyContentMap[dialogAlign as Align]}
        >
          {children}
        </Stack>
      </ButtonGroupContext.Provider>
    </DialogWrapper>
  );
};
