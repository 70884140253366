import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { ContractStageChip } from '@vertice/core/src/modules/saas/contract/components/ContractStageChip';
import {
  GenericDateIcon,
  GenericMoneyIcon,
} from '@vertice/core/src/modules/intelligentWorkflows/components/icons/Icons';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFormatDate, useFormatCurrency } from '@verticeone/utils/formatting';
import { isRollingContract } from '@vertice/core/src/modules/saas/contract/computed';
import { CardLine, CardTooltip, CardValue } from './CardContentElements';
import { PageCard } from './PageCard';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

type ContractPageCardProps = { contract: Contract };

export const ContractPageCard: FC<ContractPageCardProps> = ({ contract }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useFormatCurrency();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  const isRolling = isRollingContract(contract);
  const { parts, materialized, record } = contract;
  const startDate = parts.contractual?.lifecycle?.startDate;
  const endDate = parts.contractual?.lifecycle?.endDate;
  const annualCost = parts.overview?.annualCostWithVertice || parts.overview?.annualCostWithoutVertice;
  const currency = parts.contractual?.financial?.baseCurrency ?? DEFAULT_CURRENCY;
  const rollingFrequency = parts.contractual?.lifecycle?.rollFrequency;
  const rollingFrequencyLabel = isRolling ? t(`CONTRACTS.ROLLING_CONTRACT_${rollingFrequency}`) : '';

  return (
    <PageCard
      path={generatePathForRoute(routes.CONTRACTS.DETAIL, {
        contractId: record.contractId,
      })}
    >
      <CardLine>
        <Text variant="body-bold" size="M">
          {t('ENTITIES.CONTRACT.NAME')}
        </Text>
        <ContractStageChip stage={materialized?.simplifiedStage} size="XS" />
      </CardLine>
      <CardLine>
        <GenericDateIcon size="S" />
        {isRolling ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <CardTooltip translationKey="ENTITIES.CONTRACT.LABELS.START_DATE">
              <CardValue>{startDate ? formatDate(startDate) : '-'}</CardValue>
            </CardTooltip>
            <CardValue color="text4">•</CardValue>
            <CardTooltip translationKey="ENTITIES.CONTRACT.LABELS.RECURRENCE">
              <CardValue color="text3">
                {t('CONTRACTS.ROLLING_CONTRACT_DEADLINE', { frequency: rollingFrequencyLabel })}
              </CardValue>
            </CardTooltip>
          </Stack>
        ) : startDate ? (
          <CardTooltip translationKey="SAAS.LINKED_PAGES_CARD.DATES_TOOLTIP_FIXED_TERM">
            <CardLine>
              <CardValue>
                {formatDate(startDate)} - {endDate && formatDate(endDate)}
              </CardValue>
            </CardLine>
          </CardTooltip>
        ) : (
          '-'
        )}
      </CardLine>

      <CardTooltip translationKey="ENTITIES.CONTRACT.LABELS.TOTAL_ANNUAL_COST">
        <CardLine>
          <GenericMoneyIcon size="S" />
          <CardValue>{annualCost ? formatCurrency(annualCost, { currency }) : '-'}</CardValue>
        </CardLine>
      </CardTooltip>
    </PageCard>
  );
};
