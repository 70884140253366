import React from 'react';
import { SnackbarProvider as BaseSnackbarProvider, SnackbarProviderProps } from 'notistack';
import Notification from './Notification';

const SnackbarProvider = ({ children, ...otherProps }: SnackbarProviderProps) => (
  <BaseSnackbarProvider
    Components={{
      success: Notification,
      info: Notification,
      warning: Notification,
      error: Notification,
    }}
    maxSnack={10}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    {...otherProps}
  >
    {children}
  </BaseSnackbarProvider>
);

export default SnackbarProvider;
