import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Tooltip } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { useFormatDate } from '@verticeone/design-system/src';

import { RequestRow } from '../../types';
import { STATUS_FILTER_MAP } from '../filter/types';
import TwoRowCell from './TwoRowCell';
import { ComponentProps } from 'react';

type KeyDate = {
  label: string;
  value: string;
};

export const getKeyDateLabel = (label: string) => {
  switch (label) {
    case 'targetSignDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.TARGET_SIGN_DATE';
    case 'autoRenewalDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.AUTO_RENEWAL';
    case 'renewalDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.RENEWAL';
    default:
      return '-';
  }
};

const sortKeyDates = (keyDates: RequestRow['keyDates']) =>
  Object.entries(keyDates ?? {})
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());

export const getKeyDates = ({ status, closedAt, lastActivityAt, keyDates }: KeyDateCellProps['row']): KeyDate[] => {
  const isCompleted = STATUS_FILTER_MAP['completed'].includes(status);
  const isCancelled = STATUS_FILTER_MAP['cancelled'].includes(status);

  if (isCompleted || isCancelled)
    return [
      {
        label: isCompleted
          ? 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.COMPLETED'
          : 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.CANCELLED',
        value: closedAt ?? lastActivityAt ?? '-',
      },
    ];

  const sortedKeyDates = sortKeyDates(keyDates);

  if (!keyDates || sortedKeyDates.length === 0) return [];

  return sortedKeyDates.map(({ name, value }) => ({
    label: getKeyDateLabel(name),
    value,
  }));
};

type KeyDateCellProps = {
  row: Pick<RequestRow, 'status' | 'closedAt' | 'lastActivityAt' | 'keyDates'>;
};

export const KeyDateCell = ({ row }: KeyDateCellProps) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();
  const keyDates = getKeyDates(row);

  const isEmpty = keyDates.length === 0;
  const { value: mainKeyDateValue, label: mainKeyDateLabel } = keyDates[0] ?? { value: '-', label: '-' };
  const formattedDate = !isEmpty ? formatDate(mainKeyDateValue) : '-';

  const cell = <TwoRowCell subtitle={!isEmpty ? t(mainKeyDateLabel) : undefined} title={formattedDate!} />;

  return (
    <Tooltip
      title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.KEY_DATES')}
      size="S"
      placement="bottom"
      content={<KeyDateTooltipContent keyDates={keyDates} />}
      disableHoverListener={keyDates.length <= 1}
    >
      <Stack width="100%">{cell}</Stack>
    </Tooltip>
  );
};

type KeyDateTooltipContentProps = {
  keyDates: KeyDate[];
};

const KeyDateTooltipContent = ({ keyDates }: KeyDateTooltipContentProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const rowTextProps: ComponentProps<typeof Text> = {
    variant: 'body-regular',
    color: 'text4',
    size: 'S',
  };

  if (!keyDates || keyDates.length === 0) return null;

  return (
    <Stack direction="column">
      {keyDates.map(({ label, value }) => (
        <Stack direction="row" key={[label, value].join()}>
          <Text {...rowTextProps} width="150px">
            {t(label)}
          </Text>
          <Text {...rowTextProps}>{formatDate(value)}</Text>
        </Stack>
      ))}
    </Stack>
  );
};
