import React from 'react';
import { Stack, GridProps, Box } from '@mui/material';

export type BannerContentProps = {
  icon?: React.ReactNode;
  title: React.ReactNode;
  badge?: React.ReactNode;
} & Omit<GridProps, 'title'>;

export const BannerContent = ({ icon, title, badge }: BannerContentProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="stretch" gap={1} flexGrow={1}>
      <Stack direction="row" gap={1.5} alignItems="center">
        {!!icon && icon}
        {title}
      </Stack>
      {!!badge && <Box flexShrink={0}>{badge}</Box>}
    </Stack>
  );
};
