import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextFieldCaption } from '@verticeone/design-system/src';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import { getAnnualFutureSavings, getFutureSavings } from '@vertice/core/src/modules/saas/savings/computed';
import { PropsWithContract } from '../types';

export const BreakdownFutureSavings: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();

  const annualFutureSavings = getAnnualFutureSavings(contract);
  const futureSavings = getFutureSavings(contract);

  return (
    <Stack gap={1} flex={1}>
      <TextFieldCaption
        htmlFor={id}
        label={t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS')}
        size="XS"
        tooltip={{
          title: t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS'),
          content: t('ENTITIES.CONTRACT.TOOLTIPS.FUTURE_SAVINGS'),
        }}
      />
      <LargeValue
        id={id}
        size="S"
        primaryValue={formatContractCurrency(annualFutureSavings)}
        primaryTestId="annualFutureSavingsValue"
        secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(futureSavings) })}
        secondaryTestId="futureSavingsValue"
      />
    </Stack>
  );
};
