import React from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import { Stack, styled } from '@mui/material';

const StyledSvg = styled('svg')(({ theme }) => ({
  width: '20px',
  height: '20px',
  viewBox: '0 0 20 20',
  preserveAspectRatio: 'none',

  circle: {
    cx: 10,
    cy: 10,
    r: 10,
  },
}));

const EmptyCircle = styled('circle')(({ theme }) => ({
  fill: theme.palette.secondary.color3,
}));

const FilledCircle = styled('circle')(({ theme }) => ({
  fill: theme.palette.primary.color2,
}));

interface StatusBarProps {
  numberOfElements?: number;
  maxValue?: number;
  value: number;
  step?: 'FULL' | 'HALF';
}

const DotsProgressBar: React.FC<StatusBarProps> = ({ numberOfElements = 5, value, maxValue = 5, step = 'HALF' }) => {
  const clipId = `dots-progress-bar-cut-off-${randomId()}`;

  if (numberOfElements < 2) {
    return null;
  }

  const fullCircle = <FilledCircle key="full" />;
  const emptyCircle = <EmptyCircle key="empty" />;
  const cutOff = (
    <clipPath key="cut-off" id={clipId}>
      <rect x="0" y="0" width="10" height="20" />
    </clipPath>
  );
  const clippedCircle = <FilledCircle key="half" clipPath={`url(#${clipId})`} />;

  const scaledValue = (value / maxValue) * numberOfElements;

  const getSpitCircles = (val: number, interval: number) => {
    // The value is already a whole number - number of dots in fullCircles array is correct
    if (Number.isInteger(val)) {
      return [];
    }
    // The value is rounded up to the whole number - we need to add one full dot
    if (val % 1 > interval) {
      return [fullCircle];
    }
    // The value is rounded up to 0.5 - we need to add a split dot
    return [[emptyCircle, cutOff, clippedCircle]];
  };

  const fullCircles = new Array(Math.floor(scaledValue)).fill(fullCircle);
  const splitCircles = getSpitCircles(scaledValue, step === 'HALF' ? 0.5 : 0);
  const emptyCircles = new Array(numberOfElements - Math.ceil(scaledValue)).fill(emptyCircle);
  const items = [...fullCircles, ...splitCircles, ...emptyCircles];

  return (
    <Stack data-component="dots-progress-bar" direction="row" alignItems="center" gap={1.25}>
      {items.map((item, key) => (
        <StyledSvg key={key} xmlns="http://www.w3.org/2000/svg">
          {item}
        </StyledSvg>
      ))}
    </Stack>
  );
};

export default DotsProgressBar;
