import React, { useEffect } from 'react';
import { sumBy } from 'lodash';
import { AutoAwesomeOutlined } from '@mui/icons-material';

import BaseTable from '../components/BaseTable';
import { useInstanceData } from '../../../providers/InstanceProvider';
import { useColumns } from './columns';
import TableCard from '../components/TableCard';
import { LoadableComponent } from '../components/LoadableComponent';
import { useOfferings } from '../../../providers/OfferingsProvider';
import RIOfferingsTableFooter from '../components/tableFooter/TableFooter';

const T_KEY = 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.RECOMMENDED_TAB.TABLE';

const RecommendedTable = () => {
  const columns = useColumns(T_KEY);
  const { item, reservations } = useInstanceData();
  const { offerings } = useOfferings();

  useEffect(() => {
    reservations.fetch();
  }, [reservations]);

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const isEmpty = !offerings.data?.length || !reservations.data?.length;
  const totalCost = offerings.data ? sumBy(offerings.data, ({ riCost }) => riCost) : 0;
  const isRiCoverageAppropriate = !isEmpty && item.isOverCommitted;

  return (
    <TableCard icon={AutoAwesomeOutlined} tKey={T_KEY}>
      <LoadableComponent
        isLoading={offerings.isFetching || reservations.isFetching}
        error={offerings.error || reservations.error}
        isEmpty={isEmpty}
        isRiCoverageAppropriate={isRiCoverageAppropriate}
      >
        <BaseTable
          data={isRiCoverageAppropriate ? [] : offerings.data ?? []}
          defaultSortModel={{ sort: 'desc', field: 'expectedSavings' }}
          getRowId={(row) => row.reservedInstancesOfferingId}
          tableColumns={columns}
          slots={{
            footer: RIOfferingsTableFooter,
          }}
          slotProps={{ footer: { totalCost, isEmpty } }}
          hideFooter={isRiCoverageAppropriate}
        />
      </LoadableComponent>
    </TableCard>
  );
};

export default RecommendedTable;
