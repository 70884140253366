import { BillingFrequency, RenewalType, RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CommonIntakeFormFormData, CommonRenewalFormData } from '../types';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';

export type AddExistingContractFormData = CommonIntakeFormFormData &
  CommonRenewalFormData & {
    type: 'add_existing_contract';
    totalAnnualCost: number | null;
    startDate?: string;
    signDate?: string;
    billingFrequency: BillingFrequency | null;
    rollingFrequency: RollFrequency | null;
    contractLength?: number | null;
    renewalDate?: string;
    autoRenewalDate?: string;
    vendorContactName?: string;
    vendorContactEmail?: string;
    renewalType: RenewalType | null;
    // for UI only
    contractType: 'fixed' | 'rolling' | null;
    initiateRenewal: 'yes' | 'no' | null;
  };

export const AddExistingContractFormEntry = createTypedFormEntry<AddExistingContractFormData>();

export const isAddExistingContractFormData = (
  formData: CommonIntakeFormFormData
): formData is AddExistingContractFormData => formData.type === 'add_existing_contract';
