import { Filter, FilterValues } from '../types';
import { useCallback, useMemo } from 'react';
import {
  contractContainsString,
  filterCheckboxField,
  filterDepartment,
  filterDropdownField,
  filterOwner,
  filterRenewalStatus,
  filterStage,
  filterTextField,
} from '../filterFunctions';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { every } from 'lodash';
import useContractValueFilter from './useContractValueFilter';

export type UseFilterContractParams = {
  activeFilters?: Filter[];
  filterValues?: FilterValues;
};

export type UseFilterContractReturn = {
  filterContract: (view: ViewOnContract) => boolean;
  isLoading: boolean;
};

const useFilterContract = ({ activeFilters, filterValues }: UseFilterContractParams): UseFilterContractReturn => {
  const { filterContractValue, isLoading: isContractValueFilterLoading } = useContractValueFilter();

  const filterFunctions = useMemo(
    () => ({
      [Filter.VENDOR_PRODUCT]: contractContainsString,
      [Filter.RENEWAL_STATUS]: filterRenewalStatus,
      [Filter.THRESHOLD]: filterContractValue,
      [Filter.DEPARTMENT]: filterDepartment,
      [Filter.OWNER]: filterOwner,
      [Filter.SIGNING_ENTITY]: filterTextField(
        (view: ViewOnContract) => view.contract.parts.contractual?.signatory?.signingEntity?.name
      ),
      [Filter.REGION]: filterTextField((view: ViewOnContract) => view.contract.classification?.region),
      [Filter.ROLLING_FREQUENCY]: filterDropdownField(
        (view: ViewOnContract) => view.contract.parts.contractual?.lifecycle?.rollFrequency
      ),
      [Filter.BILLING_FREQUENCY]: filterDropdownField(
        (view: ViewOnContract) => view.contract.parts.contractual?.financial?.billingFrequency
      ),
      [Filter.PAYMENT_TERMS]: filterDropdownField(
        (view: ViewOnContract) => view.contract.parts.contractual?.financial?.paymentTerms
      ),
      [Filter.STAGE]: filterStage,
      [Filter.CONTRACT_ORIGIN]: filterDropdownField((view: ViewOnContract) => view.contract.record.contractOrigin),
      [Filter.CONTRACT_CATEGORY]: filterDropdownField((view: ViewOnContract) => view.contract.classification?.category),
      [Filter.CUSTOM_CHECKBOX_01]: filterCheckboxField(
        (view: ViewOnContract) => view.contract.customFields?.checkbox01
      ),
      [Filter.CUSTOM_CHECKBOX_02]: filterCheckboxField(
        (view: ViewOnContract) => view.contract.customFields?.checkbox02
      ),
      [Filter.CUSTOM_CHECKBOX_03]: filterCheckboxField(
        (view: ViewOnContract) => view.contract.customFields?.checkbox03
      ),
      [Filter.CUSTOM_CHECKBOX_04]: filterCheckboxField(
        (view: ViewOnContract) => view.contract.customFields?.checkbox04
      ),
      [Filter.CUSTOM_CHECKBOX_05]: filterCheckboxField(
        (view: ViewOnContract) => view.contract.customFields?.checkbox05
      ),
      [Filter.CUSTOM_TEXT_01]: filterTextField((view: ViewOnContract) => view.contract.customFields?.text01),
      [Filter.CUSTOM_TEXT_02]: filterTextField((view: ViewOnContract) => view.contract.customFields?.text02),
      [Filter.CUSTOM_TEXT_03]: filterTextField((view: ViewOnContract) => view.contract.customFields?.text03),
      [Filter.CUSTOM_TEXT_04]: filterTextField((view: ViewOnContract) => view.contract.customFields?.text04),
      [Filter.CUSTOM_TEXT_05]: filterTextField((view: ViewOnContract) => view.contract.customFields?.text05),
      [Filter.CUSTOM_DROPDOWN_01]: filterDropdownField(
        (view: ViewOnContract) => view.contract.customFields?.dropdown01
      ),
      [Filter.CUSTOM_DROPDOWN_02]: filterDropdownField(
        (view: ViewOnContract) => view.contract.customFields?.dropdown02
      ),
      [Filter.CUSTOM_DROPDOWN_03]: filterDropdownField(
        (view: ViewOnContract) => view.contract.customFields?.dropdown03
      ),
      [Filter.CUSTOM_DROPDOWN_04]: filterDropdownField(
        (view: ViewOnContract) => view.contract.customFields?.dropdown04
      ),
      [Filter.CUSTOM_DROPDOWN_05]: filterDropdownField(
        (view: ViewOnContract) => view.contract.customFields?.dropdown05
      ),
    }),
    [filterContractValue]
  );

  const evaluateFilterIfVisible = useCallback(
    (view: ViewOnContract) => (filterName: Filter) => {
      if (!filterValues) {
        return true;
      }

      const value = filterValues[filterName];
      const filterFunction = filterFunctions[filterName];
      return filterFunction(view, value as any);
    },
    [filterFunctions, filterValues]
  );

  const filterContract = useCallback(
    (view: ViewOnContract) => (activeFilters ? every(activeFilters.map(evaluateFilterIfVisible(view))) : true),
    [evaluateFilterIfVisible, activeFilters]
  );

  return {
    filterContract,
    isLoading: isContractValueFilterLoading,
  };
};

export default useFilterContract;
