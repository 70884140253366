import React from 'react';
import { Button } from '@verticeone/design-system/src';
import { useContractGridContext } from '../ContractContext';
import { useTranslation } from 'react-i18next';

type SaveContractProps = {
  contractId: string;
  state: 'new' | 'existing';
};

const SaveContract = ({ contractId, state }: SaveContractProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.BUTTONS' });
  const { actions, isEditing, isSaving } = useContractGridContext();

  const handleSave = () => {
    if (state === 'new') {
      actions.create();
    } else {
      actions.update(contractId);
    }
  };

  if (!isEditing) {
    return null;
  }

  return (
    <Button size="S" variant="solid" color="tertiary" onClick={handleSave} isLoading={isSaving} disabled={isSaving}>
      {t('SAVE')}
    </Button>
  );
};

export default SaveContract;
