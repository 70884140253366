import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
  EntraSourceIcon,
  GoogleSourceIcon,
  JumpCloudSourceIcon,
  OktaSourceIcon,
  OneLoginSourceIcon,
  SlackSourceIcon,
} from '@vertice/assets';
import { Text } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import { useFormatDate } from '@verticeone/utils/formatting';
import { AlarmLastDataUpdate } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { ProviderIds } from '../../utils';

type LastFetchedTooltipItemProps = {
  logItem: AlarmLastDataUpdate;
  providersWithFailedAlarms: string[];
};

export const getProvider = (providerName: string) => {
  const ICON_SIZE = 16;

  switch (providerName) {
    case ProviderIds.ENTRA:
      return { name: 'Entra', icon: <EntraSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.GOOGLE_SAML:
      return { name: 'Google SAML', icon: <GoogleSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.GOOGLE_SOCIAL_LOGIN:
    case ProviderIds.GOOGLE_SOCIAL_LOGIN_NO_PREFIX:
      return { name: 'Google Social Login', icon: <GoogleSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.JUMPCLOUD:
      return { name: 'JumpCloud', icon: <JumpCloudSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.OKTA:
    case ProviderIds.SIMULATOR:
      return { name: 'Okta', icon: <OktaSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.ONE_LOGIN:
      return { name: 'OneLogin', icon: <OneLoginSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    case ProviderIds.SLACK:
      return { name: 'Slack', icon: <SlackSourceIcon width={ICON_SIZE} height={ICON_SIZE} /> };
    default:
      return null;
  }
};

export const LastFetchedTooltipItem = ({ logItem, providersWithFailedAlarms }: LastFetchedTooltipItemProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const formatDate = useFormatDate();

  const hasProviderFailedAlarm = providersWithFailedAlarms.includes(logItem.providerName);
  const hasProviderFailedAccessLogUpdate = logItem.updatedAt === null;
  const containsProviderFailedItems = hasProviderFailedAlarm || hasProviderFailedAccessLogUpdate;

  return (
    <Stack direction="row" alignItems="center" gap={1} key={logItem.providerName}>
      {containsProviderFailedItems ? (
        <IconWrapper icon={InfoIcon} size="M" htmlColor={palette.error.color3} />
      ) : (
        getProvider(logItem.providerName)?.icon
      )}
      <Text
        variant="body-regular"
        size="S"
        color={containsProviderFailedItems ? palette.error.color3 : palette.text.color5}
      >
        {`${getProvider(logItem.providerName)?.name}:`}
      </Text>
      <Text
        variant="body-regular"
        size="S"
        color={containsProviderFailedItems ? palette.error.color3 : palette.text.color4}
      >
        {logItem.updatedAt
          ? formatDate(logItem.updatedAt, {
              formatterOptions: {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              },
            })
          : t('APPLICATIONS.USAGE.LAST_UPDATED.NO_DATA')}
      </Text>
    </Stack>
  );
};
