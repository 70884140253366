import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import { PredefinedWidgetDef } from '../shared/types';
import { TasksSection } from './TasksSection';

export const widgetDef: PredefinedWidgetDef = {
  urnSuffix: /^widget\/request\/tasks\/active\/v\d+$/,
  component: TasksSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.ACTIVE_TASKS',
  renderWhenMissingData: true,
  renderPredicate: (_widgetData, request) =>
    !Array<Request['status']>('COMPLETED', 'FAILED', 'CANCELLED', 'TERMINATED').includes(request.status),
};
