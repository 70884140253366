import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { getTableData } from '../../../../../modules/cloud/utils/graphDataUtils';
import { type TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

type DataColumns =
  | 'aws_infra_spend'
  | 'aws_marketplace_spend'
  | 'eligible_marketplace_spend'
  | 'non_eligible_marketplace_spend';

const EDPEligibleSpendQuery = graphql(`
  query EDPEligibleSpend($accountId: String!, $dateRangeFrom: String!) {
    athenaViewQuery(
      params: {
        accountId: $accountId
        name: "cco_view_edp_eligibility_v1"
        parameters: ["{accountId}", $dateRangeFrom]
      }
    ) {
      __typename
      ... on DataTableResult {
        table(
          columns: [
            "aws_infra_spend"
            "aws_marketplace_spend"
            "eligible_marketplace_spend"
            "non_eligible_marketplace_spend"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const useEligibleSpendData = () => {
  const { accountId } = useAccountContext();
  const startDate = useMemo(() => dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'), []);
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['EDPEligibleSpendChart'],
    queryFn: () =>
      fetchCloudOptimization(EDPEligibleSpendQuery, {
        accountId,
        dateRangeFrom: startDate,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        return (getTableData(data.athenaViewQuery.table as TableType)?.[0] as Record<DataColumns, number>) ?? null;
      }

      return null;
    },
  });
};
