import { Contract, SimplifiedStage, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import {
  ContractListColumn,
  FilterView,
  PREDEFINED_VIEW_COMPLETED,
  PREDEFINED_VIEW_EXPIRED,
  PREDEFINED_VIEW_EXPIRED_IAT,
  PREDEFINED_VIEW_LIVE_FIXED_TERM,
  PREDEFINED_VIEW_LIVE_ROLLING,
  FilterViewWithCount,
  UnifiedContractStage,
} from './types';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { sumBy } from 'lodash';
import evaluateCustomFilterView from './Filters/customViews/evaluateCustomFilterView';

export const getUnifiedStage = (contract: Contract): UnifiedContractStage | null => {
  const simplifiedStage = contract.materialized?.simplifiedStage;

  if (!simplifiedStage) {
    return null;
  }

  if (
    // Simple cases where the unified stage matches simplified stage
    (
      Array<UnifiedContractStage>('DRAFT', 'NOT_YET_LIVE', 'LIVE', 'TERMINATED', 'EXPIRED_PLANNED') as string[]
    ).includes(simplifiedStage)
  ) {
    return simplifiedStage as UnifiedContractStage;
  }

  if (Array<SimplifiedStage>('EXPIRED_NATURAL', 'EXPIRED_UNINTENTIONAL').includes(simplifiedStage)) {
    return 'EXPIRED';
  }

  if (
    simplifiedStage === 'WORKFLOW_IN_PROGRESS' &&
    (
      Array<UnifiedContractStage>('REQUIREMENTS_GATHERING', 'NEGOTIATING', 'APPROVAL', 'CONTRACTING') as string[]
    ).includes(contract.workflow?.stage ?? '')
  ) {
    return contract.workflow?.stage as UnifiedContractStage;
  }

  return null;
};

export const getFilterKey = (filter: FilterView): string => (filter.type === 'predefined' ? filter.key : filter.id);

export const evaluateFilterView = (filterView: FilterView, viewOnContract: ViewOnContract): boolean =>
  filterView.type === 'custom'
    ? evaluateCustomFilterView(filterView, viewOnContract)
    : filterView.filterFunction(viewOnContract);

export const generateFilterViewsWithCount = (
  viewsOnContracts: ViewOnContract[],
  filterViews: FilterView[]
): FilterViewWithCount[] =>
  filterViews.map((filterView) => ({
    filterView,
    count: sumBy(viewsOnContracts, (viewOnContract) => (evaluateFilterView(filterView, viewOnContract) ? 1 : 0)),
  }));

export const getDefaultSortModel = (activeFilterView: FilterView): GridSortModel => {
  switch (activeFilterView) {
    case PREDEFINED_VIEW_COMPLETED:
      return [{ field: ContractListColumn.COMPLETED, sort: 'desc' }];
    case PREDEFINED_VIEW_EXPIRED:
    case PREDEFINED_VIEW_EXPIRED_IAT:
      return [{ field: ContractListColumn.VENDOR, sort: 'asc' }];
    case PREDEFINED_VIEW_LIVE_FIXED_TERM:
      return [{ field: ContractListColumn.RENEWAL_DATE, sort: 'asc' }];
    case PREDEFINED_VIEW_LIVE_ROLLING:
      return [{ field: ContractListColumn.NEXT_ROLL_DATE, sort: 'asc' }];
    default:
      return [{ field: ContractListColumn.DEADLINE, sort: 'asc' }];
  }
};
