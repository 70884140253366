export enum JiraSetupStep {
  CONNECTION = 'connection',
  CONFIGURATION = 'configuration',
  SUCCESS = 'success',
}

export type StepContentProps = {
  activeStep: JiraSetupStep;
  setStep: (step: JiraSetupStep) => void;
};
