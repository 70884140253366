import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { Card } from '@verticeone/design-system/src';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { ifExpression } from '@verticeone/design-system/src/utils/ifExpression';

import { TasksThumbnail } from '../../../../../definitionsTypes';
import { WorkflowTaskNodeType } from '../../../../../workflowSchema/model/types';
import { useChildRequestActiveTasks } from './useChildRequestActiveTasks';
import { ActiveTasks } from './ActiveTasks';
import { RequestName } from './RequestName';

type StyledCardProps = {
  $isActive: boolean;
};

const StyledCard = styled(Card)<StyledCardProps>(({ theme, $isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  ...ifExpression($isActive, {
    cursor: 'pointer',
    transition: 'border 200ms ease-in-out',
    '&:hover': {
      border: `1px solid ${theme.palette.core.color5}`,
    },
  }),
}));

type ActiveChildRequestType = {
  id: string;
  accountId: string;
  name?: string;
  thumbnail: TasksThumbnail;
  type: WorkflowTaskNodeType;
};

type ActiveChildRequestProps = {
  request: ActiveChildRequestType;
};

export const ActiveChildRequest: FC<ActiveChildRequestProps> = ({ request }) => {
  const { id, accountId, thumbnail, type } = request;
  const isVerticeActiveChildRequest = type === 'VERTICE_SERVICE';

  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  const { activeTasksCount, isLoading } = useChildRequestActiveTasks({
    requestId: id,
    accountId,
    skip: isVerticeActiveChildRequest,
  });

  const handleRowClick = () => {
    navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, { requestId: id });
  };

  return (
    <StyledCard
      $isActive={!isVerticeActiveChildRequest}
      role="row"
      onClick={isVerticeActiveChildRequest ? undefined : handleRowClick}
    >
      <RequestName name={request.name || ''} type={type} thumbnail={thumbnail} />
      <Stack p={4} flex={1} />
      {!isVerticeActiveChildRequest && <ActiveTasks isLoading={isLoading} count={activeTasksCount} />}
    </StyledCard>
  );
};
