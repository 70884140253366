import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const taggingApi = createApi({
  reducerPath: 'taggingApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.TAGS),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
