import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { VisualizationDivergentColorRange } from '@mui/material/styles/createPalette';
import type { Color } from '../palette/types';

type ColorsPaletteVariant = 'light' | 'normal' | 'dark';

export type ColorScheme = {
  [key in ColorsPaletteVariant]: Color;
};

export const colorScheme = (baseColor: VisualizationDivergentColorRange, positive: boolean): ColorScheme => ({
  light: baseColor[positive ? '+10' : '-10'],
  normal: baseColor[positive ? '+30' : '-30'],
  dark: baseColor[positive ? '+50' : '-50'],
});

/** Hook that returns an array of colors that are easy to distinguish in charts. */
export const useDivergentColorPalette = () => {
  const { palette } = useTheme();

  return useMemo(
    () => [
      colorScheme(palette.visualization.divergent.primary, true),
      colorScheme(palette.visualization.divergent.primary, false),
      colorScheme(palette.visualization.divergent.secondary, true),
      colorScheme(palette.visualization.divergent.secondary, false),
      colorScheme(palette.visualization.divergent.tertiary, true),
      colorScheme(palette.visualization.divergent.tertiary, false),
    ],
    [palette]
  );
};
