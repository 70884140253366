import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import DataGrid, { GridColDef } from '@vertice/components/src/DataGrid';
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowHeightParams,
  GridRowModel,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import RegionTypeCell from './RegionTypeCell';
import { AWS_BRAND_COLOR } from '../../../constants';
import { RegionData, RegionItem } from './types';

const HEADER_HEIGHT = 48;
const GROUP_ROW_HEIGHT = 48;
const ITEM_ROW_HEIGHT = 60;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: '0',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'none',

  '.MuiDataGrid-row': {
    backgroundColor: theme.palette.input.bg,
  },

  '.group-row': {
    backgroundColor: theme.palette.input.color1,
  },
}));

type CoverageTableProps = {
  data: RegionData[];
  columns: GridColDef[];
};

const CoverageTable = ({ data, columns }: CoverageTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RESERVED_INSTANCES' });

  /** Flatten tree data and add helper attributes */
  const rows = useMemo(
    () =>
      data?.reduce((acc: (RegionData | RegionItem)[], item: RegionData) => {
        return [...acc, { ...item, group: true }, ...item.items];
      }, []),
    [data]
  );

  const getRowId = (row: GridRowModel) => row.id;
  const getTreeDataPath = (row: GridRowModel) => [row.region, ...(row.group ? [] : [row.id])];

  const groupingColumn = {
    headerName: t('COLUMN.TYPE'),
    renderCell: (params: GridRenderCellParams) => <RegionTypeCell {...params} />,
    colSpan: ({ row }: GridCellParams) => (row.group ? columns?.length : 1),
  };

  return (
    <Box height="600px">
      <StyledDataGrid
        treeData
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColumn}
        defaultGroupingExpansionDepth={-1} // expand all rows
        rows={rows || []}
        columns={columns}
        getRowId={getRowId}
        initialState={{
          sorting: {
            sortModel: [{ field: 'saving', sort: 'desc' }],
          },
        }}
        sortingMode="client"
        hideFooter
        getRowHeight={(params: GridRowHeightParams) => (params.model.group ? GROUP_ROW_HEIGHT : ITEM_ROW_HEIGHT)}
        getRowClassName={(params: GridRowParams) => (params.row.group ? 'group-row' : '')}
        columnHeaderHeight={HEADER_HEIGHT}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        autoHeight={false}
        color={AWS_BRAND_COLOR}
      />
    </Box>
  );
};

export default CoverageTable;
