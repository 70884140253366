import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { Text } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { ConcessionTypeType } from '../types';

const TypeCellReadOnly = ({ value }: GridCellParams<any, ConcessionTypeType | null>) => {
  const { t } = useTranslation();

  if (!value) {
    return '';
  }

  return <Text variant="body-regular">{t(`CONCESSIONS_LIST.TYPE.${value}`)}</Text>;
};

export default TypeCellReadOnly;
