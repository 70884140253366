import { Box, styled } from '@mui/material';

export const VendorLogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.core.color1,
  width: '2.5rem',
  height: '2.5rem',
}));
