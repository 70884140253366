import React from 'react';
import useRdsRIUtilizationData from './useRdsRIUtilizationData';
import RIUtilizationTable from '../RIUtilizationTable';
import { LoadableContentWrapper } from '../../../components/LoadableContentWrapper';

const RdsRIUtilizationCard = () => {
  const { data, ...loadingStatus } = useRdsRIUtilizationData();

  return (
    <LoadableContentWrapper {...loadingStatus}>
      <RIUtilizationTable data={data!} />
    </LoadableContentWrapper>
  );
};

export default RdsRIUtilizationCard;
