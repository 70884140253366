import { Resolver } from 'react-hook-form';
import { emailRegexp } from '../utils/oldValidators';
import { NewContactFormType } from './NewContact';

interface Error {
  type: string;
}

interface ErrorsResponse {
  firstName?: Error;
  lastName?: Error;
  email?: Error;
  jobTitle?: Error;
}

const validateEmail = (email: string) => email.toLowerCase().match(emailRegexp);

export const resolver: Resolver<NewContactFormType> = async (values) => {
  const errors = {} as ErrorsResponse;
  const { firstName, lastName, email, jobTitle } = values;

  if (!firstName?.trim().length) {
    errors.firstName = { type: 'required' };
  }

  if (!lastName?.trim().length) {
    errors.lastName = { type: 'required' };
  }

  if (!email?.trim().length) {
    errors.email = { type: 'required' };
  }

  if (!validateEmail(email)) {
    errors.email = { type: 'notValid' };
  }

  if (!jobTitle.trim().length) {
    errors.jobTitle = { type: 'required' };
  }

  return { values, errors };
};
