export type CheckPortableRouteList<T> = {
  [K in keyof T]: T[K] extends PortableRoute ? T[K] : never;
};

export type PortableRoute = {
  path: string;
  children?: Record<string, PortableRoute>;
};

export type PortableRouteList = Record<string, PortableRoute>;

export const createPortableRoutes = <Routes extends PortableRouteList>(
  routes: CheckPortableRouteList<Routes>
): Routes => routes;
