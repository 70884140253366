import { Stack, styled } from '@mui/material';

export const StyledStartEndNode = styled(Stack)<{ $passed?: boolean }>(({ theme: { palette }, $passed }) => ({
  backgroundColor: $passed ? palette.success.color2 : palette.neutral.color2,
  color: palette.text.color5,
  padding: '4px 6px',
  borderRadius: 16,
  alignItems: 'center',
  flexDirection: 'row',
  gap: 4,
  height: 24,
  '& svg': {
    width: 16,
    height: 16,
    opacity: 0.6,
  },
}));
