import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

export const AdditionalNotesFormEntry = () => {
  const { t } = useTranslation();
  return (
    <TaskFormEntry<any, typeof FormTextField>
      name="additionalNotes"
      label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_NOTES')}
      component={FormTextField}
      width={12}
      componentProps={{
        multiline: true,
        rows: 4,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
    />
  );
};
