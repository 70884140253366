import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { type DropzoneInputProps, type DropzoneRootProps, FileError, useDropzone } from 'react-dropzone';
import { Stack } from '@mui/material';
import { type FileType, SUPPORTED_FILE_TYPES } from './supportedFileTypes';
import type { DesignSystemSize } from '../../types';
import { testProps } from '../../utils/testProperties';
import {
  type FileAccepted,
  type FileRejected,
  type FilesProgress,
  acceptableFileType,
  isFileRejectedError,
  transformFilesToAccepted,
  filesToFilesProgress,
} from './utils';
import { FilesToReplace, ReplaceDialog } from './ReplaceDialog';
import { xorWith, differenceWith, isEqual } from 'lodash';

type updateFileProgressProps = {
  file: FileAccepted | FileRejected;
  progress?: number;
  status: 'ready' | 'uploading' | 'uploaded' | 'cancelled';
};

export type OnFilesChangeProps = {
  files: Array<FileAccepted | FileRejected>;
  acceptedFiles: Array<FileAccepted>;
  rejectedFiles: Array<FileRejected>;
  updateFileProgress: ({ file, progress, status }: updateFileProgressProps) => void;
};

type DragAndDropPropsContext = {
  isDisabled: boolean;
  isDragActive: boolean;
  maxFileSize: number;
  addFiles: (acceptedFiles: FileAccepted[], rejectedFiles: FileRejected[]) => Promise<void>;
  removeFiles: (file: (FileAccepted | FileRejected)[]) => Promise<void>;
  acceptedTypes: Array<FileType>;
  filesProgress: FilesProgress;
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  size?: DesignSystemSize;
  customErrorCodes?: string[];
} & OnFilesChangeProps;

export type DragAndDropProps = {
  size?: DesignSystemSize;
  isDisabled?: boolean;
  multiple?: boolean;
  maxFileSize?: number;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
  customErrorCodes?: string[];
  testId?: string;
  acceptedTypes?: Array<FileType>;
  files?: Array<FileAccepted>;
  onFilesChange?: (attr: OnFilesChangeProps) => void;
  onAddFiles?: (acceptedFiles: FileAccepted[], rejectedFiles: FileRejected[]) => Promise<void>;
  onRemoveFiles?: (file: (FileAccepted | FileRejected)[]) => Promise<void>;
  children: ReactNode;
};

const DragAndDropContext = createContext<DragAndDropPropsContext>({} as DragAndDropPropsContext);

export const Fileupload = ({
  size,
  children,
  isDisabled,
  files: presetFiles,
  onFilesChange,
  onAddFiles,
  onRemoveFiles,
  multiple = false,
  testId,
  acceptedTypes = Object.keys(SUPPORTED_FILE_TYPES) as Array<FileType>,
  maxFileSize = 1048576, // 1mb
  validator,
  customErrorCodes = [],
}: DragAndDropProps) => {
  const [files, setFiles] = useState<Array<FileAccepted | FileRejected>>(presetFiles ?? []);
  const [filesProgress, setFilesProgress] = useState<FilesProgress>({});

  const [filesToReplace, setFilesToReplace] = useState<FilesToReplace>();
  const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);

  const acceptedFiles = useMemo(
    () => files.filter((file) => !isFileRejectedError(file)) as Array<FileAccepted>,
    [files]
  );
  const rejectedFiles = useMemo(
    () => files.filter((file) => isFileRejectedError(file)) as Array<FileRejected>,
    [files]
  );

  useEffect(() => {
    if (presetFiles && xorWith(presetFiles, acceptedFiles, isEqual).length) {
      setFiles([...presetFiles, ...rejectedFiles]);
    }
  }, [presetFiles, acceptedFiles, rejectedFiles]);

  useEffect(() => {
    setFilesProgress((prev) => filesToFilesProgress(prev, files));
  }, [files]);

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    multiple,
    disabled: !!isDisabled,
    accept: acceptableFileType(acceptedTypes),
    maxSize: maxFileSize,
    validator: validator,
    onFileDialogOpen: () => {
      rootRef.current?.blur();
    },
    onDrop: (droppedAcceptedFiles, droppedRejectedFiles) => {
      const hasSameFileCb = (file: FileAccepted | FileRejected) => {
        return !files.some((f) => f.file.name === file.file.name);
      };

      const droppedAcceptedFilesTyped = transformFilesToAccepted(droppedAcceptedFiles);
      const newDroppedAcceptedFiles = droppedAcceptedFilesTyped.filter(hasSameFileCb);
      const newDroppedRejectedFiles = droppedRejectedFiles.filter(hasSameFileCb);

      void addFiles(newDroppedAcceptedFiles, newDroppedRejectedFiles);

      // Handle files with name conflict
      const fileNameComparator = (f1: FileAccepted, f2: FileAccepted) => f1.file.name === f2.file.name;
      const acceptedFilesToReplace = differenceWith(
        droppedAcceptedFilesTyped,
        newDroppedAcceptedFiles,
        fileNameComparator
      );
      const rejectedFilesToReplace = differenceWith(droppedRejectedFiles, newDroppedRejectedFiles, fileNameComparator);

      if (acceptedFilesToReplace.length || rejectedFilesToReplace.length) {
        setFilesToReplace({ accepted: acceptedFilesToReplace, rejected: rejectedFilesToReplace });
        setIsReplaceDialogOpen(true);
      }
    },
  });

  const updateFileProgress = useCallback(
    ({ file, progress, status }: updateFileProgressProps) => {
      setFilesProgress((prev) => {
        if (prev[file.file.name] !== undefined) {
          return {
            ...prev,
            [file.file.name]: {
              progress: progress ?? prev[file.file.name].progress,
              status,
            },
          };
        }
        return prev;
      });
    },
    [setFilesProgress]
  );

  useEffect(() => {
    onFilesChange?.({ files, acceptedFiles, rejectedFiles, updateFileProgress });
  }, [onFilesChange, files, acceptedFiles, rejectedFiles, updateFileProgress]);

  const addFiles = useCallback(
    async (newAcceptedFiles: FileAccepted[], newRejectedFiles: FileRejected[]) => {
      setFiles((prevFiles) => [multiple ? prevFiles : [], newAcceptedFiles, newRejectedFiles].flat());

      return onAddFiles?.(newAcceptedFiles, newRejectedFiles);
    },
    [onAddFiles, multiple]
  );

  const removeFiles = useCallback(
    async (removedFiles: (FileAccepted | FileRejected)[]) => {
      const removedFileNames = removedFiles.map((f) => f.file.name);
      setFiles((prevFiles) => prevFiles.filter((f) => !removedFileNames.includes(f.file.name)));

      return onRemoveFiles?.(removedFiles);
    },
    [onRemoveFiles]
  );

  return (
    <DragAndDropContext.Provider
      value={{
        isDisabled: !!isDisabled,
        acceptedTypes,
        isDragActive,
        maxFileSize,
        addFiles,
        removeFiles,
        size,
        files,
        acceptedFiles,
        rejectedFiles,
        filesProgress,
        updateFileProgress,
        customErrorCodes,
        rootProps: getRootProps(),
        inputProps: getInputProps(),
      }}
    >
      <Stack direction="column" width="100%" {...testProps(testId, 'Fileupload')}>
        {children}
        <ReplaceDialog
          isOpen={isReplaceDialogOpen}
          setIsOpen={setIsReplaceDialogOpen}
          filesToReplace={filesToReplace}
        />
      </Stack>
    </DragAndDropContext.Provider>
  );
};

export const useFileupload = () => {
  const context = useContext(DragAndDropContext);
  if (!context) {
    throw new Error('useFileupload must be used within a DragAndDrop component');
  }
  return context;
};
