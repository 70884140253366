import { FC } from 'react';
import { Stack } from '@mui/material';
import { CardGridColDef, CardGridSortModel, OnRowClickEvent, ValidRowModel } from './types';

import { CardGridRows } from './CardGridRows';
import { CardGridHeader } from './CardGridHeader';
import { CardGridStateContext } from './CardGridStateContext';

export type CardGridProps<R extends ValidRowModel> = {
  columns: CardGridColDef<R>[];
  rows: Array<R>;
  activeRowId?: string;
  loading?: boolean;
  loadingRowCount?: number;
  slots?: {
    noRowsOverlay?: FC;
  };
  onRowClick?: OnRowClickEvent<R>;
  noRowsOverlayHeight?: number;
  rowHeight?: number;
  hideHeaders?: boolean;
  sortModel?: CardGridSortModel;
  onSortModelChange?: (sortModel: CardGridSortModel) => void;
};

const DEFAULT_NO_ROWS_OVERLAY_HEIGHT = 64;
export const CardGrid = <R extends ValidRowModel>({
  rows,
  slots,
  noRowsOverlayHeight = DEFAULT_NO_ROWS_OVERLAY_HEIGHT,
  sortModel,
  onSortModelChange,
  loading,
  hideHeaders = false,
  ...restProps
}: CardGridProps<R>) => {
  const NoRows = slots?.noRowsOverlay || Stack;

  return (
    <CardGridStateContext rows={rows} sortModel={sortModel || []} onSortModelChange={onSortModelChange}>
      <Stack width="100%" role="grid" /* <- for testing & a11y */>
        {!hideHeaders && <CardGridHeader<R> columns={restProps.columns} />}
        {rows.length === 0 && !loading ? (
          <Stack height={noRowsOverlayHeight}>
            <NoRows />
          </Stack>
        ) : (
          <CardGridRows<R> {...restProps} loading={loading} />
        )}
      </Stack>
    </CardGridStateContext>
  );
};
