import React from 'react';
import useAccountsData from '../Table/useAccountsData';
import BaseCloudAnalyticsStats from '../../BaseCloudTab/Stats/BaseCloudAnalyticsStats';

const CloudAccountsTable = () => {
  const { data, isLoading } = useAccountsData();
  return <BaseCloudAnalyticsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountsTable;
