import React from 'react';
import { Stack } from '@mui/material';
import DocumentUpload from './DocumentUpload';

const DocumentsTab = () => {
  return (
    <Stack p={6}>
      <DocumentUpload />
    </Stack>
  );
};

export default DocumentsTab;
