import React from 'react';

export type ContextHookResult<ContextName extends string, T> = {
  [ProviderName in `${ContextName}ContextProvider`]: React.Provider<NonNullable<T>>;
} & {
  [HookName in `use${ContextName}Context`]: () => T;
};

/**
 * Creates a context that is required to be used inside its provider.
 * If the provider is not there, the useContext throws an error.
 */
export const createRequiredContext = <CtxType, CtxName extends string>(
  contextName: CtxName
): ContextHookResult<CtxName, CtxType> => {
  const context = React.createContext<CtxType>(null!);
  const providerName = `${contextName}ContextProvider` as `${CtxName}ContextProvider`;

  return {
    [providerName]: context.Provider,
    [`use${contextName}Context`]: () => {
      const data = React.useContext(context);
      if (data === null) {
        throw new Error(`Trying to use ${contextName}Context outside of ${providerName}`);
      }
      return data;
    },
  } as ContextHookResult<CtxName, CtxType>;
};
