import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Button } from '@verticeone/design-system/src';
import { TextField } from '@verticeone/design-system/src';
import { Dialog, DialogHeader } from '@verticeone/design-system/src';
import { useDiscoveredApplicationMutation } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { useListContractsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import ContractView from './ContractView';
import { DataHealthCode } from '../DataHealth/types';
import { isExpiredContract } from '@vertice/core/src/modules/saas/contract/computed';
import { useLazyGetApplicationDataHealthQuery } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';

type LicenseDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (code: DataHealthCode) => void;
  application?: ApplicationWithMeasureReports;
};

export const LicenseDialog = ({ open, onClose, onSuccess, application }: LicenseDialogProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const isSeatBased = application?.license?.type === 'SEAT_BASED';
  const [numberOfLicenses, setNumberOfLicenses] = useState(isSeatBased ? application?.license?.quantity : 0);

  const { applicationId } = useParams();
  const { accountId } = useAccountContext();
  const [discoveredApplicationMutation] = useDiscoveredApplicationMutation();
  const [fetchApplicationDataHealthQuery] = useLazyGetApplicationDataHealthQuery();
  const updateLicenseInformation = async (target: 'SEATS' | 'NOT_SEAT_BASED') => {
    if (applicationId) {
      const response = await discoveredApplicationMutation({
        accountId,
        input: {
          id: applicationId,
          license: {
            quantity: target === 'SEATS' && numberOfLicenses !== undefined ? numberOfLicenses : null,
            type: target === 'SEATS' && numberOfLicenses !== undefined ? 'SEAT_BASED' : null,
          },
        },
      });

      if ('data' in response) {
        const dataHealthResponse = await fetchApplicationDataHealthQuery({ accountId, applicationId });
        if (
          dataHealthResponse?.data &&
          dataHealthResponse.data.getApplicationWithMeasure?.dataHealth?.checks?.some(
            (check) =>
              (check.status === 'PASSED' || check.status === 'IGNORED') &&
              check.code === DataHealthCode.LICENSE_COUNT_MISSING
          )
        ) {
          onSuccess(DataHealthCode.LICENSE_COUNT_MISSING);
        }
      }
    }

    onClose();
  };

  const lineageId = application?.matches?.find((match) => match.entityType === 'Contract')?.entityId;
  const { data: contractsInLineage } = useListContractsQuery(
    {
      lineageId: lineageId || '',
      accountId: accountId,
    },
    { skip: !lineageId || !open }
  );

  const referenceContract = useMemo(() => {
    const liveContract = contractsInLineage?.contracts.find(
      ({ contract }) => contract.materialized?.simplifiedStage === 'LIVE'
    )?.contract;
    if (liveContract) {
      return liveContract;
    }
    const contractInProgress = contractsInLineage?.contracts.find(
      ({ contract }) => contract.materialized?.simplifiedStage === 'WORKFLOW_IN_PROGRESS'
    )?.contract;

    if (contractInProgress) {
      return contractInProgress;
    }

    const expiredContract = contractsInLineage?.contracts.find(({ contract }) => isExpiredContract(contract))?.contract;

    if (expiredContract) {
      return expiredContract;
    }

    return null;
  }, [contractsInLineage]);

  return (
    <Dialog size="M" open={open} setOpen={onClose}>
      <DialogHeader>
        <Text variant="heading" size="S" color={palette.text.color1}>
          {t('APPLICATIONS.USAGE.LICENSE_DIALOG.HEADER')}
        </Text>
      </DialogHeader>
      <Stack direction="column" spacing={0} divider={<Divider sx={{ width: 1 }} />}>
        <Box p={4}>
          <Text size="M" variant="body-regular" color={palette.text.color2}>
            {t('APPLICATIONS.USAGE.LICENSE_DIALOG.TEXT')}
          </Text>
        </Box>

        {referenceContract && <ContractView contract={referenceContract} />}

        <Stack p={4}>
          <Text variant="caption" size="XS" color={palette.text.color2} mb={1}>
            {t('APPLICATIONS.USAGE.LICENSE_DIALOG.LICENSE_COUNT')}
          </Text>
          <TextField
            placeholder="Enter License Count"
            value={numberOfLicenses}
            onChange={(e) => setNumberOfLicenses(parseInt(e.target.value) || 0)}
          />
          <Button
            size="M"
            variant="solid"
            color="primary"
            disabled={numberOfLicenses === undefined || numberOfLicenses === 0}
            onClick={() => updateLicenseInformation('SEATS')}
            sx={{ width: 1, marginTop: 4 }}
          >
            {t('APPLICATIONS.USAGE.LICENSE_DIALOG.CONFIRM')}
          </Button>

          <Divider>
            <Text size="XS" variant="label" color={palette.text.color3} sx={{ textTransform: 'uppercase' }}>
              {t('APPLICATIONS.USAGE.LICENSE_DIALOG.OR')}
            </Text>
          </Divider>
          <Button size="M" variant="outline" color="neutral" onClick={() => updateLicenseInformation('NOT_SEAT_BASED')}>
            {t('APPLICATIONS.USAGE.LICENSE_DIALOG.NO_SEATS')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default LicenseDialog;
