import { styled } from '@mui/material';

/**
 * Unify backgrounds for icons which do not fulfill whole icon area and have some transparent background.
 */

export const IntegrationCardIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.core.color2,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const IntegrationBreadcrumbIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
