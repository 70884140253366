import { PredefinedFormDef } from '../shared/types';
import { CustomerNegotiationForm } from './CustomerNegotiationForm';
import { zodSchema, FormData } from './schema';
import { CustomerLedNegotiationOutput, getCostModel } from './costModel';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/customerNegotiation\/v\d+$/,
  component: CustomerNegotiationForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    vendor: getValue('vendor'),
    additionalNotes: getValue('additionalNotes'),
    targetSignDate: getValue('targetSignDate'),
    products: getValue('products', []),
    recurrence: getValue('recurrence', 'fixed-term'),
    vendorProposedCost: getValue('vendorProposedCost'),
    negotiatedCost: getValue('negotiatedCost'),
    startDate: getValue('startDate'),
    endDate: getValue('endDate'),
    contractCurrency: getValue('contractCurrency'),
  }),
  transformOutput: ({
    vendorProposedCost,
    negotiatedCost,
    recurrence,
    startDate,
    endDate,
    products,
    contractCurrency,
  }): CustomerLedNegotiationOutput => {
    const vendorProposedCostModel = getCostModel(recurrence, startDate, endDate, vendorProposedCost);
    const negotiatedCostModel = getCostModel(recurrence, startDate, endDate, negotiatedCost);

    return {
      products,
      contractCurrency,
      vendorProposedCost: vendorProposedCost ?? undefined,
      startDate,
      endDate: endDate ?? undefined,
      recurrence,
      negotiatedCost,
      costModel: {
        configuration: {
          baseline: vendorProposedCostModel?.id,
          negotiated: negotiatedCostModel?.id,
        },
        instances: [
          ...(vendorProposedCostModel ? [vendorProposedCostModel] : []),
          ...(negotiatedCostModel ? [negotiatedCostModel] : []),
        ],
      },
    };
  },
};
