import { VisualizationVariants } from '../../../typings/muiPalette';
import { Palette } from '@mui/material';
import { adjustHue, getContrast, setLightness } from 'polished';

/**
 * Returns parameters for coloring the avatar
 * divergentPalette - selected divergent palette ('primary', 'secondary', 'tertiary')
 * divergentPaletteBeginning - whether to take the colors from the start or the end of the divergent palette
 * hueShift - the hue shift to apply to the colors
 */
export const getColoringParameters = (personName?: string) => {
  const hash = personName?.split('').reduce((acc, char, index) => acc + char.charCodeAt(0) * index, 0) || 0;
  const hueStep = 30;
  const hueShift = (hash * hueStep) % 360;
  const divergentPalettes = Array<VisualizationVariants>('primary', 'secondary', 'tertiary');
  const divergentPaletteIndex = hash % divergentPalettes.length;
  return {
    divergentPalette: divergentPalettes[divergentPaletteIndex],
    divergentPaletteBeginning: divergentPaletteIndex % 2 === 0 ? 'start' : 'end',
    hueShift,
  };
};

/**
 * Pick colors from divergent palette based on the person name
 */
export const getAvatarColors = (palette: Palette, personId?: string) => {
  const { hueShift, divergentPalette, divergentPaletteBeginning } = getColoringParameters(personId);
  const colorVariant = palette.visualization.divergent[divergentPalette];
  const divergentPaletteColors = Object.values(colorVariant);

  const bgColor = adjustHue(hueShift || 0, divergentPaletteColors[divergentPaletteBeginning === 'start' ? 4 : 5]);
  let fgColor = adjustHue(hueShift || 0, divergentPaletteColors[divergentPaletteBeginning === 'start' ? 0 : 9]);

  const contrast = getContrast(bgColor, fgColor);
  if (contrast < 2) {
    fgColor = setLightness(0.35, fgColor);
  }
  return {
    bgColor,
    fgColor,
  };
};
