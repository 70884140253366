const permissions = [
  'ec2:DescribeReservedInstances',
  'ec2:ModifyReservedInstances',
  'ec2:PurchaseReservedInstancesOffering',
  'ec2:DescribeInstances',
  'ec2:DescribeInstanceTypes',
  'ec2:DescribeAvailabilityZones',
  'ec2:DescribeReservedInstancesOfferings',
];

export default permissions;
