import { useCallback } from 'react';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

type IntFormatOptions =
  | { formatStyle: 'unit'; unit: 'byte' | 'kilobyte' | 'megabyte' | 'gigabyte' | 'terabyte' | 'petabyte' }
  | { formatStyle: 'currency'; currency?: string }
  | { formatStyle: 'decimal' }
  | { formatStyle: 'percent' };

type IntFormatterProps = {
  value: number;
} & IntFormatOptions;

export const useIntFormat = () => {
  const { locale } = useLocaleContext();

  //prettier-ignore
  return useCallback((value: number, { formatStyle, ...otherOptions }: IntFormatOptions) => {
    const formatOptions: Intl.NumberFormatOptions = { ...otherOptions, style: formatStyle };

    if (formatOptions.style === 'currency' && !formatOptions.currency) {
      formatOptions.currency = DEFAULT_CURRENCY;
    }

    return new Intl.NumberFormat(locale, { ...formatOptions, maximumFractionDigits: 2 }).format(value);
  }, [locale]);
};

const IntFormatter = ({ value, ...options }: IntFormatterProps) => {
  const formatter = useIntFormat();

  return <span>{formatter(value, options)}</span>;
};

export default IntFormatter;
