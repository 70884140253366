import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryProps } from '../../../ContractFormEntry';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { keyBy, orderBy } from 'lodash';
import { ContractFormData } from '../../../types';
import { useListAccountUsersQuery } from '@vertice/slices';
import { useContractContext } from '../../../ContractContext';
import UserReadOnlyListField, { UserReadOnlyListFieldType } from '../../UserReadOnlyListField';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { getSelectOptions } from './utils';
import { SelectOption } from './types';

type ReadComponent = UserReadOnlyListFieldType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, SelectOption, false>;

type ContractOwnerFormEntryProps = Omit<
  ContractFormEntryProps<ReadComponent, WriteComponent>,
  'component' | 'writeComponent' | 'componentProps'
>;

const ContractOwnerFormEntry = (options: ContractOwnerFormEntryProps) => {
  const { t } = useTranslation();
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const accountId = contractContext.accessVia.accountId;
  const { data: usersData } = useListAccountUsersQuery({ accountId: accountId! }, { skip: !accountId });

  const contractWatchers = useWatch<ContractFormData>({ name: 'classification.contractWatchers' });
  // Create a list of possible owners excluding the users which are selected as watchers
  const contractOwnerOptions = useMemo(() => {
    const filteredOptions = usersData?.users ? getSelectOptions(usersData.users, contractWatchers) : undefined;
    return filteredOptions ? orderBy(filteredOptions, (opt) => opt.label.toLowerCase()) : undefined;
  }, [usersData, contractWatchers]);

  const userMap = useMemo(() => keyBy(contractOwnerOptions, 'value'), [contractOwnerOptions]);

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...options}
      component={UserReadOnlyListField}
      componentProps={{
        emptyValue: t('ENTITIES.CONTRACT.PLACEHOLDERS.OWNERS_NO_ITEMS'),
        getUserData: (value) => {
          const userId = value.userId;
          const userItem = userId ? userMap[userId as string] : undefined;
          return userItem?.user;
        },
      }}
      // TODO: Use our unified @vertice/core/src/modules/user/UserSelect instead
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: contractOwnerOptions,
        boxValue: (value) => {
          if (!value) {
            return undefined;
          }
          const typedValue = value as { userId?: string };
          return typedValue.userId ? userMap[typedValue.userId] : undefined;
        },
        unboxValue: (selectValue) => (selectValue ? { userId: selectValue?.value } : undefined),
      }}
    />
  );
};

export default ContractOwnerFormEntry;
