import React, { useEffect, useState, FC } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { FormControl, Typography } from '@mui/material';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useLocation, useNavigate } from 'react-router';
import {
  TextField,
  Form,
  FormLabel,
  ConfirmPasswordInput,
  FeedbackLabel,
  PasswordInput,
  SubmitButton,
} from '@vertice/components/src/Form';
import { useFormContext } from 'react-hook-form';
import authStyles from '../../components/Auth.module.scss';
import AuthContainer from '../../components/AuthContainer/AuthContainer';
import PasswordCriteria from '../../components/PasswordCriteria/PasswordCriteria';
import RedirectButton from '../../components/RedirectButton';
import useValidatePassword from '../../useValidatePassword';

interface SubmitResetPasswordFormProps {
  onPasswordConfirmValidationResult: (value: boolean) => void;
  passwordMismatchError: boolean;
  error: string;
}

type SubmitResetPasswordFormData = {
  code: string;
  password: string;
  confirmPassword: string;
};

const SubmitResetPasswordForm: FC<SubmitResetPasswordFormProps> = ({
  onPasswordConfirmValidationResult,
  passwordMismatchError,
  error,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<SubmitResetPasswordFormData>();
  const passwordValidation = useValidatePassword(watch('password'));
  const confirmPasswordValidation = useValidatePassword(watch('confirmPassword'));

  const passwordMatchCriteria = !Object.values(passwordValidation).every((value) => value);
  const confirmPasswordValidationMatchCriteria = !Object.values(confirmPasswordValidation).every((value) => value);

  return (
    <div className={authStyles['form-wrapper']}>
      <FormControl fullWidth variant="outlined">
        <FormLabel for="code">{t('SUBMIT_RESET_PASSWORD.CODE')}</FormLabel>
        <TextField<SubmitResetPasswordFormData>
          id="code"
          autoFocus
          autoComplete="off"
          required
          placeholder={t('SUBMIT_RESET_PASSWORD.CODE')}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <FormLabel for="password" error={passwordMismatchError || passwordMatchCriteria}>
          {t('SUBMIT_RESET_PASSWORD.NEW_PASSWORD')}
        </FormLabel>
        <PasswordInput<SubmitResetPasswordFormData>
          id="password"
          required
          autoComplete="off"
          error={passwordMismatchError || passwordMatchCriteria}
          placeholder={t('SUBMIT_RESET_PASSWORD.NEW_PASSWORD')}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <FormLabel for="confirmPassword" error={passwordMismatchError || confirmPasswordValidationMatchCriteria}>
          {t('SUBMIT_RESET_PASSWORD.CONFIRM_NEW_PASSWORD')}
        </FormLabel>
        <ConfirmPasswordInput<SubmitResetPasswordFormData>
          id="confirmPassword"
          watchFor="password"
          autoComplete="off"
          error={passwordMismatchError || confirmPasswordValidationMatchCriteria}
          onValidationResult={onPasswordConfirmValidationResult}
          placeholder={t('SUBMIT_RESET_PASSWORD.CONFIRM_NEW_PASSWORD')}
        />
      </FormControl>
      <FeedbackLabel error={error} />
      <PasswordCriteria id="password" />
    </div>
  );
};

const SubmitResetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { from, email } = location.state || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);

  const defaultFormValues = {
    code: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (form: typeof defaultFormValues) => {
    setIsSubmitting(true);
    setError('');
    if (form.password !== form.confirmPassword) {
      setError(t('AUTH.ERRORS.PASSWORD_MUST_MATCH'));
    } else {
      try {
        await Auth.forgotPasswordSubmit(email, form.code, form.password);
        navigate(`${ROUTES.LOGIN}?userEmail=${encodeURIComponent(email)}`, {
          state: { from, notificationKey: 'AUTH.PASSWORD_SET' },
          replace: true,
        });
      } catch (e) {
        if (e && typeof e === 'object' && !!Object.prototype.hasOwnProperty.call(e, 'message')) {
          const errorObject = e as { message?: string; name?: string };
          if (errorObject?.message) {
            setError(errorObject.message);
          }
        }
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.LOGIN);
    }
  }, [email, navigate]);

  const handlePasswordConfirmValidationResult = (value: boolean) => {
    if (value) {
      setError('');
      setPasswordMismatchError(false);
    } else {
      setError(t('AUTH.ERRORS.PASSWORD_MUST_MATCH'));
      setPasswordMismatchError(true);
    }
  };

  return (
    <AuthContainer>
      <Typography variant="heading-s">{t('SUBMIT_RESET_PASSWORD.HEADER')}</Typography>
      <Form defaultValues={defaultFormValues} onSubmit={handleSubmit} className={authStyles.form}>
        <SubmitResetPasswordForm
          onPasswordConfirmValidationResult={handlePasswordConfirmValidationResult}
          passwordMismatchError={passwordMismatchError}
          error={error}
        />
        <div className={authStyles.actions}>
          <SubmitButton isLoading={isSubmitting}>{t('SUBMIT_RESET_PASSWORD.SUBMIT')}</SubmitButton>
          <RedirectButton to={ROUTES.LOGIN}>{t('SUBMIT_RESET_PASSWORD.GO_TO_LOGIN')}</RedirectButton>
        </div>
      </Form>
    </AuthContainer>
  );
};

export default SubmitResetPassword;
