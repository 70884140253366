import React, { RefAttributes } from 'react';
import { styled, tableRowClasses, inputBaseClasses, iconButtonClasses, listClasses } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import type { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import type { GridValidRowModel } from '@mui/x-data-grid';

import { sortingOrder, HEADER_SIZES } from './constants';
import { DesignSystemColor, DesignSystemSize } from '../../types';
import { tableCellSpacing } from '../../utils/css/tableSharedStyles';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import GridPagination from './components/GridPagination';
import { getTextVariantStyle } from '../Text';
import { AscendingIcon } from './components/AscendingIcon';
import { DescendingIcon } from './components/DescendingIcon';
import { testProps } from '../../utils/testProperties';
import { DataGridProps } from './types';
import { useDataGridContext } from './DataGridContext';
import { MenuIcon } from './components/MenuIcon';
import useTheme from '@mui/material/styles/useTheme';
import { paperClasses } from '@mui/material/Paper';
import ColumnMenu from './components/ColumnMenu';

type CSSVariableOverrideProps = {
  $overlayHeight?: number;
};

type StyledProps = CSSVariableOverrideProps & {
  $color: DesignSystemColor;
  $size: DesignSystemSize;
  $headerSize: DesignSystemSize;
  $noBorder?: boolean;
  $noBorderRadius?: boolean;
  $multilineHeaders?: boolean;
};

const DataGridWithCSSVariableOverride = styled(DataGridPro)<CSSVariableOverrideProps>(({ $overlayHeight }) =>
  $overlayHeight ? `--DataGrid-overlayHeight: ${$overlayHeight}px` : ''
);

const StyledDataGridPro = styled(DataGridWithCSSVariableOverride)<StyledProps>(
  ({ $size, $headerSize, $noBorder, $noBorderRadius, $multilineHeaders, theme }) => ({
    borderRadius: $noBorderRadius ? 0 : theme.spacing(4),
    display: 'grid',
    // so the grid pagination doesn't expand
    gridTemplateRows: 'auto min-content',
    [`.${gridClasses.main}`]: {
      borderTopLeftRadius: $noBorderRadius ? 0 : theme.spacing(4),
      borderTopRightRadius: $noBorderRadius ? 0 : theme.spacing(4),

      // make it so the border doesn't display in scrollbar
      '&:last-child': {
        borderBottomLeftRadius: $noBorderRadius ? 0 : theme.spacing(4),
        borderBottomRightRadius: $noBorderRadius ? 0 : theme.spacing(4),
      },
    },

    [`.${gridClasses.columnHeaders}`]: {
      boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '2z', distance: '20' }),
      backgroundColor: theme.palette.core.color1,
      // needed so the column header shadow is visible above the virtual scroller cells with set background color
      zIndex: 1,
    },

    [`.${gridClasses.columnSeparator}`]: {
      visibility: 'visible',
    },

    // needed override inlined styles to make the checkbox column width consistent
    [`.${gridClasses.cellCheckbox}, .${gridClasses.columnHeaderCheckbox}`]: {
      maxWidth: '100% !important',
      width: 'auto !important',
    },

    [`.${gridClasses.columnHeader}`]: {
      paddingLeft: baseSizes[$size],
      paddingRight: baseSizes[$size],

      '&:focus-within': {
        outline: 'none',
      },

      '&:focus-visible': {
        outline: `2px solid ${theme.palette.core.color5}`,
        outlineOffset: '-2px',
      },
    },
    [`.${gridClasses.columnHeaderTitleContainer}`]: {
      whiteSpace: $multilineHeaders ? 'inherit' : 'nowrap',
      gap: HEADER_SIZES[$headerSize].gap,
      overflow: 'unset',
      [`.${iconButtonClasses.root}`]: {
        padding: 0,
        opacity: 0.4,
        transition: 'opacity 0.2s',
        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 1,
        },
      },
    },
    // needed to show shadows around title header with expendable button
    [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
      paddingRight: '4px',
      marginRight: '-4px',
      height: '100%',
    },
    [`.${gridClasses.columnHeaderTitle}`]: {
      ...getTextVariantStyle({ variant: 'label', size: HEADER_SIZES[$headerSize].fontSize }),
      color: theme.palette.neutral.color1,
      whiteSpace: $multilineHeaders ? 'normal' : 'nowrap',
    },

    [`.${gridClasses.row}`]: {
      backgroundColor: theme.palette.input.bg,

      '&:hover': {
        backgroundColor: theme.palette.input.color1,
      },

      [`&.${tableRowClasses.selected}`]: {
        backgroundColor: theme.palette.input.color2,

        '&:hover': {
          backgroundColor: theme.palette.input.color2,
        },
      },
    },

    [`.${gridClasses.cell}`]: {
      paddingLeft: baseSizes[$size],
      paddingRight: baseSizes[$size],
      ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
      color: theme.palette.text.color1,

      [`&.${gridClasses.withBorderColor}`]: {
        borderColor: theme.palette.core.color2,
      },

      '&:focus-within': {
        outline: `2px solid ${theme.palette.core.color5}`,
        outlineOffset: '-2px',
      },

      [`&.${gridClasses['cell--editing']}`]: {
        '&:focus-within': {
          outline: `2px solid ${theme.palette.core.color5}`,
          outlineOffset: '-2px',
        },

        [`& .${inputBaseClasses.root}`]: {
          ...getTextVariantStyle({ variant: 'body-regular', size: $size }),
          color: theme.palette.text.color1,
        },
      },
    },
    ...($noBorder
      ? {
          border: 'none',
          [`.${gridClasses.root}`]: { border: 'none' },
          [`.${gridClasses.withBorderColor}`]: {
            '&:last-child': {
              borderRight: 'none',
            },
          },
          [`.${gridClasses.row}`]: {
            '&:last-child': {
              [`.${gridClasses.cell}`]: {
                borderBottom: 'none',
              },
            },
          },
        }
      : {}),
  })
) as <R extends GridValidRowModel>(
  props: DataGridProProps<R> & StyledProps & RefAttributes<HTMLDivElement>
) => JSX.Element;

export const DataGridBase = <R extends GridValidRowModel>({
  color = 'primary',
  size = 'M',
  headerSize = size,
  noBorder,
  noBorderRadius,
  noRowsOverlayHeight,
  pagination,
  testId,
  multilineHeaders,
  ...props
}: DataGridProps<R>) => {
  const theme = useTheme();
  const { component } = useDataGridContext();
  if (props.disableColumnFilter === undefined) {
    // TODO: overriding the default value - remove it when BE will implement filtering
    props.disableColumnFilter = true;
  }

  return (
    <StyledDataGridPro<R>
      sortingOrder={sortingOrder}
      $color={color}
      $headerSize={headerSize}
      $multilineHeaders={multilineHeaders}
      $size={size}
      $noBorder={noBorder}
      $noBorderRadius={noBorderRadius}
      $overlayHeight={noRowsOverlayHeight}
      {...props}
      rowHeight={4 * tableCellSpacing[size].height}
      columnHeaderHeight={4 * tableCellSpacing[headerSize].height}
      pagination={pagination}
      hideFooter={!pagination && props.hideFooter !== false}
      hideFooterRowCount={!pagination && props.hideFooterRowCount !== false}
      hideFooterSelectedRowCount
      showCellVerticalBorder
      showColumnVerticalBorder
      slots={{
        columnUnsortedIcon: AscendingIcon,
        columnSortedAscendingIcon: AscendingIcon,
        columnSortedDescendingIcon: DescendingIcon,
        columnMenuIcon: MenuIcon,
        columnMenu: ColumnMenu,
        pagination: GridPagination,
        ...props.slots,
      }}
      slotProps={{
        basePopper: {
          // styles extracted from Menu component
          sx: {
            [`&.${gridClasses.menu} .${paperClasses.root}`]: {
              border: `1px solid ${theme.palette.core.color3}`,
              boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '60' }),
              marginTop: theme.spacing(1),
              overflow: 'hidden auto',
              width: 200,
            },
            [`.${listClasses.root}`]: {
              padding: 0,
            },
          },
        },
        ...props.componentsProps,
        ...props.slotProps,
      }}
      {...testProps(testId, component)}
    />
  );
};
