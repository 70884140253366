import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system/src';
import { LoadableComponent } from '@verticeone/design-system/src';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useSpendBreakdownData } from './useSpendBreakdownData';
import BreakdownChart from '../BreakdownChart';
import CurrentTermSubtitle from '../../CurrentTermSubtitle';

const Layout = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SPEND_BREAKDOWN' });
  const { palette } = useTheme();
  const { data, isFetching } = useSpendBreakdownData();

  return (
    <Card>
      <CardHeader size="S">
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <CardHeaderTitle text={t('TITLE')} tag="h2" />
            <CurrentTermSubtitle />
          </Box>
        </Stack>
      </CardHeader>
      <Box bgcolor={palette.core.color1} py={6}>
        <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
          {data && (
            <BreakdownChart
              data={data}
              thresholdValue={data.commitment}
              thresholdLabel={t('GRAPH.COMMITMENT')}
              showOutstandingCommitment
            />
          )}
        </LoadableComponent>
      </Box>
    </Card>
  );
};

export default Layout;
