const checkIsPdf = (fileName: string) => fileName.split('.').slice(-1)[0].toLowerCase() === 'pdf';

const createObjectURL = (blob: Blob) => {
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
};

export const autoDownload = (fileUrl: string, fileName = 'file') => {
  const isPDF = checkIsPdf(fileName);

  let a: HTMLAnchorElement | null = document.createElement('a');
  a.setAttribute('href', fileUrl);

  if (isPDF) {
    a.setAttribute('target', '_blank');
  } else {
    a.setAttribute('download', fileName);
  }

  a.click();
  a = null;
};

export const autoDownloadBlob = (blob: Blob, fileName: string) => {
  if (checkIsPdf(fileName)) {
    blob = new Blob([blob], { type: 'application/pdf' });
  }

  const blobUrl = createObjectURL(blob);
  autoDownload(blobUrl, fileName);
};
