import { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Placeholder, Divider, ChipButton, PageHeader } from '@verticeone/design-system/src';

import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

import { useConfirmPageExit } from '@vertice/core/src/hooks/useConfirmPageExit';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { WorkflowEditor } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/WorkflowEditor';
import { WorkflowViewer } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowViewer/WorkflowViewer';
import { useSaveWorkflow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useSaveWorkflow';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import { useMainLayout } from '../../../../layouts/Main/LayoutWrapper';
import { useWorkflowVersions } from '../WorkflowDetailPage/useWorkflowVersions';
import { DiscardChangesDialog } from './components/DiscardChangesDialog';

export const WorkflowEditorPage: FC = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setOpenOverride } = useMainLayout();

  useEffect(() => {
    setOpenOverride(false);
    return () => setOpenOverride(undefined);
  }, [setOpenOverride]);

  const params = useParams() as { workflowId: string; versionId: string; serviceId: string };

  const {
    data: workflowVersionData,
    isLoading: isWorkflowVersionLoading,
    refetch,
  } = useGetWorkflowVersionQuery({
    accountId,
    workflowId: params.workflowId,
    workflowVersion: params.versionId,
  });

  const { activeVersion } = useWorkflowVersions(params.workflowId);
  const { saveWorkflow, isSavingWorkflow } = useSaveWorkflow();
  const navigate = useNavigate();

  const workflowDefinition = workflowVersionData?.workflowDefinition as Definitions;
  const [workingDefinitions, setWorkingDefinitions] = useState<Definitions | undefined>(workflowDefinition);
  const [hasChanges, setHasChanges] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isChangingPage = nextLocation.pathname !== currentLocation.pathname;
    const isExitAction = Boolean(new URLSearchParams(currentLocation.search).get('exit'));

    return hasChanges && isChangingPage && !isExitAction;
  });

  const { data: servicesData } = useListServiceCatalogQuery({
    accountId,
  });
  const service = servicesData?.items?.find((s) => s.urn === activeVersion?.serviceRef);

  useEffect(() => {
    if (!isEqual(workingDefinitions, workflowDefinition)) setHasChanges(true);
    else setHasChanges(false);
  }, [workingDefinitions, workflowDefinition]);

  useEffect(() => {
    if (workflowDefinition) {
      setWorkingDefinitions(workflowDefinition);
    }
  }, [workflowDefinition]);

  useConfirmPageExit(hasChanges);

  if (isWorkflowVersionLoading) {
    return <Placeholder height="100%" />;
  }

  const handleSaveWorkflow = (shouldGoBack = false) => {
    if (workingDefinitions) {
      saveWorkflow(
        {
          workflowId: params.workflowId,
          definitions: workingDefinitions,
          workflowVersion: workflowVersionData?.versionId,
          serviceRef: workflowVersionData?.serviceRef,
          customVersionName: workflowVersionData?.versionName,
        },
        async () => {
          if (shouldGoBack) {
            const param = new URLSearchParams({ exit: 'true' }).toString();

            // Since state setters are async, we will use a search param as deciding factor to navigate
            navigate(`?${param}`);
            navigate(backPathWithTabParam);
          } else {
            await refetch();
          }

          enqueueSnackbar({ variant: 'success', message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_SUCCESS') });
        },
        () => {
          enqueueSnackbar({
            variant: 'error',
            message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE'),
            description: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE_DESCRIPTION'),
          });
        }
      );
    }
  };

  const backPath = generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, {
    workflowId: params.workflowId,
    serviceId: params.serviceId,
  });

  const backPathWithTabParam = `${backPath}?${new URLSearchParams({ tab: 'drafts' }).toString()}`;

  return (
    <Stack height={'100%'} minWidth={'100%'}>
      <IntelligentWorkflowsLayout
        parentPageAccountId={accountId}
        parentPageName={activeVersion?.workflowName}
        parentPagePath={backPathWithTabParam}
        grandparentPageName={service?.name}
        grandparentPagePath={generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
          serviceId: params.serviceId,
        })}
        currentPageName={workflowVersionData?.versionName}
        currentPageAdornments={{
          end: (
            <ChipButton variant="solid" color="transparent" size="S" onClick={() => null} style={{ cursor: 'default' }}>
              {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DRAFT')}
            </ChipButton>
          ),
        }}
        actions={
          <>
            <PageHeader.SecondaryAction
              onClick={() => {
                handleSaveWorkflow(true);
              }}
            >
              {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE_AND_CLOSE')}
            </PageHeader.SecondaryAction>
            <PageHeader.PrimaryAction
              disabled={!hasChanges}
              onClick={() => {
                handleSaveWorkflow();
              }}
            >
              {isSavingWorkflow ? (
                <CircularProgress size="XS" color="secondary" />
              ) : (
                t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE')
              )}
            </PageHeader.PrimaryAction>
          </>
        }
        padding={0}
        gap={0}
      >
        <Divider />
        <Stack flexGrow={1}>
          {workflowDefinition ? (
            <WorkflowViewer definitions={workingDefinitions} isEditor>
              <WorkflowEditor
                workflowDefinitions={workingDefinitions}
                setDefinitions={setWorkingDefinitions}
                serviceRef={workflowVersionData?.serviceRef}
              />
            </WorkflowViewer>
          ) : (
            <Placeholder height="100%" />
          )}
        </Stack>
        <DiscardChangesDialog
          open={blocker.state === 'blocked'}
          onClose={() => blocker.reset?.()}
          onDiscard={() => blocker.proceed?.()}
        />
      </IntelligentWorkflowsLayout>
    </Stack>
  );
};
