import React from 'react';
import { Stack } from '@mui/material';

type ButtonGroupProps = {
  children: React.ReactNode;
};

const ButtonGroup = ({ children }: ButtonGroupProps) => (
  <Stack py={4} gap={2} direction="row">
    {children}
  </Stack>
);

export default ButtonGroup;
