import React from 'react';
import { Grid } from '@mui/material';

type FormSectionProps = {
  children: React.ReactNode;
};

/**
 * A form section is a container for collection of FormEntry components.
 */
const FormSection = ({ children }: FormSectionProps) => (
  <Grid container spacing={8}>
    {children}
  </Grid>
);

export default FormSection;
