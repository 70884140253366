import React from 'react';
import { Stack } from '@mui/material';
import { useInstanceData } from './providers/InstanceProvider';
import ChipsGroup from './ChipsGroup';
import { Text } from '@verticeone/design-system/src';

const OptimizeCardHeaderTitle = () => {
  const { item, managedByVertice } = useInstanceData();

  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <Text color={managedByVertice.isActive ? 'tertiary1' : 'text1'} variant="heading" size="S">
        {item.instanceFamily}
      </Text>
      <ChipsGroup />
    </Stack>
  );
};

export default OptimizeCardHeaderTitle;
