import { Stack } from '@mui/material';
import type { DesignSystemSize } from '../../types';
import { useFileupload } from './Fileupload';
import { FileItem, type Column } from './FileItem';
import { FileAccepted, FileRejected } from './utils';

type FileListProps = {
  size?: DesignSystemSize;
  hiddenColumns?: Array<Column>;
  hideErrorFiles?: boolean;
  isDisabled?: boolean;
  dynamicFileNameLength?: boolean;
  onFileNameClick?: (file: FileAccepted | FileRejected) => void;
};

export const FileList = ({
  size,
  hiddenColumns,
  isDisabled,
  hideErrorFiles = false,
  dynamicFileNameLength = false,
  onFileNameClick,
}: FileListProps) => {
  const { files, acceptedFiles } = useFileupload();

  if (hideErrorFiles && (!acceptedFiles || !acceptedFiles.length)) {
    return null;
  }

  return (
    <Stack direction="column" gap={2}>
      {files.map((file) => (
        <FileItem
          key={file.file.name}
          file={file}
          size={size}
          hiddenColumns={hiddenColumns}
          hideErrorFiles={hideErrorFiles}
          isDisabled={isDisabled}
          dynamicFileNameLength={dynamicFileNameLength}
          onFileNameClick={onFileNameClick}
        />
      ))}
    </Stack>
  );
};
