import { useMemo } from 'react';

import { RequestRow } from '../../types';
import { StatusFilterType } from './types';
import { useFilterUtils } from './useFilterUtils';

type RequestFilter = {
  searchQuery: string;
  activeStatusFilter: StatusFilterType;
  myRequestsOnly: boolean;
};

export const useRequestFilter = (requests: RequestRow[], filter: RequestFilter) => {
  const { filterBySearchQuery, filterByStatusFilter, filterByMyRequests } = useFilterUtils();

  // This needs some optimizing, but works pretty good for a "full text search"
  const filteredRequests = useMemo(
    () =>
      filterByMyRequests(
        filter.searchQuery ? filterBySearchQuery(requests, filter.searchQuery) : requests,
        filter.myRequestsOnly
      ),
    [requests, filterByMyRequests, filter.myRequestsOnly, filterBySearchQuery, filter.searchQuery]
  );

  // We need this to show correct amounts of requests in the filter
  const searchQueryRequests = useMemo(() => {
    return filterByStatusFilter(filteredRequests, filter.activeStatusFilter);
  }, [filterByStatusFilter, filteredRequests, filter.activeStatusFilter]);

  return { filteredRequests, searchQueryRequests };
};
