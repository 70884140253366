import React, { FC, useMemo } from 'react';
import { RequestGrid } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/RequestsGrid';
import { useRequestsDashboardColumns } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/useRequestsColumns';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useGridRequestDetails } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/useRequestDetails';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';

type RequestsProps = {
  paginationModel: GridPaginationModel | undefined;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel | undefined>>;
};
const Requests: FC<RequestsProps> = ({ paginationModel = { page: 0, pageSize: 25 }, setPaginationModel }) => {
  const { requests, requestIds, isLoadingRequests, onGridStateChanged } = useGridRequestDetails();

  const columns = useRequestsDashboardColumns();
  const activeRequests = useMemo(() => requests.filter(({ status }) => status === 'ACTIVE'), [requests]);

  return (
    <UsersContextProvider>
      <RequestGrid
        columns={columns}
        requests={activeRequests}
        requestIds={requestIds}
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        onStateChange={onGridStateChanged}
        loading={isLoadingRequests}
        defaultSortModel={[{ field: 'keyDate', sort: 'asc' }]}
        noBorderRadius
        noBorder
      />
    </UsersContextProvider>
  );
};

export default Requests;
