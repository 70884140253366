import { DesignSystemSize } from '../../types';
import { createRequiredContext } from '../../utils/contexts';
import { AccordionVariant } from './types';

type AccordionContextValue = {
  variant: AccordionVariant;
  size: DesignSystemSize;
  disabled?: boolean;
  alwaysExpanded?: boolean;
};

export const { AccordionContextProvider, useAccordionContext } = createRequiredContext<
  AccordionContextValue,
  'Accordion'
>('Accordion');
