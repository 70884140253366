import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { ChipButton, Text, Dialog, DialogContent, DialogHeader } from '@verticeone/design-system/src';

import { ProcessDefinition, GatewayDefinition } from '../../definitionsTypes';
import { VariablesList } from './VariablesList';
import { useGatewayVariables } from './useGatewayVariables';

type GatewayVariablesDialogProps = {
  gateway: GatewayDefinition;
  isOpened: boolean;
  onClose: () => void;
  processDefinition: ProcessDefinition;
};

export const GatewayVariablesDialog: FC<GatewayVariablesDialogProps> = ({
  isOpened,
  onClose,
  gateway,
  processDefinition,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY.DIALOG' });

  const { requestProperties, globalUDFProperties } = useGatewayVariables({
    gatewayId: gateway.gateway.id,
    processDefinition,
  });

  return (
    <Dialog size="M" open={isOpened} onClose={onClose} setOpen={onClose}>
      <DialogHeader>{t('AVAILABLE_VARIABLES')}</DialogHeader>
      <DialogContent>
        <Text size="S" variant="body-bold">
          {t('LEGEND.TITLE')}
        </Text>
        <Stack direction="row" gap={1}>
          <ChipButton isActive={false} size="XXS">
            {t('LEGEND.SOURCE')}
          </ChipButton>
          <ChipButton isActive={false} color="warning" size="XXS">
            {t('LEGEND.OPTIONALITY')}
          </ChipButton>
          <ChipButton isActive={false} color="secondary" size="XXS">
            {t('LEGEND.TYPE')}
          </ChipButton>
        </Stack>
        <Stack gap={5}>
          <VariablesList title={t('REQUEST')} properties={requestProperties} />
          <VariablesList title={t('UDFS')} properties={globalUDFProperties} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
