import React from 'react';
import { Stack, useTheme } from '@mui/material';

type ImageHeadingProps = {
  children: React.ReactNode;
};

export const ImageHeader: React.FC<ImageHeadingProps> = ({ children }) => {
  const { palette } = useTheme();

  return (
    <Stack overflow="hidden" borderRadius={'16px 16px 0 0'} bgcolor={palette.core.color1}>
      {children}
    </Stack>
  );
};
