import { VendorLegalInfo } from '@vertice/slices';

export interface InsightLink {
  label: string;
  link?: string;
}

interface LinksDataModel {
  [key: string]: InsightLink[];
}

export const useLinksDataModelLegal = (vendorLegalData: VendorLegalInfo = {}): LinksDataModel => ({
  MSA_LEGAL_AGREEMENTS: [
    {
      label: 'MSA',
      link: vendorLegalData.msaUrl,
    },
    {
      label: 'Legal Agreements',
      link: vendorLegalData.msaAgreementUrl,
    },
  ],
  DATA_PROTECTION_AGREEMENTS: [
    {
      label: 'Data Processing Addendum',
      link: vendorLegalData.dpaUrl,
    },
    {
      label: 'Subprocessors Addendum',
      link: vendorLegalData.subprocessorsUrl,
    },
  ],
});

export const useLinksDataModelCompliance = (vendorLegalData: VendorLegalInfo = {}): LinksDataModel => ({
  PRIVACY: [
    {
      label: 'Privacy Policy',
      link: vendorLegalData.privacyUrl,
    },
  ],
  STATUS_AND_UPTIME: [
    {
      label: 'Status Page',
      link: vendorLegalData.statusUrl,
    },
  ],
  GDPR_AND_COMPLIANCE: [
    {
      label: 'GDPR Overview',
      link: vendorLegalData.gdprUrl,
    },
    {
      label: 'Security',
      link: vendorLegalData.securityUrl,
    },
    {
      label: 'Compliance',
      link: vendorLegalData.complianceUrl,
    },
  ],
});
