export type FileType = 'txt' | 'csv' | 'doc' | 'xls' | 'ppt' | 'pdf' | 'jpg' | 'png';
export type FileTypeParams = {
  extensions: Array<`.${string}`>;
  mimeTypes: Array<string>;
};

export const SUPPORTED_FILE_TYPES: Record<FileType, FileTypeParams> = {
  txt: {
    extensions: ['.txt'],
    mimeTypes: ['text/plain'],
  },
  csv: {
    extensions: ['.csv'],
    mimeTypes: ['text/csv'],
  },
  doc: {
    extensions: ['.doc', '.docx'],
    mimeTypes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  },
  xls: {
    extensions: ['.xls', '.xlsx'],
    mimeTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  },
  ppt: {
    extensions: ['.ppt', '.pptx'],
    mimeTypes: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  },
  pdf: {
    extensions: ['.pdf'],
    mimeTypes: ['application/pdf'],
  },
  jpg: {
    extensions: ['.jpg', '.jpeg'],
    mimeTypes: ['image/jpg', 'image/jpeg'],
  },
  png: {
    extensions: ['.png'],
    mimeTypes: ['image/png'],
  },
};
