import React from 'react';
import { IconButton } from '@verticeone/design-system/src';
import { AddOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export type NewFilterViewButtonProps = {
  onClick: () => void;
};

const NewFilterViewButton = ({ onClick }: NewFilterViewButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      icon={AddOutlined}
      variant="outline"
      size="S"
      onClick={onClick}
      aria-label={t('ENTITIES.CONTRACT.LABELS.LIST_FILTERS.NEW_VIEW')}
    />
  );
};

export default NewFilterViewButton;
