import { ForwardedRef, forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { TestProps } from '../../types';
import { testProps } from '../../utils/testProperties';
import { StackProps } from '@mui/material/Stack/Stack';

type CardProps = TestProps & StackProps;

export const StyledCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  borderRadius: theme.spacing(4),
  border: `1px solid ${theme.palette.core.color3}`,
  overflow: 'hidden',
  position: 'relative',
}));

export const Card = forwardRef(({ children, testId, ...props }: CardProps, ref: ForwardedRef<HTMLDivElement>) => (
  <StyledCard {...testProps(testId, 'card')} {...props} ref={ref}>
    {children}
  </StyledCard>
));
