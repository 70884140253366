import { PointOptionsObject } from 'highcharts';
import { LegendItemData } from '../../components/Legend/types';

/**
 * Creates a legend item for each point.
 * @param points
 */
const extractLegendItemsFromPoints = (points: PointOptionsObject[]): LegendItemData[] =>
  points.map(({ name, y, color }: PointOptionsObject) => ({
    id: (name as string | undefined) ?? '',
    color: (color as string | undefined) ?? 'transparent',
    label: name as string,
  }));

export default extractLegendItemsFromPoints;
