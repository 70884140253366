import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NoRowsOverlay from '../NoRowsOverlay';
import { Loader } from '@verticeone/design-system/src';
import { useVendorProductsContext } from './VendorProductsContextProvider';

type NoRowsProps = {
  readOnlyMode?: boolean;
};

export const NoRows: FC<NoRowsProps> = ({ readOnlyMode }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });
  const { vendorProducts, isLoading } = useVendorProductsContext();

  if (isLoading) {
    return <Loader />;
  }

  if (vendorProducts.length === 0) {
    return <NoRowsOverlay text={t('NO_PRODUCTS_FOR_THIS_VENDOR')} />;
  }

  if (readOnlyMode) {
    return <NoRowsOverlay text={t('NO_PRODUCTS_SELECTED')} />;
  }

  return <NoRowsOverlay text={t('ADD_PRODUCTS_IF_KNOWN')} />;
};
