import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@verticeone/design-system/src';
import { useRecommendationData } from './Recommendation/useRecommendationData';

const TotalCostInfo = () => {
  const { data } = useRecommendationData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW' });

  if (!!data?.uncoverableCost) {
    return <Alert color="warning" size="M" subtitle={t('TOTAL_COST_INFO')} testId="total-cost-alert" variant="ghost" />;
  }
};

export default TotalCostInfo;
