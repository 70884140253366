import { Divider, useTheme } from '@mui/material';
import React from 'react';

const CardDivider = () => {
  const { palette } = useTheme();

  return <Divider sx={{ borderColor: palette.core.color3 }} />;
};

export default CardDivider;
