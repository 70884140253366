import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEc2ProcessorUsageQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getAccount } from '@vertice/slices/src/slices/account';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { toConstantCase } from '@verticeone/utils/strings';
import { getTableData } from '../../utils/graphDataUtils';

type TableData = Array<
  Record<'processor' | 'sum_normalized_usage_quantity' | 'percentage' | 'sum_cost' | 'effective_hourly_rate', string>
>;

export type EC2ProcessorUsageRow = {
  name: string;
  processor: string;
  normalizedUsageQuantity: number;
  effectiveHourlyRate: number;
  percentage: number;
  cost: number;
};

export type EC2ProcessorUsageData = EC2ProcessorUsageRow[];

const useEC2ProcessorUsageData = (): LoadableAdvanced<EC2ProcessorUsageData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EC2_PROCESSOR_USAGE.PROCESSORS' });
  const { accountId } = useSelector(getAccount);
  const pollingInterval = useRef(5000);

  const { data: rawData, error } = useEc2ProcessorUsageQuery(
    { accountId: accountId! },
    {
      skip: !accountId,
      pollingInterval: pollingInterval.current,
      selectFromResult: (result) => {
        if (result?.data?.athenaViewQuery?.__typename === 'DataTableResult' && result.data.athenaViewQuery.table) {
          pollingInterval.current = 0;
          return {
            ...result,
            data: getTableData(result.data.athenaViewQuery.table) as TableData,
          };
        }
        return { ...result, data: undefined };
      },
    }
  );

  const computed = useMemo(() => {
    if (!rawData) {
      return undefined;
    }

    return rawData.map((item) => ({
      name: t(toConstantCase(item.processor)) ?? item.processor,
      processor: item.processor,
      normalizedUsageQuantity: parseFloat(item.sum_normalized_usage_quantity),
      percentage: parseFloat(item.percentage) * 100,
      cost: parseFloat(item.sum_cost),
      effectiveHourlyRate: parseFloat(item.effective_hourly_rate),
    }));
  }, [t, rawData]);

  return {
    error,
    isEmpty: Boolean(computed && computed.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useEC2ProcessorUsageData;
