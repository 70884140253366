import { rgba } from 'polished';
import type { Color, PaletteColors, ColorVariants } from '../types';
import { colorPalette } from './colorPalette';
import { HalfColorVariants } from '../types';

type TransparentColorVariantKeys = HalfColorVariants | Exclude<ColorVariants, '100'>;
type TransparentPaletteColors = Extract<PaletteColors, 'black' | 'coolGray' | 'gray' | 'warmGray' | 'white'>;

const getColor = (baseColor: Color, alpha: number): Color => rgba(baseColor, alpha) as Color;

const getPalette = (color: Color) => ({
  5: getColor(color, 0.05),
  10: getColor(color, 0.1),
  15: getColor(color, 0.15),
  20: getColor(color, 0.2),
  25: getColor(color, 0.25),
  30: getColor(color, 0.3),
  35: getColor(color, 0.35),
  40: getColor(color, 0.4),
  45: getColor(color, 0.45),
  50: getColor(color, 0.5),
  55: getColor(color, 0.55),
  60: getColor(color, 0.6),
  65: getColor(color, 0.65),
  70: getColor(color, 0.7),
  75: getColor(color, 0.75),
  80: getColor(color, 0.8),
  85: getColor(color, 0.85),
  90: getColor(color, 0.9),
  95: getColor(color, 0.95),
});

export const transparencyPalette: Record<TransparentPaletteColors, Record<TransparentColorVariantKeys, Color>> = {
  black: getPalette(colorPalette.black.main),
  white: getPalette(colorPalette.white.main),
  gray: getPalette(colorPalette.gray['100'].main),
  coolGray: getPalette(colorPalette.coolGray['100'].main),
  warmGray: getPalette(colorPalette.warmGray['100'].main),
};
