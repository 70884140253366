import React, { useCallback, useMemo, useState } from 'react';
import Highcharts, {
  type Chart as HighchartsChart,
  type SeriesOptionsType,
  type TooltipFormatterContextObject,
} from 'highcharts';
import injectHighchartsIndicators from 'highcharts/indicators/indicators';
import injectHighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Box, Stack } from '@mui/material';

import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import extractLegend from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import type { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';

import { useGetXAxis, type XAxisTypes } from './xAxis';
import { useGetYAxis, type YAxisTypes } from './yAxis';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';

injectHighchartsMore(Highcharts);
injectHighchartsIndicators(Highcharts);

type LegendConfig = {
  enhance?: (items: Array<LegendItemData>) => Array<LegendItemData>;
};

type PercentageGraphProps = {
  legend?: boolean | LegendConfig;
  tooltip?: (points: Array<TooltipFormatterContextObject> | undefined) => React.ReactNode;
  xAxis: XAxisTypes | Array<XAxisTypes>;
  yAxis: YAxisTypes | Array<YAxisTypes>;
  series: Array<SeriesOptionsType>;
};

const PercentageGraph = ({ xAxis, yAxis, series, legend, tooltip }: PercentageGraphProps) => {
  const applyStyledHighcharts = useStyledHighcharts();
  const getXAxis = useGetXAxis();
  const getYAxis = useGetYAxis();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const options = useMemo(() => {
    return buildOptions([
      applyStyledHighcharts,
      (opts) =>
        mergeOptions(
          {
            chart: {
              height: 455,
              spacing: [0, 0, 24, 0],
              marginTop: 0,
            },
            plotOptions: {
              series: {
                states: {
                  inactive: { opacity: 1 },
                  hover: {
                    enabled: true,
                    halo: {
                      size: 0,
                    },
                  },
                },
                marker: { enabled: false },
                lineWidth: 1,
              },
            },
            tooltip: { shared: true },
            yAxis: getYAxis(yAxis),
            xAxis: getXAxis(xAxis),
            series,
          },
          opts
        ),
    ]);
  }, [applyStyledHighcharts, getXAxis, getYAxis, series, xAxis, yAxis]);

  return (
    <Stack position="relative">
      {!!legend && (
        <Box position="absolute" width="auto" height="auto" top="22px" right="24px" zIndex={1}>
          <Legend items={(legend as LegendConfig)?.enhance?.(extractLegend(options)) ?? extractLegend(options)} />
        </Box>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      {tooltip && (
        <HighchartTooltip chart={chart}>
          {({ points }) => <TooltipWrapper>{tooltip(points)}</TooltipWrapper>}
        </HighchartTooltip>
      )}
    </Stack>
  );
};

export default PercentageGraph;
