import React from 'react';
import { WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Content } from '@vertice/core/src/components/Dialog/Components';
import useNavigateToContractWithDelay from '../../../sharedHooks/useNavigateToContractWithDelay';
import { ConfirmationPanel } from '../../../SharedComponents/ConfirmationPanel';
import { useFormContext } from 'react-hook-form';
import { NewPurchaseFormData } from '../../types';

const PurchaseStarted: React.FC<WizardStepProps> = () => {
  const { watch } = useFormContext<NewPurchaseFormData>();
  const { newContractId } = watch();

  useNavigateToContractWithDelay(newContractId);

  return (
    <Content>
      <ConfirmationPanel headingTitleKey="INTAKE_FORM.NEW_PURCHASE_PURCHASE_STARTED.HEADING_TITLE" />
    </Content>
  );
};

export default PurchaseStarted;
