import React, { useState } from 'react';
import { useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { OKTA_INTEGRATION_ID, STEPS } from './Steps/common';
import { CollectStep } from './Steps/CollectStep';
import { ConnectionCheckStep } from './Steps/ConnectionCheckStep';
import { StartStep } from './Steps/StartStep';
import { ConnectionSuccessStep } from './Steps/ConnectionSuccessStep';
import { ConnectionErrorStep } from './Steps/ConnectionErrorStep';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import Wizard, { TestIntegrationParams } from 'pages/Preferences/Integrations/components/Wizard';
import DataSourceManagementStep from 'pages/Preferences/Integrations/components/DataSourceManagementStep';

const OktaSetupWizard = () => {
  const { accountId } = useAccountContext();
  const [step, setStep] = useState(STEPS.START);
  const [apiToken, setApiToken] = useState('');
  const [authServerURL, setAuthServerURL] = useState('');
  const [testingInProgress, setTestingInProgress] = useState(false);
  const [integrationParams, setIntegrationParams] = useState<TestIntegrationParams>({});

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const handleCancellation = async () => {
    if (!accountId || step === STEPS.START) return;
    await revokeAccountIntegration({ accountId, integrationId: OKTA_INTEGRATION_ID });
  };

  return (
    <Wizard activeStep={step} onCancel={handleCancellation}>
      <StartStep stepValue={STEPS.START} setStep={setStep} />
      <DataSourceManagementStep
        stepValue={STEPS.DATA_SOURCES}
        setStep={setStep}
        nextStep={STEPS.COLLECT_API_KEYS}
        previousStep={STEPS.START}
        accountId={accountId!}
        usersDataSourceCode="OKTA_USER_BASE"
        applicationDataSourceCodes={['OKTA_APPLICATIONS', 'OKTA_SSO_ACCESS_LOG']}
        setIntegrationParams={setIntegrationParams}
      />
      <CollectStep
        stepValue={STEPS.COLLECT_API_KEYS}
        setStep={setStep}
        setApiToken={setApiToken}
        apiToken={apiToken}
        setAuthServerURL={setAuthServerURL}
        authServerURL={authServerURL}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
        integrationParams={integrationParams}
      />
      <ConnectionCheckStep
        stepValue={STEPS.CHECK_CONNECTION}
        setStep={setStep}
        accountId={accountId!}
        setTestingInProgress={setTestingInProgress}
        testingInProgress={testingInProgress}
      />
      <ConnectionErrorStep
        stepValue={STEPS.CONNECTION_ERROR}
        setStep={setStep}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
      />
      <ConnectionSuccessStep
        stepValue={STEPS.CONNECTION_SUCCESS}
        setStep={setStep}
        accountId={accountId!}
        testingInProgress={testingInProgress}
        setTestingInProgress={setTestingInProgress}
      />
    </Wizard>
  );
};

export default OktaSetupWizard;
