import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { AccountUser, UserSettings } from '@vertice/slices';
import { UserRoleType } from '@vertice/core/src/constants/userRoles';
import useUpdateUser, { UpdateUserResponse } from '@vertice/hooks/src/useUpdateUser';
import useCreateUser, { CreateUserResponse } from '@vertice/hooks/src/useCreateUser';
import { getDefaultUserRole } from './utils';
import { RoleSelector } from './RoleSelector';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import {
  zodToHookFormValidator,
  email as emailSchema,
  phoneNumber as phoneNumberSchema,
} from '@verticeone/utils/validation';
import { z } from 'zod';
import { FormTimezoneSelect } from '@vertice/core/src/modules/forms/fields/FormTimezoneSelect/FormTimezoneSelect';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { Alert } from '@verticeone/design-system/src';
import { removeAllWhitespace } from '@verticeone/utils/strings';
import { Divider } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';

interface UserFormProps {
  formId: string;
  userData?: AccountUser;
  userSettings?: UserSettings;
  showTimeZone?: boolean;
  adminMode?: boolean;
  onUserSaved?: (contact: AccountUser) => void;
  setRequestInProgress?: (progress: boolean) => void;
  warningMessage?: string;
  isRequestInProgress: boolean;
  isEditMode: boolean;
}

interface UserFormFormType {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  userRole: string;
}

export interface FormType {
  settings: UserSettings;
  userData: UserFormFormType;
}

const UserFormEntry = createTypedFormEntry<FormType>();

const UserForm: FC<UserFormProps> = ({
  formId,
  userData,
  userSettings,
  showTimeZone,
  onUserSaved,
  adminMode,
  setRequestInProgress,
  warningMessage,
  isRequestInProgress,
}) => {
  const { t } = useTranslation();
  const { updateUser } = useUpdateUser();
  const { createUser, defaultUserSettings } = useCreateUser();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const defaultValues = {
    settings: {
      timeZone: userSettings?.timeZone || '',
    },
    userData: {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      phoneNumber: userData?.phoneNumber || '',
      jobTitle: userData?.accountUserProperties?.jobTitle || '',
      userRole: getDefaultUserRole(userData),
    },
  };

  const methods = useForm<FormType>({ defaultValues, mode: 'all' });
  const { handleSubmit, setValue, watch, formState } = methods;
  const role = watch('userData.userRole');

  const isEditMode = !!userData;

  const setProgress = (progress: boolean) => {
    if (setRequestInProgress) {
      setRequestInProgress(progress);
    }
  };

  const createUserWithData = async (formData: FormType) => {
    const { firstName, lastName, email, phoneNumber, jobTitle, userRole } = formData.userData;

    const userSettingsData = defaultUserSettings as UserSettings;
    if (formData.settings?.timeZone) {
      userSettingsData.timeZone = formData.settings.timeZone;
    }

    return createUser({
      firstName,
      lastName,
      email,
      ...(phoneNumber.trim().length && { phoneNumber: removeAllWhitespace(phoneNumber) }),
      role: userRole,
      jobTitle,
      userSettings: userSettingsData,
    });
  };

  const editUserWithData = async (formData: FormType) => {
    const { firstName, lastName, email, phoneNumber, jobTitle, userRole } = formData.userData;

    if (!userData) {
      return {};
    }

    return updateUser({
      userId: userData.userId,
      firstName,
      lastName,
      email,
      ...(phoneNumber.trim().length && { phoneNumber: removeAllWhitespace(phoneNumber) }),
      role: userRole,
      jobTitle,
      ...(formData.settings?.timeZone && { userSettings: formData.settings }),
    });
  };

  const onSubmit: SubmitHandler<FormType> = async (formData) => {
    const { firstName, lastName, email, phoneNumber, jobTitle } = formData.userData;

    setProgress(true);
    let response;
    if (userData) {
      response = await editUserWithData(formData);
    } else {
      response = await createUserWithData(formData);
    }
    setProgress(false);

    const successResponse = response as CreateUserResponse | UpdateUserResponse;
    if (successResponse?.data && onUserSaved) {
      onUserSaved({
        userId: successResponse.data?.userId!,
        userName: email,
        firstName,
        lastName,
        email,
        phoneNumber,
        accountUserProperties: {
          jobTitle,
        },
      });
    }
  };

  return (
    <>
      <Stack direction="row" gap={8} overflow="auto">
        <Stack flex={1}>
          <FormProvider {...methods}>
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
              <FormSection>
                <UserFormEntry
                  name="userData.firstName"
                  label={t('PREFERENCES.NEW_USER.LABELS.FIRST_NAME')}
                  component={FormTextField}
                  required
                  width={12}
                />
                <UserFormEntry
                  name="userData.lastName"
                  label={t('PREFERENCES.NEW_USER.LABELS.LAST_NAME')}
                  component={FormTextField}
                  required
                  width={12}
                />
                <UserFormEntry
                  name="userData.email"
                  label={t('PREFERENCES.NEW_USER.LABELS.EMAIL')}
                  component={FormTextField}
                  componentProps={{
                    validate: zodToHookFormValidator(emailSchema(t)),
                  }}
                  required
                  disabled={isEditMode}
                  width={12}
                />
                <UserFormEntry
                  name="userData.phoneNumber"
                  label={t('PREFERENCES.NEW_USER.LABELS.PHONE_NUMBER')}
                  component={FormTextField}
                  componentProps={{
                    validate: zodToHookFormValidator(phoneNumberSchema(t).nullish().or(z.literal(''))),
                  }}
                  width={12}
                />

                {showTimeZone && (
                  <UserFormEntry
                    name="settings.timeZone"
                    label={t('PREFERENCES.NEW_USER.LABELS.TIME_ZONE')}
                    component={FormTimezoneSelect}
                    width={12}
                  />
                )}

                <UserFormEntry
                  name="userData.jobTitle"
                  label={t('PREFERENCES.NEW_USER.LABELS.JOB_TITLE')}
                  component={FormTextField}
                  required
                  width={12}
                />
              </FormSection>
            </form>
          </FormProvider>
          {warningMessage && (
            <Stack py={6}>
              <Alert color="warning">{warningMessage}</Alert>
            </Stack>
          )}
        </Stack>

        <Stack flex={1} gap={1}>
          <TextFieldCaption label={t('PREFERENCES.NEW_USER.LABELS.ROLE_TYPE')} required size="XS" />
          <RoleSelector
            role={role as UserRoleType}
            onRoleChanged={(value: UserRoleType) => setValue('userData.userRole', value)}
            disabled={!adminMode && !isUserAdmin && isEditMode}
          />
        </Stack>
      </Stack>
      <Stack alignItems="center">
        <Stack width="100%">
          <Divider />
        </Stack>

        <Stack width="40%" py={4}>
          <Button
            type="submit"
            form={formId}
            isLoading={isRequestInProgress}
            disabled={isRequestInProgress || !formState.isValid}
            variant="solid"
            color="primary"
            fullWidth
          >
            {isEditMode ? t('PREFERENCES.NEW_USER.SAVE_CHANGES') : t('PREFERENCES.NEW_USER.ADD_NEW')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default UserForm;
