import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const accountsAdminApi = createApi({
  reducerPath: 'accountsAdminApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.ADMIN_ACCOUNTS),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
