import React from 'react';
import { sortBy } from 'lodash';

import {
  Order,
  useRioTableQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getChartData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';

import GenericGraph from '../../GenericGraph';
import { TARGET_COVERAGE_ID } from './series/useTargetCoverage';
import { useGetSeries } from './series';
import useTooltip from './tooltip';

type PercentageGraphProps = {
  code: string;
  target: number;
  series: {
    xAxis: string;
    value: string;
  };
};

const PercentageGraph = ({ code, target, series }: PercentageGraphProps) => {
  const { accountId } = useAccountContext();
  const getPercentageSeries = useGetSeries();
  const getTooltip = useTooltip();

  const {
    data: values,
    isFetching,
    error,
  } = useRioTableQuery(
    {
      code: code,
      columns: [series.xAxis, series.value],
      accountId: accountId!,
      orderColumns: [],
      order: Order.Desc,
      withLink: false,
    },
    {
      skip: !accountId,
      selectFromResult: (result) => {
        return {
          ...result,
          data: result.data?.testDataTableQuery?.table
            ? (sortBy(getChartData(result.data.testDataTableQuery.table), (item) => item[0]) as Array<[number, number]>)
            : [],
        };
      },
    }
  );

  return (
    <LoadableContentWrapper isLoading={isFetching} isEmpty={!values?.length} error={error}>
      <GenericGraph
        legend={{
          enhance: (items) => items.map((i) => (i.id === TARGET_COVERAGE_ID ? { ...i, outlined: true } : i)),
        }}
        xAxis={{ type: 'dateTime', accuracy: 'day' }}
        yAxis={{ type: 'percentage', maxTargetValue: target }}
        tooltip={getTooltip}
        series={getPercentageSeries([
          { series: 'historicalCoverage', data: values, target },
          { series: 'targetCoverage', data: values },
          { series: 'missedSavings', data: values, target },
          { series: 'discountedUsage', data: values },
        ])}
      />
    </LoadableContentWrapper>
  );
};

export default PercentageGraph;
