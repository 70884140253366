import { RecommendationStatus } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

export const STATUS_CHIP = {
  [RecommendationStatus.Discovered]: {
    color: 'primary',
    label: 'CLOUD.CLOUD_RECOMMENDATIONS.STATUS.DISCOVERED.ACTION',
  },
  [RecommendationStatus.Cancelled]: {
    color: 'neutral',
    label: 'CLOUD.CLOUD_RECOMMENDATIONS.STATUS.CANCELLED.ACTION',
  },
  [RecommendationStatus.Fulfilled]: {
    color: 'success',
    label: 'CLOUD.CLOUD_RECOMMENDATIONS.STATUS.FULFILLED.ACTION',
  },
  [RecommendationStatus.Inprogress]: {
    color: 'info',
    label: 'CLOUD.CLOUD_RECOMMENDATIONS.STATUS.INPROGRESS.ACTION',
  },
  [RecommendationStatus.EmptyResults]: {
    color: 'neutral',
    label: 'CLOUD.CLOUD_RECOMMENDATIONS.STATUS.EMPTY_RESULTS.ACTION',
  },
} as const;
