import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormConcessionsField, {
  type FormConcessionsFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormConcessionsField';
import React from 'react';
import { ContractFormData } from '../../types';
import { useWatch } from 'react-hook-form';

type Component = FormConcessionsFieldComponentType<ContractFormData>;

type ConcessionsContractFormEntryProps = Omit<
  ContractFormEntryProps<Component, Component>,
  'component' | 'writeComponent'
>;

const ConcessionsContractFormEntry = (options: ConcessionsContractFormEntryProps) => {
  const currency = useWatch<ContractFormData>({ name: 'parts.contractual.financial.baseCurrency' });
  return (
    <ContractFormEntry<Component, Component>
      {...options}
      component={FormConcessionsField}
      componentProps={{ currency }}
      writeComponent={FormConcessionsField}
      writeComponentProps={{ currency, editable: true }}
    />
  );
};

export default ConcessionsContractFormEntry;
