import { useRouteNavigate } from '@verticeone/router/src';
import { useEffect } from 'react';
import type { RoutesType } from '@verticeone/router/src/routes/RoutesType';

const useNavigateIf = (condition: boolean, route: RoutesType[keyof RoutesType]) => {
  const { navigate } = useRouteNavigate();

  useEffect(() => {
    if (condition) {
      navigate(route);
    }
  }, [condition, navigate, route]);
};

export default useNavigateIf;
