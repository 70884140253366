import React from 'react';
import Layout from './Layout';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import LastEvaluated from '../components/LastEvaluated/LastEvaluated';
import { useSpendBreakdownData } from './BreakdownChart/SpendBreakdownChart/useSpendBreakdownData';

type ContentProps = {
  updatedAt?: number;
};

const Content = ({ updatedAt }: ContentProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
          <BreadcrumbLink disabled>{t('CLOUD.NAVBAR.EDP')}</BreadcrumbLink>
        </Breadcrumbs>
        <Stack justifyContent="space-between" gap={2} direction="row">
          <Text variant="heading" size="M" color={palette.text.color1} tag="h1">
            {t('CLOUD.CLOUD_EDP.TITLE')}
          </Text>
          {updatedAt && <LastEvaluated updatedAt={new Date(updatedAt)} textSize={'S'} />}
        </Stack>
      </Stack>
      <Box px={10} position="relative">
        <Layout />
      </Box>
    </Stack>
  );
};

const CloudInsightsEDP = () => {
  const { data, isFetching } = useSpendBreakdownData();

  return (
    <LoadableAWSPageWrapper isLoading={isFetching}>
      <Content updatedAt={data?.updated_at} />
    </LoadableAWSPageWrapper>
  );
};

export default CloudInsightsEDP;
