import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import { SOONEST_TARGET_SIGN_DATE } from '../constants';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

type TargetSignDateFormEntryProps = {
  width?: number;
};

export const TargetSignDateFormEntry: FC<TargetSignDateFormEntryProps> = ({ width = 12 }) => {
  const { t } = useTranslation();
  return (
    <TaskFormEntry<any, typeof FormDateField>
      name="targetSignDate"
      tooltip={{
        title: t('ENTITIES.CONTRACT.TOOLTIPS.TARGET_SIGN_DATE'),
      }}
      label={t('ENTITIES.CONTRACT.LABELS.TARGET_SIGN_DATE')}
      component={FormDateField}
      componentProps={{
        minDate: SOONEST_TARGET_SIGN_DATE,
        // TODO: Move to Zod schema
        validate: {
          isAfterOrExactly30DaysFromNow: (value) => {
            if (value && dayjs(value).isBefore(SOONEST_TARGET_SIGN_DATE)) {
              return t('ENTITIES.CONTRACT.TOOLTIPS.TARGET_SIGN_DATE');
            }
            return true;
          },
        },
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
      width={width}
    />
  );
};
