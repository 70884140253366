import { useTranslation } from 'react-i18next';
import { Filter } from '../types';
import GenericTextFilter from './GenericTextFilter';

const SigningEntityFilter = () => {
  const { t } = useTranslation();

  return (
    <GenericTextFilter
      name={Filter.SIGNING_ENTITY}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.SEARCH_SIGNING_ENTITY')}
      testId={'signing-entity-filter'}
    />
  );
};

export default SigningEntityFilter;
