import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import type { RiListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import type { EC2DataEnhanced } from './useEC2Data';
import type { ShoppingCartItemType } from '../../components/providers/ShoppingCartProvider';
import { calculateCosts, SECOND_IN_MILLISECONDS } from '../../../utils';
import type { ChartSeriesDefinition, OfferingData } from '../../../types';

export const parsePreInstalledSw = (preInstalledSw: string) => {
  switch (preInstalledSw) {
    case 'SQL Web':
      return 'SQL Server Web';
    case 'SQL Std':
      return 'SQL Server Standard';
    case 'SQL Ent':
      return 'SQL Server Enterprise';
    default:
      return preInstalledSw;
  }
};

export const parseOperatingSystem = (operatingSystem: string, preInstalledSw: string) => {
  switch (operatingSystem) {
    case 'Windows':
      switch (preInstalledSw) {
        case 'SQL Web':
        case 'SQL Std':
        case 'SQL Ent':
          return `Windows with ${parsePreInstalledSw(preInstalledSw)}`;
        default:
          return 'Windows';
      }
    case 'Linux':
      switch (preInstalledSw) {
        case 'SQL Web':
        case 'SQL Std':
        case 'SQL Ent':
          return `Linux with ${parsePreInstalledSw(preInstalledSw)}`;
        default:
          return 'Linux/UNIX';
      }
    case 'RHEL':
      switch (preInstalledSw) {
        case 'SQL Web':
        case 'SQL Std':
        case 'SQL Ent':
          return `RHEL with ${parsePreInstalledSw(preInstalledSw)}`;
        default:
          return 'Red Hat Enterprise Linux';
      }
    case 'Red Hat Enterprise Linux with HA':
      switch (preInstalledSw) {
        case 'SQL Web':
        case 'SQL Std':
        case 'SQL Ent':
          return `RHEL with HA and ${parsePreInstalledSw(preInstalledSw)}`;
        default:
          return 'RHEL with HA';
      }
    case 'SUSE':
      return 'SUSE Linux';
    case 'Ubuntu Pro':
      return 'Ubuntu Pro';
    default:
      return operatingSystem;
  }
};

export type Ec2RiOffering = OfferingData<{
  offeringClass: string;
}>;

export const parseEc2RiOfferings = (
  data: RiListOfferingsQuery | undefined,
  instance: EC2DataEnhanced,
  cartItems?: ShoppingCartItemType[] | null
): Ec2RiOffering[] => {
  if (
    data?.riListOfferingsQuery?.__typename !== 'RIOfferingsEC2Result' ||
    data?.riListOfferingsQuery?.items === undefined ||
    data?.riListOfferingsQuery?.items?.length === 0
  ) {
    return [];
  }

  const items = !!cartItems
    ? data.riListOfferingsQuery.items.filter((item) =>
        cartItems?.some((cardItem) => cardItem.id === item.reservedInstancesOfferingId)
      )
    : data?.riListOfferingsQuery?.items;

  // return RIOfferingsEC2Result result
  return items.map(
    ({
      reservedInstancesOfferingId,
      instanceType,
      duration,
      offeringType,
      offeringClass,
      recurringCharges,
      currencyCode,
      fixedPrice,
      usagePrice,
    }) => {
      const quantity =
        cartItems?.find((item) => item.id === reservedInstancesOfferingId)?.quantity ||
        instance.proposedNumberOfInstances;

      const { normalizedUnits, durationOnDemandCost, riCost, expectedSavings, upfrontCost, monthlyCost } =
        calculateCosts({
          duration,
          onDemandCost: instance.onDemandCost,
          onDemandUsage: instance.onDemandUsage,
          riInstanceNormalizationFactor: instance.riInstanceNormalizationFactor,
          recurringCharges,
          fixedPrice,
          usagePrice,
          quantity,
        });

      return {
        reservedInstancesOfferingId,
        instanceType,
        term: Math.round(dayjs.duration(duration * SECOND_IN_MILLISECONDS).asMonths()),
        offeringType,
        offeringClass: capitalize(offeringClass),
        normalizedUnits,
        onDemandCost: durationOnDemandCost,
        riCost,
        expectedSavings,
        upfrontCost,
        monthlyCost,
        quantity,
        currencyCode,
      };
    }
  );
};

export const chartSeries: ChartSeriesDefinition[] = [
  {
    id: 'on_demand_usage',
    type: 'line',
    valueGetter: (item) => Number(item.onDemandUsage),
  },
  {
    id: 'sp_usage',
    type: 'column',
    valueGetter: (item) => Number(item.spUsage || 0),
  },
  {
    id: 'total_usage',
    type: 'spline',
    valueGetter: (item) => Number(item.reservedUsage) + Number(item.onDemandUsage) + Number(item.spUsage),
    zIndex: 1,
  },
  {
    id: 'reserved_unused',
    type: 'column',
    valueGetter: (item) =>
      Number(item.reservedCapacity) ? Number(item.reservedCapacity) - Number(item.reservedUsage) : null,
  },
  {
    id: 'reserved_usage',
    type: 'column',
    valueGetter: (item) => Number(item.reservedUsage),
  },
];
