import React, { useCallback, useMemo, useState } from 'react';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import injectHighchartsIndicators from 'highcharts/indicators/indicators';
import injectHighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Box, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { millisecondsInHour } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { rgba } from 'polished';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/theme';
import {
  dayInMonthFormatter,
  yLabelPercentageFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useCustomCrosshair } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useCustomCrosshair';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { formatPercentage } from '@verticeone/utils/formatting';

injectHighchartsMore(Highcharts);
injectHighchartsIndicators(Highcharts);

type ReservedInstancesGraphProps = {
  target: number;
  values: [number, number | undefined][];
};

const SERIES_TARGET = 'target';

const ReservedInstancesGraph = ({ target, values }: ReservedInstancesGraphProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { locale } = useLocaleContext();
  const tooltipXAxisValueFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const applyCustomCrosshair = useCustomCrosshair(palette.core.color6, 1);

  const optionOverrides = useMemo(() => {
    const areaRangeData = values.map((value) => [...value, target]);

    return buildOptions([
      applyCustomCrosshair,
      (opts) =>
        mergeOptions(
          {
            chart: {
              height: 455,
              spacing: [0, 0, 24, 0],
              marginTop: 0,
            },
            plotOptions: {
              series: {
                states: {
                  inactive: { opacity: 1 },
                  hover: {
                    enabled: true,
                    halo: {
                      size: 0,
                    },
                  },
                },
                marker: { enabled: false },
                lineWidth: 1,
              },
            },
            yAxis: {
              min: 0,
              max: 1.1,
              tickInterval: 0.1,
              showLastLabel: false,
              plotLines: [
                { value: 0, width: 1, color: palette.core.color6, zIndex: 3 },
                { value: target, width: 2, dashStyle: 'ShortDash', color: palette.core.color6, zIndex: 3 },
              ],
              labels: {
                formatter: yLabelPercentageFormatter(palette, locale),
              },
            },
            xAxis: {
              type: 'datetime',
              labels: { formatter: dayInMonthFormatter },
              minTickInterval: 7 * 24 * millisecondsInHour,
              showFirstLabel: false,
            },
            tooltip: { shared: true },
            series: [
              {
                name: t('RESERVED_INSTANCES.HISTORICAL_COVERAGE'),
                type: 'line',
                data: values,
                zIndex: 2,
                color: palette.error.color1,
                zones: [
                  {
                    value: target,
                    color: palette.error.color1,
                  },
                  {
                    color: palette.success.color2,
                  },
                ],
              },
              {
                id: SERIES_TARGET,
                name: t('RESERVED_INSTANCES.TARGET_COVERAGE'),
                type: 'line',
                color: palette.core.color6,
                visible: false,
                enableMouseTracking: false,
              },
              {
                name: t('RESERVED_INSTANCES.MISSED_SAVINGS'),
                type: 'arearange',
                data: areaRangeData,
                // Opacity used for drawing legend & tooltip color squares
                color: rgba(palette.error.color1, 0.2),
                lineWidth: 0,
                // Opacity used for drawing the actual data
                fillOpacity: 0.2,
                zIndex: 1,
                zones: [
                  {
                    value: target,
                    color: rgba(palette.error.color1, 0.2),
                  },
                  {
                    color: rgba(palette.success.color2, 0.2),
                  },
                ],
                enableMouseTracking: false,
              },
              {
                name: t('RESERVED_INSTANCES.DISCOUNTED_USAGE'),
                type: 'area',
                data: values,
                zIndex: 1,
                fillOpacity: 0.2,
                color: rgba(palette.success.color2, 0.2),
              },
            ],
          },
          opts
        ),
    ]);
  }, [applyCustomCrosshair, locale, palette, target, values, t]);
  const options = useStyledHighcharts(optionOverrides);

  return (
    <Stack position="relative">
      <Box position="absolute" width="auto" height="auto" top="22px" right="24px" zIndex={1}>
        <Legend
          items={extractLegendItemsFromSeries(options).map((legendItem) => {
            if (legendItem.id === SERIES_TARGET) {
              return { ...legendItem, outlined: true };
            }
            return legendItem;
          })}
        />
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => (
          <TooltipWrapper>
            {points
              // Show tooltip only for coverage data
              ?.filter((point) => {
                const { type } = point.series;
                return type === 'line';
              })
              .map((point, pointIndex) => {
                const formattedValue = formatPercentage(Number(point.y!), { locale });
                return (
                  <TooltipValueWithTimeInfo
                    value={formattedValue}
                    timeInfo={pointIndex === 0 ? tooltipXAxisValueFormatter.format(new Date(point.x!)) : undefined}
                    key={point.series.name}
                  />
                );
              })}
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default ReservedInstancesGraph;
