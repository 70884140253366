import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@verticeone/design-system/src';
import { DataGrid } from '@verticeone/design-system/src';
import { NoData } from '@verticeone/design-system/src';

import { WorkflowRow } from './types';
import { useWorkflowsTableColumns } from './useWorkflowsTableColumns';

type WorkflowsTableProps = {
  workflows: WorkflowRow[];
  serviceRef?: string;
};

export const WorkflowsTable: FC<WorkflowsTableProps> = ({ workflows, serviceRef }) => {
  const { t } = useTranslation();
  const columns = useWorkflowsTableColumns(serviceRef!);

  return (
    <Card>
      <DataGrid<WorkflowRow>
        color="neutral"
        columns={columns}
        rows={workflows}
        sortingMode="client"
        disableRowSelectionOnClick
        getRowHeight={() => 60}
        autoHeight
        noBorder
        noBorderRadius
        noRowsOverlayHeight={200}
        slots={{
          noRowsOverlay: () => <NoData header={t(`INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.NO_WORKFLOWS`)} />,
        }}
        initialState={{
          sorting: {
            sortModel: [
              { field: 'isDefault', sort: 'desc' },
              { field: 'workflowName', sort: 'asc' },
            ],
          },
          columns: {
            columnVisibilityModel: {
              isDefault: false,
            },
          },
        }}
      ></DataGrid>
    </Card>
  );
};
