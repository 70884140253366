import { Middleware } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import smartlookClient from 'smartlook-client';
import { addNotification } from '../slices/Notifications';

declare function cwr(operation: string, payload: any): void;

const get409ErrorMessage = (endpoint: string) =>
  ({
    adminCreateUser: 'SNACKBAR.ERRORS.USER_EXISTS',
    createNewAccountUser: 'SNACKBAR.ERRORS.USER_EXISTS',
    removeUserRole: 'SNACKBAR.ERRORS.DELETE_USER',
  }[endpoint] || 'SNACKBAR.ERRORS.409');

export const errorHandlerMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.payload?.status >= 300) {
      const err = new Error(action.payload.status);
      const { endpointName, originalArgs } = action.meta.arg;
      err.name = JSON.stringify({ endpointName, originalArgs });

      if (typeof cwr === 'function') {
        cwr('recordError', err);
      }
      if (smartlookClient.initialized()) {
        smartlookClient.error(err);
      }
    }

    if (action.payload?.status === 401) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Auth.signOut();
    } else if (action.payload?.status === 409) {
      dispatch(
        addNotification({ textKey: get409ErrorMessage(action.meta.arg.endpointName), type: 'error', id: '409' })
      );
    } else if (action.payload?.status === 403) {
      dispatch(addNotification({ textKey: 'SNACKBAR.ERRORS.403', type: 'error', id: '403' }));
    } else if (action.payload?.status >= 400 && action.payload?.status < 500) {
      const withoutToast = ['migrateUserSso'].some((actionName) => actionName === action.meta.arg.endpointName);
      if (!withoutToast) {
        dispatch(addNotification({ textKey: 'SNACKBAR.ERRORS.4XX', type: 'error', id: '4XX' }));
      }
    } else if (
      (action.payload?.status >= 500 && action.payload?.status < 600) ||
      action.payload?.status === 'FETCH_ERROR'
    ) {
      dispatch(addNotification({ textKey: 'SNACKBAR.ERRORS.5XX', type: 'error', id: '5XX' }));
    }

    return next(action);
  };
