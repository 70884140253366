import {
  PhoneNumber,
  Timestamp,
  UserSettings,
  UpdateAccountUserApiResponse,
  UpdateUserApiResponse,
  useAdminUpdateUserMutation,
  useUpdateAccountUserMutation,
  useAdminUpdateUserSettingsMutation,
  UpdateUserSettingsApiResponse,
  useListAccountUsersQuery,
} from '@vertice/slices';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

interface UpdateUserProps {
  userId: string;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: PhoneNumber;
  retentionDate?: Timestamp;
  calendlyUrl?: string;
  role?: string;
  jobTitle?: string;
  userSettings?: UserSettings;
}

type userKeyProps = keyof UpdateUserProps;

export type UpdateUserResponse = {
  data: {
    userId: string;
  };
};

export type UpdateUserError = {
  error: string;
};

const useUpdateUser = () => {
  const { accountId } = useAccountContext();
  const [updateUser, { isLoading: isUserUpdating }] = useAdminUpdateUserMutation();
  const [updateAccountUser, { isLoading: isAccountUserUpdating }] = useUpdateAccountUserMutation();
  const [updateUserSettings, { isLoading: isUserSettingsUpdating }] = useAdminUpdateUserSettingsMutation();

  const { refetch } = useListAccountUsersQuery({ accountId: accountId! }, { skip: !accountId });

  const extract = (object: UpdateUserProps, fields: userKeyProps[]) =>
    fields.reduce((acc: any, key: userKeyProps) => {
      const value = object[key];
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

  const isEmpty = (obj: any) => Object.keys(obj).length === 0;

  const update = async (userData: UpdateUserProps): Promise<UpdateUserResponse | UpdateUserError> => {
    const userId = userData.userId;
    const userInfo = extract(userData, [
      'email',
      'firstName',
      'middleName',
      'lastName',
      'phoneNumber',
      'retentionDate',
    ]);
    const userProperties = extract(userData, ['calendlyUrl']);

    const accountUserInfo = extract(userData, ['role']);
    const accountUserProperties = extract(userData, ['jobTitle']);

    const userSettings = userData.userSettings;

    const userBody = {
      ...userInfo,
      ...(!isEmpty(userProperties) && { userProperties }),
    };

    const asyncTasks = [];
    if (accountId && userId && !isEmpty(userBody)) {
      asyncTasks.push(
        updateUser({
          accountId,
          userId,
          body: userBody,
        })
      );
    }

    const userAccountBody = {
      ...accountUserInfo,
      ...(!isEmpty(accountUserProperties) && { accountUserProperties }),
    };

    if (accountId && userId && !isEmpty(userAccountBody)) {
      asyncTasks.push(
        updateAccountUser({
          accountId,
          userId,
          body: userAccountBody,
        })
      );
    }

    if (accountId && userId && userSettings && !isEmpty(userSettings)) {
      asyncTasks.push(
        updateUserSettings({
          accountId,
          userId,
          userSettings,
        })
      );
    }

    const responses = await Promise.all(asyncTasks);
    const error = responses.some(
      (
        response:
          | { data: UpdateUserApiResponse | UpdateAccountUserApiResponse | UpdateUserSettingsApiResponse | undefined }
          | { error: FetchBaseQueryError | SerializedError }
      ) => (response as { error: FetchBaseQueryError }).error
    );
    if (!error) {
      await refetch();
    }
    return error ? { error: `Failed to update user with id ${userId}` } : { data: { userId } };
  };

  return {
    updateUser: update,
    isLoading: isUserUpdating || isAccountUserUpdating || isUserSettingsUpdating,
  };
};

export default useUpdateUser;
