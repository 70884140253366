import React from 'react';
import { Stack, Typography, Divider, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TooltipTotalProps = {
  label?: string;
  value: string;
};

const TooltipTotal = ({ label: labelRaw, value }: TooltipTotalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const label = labelRaw ?? t('TOOLTIP.TOTAL');

  return (
    <Stack direction="column" gap="7px">
      <Divider sx={{ borderColor: palette.global.color.coolGray['70'].main }} />
      <Stack direction="row" gap="7px" justifyContent="space-between">
        <Typography variant="body-regular-s">{label}</Typography>
        <Typography variant="body-bold-s">{value}</Typography>
      </Stack>
    </Stack>
  );
};

export default TooltipTotal;
