import { Vendor } from './types';
import { ContractPartContractualVendor } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const contractVendorToVendor = (contractVendor: ContractPartContractualVendor): Vendor => {
  if (contractVendor.vendorType === 'PREDEFINED' && contractVendor.vendorId) {
    return {
      type: 'PREDEFINED',
      id: contractVendor.vendorId!,
      name: contractVendor.vendorName,
    };
  } else if (contractVendor.vendorType === 'INLINE' && contractVendor.vendorName) {
    return {
      type: 'INLINE',
      name: contractVendor.vendorName,
    };
  }

  throw new Error('Invalid contract vendor');
};
