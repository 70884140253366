import React, { useState } from 'react';
import { FieldPath, useFormContext, Validate } from 'react-hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@verticeone/design-system/src';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type PasswordInputProps<FormDataType extends FieldValues> = Omit<TextFieldProps, 'id'> & {
  // TODO: create a separate name property, fix type-safety
  id: FieldPath<FormDataType>;
  required?: boolean;
  error?: boolean;
  validate?: Validate<string, FormDataType> | Record<string, Validate<string, FormDataType>>;
};

const PasswordInput = <FormDataType extends FieldValues = never>({
  id,
  required,
  error,
  validate,
  ...otherProps
}: PasswordInputProps<FormDataType>) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    register,
    formState: { isSubmitted, errors },
  } = useFormContext<FormDataType>();

  const handleClickShowPassword = () => setIsShowPassword((value) => !value);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

  return (
    <TextField
      {...register(id, {
        required: required ?? false,
        validate: validate,
      })}
      id={id}
      fullWidth
      // TODO: Fix typing
      error={error || (isSubmitted && !!(errors as any)[id]?.message)}
      helperText={isSubmitted && (errors as any)[id]?.message}
      InputProps={{
        ...(otherProps?.InputProps || {}),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {isShowPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={isShowPassword ? 'text' : 'password'}
      {...otherProps}
    />
  );
};

export default React.memo(PasswordInput) as unknown as typeof PasswordInput;
