import React from 'react';
import { useTranslation } from 'react-i18next';
import { History } from '@mui/icons-material';
import RITransactions from './RITransactions';
import useLoadMoreRiPurchaseData from './useLoadMoreRiPurchaseData';

const RITransactionHistory = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_PURCHASE' });
  const { data, refetch, onLoadMore, ...loadingStatus } = useLoadMoreRiPurchaseData();

  return (
    <RITransactions
      isPending={false}
      title={t('HISTORY_TITLE')}
      icon={History}
      testId="ri-transaction-history"
      visibleRows={10}
      onLoadMore={onLoadMore}
      data={data}
      {...loadingStatus}
    />
  );
};

export default RITransactionHistory;
