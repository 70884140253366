import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Select } from '@verticeone/design-system/src';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { ContractValue, Filter } from '../types';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { THRESHOLD_CURRENCY } from '../hooks/useContractValueFilter';

type ThresholdFilterOption = { value: ContractValue; label: string };

export const ThresholdFilter: FC = () => {
  const { t } = useTranslation();
  const { data: accountSettings } = useAccountSettings();
  const formatCurrency = useFormatCurrency();
  const formattedThreshold = useMemo(() => {
    const thresholdValue = accountSettings?.verticeManagedContractThreshold;
    return thresholdValue
      ? formatCurrency(thresholdValue, { currency: THRESHOLD_CURRENCY, maximumFractionDigits: 0 })
      : undefined;
  }, [accountSettings, formatCurrency]);

  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastContractValues = setFilterValue(Filter.THRESHOLD);

  const options: ThresholdFilterOption[] = useMemo(
    () => [
      {
        value: ContractValue.ABOVE_THRESHOLD,
        label: formattedThreshold
          ? t('ENTITIES.FILTERS.THRESHOLD_FILTER.ABOVE_THRESHOLD_WITH_VALUE', { value: formattedThreshold })
          : t('ENTITIES.FILTERS.THRESHOLD_FILTER.ABOVE_THRESHOLD'),
      },
      {
        value: ContractValue.BELOW_THRESHOLD,
        label: t('ENTITIES.FILTERS.THRESHOLD_FILTER.BELOW_THRESHOLD'),
      },
    ],
    [t, formattedThreshold]
  );

  const onChange = (selection: MultiValue<ThresholdFilterOption>) =>
    setLastContractValues(selection.length ? selection.map((option) => option.value) : undefined);

  return (
    <Stack minWidth={240}>
      <Select<ThresholdFilterOption, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={options}
        value={options?.filter(({ value }) => filterValues?.[Filter.THRESHOLD]?.includes(value))}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.CONTRACT_VALUE')}
        onChange={onChange}
        testId={'threshold-filter'}
      />
    </Stack>
  );
};
