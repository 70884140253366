import { createContext, useContext } from 'react';
import AuthUser from './AuthUser';

export enum AuthType {
  AMPLIFY = 'amplify',
  ASSUME_ROLE = 'assumeRole',
}

export type AuthContextData = {
  getJwtToken: () => Promise<string>;
  signOut: () => Promise<void>;
  user?: AuthUser;
  /** Type of the authentication method (e.g. 'amplify', 'assumeRole', ...) */
  type: AuthType;
};

const AuthenticationContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthenticationContextProvider = AuthenticationContext.Provider;

export const useAuthentication = () => useContext(AuthenticationContext);
