import { rgba } from 'polished';
import type { PaletteColors, ColorVariants, HalfColorVariants } from '../types';
import { colorPalette } from './colorPalette';
import { transparencyPalette } from './transparencyPalette';

type Angle = '45deg' | '135deg' | '225deg' | '315deg';
type Variant = Extract<PaletteColors, 'white' | 'black'>;
type Alpha = Extract<ColorVariants | HalfColorVariants, '5' | '10' | '20' | '40' | '60' | '80'>;

const getGradient = (variant: Variant, alpha: Alpha, angle: Angle) => {
  const deg = ['45deg', '225deg'].includes(angle) ? '45deg' : '135deg';
  const isReverse = ['225deg', '315deg'].includes(angle);
  const color1 = rgba(colorPalette[variant].main, 0);
  const color2 = transparencyPalette[variant][alpha];

  return `linear-gradient(${deg}, ${isReverse ? color2 : color1} 0%, ${isReverse ? color1 : color2} 100%)`;
};

export const illustrationPalette: Record<Variant, Record<Alpha, Record<Angle, string>>> = {
  black: {
    5: {
      '45deg': getGradient('black', '5', '45deg'),
      '135deg': getGradient('black', '5', '135deg'),
      '225deg': getGradient('black', '5', '225deg'),
      '315deg': getGradient('black', '5', '315deg'),
    },
    10: {
      '45deg': getGradient('black', '10', '45deg'),
      '135deg': getGradient('black', '10', '135deg'),
      '225deg': getGradient('black', '10', '225deg'),
      '315deg': getGradient('black', '10', '315deg'),
    },
    20: {
      '45deg': getGradient('black', '20', '45deg'),
      '135deg': getGradient('black', '20', '135deg'),
      '225deg': getGradient('black', '20', '225deg'),
      '315deg': getGradient('black', '20', '315deg'),
    },
    40: {
      '45deg': getGradient('black', '40', '45deg'),
      '135deg': getGradient('black', '40', '135deg'),
      '225deg': getGradient('black', '40', '225deg'),
      '315deg': getGradient('black', '40', '315deg'),
    },
    60: {
      '45deg': getGradient('black', '60', '45deg'),
      '135deg': getGradient('black', '60', '135deg'),
      '225deg': getGradient('black', '60', '225deg'),
      '315deg': getGradient('black', '60', '315deg'),
    },
    80: {
      '45deg': getGradient('black', '80', '45deg'),
      '135deg': getGradient('black', '80', '135deg'),
      '225deg': getGradient('black', '80', '225deg'),
      '315deg': getGradient('black', '80', '315deg'),
    },
  },
  white: {
    5: {
      '45deg': getGradient('white', '5', '45deg'),
      '135deg': getGradient('white', '5', '135deg'),
      '225deg': getGradient('white', '5', '225deg'),
      '315deg': getGradient('white', '5', '315deg'),
    },
    10: {
      '45deg': getGradient('white', '10', '45deg'),
      '135deg': getGradient('white', '10', '135deg'),
      '225deg': getGradient('white', '10', '225deg'),
      '315deg': getGradient('white', '10', '315deg'),
    },
    20: {
      '45deg': getGradient('white', '20', '45deg'),
      '135deg': getGradient('white', '20', '135deg'),
      '225deg': getGradient('white', '20', '225deg'),
      '315deg': getGradient('white', '20', '315deg'),
    },
    40: {
      '45deg': getGradient('white', '40', '45deg'),
      '135deg': getGradient('white', '40', '135deg'),
      '225deg': getGradient('white', '40', '225deg'),
      '315deg': getGradient('white', '40', '315deg'),
    },
    60: {
      '45deg': getGradient('white', '60', '45deg'),
      '135deg': getGradient('white', '60', '135deg'),
      '225deg': getGradient('white', '60', '225deg'),
      '315deg': getGradient('white', '60', '315deg'),
    },
    80: {
      '45deg': getGradient('white', '80', '45deg'),
      '135deg': getGradient('white', '80', '135deg'),
      '225deg': getGradient('white', '80', '225deg'),
      '315deg': getGradient('white', '80', '315deg'),
    },
  },
};
