import { PredefinedFormDef } from '../shared/types';
import { RequirementsGatheringForm } from './RequirementsGatheringForm';
import { zodSchema, FormData } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/(purchase\/)?requirementsGathering\/v\d+$/,
  component: RequirementsGatheringForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    vendor: getValue('vendor'),
    products: getValue('products', []),
    additionalNotes: getValue('additionalNotes'),
    department: getValue('department'),
    contractCurrency: getValue('contractCurrency'),
    targetSignDate: getValue('targetSignDate'),
    approvedBudget: getValue('approvedBudget'),
    verticeNegotiationRequested: getValue('verticeNegotiationRequested', false),
    internalNegotiationRequested: getValue('internalNegotiationRequested', false),
    signingEntity: getValue('signingEntity'),
    desiredContractLength: getValue('desiredContractLength'),

    legalReviewRequired: getValue('legalReviewRequired'),
    securityReviewRequired: getValue('securityReviewRequired'),
    piiExpectedToBeHeld: getValue('piiExpectedToBeHeld'),

    contactVendorDirectly: getValue('contactVendorDirectly'),
    discussMultiYearDeals: getValue('discussMultiYearDeals'),
    discussPlannedGrowth: getValue('discussPlannedGrowth'),
    discussCompetitors: getValue('discussCompetitors'),
    discussCaseStudies: getValue('discussCaseStudies'),
    parentAccountId: getValue('parentAccountId'),
  }),
};
