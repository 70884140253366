import dayjs from 'dayjs';
import { sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import type { RiRecurringPrice } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import * as Translations from '@vertice/i18n/src/i18n/en.json';
import { subDays, format } from 'date-fns';
import { keyBy, range } from 'lodash';

export const DAY_IN_HOURS = 24;
export const HOUR_IN_SECONDS = 3600;
export const SECOND_IN_MILLISECONDS = 1000;

type CalculateCostsParams = {
  duration: number;
  onDemandCost: number;
  onDemandUsage: number;
  riInstanceNormalizationFactor: number;
  recurringCharges?: RiRecurringPrice[];
  fixedPrice: number;
  usagePrice: number;
  quantity: number;
};

export const calculateCosts = ({
  duration,
  onDemandCost,
  onDemandUsage,
  riInstanceNormalizationFactor,
  recurringCharges,
  fixedPrice,
  quantity,
}: CalculateCostsParams) => {
  const durationHours = duration / HOUR_IN_SECONDS;
  const durationDays = durationHours / DAY_IN_HOURS;

  const hourlyOnDemandRate = onDemandCost / onDemandUsage;
  const normalizedUnits = DAY_IN_HOURS * quantity * riInstanceNormalizationFactor;
  const durationOnDemandCost = durationDays * hourlyOnDemandRate * normalizedUnits;

  const recurringChargesDurationCosts =
    recurringCharges?.map(({ amount }) => {
      const amountParsed = amount ?? 0;
      return durationHours * amountParsed;
    }) ?? [];

  const totalRecurringCost = sum(recurringChargesDurationCosts) * quantity;
  const upfrontCost = fixedPrice * quantity;
  const riCost = upfrontCost + totalRecurringCost;
  const expectedSavings = durationOnDemandCost - riCost;
  const durationInMonths = dayjs.duration(duration * SECOND_IN_MILLISECONDS).asMonths();
  const monthlyCost = totalRecurringCost / durationInMonths;

  return {
    normalizedUnits,
    durationOnDemandCost,
    riCost,
    expectedSavings,
    upfrontCost,
    monthlyCost,
  };
};

export const useCreateLabel = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.CHIPS.LABEL' });

  return (label: keyof typeof Translations.CLOUD.RIO.OPTIMIZE.CHIPS.LABEL, value?: string, defaultValue?: string) => ({
    label: t(label as string),
    value: value ?? defaultValue,
  });
};

export const DATE_FORMAT = 'yyyy-MM-dd';
export const PAST_PERIOD = 45;

export const fillMissingPastReservationsData = <DataType extends { day: number }>(
  data: Array<DataType>,
  startDate: Date,
  numberOfDays = PAST_PERIOD
): Array<DataType> => {
  const dataByDate = keyBy(data, (p) => format(new Date(p.day), DATE_FORMAT));

  return range(numberOfDays)
    .map((i) => {
      const dayStartStr = format(subDays(startDate, i), DATE_FORMAT);

      return (
        dataByDate[dayStartStr] ?? {
          day: new Date(dayStartStr).getTime(),
          instanceFamily: '',
          reservedCapacity: 0,
          operatingSystem: '',
          region: '',
          licenseModel: '',
          onDemandUsage: 0,
          reservedUsage: 0,
          spUsage: 0,
        }
      );
    })
    .sort((a, b) => a.day - b.day);
};
