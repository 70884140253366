import React from 'react';
import { Grid, Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Card, CardContent, LoadableComponent } from '@vertice/components';
import { prepareDataForApplication } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import {
  UsageBox,
  UsageBoxBadge,
  UsageBoxContent,
  UsageBoxTitle,
} from '@vertice/slices/src/slices/UsageAnalytics/components/UsageBox';

import type { Severity } from '@vertice/slices/src/types/Severity';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

type UsersTileProps = {
  isLoading: boolean;
  application?: ApplicationWithMeasureReports;
};

type StyledLastDaysProps = {
  severity: Severity;
  theme: Theme;
};

const StyledLastDays = styled((props) => <Typography {...props} variant="caption-m" />)<StyledLastDaysProps>(
  ({ severity, theme }) => ({
    display: 'inline-flex',
    color: theme.palette[severity].color1,
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1rem',
  })
);

const StyledUsageBoxContent = styled(UsageBoxContent)({
  margin: '0',
});

const StyledUsageBoxBadge = styled(UsageBoxBadge)({
  '&, .MuiTypography-root': {
    fontSize: '1.25rem',
  },
});

const StyledUsersTooltip = styled((props) => <Typography {...props} variant="body-regular-s" />)(({ theme }) => ({
  color: theme.palette.text.color2,
  width: '17rem',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}));

const UsersTile = ({ isLoading, application }: UsersTileProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const applicationData = prepareDataForApplication(application);

  return (
    <Card sx={{ marginBottom: theme.spacing(4) }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <UsageBox>
              <LoadableComponent isLoading={isLoading}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <UsageBoxTitle>{t('PREFERENCES.INTEGRATIONS.USAGE.ACTIVE_USERS')}</UsageBoxTitle>
                    <StyledUsageBoxContent text={applicationData.activeUsers?.users ?? 0} dataTestId="activeUsers" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledUsersTooltip>{t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.TOOLTIP')}</StyledUsersTooltip>
                  </Grid>
                </Grid>
              </LoadableComponent>
            </UsageBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <UsageBox>
              <LoadableComponent isLoading={isLoading}>
                <Stack direction="row" alignItems="center" height="100%">
                  <StyledUsageBoxBadge
                    text={applicationData.activeUsers?.delta.value ?? 0}
                    severity={applicationData.activeUsers?.delta.severity ?? 'info'}
                    append="%"
                  />
                  <StyledLastDays severity={applicationData.activeUsers?.delta.severity ?? 'info'}>
                    {t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.BADGE_DESCRIPTION')}
                  </StyledLastDays>
                </Stack>
              </LoadableComponent>
            </UsageBox>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UsersTile;
