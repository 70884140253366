import { DesignSystemColor, DesignSystemSize } from '../../types';
import { TabsVariant } from './Tabs';
import { createRequiredContext } from '../../utils/contexts';

export type TabsContextValue = {
  variant: TabsVariant;
  size: DesignSystemSize;
  color: DesignSystemColor;
};

export const { TabsContextProvider, useTabsContext } = createRequiredContext<TabsContextValue, 'Tabs'>('Tabs');
