import { useCloudInventory } from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/useCloudInventory';
import { Card } from '@verticeone/design-system/src';
import Stack from '@mui/material/Stack';
import { ToggleSwitch } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';

type CloudInventoryContentProps = {
  showToggleSwitch?: boolean;
};

const CloudInventoryContent = ({ showToggleSwitch }: CloudInventoryContentProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_UTILIZATION' });
  const toggleSwitchId = 'expired_reservations_toggle_switch';
  const { showExpired, setShowExpired, activeTab, setActiveTab, tabs } = useCloudInventory();

  const getTabLabelKey = (tabId: string) => `TABS.${tabId.toUpperCase()}`;

  return (
    <Card>
      <TabsBottomLineWrapper paddingX={6} sx={{ paddingTop: 6 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Tabs variant="outlined" value={activeTab?.id} color={AWS_BRAND_COLOR}>
            {tabs.map(({ id }) => (
              <Tab key={id} value={id} label={t(getTabLabelKey(id))} onClick={() => setActiveTab(id)} />
            ))}
          </Tabs>
          {showToggleSwitch && (
            <Stack direction="row" alignItems="center" gap={2}>
              <ToggleSwitch
                id={toggleSwitchId}
                size="S"
                color="tertiary"
                checked={showExpired}
                onChange={() => setShowExpired(!showExpired)}
              />
              <Text variant="body-regular" tag="label" htmlFor={toggleSwitchId}>
                {t('FILTER.SHOW_EXPIRED')}
              </Text>
            </Stack>
          )}
        </Stack>
      </TabsBottomLineWrapper>
      {activeTab?.component ? <activeTab.component /> : null}
    </Card>
  );
};

export default CloudInventoryContent;
