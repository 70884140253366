import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system/src';

export type UseBillingFrequencyOptionsReturn = SimpleOption[];

const useBillingFrequencyOptions = (): UseBillingFrequencyOptionsReturn => {
  const { t } = useTranslation();
  const billingFrequencyOptions = useMemo(
    () => [
      {
        value: 'MONTHLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_MONTHLY'),
      },
      {
        value: 'QUARTERLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_QUARTERLY'),
      },
      {
        value: 'ANNUALLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ANNUALLY'),
      },
      {
        value: 'BIANNUALLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_BIANNUALLY'),
      },
      {
        value: 'ONE_OFF',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ONE_OFF'),
      },
      {
        value: 'OTHER',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_OTHER'),
      },
    ],
    [t]
  );

  return billingFrequencyOptions;
};

export default useBillingFrequencyOptions;
