import { ViewOnContractForVendorsPage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const isContractWorkflowInProgress = (view: ViewOnContractForVendorsPage) =>
  view.materialized?.simplifiedStage === 'WORKFLOW_IN_PROGRESS';

const isLiveNotLiveOrDraft = (view: ViewOnContractForVendorsPage) =>
  !!view.materialized?.simplifiedStage &&
  ['DRAFT', 'LIVE', 'NOT_YET_LIVE'].includes(view.materialized?.simplifiedStage);

const isRenewalInProgress = (view: ViewOnContractForVendorsPage) => {
  const isRenewal =
    !!view.contractOrigin && ['RENEWAL_WITH_VERTICE', 'RENEWAL_WITHOUT_VERTICE'].includes(view.contractOrigin);
  return isRenewal && isContractWorkflowInProgress(view);
};

const isPurchaseInProgress = (view: ViewOnContractForVendorsPage) => {
  const isPurchase =
    !!view.contractOrigin && ['PURCHASE_WITH_VERTICE', 'PURCHASE_WITHOUT_VERTICE'].includes(view.contractOrigin);
  return isPurchase && isContractWorkflowInProgress(view);
};

export const getContractCounts = (contractsPerLineage: ViewOnContractForVendorsPage[][]) => {
  const initialValue = {
    existing: 0,
    purchaseInProgress: 0,
  };

  return contractsPerLineage.reduce((acc, contractsInLineage) => {
    const hasExisting = contractsInLineage.some((view) => isRenewalInProgress(view) || isLiveNotLiveOrDraft(view));
    const hasPurchaseInProgress = contractsInLineage.some((view) => isPurchaseInProgress(view));

    return {
      existing: acc.existing + (hasExisting ? 1 : 0),
      purchaseInProgress: acc.purchaseInProgress + (hasPurchaseInProgress ? 1 : 0),
    };
  }, initialValue);
};
