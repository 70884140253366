import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { Grid } from '@mui/material';
import { EmailField } from '@vertice/core/src/components/EmailField';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { NewPurchaseFormData, NewPurchaseFormEntry } from '../../types';
import { PurchaseRequirements } from '../../../SharedComponents/PurchaseRequirements';
import { PurchasingManagerPermissions } from '../../../SharedComponents/PurchasingManagerPermissions';
import { useCreateNewPurchaseRequest } from './useCreateNewPurchaseRequest';

const useAdditionalRequirementsWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('purchase_started'),
  };
};

const AdditionalRequirements: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { goBack, goToNext } = useAdditionalRequirementsWizard();
  const { t } = useTranslation();
  const { watch, trigger } = useFormContext<NewPurchaseFormData>();
  const desiredContractLength = watch('desiredContractLength');

  const [createRequest, creatingPurchaseRequest] = useCreateNewPurchaseRequest(goToNext);

  const validationFields = watch(['desiredContractLength', 'signatoryEmail']);

  useEffect(() => {
    void trigger(['desiredContractLength', 'signatoryEmail']).then(setNextButtonEnabled);
  }, [trigger, validationFields]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.NEW_PURCHASE_ADDITIONAL_REQ.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.NEW_PURCHASE_ADDITIONAL_REQ.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel />
      <InnerScrollable>
        <FormSection>
          <Grid item xs={12}>
            <PurchaseRequirements />
          </Grid>
          <NewPurchaseFormEntry
            name="signatoryName"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNATORY_NAME'),
            }}
            component={FormTextField}
            width={6}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNATORY_NAME') }}
          />
          <NewPurchaseFormEntry
            name="signatoryEmail"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNATORY_EMAIL'),
            }}
            component={EmailField}
            width={6}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNATORY_EMAIL') }}
          />
          <NewPurchaseFormEntry
            name="signingEntity"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNING_ENTITY'),
            }}
            component={FormTextField}
            width={12}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNING_ENTITY') }}
          />
          <NewPurchaseFormEntry
            name="startDate"
            label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
            component={FormDateField}
          />
          <NewPurchaseFormEntry
            name="desiredContractLength"
            label={t('ENTITIES.CONTRACT.LABELS.DESIRED_CONTRACT_LENGTH')}
            component={FormNumberField}
            componentProps={{
              minValue: 0,
              unit: t('UNITS.MONTH', { count: desiredContractLength || 0 }),
            }}
            width={6}
          />
          <Grid item xs={12}>
            <PurchasingManagerPermissions />
          </Grid>
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: createRequest,
          isLoading: creatingPurchaseRequest,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextButtonEnabled || creatingPurchaseRequest,
        }}
      />
    </Content>
  );
};

export default AdditionalRequirements;
