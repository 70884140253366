import { sleep } from '@verticeone/utils/async';
import { useLazyGetRequestQuery } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { WorkflowStage } from '../types';
import { useMemo } from 'react';

export const useWaitForRequestStage = (delay = 2000, maxAttempts = 20) => {
  const [getRequest] = useLazyGetRequestQuery();

  return useMemo(
    () => async (accountId: string, requestId: string, awaitedStage?: WorkflowStage) => {
      let stageFound = false;
      let attempts = 0;
      do {
        await sleep(delay);
        stageFound = await getRequest({ accountId, requestId })
          .unwrap()
          .then((response) =>
            // if awaitedStage is not provided, we just check if the stage is present
            awaitedStage ? response.request?.context?.stage === awaitedStage : !!response.request?.context?.stage
          );
        attempts++;
      } while (!stageFound && attempts < maxAttempts);
      return stageFound;
    },
    [delay, getRequest, maxAttempts]
  );
};
