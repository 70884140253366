import { useFormatDate } from '@verticeone/utils/formatting';
import { Text } from '@verticeone/design-system/src';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isContractLiveOrNotYetLive, isRollingContract } from '../../../computed';
import { getNextRollDate } from '../../NextRollDateComputedField/NextRollDateComputedField';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';

export const completedCellValueGetter = ({ contract }: ViewOnContract): string => {
  return contract?.parts?.contractual?.signatory?.signingDate || '';
};

export const nextRollDateValueGetter = ({ contract }: ViewOnContract): string | undefined => {
  if (isContractLiveOrNotYetLive(contract) && isRollingContract(contract)) {
    const startDate = contract.parts.contractual?.lifecycle?.startDate;
    const rollFrequency = contract.parts.contractual?.lifecycle?.rollFrequency;
    const nextRollDate = getNextRollDate(keepOnlyLocalDatePart(startDate) ?? undefined, rollFrequency ?? undefined);
    return nextRollDate?.toISOString();
  }

  return undefined;
};

export type DateCellProps = {
  date: string;
};

export const DateCell = ({ date }: DateCellProps) => {
  const formatDate = useFormatDate();
  return date ? (
    <Text variant="body-regular">{formatDate(date)}</Text>
  ) : (
    <Text variant="body-regular" size="M" color="inactive2">
      —
    </Text>
  );
};
