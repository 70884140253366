import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Switch } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  useUpdateDataSourceMutation,
  useDataSourceQuery,
  DataSourceStatus,
  useUpdateThreeDataSourcesMutation,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { StepProps, STEPS } from './common';
import styles from '../../../../common.module.scss';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';

const GOOGLE_USER_DATA_SOURCE = 'GOOGLE_USER_BASE';

export const CollectStep = ({
  setStep,
  accountId,
  samlProviderTargetState,
  setSamlProviderTargetState,
}: StepProps & { samlProviderTargetState: boolean; setSamlProviderTargetState: (state: boolean) => void }) => {
  const { t } = useTranslation();
  const onBack = () => setStep(STEPS.START);

  const [userAnalyticsChecked, setUserAnalyticsChecked] = React.useState<boolean>(false);
  const [socialLoginChecked, setSocialLoginChecked] = React.useState<boolean>(false);

  const [updateDataSource] = useUpdateDataSourceMutation();
  const [updateThreeDataSources] = useUpdateThreeDataSourcesMutation();
  const { data: userDataSourceQuery } = useDataSourceQuery({ accountId, code: GOOGLE_USER_DATA_SOURCE });
  const { data: socialLoginDataLakeDataSourceQuery } = useDataSourceQuery({
    accountId,
    code: 'GOOGLE_SOCIAL_LOGIN_DATALAKE',
  });
  const { data: socialLoginAccessLogDataSourceQuery } = useDataSourceQuery({
    accountId,
    code: 'GOOGLE_SOCIAL_LOGIN_ACCESS_LOG',
  });
  const { data: socialLoginApplicationsDataSourceQuery } = useDataSourceQuery({
    accountId,
    code: 'GOOGLE_SOCIAL_LOGIN_APPLICATIONS',
  });

  useEffect(() => {
    setUserAnalyticsChecked(userDataSourceQuery?.dataSource?.status === DataSourceStatus.Active);
  }, [userDataSourceQuery]);

  useEffect(() => {
    setSocialLoginChecked(
      socialLoginDataLakeDataSourceQuery?.dataSource?.status === DataSourceStatus.Active &&
        socialLoginAccessLogDataSourceQuery?.dataSource?.status === DataSourceStatus.Active &&
        socialLoginApplicationsDataSourceQuery?.dataSource?.status === DataSourceStatus.Active
    );
  }, [socialLoginDataLakeDataSourceQuery, socialLoginAccessLogDataSourceQuery, socialLoginApplicationsDataSourceQuery]);

  const handleOnSocialLoginChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateThreeDataSources({
      accountId,
      dataSourceCode1: 'GOOGLE_SOCIAL_LOGIN_DATALAKE',
      dataSourceCode2: 'GOOGLE_SOCIAL_LOGIN_ACCESS_LOG',
      dataSourceCode3: 'GOOGLE_SOCIAL_LOGIN_APPLICATIONS',
      input: {
        status: event.target.checked ? DataSourceStatus.Active : DataSourceStatus.Inactive,
      },
    })
      .then((res) => {
        if ('error' in res) {
          setSocialLoginChecked(!event.target.checked);
        } else {
          setSocialLoginChecked(
            res.data.status1.status === DataSourceStatus.Active &&
              res.data.status2.status === DataSourceStatus.Active &&
              res.data.status3.status === DataSourceStatus.Active
          );
        }
      })
      .catch(() => {
        setSocialLoginChecked(!event.target.checked);
      });
  };

  const handleOnSAMLChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateThreeDataSources({
      accountId,
      dataSourceCode1: 'GOOGLE_SAML_DATALAKE',
      dataSourceCode2: 'GOOGLE_SAML_ACCESS_LOG',
      dataSourceCode3: 'GOOGLE_SAML_APPLICATIONS',
      input: {
        status: event.target.checked ? DataSourceStatus.Active : DataSourceStatus.Inactive,
      },
    })
      .then((res) => {
        if ('error' in res) {
          setSamlProviderTargetState(!event.target.checked);
        } else {
          setSamlProviderTargetState(
            res.data.status1.status === DataSourceStatus.Active &&
              res.data.status2.status === DataSourceStatus.Active &&
              res.data.status3.status === DataSourceStatus.Active
          );
        }
      })
      .catch(() => {
        setSamlProviderTargetState(!event.target.checked);
      });
  };

  const handleOnUserAnalyticsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateDataSource({
      accountId,
      dataSourceCode: GOOGLE_USER_DATA_SOURCE,
      input: {
        status: event.target.checked ? DataSourceStatus.Active : DataSourceStatus.Inactive,
      },
    })
      .then((res) => {
        if ('error' in res) {
          setUserAnalyticsChecked(!event.target.checked);
        } else {
          setUserAnalyticsChecked(res.data.updateDataSource.status === DataSourceStatus.Active);
        }
      })
      .catch(() => {
        setUserAnalyticsChecked(!event.target.checked);
      });
  };

  const header = (
    <Stack gap="8px">
      <Stack direction="row" gap="6px">
        <Text variant="heading" size="M">
          {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.GET_SETTINGS')}
        </Text>
      </Stack>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.GET_SETTINGS_INTRO')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack direction="column" gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.DATA_SOURCE')}
      </Text>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Switch checked={samlProviderTargetState} onChange={handleOnSAMLChange} />
        </Grid>
        <Grid item xs={10}>
          <Stack direction="column" gap={1}>
            <Text variant="button-bold" size="M" color="text1">
              {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.SAML_SSO_TOGGLE')}
            </Text>
            <Text variant="body-regular" size="S" className={styles.text} color="text3">
              {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.SAML_SSO_TOGGLE_DESCRIPTION')}
            </Text>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Switch checked={socialLoginChecked} onChange={handleOnSocialLoginChange} />
        </Grid>
        <Grid item xs={10}>
          <Stack direction="column" gap={1}>
            <Text variant="button-bold" size="M" color="text1">
              {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.SOCIAL_LOGIN_TOGGLE')}
            </Text>
            <Text variant="body-regular" size="M" color="text3">
              {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.SOCIAL_LOGIN_TOGGLE_DESCRIPTION')}
            </Text>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Switch checked={userAnalyticsChecked} onChange={handleOnUserAnalyticsChange} />
        </Grid>
        <Grid item xs={10}>
          <Stack direction="column" gap={1}>
            <Text variant="button-bold" size="M" color="text1">
              {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.CAPTURE_USER_DATA')}
            </Text>
            <Text variant="body-regular" size="M" color="text3">
              {t('PREFERENCES.INTEGRATIONS.DATA_FIDELITY_STEP.CAPTURE_USER_DATA_DESCRIPTION')}
            </Text>
          </Stack>
        </Grid>
      </Grid>

      {socialLoginChecked ? (
        <div className={styles['social-alert-wrap']}>
          <span className={styles.icon}>
            <ErrorOutlineIcon className={styles['info-icon']} />
          </span>
          <Text variant="body-bold" size="S" className={styles['social-alert-content']}>
            <Text variant="body-bold" size="S">
              {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.SOCIAL_ALERT_LABEL')}
            </Text>
            <Text variant="body-regular" size="S">
              {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.SOCIAL_ALERT_DESCRIPTION')}
            </Text>
          </Text>
        </div>
      ) : null}
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          disabled={!samlProviderTargetState && !socialLoginChecked}
          onClick={() => setStep(STEPS.ALLOW_ACCESS)}
        >
          {t('COMMON.NEXT')}
        </Button>,
      ]}
    />
  );
};
