import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextFieldCaption } from '@verticeone/design-system/src';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import { PropsWithContract } from '../types';
import { isContractExisting, isContractRenewalWithoutVertice } from '@vertice/core/src/modules/saas/contract/computed';

export const BaselineCost: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();

  const annualBaselineCost = contract.parts.overview?.annualCostWithoutVertice;
  const totalBaselineCost = contract.parts.overview?.totalCostValueWithoutVertice;
  const isExisting = isContractExisting(contract);
  const withoutVertice = isContractRenewalWithoutVertice(contract);
  return (
    <Stack gap={1} flex={1}>
      <TextFieldCaption
        htmlFor={id}
        label={t(
          isExisting ? 'ENTITIES.CONTRACT.LABELS.ANNUAL_COST' : 'ENTITIES.CONTRACT.LABELS.INITIAL_VENDOR_PROPOSAL'
        )}
        size="XS"
        tooltip={
          isExisting
            ? undefined
            : {
                title: t('ENTITIES.CONTRACT.LABELS.INITIAL_VENDOR_PROPOSAL'),
                content: withoutVertice
                  ? t('ENTITIES.CONTRACT.TOOLTIPS.INITIAL_VENDOR_PROPOSAL_WITHOUT_VERTICE')
                  : t('ENTITIES.CONTRACT.TOOLTIPS.INITIAL_VENDOR_PROPOSAL'),
              }
        }
      />

      <LargeValue
        id={id}
        primaryValue={formatContractCurrency(annualBaselineCost)}
        primaryTestId={'annualBaselineCost'}
        primaryColor="text1"
        secondaryValue={
          totalBaselineCost
            ? t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalBaselineCost) })
            : undefined
        }
        secondaryTestId={'totalBaselineCost'}
      />
    </Stack>
  );
};
