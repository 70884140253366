import { useListRequestTasksQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useMemo } from 'react';

export const useChildRequestActiveTasks = ({
  requestId,
  accountId,
  skip,
}: {
  requestId: string;
  accountId: string;
  skip: boolean;
}) => {
  const { data, isLoading } = useListRequestTasksQuery({ accountId, requestId }, { skip });

  const activeTasksCount = useMemo(() => {
    if (!data?.items.length) {
      return 0;
    }

    return data.items.filter((task) => task.status === 'ACTIVE').length;
  }, [data?.items]);

  return {
    activeTasksCount,
    isLoading,
  };
};
