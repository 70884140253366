import MuiTab, { TabProps as MuiTabProps, tabClasses } from '@mui/material/Tab';
import styled from '@mui/material/styles/styled';
import { CustomizedProps, DesignSystemColor, TestProps } from '../../types';
import { useTabsContext } from '../Tabs/TabsContext';
import { getTextVariantStyle } from '../Text/utils';
import { testProps } from '../../utils/testProperties';
import { RequiredLabel } from './components/RequiredLabel';

export type TabProps = TestProps & CustomizedProps & MuiTabProps & { error?: boolean; required?: boolean };

type OutlinedTabProps = {
  $color: DesignSystemColor;
  $error?: boolean;
};

export const FOCUS_UNDERLINE_THICKNESS = 4;

const OutlinedTab = styled(MuiTab)<OutlinedTabProps>(({ $color, $error, theme: { palette } }) => ({
  [`&.${tabClasses.root}`]: {
    minHeight: 0,
    padding: '8px 16px',
    ...getTextVariantStyle({ variant: 'button-bold', size: 'M' }),
    color: $error ? palette.error.color2 : palette.neutral.color2,
    borderBottom: `1px solid ${palette.neutral.color2}`,
    transition: [
      'color 200ms cubic-bezier(0.0,0,0.2,1)',
      'border 200ms cubic-bezier(0.0,0,0.2,1)',
      'box-shadow 300ms cubic-bezier(0.0,0,0.2,1)',
    ].join(', '),

    // Initial transparent state for animation
    boxShadow: `0 ${FOCUS_UNDERLINE_THICKNESS}px 0 0 transparent`,
  },

  '&:hover': {
    color: $error ? palette.error.hover.color2 : palette.neutral.hover.color2,
    borderColor: palette.neutral.hover.color2,
  },

  '&:focus-visible': {
    color: $error ? palette.error.hover.color2 : palette[$color].color2,
    borderBottom: `1px solid ${palette[$color].color2}`,
    boxShadow: `0 ${FOCUS_UNDERLINE_THICKNESS}px 0 0 ${palette[$color].color4}`,
  },

  [`&.${tabClasses.selected}`]: {
    color: palette[$color].color2,
    // We don't want the border to be visible under the transparent indicator
    borderBottom: $color === 'transparent' ? '1px solid transparent' : undefined,
  },
  [`&.${tabClasses.selected}:hover`]: {
    color: palette[$color].hover.color2,
  },

  [`&.${tabClasses.disabled}`]: {
    color: palette.inactive.color3,
    borderBottom: `1px solid ${palette.inactive.color3}`,
  },
  [`&.${tabClasses.disabled}:hover`]: {
    color: palette.inactive.hover.color3,
    borderBottom: `1px solid ${palette.inactive.hover.color3}`,
  },
}));

/** WORK IN PROGRESS, limited variants supported. */
export const Tab = ({ testId, error, required, label, ...otherProps }: TabProps) => {
  const { color } = useTabsContext();
  const tabProps = {
    $color: color,
    $error: error,
    label: required ? <RequiredLabel label={label} disabled={otherProps.disabled} /> : label,
    ...(error ? { 'aria-invalid': true } : {}),
    ...(required ? { 'aria-required': true } : {}),
    ...testProps(testId, 'tab'),
    ...otherProps,
  };

  return <OutlinedTab {...tabProps} />;
};
