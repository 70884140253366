import { ContractCreate } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const ContractWorkflowsServiceUrn = 'urn:verticeone:vertice::contract-workflows';

export const augmentWithManagedBy = (contractData: ContractCreate, managedBy: string): ContractCreate => {
  return {
    ...contractData,
    record: {
      ...contractData.record,
      managedBy: managedBy,
    },
  };
};

export const augmentWithLineage = (contractData: ContractCreate, parentContractId: string): ContractCreate => {
  return {
    ...contractData,
    lineage: [
      ...(contractData?.lineage ?? []),
      {
        linkedContractId: parentContractId ? parentContractId : '',
        linkType: 'PREV',
      },
    ],
  };
};
