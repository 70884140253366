import { SxProps } from '@mui/material';
import styled from '@mui/styled-engine';
import { SVGProps } from 'react';

const BaseSubProcess = ({ width, height, className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="-4 -4 31 31"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 3.52234C3.57733 3.52234 6.02256 5.59044 7.55915 7.60718L8.81861 5.74983C6.99309 3.57071 4.13703 1.38377 0 1.38377V3.52234Z" />
    <path d="M13.9523 3.91576C15.1599 3.41154 16.5715 3.18507 18.2826 3.17965V0L22.6477 4.24884L18.2826 8.49767V5.31824C16.7534 5.32369 15.6476 5.52738 14.7851 5.88752C13.9256 6.24639 13.2427 6.78759 12.601 7.5676C12.0027 8.29484 11.446 9.22218 10.8212 10.4065C10.387 11.2294 9.7446 12.2927 8.86362 13.3539C7.05825 15.5284 4.17654 17.7722 0 17.7722V15.6336C3.30907 15.6336 5.63866 13.8789 7.20487 11.9924C7.96719 11.0742 8.53108 10.1434 8.91644 9.41302C9.55995 8.19336 10.1972 7.1118 10.9361 6.2136C11.76 5.21207 12.7149 4.43239 13.9523 3.91576Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9773 24L19.3998 21.98C19.6658 21.8838 19.9122 21.7676 20.1391 21.6313C20.366 21.4951 20.589 21.3387 20.808 21.1624L22.6857 21.7876L24 19.479L22.4979 18.1324C22.5292 17.8438 22.5448 17.5552 22.5448 17.2666C22.5448 16.9781 22.5292 16.6895 22.4979 16.4009L24 15.0542L22.6857 12.7456L20.808 13.3709C20.589 13.1945 20.366 13.0382 20.1391 12.9019C19.9122 12.7657 19.6658 12.6494 19.3998 12.5532L18.9773 10.5332H16.3487L15.9262 12.5532C15.6602 12.6494 15.4138 12.7657 15.1869 12.9019C14.96 13.0382 14.737 13.1945 14.518 13.3709L12.6403 12.7456L11.326 15.0542L12.8281 16.4009C12.7968 16.6895 12.7812 16.9781 12.7812 17.2666C12.7812 17.5552 12.7968 17.8438 12.8281 18.1324L11.326 19.479L12.6403 21.7876L14.518 21.1624C14.737 21.3387 14.96 21.4951 15.1869 21.6313C15.4138 21.7676 15.6602 21.8838 15.9262 21.98L16.3487 24H18.9773ZM17.6628 20.1677C16.1086 20.1677 14.8487 18.8736 14.8487 17.2773C14.8487 15.681 16.1086 14.387 17.6628 14.387C19.217 14.387 20.4769 15.681 20.4769 17.2773C20.4769 18.8736 19.217 20.1677 17.6628 20.1677Z"
    />
  </svg>
);
export const SubProcess = styled(({ sx, ...props }: SVGProps<SVGSVGElement> & { sx?: SxProps }) => (
  <BaseSubProcess {...props} />
))(({ sx }) => ({ ...sx }));
