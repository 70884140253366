import { KeyboardEventHandler, KeyboardEvent, useCallback, useMemo } from 'react';

// WARNING:
// This file is copied from @vertice/core/src/utils.
// Don't change this file without updating source file as well.
// This file is going to be deleted as soon as we move the formatters into the shared @verticeone/utils package.

export type KeyboardShortcut = {
  keys: string[];
  action?: VoidFunction | null;
  onDownCallback?: KeyboardEventHandler;
  onUpCallback?: KeyboardEventHandler;
};

export type KeyboardShortcuts = Record<string, KeyboardShortcut>;

export type ActiveShortcuts = {
  [key in keyof KeyboardShortcuts]: boolean;
};

export type UseKeyboardShortcuts = {
  shortcuts: KeyboardShortcuts;
  ignoreLetterPresses?: boolean;
};

export type UseKeyboardShortcutsReturn = {
  handlers: {
    onKeyDown: KeyboardEventHandler;
    onKeyUp: KeyboardEventHandler;
  };
};

const eventKeys = ({ altKey, shiftKey, ctrlKey, key }: KeyboardEvent) => {
  const lKey = key.toLowerCase();
  const modifiers: string[] = [];
  if (altKey && lKey !== 'alt') {
    modifiers.push('alt');
  }
  if (shiftKey && lKey !== 'shift') {
    modifiers.push('shift');
  }
  if (ctrlKey && lKey !== 'control') {
    modifiers.push('control');
  }

  return { keys: [...modifiers, lKey], key: lKey };
};

const isAlpha = (text: string) => /^[A-Z]+$/i.test(text);

const useKeyboardShortcuts = ({
  shortcuts,
  ignoreLetterPresses = false,
}: UseKeyboardShortcuts): UseKeyboardShortcutsReturn => {
  const onKey = useCallback(
    (event: KeyboardEvent) => {
      const { key, keys } = eventKeys(event);

      if (ignoreLetterPresses && isAlpha(key) && key.length === 1) {
        return;
      }

      Object.keys(shortcuts).forEach((shortcut: string) => {
        const shortcutKeys = shortcuts[shortcut];

        if (shortcutKeys.keys.length !== keys.length || !shortcutKeys.keys.every((k) => keys.includes(k.toLowerCase())))
          return;

        if (event.type === 'keydown') {
          shortcutKeys.onDownCallback?.(event);
        } else if (event.type === 'keyup') {
          shortcutKeys.onUpCallback?.(event);
          shortcutKeys.action?.();
        }
      });
    },
    [shortcuts, ignoreLetterPresses]
  );

  return useMemo(
    () => ({
      handlers: {
        onKeyDown: onKey,
        onKeyUp: onKey,
      },
    }),
    [onKey]
  );
};

export default useKeyboardShortcuts;
