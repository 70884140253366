import Highcharts from 'highcharts';
import { keyBy } from 'lodash';
import { LegendItemData } from '../../components/Legend/types';

/**
 * Creates a legend item for each serie of the chart.
 *
 * With the second parameter provided, only series in the array in the given order are taken into consideration. This
 * is useful especially if auxiliary series for top/bottom/top-bottom rounded corners are present.
 *
 * @param options Chart options data
 * @param orderedSeries Order of the series given by their ids
 */
const extractLegendItemsFromSeries = (options: Highcharts.Options, orderedSeries?: string[]): LegendItemData[] => {
  const series = options.series || [];
  const seriesMap = keyBy(series, 'id');
  const sortedFilteredSeries = orderedSeries ? orderedSeries.map((id) => seriesMap[id]) : series;

  return (
    sortedFilteredSeries?.map((serie: any) => ({
      color: ((serie.states?.hover?.color ?? serie.color) as string) ?? 'transparent',
      label: serie.name ?? '',
      id: serie.id ?? serie.name ?? '',
    })) ?? []
  );
};

export default extractLegendItemsFromSeries;
