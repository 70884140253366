import { useAccountContext } from '../../contexts/AccountContext';
import { useParams } from 'react-router-dom';
import useFetchedContract from '../saas/contract/hooks/useFetchContract';
import { useMemo } from 'react';
import { ContractLocator } from '../saas/contract/types';
import { isRollingContract } from '../saas/contract/computed';
import { BillingFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type ContractProperties = {
  signDate?: string;
  startDate?: string;
  renewalDate?: string;
  currency?: string;
  annualCost?: number;
  contractLength?: number;
  isRolling?: boolean;
  billingFrequency?: BillingFrequency;
  departmentId: string;
  contractId: string;
};

export const useCurrentContractProperties = (): ContractProperties | undefined => {
  const { contractId } = useParams() as { contractId: string; account_id: string };
  const { accountId } = useAccountContext();
  const contractLocator = useMemo(
    (): ContractLocator | undefined => ({ contractId, accessVia: { type: 'account', accountId } }),
    [accountId, contractId]
  );
  const fetchedContractQuery = useFetchedContract(contractLocator);

  if (!fetchedContractQuery.isLoading) {
    const parts = fetchedContractQuery.data.contract.parts;
    return {
      signDate: parts.contractual?.signatory?.signingDate,
      startDate: parts.contractual?.lifecycle?.startDate,
      renewalDate: parts.contractual?.lifecycle?.renewalDate,
      currency: parts.contractual?.financial?.baseCurrency,
      annualCost: parts.overview?.effectiveAnnualCost,
      contractLength: parts.overview?.contractLength,
      billingFrequency: parts.contractual?.financial?.billingFrequency,
      isRolling: isRollingContract(fetchedContractQuery.data.contract),
      departmentId: fetchedContractQuery.data.contract.classification?.departmentId!,
      contractId: fetchedContractQuery.data.contract.record.contractId,
    };
  }
};
