import { ReactNode } from 'react';
import { styled, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

const StyledBottomLineWrapper = styled('div')<{ $paddingX: string | number }>(
  ({ $paddingX, theme: { palette, spacing } }) => {
    const paddingX = typeof $paddingX === 'number' ? spacing($paddingX) : $paddingX;
    return {
      position: 'relative',
      paddingLeft: paddingX,
      paddingRight: paddingX,
      '&::before': {
        content: '""',
        position: 'absolute',
        height: 1,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.core.color3,
      },
    };
  }
);

export type TabsBottomLineWrapperProps = {
  children: ReactNode;
  sx?: SxProps<Theme>;

  /**
   * Padding on the left and right side of the actual Tabs. Useful if you want to see the line on the sides.
   * If you pass a number, it is multiplied by the theme spacing factor.
   * @default 0
   */
  paddingX?: number | string;
};

/**
 * Wrapper that adds a bottom line to its children. It is special in the way that the line gets rendered on background
 * and therefore can be overlapped with the content (typically Tab lines).
 */
export const TabsBottomLineWrapper = ({ children, sx, paddingX = 0 }: TabsBottomLineWrapperProps) => (
  <StyledBottomLineWrapper $paddingX={paddingX} sx={sx}>
    {children}
  </StyledBottomLineWrapper>
);
