import { STORAGE_KEY } from './useAssumeRoleAuth';

const getAssumeRoleJwtToken = async () => {
  const rawValue = localStorage.getItem(STORAGE_KEY);
  const token = rawValue ? (JSON.parse(rawValue) as string) : '';
  // TODO: check if token is expired
  return token;
};

export default getAssumeRoleJwtToken;
