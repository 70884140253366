import { ReactNode } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Stack from '@mui/material/Stack';
import FormLabel, { FormLabelProps as MuiFormLabelProps } from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import { rgba } from 'polished';
import assertExhausted from '../../utils/assertExhausted';
import i18n from '../../translate';
import type { CustomizedProps, DesignSystemSize, TestProps } from '../../types';
import { Text } from '../Text';
import { testProps } from '../../utils/testProperties';
import { Tooltip, TooltipProps } from '../Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { IconWrapper } from '../IconWrapper';

export type TextFieldCaptionProps = TestProps &
  CustomizedProps &
  Omit<MuiFormLabelProps, 'htmlFor'> & {
    label: ReactNode;
    labelSecondary?: string;
    size?: DesignSystemSize;
    required?: boolean;
    htmlFor?: string;
    tooltip?: Pick<TooltipProps, 'title' | 'content' | 'maxWidth'>;
  };

const getGapForSize = (size: DesignSystemSize): number => {
  switch (size) {
    case 'XL':
    case 'L':
    case 'M':
      return 6;
    case 'S':
    case 'XS':
    case 'XXS':
      return 4;
    default:
      assertExhausted(size);
      return 6;
  }
};

export const TextFieldCaption = ({
  htmlFor,
  label,
  labelSecondary,
  size = 'M',
  required = false,
  testId,
  tooltip,
  ...otherProps
}: TextFieldCaptionProps) => {
  const { t } = useTranslation(undefined, { i18n });
  const { palette } = useTheme();

  return (
    <FormLabel htmlFor={htmlFor} {...testProps(testId, 'text-field-caption')} {...otherProps}>
      <Stack direction="row" justifyContent="space-between" gap={`${getGapForSize(size)}px`}>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={`${getGapForSize(size)}px`}>
          {required && (
            <Text
              variant="caption"
              size={size}
              color={palette.error.color2}
              sx={{ display: 'block', marginX: `-${getGapForSize(size) / 2}px` }}
              aria-hidden
            >
              ﹡
            </Text>
          )}
          <Text variant="caption" size={size} color={palette.text.color2}>
            {label}
          </Text>
          {labelSecondary && (
            <Text variant="caption" size={size} color={rgba(palette.text.color2, 0.4)}>
              {labelSecondary}
            </Text>
          )}
          {tooltip && (
            <Tooltip {...tooltip} size="S">
              <IconWrapper icon={InfoIcon} size={size} htmlColor={rgba(palette.neutral.color1, 0.4)} />
            </Tooltip>
          )}
        </Stack>
        {required && (
          <Text variant="caption" size={size} color={palette.error.color2}>
            {t('FORMS.REQUIRED')}
          </Text>
        )}
      </Stack>
    </FormLabel>
  );
};
