import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useAccountDepartments } from '@vertice/core/src/hooks/useAccountDepartments';
import { Select } from '@verticeone/design-system/src';
import { Department } from '@vertice/core/src/modules/departments/types';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { Filter } from '../types';

export const DepartmentFilter: FC = () => {
  const { t } = useTranslation();
  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastDepartments = setFilterValue(Filter.DEPARTMENT);
  const { data: departments } = useAccountDepartments();

  const onChange = (selection: MultiValue<Department>) =>
    setLastDepartments(selection.length ? selection.map((option) => option.departmentId) : undefined);

  return (
    <Stack minWidth={240}>
      <Select<Department, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={departments}
        value={departments?.filter(({ departmentId }) => filterValues?.[Filter.DEPARTMENT]?.includes(departmentId))}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ departmentId }) => departmentId}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.DEPARTMENT')}
        onChange={onChange}
        testId={'department-filter'}
      />
    </Stack>
  );
};
