import { Stack } from '@mui/material';
import RecommendationAlert from './RecommendationAlert';
import RecommendationAnnualSummary from './RecommendationAnnualSummary';
import AlternativeRecommendationAlert from './AlternativeRecommendationAlert';
import { LoadableComponent } from '@verticeone/design-system/src';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRecommendationData } from './useRecommendationData';

const Recommendation = () => {
  const { isFetching } = useRecommendationData();

  return (
    <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
      <Stack gap={4}>
        <RecommendationAlert />
        <RecommendationAnnualSummary />
        <AlternativeRecommendationAlert />
      </Stack>
    </LoadableComponent>
  );
};

export default Recommendation;
