import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@vertice/slices';
import { Text } from '@verticeone/design-system/src';
import { Link, Stack } from '@mui/material';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system/src';

type LegacyVendorInfoCardProps = {
  description?: string | null;
  yearFounded?: number | null;
  hq?: string | null;
  website?: Url;
};

type VendorInfoFieldProps = {
  label: string;
  value: string | number;
};

const VendorInfoField = ({ label, value }: VendorInfoFieldProps) => {
  return (
    <Stack gap={2}>
      <Text variant="label" size="S" color="text3">
        {label}
      </Text>
      <Text variant="caption" size="M">
        {value}
      </Text>
    </Stack>
  );
};

const LegacyVendorInfoCard = ({ description, yearFounded, hq, website }: LegacyVendorInfoCardProps) => {
  const { t } = useTranslation();

  return (
    <Card minWidth={374} height="100%">
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('VENDOR.ABOUT_VENDOR')} />
      </CardHeader>

      <Stack gap={7} px={6} pb={8}>
        {description && (
          <Text variant="body-regular" size="M">
            {description}
          </Text>
        )}

        <Stack direction="row" gap={30}>
          {yearFounded && <VendorInfoField label={t('VENDOR.YEAR_FOUNDED')} value={yearFounded} />}
          {hq && <VendorInfoField label={t('VENDOR.HQ')} value={hq} />}
        </Stack>

        {website && (
          <Text variant="button-bold" size="S" color="primary">
            <Link href={'//' + website} target="_blank" rel="noreferrer" underline="none">
              {t('VENDOR.WEBSITE')}
            </Link>
          </Text>
        )}
      </Stack>
    </Card>
  );
};

export default LegacyVendorInfoCard;
