import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.ANALYTICS),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
