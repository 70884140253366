import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const workflowsV2Api = createApi({
  reducerPath: 'workflowsV2Api',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.WORKFLOWS_V2),
  refetchOnMountOrArgChange: true,
  tagTypes: ['workflowsV2'],
  endpoints: () => ({}),
});
