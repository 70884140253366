import React from 'react';
import { Grid, styled } from '@mui/material';

type CardContentProps = {
  children: React.ReactNode;
};

/**
 * @deprecated Use @verticeone/design-system/src/components/Card instead.
 * The new card doesn't require a special wrapper around content.
 */
const CardContent = styled((props) => <Grid item {...props} />)<CardContentProps>(({ theme }) => ({
  position: 'relative',
  zIndex: '2',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  isolation: 'isolate',
  border: `1px solid ${theme.palette.core.color3}`,
  marginLeft: '-1px',
  marginRight: '-1px',
}));

export default CardContent;
