import React, { FC } from 'react';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { Stack } from '@mui/material';
import { isNil } from 'lodash';
import { Text } from '@verticeone/design-system/src';
import { ChipButton } from '@verticeone/design-system/src';
import useFormatSavingsPercentage from '@vertice/core/src/modules/saas/savings/useFormatSavingsPercentage';

export type SavingsCellProps = {
  savingsPercentage?: number;
  value?: number;
  currency?: string;
  highlightIfSuccess?: boolean;
  bold?: boolean;
};

export const SavingsCell: FC<SavingsCellProps> = ({ savingsPercentage, value, currency, highlightIfSuccess, bold }) => {
  const formatCurrency = useFormatCurrency();
  const formatSavingsPercentage = useFormatSavingsPercentage();

  const isSuccess = !isNil(value) && value > 0;

  return (
    <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end">
      {!isNil(savingsPercentage) && savingsPercentage > 0 && (
        <ChipButton color="success" variant="ghost" size="S" isActive={false}>
          {formatSavingsPercentage(savingsPercentage)}
        </ChipButton>
      )}

      <Text
        variant={bold ? 'body-bold' : 'body-regular'}
        size="M"
        color={highlightIfSuccess && isSuccess ? 'success1' : 'text1'}
      >
        {isNil(value) || !currency ? 0 : formatCurrency(value, { currency, maximumFractionDigits: 0 })}
      </Text>
    </Stack>
  );
};

export default SavingsCell;
