import { MoreVertOutlined } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import { IconWrapper } from '../../IconWrapper';
import { useDataGridContext } from '../DataGridContext';

export const MenuIcon = () => {
  const { palette } = useTheme();
  const { headerSize } = useDataGridContext();

  return <IconWrapper icon={MoreVertOutlined} size={headerSize} htmlColor={palette.neutral.color1} />;
};
