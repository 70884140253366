import React from 'react';
import { Stack } from '@mui/material';

type BodyProps = {
  children: React.ReactNode;
};

const Body = ({ children }: BodyProps) => (
  <Stack p={6} py={0} gap={6}>
    {children}
  </Stack>
);

export default Body;
