import React from 'react';

import BaseSavingsOverTimeGraph, {
  useSavingsData,
} from '@vertice/dashboard/src/pages/Cloud/components/SavingsOverTimeGraph';
import type { Code } from '../types';
import { useRecommendationData } from './RecommendationProvider';

type SavingsOverTimeGraphProps = {
  code: Code;
};

const SavingsOverTimeGraph = ({ code }: SavingsOverTimeGraphProps) => {
  const { data, isLoading } = useSavingsData(code);
  const {
    item: { empty },
  } = useRecommendationData();

  if (empty) {
    return null;
  }

  return (
    <BaseSavingsOverTimeGraph code={code} isLoading={isLoading} values={data?.values ?? []} dates={data?.dates ?? []} />
  );
};

export default SavingsOverTimeGraph;
