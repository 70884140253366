import { Skeleton, SkeletonProps, styled } from '@mui/material';
import React from 'react';
import { StyledComponent } from 'styled-components';

export type LoadableTextComponentProps = SkeletonProps & {
  isLoading?: boolean;
  children: React.ReactNode;
};

const BaseLoadableComponent = ({ isLoading, children, ...otherProps }: LoadableTextComponentProps) => {
  if (isLoading) {
    return <Skeleton {...otherProps} width="max(100%, 100px)" height={29} animation="wave" />;
  }

  return <>{children}</>;
};

export const LoadableTextComponent = styled(BaseLoadableComponent)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.core.color2,
  transform: 'scale(1, 1)',
})) as StyledComponent<typeof BaseLoadableComponent, LoadableTextComponentProps>;
