import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { SlackIconBW } from '@vertice/assets';
import { Text } from '@verticeone/design-system/src';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { WizardV2StepProps } from '../../../common';

export const InitialStep: FC<WizardV2StepProps> = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle
          icon={SlackIconBW}
          text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_TITLE')}
        />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
      </CardHeader>
      <Stack gap={1} p={6}>
        <Text variant="caption" size="XS" color="text2">
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TITLE')}
        </Text>
        <Text variant="body-regular" size="M" color="text2">
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TEXT')}
        </Text>
      </Stack>
    </Card>
  );
};
