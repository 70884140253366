import { GridComparatorFn, GridSortCellParams } from '@mui/x-data-grid-pro';
import {
  AccountValue,
  OrgResourceType,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

type ColumnComparator<T> = (billingAccountId?: string) => GridComparatorFn<T>;

export const nameComparator: ColumnComparator<string> =
  (billingAccountId?: string) => (v1: string, v2: string, params1: GridSortCellParams, params2: GridSortCellParams) => {
    // Do not sort billing account as we want to keep it on top (sorted in advance)
    if (billingAccountId === String(params1.id) || billingAccountId === String(params2.id)) {
      return 0;
    }

    return v1.localeCompare(v2);
  };

const statusOrd = (status?: boolean) => {
  if (status) {
    // Connected (true)
    return 1;
  }

  if (status === undefined) {
    // Loading...
    return 2;
  }

  return 3; // Disconnected (false)
};

export const statusComparator: ColumnComparator<boolean> =
  (billingAccountId?: string) =>
  (v1: boolean, v2: boolean, params1: GridSortCellParams, params2: GridSortCellParams) => {
    // Do not sort billing account as we want to keep it on top (sorted in advance)
    if (billingAccountId === String(params1.id) || billingAccountId === String(params2.id)) {
      return 0;
    }
    return statusOrd(v1) - statusOrd(v2);
  };

// The aim of this comparator is to keep the MANAGEMENT_ACCOUNT (billing account) always on top
export const accountTypeComparator = (first: AccountValue, second: AccountValue) => {
  if (first.type === OrgResourceType.ManagementAccount) {
    return -1;
  }

  if (second.type === OrgResourceType.ManagementAccount) {
    return 1;
  }

  return 0;
};
