import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import useValidatePassword, { validationRules } from '../../useValidatePassword';
import styles from './PasswordCriteria.module.scss';

type PasswordCriteriaProps = {
  id?: string;
};
type CriteriaProps = {
  isError: boolean;
  text: string | React.ReactNode;
};

type CriteriaType = {
  translationPath: string;
  validationRuleName: validationRules;
};

const Criteria = ({ isError, text }: CriteriaProps) => (
  <div className={clsx(styles.row, isError && styles.error)}>
    {isError ? <CancelIcon /> : <CheckCircleIcon />}
    <Typography variant="button-m">{text}</Typography>
  </div>
);

const criteria: CriteriaType[] = [
  {
    translationPath: 'LENGTH',
    validationRuleName: 'isValidLength',
  },
  {
    translationPath: 'LOWERCASE',
    validationRuleName: 'isContainsLowercase',
  },
  {
    translationPath: 'UPPERCASE',
    validationRuleName: 'isContainsUppercase',
  },
  {
    translationPath: 'NUMBER',
    validationRuleName: 'isContainsNumber',
  },
];

const PasswordCriteria = ({ id = 'password' }: PasswordCriteriaProps) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-restricted-syntax
  const { watch } = useFormContext();
  const validation = useValidatePassword(watch(id));

  return (
    <div className={styles.container}>
      {criteria.map(({ translationPath, validationRuleName }) => (
        <Criteria
          key={translationPath}
          isError={!validation[validationRuleName]}
          text={t(`AUTH.PASSWORD_STRENGTH.${translationPath}`)}
        />
      ))}
      <Criteria
        isError={!validation.isContainsSpecialCharacter}
        text={
          <>
            {' '}
            {t('AUTH.PASSWORD_STRENGTH.SPECIAL_CHARACTER')} <br />{' '}
            <span className={styles.characters}>{t('AUTH.PASSWORD_STRENGTH.SPECIAL_CHARACTERS_LIST')}</span>
          </>
        }
      />
    </div>
  );
};

export default PasswordCriteria;
