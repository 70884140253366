import { ReactNode } from 'react';
import styled from '@mui/material/styles/styled';
import { getTextFieldAreaStyles } from '../TextFieldArea/utils';
import type { DesignSystemSize, DesignSystemVariant } from '../../types';

type PlaceholderProps = {
  children?: ReactNode;
  size: DesignSystemSize;
  variant: Exclude<DesignSystemVariant, 'plain'>;
};

const placeholderPosition: Record<
  Exclude<DesignSystemVariant, 'plain'>,
  Record<DesignSystemSize, Record<'left' | 'top', number>>
> = {
  solid: {
    XL: { left: 21, top: 32 },
    L: { left: 19, top: 31 },
    M: { left: 17, top: 29 },
    S: { left: 15, top: 26 },
    XS: { left: 13, top: 23 },
    XXS: { left: 11, top: 22 },
  },
  outline: {
    XL: { left: 23, top: 17 },
    L: { left: 21, top: 17 },
    M: { left: 19, top: 16 },
    S: { left: 17, top: 16 },
    XS: { left: 15, top: 14 },
    XXS: { left: 13, top: 14 },
  },
  ghost: {
    XL: { left: 0, top: 21 },
    L: { left: 0, top: 21 },
    M: { left: 0, top: 20 },
    S: { left: 0, top: 18 },
    XS: { left: 0, top: 16 },
    XXS: { left: 0, top: 16 },
  },
};

const StyledPlaceholder = styled('div')<PlaceholderProps>(({ theme, size, variant }) => ({
  position: 'absolute',
  ...placeholderPosition[variant][size],
  ...getTextFieldAreaStyles({ theme, $size: 'M' }),
  color: theme.palette.text.color3,
  opacity: 1,
  pointerEvents: 'none',
}));

const Placeholder = (props: PlaceholderProps) => <StyledPlaceholder {...props} />;

export default Placeholder;
