import { useTranslation } from 'react-i18next';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { Card, CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system/src';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';
import { useEBSStorageData } from './useEBSStorageData';
import EBSProvisionedStorageDistributionGraph from './EBSProvisionedStorageDistributionGraph';

const EBSProvisionedStorageDistributionCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useEBSStorageData();

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('EBS_STORAGE_DISTRIBUTION.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_MONTH')} icon={CalendarTodayOutlined} />
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus} pb={6}>
        <EBSProvisionedStorageDistributionGraph data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default EBSProvisionedStorageDistributionCard;
