import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import {
  ForecastPrecisionType,
  TimeSeriesDataValue,
  useUsageCostPerAccountQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeferredQuery } from '@verticeone/utils/api';
import {
  CategoryValues,
  DATE_FORMAT,
  fillMissingMonths,
  findFirstCurrency,
  getLargestNCategories,
  getSeriesByCategory,
} from '../../utils/graphDataUtils';
import { LoadableAdvanced } from '@verticeone/utils/async';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import useAccountNameMap from '../../hooks/useAccountNameMap';
import { AWS_DEFAULT_CURRENCY } from '../../constants';

const startDate = addMonths(startOfMonth(new Date()), -11);

const NUMBER_OF_SERIES = 5;
const AGGREGATED_CATEGORY = 'others';

export type SpendByAccountData = {
  currency: string;

  /** All months in the X axis in the format of yyyy-MM-dd. */
  months: string[];

  /** The list of accounts with the monetary values. The number of values should correspond to the number of months. */
  values: SeriesOptionsWithData[];

  /** The list of used series in the required order */
  usedCategories: string[];
};

const useSpendByAccountData = (): LoadableAdvanced<SpendByAccountData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { accountId } = useSelector(getAccount);
  const { accountNameMap, error: accountError } = useAccountNameMap();

  const { data: rawData, error: dataError } = useDeferredQuery(
    useUsageCostPerAccountQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  const getDataSeries = (
    monthlyData: TimeSeriesDataValue[][],
    usedCategories: string[],
    accountNames: { [id: string]: string }
  ) => {
    const seriesByCategory = getSeriesByCategory(monthlyData, usedCategories, {
      aggregatedCategory: AGGREGATED_CATEGORY,
    });

    return seriesByCategory.map((categorySeries: CategoryValues) => {
      const categoryId = categorySeries.id;
      const accountName = categoryId === AGGREGATED_CATEGORY ? t('SPEND_BY_ACCOUNT.OTHERS') : accountNames[categoryId];

      return {
        ...categorySeries,
        name: accountName ?? categoryId,
        type: 'column',
      };
    });
  };

  const computed = useMemo(() => {
    if (!rawData || !accountNameMap) return undefined;
    const allMonthsData = fillMissingMonths(rawData, startDate, []);
    const allMonthsDataValues = allMonthsData.map(({ values }) =>
      values.filter(({ values: unitValues }) => unitValues[0] >= 0)
    );

    const usedCategories = getLargestNCategories(allMonthsDataValues, {
      maxNumberOfSeries: NUMBER_OF_SERIES,
      aggregatedCategory: AGGREGATED_CATEGORY,
    });
    return {
      months: allMonthsData.map(({ time }) => time!),
      values: getDataSeries(allMonthsDataValues, usedCategories, accountNameMap) as SeriesOptionsWithData[],
      currency: findFirstCurrency(allMonthsData) ?? AWS_DEFAULT_CURRENCY,
      usedCategories,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, accountNameMap]);

  return {
    error: dataError || accountError,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useSpendByAccountData;
