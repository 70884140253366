import { ReactNode, forwardRef, useCallback, useState } from 'react';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { CustomizedProps, DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { textFieldVariantDefinitions } from './variantDefinitions';
import { testProps } from '../../utils/testProperties';

export type TextFieldVariant = 'outlined' | 'solid' | 'ghost';
export const allTextFieldVariants: TextFieldVariant[] = ['outlined', 'solid', 'ghost'];

export type TextFieldProps = TestProps &
  CustomizedProps &
  Omit<MuiTextFieldProps, 'size' | 'variant' | 'color'> & {
    variant?: TextFieldVariant;
    size?: DesignSystemSize;
    color?: DesignSystemColor;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
  };

const MUI_VARIANTS_WITH_UNDERLINE = ['standard', 'filled'];

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      variant = 'outlined',
      color = 'primary',
      size = 'M',
      hiddenLabel = false,
      InputProps,
      value,
      defaultValue,
      onChange,
      onKeyDown,
      onWheel,
      type,
      startAdornment,
      endAdornment,
      testId,
      ...otherProps
    },
    ref
  ) => {
    const { component: StyledTextField, muiVariant } =
      textFieldVariantDefinitions[variant] ?? textFieldVariantDefinitions.outlined;
    const isControlled = typeof value !== 'undefined';

    // We mirror internal value upwards for uncontrolled use-cases so that we can apply styles based on current value.
    const [mirroredValue, setMirroredValue] = useState(isControlled ? null : defaultValue);
    const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
      (e, ...props) => {
        if (!isControlled) {
          setMirroredValue(e.target.value);
        }
        if (onChange) {
          onChange(e, ...props);
        }
      },
      [onChange, isControlled]
    );

    const handleWheel = useCallback<NonNullable<TextFieldProps['onWheel']>>(
      (e, ...props) => {
        if (type === 'number') {
          // prevent value changing in Safari and Chrome as well
          (e.target as HTMLElement).blur();
        }

        if (onWheel) {
          onWheel(e, ...props);
        }
      },
      [onWheel, type]
    );

    const handleKeyDown = useCallback<NonNullable<TextFieldProps['onKeyUp']>>(
      (e, ...props) => {
        if (type === 'number' && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
          e.preventDefault();
        }

        if (onKeyDown) {
          onKeyDown(e, ...props);
        }
      },
      [onKeyDown, type]
    );

    return (
      <StyledTextField
        ref={ref}
        variant={muiVariant}
        size={size}
        hiddenLabel={hiddenLabel}
        color={color}
        InputProps={{
          ...(MUI_VARIANTS_WITH_UNDERLINE.includes(muiVariant) ? { disableUnderline: true } : {}),
          startAdornment,
          endAdornment,
          ...InputProps,
        }}
        type={type}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onWheel={handleWheel}
        {...otherProps}
        {...testProps(testId, 'text-field')}
        $internalValue={isControlled ? value : mirroredValue}
      />
    );
  }
);
