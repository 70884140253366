import { PredefinedFormDef } from '../shared/types';
import { zodSchema, FormData } from './schema';
import { AssignRequestOwnerForm } from './AssignRequestOwnerForm';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/core\/assignees\/requestOwner\/v\d+$/,
  component: AssignRequestOwnerForm,
  schema: zodSchema,
  getDefaultValues: (getValue) => ({
    requestOwner: getValue('requestOwner'),
  }),
};
