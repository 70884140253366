import { useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';

const DRAWER_WIDTH = 900;

export const useFitTaskNodeIntoView = () => {
  const flow = useReactFlow();

  const fitTaskNodeIntoView = useCallback(
    (nodeId: string) => {
      const selectedNode = flow.getInternalNode(nodeId);

      if (!selectedNode) {
        return;
      }

      const { measured, position } = selectedNode;

      const targetZoom = Math.max(flow.getZoom(), 0.7);

      const xOffsetByDrawer = DRAWER_WIDTH / 2 / targetZoom;
      const xOffsetByNode = (measured?.width ?? 0) / 2;
      const yOffsetByNode = (measured?.height ?? 0) / 2;

      const x = position.x + xOffsetByNode + xOffsetByDrawer;
      const y = position.y + yOffsetByNode;

      void flow.setCenter(x, y, { zoom: targetZoom, duration: 1000 });
    },
    [flow]
  );

  return {
    fitTaskNodeIntoView,
  };
};
