import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { Link as RouterLink } from 'react-router-dom';
import { Text } from '@verticeone/design-system/src';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import VendorSearchFilter from './filters/VendorSearchFilter';
import { CategoryFilter } from './filters/CategoryFilter';
import MyVendorButton from './filters/MyVendorButton';
import { VendorListFilterContext } from '../VendorListFilterContext';
import { VendorListFilter } from '../types';

const VendorsPageHeader = () => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const { listFilter } = useContext(VendorListFilterContext);
  const listTitle = listFilter === VendorListFilter.ALL_VENDORS ? t('VENDORS.ALL_VENDORS') : t('VENDORS.MY_VENDORS');

  return (
    <Stack direction="row" justifyContent="space-between" gap={2} p={8}>
      <Stack gap={1}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
          <BreadcrumbLink component={RouterLink} to={generatePath(routes.VENDORS.PATH)}>
            {t('SAAS.NAVBAR.VENDORS')}
          </BreadcrumbLink>
        </Breadcrumbs>
        <Text variant="heading" size="M" tag="h1">
          {listTitle}
        </Text>
      </Stack>
      <Stack direction="row" gap={4} alignSelf="flex-start" alignItems="stretch">
        <VendorSearchFilter />
        <CategoryFilter />
        <MyVendorButton />
      </Stack>
    </Stack>
  );
};

export default VendorsPageHeader;
