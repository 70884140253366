import React from 'react';

import EC2CoverageCard from '@vertice/dashboard/src/modules/cloud/cards/EC2CoverageCard/EC2CoverageCard';
import AWSSupportFeesCard from '@vertice/dashboard/src/modules/cloud/cards/AWSSupportFeesCard/AWSSupportFeesCard';
import EC2ProcessorUsageCard from '@vertice/dashboard/src/modules/cloud/cards/EC2ProcessorUsageCard/EC2ProcessorUsageCard';
import HistoricalSpendByEC2ProcessorCard from '@vertice/dashboard/src/modules/cloud/cards/HistoricalSpendByEC2ProcessorCard/HistoricalSpendByEC2ProcessorCard';
import RDSReservedInstancesCard from '@vertice/dashboard/src/modules/cloud/cards/RDSReservedInstancesCard/RDSReservedInstancesCard';
import ExpiringReservedInstancesCard from '@vertice/dashboard/src/modules/cloud/cards/ExpiringReservedInstancesCard/ExpiringReservedInstancesCard';
import CloudWatchHistoricalSpendCard from '@vertice/dashboard/src/modules/cloud/cards/CloudWatchHistoricalSpendCard/CloudWatchHistoricalSpendCard';
import CloudWatchFeaturesBySpendCard from '@vertice/dashboard/src/modules/cloud/cards/CloudWatchFeaturesBySpendCard/CloudWatchFeaturesBySpendCard';
import S3IntelligentTieringCard from '@vertice/dashboard/src/modules/cloud/cards/S3IntelligentTieringCard/S3IntelligentTieringCard';
import ElasticacheBackupCostsCard from '@vertice/dashboard/src/modules/cloud/cards/ElasticacheBackupCostsCard/ElasticacheBackupCostsCard';

import ExtensionGrid from './components/ExtensionGrid';
import GenericCard from './components/GenericCard';
import GenericTable, { IntFormatter, DateFormatter, Chip, Column } from './components/GenericTable';
import type { Code } from './types';
import { differenceInDays } from 'date-fns/fp';
import { Trans } from 'react-i18next';
import { PercentageGraph } from './components/GenericGraph';
import VR19 from './components/LayoutExtensions/VR19';
import VR31 from './components/LayoutExtensions/VR31/VR31';

const now = new Date();

export const testLayoutExtensions: Partial<Record<Code, JSX.Element>> = {
  'VR-6': <AWSSupportFeesCard />,
  'VR-12': <EC2CoverageCard testCode="VT-12" headerLabel="SP_COVERAGE" />,
  'VR-13': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-13.TABLE" show={['title']}>
      <GenericTable
        code="VT-13"
        testId="savings-plan-utilization"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-13.TABLE"
        columns={
          [
            'discount_id',
            'region',
            'account_id',
            'offering_type',
            'used_commitment',
            'total_commitment',
            'utilization',
          ] as const
        }
        getRowId={(row) => `${row.discount_id}-${row.account_id}`}
        defaultSortColumns={{ field: 'utilization', sort: 'asc' }}
        renderCell={{
          used_commitment: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          total_commitment: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          utilization: (value) => <IntFormatter value={value as number} formatStyle="percent" />,
        }}
      />
    </GenericCard>
  ),
  'VR-14': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-14.TABLE" show={['title']}>
      <GenericTable
        code="VT-14"
        testId="savings-plan-utilization"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-14.TABLE"
        columns={
          [
            'discount_id',
            'region',
            'account_id',
            'offering_type',
            'payment_option',
            'purchase_term',
            'unblended_cost',
            'total_commitment',
            'utilization',
            'savings',
            'end_time',
          ] as const
        }
        getRowId={(row) => `${row.discount_id}-${row.account_id}`}
        defaultSortColumns={{ field: 'end_time', sort: 'asc' }}
        renderCell={{
          unblended_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          total_commitment: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          utilization: (value) => <IntFormatter value={value as number} formatStyle="percent" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          end_time: (value, row) => {
            const endDate = new Date(value);
            const daysRemaining = differenceInDays(now, endDate);
            return (
              <Column direction="row">
                <DateFormatter value={value as number} />
                <Chip color={daysRemaining < 61 ? (daysRemaining < 31 ? 'error' : 'warning') : 'neutral'}>
                  <Trans i18nKey="UNITS.DAY" values={{ count: daysRemaining }} />
                </Chip>
              </Column>
            );
          },
        }}
      />
    </GenericCard>
  ),
  'VR-18': <EC2CoverageCard testCode="VT-18" headerLabel="RI_COVERAGE" />,
  'VR-19': <VR19 />,
  'VR-20': <ExpiringReservedInstancesCard />,
  'VR-22': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-22.TABLE'} show={['title']}>
      <GenericTable
        code="VT-22"
        testId="rightsize-ec2"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-22.TABLE"
        columns={
          [
            'account_id',
            'region',
            'resource_id',
            'current_resource_summary',
            'recommended_resource_summary',
            'cost',
            'proposed_cost',
            'savings',
          ] as const
        }
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 600 },
        }}
      />
    </GenericCard>
  ),
  'VR-23': (
    <ExtensionGrid breakpoints={[5, 7]}>
      <EC2ProcessorUsageCard sx={{ flex: 1 }} />
      <HistoricalSpendByEC2ProcessorCard />
      <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-23.TABLE" show={['title']}>
        <GenericTable
          code="VT-23"
          limit={100}
          testId="intel-to-amd-migration-cost-savings-analysis"
          translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-23.TABLE"
          columns={
            [
              'resource_id',
              'account_id',
              'region',
              'instance_family',
              'operating_system',
              'processor',
              'sum_cost',
              'savings',
            ] as const
          }
          getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
          defaultSortColumns={{ field: 'savings', sort: 'desc' }}
          renderCell={{
            sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          }}
          columnSettings={{
            resource_id: { flex: 1, minWidth: 140 },
            account_id: { flex: 1, minWidth: 170 },
            region: { flex: 1, minWidth: 130 },
            instance_family: { flex: 1, minWidth: 165 },
            operating_system: { flex: 1, minWidth: 140 },
            processor: { flex: 1, minWidth: 120 },
            sum_cost: { flex: 1, minWidth: 130, align: 'right', headerAlign: 'right' },
            savings: { flex: 1, minWidth: 200, align: 'right', headerAlign: 'right' },
          }}
        />
      </GenericCard>
    </ExtensionGrid>
  ),
  'VR-24': (
    <ExtensionGrid breakpoints={[5, 7]}>
      <EC2ProcessorUsageCard sx={{ flex: 1 }} />
      <HistoricalSpendByEC2ProcessorCard />
      <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-24.TABLE" show={['title']}>
        <GenericTable
          code="VT-24"
          limit={100}
          testId="graviton-vs-intel-amd-cost-savings-analysis"
          translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-24.TABLE"
          columns={
            [
              'resource_id',
              'account_id',
              'region',
              'instance_family',
              'operating_system',
              'processor',
              'sum_cost',
              'savings',
            ] as const
          }
          getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
          defaultSortColumns={{ field: 'savings', sort: 'desc' }}
          renderCell={{
            sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          }}
          columnSettings={{
            resource_id: { flex: 1, minWidth: 140 },
            account_id: { flex: 1, minWidth: 170 },
            region: { flex: 1, minWidth: 130 },
            instance_family: { flex: 1, minWidth: 165 },
            operating_system: { flex: 1, minWidth: 140 },
            processor: { flex: 1, minWidth: 120 },
            sum_cost: { flex: 1, minWidth: 130, align: 'right', headerAlign: 'right' },
            savings: { flex: 1, minWidth: 200, align: 'right', headerAlign: 'right' },
          }}
        />
      </GenericCard>
    </ExtensionGrid>
  ),
  'VR-26': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-26.TABLE" show={['title']}>
      <GenericTable
        code="VT-26"
        testId="idle-vpc-endpoints-detected"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-26.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost_per_resource_and_unit'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'cost_per_resource_and_unit', sort: 'desc' }}
        renderCell={{
          cost_per_resource_and_unit: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          account_id: { width: 180 },
          region: { width: 200 },
          cost_per_resource_and_unit: { width: 260, align: 'right', headerAlign: 'right' },
        }}
      />
    </GenericCard>
  ),
  'VR-31': <VR31 />,
  'VR-32': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-32.TABLE" show={['title']}>
      <GenericTable
        code="VT-32"
        testId="unattached-ebs-volumes-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-32.TABLE"
        columns={['volume_id', 'region', 'account_id', 'volume_type', 'size', 'cost'] as const}
        getRowId={(row) => `${row.volume_id}-${row.volume_type}-${row.account_id}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          size: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-33': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-33.TABLE'} show={['title']}>
      <GenericTable
        code="VT-33"
        testId="orphaned-ebs-snapshots-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-33.TABLE"
        columns={
          [
            'snapshot_id',
            'account_id',
            'description',
            'region',
            'start_time',
            'storage_tier',
            'volume_id',
            'volume_size',
            'cost',
          ] as const
        }
        getRowId={(row) => `${row.snapshot_id}-${row.account_id}-${row.volume_id}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          start_time: (value) => <DateFormatter value={value as number} dateStyle="medium" />,
          volume_size: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-36': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-36.TABLE" show={['title']}>
      <GenericTable
        code="VT-36"
        testId="cloudfront-private-rate-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-36.TABLE"
        columns={['product_family', 'cf_location', 'usage_amount', 'unit', 'cost'] as const}
        getRowId={(row) => `${row.product_family}-${row.cf_location}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          usage_amount: (value) => <IntFormatter value={value as number} formatStyle="decimal" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-37': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-37.TABLE" show={['title']}>
      <GenericTable
        code="VT-37"
        testId="cloudfront-security-savings-bundle"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-37.TABLE"
        columns={['product', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.product}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-38': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-38.TABLE'} show={['title']}>
      <GenericTable
        code="VT-38"
        testId="documentdb-backups"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-38.TABLE"
        columns={['linked_account_id', 'region', 'resource_name', 'usage_amount', 'cost'] as const}
        getRowId={(row) => `${row.linked_account_id}-${row.region}-${row.resource_name}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          usage_amount: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
        }}
      />
    </GenericCard>
  ),
  'VR-40': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-40.TABLE" show={['title']}>
      <GenericTable
        code="VT-36"
        testId="cloudfront-private-rate-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-40.TABLE"
        columns={['product_family', 'cf_location', 'usage_amount', 'unit', 'cost'] as const}
        getRowId={(row) => `${row.product_family}-${row.cf_location}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          usage_amount: (value) => <IntFormatter value={value as number} formatStyle="decimal" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-41': (
    <ExtensionGrid>
      <CloudWatchHistoricalSpendCard />
      <CloudWatchFeaturesBySpendCard />
    </ExtensionGrid>
  ),
  'VR-52': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-52.TABLE" show={['title']}>
      <GenericTable
        code="VT-52"
        limit={100}
        testId="opensearch-service-migration-to-graviton-cost-savings-analysis"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-52.TABLE"
        columns={
          ['resource_id', 'account_id', 'region', 'instance_family', 'processor', 'sum_cost', 'savings'] as const
        }
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 140 },
          account_id: { flex: 1, minWidth: 170 },
          region: { flex: 1, minWidth: 130 },
          instance_family: { flex: 1, minWidth: 165 },
          processor: { flex: 1, minWidth: 120 },
          sum_cost: { flex: 1, minWidth: 130, align: 'right', headerAlign: 'right' },
          savings: { flex: 1, minWidth: 200, align: 'right', headerAlign: 'right' },
        }}
      />
    </GenericCard>
  ),
  'VR-55': <RDSReservedInstancesCard />,
  'VR-56': (
    <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-56.TABLE" show={['title']}>
      <GenericTable
        code="VT-56"
        limit={100}
        testId="rds-graviton-processor-cost-savings-analysis"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-56.TABLE"
        columns={
          ['resource_id', 'account_id', 'region', 'instance_family', 'processor', 'sum_cost', 'savings'] as const
        }
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 140 },
          account_id: { flex: 1, minWidth: 170 },
          region: { flex: 1, minWidth: 130 },
          instance_family: { flex: 1, minWidth: 165 },
          processor: { flex: 1, minWidth: 120 },
          sum_cost: { flex: 1, minWidth: 130, align: 'right', headerAlign: 'right' },
          savings: { flex: 1, minWidth: 200, align: 'right', headerAlign: 'right' },
        }}
      />
    </GenericCard>
  ),
  'VR-59': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-59.TABLE'} show={['title']}>
      <GenericTable
        code="VT-59"
        testId="sagemaker-savings-plan-coverage"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-59.TABLE"
        columns={
          [
            'aws_account_id',
            'region',
            'instance_family',
            'instance_size',
            'component',
            'sp_coverage',
            'cost',
            'proposed_ml_sp_cost',
            'savings_amount_ml_sp_cost',
          ] as const
        }
        getRowId={(row) =>
          `${row.aws_account_id}-${row.region}-${row.instance_family}-${row.instance_size}-${row.component}`
        }
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_ml_sp_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings_amount_ml_sp_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          sp_coverage: (value) => <IntFormatter value={value as number} formatStyle="percent" />,
        }}
      />
    </GenericCard>
  ),
  'VR-60': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-60.TABLE'} show={['title']}>
      <GenericTable
        code="VT-60"
        testId="s3-lifecycle-policy"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-60.TABLE"
        columns={
          ['account_id', 'resource_id', 'sum_usage_amount', 'sum_unblended_cost', 'proposed_cost_lifecycle'] as const
        }
        getRowId={(row) => `${row.resource_id}-${row.account_id}`}
        defaultSortColumns={{ field: 'proposed_cost_lifecycle', sort: 'desc' }}
        renderCell={{
          sum_usage_amount: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          sum_unblended_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost_lifecycle: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-61': <S3IntelligentTieringCard />,
  'VR-68': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-68.TABLE'} show={['title']}>
      <GenericTable
        code="VT-68"
        testId="dynamodb-standard-class"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-68.TABLE"
        columns={
          [
            'account_id',
            'resource_name',
            'size',
            'read_requests',
            'write_requests',
            'resource_cost',
            'proposed_cost',
            'savings',
          ] as const
        }
        getRowId={(row) => `${row.resource_name}-${row.account_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          size: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          resource_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-71': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-71.TABLE'} show={['title']}>
      <GenericTable
        code="VT-71"
        testId="idle-ip4"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-71.TABLE"
        columns={['resource_id', 'region', 'account_name', 'address_type', 'usage', 'cost'] as const}
        getRowId={(row) => `${row.account_name}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 600 },
        }}
      />
    </GenericCard>
  ),
  'VR-80': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-80.TABLE'} show={['title']}>
      <GenericTable
        code="VT-80"
        testId="rightsize-lambda"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-80.TABLE"
        columns={
          [
            'account_id',
            'region',
            'resource_id',
            'current_resource_summary',
            'recommended_resource_summary',
            'cost',
            'proposed_cost',
            'savings',
          ] as const
        }
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          current_resource_summary: (value) => (
            <IntFormatter value={value as number} formatStyle="unit" unit="megabyte" />
          ),
          recommended_resource_summary: (value) => (
            <IntFormatter value={value as number} formatStyle="unit" unit="megabyte" />
          ),
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 600 },
        }}
      />
    </GenericCard>
  ),
  'VR-91': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-91.TABLE'} show={['title']}>
      <GenericTable
        code="VT-91"
        testId="efs-life-cycle"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-91.TABLE"
        columns={['account_id', 'region', 'file_system_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.file_system_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-101': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-101.TABLE'} show={['title']}>
      <GenericTable
        code="VT-101"
        testId="orphaned-ebs-snapshots-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-101.TABLE"
        columns={
          [
            'snapshot_id',
            'account_id',
            'description',
            'region',
            'start_time',
            'storage_tier',
            'volume_id',
            'volume_size',
            'cost',
          ] as const
        }
        getRowId={(row) => `${row.snapshot_id}-${row.account_id}-${row.volume_id}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          start_time: (value) => <DateFormatter value={value as number} dateStyle="medium" />,
          volume_size: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-102': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-102.TABLE'} show={['title']}>
      <GenericTable
        code="VT-102"
        testId="orphaned-ebs-snapshots-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-102.TABLE"
        columns={
          [
            'snapshot_id',
            'account_id',
            'description',
            'region',
            'start_time',
            'storage_tier',
            'volume_id',
            'volume_size',
            'cost',
          ] as const
        }
        getRowId={(row) => `${row.snapshot_id}-${row.account_id}-${row.volume_id}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          start_time: (value) => <DateFormatter value={value as number} dateStyle="medium" />,
          volume_size: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-103': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-103.TABLE'} show={['title']}>
      <GenericTable
        code="VT-28"
        testId="dynamodb-standard-IA-class"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-103.TABLE"
        columns={
          [
            'account_id',
            'resource_name',
            'size',
            'read_requests',
            'write_requests',
            'resource_cost',
            'proposed_cost',
            'savings',
          ] as const
        }
        getRowId={(row) => `${row.resource_name}-${row.account_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          size: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
          resource_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-104': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-104.GRAPH'} show={['title', 'subtitle']}>
      <PercentageGraph code="VT-104" series={{ value: 'sp_coverage', xAxis: 'time' }} target={0.4} />
    </GenericCard>
  ),
  'VR-119': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-119.TABLE'} show={['title']}>
      <GenericTable
        code="VT-119"
        testId="ebs-storage-fsr"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-119.TABLE"
        columns={['account_id', 'resource_id', 'sum_cost'] as const}
        getRowId={(row) => `${row.account_id}-${row.resource_id}`}
        defaultSortColumns={{ field: 'sum_cost', sort: 'desc' }}
        renderCell={{
          sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-111': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-111.TABLE'} show={['title']}>
      <GenericTable
        code="VT-111"
        testId="fsx-for-ontap-efficient-storage"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-111.TABLE"
        columns={
          [
            'account_id',
            'region',
            'file_system_id',
            'storage_configured_size',
            'cost',
            'proposed_cost',
            'savings',
          ] as const
        }
        getRowId={(row) => `${row.account_id}-${row.region}-${row.file_system_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          storage_configured_size: (value) => (
            <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />
          ),
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-113': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-113.TABLE'} show={['title']}>
      <GenericTable
        code="VT-113"
        testId="idle-application-lb"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-113.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-114': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-114.TABLE'} show={['title']}>
      <GenericTable
        code="VT-114"
        testId="idle-network-lb"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-114.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-117': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-117.TABLE'} show={['title']}>
      <GenericTable
        code="VT-117"
        testId="ec2-idle-nat-gateway"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-117.TABLE"
        columns={['account_id', 'region', 'resource_id', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-120': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-120.TABLE'} show={['title']}>
      <GenericTable
        code="VT-120"
        testId="fsx-storage-lustre-rc20"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-120.TABLE"
        columns={['account_id', 'resource_name', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.resource_name}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-121': <ElasticacheBackupCostsCard />,
  'VR-122': <ElasticacheBackupCostsCard />,
  'VR-123': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-123.TABLE'} show={['title']}>
      <GenericTable
        code="VT-123"
        testId="dynamo-on-demand-backups-pitr"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-123.TABLE"
        columns={['linked_account_id', 'region', 'table_name', 'usage_amount', 'number_of_backups', 'cost'] as const}
        getRowId={(row) => `${row.linked_account_id}-${row.region}-${row.table_name}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          usage_amount: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
        }}
      />
    </GenericCard>
  ),
  'VR-124': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-124.TABLE'} show={['title']}>
      <GenericTable
        code="VT-124"
        testId="rds-charged-backups-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-124.TABLE"
        columns={['resource_name', 'region', 'account_id', 'sum_cost'] as const}
        getRowId={(row) => `${row.resource_name}-${row.region}-${row.account_id}`}
        defaultSortColumns={{ field: 'sum_cost', sort: 'desc' }}
        renderCell={{
          sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-125': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-125.GRAPH'} show={['title', 'subtitle']}>
      <PercentageGraph code="VT-125" series={{ value: 'sp_coverage', xAxis: 'time' }} target={0.8} />
    </GenericCard>
  ),
  'VR-126': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-126.TABLE'} show={['title']}>
      <GenericTable
        code="VT-126"
        testId="number-of-breaching-clusters"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-126.TABLE"
        columns={['account_id', 'region', 'cluster_name', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.cluster_name}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-128': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-128.TABLE'} show={['title']}>
      <GenericTable
        code="VT-128"
        testId="dynamo-on-demand-backups-cold-storage"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-128.TABLE"
        columns={['linked_account_id', 'region', 'table_name', 'usage_amount', 'number_of_backups', 'cost'] as const}
        getRowId={(row) => `${row.linked_account_id}-${row.region}-${row.table_name}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          usage_amount: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="gigabyte" />,
        }}
      />
    </GenericCard>
  ),
  'VR-129': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-129.TABLE'} show={['title']}>
      <GenericTable
        code="VT-129"
        testId="glue-spart-job-regular-schedule"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-129.TABLE"
        columns={['job_name', 'account_id', 'region', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.job_name}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-130': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-130.TABLE'} show={['title']}>
      <GenericTable
        code="VT-130"
        testId="documentdb-high-io-standard-clusters"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-130.TABLE"
        columns={['account_id', 'region', 'cluster_name', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.cluster_name}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-131': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-131.TABLE'} show={['title']}>
      <GenericTable
        code="VT-131"
        testId="orphaned-kms-keys"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-131.TABLE"
        columns={['key_id', 'account_id', 'region', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.key_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-132': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-132.TABLE'} show={['title']}>
      <GenericTable
        code="VT-132"
        testId="athena-queries"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-132.TABLE"
        columns={['account_id', 'region', 'resource_name', 'usage', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_name}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          usage: (value) => <IntFormatter value={value as number} formatStyle="unit" unit="terabyte" />,
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-133': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-133.TABLE'} show={['title']}>
      <GenericTable
        code="VT-133"
        testId="rds-extended-support"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-133.TABLE"
        columns={['account_id', 'region', 'db_name', 'db_engine', 'cost'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.db_name}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-134': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-134.TABLE'} show={['title']}>
      <GenericTable
        code="VT-134"
        testId="neptune-high-io-standard-clusters"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-134.TABLE"
        columns={['account_id', 'region', 'cluster_name', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.cluster_name}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-135': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-135.TABLE'} show={['title']}>
      <GenericTable
        code="VT-135"
        testId="vpc-flow-logs-cw-ia"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-135.TABLE"
        columns={['account_id', 'region', 'log_group_name', 'flow_log_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.flow_log_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-136': (
    <ExtensionGrid breakpoints={[5, 7]}>
      <EC2ProcessorUsageCard sx={{ flex: 1 }} />
      <HistoricalSpendByEC2ProcessorCard />
      <GenericCard
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.EC2_AMD_AND_INTEL_TO_GRAVITON_MIGRATION.TABLE"
        show={['title']}
      >
        <GenericTable
          code="VT-136"
          limit={100}
          testId="graviton-vs-intel-amd-cost-savings-analysis"
          translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.EC2_AMD_AND_INTEL_TO_GRAVITON_MIGRATION.TABLE"
          columns={
            [
              'resource_id',
              'account_id',
              'region',
              'instance_family',
              'operating_system',
              'processor',
              'sum_cost',
              'savings',
            ] as const
          }
          getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
          defaultSortColumns={{ field: 'savings', sort: 'desc' }}
          renderCell={{
            sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          }}
          columnSettings={{
            resource_id: { flex: 1, minWidth: 140 },
            account_id: { flex: 1, minWidth: 170 },
            region: { flex: 1, minWidth: 130 },
            instance_family: { flex: 1, minWidth: 165 },
            operating_system: { flex: 1, minWidth: 140 },
            processor: { flex: 1, minWidth: 120 },
            sum_cost: { flex: 1, minWidth: 130, align: 'right', headerAlign: 'right' },
            savings: { flex: 1, minWidth: 200, align: 'right', headerAlign: 'right' },
          }}
        />
      </GenericCard>
    </ExtensionGrid>
  ),
  'VR-137': (
    <ExtensionGrid breakpoints={[5, 7]}>
      <EC2ProcessorUsageCard sx={{ flex: 1 }} />
      <HistoricalSpendByEC2ProcessorCard />
      <GenericCard
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.EC2_AMD_AND_INTEL_TO_GRAVITON_MIGRATION.TABLE"
        show={['title']}
      >
        <GenericTable
          code="VT-137"
          limit={100}
          testId="graviton-vs-intel-amd-cost-savings-analysis"
          translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.EC2_AMD_AND_INTEL_TO_GRAVITON_MIGRATION.TABLE"
          columns={
            [
              'resource_id',
              'account_id',
              'region',
              'instance_family',
              'operating_system',
              'processor',
              'sum_cost',
              'savings',
            ] as const
          }
          getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
          defaultSortColumns={{ field: 'savings', sort: 'desc' }}
          renderCell={{
            sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          }}
          columnSettings={{
            resource_id: { flex: 1, minWidth: 140 },
            account_id: { flex: 1, minWidth: 170 },
            region: { flex: 1, minWidth: 130 },
            instance_family: { flex: 1, minWidth: 165 },
            operating_system: { flex: 1, minWidth: 140 },
            processor: { flex: 1, minWidth: 120 },
            sum_cost: { flex: 1, minWidth: 130, align: 'right', headerAlign: 'right' },
            savings: { flex: 1, minWidth: 200, align: 'right', headerAlign: 'right' },
          }}
        />
      </GenericCard>
    </ExtensionGrid>
  ),
  'VR-138': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-138.TABLE'} show={['title']}>
      <GenericTable
        code="VT-138"
        testId="route53-network-interface-resolvers"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-138.TABLE"
        columns={['region', 'resolvers_count', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.region}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-139': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-139.TABLE'} show={['title']}>
      <GenericTable
        code="VT-139"
        testId="dms-multiple-az"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-139.TABLE"
        columns={
          [
            'account_id',
            'region',
            'resource_name',
            'product_family',
            'unit',
            'usage',
            'cost',
            'proposed_cost',
            'savings',
          ] as const
        }
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_name}-${row.product_family}`}
        defaultSortColumns={{ field: 'cost', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-140': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-140.TABLE'} show={['title']}>
      <GenericTable
        code="VT-140"
        testId="idle-classic-lb"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-140.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-141': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-141.TABLE'} show={['title']}>
      <GenericTable
        code="VT-141"
        testId="idle-gateway-lb"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-141.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  ),
  'VR-142': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-142.TABLE'} show={['title']}>
      <GenericTable
        code="VT-142"
        testId="stop-ec2"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-142.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 600 },
        }}
      />
    </GenericCard>
  ),
  'VR-143': (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-143.TABLE'} show={['title']}>
      <GenericTable
        code="VT-143"
        testId="efs-multiaz"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.TESTS.VR-143.TABLE"
        columns={['account_id', 'region', 'resource_id', 'cost', 'proposed_cost', 'savings'] as const}
        getRowId={(row) => `${row.account_id}-${row.region}-${row.resource_id}`}
        defaultSortColumns={{ field: 'savings', sort: 'desc' }}
        renderCell={{
          cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          proposed_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
          savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
        columnSettings={{
          resource_id: { flex: 1, minWidth: 600 },
        }}
      />
    </GenericCard>
  ),
};
