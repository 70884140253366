import React from 'react';
import { styled } from '@mui/material';
import Loader, { LoaderProps } from './Loader';

type LoadableComponentProps = LoaderProps & {
  isLoading: boolean;
  className?: string;
  children: React.ReactNode;
};

const LoadableComponentWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 2rem;
`;

export const LoadableComponent = ({ isLoading, children, className = '', ...loaderProps }: LoadableComponentProps) => {
  if (isLoading) {
    return (
      <LoadableComponentWrapper className={className}>
        <Loader {...loaderProps} />
      </LoadableComponentWrapper>
    );
  }

  return <>{children}</>;
};
