import { ErrorCode } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';
import { useFileupload } from './Fileupload';
import { getFileTypes, formatSize } from './utils';

type ConvertType<T extends string> = T extends `${infer First}-${infer Rest}`
  ? `${Uppercase<First>}_${ConvertType<Rest>}`
  : Uppercase<T>;

const ERROR_CODE_LIST = ['file-too-small', 'file-too-large', 'too-many-files', 'file-invalid-type'] as const;
type ERROR_CODES = ConvertType<(typeof ERROR_CODE_LIST)[number]> | 'UNKNOWN_ERROR';
type ERROR_KEY = `DRAG_AND_DROP.FILE_LIST.ERRORS.${ERROR_CODES}`;

export const useGetErrorMessage = () => {
  const { t } = useTranslation(undefined, { i18n });
  const { acceptedTypes, maxFileSize } = useFileupload();
  const getErrorKey = (errorCode: ErrorCode): ERROR_CODES => {
    if (ERROR_CODE_LIST.includes(errorCode)) {
      return errorCode.toUpperCase().replace(/-/g, '_') as Extract<ERROR_CODES, 'UNKNOWN_ERROR'>;
    }

    return 'UNKNOWN_ERROR';
  };

  return (errorCode: ErrorCode) => {
    const errorKey = ['DRAG_AND_DROP.FILE_LIST.ERRORS', getErrorKey(errorCode)].join('.') as ERROR_KEY;
    return t(errorKey, { size: formatSize(maxFileSize), type: getFileTypes(acceptedTypes).join(', ') });
  };
};
