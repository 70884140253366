import { PredefinedFormDef } from '../shared/types';
import { ApprovalSimpleForm } from './ApprovalSimpleForm';
import { FormData, zodSchema } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/core\/approval\/simple\/v\d+$/,
  component: ApprovalSimpleForm,
  schema: zodSchema,
  getDefaultValues: (getValue) => ({
    approved: getValue('approved', true),
  }),
};
