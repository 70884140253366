import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import { Link, generatePath } from 'react-router-dom';
import { Text } from '@verticeone/design-system/src';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import useIntakeForm from '../../../hooks/useIntakeForm';
import useContractListExport from '@vertice/core/src/modules/saas/contract/hooks/useContractListExport';
import { isCancelledContract } from '@vertice/core/src/modules/saas/contract/computed';

export const PageHeader: FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { userCanAddExistingContract } = useIntakeForm();
  const { canExportContracts, downloadFile, isExportInProgress } = useContractListExport({
    filter: (view) => !isCancelledContract(view.contract),
  });

  const goToAddExistingIntakeForm = () => navigate(routes.CREATE_EXISTING_CONTRACT);

  return (
    <Stack direction="row" justifyContent="space-between" px={10}>
      <Stack gap={1}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
          <BreadcrumbLink component={Link} to={generatePath(routes.CONTRACTS.PATH)}>
            {t('SAAS.NAVBAR.CONTRACTS')}
          </BreadcrumbLink>
        </Breadcrumbs>

        <Text variant="heading" size="M" tag="h1">
          {t('SAAS.NAVBAR.CONTRACTS')}
        </Text>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        {canExportContracts && (
          <Button color="neutral" variant="outline" isLoading={isExportInProgress} onClick={downloadFile}>
            {t('ENTITIES.CONTRACT.ACTIONS.EXPORT')}
          </Button>
        )}

        {userCanAddExistingContract && (
          <Button color="primary" variant="solid" onClick={goToAddExistingIntakeForm}>
            {t('CONTRACTS.ADD_EXISTING')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
