import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { type Feature, useListFeaturesQuery } from '@vertice/slices/src/openapi/codegen/featuresAPI';
import { OVERRIDE_KEY } from './overrideFeature';
import { useEffect, useState } from 'react';

export const useFeatures = () => {
  const { accountId } = useSelector(getAccount);
  // State variable to track feature overrides
  const [overrides, setOverrides] = useState(() => JSON.parse(localStorage.getItem(OVERRIDE_KEY) || '{}'));

  const { data, isFetching, isUninitialized } = useListFeaturesQuery(
    { accountId: accountId! },
    { skip: !accountId, refetchOnMountOrArgChange: false }
  );

  // Update the state variable whenever local storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      setOverrides(JSON.parse(localStorage.getItem(OVERRIDE_KEY) || '{}'));
    };

    window.addEventListener(OVERRIDE_KEY, handleStorageChange);
    return () => window.removeEventListener(OVERRIDE_KEY, handleStorageChange);
  }, []);

  const getFeature = (feature: string): Feature | null => {
    // Merge the feature data with the overrides
    return { ...data?.features?.[feature], ...overrides[feature] } ?? null;
  };
  const isEnabled = (feature: string): boolean => !!getFeature(feature)?.enabled;

  return {
    getFeature,
    isEnabled,
    isLoading: isUninitialized || isFetching,
  };
};
