import { useTranslation } from 'react-i18next';

export type Option = {
  readonly value: string;
  readonly label: string;
};

export const useGatewayTypeOptions = (): Option[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY.GATEWAY_TYPE',
  });

  return [
    {
      value: 'Exclusive',
      label: t('EXCLUSIVE'),
    },
    {
      value: 'Join',
      label: t('JOIN'),
    },
    {
      value: 'Fork',
      label: t('FORK'),
    },
  ];
};
