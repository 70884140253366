import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StatsItem from './StatsItem';
import { ScoreBarChartItem } from './ScoreBar';
import { useFormatPercentage } from '@verticeone/utils/formatting';

type ScoreBarStatsProps = {
  values: ScoreBarChartItem[];
};

const ScoreBarStats = ({ values }: ScoreBarStatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_OPTIMIZATION' });
  const formatPercentage = useFormatPercentage();

  return (
    <Stack direction="row" justifyContent="space-between">
      {values.map(({ name, value, textColor }) => (
        <StatsItem
          key={name}
          title={t(name.toUpperCase())}
          value={formatPercentage(value, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}
          color={textColor}
        />
      ))}
    </Stack>
  );
};

export default ScoreBarStats;
