import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback, useState } from 'react';
import {
  formatUserRef,
  isUserRef,
  parseRequestRef,
  parseUserRef,
  parseUserTaskRef,
} from '@vertice/core/src/hooks/workflows/refUtils';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWaitForRequestReady } from './useWaitForRequestReady';
import { useLoggedUser } from '@verticeone/auth/src';
import { ContractPartContractualProducts } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Vendor } from '../../vendor/types';
import { useWaitForRequestActiveTask } from './useWaitForRequestActiveTask';

export type RenewalProduct = {
  id?: string;
  name?: string;
  productId?: string;
  annualCost?: number;
  numberOfLicences?: number;
};

export type CreateRenewalRequestParams = {
  contractId: string;
  products?: RenewalProduct[];
  vendor: Vendor;
  source?: string;
  department?: string;
};

type CreateRenewalRequestResult = {
  requestId?: string;
};

export const useCreateRenewalRequest = () => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [createdRenewalRequestId, setCreatedRenewalRequestId] = useState<string | undefined>(undefined);
  const [createdRenewalRequestTaskId, setCreatedRenewalRequestTaskId] = useState<string | undefined>(undefined);

  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestReady = useWaitForRequestReady();
  const waitForRequestActiveTask = useWaitForRequestActiveTask();
  const createRenewalRequest = useCallback(
    async ({ source, ...renewalInput }: CreateRenewalRequestParams): Promise<CreateRenewalRequestResult> => {
      try {
        setIsRequestPending(true);

        const createRequestResponse = await createRequestMutation({
          accountId,
          createRequest: {
            sourceRef:
              source ??
              `urn:verticeone:vertice:${accountId}:cfa:page/intake/existingContract/${renewalInput.contractId}`,
            serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/renewal/generic/v4`,
            name: `${renewalInput.vendor.name} Renewal`,
            input: {
              ...renewalInput,
              products: contractProductsToRenewalProducts(renewalInput.products),
            },
            owner: formatUserRef(userId),
          },
        });

        const requestReference = 'data' in createRequestResponse ? createRequestResponse.data?.ref : undefined;
        if (requestReference) {
          const requestId = parseRequestRef(requestReference).requestId;

          const waitForRequestAndTask = Promise.all([
            waitForRequestReady(accountId, requestId),
            waitForRequestActiveTask(accountId, requestId),
          ]);

          const wait20Seconds = new Promise<undefined>((resolve) => {
            setTimeout(resolve, 20000);
          });

          const result = await Promise.race([waitForRequestAndTask, wait20Seconds]);
          setCreatedRenewalRequestId(requestId);
          if (result) {
            const [requestReady, task] = result;
            const taskAssignedToLoggedUser = task.assignment.some(
              (assignment) => isUserRef(assignment) && parseUserRef(assignment).userId === userId
            );
            if (requestReady && task.status === 'ACTIVE' && task.type === 'USER' && taskAssignedToLoggedUser) {
              setCreatedRenewalRequestTaskId(parseUserTaskRef(task.ref).taskId);
            }
          }
          return { requestId };
        }
        return { requestId: undefined };
      } finally {
        setIsRequestPending(false);
      }
    },
    [userId, createRequestMutation, accountId, waitForRequestReady, waitForRequestActiveTask]
  );

  return {
    createRenewalRequest,
    isCreatingRenewalRequest: isRequestPending,
    createdRenewalRequestId,
    createdRenewalRequestTaskId,
  };
};

const contractProductsToRenewalProducts = (products?: ContractPartContractualProducts): RenewalProduct[] => {
  return (
    products?.map((product) => ({
      id: product.productId ?? undefined,
      name: product.productName ?? undefined,
      productId: product.productId ?? undefined,
      annualCost: product.annualCost ?? undefined,
      numberOfLicences: product.licenseCount ?? undefined,
    })) ?? []
  );
};
