import React, { useCallback } from 'react';
import { FieldPathByValue, FieldValues, useFormContext } from 'react-hook-form';

import { OfferProduct, Offer, joinFormPath, useWatchNested } from '../schemas';
import { useTaskFormContext } from '../TaskFormContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import {
  CostModelProductItem,
  CostModelProductList,
} from '../../../../../../../saas/contract/components/CostModelProductList';

type OfferFormProductListProps<
  V extends FieldValues,
  P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>
> = {
  name: P;
};

export const OfferFormProductList = <T extends FieldValues>({ name: offerName }: OfferFormProductListProps<T>) => {
  const { setValue } = useFormContext<T>();
  const { readOnly } = useTaskFormContext();

  const products = useWatchNested({ offerName, fieldName: 'products' })?.map(toCostModelProductItem);
  const vendor = useWatchNested({ offerName, fieldName: 'vendor' });
  const baseCurrency = useWatchNested({ offerName, fieldName: 'baseCurrency' });
  const rollingFrequency = useWatchNested({ offerName, fieldName: 'rollingFrequency' });

  const setProducts = useCallback(
    (productsToSet: CostModelProductItem[]) =>
      setValue(
        joinFormPath<T>(offerName, 'products'),
        productsToSet.map((product) => ({
          ...product,
          annualCost: product.totalCost,
          allocationSpan: product.allocationSpan
            ? {
                start_date: product.allocationSpan.startDate ?? undefined,
                end_date: product.allocationSpan.endDate ?? undefined,
                rolling_frequency: product.allocationSpan.rollingFrequency ?? undefined,
              }
            : undefined,
        })) as any,
        { shouldValidate: true }
      ),
    [setValue, offerName]
  );

  return (
    <CostModelProductList
      rollingFrequency={rollingFrequency}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      withActions={!readOnly}
      vendorId={vendor?.id}
      currency={baseCurrency}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
    />
  );
};

const toCostModelProductItem = (product: OfferProduct): CostModelProductItem => ({
  ...product,
  totalCost: product.annualCost,
  allocationSpan: product.allocationSpan
    ? {
        startDate: product.allocationSpan.start_date ?? undefined,
        endDate: product.allocationSpan.end_date ?? undefined,
        rollingFrequency: product.allocationSpan.rolling_frequency ?? undefined,
      }
    : undefined,
});
