import { useTranslation } from 'react-i18next';
import { AccordionDetails, Stack } from '@mui/material';

import { DataHealthCheck } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { Accordion, AccordionSummary } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { DataHealthItem } from './DataHealthItem';
import { DataHealthType } from './types';
import { TFunction } from 'i18next';

type DataHealthItemWrapperProps = {
  type: DataHealthType;
  list?: DataHealthCheck[];
  count: number;
  application?: ApplicationWithMeasureReports;
};

const getHeader = (type: DataHealthType, count: number, t: TFunction) => {
  switch (type) {
    case DataHealthType.WARNING:
      return t('APPLICATIONS.USAGE.DATA_HEALTH.WARNING_HEADER', {
        count,
      });
    case DataHealthType.INFO:
      return t('APPLICATIONS.USAGE.DATA_HEALTH.INFO_HEADER', {
        count,
      });
    case DataHealthType.SUCCESS:
    default:
      return t('APPLICATIONS.USAGE.DATA_HEALTH.SUCCESS_HEADER', {
        count,
      });
  }
};

export const DataHealthItemWrapper = ({ type, list = [], count, application }: DataHealthItemWrapperProps) => {
  const { t } = useTranslation();

  if (count < 1) return null;

  const isSuccess = type === DataHealthType.SUCCESS;

  return (
    <Accordion variant="solid" size="XS" defaultExpanded={!isSuccess} sx={{ paddingLeft: 6, paddingRight: 6 }}>
      <AccordionSummary
        title={
          <Text variant="body-bold" size="S" color={type}>
            {getHeader(type, count, t)}
          </Text>
        }
      />
      <AccordionDetails sx={{ paddingTop: 2 }}>
        <Stack gap={2}>
          {list.map((item) => (
            <DataHealthItem
              key={item.code}
              type={type}
              code={item.code}
              status={item.status}
              application={application}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
