import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { Box, styled } from '@mui/material';
import { Loader } from '@verticeone/design-system/src';
import { DataGrid, DataGridProps } from '@verticeone/design-system/src';
import AddItemFooter from './AddItemFooter';
import NoRowsOverlay from './NoRowsOverlay';
import { useDepartmentListContext } from './DepartmentListContextProvider';
import WatchersEditCell from './cells/WatchersEditCell';
import OwnerEditCell from './cells/OwnerEditCell';
import UserReadOnlyCell from './cells/UserReadOnlyCell';
import { labelComparator, ownerComparator, preProcessNonEmptyCell } from './utils';
import { DepartmentItem } from './types';

export interface DepartmentListProps {
  extraColumns?: GridColDef[];
  dataGridExtraProps?: Partial<DataGridProps<DepartmentItem>>;
  readOnlyMode?: boolean;
  noBorder?: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
  '& .Mui-error': {
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.error.color1,
  },
}));

const DepartmentList: FC<DepartmentListProps> = ({
  extraColumns = [],
  dataGridExtraProps = {},
  readOnlyMode,
  noBorder,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.DEPARTMENT' });
  const { departments, isLoading } = useDepartmentListContext();

  const columns: GridColDef[] = [
    {
      field: 'label',
      headerName: t('DEPARTMENT_NAME'),
      preProcessEditCellProps: preProcessNonEmptyCell(departments),
      valueParser: (value: string) => value.trimStart(),
      disableColumnMenu: true,
      sortComparator: labelComparator,
      editable: true,
      flex: 1,
    },
    {
      field: 'owner',
      headerName: t('DEPARTMENT_OWNER'),
      renderCell: (params: GridRenderCellParams) => <UserReadOnlyCell {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <OwnerEditCell {...params} />,
      disableColumnMenu: true,
      sortComparator: ownerComparator,
      editable: true,
      flex: 1,
    },
    {
      field: 'watchers',
      headerName: t('DEPARTMENT_WATCHERS'),
      renderCell: (params: GridRenderCellParams) => <UserReadOnlyCell {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <WatchersEditCell {...params} />,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      flex: 2,
    },
  ];

  const columnsToUse = [...columns, ...extraColumns];

  if (!departments || isLoading) {
    return (
      <Box p={6}>
        <Loader />
      </Box>
    );
  }

  return (
    <StyledBox>
      <DataGrid
        rows={departments || []}
        columns={columnsToUse}
        getRowId={(row: GridRowModel) => row.id}
        slots={{
          footer: AddItemFooter,
          noRowsOverlay: () => <NoRowsOverlay />,
        }}
        sortingMode="client"
        initialState={{
          sorting: {
            sortModel: [{ field: 'label', sort: 'asc' }],
          },
        }}
        isCellEditable={() => false}
        disableRowSelectionOnClick
        hideFooter
        columnHeaderHeight={48}
        autoHeight={true}
        noBorder={noBorder}
        noBorderRadius={noBorder}
        {...dataGridExtraProps}
      />
    </StyledBox>
  );
};

export default DepartmentList;
