import { useCallback, useEffect, useMemo, useRef, ChangeEvent, useState, forwardRef, ForwardedRef } from 'react';
import { Highlighter } from './components/Highlighter';
import { SuggestionsOverlay } from './components/Suggestions';
import { UserSuggestion } from './types';
import { TextFieldAreaProps } from '../TextFieldArea/components/constants';
import { TextFieldArea } from '../TextFieldArea';
import styled from '@mui/material/styles/styled';
import { DesignSystemSize } from '../../types';
import {
  applyChangeToValue,
  findStartOfMentionInPlainText,
  makeMentionsMarkup,
  mapPlainTextIndex,
  mentionSegments,
  spliceString,
} from './utils';

const TextVariant = 'body-regular';

const StyledTextArea = styled(TextFieldArea, { shouldForwardProp: () => true })(({ theme }) => ({
  '&.textarea': {
    paddingRight: `${theme.spacing(14)}`,
    overscrollBehavior: 'none',
  },
}));

type MentionsTextFieldProps = {
  size?: DesignSystemSize;
  value?: string;
  onChange: (value: string) => void;

  trigger: string;
  markup: string;
  regex: RegExp;

  displayTransform: (id: string) => string;
  appendSpaceOnAdd?: boolean;
  allowSpaceInQuery?: boolean;

  label?: string;
  suggestions: UserSuggestion[];

  inputProps: Omit<
    TextFieldAreaProps,
    | 'size'
    | 'textVariant'
    | 'value'
    | 'onChange'
    | 'onSelect'
    | 'onBlur'
    | 'defaultValue'
    | 'autosize'
    | 'minRows'
    | 'maxRows'
  >;
};

export const MentionsTextArea = forwardRef(
  (
    {
      size = 'M',
      value = '',
      trigger,
      markup,
      regex,
      label,
      displayTransform,
      appendSpaceOnAdd,
      allowSpaceInQuery,
      suggestions,
      onChange,
      inputProps,
    }: MentionsTextFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const cursorRef = useRef<HTMLSpanElement>(null);

    const [selectionStart, setSelectionStart] = useState<number | null>(null);
    const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

    const { segments, plainText, rawLength } = useMemo(
      () => mentionSegments(value, regex, displayTransform),
      [value, regex, displayTransform]
    );

    useEffect(() => {
      if (!ref || !('current' in ref)) return;

      const input = ref.current;
      if (!input || (input.selectionStart === selectionStart && input.selectionEnd === selectionEnd)) {
        return;
      }
      input.setSelectionRange(selectionStart, selectionEnd);
    }, [selectionStart, selectionEnd, ref]);

    const addMention = useCallback(
      (suggestion: UserSuggestion, querySequenceStart: number, querySequenceEnd: number) => {
        const start = mapPlainTextIndex(segments, querySequenceStart, 'START', rawLength);
        if (start == null || start === undefined) {
          return;
        }

        const end = start + querySequenceEnd - querySequenceStart;

        let insert = makeMentionsMarkup(markup, suggestion.id, suggestion.label);
        let displayValue = displayTransform(suggestion.id);

        if (appendSpaceOnAdd) {
          insert += ' ';
          displayValue += ' ';
        }

        const newCaretPosition = querySequenceStart + displayValue.length;
        setSelectionStart(newCaretPosition);
        setSelectionEnd(newCaretPosition);

        onChange(spliceString(value, start, end, insert));
      },
      [appendSpaceOnAdd, displayTransform, markup, onChange, rawLength, segments, value]
    );

    const handleChange = ({ target, nativeEvent }: ChangeEvent<HTMLInputElement>) => {
      let newPlainTextValue = target.value;

      let selectionStartBefore = selectionStart;
      if (selectionStartBefore === null) {
        selectionStartBefore = target.selectionStart;
      }

      let selectionEndBefore = selectionEnd;
      if (selectionEndBefore === null) {
        selectionEndBefore = target.selectionEnd;
      }

      // Derive the new value to set by applying the local change in the textarea's plain text
      const { value: newValue, plainText: newPlainText } = applyChangeToValue(
        value,
        rawLength,
        newPlainTextValue,
        plainText,
        selectionStartBefore,
        selectionEndBefore,
        target.selectionEnd || 0,
        segments,
        regex,
        displayTransform
      );

      // In case a mention is deleted, also adjust the new plain text value
      newPlainTextValue = newPlainText;

      // Save current selection after change to be able to restore caret position after rerendering
      let selectionStartAfter = target.selectionStart;
      let selectionEndAfter = target.selectionEnd;

      // Adjust selection range in case a mention will be deleted by the characters outside of the
      // selection range that are automatically deleted
      const startOfMention = findStartOfMentionInPlainText(segments, target.selectionStart || 0);
      if (startOfMention !== undefined && selectionEndAfter !== null && selectionEndAfter > startOfMention) {
        // only if a deletion has taken place
        const data = (nativeEvent as any).data;
        selectionStartAfter = startOfMention + (data ? data.length : 0);
        selectionEndAfter = selectionStartAfter;
      }

      setSelectionStart(selectionStartAfter);
      setSelectionEnd(selectionEndAfter);

      // Propagate change
      onChange(newValue);
    };

    const handleSelect = useCallback(({ target }: ChangeEvent<HTMLTextAreaElement>) => {
      setSelectionStart(target.selectionStart);
      setSelectionEnd(target.selectionEnd);
    }, []);

    return (
      <>
        <StyledTextArea
          size={size}
          textVariant={TextVariant}
          autosize
          minRows={1}
          maxRows={10}
          ref={ref}
          value={plainText}
          label={label}
          onChange={handleChange}
          onSelect={handleSelect}
          {...inputProps}
        />
        <Highlighter
          size={size}
          variant={TextVariant}
          inputRef={ref}
          cursorRef={cursorRef}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          segments={segments}
        />
        <SuggestionsOverlay
          plainText={plainText}
          segments={segments}
          rawLength={rawLength}
          suggestions={suggestions}
          trigger={trigger}
          allowSpaceInQuery={allowSpaceInQuery}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          cursorRef={cursorRef}
          inputRef={ref}
          onSelect={addMention}
        />
      </>
    );
  }
);
