import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';
import React from 'react';
import useSavingsPlanInventoryData from '../useSavingsPlanInventoryData';
import SPInventoryTable from '../components/SPInventoryTable';

const EC2 = () => {
  const { data, ...loadingStatus } = useSavingsPlanInventoryData({ spType: 'EC2Instance' });

  return (
    <LoadableContentWrapper {...loadingStatus}>
      <SPInventoryTable
        data={data!}
        visibleColumns={{
          savingsPlanId: true,
          accountId: true,
          termLengthYears: true,
          paymentOption: true,
          totalCommitment: true,
          ec2InstanceFamily: true,
          ec2Region: true,
          hourlyCommitment: true,
          startDate: true,
          endDate: true,
        }}
      />
    </LoadableContentWrapper>
  );
};

export default EC2;
