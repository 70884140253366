import React, { ReactNode, useState } from 'react';
import { Box, Stack } from '@mui/material';
import NodeWrapper from './NodeWrapper';
import { getNodeConnectors, getRows, isLeftConnectorHighlighted, isRightConnectorHighlighted } from './utils';
import { RowItem, TreeItem } from './types';

type TreeProps = {
  data: TreeItem;
  nodeWidth: number;
  rowHeight: number;
  nodeRenderer: (item: any, selected: boolean) => ReactNode;
  gap?: number;
  connectorLength?: number;
  strokeWidth?: number;
  activeRows?: number[];
};

const Tree = ({
  data,
  nodeWidth,
  rowHeight,
  gap = 16,
  connectorLength = 40,
  strokeWidth = 2,
  nodeRenderer,
  activeRows,
}: TreeProps) => {
  const { rows: flatRows, rightOffset, leftOffset } = getRows(data);
  const [rows, setRows] = useState(flatRows);

  const onSelectionChanged = (rowsCopy: RowItem[][], item: RowItem, rowIndex: number, isSelect: boolean) => {
    if (isSelect) {
      // select and expand path towards the root node
      let parentId = item.parentId;
      let searchedIndex = rowIndex - 1;
      while (parentId && searchedIndex >= 0) {
        // eslint-disable-next-line no-loop-func
        const parentNode = rowsCopy[searchedIndex].find((parentCandidate) => parentCandidate.id === parentId);
        if (parentNode) {
          parentNode.selected = true;
          parentNode.expanded = true;
          parentId = parentNode?.parentId;
        }
        searchedIndex -= 1;
      }
    }

    item.selected = isSelect;
    item.expanded = item.collapsible ? isSelect : true;

    // select/deselect and show/hide direct children
    const childrenIds = item.children?.map((child) => child.id);
    if (childrenIds) {
      rowsCopy[rowIndex + 1].forEach((childCandidate) => {
        if (childrenIds.includes(childCandidate.id)) {
          childCandidate.selected = isSelect;
          childCandidate.visible = item.expanded;
        }
      });
    }
  };

  const handleClick = (item: RowItem, rowIndex: number) => (event: any) => {
    const isSelect = !item.selected;

    if (isSelect) {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }

    // create a copy of rows not to mutate the original
    const newRows = rows.slice();

    // deselect and collapse all collapsible items
    newRows.forEach((rowItems, index) => {
      rowItems.forEach((rowItem) => {
        rowItem.selected = false;
        rowItem.expanded = rowItem.collapsible === false;

        // show/hide children nodes based on expanded property
        const childrenIds = rowItem.children?.map((child: TreeItem) => child.id);
        if (childrenIds) {
          rows[index + 1].forEach((childCandidate) => {
            if (childrenIds.includes(childCandidate.id)) {
              childCandidate.visible = rowItem.expanded;
            }
          });
        }
      });
    });

    const selectedItem = newRows[rowIndex].find((rowItem) => rowItem.id === item.id);
    onSelectionChanged(newRows, selectedItem!, rowIndex, isSelect);
    setRows(newRows);
  };
  const visibleRows = rows
    .map((rowItems) => rowItems.filter((rowItem) => rowItem.visible))
    .filter((rowItems) => rowItems.length > 0);

  return (
    <Stack
      alignItems="flex-start"
      width={(nodeWidth + gap) * (leftOffset + 1 + rightOffset)}
      height={rowHeight * visibleRows.length}
    >
      {rows.map((rowItems, rowIndex) => (
        <Stack key={rowIndex} direction="row" height={rowHeight}>
          {rowItems
            .filter((rowItem) => rowItem.visible)
            .map((rowItem) => (
              <Stack direction="row" key={rowItem.id}>
                {rowItem.groupIndex === 0 && <Box width={(nodeWidth + gap) * (leftOffset + rowItem.offset)} />}
                <NodeWrapper
                  width={nodeWidth}
                  horizontalPadding={gap / 2}
                  verticalPadding={connectorLength}
                  strokeWidth={strokeWidth}
                  connectors={getNodeConnectors(rowItem)}
                  selected={rowItem.selected}
                  highlightLeftConnector={isLeftConnectorHighlighted(rowItem, rows[rowIndex])}
                  highlightRightConnector={isRightConnectorHighlighted(rowItem, rows[rowIndex])}
                  onClick={!activeRows || activeRows.includes(rowIndex) ? handleClick(rowItem, rowIndex) : undefined}
                >
                  {nodeRenderer(rowItem.userData, rowItem.selected)}
                </NodeWrapper>
              </Stack>
            ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default Tree;
