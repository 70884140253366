import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import { testProps } from '../../utils';
import { DesignSystemSize, TestProps } from '../../types';
import { BreadcrumbsV2 } from '../BreadcrumbsV2';
import { MenuItem } from '../Menu';
import { PageHeaderColor } from './types';
import { BreadcrumbsV2Props } from '../BreadcrumbsV2/types';
import { PageHeaderContextProvider, usePageHeaderContext } from './PageHeaderContext';
import { LinkAction, MenuAction, PrimaryAction, SecondaryAction } from './Actions';

type PageHeaderBreadcrumbsProps = Omit<BreadcrumbsV2Props, 'size'>;

const PageHeaderBreadcrumbs = ({ items, ...restProps }: PageHeaderBreadcrumbsProps) => {
  const { size } = usePageHeaderContext();

  return <BreadcrumbsV2 items={items} size={size} {...restProps} />;
};

export type PageHeaderProps = {
  breadcrumb: ReactNode;
  actions: ReactNode;
  size?: DesignSystemSize;
  color: PageHeaderColor;
} & TestProps;

const PageHeader = ({ breadcrumb, actions, color, size = 'M', testId }: PageHeaderProps) => (
  <PageHeaderContextProvider value={{ color, size }}>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      m={6}
      gap={8}
      maxWidth="100%"
      maxHeight={32}
      {...testProps(testId, 'page-header')}
    >
      <Stack alignItems="center">{breadcrumb}</Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        {actions}
      </Stack>
    </Stack>
  </PageHeaderContextProvider>
);

PageHeader.Breadcrumbs = PageHeaderBreadcrumbs;
PageHeader.PrimaryAction = PrimaryAction;
PageHeader.SecondaryAction = SecondaryAction;
PageHeader.LinkAction = LinkAction;
PageHeader.MenuAction = MenuAction;
PageHeader.MenuActionItem = MenuItem;

export { PageHeader };
