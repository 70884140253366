import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextFieldCaption } from '@verticeone/design-system/src';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import { getAdditionalSavings, getAnnualAdditionalSavings } from '@vertice/core/src/modules/saas/savings/computed';
import { PropsWithContract } from '../types';

export const BreakdownAdditionalSavings: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();

  const annualAdditionalSavings = getAnnualAdditionalSavings(contract);
  const additionalSavings = getAdditionalSavings(contract);

  return (
    <Stack gap={1} flex={1}>
      <TextFieldCaption
        htmlFor={id}
        label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_SAVINGS')}
        size="XS"
        tooltip={{
          title: t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_SAVINGS'),
          content: t('ENTITIES.CONTRACT.TOOLTIPS.ADDITIONAL_SAVINGS'),
        }}
      />
      <LargeValue
        id={id}
        size="S"
        primaryValue={formatContractCurrency(annualAdditionalSavings)}
        primaryTestId="annualAdditionalSavingsValue"
        secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(additionalSavings) })}
        secondaryTestId="additionalSavingsValue"
      />
    </Stack>
  );
};
