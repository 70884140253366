import { SxProps } from '@mui/material';
import styled from '@mui/styled-engine';
import { SVGProps } from 'react';

const BaseWorkflowsTwoTone = ({ width, height, className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity=".4">
      <path d="M12.1842 7L13.1242 4.93L15.1842 4L13.1242 3.07L12.1842 1L11.2642 3.07L9.1842 4L11.2642 4.93L12.1842 7Z" />
      <path d="M4.39929 14.6114L4.89929 12.6114L6.89929 12.1114L4.89929 11.6114L4.39929 9.61143L3.89929 11.6114L1.89929 12.1114L3.89929 12.6114L4.39929 14.6114Z" />
    </g>
    <path d="M18.7598 14.5701L23 18.9772L18.7598 23.3843V19.9557C16.3212 19.9474 14.5243 19.4443 13.0664 18.2932C12.6993 18.0033 12.3618 17.6782 12.0462 17.3214L13.2563 15.425C13.5916 15.8577 13.9374 16.2164 14.3134 16.5132C15.2922 17.2861 16.5961 17.7292 18.7598 17.7374V14.5701Z" />
    <path d="M1 7.03783C4.47502 7.03783 6.85032 9.18296 8.34297 11.2748L9.5664 9.34829C7.79309 7.088 5.01871 4.8196 1 4.8196V7.03783Z" />
    <path d="M14.5532 7.4459C15.7263 6.9229 17.0976 6.688 18.7598 6.68237V3.38428L23 7.79139L18.7598 12.1985V8.90062C17.2742 8.90628 16.2001 9.11756 15.3622 9.49112C14.5273 9.86335 13.8639 10.4247 13.2406 11.2338C12.6594 11.9881 12.1187 12.95 11.5117 14.1784C11.0899 15.032 10.4659 16.1349 9.61013 17.2356C7.85639 19.4911 5.05709 21.8185 1 21.8185V19.6002C4.21443 19.6002 6.47739 17.7802 7.99881 15.8234C8.73933 14.871 9.2871 13.9055 9.66143 13.1479C10.2865 11.8829 10.9055 10.761 11.6233 9.82934C12.4237 8.7905 13.3513 7.98177 14.5532 7.4459Z" />
  </svg>
);

export const WorkflowsTwoTone = styled(({ sx, ...props }: SVGProps<SVGSVGElement> & { sx?: SxProps }) => (
  <BaseWorkflowsTwoTone {...props} />
))(({ sx }) => ({ ...sx }));
