import React, { FC } from 'react';
import CustomFieldsListBase, { CustomFieldsListProps as CustomFieldsListBaseProps } from './CustomFieldsList';
import WithActions from './WithActions';
import CustomFieldsListContextProvider from './CustomFieldsListContextProvider';

interface CustomFieldsListProps extends CustomFieldsListBaseProps {
  withActions?: boolean;
  excludeDeleteAction?: boolean;
  allowSavingFor?: Array<string>;
}

export const CustomFieldsList: FC<CustomFieldsListProps> = ({
  withActions = true,
  excludeDeleteAction,
  allowSavingFor,
  ...rest
}) => {
  const listBase = <CustomFieldsListBase readOnlyMode={!withActions} {...rest} />;
  return (
    <CustomFieldsListContextProvider>
      {withActions ? <WithActions {...{ excludeDeleteAction, allowSavingFor }}>{listBase}</WithActions> : listBase}
    </CustomFieldsListContextProvider>
  );
};
