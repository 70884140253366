import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { Card } from '@verticeone/design-system/src';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import DisclaimerBanner from './components/DisclaimerBanner';
import HeaderSummary from './components/HeaderSummary';
import { CloudOptimizationProvider } from './CloudOptimizationContext';
import TogglePeriod from './components/TogglePeriod';
import CardDivider from '../../../modules/cloud/cards/shared/CardDivider';
import OptimizationTestsTable from './components/OptimizationTestsTable';
import LoadableOptimization from './components/LoadableOptimization';

const CloudOptimization = () => {
  const { t } = useTranslation();

  return (
    <LoadableAWSPageWrapper>
      <Stack gap={8} p={10}>
        <Stack gap={1}>
          <Breadcrumbs variant="slash" size="S">
            <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
            <BreadcrumbLink disabled>{t('CLOUD.CLOUD_OPTIMIZATION.BREADCRUMBS_TITLE')}</BreadcrumbLink>
          </Breadcrumbs>
          <Text variant="heading" size="M" tag="h1">
            {t('CLOUD.CLOUD_OPTIMIZATION.TITLE')}
          </Text>
        </Stack>
        <DisclaimerBanner />
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item sm={12}>
            <Card>
              <CloudOptimizationProvider>
                <HeaderSummary />
                <LoadableOptimization>
                  <Stack
                    paddingLeft={6}
                    paddingBottom={6}
                    paddingRight={6}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    <TogglePeriod />
                  </Stack>
                </LoadableOptimization>
                <CardDivider />
                <OptimizationTestsTable />
              </CloudOptimizationProvider>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </LoadableAWSPageWrapper>
  );
};

export default CloudOptimization;
