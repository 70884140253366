import {
  CheckDataPoint,
  TimeSeriesDataValue,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import {
  CategoryValues,
  DATE_FORMAT,
  fillMissingMonths,
  getLargestNCategories,
  getSeriesByCategory,
  getTableData,
} from '../../utils/graphDataUtils';
import format from 'date-fns/format';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { AWS_DEFAULT_CURRENCY } from '../../constants';
import { NUMBER_OF_SERIES } from './constants';

const coverWithArray = <ValueType>(value: ValueType | ValueType[]): ValueType[] =>
  Array.isArray(value) ? value : [value];

const getDataSeries = (monthlyData: TimeSeriesDataValue[][], usedCategories: string[], aggregatedCategory: string) => {
  const seriesByCategory = getSeriesByCategory(monthlyData, usedCategories, {
    aggregatedCategory,
  });

  return seriesByCategory.map((categorySeries: CategoryValues) => {
    const categoryId = categorySeries.id;

    return {
      ...categorySeries,
      name: categoryId,
      type: 'column',
    };
  });
};

const getTimeSeriesValues = (dataPoints: CheckDataPoint[]) => {
  return dataPoints
    .map(({ values }) => {
      return values.map(({ dateStart, checkResult }) => {
        const timeSeriesDataValues = getTableData(checkResult, {
          feature: 'dimensions',
          subfeature: 'units',
          cost: 'values',
        }) as TimeSeriesDataValue[];

        const transformedValues: TimeSeriesDataValue[] = timeSeriesDataValues.map(
          (timeSeriesDataValue): TimeSeriesDataValue => ({
            dimensions: coverWithArray(timeSeriesDataValue.dimensions),
            units: coverWithArray(timeSeriesDataValue.units),
            values: coverWithArray(timeSeriesDataValue.values),
          })
        );

        return {
          time: format(new Date(dateStart), DATE_FORMAT),
          values: transformedValues,
        };
      });
    })
    .flat();
};

export const prepareHistoricalSpendData = (
  rawData: CheckDataPoint[] | undefined,
  startDate: Date,
  aggregatedCategory: string
) => {
  if (!rawData) return undefined;

  const timeToSeriesData = getTimeSeriesValues(rawData);
  const allMonthsData = fillMissingMonths(timeToSeriesData, startDate, []);
  const allMonthsDataValues = allMonthsData.map(({ values }) => values);
  const usedCategories = getLargestNCategories(allMonthsDataValues, {
    maxNumberOfSeries: NUMBER_OF_SERIES,
    aggregatedCategory,
  });

  return {
    months: allMonthsData.map(({ time }) => time!),
    values: getDataSeries(allMonthsDataValues, usedCategories, aggregatedCategory) as SeriesOptionsWithData[],
    currency: AWS_DEFAULT_CURRENCY,
    usedCategories,
  };
};
