import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowModel } from '@mui/x-data-grid-pro';
import useAccountsData, { TableRow } from '../useAccountsData';
import { useCloudAnalytics } from '../../../../CloudAnalyticsContext';
import { getTableHeight } from '../../../BaseCloudTab/Table/utils';
import BaseCloudAnalyticsTable from '../../../BaseCloudTab/Table/BaseCloudAnalyticsTable';
import useDrawerAccountsData from '../../../../components/ComparisonDrawer/useDrawerAccountsData';
import ComparisonDrawerProvider from '../../../../components/ComparisonDrawer/ComparisonDrawerContext';

const CloudAccountsTable = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const { getLastNode, addChildNode } = useCloudAnalytics();
  const { search, loadingHeight } = getLastNode();
  const { data, ...loadingStatus } = useAccountsData();
  const filteredData = data?.tableData?.filter((row) =>
    row.accountName?.toLowerCase().includes(search?.toLowerCase() || '')
  );

  const getRowId = (row: GridRowModel) => row.accountId;

  const calculatedHeight = getTableHeight(filteredData);

  const handleRowClick = ({ row }: GridRowModel) => {
    addChildNode({
      id: row.accountId,
      label: row.accountName,
      type: 'account',
      loadingHeight: calculatedHeight,
    });
  };

  return (
    <ComparisonDrawerProvider
      instanceSettings={{
        query: useDrawerAccountsData,
        mappingFunction: (acc, { account_id, account_name }) => ({
          ...acc,
          [account_id]: account_name || account_id,
        }),
        comparisonColumn: 'account_id',
      }}
    >
      <BaseCloudAnalyticsTable<TableRow>
        height={loadingStatus.isLoading && loadingHeight ? loadingHeight : calculatedHeight}
        data={filteredData || []}
        firstColumn={{
          field: 'accountName',
          headerName: t('COLUMN.ACCOUNT_NAME'),
        }}
        getRowId={getRowId}
        onRowClick={handleRowClick}
        isLoading={loadingStatus.isLoading}
      />
    </ComparisonDrawerProvider>
  );
};

export default CloudAccountsTable;
