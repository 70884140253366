import { PredefinedFormDef } from '../shared/types';
import { FinalOfferForm } from './FinalOfferForm';
import { zodSchema, FormData } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/offers\/final\/v\d+$/,
  component: FinalOfferForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    disableRejection: getValue('disableRejection'),
    baselineOffer: getValue('baselineOffer'),
    negotiationOffers: getValue('negotiationOffers'),

    finalOffer: getValue('finalOffer'),
    status: getValue('status'),
  }),
};
