import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system/src';
import { Tooltip } from '@verticeone/design-system/src';
import { Box } from '@mui/material';

type PurchaseTooltipProps = {
  show: boolean;
  children: React.ReactNode;
};

const PurchaseTooltip = ({ show, children }: PurchaseTooltipProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.ACTIONS_ROW.PURCHASE_SP' });

  if (show) {
    return (
      <Tooltip size="S" content={t('TOOLTIP')}>
        <span>{children}</span>
      </Tooltip>
    );
  }

  return children;
};

const PurchaseSPButton = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.ACTIONS_ROW.PURCHASE_SP' });

  const isBuyingJourneyEnabled = false;

  return (
    <Box>
      <PurchaseTooltip show={true}>
        <Button testId="purchase-sp" variant="ghost" size="M" color="tertiary" disabled={!isBuyingJourneyEnabled}>
          {t('LABEL')}
        </Button>
      </PurchaseTooltip>
    </Box>
  );
};

export default PurchaseSPButton;
