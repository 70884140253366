import { FC } from 'react';
import { Skeleton, SkeletonProps, styled } from '@mui/material';
import { testProps } from '../../utils/testProperties';
import { TestProps } from '../../types';

type PlaceholderVariant = 'circular' | 'rectangular' | 'rounded' | 'text';

export type PlaceholderProps = Omit<SkeletonProps, 'animation' | 'variant'> &
  TestProps & {
    variant?: PlaceholderVariant;
  };

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.core.color1,
  transform: 'scale(1, 1)',
  '&.MuiSkeleton-rounded': {
    borderRadius: theme.spacing(2),
  },
}));

export const Placeholder: FC<PlaceholderProps> = ({ testId, ...props }) => {
  return <StyledSkeleton {...props} {...testProps(testId, 'placeholder')} animation="wave" />;
};
