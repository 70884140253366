import { ReactNode } from 'react';
import { AccordionSummary as MuiAccordionSummary, accordionSummaryClasses, Stack } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import { Text } from '../Text';
import { DesignSystemSize } from '../../types';
import { gradientBorder } from '../../utils/css/gradientBorder';
import { baseSizes, reducedSizes } from '../../guidelines/Sizing/sizings';
import { IconWrapper } from '../IconWrapper';
import { useAccordionContext } from './AccordionContext';
import { ifExpression } from '../../utils/ifExpression';
import { AccordionVariant } from './types';

type StyledAccordionSummaryProps = {
  theme: Theme;
  $variant?: AccordionVariant;
  $size: DesignSystemSize;
  $alwaysExpanded?: boolean;
};

const GRADIENT_BORDER_WIDTH = '2px';

const StyledBaseAccordionSummary = styled(MuiAccordionSummary)(
  ({ theme, $variant, $size, $alwaysExpanded }: StyledAccordionSummaryProps) => ({
    [`&.${accordionSummaryClasses.root}`]: {
      minHeight: 'unset',
      padding: reducedSizes[$size],
      paddingLeft: baseSizes[$size],
      borderRadius: '8px',
      gap: reducedSizes[$size],

      [`&:hover .${accordionSummaryClasses.expandIconWrapper}`]: {
        ...ifExpression($variant !== 'label', {
          backgroundColor: theme.palette.neutral.hover.color4,
        }),
      },

      [`&:focus-visible .${accordionSummaryClasses.expandIconWrapper}`]: {
        ...ifExpression($variant !== 'label', {
          outline: `2px solid ${theme.palette.neutral.color1}`,
        }),
      },

      [`&:focus-visible .${accordionSummaryClasses.expandIconWrapper} svg`]: {
        ...ifExpression($variant === 'label', {
          color: theme.palette.neutral.color3,
        }),
      },

      [`&.${accordionSummaryClasses.disabled}`]: {
        opacity: 1,
        ...ifExpression($variant !== 'label', {
          ...(!$alwaysExpanded && { backgroundColor: theme.palette.inactive.color3 }),
        }),
      },
    },

    [`.${accordionSummaryClasses.content}`]: {
      alignItems: 'center',
      margin: 0,
      gap: reducedSizes[$size],
    },

    [`.${accordionSummaryClasses.expandIconWrapper}`]: {
      padding: `calc(${reducedSizes[$size]} - ${GRADIENT_BORDER_WIDTH})`,
      ...ifExpression($variant !== 'label', {
        backgroundColor: theme.palette.neutral.color4,
        ...gradientBorder({
          width: GRADIENT_BORDER_WIDTH,
          color: theme.palette.gradient.transparentToBlack05,
          radius: '8px',
        }),
      }),
    },

    [`&.${accordionSummaryClasses.expanded}`]: {
      borderRadius: '8px 8px 0 0',
    },
  })
);

const StyledOutlinedAccordionSummary = styled(StyledBaseAccordionSummary)(({ theme }: StyledAccordionSummaryProps) => ({
  [`&.${accordionSummaryClasses.root}`]: {
    border: `1px solid ${theme.palette.core.color3}`,
    backgroundColor: theme.palette.core.bg,

    '&:hover': {
      borderColor: theme.palette.core.color4,
    },
  },
}));

const StyledSolidAccordionSummary = styled(StyledBaseAccordionSummary)(({ theme }: StyledAccordionSummaryProps) => ({
  [`&.${accordionSummaryClasses.root}:hover`]: {
    backgroundColor: theme.palette.core.color1,
  },

  [`&.${accordionSummaryClasses.expanded}`]: {
    borderBottom: `1px solid ${theme.palette.core.color3}`,
  },
}));

const StyledLabelAccordionSummary = styled(StyledBaseAccordionSummary)(
  ({ theme, $size }: StyledAccordionSummaryProps) => ({
    [`&.${accordionSummaryClasses.root}`]: {
      padding: 0,
      paddingBottom: baseSizes[$size],
      backgroundColor: 'transparent',
      gap: reducedSizes[$size],
    },

    [`&.${accordionSummaryClasses.disabled}`]: {
      backgroundColor: theme.palette.transparent,
    },
  })
);

const StyledAccordionMap = {
  solid: StyledSolidAccordionSummary,
  outlined: StyledOutlinedAccordionSummary,
  label: StyledLabelAccordionSummary,
} as const;

type Props = {
  icon?: ReactNode;
  title: ReactNode;
  adornment?: ReactNode;
};

export const AccordionSummary = ({ icon, title, adornment }: Props) => {
  const { palette } = useTheme();
  const { variant, size, disabled, alwaysExpanded } = useAccordionContext();
  const Component = StyledAccordionMap[variant];

  const textColor = disabled && !alwaysExpanded ? 'inactive1' : variant === 'label' ? 'text2' : 'text1';

  return (
    <Component
      $size={size}
      $alwaysExpanded={alwaysExpanded}
      $variant={variant}
      expandIcon={
        alwaysExpanded ? undefined : <IconWrapper icon={ExpandMore} size={size} htmlColor={palette.neutral.color1} />
      }
    >
      {icon}
      <Stack direction="row" flex={1}>
        <Text variant={variant === 'label' ? variant : 'body-bold'} size={size} color={textColor}>
          {title}
        </Text>
      </Stack>
      {adornment && <Stack direction="row">{adornment}</Stack>}
    </Component>
  );
};
