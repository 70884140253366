import React, { type FunctionComponent, type SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import type { SvgIconComponent } from '@mui/icons-material';

import { Card, CardHeader, CardHeaderTitle, CardHeaderActions } from '@verticeone/design-system/src';

export type TableCardProps = {
  icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  tKey: string;
  filterComponent?: JSX.Element;
  children: React.ReactNode;
};

const TableCard = ({ icon, tKey, filterComponent, children }: TableCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle icon={icon} text={t('TITLE')} tag="h2" />
        {filterComponent && <CardHeaderActions gap={6}>{filterComponent}</CardHeaderActions>}
      </CardHeader>
      {children}
    </Card>
  );
};

export default TableCard;
