import { useTranslation } from 'react-i18next';

import { GoogleIcon } from '@vertice/assets';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type GoogleSetupCardProps = {
  providerId: string;
};

export const GoogleSetupCard = ({ providerId }: GoogleSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="Google"
      providerIcon={<GoogleIcon />}
      description={t('PREFERENCES.INTEGRATIONS.GOOGLE.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.GOOGLE_SETUP}`}
    />
  );
};
