import { forwardRef, ReactNode } from 'react';
import type { DesignSystemSize, TestProps } from '../../types';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
  tooltipClasses as muiTooltipClasses,
} from '@mui/material/Tooltip';
import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';
import { Text } from '../Text';
import { IconWrapper, IconWrapperProps } from '../IconWrapper';
import { Button } from '../Button';
import { baseSizes, reducedSizes } from '../../guidelines/Sizing/sizings';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { testProps } from '../../utils/testProperties';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export type TooltipProps = TestProps &
  Pick<MuiTooltipProps, 'placement' | 'children' | 'disableHoverListener'> & {
    title?: ReactNode;
    content?: ReactNode;
    tooltip?: ReactNode;
    size?: DesignSystemSize;
    minified?: boolean;
    noArrow?: boolean;
    button?: {
      label: string;
      onClick: () => void;
    };
    sx?: SxProps<Theme>;
    maxWidth?: string;
  };

const tooltipArrowSizes: Record<DesignSystemSize, number> = { XXS: 15, XS: 18, S: 21, M: 24, L: 27, XL: 30 };

const ARROW_SIDES_RATIO = Math.sqrt(2) / 2;

const TooltipWrapper = styled(({ className, testId, ...props }: MuiTooltipProps & TestProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} PopperProps={testProps(testId, 'tooltip') as any} />
))<{
  $size: DesignSystemSize;
  $minified?: boolean;
  $noArrow?: boolean;
  $maxWidth?: string;
}>(({ theme, $minified, $size, $noArrow, $maxWidth: maxWidth }) => {
  const arrowSize = tooltipArrowSizes[$size];
  const arrowSideSize = arrowSize * ARROW_SIDES_RATIO;
  const margin = $noArrow ? 8 : arrowSize / 2 + 4;
  const padding = $minified ? reducedSizes[$size] : baseSizes[$size];
  return {
    [`& .${muiTooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.core.color6,
      padding: padding,
      borderRadius: '8px',
      maxWidth,
      // left
      [`&.${muiTooltipClasses.tooltipPlacementLeft}`]: {
        marginRight: margin,
        [`.${muiTooltipClasses.arrow}`]: {
          marginRight: -arrowSideSize,
          width: arrowSideSize,
          height: arrowSize,
        },
      },
      // right
      [`&.${muiTooltipClasses.tooltipPlacementRight}`]: {
        marginLeft: margin,
        [`.${muiTooltipClasses.arrow}`]: {
          marginLeft: -arrowSideSize,
          width: arrowSideSize,
          height: arrowSize,
        },
      },
      // top
      [`&.${muiTooltipClasses.tooltipPlacementTop}`]: {
        marginBottom: margin,
        [`.${muiTooltipClasses.arrow}`]: {
          marginBottom: -arrowSideSize,
          width: arrowSize,
          height: arrowSideSize,
        },
      },
      // bottom
      [`&.${muiTooltipClasses.tooltipPlacementBottom}`]: {
        marginTop: margin,
        [`.${muiTooltipClasses.arrow}`]: {
          marginTop: -arrowSideSize,
          width: arrowSize,
          height: arrowSideSize,
        },
      },
    },
    [`& .${muiTooltipClasses.arrow}`]: {
      '&::before': {
        backgroundColor: theme.palette.core.color6,
      },
    },
  };
});

type TooltipInfoTriggerProps = Partial<IconWrapperProps>;

// Typical tooltip trigger that is used on many places
export const TooltipInfoTrigger = styled(
  forwardRef<SVGSVGElement, TooltipInfoTriggerProps>((props, ref) => (
    <IconWrapper {...props} icon={InfoIcon} size="M" ref={ref} />
  ))
)(({ theme, htmlColor }) => ({
  color: htmlColor || theme.palette.neutral.color2,
}));

export const Tooltip = ({
  title,
  content,
  button,
  size = 'M',
  minified,
  placement = 'top',
  noArrow,
  maxWidth,
  tooltip,
  children,
  testId,
  ...otherProps
}: TooltipProps) => {
  const baseSize = baseSizes[size];
  const tooltipFallback =
    title || content ? (
      <Stack sx={{ gap: `calc(${baseSize} * 0.25)` }}>
        {title && (
          <Text size={size} variant="body-bold" color="text5">
            {title}
          </Text>
        )}
        {content && (
          <Text size={size} variant="body-regular" color="text4">
            {content}
          </Text>
        )}
        {button && (
          <Button
            size={size}
            variant="solid"
            color="neutral"
            onClick={button.onClick}
            sx={{ marginTop: `calc(${baseSize} * 1.5)` }}
          >
            {button.label}
          </Button>
        )}
      </Stack>
    ) : (
      ''
    );

  return (
    <TooltipWrapper
      {...otherProps}
      arrow={!noArrow}
      placement={placement}
      title={tooltip || tooltipFallback}
      $size={size}
      $minified={minified}
      $noArrow={noArrow}
      $maxWidth={maxWidth}
      testId={testId}
    >
      {children}
    </TooltipWrapper>
  );
};
