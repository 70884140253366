export const getDiagonalCrossPattern = (color: string, backgroundColor?: string) => ({
  pattern: {
    path: {
      d: 'M 0 0 L 10 10 M 0 10 L 10 0',
      strokeWidth: 1,
    },
    width: 10,
    height: 10,
    color,
    backgroundColor,
  },
});

export const getDotPattern = (color: string, backgroundColor?: string, size = 10) => ({
  pattern: {
    path: {
      d: 'M 2 2 m -1, 0 a 1,1 0 1,0 2,0 a 1,1 0 1,0 -2,0', // solid dot, radius 1
      strokeWidth: 1,
    },
    width: size,
    height: size,
    color,
    backgroundColor,
  },
});
