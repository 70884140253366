import { rgba } from 'polished';
import type { ThemeVariant } from '../../types';
import type { PaletteColors, ColorVariants } from '../types';
import { colorPalette } from './colorPalette';

type GradientColor = Exclude<PaletteColors, 'white' | 'black'>;
export type GradientName =
  | 'blueToPurple'
  | 'blueToMagenta'
  | 'blueToRed'
  | 'blueToOrange'
  | 'blueToYellow'
  | 'blueToGreen'
  | 'blueToCyan'
  | 'purpleToBlue'
  | 'purpleToMagenta'
  | 'purpleToRed'
  | 'purpleToOrange'
  | 'purpleToYellow'
  | 'purpleToGreen'
  | 'purpleToCyan'
  | 'magentaToPurple'
  | 'magentaToRed'
  | 'redToMagenta'
  | 'redToOrange'
  | 'orangeToRed'
  | 'orangeToYellow'
  | 'yellowToOrange'
  | 'yellowToGreen'
  | 'greenToYellow'
  | 'greenToCyan'
  | 'cyanToGreen'
  | 'transparentToBlack05'
  | 'transparentToBlack10'
  | 'black40ToTransparent';

export const getGradient = (
  variant: Exclude<ThemeVariant, 'default'>,
  color1: GradientColor,
  color2: GradientColor
) => {
  const alpha = {
    light: ['70', '50'],
    dark: ['60', '40'],
  }[variant] as Array<ColorVariants>;

  // prettier-ignore
  return `linear-gradient(6.02deg, ${colorPalette[color1][alpha[0]].main} 0%, ${colorPalette[color2][alpha[1]].main} 100%)`;
};

const getGradientToTransparent = (variant: Exclude<ThemeVariant, 'default'>, transparency: number, rotation = 0) => {
  const color = variant === 'light' ? colorPalette.black.main : colorPalette.black.main;
  return `linear-gradient(${rotation}deg, ${rgba(color, transparency)}, ${rgba(color, 0)})`;
};

export const gradientPalette: Record<Exclude<ThemeVariant, 'default'>, Record<GradientName, string>> = {
  light: {
    blueToPurple: `linear-gradient(6.02deg, ${colorPalette.blue['70'].main} 0%, ${colorPalette.purple['60'].main} 100%)`,
    blueToMagenta: getGradient('light', 'blue', 'magenta'),
    blueToRed: getGradient('light', 'blue', 'red'),
    blueToOrange: getGradient('light', 'blue', 'orange'),
    blueToYellow: getGradient('light', 'blue', 'yellow'),
    blueToGreen: getGradient('light', 'blue', 'green'),
    blueToCyan: getGradient('light', 'blue', 'cyan'),
    purpleToBlue: getGradient('light', 'purple', 'blue'),
    purpleToMagenta: getGradient('light', 'purple', 'magenta'),
    purpleToRed: getGradient('light', 'purple', 'red'),
    purpleToOrange: getGradient('light', 'purple', 'orange'),
    purpleToYellow: getGradient('light', 'purple', 'yellow'),
    purpleToGreen: getGradient('light', 'purple', 'green'),
    purpleToCyan: getGradient('light', 'purple', 'cyan'),
    magentaToPurple: getGradient('light', 'magenta', 'purple'),
    magentaToRed: getGradient('light', 'magenta', 'red'),
    redToMagenta: getGradient('light', 'red', 'magenta'),
    redToOrange: getGradient('light', 'red', 'orange'),
    orangeToRed: getGradient('light', 'orange', 'red'),
    orangeToYellow: getGradient('light', 'orange', 'yellow'),
    yellowToOrange: getGradient('light', 'yellow', 'orange'),
    yellowToGreen: getGradient('light', 'yellow', 'green'),
    greenToYellow: getGradient('light', 'green', 'yellow'),
    greenToCyan: getGradient('light', 'green', 'cyan'),
    cyanToGreen: getGradient('light', 'cyan', 'green'),
    transparentToBlack05: getGradientToTransparent('light', 0.05),
    transparentToBlack10: getGradientToTransparent('light', 0.1),
    black40ToTransparent: getGradientToTransparent('light', 0.4, 180),
  },
  dark: {
    blueToPurple: `linear-gradient(6.02deg, ${colorPalette.blue['60'].main} 0%, ${colorPalette.purple['50'].main} 100%)`,
    blueToMagenta: getGradient('dark', 'blue', 'magenta'),
    blueToRed: getGradient('dark', 'blue', 'red'),
    blueToOrange: getGradient('dark', 'blue', 'orange'),
    blueToYellow: getGradient('dark', 'blue', 'yellow'),
    blueToGreen: getGradient('dark', 'blue', 'green'),
    blueToCyan: getGradient('dark', 'blue', 'cyan'),
    purpleToBlue: getGradient('dark', 'purple', 'blue'),
    purpleToMagenta: getGradient('dark', 'purple', 'magenta'),
    purpleToRed: getGradient('dark', 'purple', 'red'),
    purpleToOrange: getGradient('dark', 'purple', 'orange'),
    purpleToYellow: getGradient('dark', 'purple', 'yellow'),
    purpleToGreen: getGradient('dark', 'purple', 'green'),
    purpleToCyan: getGradient('dark', 'purple', 'cyan'),
    magentaToPurple: getGradient('dark', 'magenta', 'purple'),
    magentaToRed: getGradient('dark', 'magenta', 'red'),
    redToMagenta: getGradient('dark', 'red', 'magenta'),
    redToOrange: getGradient('dark', 'red', 'orange'),
    orangeToRed: getGradient('dark', 'orange', 'red'),
    orangeToYellow: getGradient('dark', 'orange', 'yellow'),
    yellowToOrange: getGradient('dark', 'yellow', 'orange'),
    yellowToGreen: getGradient('dark', 'yellow', 'green'),
    greenToYellow: getGradient('dark', 'green', 'yellow'),
    greenToCyan: getGradient('dark', 'green', 'cyan'),
    cyanToGreen: getGradient('dark', 'cyan', 'green'),
    transparentToBlack05: getGradientToTransparent('dark', 0.05),
    transparentToBlack10: getGradientToTransparent('dark', 0.1),
    black40ToTransparent: getGradientToTransparent('dark', 0.4),
  },
};
