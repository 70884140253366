import { AccountUser } from '@vertice/slices';

type UserStatus = 'DISABLED' | 'ACTIVE' | 'PENDING' | undefined;
type SSOUserStatus = 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'ERROR';
type DirectoryStatus =
  | 'NOT_APPLICABLE'
  | 'INACTIVE'
  | 'INVITED_MANAGED'
  | 'INVITED_SSO'
  | 'INVITED_SSO_MIGRATION'
  | 'ACTIVE_MANAGED'
  | 'ACTIVE_SSO'
  | 'ACTIVE_SSO_MIGRATION'
  | 'ACTIVE_VERTICE_EMPLOYEE'
  | undefined;

export const createUsersMap = (users: Array<AccountUser>) => new Map(users.map((user) => [user.userId, user]));

/**
 * Resolves the SSO status of a user based on the user status and directory status.
 * The SSO status is determined by the following rules:
 *  - If the user status is DISABLED, the SSO status is INACTIVE
 *  - If the user status is PENDING, the SSO status is INACTIVE if the directory status is INVITED_MANAGED, otherwise PENDING
 *  - If the user status is ACTIVE, the SSO status is PENDING if the directory status is INVITED_SSO or INVITED_SSO_MIGRATION, otherwise ACTIVE
 *  - If the user status is undefined, the SSO status is ERROR
 * @param userStatus
 * @param directoryStatus
 */
export const resolveSSOUserStatus = (userStatus: UserStatus, directoryStatus: DirectoryStatus): SSOUserStatus => {
  if (userStatus === 'DISABLED') {
    return 'INACTIVE';
  }

  if (userStatus === 'PENDING') {
    if (directoryStatus === 'INVITED_MANAGED') {
      return 'INACTIVE';
    }

    if (['INVITED_SSO', 'INVITED_SSO_MIGRATION'].includes(directoryStatus!)) {
      return 'PENDING';
    }
  }

  if (userStatus === 'ACTIVE') {
    if (directoryStatus === 'INVITED_SSO' || directoryStatus === 'INVITED_SSO_MIGRATION') {
      return 'PENDING';
    }

    if (directoryStatus === 'ACTIVE_SSO' || directoryStatus === 'ACTIVE_SSO_MIGRATION') {
      return 'ACTIVE';
    }

    if (directoryStatus === 'ACTIVE_MANAGED') {
      return 'INACTIVE';
    }
  }

  return 'ERROR';
};
