import {
  LatestOptimizationCheckQuery,
  MonitoringValueCheckResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getTableData } from '../../utils/graphDataUtils';
import { chain, sumBy } from 'lodash';
import { AWS_DEFAULT_CURRENCY } from '../../constants';
import { FeatureBySpend, TableDataOfFeatureBySpend } from './types';

const getFeatureValues = (tableData: TableDataOfFeatureBySpend[]): FeatureBySpend[] =>
  chain(tableData)
    .groupBy(({ feature }) => feature)
    .map((valuesPerService, feature) => ({
      feature,
      value: sumBy(valuesPerService, ({ value }) => {
        return value >= 0 ? value : 0;
      }),
      path: [feature],
    }))
    .value();

const getSubFeatureValues = (tableData: TableDataOfFeatureBySpend[]): FeatureBySpend[] =>
  chain(tableData)
    .map((valuesPerService) => {
      const { value, subfeature, feature } = valuesPerService;

      return {
        feature,
        value: value,
        path: [feature, subfeature],
      };
    })
    .value()
    .filter(Boolean);

export const prepareTopFeaturesBySpendData = (rawData: LatestOptimizationCheckQuery | undefined) => {
  if (
    !rawData ||
    rawData?.monitoringLatestQuery?.__typename !== 'MonitoringResult' ||
    !rawData.monitoringLatestQuery.items
  ) {
    return undefined;
  }

  const monitoringValueResult = rawData.monitoringLatestQuery.items[0].results as MonitoringValueCheckResult;

  const tableData = getTableData(monitoringValueResult, {
    feature: 'feature',
    subfeature: 'subfeature',
    cost: 'value',
  }) as TableDataOfFeatureBySpend[];

  const featureValues = getFeatureValues(tableData);
  const subFeatureValues = getSubFeatureValues(tableData);

  const items = chain([...subFeatureValues, ...featureValues])
    .orderBy('value', 'desc')
    .value();

  return {
    currency: AWS_DEFAULT_CURRENCY,
    items,
  };
};
