import React, { ReactNode } from 'react';
import { useController, FieldValues } from 'react-hook-form';
import { CommonFormFieldProps } from '../../types';
import { GridRowParams } from '@mui/x-data-grid-pro';
import {
  CostModelProductItem,
  RollingFrequency,
  CostModelProductList,
} from '../../../saas/contract/components/CostModelProductList';
import { ProductItem } from '../../../saas/contract/components/ProductList/types';

type FormCostModelProductsFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  vendorId?: string;
  currency?: string;
  editable?: boolean;
  rollingFrequency: RollingFrequency;
  detailRowRenderer?: (params: GridRowParams) => ReactNode;
  productCellDecorator?: (row: ProductItem) => ReactNode;
};

export type FormCostModelProductsFieldComponentType<FormDataType extends FieldValues> = (
  props: FormCostModelProductsFieldProps<FormDataType>
) => JSX.Element;

export const FormCostModelProductsField = <FormDataType extends FieldValues = never>({
  name,
  required,
  editable,
  vendorId,
  currency,
  rollingFrequency,
  detailRowRenderer,
  productCellDecorator,
}: FormCostModelProductsFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
    },
  });

  return (
    <CostModelProductList
      withActions={editable}
      detailRowRenderer={detailRowRenderer}
      productCellDecorator={productCellDecorator}
      selectedProducts={(value as CostModelProductItem[]) || []}
      setSelectedProducts={(newValue) => {
        field.onChange(newValue);
      }}
      currency={currency}
      vendorId={vendorId}
      rollingFrequency={rollingFrequency}
    />
  );
};
