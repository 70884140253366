import { GridRowModel } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';
import { asProductItem } from '../ProductList/utils';
import { CostModelProductItem } from './types';

export const asCostModelProductItem =
  (vendorId?: string) =>
  (newRow: GridRowModel, vendorProducts: Product[]): CostModelProductItem => {
    const hasSpan =
      newRow.allocationSpan?.startDate || newRow.allocationSpan?.endDate || newRow.allocationSpan?.rollingFrequency;

    return {
      ...asProductItem(newRow, vendorProducts),
      licenseCost: newRow.licenseCost,
      totalCost: newRow.totalCost,
      vendorId: vendorId,
      allocationSpan: hasSpan
        ? {
            startDate: newRow.allocationSpan?.startDate,
            endDate: newRow.allocationSpan?.endDate,
            rollingFrequency: newRow.allocationSpan?.rollingFrequency,
          }
        : undefined,
    };
  };
