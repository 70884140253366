/**
 * Turn `lineHeight` value into something that can be fed into the `height` CSS property.
 * Uses 1em as fallback for unsupported lineHeights.
 * @example
 * lineHeightToHeight(1.2) -> 'calc(1em * 1.2)'
 * lineHeightToHeight('120%') -> 'calc(1em * 1.2)'
 * lineHeightToHeight('16px') -> '16px'
 * // Unsupported unit:
 * lineHeightToHeight('1.2rem') -> 'calc(1em * 1)'
 */
export const lineHeightToHeight = (lineHeight: string | number) => {
  if (typeof lineHeight === 'string' && lineHeight.endsWith('px')) {
    return lineHeight;
  }

  const multiplier: number =
    // eslint-disable-next-line no-nested-ternary
    typeof lineHeight === 'number' ? lineHeight : lineHeight.endsWith('%') ? parseFloat(lineHeight) / 100 : 1;

  return `calc(1em * ${multiplier})`;
};
