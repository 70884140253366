import dayjs from 'dayjs';
import { SECOND_IN_MILLISECONDS } from '../../../pages/Cloud/CloudRIOptimiser/tabs/CloudOptimizeTab/instances/utils';
import useTermFormatter from './useTermFormater';

const useDurationFormatter = () => {
  const formatTerm = useTermFormatter();

  return (value: number) =>
    value ? formatTerm(Math.round(dayjs.duration(value * SECOND_IN_MILLISECONDS).asMonths())) : '';
};

export default useDurationFormatter;
