import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { FieldValues } from 'react-hook-form';

import { SelectColor } from '@verticeone/design-system/src';
import { SimpleOption } from '@verticeone/design-system/src';

import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { CommonFormEntryProps, SingleSelectComponent } from './types';
import { keyBy } from 'lodash';

export type OfferFrequency = 'MONTHLY' | 'QUARTERLY' | 'BIANNUALLY' | 'ANNUALLY' | 'NO';
export const OFFER_FREQUENCY_OPTIONS: [OfferFrequency, ...OfferFrequency[]] = [
  'MONTHLY',
  'QUARTERLY',
  'BIANNUALLY',
  'ANNUALLY',
  'NO',
];

export const OfferFrequencyFormEntry = <FormDataType extends FieldValues>({
  name,
  label,
}: CommonFormEntryProps<FormDataType>) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      OFFER_FREQUENCY_OPTIONS.map<SimpleOption>((value) => ({
        value,
        label: t(`ENTITIES.CONTRACT.LABELS.FREQUENCY_${value.toUpperCase()}`),
      })),
    [t]
  );

  const optionsMap = useMemo(() => keyBy(options, 'value'), [options]);

  return (
    <TaskFormEntry<any, SingleSelectComponent>
      name={name}
      label={label}
      component={FormSelectField2}
      componentProps={{
        isClearable: false,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR as SelectColor,
        options,
        boxValue: (value) => (value ? optionsMap[value] : undefined),
        unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
        testId: 'rolling-frequency',
      }}
      width={6}
    />
  );
};
