import { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';

import { TaskDefinition, TasksThumbnail } from '../../definitionsTypes';
import { TaskIcon } from '../../sharedVisualStyle/taskStyle';
import { resolveTaskNodeThumbnailConfiguration } from '../model/resolveTaskNodeThumbnailConfiguration';
import { getTaskAssignmentConfigurations, updateTaskDefinition } from '../../definitions/taskDefinition';

import { EditUserTaskForm } from './forms/EditUserTaskForm/EditUserTaskForm';
import { EditUserTaskFormData, editUserTaskFormSchema } from './forms/EditUserTaskForm/schema';
import {
  getAssignmentConfigurationsFromAssignees,
  getAssigneesFromAssignmentConfigurations,
} from './forms/EditUserTaskForm/assignmentOptions';
import { EditorDrawer } from './EditorDrawer';
import useAddEditInviteContactModal from '../../../preferences/useAddEditInviteContactModal';

export type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

type EditUserTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  close: VoidFunction;
  saveTask: (task: TaskDefinition) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
};

const EditUserTaskDrawerHeader = ({ text, thumbnail }: { text: string; thumbnail: TasksThumbnail }) => {
  if (!thumbnail) return null;

  return (
    <Stack direction="row" gap={2} alignItems={'center'} p={6}>
      <TaskIcon userId={''} status={'PENDING'} type={thumbnail.type} id={thumbnail.id} />
      <Text variant="heading" size="S" color="text1">
        {text}
      </Text>
    </Stack>
  );
};

export const EditUserTaskDrawer = ({
  isOpen,
  task,
  close,
  saveTask,
  onDirty,
  editorConfig,
}: EditUserTaskDrawerProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const values = useMemo<EditUserTaskFormData>(() => {
    if (task === undefined) {
      return {
        name: '',
        assignees: [],
        description: '',
      };
    }
    const assignmentConfigurations = getTaskAssignmentConfigurations(task);

    return {
      name: task.task.name || '',
      assignees: getAssigneesFromAssignmentConfigurations(assignmentConfigurations),
      description: task.task.description || '',
    };
  }, [task]);

  const handlers = useForm<EditUserTaskFormData>({
    resolver: zodResolver(editUserTaskFormSchema),
    defaultValues: values,
    values,
  });

  const thumbnail = resolveTaskNodeThumbnailConfiguration(task?.task.configurations!);

  const onSubmit = (data: EditUserTaskFormData) => {
    if (!task) return;

    const newAssignmentConfigurations = getAssignmentConfigurationsFromAssignees(data.assignees);

    const newTask = updateTaskDefinition(task, {
      name: data.name,
      description: data.description,
      assignmentConfigurations: newAssignmentConfigurations,
    });

    saveTask(newTask);
  };

  const { modal, handleAddRequest } = useAddEditInviteContactModal({
    adminMode: false,
    warningMessage: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.NEW_USER_WARNING'),
  });

  return (
    <EditorDrawer open={isOpen}>
      <EditUserTaskDrawerHeader text={task?.task.name!} thumbnail={thumbnail} />
      <Stack maxHeight="100%" height="100%" width={'100%'}>
        <FormProvider {...handlers}>
          <form style={{ width: '100%', height: '100%' }} onSubmit={handlers.handleSubmit(onSubmit)}>
            <Stack direction="column" justifyContent="space-between" height={'100%'} width={'100%'}>
              <Stack direction="row" justifyContent="space-between" px={6} overflow="auto" width={'100%'}>
                <EditUserTaskForm
                  onDirty={onDirty}
                  onAddUser={handleAddRequest}
                  allowContractOwnerAssignment={editorConfig.allowContractOwnerAssignment}
                />
              </Stack>
              <Stack gap={1} p={6} direction={'row'} borderTop={`1px solid ${palette.core.color3}`}>
                <Button onClick={close} variant="outline" color="secondary" size="S" fullWidth>
                  {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.DISCARD')}
                </Button>
                <Button variant="solid" color="secondary" size="S" fullWidth type="submit">
                  {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.APPLY')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
        {modal}
      </Stack>
    </EditorDrawer>
  );
};
