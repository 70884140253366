import { pick } from 'lodash';
import type { FileError } from 'react-dropzone';
import { type FileType, type FileTypeParams, SUPPORTED_FILE_TYPES } from './supportedFileTypes';

export type FilesProgress = {
  [fileName: string]: {
    progress: number;
    status: 'ready' | 'uploading' | 'uploaded' | 'cancelled';
  };
};

export type FileAccepted = {
  file: File;
};

export type FileRejected = {
  file: File;
  errors: FileError[];
};

export const acceptableFileType = (extensionKeys: Array<FileType>): Record<string, FileTypeParams['extensions']> => {
  return Object.values(pick(SUPPORTED_FILE_TYPES, extensionKeys)).reduce(
    (acc, curr) => ({
      ...acc,
      ...curr.mimeTypes.reduce((accMimeTypes, type) => ({ ...accMimeTypes, [type]: curr.extensions }), {}),
    }),
    {}
  );
};

export const getFileTypes = (extensionKeys: Array<FileType>): Array<string> =>
  Object.values(pick(SUPPORTED_FILE_TYPES, extensionKeys))
    .map((ext) => ext.extensions.map((e) => e.substring(1).toUpperCase()))
    .flat();

export const transformFilesToAccepted = (files: Array<File>): Array<FileAccepted> => files.map((file) => ({ file }));

export const isFileRejectedError = (file: FileAccepted | FileRejected): file is FileRejected => {
  return (file as FileRejected).errors !== undefined;
};

/**
 * @param originalFileName is the fileName including the extension.
 * @param maxLength is length of file name without extension.
 */
export const getShortenFileName = (originalFileName: string, maxLength: number): string => {
  const matches = originalFileName.match(/^(.+)\.([^.]+)$/);

  /**
   * maxLength must be 5 or more because it replaces 3 middle chars
   * @example abcde.jpg => a...e.jpg
   */
  if (matches?.[1] && matches?.[2] && maxLength > 4 && matches[1].length > maxLength) {
    return `${matches[1].slice(0, maxLength / 2 - 1)}...${matches[1].slice(-maxLength / 2 + 2)}.${matches[2]}`;
  }

  return originalFileName;
};

export const formatSize = (bytes: number) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1).replace('.0', '')} ${units[u]}`;
};

export const filesToFilesProgress = (prev: FilesProgress, files: Array<FileAccepted | FileRejected>) => {
  //prettier-ignore
  return files.reduce((acc, file) => ({
    ...acc,
    [file.file.name]: {
      progress: prev[file.file.name]?.progress ?? 0,
      status: 'ready',
    },
  }), {});
};
