import { Stack } from '@mui/material';
import { StyledStackCard } from './ServiceCard';

import { Placeholder } from '@verticeone/design-system/src';

export const ServicePlaceholderCard = () => {
  return (
    <StyledStackCard>
      <Placeholder height={160} />
      <Stack padding={4} gap={2}>
        <Placeholder height={40} />
        <Placeholder height={20} />
      </Stack>
    </StyledStackCard>
  );
};
