import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useVisibleCustomFields } from './useVisibleCustomFields';

export const useCanSeeCustomFields = () => {
  const { isEnabled } = useFeatures();
  const contractCustomizationEnabled = isEnabled(FEATURES.CONTRACTS_CUSTOMIZATION);
  const { visibleFields: visibleCustomFields } = useVisibleCustomFields({ skip: !contractCustomizationEnabled });

  return contractCustomizationEnabled && !!visibleCustomFields?.length;
};
