import { Stack, styled } from '@mui/material';

export const ProgressiveDisclosureStack = styled(Stack)<{ height?: string }>`
  height: 0px;
  opacity: 0;
  transition: opacity 100ms linear, height 100ms linear;
  .Mui-hovered & {
    transition: opacity 100ms linear, height 100ms linear;
    ${(props) => `height: ${props.height || '22px'};`}
    opacity: 1;
  }
`;
