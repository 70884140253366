import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

type BreakdownHeaderProps = {
  serviceName: string;
  resourceCost: number;
};

const GraphBreakdownHeader = ({ serviceName, resourceCost }: BreakdownHeaderProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW.WIDGETS.COMPUTE' });
  const formatCurrency = useFormatCurrency();

  return (
    <Stack direction="row" justifyContent="space-between" pr={5}>
      <Text variant="caption" size="S" color="text2">
        {serviceName}
      </Text>
      <Text variant="body-regular" size="S" color="text2">
        {t('RESOURCE_COST', {
          resourceCost: formatCurrency(resourceCost, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 }),
        })}
      </Text>
    </Stack>
  );
};

export default GraphBreakdownHeader;
