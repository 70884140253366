import { useTranslation } from 'react-i18next';

import { OktaIcon } from '@vertice/assets';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type OktaSetupCardProps = {
  providerId: string;
};

export const OktaSetupCard = ({ providerId }: OktaSetupCardProps) => {
  const { t } = useTranslation();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="Okta"
      providerIcon={<OktaIcon />}
      description={t('PREFERENCES.INTEGRATIONS.OKTA.DESCRIPTION')}
      wizardRoute={`${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.OKTA_SETUP}`}
    />
  );
};
