import {
  ApiVendor,
  ContractFormData,
  ContractFormProducts,
  CostModelMaterialized,
  FetchedContract,
  PurchaseRequestInputPresetConfiguration,
  RenewalRequestInputPresetConfiguration,
  WorkflowStage,
} from './types';
import {
  ContractCreate,
  ContractPartContractual,
  ContractPartContractualRenewal,
  ContractUpdate,
  Contract,
  ContractClassificationUpdate,
  VendorColabDetails,
  ContractModel2,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { nullifyEmptyValues, safePick, omitEmptyValues } from '@verticeone/utils/objects';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';
import { PurchaseRequestInput } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { Vendor } from '@vertice/core/src/modules/vendor/types';
import { CostModelTransformationsMap } from './costModels/CostModelTransformationsMap';
import { CostModelClassType, DefaultOverviewValues } from './costModels/types';

const emptyCustomFields = {
  checkbox01: null,
  checkbox02: null,
  checkbox03: null,
  checkbox04: null,
  checkbox05: null,
  date01: null,
  date02: null,
  date03: null,
  date04: null,
  date05: null,
  decimal01: null,
  decimal02: null,
  decimal03: null,
  decimal04: null,
  decimal05: null,
  dropdown01: null,
  dropdown02: null,
  dropdown03: null,
  dropdown04: null,
  dropdown05: null,
  text01: null,
  text02: null,
  text03: null,
  text04: null,
  text05: null,
  textarea01: null,
  textarea02: null,
};

export const apiVendorToSelectItem = (apiVendor: ApiVendor | undefined): Vendor | null => {
  if (!apiVendor) {
    return null;
  }
  const { vendorType, vendorId, vendorName } = apiVendor;

  if (!vendorId && !vendorName) {
    return null;
  }

  return vendorType === 'PREDEFINED' && vendorId
    ? {
        type: 'PREDEFINED',
        id: vendorId,
        name: vendorName,
      }
    : {
        type: 'INLINE',
        id: vendorId,
        name: vendorName || '', // vendorName should always be present for INLINE vendors
      };
};

const vendorOptionToApiInput = (vendorOption: Vendor) => ({
  vendorType: vendorOption.type,
  vendorId: vendorOption.id,
  vendorName: vendorOption.name,
});

export const toProductsSelectItem = (contractProducts: ContractPartContractual['products']): ContractFormProducts => {
  if (!contractProducts) {
    return [];
  }
  return contractProducts.map((p) => {
    return {
      id: p.productId!,
      name: p.productName!,
      productId: p.productId!,
      numberOfLicences: p.licenseCount ?? null,
      licenseType: p.licenseType
        ? {
            id: p.licenseType.id,
            label: p.licenseType.label ?? '',
          }
        : null,
      annualCost: p.annualCost ?? null,
    };
  });
};

export const apiCostModelToFormData = (
  contractModel?: ContractModel2,
  defaultValues?: DefaultOverviewValues
): CostModelMaterialized | undefined => {
  if (!contractModel) {
    return undefined;
  }
  const costModelTransformation = CostModelTransformationsMap[contractModel?.class as CostModelClassType];
  return costModelTransformation?.apiToForm(contractModel, defaultValues);
};

export const fetchedContractToForm = ({ contract }: FetchedContract): ContractFormData => ({
  record: safePick(contract.record ?? {}, 'contractOrigin', 'contractCategory', 'status'),
  parts: {
    overview: contract.parts.overview ?? {},
    model: {
      cost: {
        configuration: {
          negotiated: contract.parts.model?.cost?.configuration?.negotiated,
          baseline: contract.parts.model?.cost?.configuration?.baseline,
        },
      },
    },
    contractual: {
      lifecycle: {
        startDate: contract.parts.contractual?.lifecycle?.startDate,
        renewalDate: contract.parts.contractual?.lifecycle?.renewalDate,
        autoRenewalDeadline: contract.parts.contractual?.lifecycle?.autoRenewalDeadline,
        endDate: contract.parts.contractual?.lifecycle?.endDate,
        rollFrequency: contract.parts.contractual?.lifecycle?.rollFrequency,
        cancellationReason: contract.parts.contractual?.lifecycle?.cancellationReason,
        cancellationNote: contract.parts.contractual?.lifecycle?.cancellationNote,
        cancellationDate: contract.parts.contractual?.lifecycle?.cancellationDate,
      },
      renewal: {
        markedForExpiration: contract.parts.contractual?.renewal?.markedForExpiration,
        autoRenewalWaived: contract.parts.contractual?.renewal?.autoRenewalWaived,
      },
      financial: {
        baseCurrency: contract.parts.contractual?.financial?.baseCurrency,
        billingFrequency: contract.parts.contractual?.financial?.billingFrequency,
        billingFrequencyOther: contract.parts.contractual?.financial?.billingFrequencyOther,
        paymentTerms: contract.parts.contractual?.financial?.paymentTerms,
        paymentTermsOther: contract.parts.contractual?.financial?.paymentTermsOther,
      },
      signatory: {
        signingDate: contract.parts.contractual?.signatory?.signingDate,
        signer: {
          name: contract.parts.contractual?.signatory?.signer?.name,
          email: contract.parts.contractual?.signatory?.signer?.email,
          role: contract.parts.contractual?.signatory?.signer?.role,
        },
        signingEntity: {
          name: contract.parts.contractual?.signatory?.signingEntity?.name,
          address: {
            line1: contract.parts.contractual?.signatory?.signingEntity?.address?.line1,
            line2: contract.parts.contractual?.signatory?.signingEntity?.address?.line2,
            city: contract.parts.contractual?.signatory?.signingEntity?.address?.city,
            zip: contract.parts.contractual?.signatory?.signingEntity?.address?.zip,
            country: contract.parts.contractual?.signatory?.signingEntity?.address?.country,
          },
        },
      },
      vendor: {
        vendorOption: apiVendorToSelectItem(contract.parts?.contractual?.vendor),
        vendorContact: {
          name: contract.parts.contractual?.vendor?.vendorContact?.name,
          email: contract.parts.contractual?.vendor?.vendorContact?.email,
          role: contract.parts.contractual?.vendor?.vendorContact?.role,
          phone: contract.parts.contractual?.vendor?.vendorContact?.phone,
        },
        purchasedFromReseller: contract.parts.contractual?.vendor?.purchasedFromReseller,
        resellerName: contract.parts.contractual?.vendor?.resellerName,
      },
      products: toProductsSelectItem(contract.parts.contractual?.products),
    },
  },
  additionalDetails: {
    internal: {
      notes: contract.additionalDetails?.internal?.notes,
      vendorColabDetails: contract.additionalDetails?.internal?.vendorColabDetails,
    },
    external: {
      notes: contract.additionalDetails?.external?.notes,
    },
  },
  classification: {
    category: contract.classification?.category,
    region: contract.classification?.region,
    departmentId: contract.classification?.departmentId,
    contractOwner: contract.classification?.contractOwner,
    contractWatchers: contract.classification?.contractWatchers,
    departmentOwner: contract.classification?.departmentOwner,
    departmentWatchers: contract.classification?.departmentWatchers,
    assignedPuMa: contract.classification?.assignedPuMa,
  },
  workflow: {
    stage: contract.workflow?.stage,
    cancellationReason: contract.workflow?.cancellationReason,
    cancellationNote: contract.workflow?.cancellationNote,
    externalDocuments: safePick(
      contract.workflow?.externalDocuments ?? {},
      'caseStudies',
      'contactVendorDirectly',
      'desiredContractLength',
      'discussCompetitors',
      'legalReviewRequired',
      'multiYear',
      'piiExpected',
      'plannedBudget',
      'plannedGrowth',
      'plannedGrowthNotes',
      'renewalType',
      'requestOwner',
      'requestOwnerEmail',
      'securityReviewRequired',
      'targetSignDate'
    ),
    internalDocuments: {
      listPrice: contract.workflow?.internalDocuments?.listPrice,
      leversUsed: contract.workflow?.internalDocuments?.leversUsed,
      competitionUsed: contract.workflow?.internalDocuments?.competitionUsed,
    },
  },
  customFields: contract.customFields ?? emptyCustomFields,
  costModel: {
    baseline: apiCostModelToFormData(contract.parts.model?.cost?.configuration?.baseline, {
      defaultAnnualCost: contract.parts.overview?.annualCostBaseline,
    }),
    negotiated: apiCostModelToFormData(contract.parts.model?.cost?.configuration?.negotiated, {
      defaultAnnualCost: contract.parts.overview?.annualCostNegotiated,
      defaultScopeReductionSavings: contract.parts.overview?.annualScopeReductionSavings,
    }),
  },
});

type FormToApiExtraOptions = {
  allowRestrictedFields?: boolean;
  defaultCostModelClass?: string;
};

export const formToApiContract = (
  formData: ContractFormData,
  mode: 'create' | 'update' = 'create',
  { allowRestrictedFields = false, defaultCostModelClass }: FormToApiExtraOptions = {}
): ContractUpdate | ContractCreate => {
  const preprocess = mode === 'update' ? nullifyEmptyValues : omitEmptyValues;

  const formCostModelToApiCostModel = (formDataCostModel?: CostModelMaterialized): ContractModel2 | undefined => {
    if (!formDataCostModel) {
      return undefined;
    }

    const costModelClass = (formDataCostModel?.class as CostModelClassType) ?? defaultCostModelClass;
    const costModelTransformation = CostModelTransformationsMap[costModelClass];
    return costModelTransformation?.formToApi(formDataCostModel, mode);
  };

  return {
    record: safePick(formData.record ?? {}, 'contractOrigin', 'contractCategory', 'status'),
    parts: {
      model: {
        cost: {
          configuration: {
            baseline: formCostModelToApiCostModel(formData.costModel?.baseline),
            negotiated: formCostModelToApiCostModel(formData.costModel?.negotiated),
          },
        },
      },
      contractual: preprocess({
        lifecycle: preprocess({
          startDate: keepOnlyLocalDatePart(formData.parts.contractual?.lifecycle?.startDate),
          renewalDate: keepOnlyLocalDatePart(formData.parts.contractual?.lifecycle?.renewalDate),
          autoRenewalDeadline: keepOnlyLocalDatePart(formData.parts.contractual?.lifecycle?.autoRenewalDeadline),
          endDate: keepOnlyLocalDatePart(formData.parts.contractual?.lifecycle?.endDate),
          rollFrequency: formData.parts.contractual?.lifecycle?.rollFrequency,
          cancellationReason: formData.parts.contractual?.lifecycle?.cancellationReason,
          cancellationNote: formData.parts.contractual?.lifecycle?.cancellationNote,
          cancellationDate: keepOnlyLocalDatePart(formData.parts.contractual?.lifecycle?.cancellationDate),
        }),
        renewal: preprocess({
          markedForExpiration: formData.parts.contractual?.renewal?.markedForExpiration,
          autoRenewalWaived: formData.parts.contractual?.renewal?.autoRenewalWaived,
        }) as unknown as ContractPartContractualRenewal,
        financial: preprocess({
          baseCurrency: formData.parts.contractual?.financial?.baseCurrency,
          billingFrequency: formData.parts.contractual?.financial?.billingFrequency,
          billingFrequencyOther: formData.parts.contractual?.financial?.billingFrequencyOther,
          paymentTerms: formData.parts.contractual?.financial?.paymentTerms,
          paymentTermsOther: formData.parts.contractual?.financial?.paymentTermsOther,
        }),
        signatory: preprocess({
          signingDate: keepOnlyLocalDatePart(formData.parts?.contractual?.signatory?.signingDate),
          signer: preprocess({
            name: formData.parts.contractual?.signatory?.signer?.name,
            email: formData.parts.contractual?.signatory?.signer?.email,
            role: formData.parts.contractual?.signatory?.signer?.role,
          }),
          signingEntity: preprocess({
            name: formData.parts?.contractual?.signatory?.signingEntity?.name,
            address: preprocess({
              line1: formData.parts.contractual?.signatory?.signingEntity?.address?.line1,
              line2: formData.parts.contractual?.signatory?.signingEntity?.address?.line2,
              city: formData.parts.contractual?.signatory?.signingEntity?.address?.city,
              zip: formData.parts.contractual?.signatory?.signingEntity?.address?.zip,
              country: formData.parts.contractual?.signatory?.signingEntity?.address?.country,
            }),
          }),
        }),
        vendor: preprocess({
          ...vendorOptionToApiInput(formData.parts.contractual.vendor?.vendorOption!),
          vendorContact: preprocess({
            name: formData.parts.contractual?.vendor?.vendorContact?.name,
            email: formData.parts.contractual?.vendor?.vendorContact?.email,
            role: formData.parts.contractual?.vendor?.vendorContact?.role,
            phone: formData.parts.contractual?.vendor?.vendorContact?.phone,
          }),
          purchasedFromReseller: formData.parts.contractual?.vendor?.purchasedFromReseller,
          resellerName: formData.parts.contractual?.vendor?.resellerName,
        }),
        products:
          formData.parts.contractual.products?.map((p) => {
            return {
              productId: p.productId,
              licenseType: p.licenseType ?? undefined,
              annualCost: p.annualCost ?? undefined,
              licenseCount: p.numberOfLicences ?? undefined,
            };
          }) || [],
      }),
    },
    additionalDetails: {
      internal: allowRestrictedFields
        ? preprocess({
            notes: formData.additionalDetails.internal?.notes,
            vendorColabDetails: preprocess<VendorColabDetails>(
              safePick(
                formData.additionalDetails.internal?.vendorColabDetails ?? {},
                'ragStatus',
                'outcome',
                'customerSentiment',
                'notes'
              )
            ) as VendorColabDetails,
          })
        : undefined,
      external: preprocess({
        notes: formData.additionalDetails.external?.notes,
      }),
    },
    classification: preprocess<ContractClassificationUpdate>({
      category: formData.classification?.category,
      region: formData.classification?.region,
      departmentId: formData.classification?.departmentId,
      contractOwner: formData.classification?.contractOwner as { userId: string },
      contractWatchers: formData.classification?.contractWatchers as { userId: string }[],
      assignedPuMa: formData.classification?.assignedPuMa as { userId: string },
    }) as ContractClassificationUpdate,
    workflow: {
      ...preprocess({
        cancellationReason: formData.workflow?.cancellationReason,
        cancellationNote: formData.workflow?.cancellationNote,
        externalDocuments: preprocess({
          ...safePick(
            formData.workflow?.externalDocuments ?? {},
            'caseStudies',
            'contactVendorDirectly',
            'desiredContractLength',
            'discussCompetitors',
            'legalReviewRequired',
            'multiYear',
            'piiExpected',
            'plannedBudget',
            'plannedGrowth',
            'plannedGrowthNotes',
            'renewalType',
            'requestOwner',
            'requestOwnerEmail',
            'securityReviewRequired'
          ),
          targetSignDate: keepOnlyLocalDatePart(formData.workflow?.externalDocuments?.targetSignDate),
        }),
      }),
      internalDocuments: allowRestrictedFields
        ? preprocess({
            listPrice: formData.workflow?.internalDocuments?.listPrice,
            competitionUsed: formData.workflow?.internalDocuments?.competitionUsed,
            leversUsed: formData.workflow?.internalDocuments?.leversUsed,
          })
        : undefined,
    },
    customFields: preprocess(formData.customFields),
  };
};

export const formToMinimalApiPurchaseRequest = (formData: ContractFormData): PurchaseRequestInput => {
  if (
    !formData.parts.contractual?.vendor?.vendorOption?.type ||
    !formData.parts.contractual?.vendor?.vendorOption?.name
  ) {
    throw new Error('To create a purchase request, vendor is required');
  }

  return omitEmptyValues<PurchaseRequestInput>(vendorOptionToApiInput(formData.parts.contractual.vendor.vendorOption));
};

export const contractToPurchaseRequestInputPresetConfiguration = (
  contract: Contract
): PurchaseRequestInputPresetConfiguration => {
  const workflowStage: WorkflowStage = 'REQUIREMENTS_GATHERING'; // separate variable = check for typos

  return {
    kind: 'InputPreset',
    configuration: {
      contractId: contract.record.contractId,
      stage: workflowStage,
    },
  };
};

export const contractToRenewalRequestInputPresetConfiguration = (
  parentContractId: string,
  contract: Contract
): RenewalRequestInputPresetConfiguration => {
  const workflowStage: WorkflowStage = 'REQUIREMENTS_GATHERING'; // separate variable = check for typos

  return {
    kind: 'InputPreset',
    configuration: {
      contractId: contract.record.contractId,
      parentContractId: parentContractId,
      contractCategory: contract.record.contractCategory ?? null,
      vendorType: contract?.parts?.contractual?.vendor?.vendorType ?? null,
      vendorName: contract?.parts?.contractual?.vendor?.vendorName ?? null,
      vendorId: contract?.parts?.contractual?.vendor?.vendorId ?? null,
      stage: workflowStage,
    },
  };
};
