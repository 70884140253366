import { useTranslation } from 'react-i18next';

import { SlackDialog } from './SlackDialog';

type UnlinkDialogProps = {
  isOpened: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

export const DisconnectSlackDialog = (props: UnlinkDialogProps) => {
  const { t } = useTranslation();

  return (
    <SlackDialog
      header={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_HEADER')}
      contentText={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_TEXT')}
      buttonLabel={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_BUTTON')}
      {...props}
    />
  );
};
