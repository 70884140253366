import { useMutation } from '@tanstack/react-query';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useCloudClient } from '../../../../CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { type UpdateRecommendationsMutationVariables } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';
import { QUERY_KEY as OPTIMIZATIONS_QUERY_KEY } from '../../../dataSource/useOptimizations';

export const QUERY_KEY = 'EDPContractCreate' as const;

const UpdateRecommendationsQuery = graphql(`
  mutation UpdateRecommendations($accountId: String!, $recommendations: [RecommendationSettings]) {
    updateSettings(params: { accountId: $accountId, recommendations: $recommendations }) {
      ... on PutEventDetails {
        entries {
          ... on EventDetails {
            eventId
          }
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

const useUpdateRecommendationsMutation = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization, queryClient } = useCloudClient();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (data: Omit<UpdateRecommendationsMutationVariables, 'accountId'>) => {
      return fetchCloudOptimization(UpdateRecommendationsQuery, {
        accountId,
        ...data,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [OPTIMIZATIONS_QUERY_KEY],
        refetchType: 'all',
      });
    },
  });
};

export default useUpdateRecommendationsMutation;
