import { Box, useTheme } from '@mui/material';
import { FC, RefObject } from 'react';
import { Text } from '../../Text';
import { DesignSystemSize } from '../../../types';

// FIXME: in case of outline variant the 1px border mess up overlay by 0.5px in x/y
export const StrokeOverlayWidth = 0.5;

export type InputContent = { key: string } & (
  | { type: 'text'; label: string }
  | { type: 'mention'; label: string }
  | { type: 'cursor'; start: string; end: string }
);

export type ContentProps = {
  cursorRef: RefObject<HTMLSpanElement>;
  content: InputContent;
  size: DesignSystemSize;
  variant: 'button-regular' | 'button-bold' | 'caption' | 'label' | 'link' | 'heading' | 'body-regular' | 'body-bold';
};
export const Content: FC<ContentProps> = ({ content, size, variant, cursorRef }) => {
  const { palette } = useTheme();

  if (content.type === 'mention') {
    return (
      <Text
        variant={variant}
        size={size}
        color={palette.secondary.color1}
        sx={{
          WebkitTextStroke: `${StrokeOverlayWidth}px ${palette.secondary.color1}`,
        }}
      >
        {content.label}
      </Text>
    );
  } else if (content.type === 'text') {
    return (
      <Text variant={variant} size={size} visibility="hidden">
        {content.label}
      </Text>
    );
  } else if (content.type === 'cursor') {
    return (
      <>
        {content.start && (
          <Text variant={variant} size={size} visibility="hidden">
            {content.start}
          </Text>
        )}
        <Box key="cursor" ref={cursorRef} component="span" visibility="hidden"></Box>
        {content.end && (
          <Text variant={variant} size={size} visibility="hidden">
            {content.end}
          </Text>
        )}
      </>
    );
  }

  return null;
};
