import { storageApi as api } from '../../api/storageAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listFiles: build.query<ListFilesApiResponse, ListFilesApiArg>({
      query: (queryArg) => ({ url: `/list/documents/accounts/${queryArg.accountId}/${queryArg['path+']}` }),
    }),
    getPreSignedLink: build.query<GetPreSignedLinkApiResponse, GetPreSignedLinkApiArg>({
      query: (queryArg) => ({
        url: `/preSign/get/documents/accounts/${queryArg.accountId}/${queryArg['path+']}`,
        params: { responseContentDisposition: queryArg.responseContentDisposition },
      }),
    }),
    headPreSignedLink: build.query<HeadPreSignedLinkApiResponse, HeadPreSignedLinkApiArg>({
      query: (queryArg) => ({ url: `/preSign/head/documents/accounts/${queryArg.accountId}/${queryArg['path+']}` }),
    }),
    putPreSignedLink: build.query<PutPreSignedLinkApiResponse, PutPreSignedLinkApiArg>({
      query: (queryArg) => ({ url: `/preSign/put/documents/accounts/${queryArg.accountId}/${queryArg['path+']}` }),
    }),
    deletePreSignedLink: build.query<DeletePreSignedLinkApiResponse, DeletePreSignedLinkApiArg>({
      query: (queryArg) => ({ url: `/preSign/delete/documents/accounts/${queryArg.accountId}/${queryArg['path+']}` }),
    }),
    getFileContent: build.query<GetFileContentApiResponse, GetFileContentApiArg>({
      query: (queryArg) => ({ url: `/files/documents/accounts/${queryArg.accountId}/${queryArg['path+']}` }),
    }),
    getFileMetadata: build.mutation<GetFileMetadataApiResponse, GetFileMetadataApiArg>({
      query: (queryArg) => ({
        url: `/files/documents/accounts/${queryArg.accountId}/${queryArg['path+']}`,
        method: 'HEAD',
      }),
    }),
    deleteFile: build.mutation<DeleteFileApiResponse, DeleteFileApiArg>({
      query: (queryArg) => ({
        url: `/files/documents/accounts/${queryArg.accountId}/${queryArg['path+']}`,
        method: 'DELETE',
      }),
    }),
    uploadFile: build.mutation<UploadFileApiResponse, UploadFileApiArg>({
      query: (queryArg) => ({
        url: `/files/documents/accounts/${queryArg.accountId}/${queryArg['path+']}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as storageAPICodegen };
export type ListFilesApiResponse = /** status 200 ... */ FileList;
export type ListFilesApiArg = {
  accountId: string;
  'path+': string;
};
export type GetPreSignedLinkApiResponse = /** status 200 ... */ PreSignedLink;
export type GetPreSignedLinkApiArg = {
  accountId: string;
  'path+': string;
  responseContentDisposition?: string;
};
export type HeadPreSignedLinkApiResponse = /** status 200 ... */ PreSignedLink;
export type HeadPreSignedLinkApiArg = {
  accountId: string;
  'path+': string;
};
export type PutPreSignedLinkApiResponse = /** status 200 ... */ PreSignedLink;
export type PutPreSignedLinkApiArg = {
  accountId: string;
  'path+': string;
};
export type DeletePreSignedLinkApiResponse = /** status 200 ... */ PreSignedLink;
export type DeletePreSignedLinkApiArg = {
  accountId: string;
  'path+': string;
};
export type GetFileContentApiResponse = /** status 200 ... */ Blob;
export type GetFileContentApiArg = {
  accountId: string;
  'path+': string;
};
export type GetFileMetadataApiResponse = unknown;
export type GetFileMetadataApiArg = {
  accountId: string;
  'path+': string;
};
export type DeleteFileApiResponse = unknown;
export type DeleteFileApiArg = {
  accountId: string;
  'path+': string;
};
export type UploadFileApiResponse = /** status 200 Empty, read headers */ Empty;
export type UploadFileApiArg = {
  accountId: string;
  'path+': string;
  /** ...
   */
  body: Blob;
};
export type FileMetaData = {
  fileSize: number;
  fileName: string;
  fullPath: string;
  dirPath: string;
  type: 'DIRECTORY' | 'FILE';
  lastModified?: string;
};
export type FileList = {
  searchPrefix?: string;
  files?: FileMetaData[];
};
export type PreSignedLink = {
  preSignedLink?: string;
};
export type Empty = object;
export const {
  useListFilesQuery,
  useLazyListFilesQuery,
  useGetPreSignedLinkQuery,
  useLazyGetPreSignedLinkQuery,
  useHeadPreSignedLinkQuery,
  useLazyHeadPreSignedLinkQuery,
  usePutPreSignedLinkQuery,
  useLazyPutPreSignedLinkQuery,
  useDeletePreSignedLinkQuery,
  useLazyDeletePreSignedLinkQuery,
  useGetFileContentQuery,
  useLazyGetFileContentQuery,
  useGetFileMetadataMutation,
  useDeleteFileMutation,
  useUploadFileMutation,
} = injectedRtkApi;
