import React from 'react';
import { Trans } from 'react-i18next';

import { Text } from '@verticeone/design-system/src';
import { Card } from '@verticeone/design-system/src';

import { JournalItemContentComponent } from './types';
import { useUsersContext } from '@vertice/core/src/contexts/UsersContext';
import { parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';

type DeltaChange = {
  oldOwner: string;
  newOwner: string;
};

const getDelta = (delta: any[]): DeltaChange | null => {
  for (const deltaItem of delta) {
    const [operation, target, changes] = deltaItem;
    if (operation === 'change' && target === 'Owner') {
      const [from, to] = changes;
      return {
        oldOwner: from,
        newOwner: to,
      };
    }
  }

  return null;
};

export const RequestOwnerChangedJournalItem: JournalItemContentComponent = ({ item }) => {
  const { getFullName } = useUsersContext();

  const delta = getDelta(item.delta);
  if (!delta) {
    return null;
  }

  const urnToName = (urn: string) => getFullName(parseUserRef(urn).userId);
  return (
    <Card padding={4}>
      <Text variant="body-regular" color="text1">
        <Trans
          i18nKey={'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.JOURNAL.OWNER_CHANGE_MESSAGE'}
          components={{ bold: <b /> }}
          values={{
            oldOwner: urnToName(delta.oldOwner),
            newOwner: urnToName(delta.newOwner),
          }}
        />
      </Text>
    </Card>
  );
};
