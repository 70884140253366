import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { Link, Outlet, useParams } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { Tab as BaseTab } from '@verticeone/design-system/src';
import { toConstantCase } from '@verticeone/utils/strings';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import LoadableAWSPageWrapper from 'pages/Cloud/LoadableAWSPageWrapper';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';
import { productKeyToTitleMap } from '../utils';

type TABS = 'optimize' | 'inventory' | 'transactions';

type CloudRIOLayoutProps = {
  activeTab: TABS;
};

type TabProps = {
  value: TABS;
  onClick: () => void;
};

const getTabLabelKey = (tabId: string) => `CLOUD.RIO.${toConstantCase(tabId)}_TAB`;

const Tab = ({ value, onClick, ...otherProps }: TabProps) => {
  const { t } = useTranslation();

  return <BaseTab {...otherProps} key={value} value={value} label={t(getTabLabelKey(value))} onClick={onClick} />;
};

const CloudRIOLayoutInner = ({ activeTab }: CloudRIOLayoutProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { subFeatures } = useCloudContext();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { product } = useParams<{ product: keyof typeof productKeyToTitleMap }>();

  useEffect(() => {
    if (!subFeatures.rio) {
      navigate(routes.PAGE_NOT_FOUND);
    }
  }, [subFeatures, routes, navigate]);

  useEffect(() => {
    if (!activeTab) {
      navigate(routes.CLOUD.RIO.OPTIMIZE, {}, { replace: true });
    }
  }, [activeTab, routes, navigate]);

  const showProductBreadcrumb = ['optimize', 'inventory'].includes(activeTab) && product;

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
          <BreadcrumbLink>{t('CLOUD.RIO.BREADCRUMBS_TITLE')}</BreadcrumbLink>
          <BreadcrumbLink component={Link} to={`${ROUTES.CLOUD_RIO}/${activeTab}`} disabled={!showProductBreadcrumb}>
            {t(getTabLabelKey(activeTab))}
          </BreadcrumbLink>
          {showProductBreadcrumb && (
            <BreadcrumbLink disabled>{productKeyToTitleMap[product] ?? product}</BreadcrumbLink>
          )}
        </Breadcrumbs>
        <Text variant="heading" size="M" color={palette.text.color1} tag="h1">
          {t('CLOUD.RIO.TITLE')}
        </Text>
      </Stack>
      <TabsBottomLineWrapper paddingX={10}>
        <Tabs variant="outlined" value={activeTab} color={AWS_BRAND_COLOR}>
          <Tab value="optimize" onClick={() => navigate(routes.CLOUD.RIO.OPTIMIZE)} />
          <Tab value="inventory" onClick={() => navigate(routes.CLOUD.RIO.INVENTORY)} />
          <Tab value="transactions" onClick={() => navigate(routes.CLOUD.RIO.TRANSACTIONS)} />
        </Tabs>
      </TabsBottomLineWrapper>
      <Box position="relative" px={10}>
        <Outlet />
      </Box>
    </Stack>
  );
};

const CloudRIOLayout = ({ activeTab }: CloudRIOLayoutProps) => (
  <LoadableAWSPageWrapper getCanBeViewed={({ rio }) => rio}>
    <CloudRIOLayoutInner activeTab={activeTab} />
  </LoadableAWSPageWrapper>
);

export default CloudRIOLayout;
