import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DoubleCurrencyMoney } from '../types';

const doubleCurrencyMoneyCellValueGetter = (
  contractCurrencyValue: number | undefined,
  contract: Contract,
  accountCurrency: string,
  exchangeCurrency: (value: number, sourceCurrency: string) => number
): DoubleCurrencyMoney | undefined => {
  const contractCurrency = contract?.parts.contractual?.financial?.baseCurrency ?? accountCurrency;
  return contractCurrencyValue !== undefined
    ? {
        contractCurrencyValue,
        contractCurrency,
        accountCurrencyValue: exchangeCurrency(contractCurrencyValue, contractCurrency),
        accountCurrency,
      }
    : undefined;
};

export default doubleCurrencyMoneyCellValueGetter;
