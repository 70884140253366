import { createRequiredContext } from '@verticeone/design-system/src';
import { ContractContextData, ContractContextDataTypeFromMode, FormSupportedMode } from './types';
import { FormProvider } from 'react-hook-form';
import { ReactNode } from 'react';

const { ContractContextProvider: ContractContextProviderRaw, useContractContext: useContractContextRaw } =
  createRequiredContext<ContractContextData, 'Contract'>('Contract');

/**
 * Main context provider for a contract detail.
 *
 * - Use `useContract` to prepare context data that can be passed through this.
 * - Use {@link useContractContext} to consume context data.
 *
 * TECH NOTE: React-hook-form instance from ContractContextData gets passed through the standard `FormProvider` as well,
 * so that universal form components like `FormTextField` can use it.
 */
export const ContractContextProvider = ({ value, children }: { value: ContractContextData; children: ReactNode }) => (
  <ContractContextProviderRaw value={value}>
    <FormProvider {...value.hookForm}>{children}</FormProvider>
  </ContractContextProviderRaw>
);

/**
 * Main hook for consuming contract context.
 *
 * @param mode - `'REQUIRE_FETCHED'` = require fetchedContract to be defined (typically read-only widgets or "update existing contract" forms),
 * `'DONT_REQUIRE_FETCHED'` = fetchedContract can be undefined (typically "create new contract" forms)
 *
 * DEV NOTE: It can contain computed fields/methods, if necessary. If possible, prefer putting these into separate
 * specialized small hooks.
 */
export const useContractContext = <Mode extends FormSupportedMode>(
  mode: Mode
): ContractContextDataTypeFromMode<Mode> => {
  const contractContext = useContractContextRaw();

  if (mode === 'REQUIRE_FETCHED' && contractContext.formSupportedMode !== 'REQUIRE_FETCHED') {
    throw new Error(
      "You are trying to use useContractContext('REQUIRE_FETCHED') inside of a context that was not created using " +
        "useContract('REQUIRE_FETCHED', ...). \n\n" +
        'This can signalize that you are trying to use a component that is designed to work only with an " ' +
        'ALREADY EXISTING CONTRACT inside of context, where NO EXISTING CONTRACT is also possible (e.g. in a ' +
        'create contract form). \n\n' +
        'To support running without a fetchedContract, consume the context using ' +
        "useContractContext('DONT_REQUIRE_FETCHED'). TypeScript will then help you to cover all the cases when " +
        'fetchedContract is undefined.'
    );
  }

  return {
    ...contractContext,

    // Add computed fields/methods here, if necessary.
    // If you need to, you can even do contractContext.hookForm.watch('field') and stuff like that.
  } as ContractContextDataTypeFromMode<Mode>;
};
