import React, { FC } from 'react';
import DepartmentListBase, { DepartmentListProps as DepartmentListBaseProps } from './DepartmentList';
import WithActions from './WithActions';
import DepartmentListContextProvider from './DepartmentListContextProvider';

interface DepartmentListProps extends DepartmentListBaseProps {
  withActions?: boolean;
  excludeDeleteAction?: boolean;
  allowSavingFor?: Array<string>;
}

export const DepartmentList: FC<DepartmentListProps> = ({
  withActions = true,
  excludeDeleteAction,
  allowSavingFor,
  ...rest
}) => {
  const listBase = <DepartmentListBase readOnlyMode={!withActions} {...rest} />;
  return (
    <DepartmentListContextProvider>
      {withActions ? <WithActions {...{ excludeDeleteAction, allowSavingFor }}>{listBase}</WithActions> : listBase}
    </DepartmentListContextProvider>
  );
};
