import React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { ToggleSwitch } from '@verticeone/design-system/src';
import { Box } from '@mui/material';

type ToggleSwitchCellProps = {
  disabled?: boolean;
} & GridRenderCellParams;

const ToggleSwitchCell = ({ disabled, id, field, value }: ToggleSwitchCellProps) => {
  const apiRef = useGridApiContext();

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <ToggleSwitch
        checked={value}
        onChange={(newValue) => {
          void apiRef.current.setEditCellValue({ id, field, value: newValue.target.checked });
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export default ToggleSwitchCell;
