import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import doubleCurrencyMoneyCellValueGetter from './doubleCurrencyMoneyCellValueGetter';
import { DoubleCurrencySavingsMoney } from '../types';
import {
  getAnnualSavingsVsInitial,
  getAnnualSavingsVsInitialPercentage,
} from '@vertice/core/src/modules/saas/savings/computed';

const savingsVsInitialProposalCellValueGetter = (
  contract: Contract,
  accountCurrency: string,
  exchangeCurrency: (value: number, sourceCurrency: string) => number
): DoubleCurrencySavingsMoney | undefined => {
  const savingsMoney = doubleCurrencyMoneyCellValueGetter(
    getAnnualSavingsVsInitial(contract),
    contract,
    accountCurrency,
    exchangeCurrency
  );

  if (!savingsMoney) {
    return undefined;
  }

  return {
    ...savingsMoney,
    savingsPercentage: getAnnualSavingsVsInitialPercentage(contract),
  };
};

export default savingsVsInitialProposalCellValueGetter;
