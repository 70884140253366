import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import styles from './Loader.module.scss';

export interface LoaderProps {
  size?: number;
  inline?: boolean;
}

/** @deprecated use Design System's Loader */
const Loader: FC<LoaderProps> = ({ size }) => (
  <div className={styles['root-loader']} data-testid="loader">
    <CircularProgress color="primary" size={size || 40} />
  </div>
);

export default Loader;
