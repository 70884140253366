import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './en.json';

const instance = i18n.createInstance();
void instance.use(initReactI18next).init({
  defaultNS: 'designSystem',
  resources: {
    en: {
      designSystem: enTranslation,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default instance;
