import React, { createContext, useContext, useMemo, useState } from 'react';
import { type GridApiPro, GridRowModes, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { type ContractYear, useContractCreate } from '../useContractCreate';
import { useContractUpdate } from '../useContractUpdate';
import { useContractDelete } from '../useContractDelete';
import { useSimpleDialogContext } from '@verticeone/design-system/src';
import { randomId } from '@mui/x-data-grid-generator';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { last } from 'lodash';
import { MAX_DISCOUNT, MIN_COMMITMENT, MIN_DISCOUNT } from './constants';
import dayjs from 'dayjs';

type ContractContextProps = {
  children: React.ReactNode;
};

type ContractContextType = {
  apiRef: React.MutableRefObject<GridApiPro>;
  isEditing: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  actions: {
    startEdit: () => void;
    stopEdit: () => void;
    create: () => void;
    update: (contractId: string) => void;
    delete: (contractId: string) => void;
    discard: () => void;
    addRow: () => void;
    removeRow: (rowId: string) => void;
  };
};

const ContractGridContext = createContext<ContractContextType>({} as ContractContextType);

const ContractGridContextProvider = ({ children }: ContractContextProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { getConfirmation } = useSimpleDialogContext();
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [initRows, setInitRows] = useState<Array<GridValidRowModel> | null>(null);
  const ContractSchema = z.object({
    startDate: z
      .string({
        invalid_type_error: t('ZOD_ERRORS.START_DATE_REQUIRED'),
      })
      .date(),
    endDate: z
      .string({
        invalid_type_error: t('ZOD_ERRORS.END_DATE_REQUIRED'),
      })
      .date(),
    commitment: z.number({ invalid_type_error: t('ZOD_ERRORS.COMMITMENT_REQUIRED') }).min(MIN_COMMITMENT),
    discount: z
      .number({ invalid_type_error: t('ZOD_ERRORS.DISCOUNT_REQUIRED', { min: MIN_DISCOUNT, max: MAX_DISCOUNT }) })
      .min(MIN_DISCOUNT / 100)
      .max(MAX_DISCOUNT / 100),
    id: z.string(),
    name: z.string(),
  });

  const isContractValid = useMemo(
    () => (contractYear: ContractYear, name: string) => {
      try {
        ContractSchema.parse(contractYear);
        return true;
      } catch (error) {
        const messages = [];
        if (error instanceof z.ZodError) {
          for (const issue of error.issues) {
            messages.push(issue.message);
          }
        } else {
          messages.push(error);
        }

        enqueueSnackbar({
          variant: 'error',
          message: t('ZOD_ERRORS.SNACKBAR_TITLE', { name }),
          description: messages.join('; '),
        });
      }

      return false;
    },
    [ContractSchema, enqueueSnackbar, t]
  );

  const validateContractYears = useMemo(
    () =>
      (years: Array<ContractYear>): boolean => {
        if (years.length === 0) {
          enqueueSnackbar({ variant: 'error', message: t('ERROR.EMPTY_CONTRACT') });
          return false;
        }

        const validateYears = years.map((year, index) => isContractValid(year, t('TERM', { term: index + 1 })));

        return !validateYears.includes(false);
      },
    [enqueueSnackbar, isContractValid, t]
  );

  const { mutate: deleteContract } = useContractDelete({
    onSettled: () => setIsDeleting(false),
  });
  const { mutate: createContract } = useContractCreate({
    onSettled: () => setIsSaving(false),
    onSuccess: () => actions.stopEdit(),
  });
  const { mutate: updateContract } = useContractUpdate({
    onSettled: () => setIsSaving(false),
    onSuccess: () => actions.stopEdit(),
  });

  const actions = useMemo(
    () => ({
      startEdit: () => {
        setIsEditing(true);
        setIsExpanded(true);
        setInitRows(Array.from(apiRef.current.getRowModels().values()));
        apiRef.current.getAllRowIds().forEach((id) => {
          if (apiRef.current.getRowMode(id) === GridRowModes.View) {
            apiRef.current.startRowEditMode({ id });
          }
        });
      },
      stopEdit: () => {
        setIsEditing(false);
        apiRef.current.getAllRowIds().forEach((id) => {
          if (apiRef.current.getRowMode(id) === GridRowModes.Edit) {
            apiRef.current.stopRowEditMode({ id });
          }
        });
      },
      create: () => {
        const years = Array.from(apiRef.current.getRowModels().values()) as Array<ContractYear>;
        if (!validateContractYears(years)) {
          return;
        }

        setIsSaving(true);
        createContract(years);
      },
      update: (contractId: string) => {
        const years = Array.from(apiRef.current.getRowModels().values()) as Array<ContractYear>;
        if (!validateContractYears(years)) {
          return;
        }

        setIsSaving(true);
        updateContract({ contractId, years });
      },
      delete: (contractId: string) => {
        void getConfirmation({
          title: t('DELETE_CONTRACT.TITLE'),
          description: t('DELETE_CONTRACT.DESCRIPTION'),
          okButton: {
            label: t('BUTTONS.DELETE'),
            color: 'tertiary',
          },
        }).then(({ status }) => {
          if (status === 'ok') {
            setIsDeleting(true);
            deleteContract(contractId);
          }
        });
      },
      addRow: () => {
        const id = randomId();
        const models = Array.from(apiRef.current.getRowModels().values());

        const previousEndDateFromModels = last(models)?.endDate || null;
        const lastEndDate = previousEndDateFromModels
          ? dayjs(previousEndDateFromModels).add(1, 'days').startOf('month')
          : null;
        const formattedStartDate = lastEndDate?.format('YYYY-MM-DD') ?? null;
        const formattedEndDate = lastEndDate?.add(11, 'months').endOf('month').format('YYYY-MM-DD') ?? null;

        apiRef.current.setRows([
          ...models,
          {
            id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            name: '',
            commitment: null,
            discount: null,
          },
        ]);
        apiRef.current.startRowEditMode({ id });
      },
      removeRow: (rowId: string) => {
        apiRef.current.updateRows([{ id: rowId, _action: 'delete' }]);
      },
      discard: () => {
        setIsEditing(false);

        if (initRows) {
          apiRef.current.setRows(initRows);
        }

        apiRef.current.getAllRowIds().forEach((id) => {
          if (apiRef.current.getRowMode(id) === GridRowModes.Edit) {
            apiRef.current.stopRowEditMode({
              id,
              ignoreModifications: true,
            });
          }
        });
      },
    }),
    [apiRef, createContract, deleteContract, getConfirmation, initRows, t, updateContract, validateContractYears]
  );

  const value: ContractContextType = useMemo(
    () => ({
      apiRef,
      isEditing,
      isSaving,
      isExpanded,
      isDeleting,
      setIsExpanded,
      actions,
    }),
    [apiRef, isEditing, isSaving, isExpanded, isDeleting, actions]
  );

  return <ContractGridContext.Provider value={value}>{children}</ContractGridContext.Provider>;
};

export const useContractGridContext = () => useContext(ContractGridContext);

export default ContractGridContextProvider;
