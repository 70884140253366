import React from 'react';
import RoutesContextProvider from '@verticeone/router/src/routes/RoutesContext';
import useCfaRoutes from './routes';

type CfaRouteContextProviderProps = {
  children: React.ReactNode;
};

const CfaRouteContextProvider = ({ children }: CfaRouteContextProviderProps) => {
  const routes = useCfaRoutes();

  return <RoutesContextProvider routes={routes}>{children}</RoutesContextProvider>;
};

export default CfaRouteContextProvider;
