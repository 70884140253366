import { useMemo } from 'react';
import { useAuthentication } from '@verticeone/auth/src';

const makeApiFileFetching = ({ getJwtToken }: { getJwtToken: () => Promise<string> }) => ({
  async fetchFile(url: string, requestInit: RequestInit = {}) {
    const jwtToken = await getJwtToken();
    const response = await fetch(url, {
      ...requestInit,
      headers: {
        ...requestInit.headers,
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return response.blob();
  },

  async fetchFileSize(url: string) {
    const jwtToken = await getJwtToken();
    const response = await fetch(url, {
      method: 'HEAD',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return response.headers.get('content-length');
  },
});

export const useApiFileFetching = () => {
  const { getJwtToken } = useAuthentication();
  return useMemo(() => makeApiFileFetching({ getJwtToken }), [getJwtToken]);
};
