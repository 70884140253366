import { FormControl, Grid, Stack } from '@mui/material';
import { FormControlLabel } from '@verticeone/design-system/src';
import { ToggleSwitch } from '@verticeone/design-system/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { EmailField } from '@vertice/core/src/components/EmailField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormData } from '../../RenewalRequirementsForm/schema';
import { createTypedTaskFormEntry } from '../formFields/TaskFormEntry';
import { useTaskFormContext } from '../TaskFormContext';

export const RenewalFormEntry = createTypedTaskFormEntry<FormData>();

export const VendorContact = () => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();
  const { setValue } = useFormContext<FormData>();
  const mainVendorContact = useWatch<FormData, 'mainVendorContact'>({ name: 'mainVendorContact' });

  return (
    <Stack>
      <FormControl variant="outlined">
        <Stack paddingLeft={2}>
          <FormControlLabel
            control={
              <ToggleSwitch
                size="M"
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                name="mainVendorContact"
                checked={mainVendorContact}
                disabled={readOnly}
                onChange={(e) => setValue('mainVendorContact', e.target.checked, { shouldValidate: true })}
              />
            }
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.MAIN_VENDOR_CONTACT_AVAILABLE')}
            labelPlacement="end"
          />
        </Stack>
      </FormControl>
      {mainVendorContact && (
        <Grid container spacing={4} py={4}>
          <RenewalFormEntry
            name="mainVendorContactName"
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.VENDOR_CONTACT_NAME')}
            component={FormTextField}
            componentProps={{ color: INTELLIGENT_WORKFLOWS_BRAND_COLOR }}
            required={mainVendorContact}
          />
          <RenewalFormEntry
            name="mainVendorContactEmail"
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.VENDOR_CONTACT_EMAIL')}
            component={EmailField}
            componentProps={{ color: INTELLIGENT_WORKFLOWS_BRAND_COLOR }}
            required={mainVendorContact}
          />
        </Grid>
      )}
    </Stack>
  );
};
