import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system/src';

type SecurityApprovalFormEntryProps = {
  securityApproved: boolean;
};

export const SecurityApprovalCompletedFormEntry = ({ securityApproved }: SecurityApprovalFormEntryProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2}>
      <Button
        fullWidth
        disabled
        color="success"
        sx={[
          securityApproved && {
            '&[disabled]': {
              backgroundColor: (theme) => theme.palette.success.color3,
              borderColor: (theme) => theme.palette.success.color3,
              color: (theme) => theme.palette.success.color2,
            },
          },
        ]}
      >
        {t('INTAKE_FORM.SECURITY_APPROVAL.APPROVED')}
      </Button>
      <Button
        fullWidth
        disabled
        color="error"
        sx={[
          !securityApproved && {
            '&[disabled]': {
              backgroundColor: (theme) => theme.palette.error.color3,
              borderColor: (theme) => theme.palette.error.color3,
              color: (theme) => theme.palette.error.color2,
            },
          },
        ]}
      >
        {t('INTAKE_FORM.SECURITY_APPROVAL.REJECTED')}
      </Button>
    </Stack>
  );
};
