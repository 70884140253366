import { ElementType, forwardRef, useContext } from 'react';
import Stack from '@mui/material/Stack';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import type {
  DesignSystemColor,
  DesignSystemSize,
  CustomizedProps,
  Direction,
  DesignSystemVariant,
  TestProps,
} from '../../types';
import { ButtonGroupContext } from '../ButtonGroup/ButtonGroupContext';
import { Loader } from '../Loader';
import { BaseButton, useBaseButtonStyles, UseStylesProps } from './BaseButton';
import { BaseButtonContent } from './BaseButtonContent';
import { getTextVariantStyle, TextVariants } from '../Text';
import { Text } from '../Text';
import { testProps } from '../../utils/testProperties';
export { ButtonLabel } from './BaseButtonContent';
export type DefaultComponentType = 'button';

export type ButtonProps<C extends React.ElementType = DefaultComponentType> = TestProps &
  CustomizedProps &
  Omit<MuiButtonProps<C>, 'size' | 'variant' | 'color' | 'disabled'> & {
    size?: DesignSystemSize;
    color?: DesignSystemColor;
    variant?: DesignSystemVariant;
    isCaption?: boolean;
    direction?: Direction;
    disabled?: boolean;
    isLoading?: boolean;
    isActive?: boolean;
    isCircle?: boolean;
    component?: C;
  };

export type ButtonComponentType = <C extends React.ElementType = DefaultComponentType>(
  props: ButtonProps<C>
) => JSX.Element;

export const Button = forwardRef<HTMLButtonElement, ButtonProps<any>>(
  <C extends ElementType = DefaultComponentType>(
    {
      size,
      color,
      variant,
      direction = 'horizontal',
      disabled,
      isCaption = false,
      isLoading = false,
      isActive = true,
      isCircle = false,
      children,
      testId,
      customized: _customized, // to not pass the flag down to the button
      ...otherProps
    }: ButtonProps<C>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const buttonGroupContext = useContext(ButtonGroupContext);
    const defaultProps: UseStylesProps = {
      size: size ?? buttonGroupContext?.size ?? 'M',
      color: color ?? buttonGroupContext?.color ?? 'neutral',
      variant: variant ?? buttonGroupContext?.variant ?? 'outline',
      isCaption,
      isActive,
      isLoading,
      borderRadius: isCircle ? '50%' : undefined,
    };

    const textVariant: TextVariants = isCaption ? 'label' : 'button-bold';
    const { fontSize } = getTextVariantStyle({ variant: textVariant, size: defaultProps.size });
    const styles = useBaseButtonStyles(defaultProps);

    return (
      <BaseButton
        $styles={styles}
        disabled={buttonGroupContext?.disabled || disabled || false}
        ref={ref}
        {...testProps(testId, 'button')}
        {...otherProps}
      >
        <Stack visibility={isLoading ? 'hidden' : 'visible'}>
          <Text variant={textVariant} size={defaultProps.size} visibility={isLoading ? 'hidden' : 'visible'}>
            <BaseButtonContent direction={direction}>{children}</BaseButtonContent>
          </Text>
        </Stack>

        {isLoading && (
          <Stack position="absolute" left="0" right="0" top="0" bottom="0">
            <Loader className="loader-spinner" thickness={7} size={fontSize} />
          </Stack>
        )}
      </BaseButton>
    );
  }
) as ButtonComponentType;
