import { Auth } from 'aws-amplify';

const getAmplifyJwtToken = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getIdToken();
    return accessToken.getJwtToken();
  } catch (e) {
    return '';
  }
};

export default getAmplifyJwtToken;
