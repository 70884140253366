import { useEDPConfigurationData } from './Configurations/useEDPConfigurationData';
import { useTranslation } from 'react-i18next';
import { useFormatDateRange } from '@verticeone/utils/formatting';
import { CardHeaderDescription } from '@verticeone/design-system/src';
import { Placeholder } from '@verticeone/design-system/src';
import React from 'react';

const CurrentTermSubtitle = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SPEND_TREND' });
  const { data, isLoading } = useEDPConfigurationData();
  const formatDateRange = useFormatDateRange();

  if (isLoading) {
    return (
      <CardHeaderDescription>
        <Placeholder width="80px" height={28} component="span" />
      </CardHeaderDescription>
    );
  }

  const dateRange = data?.currentTerm && formatDateRange(data.currentTerm.startDate, data.currentTerm.endDate);

  return <CardHeaderDescription>{`${t('SUBTITLE')} ${dateRange ? `(${dateRange})` : ''}`}</CardHeaderDescription>;
};

export default CurrentTermSubtitle;
