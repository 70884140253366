import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system/src';
import { CustomFieldIdentifier, FieldItem } from '../../types';

const ReadOnlyFieldTypeCell = ({ value }: GridRenderCellParams<FieldItem, CustomFieldIdentifier | undefined>) => {
  return <Text variant="body-regular">{value?.label ?? ''}</Text>;
};

export default ReadOnlyFieldTypeCell;
