import { z } from 'zod';
import { offerSchema } from '../shared/schemas';

export const DEFAULT_OFFER_LIMIT = 5;

export const zodSchema = z
  .object({
    maxOffers: z.number().min(0).max(DEFAULT_OFFER_LIMIT).optional(),
    baselineOffer: offerSchema,
    negotiationOffers: z.object({
      instances: z.array(offerSchema),
    }),
  })
  .refine(
    ({ maxOffers = DEFAULT_OFFER_LIMIT, negotiationOffers }) => {
      if (negotiationOffers.instances.length === 0 || negotiationOffers.instances.length > maxOffers) {
        return false;
      }
      return true;
    },
    { path: ['negotiationOffers.instances'] }
  );

export type FormData = z.infer<typeof zodSchema>;
