import { StylesConfig, CSSObjectWithLabel } from 'react-select';
import { rgba } from 'polished';
import { MakeStylesParams } from './common';
import { gradientBorder } from '../../../utils/css/gradientBorder';
import { getTextVariantStyle } from '../../Text/utils';
import { GroupBase } from '../types';

export const makeSolidStylesConfig = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  theme: { palette },
  error,
  color,
  size,
}: MakeStylesParams): StylesConfig<Option, IsMulti, Group> => ({
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: 30,
    transition: [
      'background-color 200ms cubic-bezier(0.0,0,0.2,1)',
      'box-shadow 400ms ease-out',
      'border 200ms cubic-bezier(0.0,0,0.2,1)',
    ].join(', '),
    backgroundColor: state.isDisabled ? palette.inactive.color4 : palette.input.bg,
    borderColor: 'transparent',
    ...(gradientBorder({
      radius: '8px',
      width: '1px',
      color: (() => {
        if (error) {
          return palette.error.color2;
        }
        if (state.isFocused) {
          return palette[color].color2;
        }
        return `linear-gradient(0, ${rgba(palette.text.color1, 0.1)}, transparent)`;
      })(),
      transition: '200ms cubic-bezier(0.0,0,0.2,1)',
    }) as CSSObjectWithLabel),
    borderWidth: 1,
    borderRadius: 8,
    boxShadow: state.isFocused
      ? `0 0 0 4px ${palette[error ? 'error' : color].color4}`
      : `0 0 0 8px ${rgba(palette[color].color4, 0)}`,
    ...getTextVariantStyle({ variant: 'body-regular', size }),

    '&:hover': {
      backgroundColor: !state.isFocused ? palette.input.color1 : undefined,
    },
  }),
});
