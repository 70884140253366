import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import type { DesignSystemSize } from '../../types';
import { getFileTypes } from './utils';
import { Text } from '../Text';
import i18n from '../../translate';
import { ifExpression } from '../../utils/ifExpression';
import { ReactComponent as UploadIcon } from './assets/uploadIcon.svg';
import { useFileupload } from './Fileupload';

type DropzoneProps = {
  size?: DesignSystemSize;
};

type UploadAreaProps = {
  $disabled: boolean;
  $isDragActive: boolean;
  theme: Theme;
};

type SizeDimensions = {
  icon: {
    width: number;
    height: number;
  };
  subtitle: DesignSystemSize;
};

const sizeMap: Record<DesignSystemSize, SizeDimensions> = {
  XXS: {
    icon: {
      width: 36,
      height: 39,
    },
    subtitle: 'XXS',
  },
  XS: {
    icon: {
      width: 40,
      height: 43,
    },
    subtitle: 'XXS',
  },
  S: {
    icon: {
      width: 44,
      height: 47,
    },
    subtitle: 'XS',
  },
  M: {
    icon: {
      width: 48,
      height: 52,
    },
    subtitle: 'XS',
  },
  L: {
    icon: {
      width: 52,
      height: 56,
    },
    subtitle: 'S',
  },
  XL: {
    icon: {
      width: 60,
      height: 65,
    },
    subtitle: 'S',
  },
};

const UploadArea = styled('button')<UploadAreaProps>(({ theme, $disabled, $isDragActive }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(8),
  gap: theme.spacing(5),
  alignSelf: 'stretch',
  border: 'none',
  borderRadius: theme.spacing(2),
  backgroundColor: (() => {
    if ($disabled) {
      return theme.palette.inactive.color4;
    }
    if ($isDragActive) {
      return theme.palette.input.color1;
    }
    return theme.palette.input.bg;
  })(),
  boxShadow: (() => {
    if ($disabled) {
      return `inset 0px 0px 0px 1px ${theme.palette.inactive.color3}`;
    }
    if ($isDragActive) {
      return `inset 0px 0px 0px 4px ${theme.palette.neutral.hover.color3}`;
    }

    return `inset 0px 0px 0px 1px ${theme.palette.neutral.color3}`;
  })(),
  cursor: $disabled ? 'not-allowed' : 'pointer',
  ...ifExpression(!$disabled, {
    '&:hover': {
      backgroundColor: theme.palette.input.color1,
      boxShadow: `inset 0px 0px 0px 2px ${theme.palette.neutral.hover.color3}`,
    },
    '&:focus': {
      boxShadow: `inset 0px 0px 0px 4px ${theme.palette.neutral.hover.color3}`,
      backgroundColor: theme.palette.input.color1,
    },
    ':focus-visible': {
      outline: 'none',
    },
  }),
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2.5),
}));

export const Dropzone = ({ size: customSize }: DropzoneProps) => {
  const { t } = useTranslation(undefined, { i18n });
  const { rootProps, inputProps, isDragActive, isDisabled, acceptedTypes, size: parentSize } = useFileupload();
  const size = customSize || parentSize || 'XL';

  // UploadArea must be of type button in order to prevent form from submit
  return (
    <>
      <UploadArea {...rootProps} $disabled={isDisabled} $isDragActive={isDragActive} type="button">
        <input {...inputProps} />
        <UploadIcon width={sizeMap[size].icon.width} height={sizeMap[size].icon.height} />
        <TextWrapper>
          <Text variant="body-regular" color={isDisabled ? 'inactive3' : 'text2'} size={size}>
            <Trans
              i18n={i18n}
              i18nKey={`DRAG_AND_DROP.DROPZONE.TITLE${isDragActive ? '_ON_DRAG' : ''}`}
              components={{
                highlight: <Text variant="body-regular" color={isDisabled ? 'inactive3' : 'primary1'} size={size} />,
              }}
            />
          </Text>
          <Text variant="label" color={isDisabled ? 'inactive3' : 'text3'} size={sizeMap[size].subtitle}>
            {t('DRAG_AND_DROP.DROPZONE.FILE_FORMAT', {
              fileFormat: getFileTypes(acceptedTypes).join(', '),
            })}
          </Text>
        </TextWrapper>
      </UploadArea>
    </>
  );
};
