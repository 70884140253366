import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';

import styles from './IntegrationInput.module.scss';

interface IntegrationInputProp {
  setValue: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder?: string;
  label: string;
  dataTestId?: string;
}

const IntegrationInput: FC<IntegrationInputProp> = ({
  setValue,
  value,
  label,
  dataTestId,
  placeholder = 'X'.repeat(34),
}) => {
  const { t } = useTranslation();

  return (
    <Box className={styles['input-container']}>
      <div className={styles.required}>
        <Typography className={styles['required-label']} variant="caption-xs" color="var(--tokens-color-light-text-2)">
          {label}
        </Typography>
        <Typography variant="caption-xs" color="var(--tokens-color-light-error-2)">
          {!value && t('PREFERENCES.INTEGRATIONS.AWS.REQUIRED')}
        </Typography>
      </div>

      <TextField
        className={styles.input}
        value={value}
        placeholder={placeholder}
        required
        onChange={setValue}
        inputProps={{ 'data-test-id': dataTestId }}
      />
    </Box>
  );
};
export default IntegrationInput;
