import React from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PendingBackground } from '@vertice/assets';
import { VendorLogoBig } from './VendorLogoBig';
import { Header } from '@vertice/core/src/components/Dialog/Components';

type VendorLogoConfirmationPanelProps = {
  headingTitleKey: string;
  vendorName?: string;
};

const StyledPendingBackground = styled(PendingBackground)({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const VendorLogoConfirmationPanel: React.FC<VendorLogoConfirmationPanelProps> = ({
  vendorName,
  headingTitleKey,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" alignItems="center" gap={10} flexGrow={1} justifyContent="center">
      <Stack direction="row" alignItems="center" justifyContent="center" height={256} width={256} position="relative">
        <StyledPendingBackground />
        <VendorLogoBig />
      </Stack>
      <Header title={t(headingTitleKey, { vendorName })} centered />
    </Stack>
  );
};
