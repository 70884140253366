import React from 'react';
import { styled } from '@mui/material';
import { AWS_BRAND_COLOR } from '../../constants';

type BorderConfig = {
  top?: boolean;
  right?: boolean;
  left?: boolean;
};

type ConnectorProps = {
  strokeWidth?: number;
  borders?: BorderConfig;
  rounded?: boolean;
  verticalHighlight?: boolean;
  horizontalHighlight?: boolean;
};

const StyledConnector = styled('div', {
  shouldForwardProp: (propName) =>
    !['borders', 'strokeWidth', 'rounded', 'verticalHighlight', 'horizontalHighlight'].includes(propName as string),
})<ConnectorProps>(({ theme, strokeWidth = 2, borders, rounded, verticalHighlight, horizontalHighlight }) => ({
  flexBasis: '50%',
  borderTop: borders?.top ? strokeWidth + 'px solid' : 'none',
  borderRight: borders?.right ? strokeWidth + 'px solid' : 'none',
  borderLeft: borders?.left ? strokeWidth + 'px solid' : 'none',
  borderColor: `
      ${horizontalHighlight ? theme.palette[AWS_BRAND_COLOR].color2 : theme.palette.core.color4}
      ${verticalHighlight ? theme.palette[AWS_BRAND_COLOR].color2 : theme.palette.core.color4}
    `,
  borderTopRightRadius: rounded && borders?.top && borders?.right ? '4px' : '0',
  borderTopLeftRadius: rounded && borders?.top && borders?.left ? '4px' : '0',
}));

const Connector = (props: ConnectorProps) => <StyledConnector {...props} />;

export default Connector;
