import { createApi } from '@reduxjs/toolkit/query/react';
import { GetFileMetadataApiArg, GetFileMetadataApiResponse } from '../openapi/codegen/storageAPI';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const storageApi = createApi({
  reducerPath: 'storageApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.STORAGE),
  endpoints: (builder) => ({
    getFileMetadata: builder.mutation<GetFileMetadataApiResponse, GetFileMetadataApiArg>({
      query: (queryArg) => ({
        url: `/files/documents/accounts/${queryArg.accountId}/${queryArg['path+']}`,
        method: 'HEAD',
      }),
      transformResponse(apiResponse, meta: { request: Request; response: Response }) {
        return { contentType: meta.response.headers.get('content-type') };
      },
    }),
  }),
});
