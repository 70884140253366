export const categoryTranslationMap = {
  BundledDiscount: 'BUNDLED_DISCOUNT',
  Credit: 'CREDIT',
  Discount: 'DISCOUNT',
  DiscountedUsage: 'DISCOUNTED_USAGE',
  Fee: 'FEE',
  Refund: 'REFUND',
  RIFee: 'RIFEE',
  Tax: 'TAX',
  Usage: 'USAGE',
  SavingsPlanUpfrontFee: 'SAVINGS_PLAN_UPFRONT_FEE',
  SavingsPlanRecurringFee: 'SAVINGS_PLAN_RECURRING_FEE',
  SavingsPlanCoveredUsage: 'SAVINGS_PLAN_COVERED_USAGE',
  SavingsPlanNegation: 'SAVINGS_PLAN_NEGATION',
};

export type CategoryType = keyof typeof categoryTranslationMap;
