import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { ContractProperties } from './useCurrentContractProperties';

type ContractDateBoundary = {
  date?: string;
  name?: string;
};

type ContractBoundaries = {
  from: ContractDateBoundary;
  to: ContractDateBoundary;
};

const dateFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

export const getContractInvoicesDateBoundaries = (
  contract: ContractProperties | undefined,
  t: TFunction,
  locale: string
): ContractBoundaries => {
  const emptyResult = {
    from: {},
    to: {},
  };

  if (!contract) {
    return emptyResult;
  }

  try {
    const signDate = contract?.signDate ? dayjs(contract?.signDate) : undefined;
    const startDate = contract?.startDate ? dayjs(contract?.startDate) : undefined;
    const renewalDate = contract?.renewalDate ? dayjs(contract?.renewalDate) : undefined;

    const from: ContractDateBoundary =
      signDate && signDate.isValid() && signDate.isBefore(startDate)
        ? {
            date: signDate.toDate().toLocaleDateString(locale, dateFormatOptions),
            name: t('ENTITIES.CONTRACT.LABELS.SIGN_DATE'),
          }
        : startDate && startDate.isValid()
        ? {
            date: startDate.toDate().toLocaleDateString(locale, dateFormatOptions),
            name: t('ENTITIES.CONTRACT.LABELS.START_DATE'),
          }
        : {};

    const to: ContractDateBoundary =
      renewalDate && renewalDate.isValid()
        ? {
            date: renewalDate.toDate().toLocaleDateString(locale, dateFormatOptions),
            name: t('ENTITIES.CONTRACT.LABELS.RENEWAL_DATE'),
          }
        : {};

    return {
      from,
      to,
    };
  } catch (_error) {
    return emptyResult;
  }
};
