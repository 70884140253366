import React, { FC } from 'react';

import { PropsWithContract } from '../types';
import { useFormatDate } from '@verticeone/utils/formatting';
import { Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import useTheme from '@mui/material/styles/useTheme';
import { Checkbox } from '@verticeone/design-system/src';

export const AutoRenewalDate: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const renewalDate = contract.parts.contractual?.lifecycle?.autoRenewalDeadline;
  const isWaived = contract.parts.contractual?.renewal?.autoRenewalWaived;
  const formatDate = useFormatDate();

  return (
    <Stack gap={0} flexBasis="33%">
      <TextFieldCaption label={t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE')} size="XS" />
      <Stack>
        <Text variant="body-regular" color="text1" sx={{ textDecoration: isWaived ? 'line-through' : undefined }}>
          {renewalDate ? formatDate(renewalDate) : '-'}
        </Text>
        {isWaived && (
          <Stack direction="row" alignItems="center" marginLeft="-6px">
            <Checkbox disabled checked size="XS" />
            <Text variant="caption" color={palette.inactive.color2} size="XS">
              {t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE_WAIVED')}
            </Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
