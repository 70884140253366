import React from 'react';
import UsageRow from '@vertice/slices/src/slices/UsageAnalytics/components/UsageRow';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Card, CardContent } from '@vertice/components';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

type UsageTileProps = {
  application?: ApplicationWithMeasureReports;
};

const UsageTile = ({ application }: UsageTileProps) => (
  <Card>
    <CardContent>
      <UsageRow application={application} />
    </CardContent>
  </Card>
);

export default UsageTile;
