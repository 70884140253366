import React from 'react';
import { rgba } from 'polished';
import { Stack, styled } from '@mui/material';
import { Text, TextProps } from '@verticeone/design-system/src';
import { Tooltip, TooltipInfoTrigger, TooltipProps } from '@verticeone/design-system/src';
import { ifExpression } from '@verticeone/design-system/src';

export type TitleProps = {
  tooltip?: Pick<TooltipProps, 'title' | 'content'>;
  children: React.ReactNode;
} & Omit<TextProps, 'children' | 'variant' | 'size'>;

const BaseTitle = ({ tooltip, children, ...otherProps }: TitleProps) => {
  if (tooltip) {
    return (
      <div className={otherProps.className}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Text {...otherProps} variant="body-bold" size="S">
            {children}
          </Text>
          <Tooltip {...tooltip} size="S">
            <TooltipInfoTrigger />
          </Tooltip>
        </Stack>
      </div>
    );
  }

  return (
    <Text {...otherProps} variant="body-bold" size="S">
      {children}
    </Text>
  );
};

export const Title = styled(BaseTitle)(({ theme, color }) => ({
  ...ifExpression(!color, {
    color: theme.palette.text.color2,
  }),
  [TooltipInfoTrigger]: {
    color: rgba(theme.palette.text.color2, 0.4),
  },
}));
